import { phoneNumberFormatter } from '@headrace/utils';
import classNames from 'classnames';
import type { VFC } from 'react';
import React, { useMemo } from 'react';

import Badge from '../Badge';
import { Input, PhoneInput, RichTextEditor, Select, TextArea } from '../forms';
import MultiSelectField from '../forms/fields/formik/MultiSelectField';
import OnlyReadRichTextEditor from '../forms/fields/formik/RichTextEditor/OnlyReadRichTextEditor';
import TruncatedText from '../TruncatedText';
import type { SettingsContentProps, SettingsFormValues } from './Types';
import { FieldType } from './Types';

interface Props {
  item: SettingsContentProps;
  index: number;
  values: SettingsFormValues;
  edit: boolean;
}

const GeneralFormFields: VFC<Props> = (props) => {
  const { item, values, edit } = props;

  const renderInputField = useMemo((): JSX.Element => {
    switch (item.fieldType) {
      case FieldType.PHONE_INPUT:
        return (
          <PhoneInput
            name={item.id}
            disabled={item.disabled}
            placeholder={item.placeholder}
          />
        );
      case FieldType.SELECT:
        return (
          <Select
            name={item.id}
            placeholder="Select a time zone"
            options={item.selectOptions ?? []}
            isSearchable
          />
        );
      case FieldType.TEXT_AREA:
        return (
          <TextArea
            name={item.id}
            inputClassName={classNames(item.className)}
          />
        );
      case FieldType.RICH_TEXT_EDITOR:
        return (
          <div className="sm:mt-0 mt-6">
            <RichTextEditor
              name={item.id}
              nameJSON={item.nameJSON || ''}
              inputClassName={classNames(item.className)}
              characterMinCount={300}
            />
          </div>
        );
      case FieldType.MULTI_SELECT:
        return (
          <MultiSelectField name={item.id} options={item.selectOptions ?? []} />
        );
      default:
        return (
          <Input
            name={item.id}
            disabled={item.disabled}
            placeholder={item.placeholder}
            helper={item.helper}
            helperClassName={item.helperClassName}
            inputClassName="!h-[38px]"
          />
        );
    }
  }, [
    item.className,
    item.disabled,
    item.fieldType,
    item.helper,
    item.helperClassName,
    item.id,
    item.nameJSON,
    item.placeholder,
    item.selectOptions,
  ]);

  const showValue = useMemo((): React.ReactNode | string => {
    if (item.fieldType === FieldType.SELECT) {
      const selected = item?.selectOptions?.find(
        ({ value }) => value === values[item.id]
      );
      return selected?.label ?? '';
    }
    if (
      item.fieldType === FieldType.MULTI_SELECT &&
      typeof item.value === 'object'
    ) {
      return (
        <div className="flex flex-wrap justify-start pt-0 sm:pt-2 gap-1">
          {item?.value.map((selectItem) => (
            <Badge
              key={selectItem.value}
              label={selectItem.label}
              className="!px-4 py-1 text-xs rouded-3xl"
            >
              {selectItem.label}
            </Badge>
          ))}
        </div>
      );
    }
    if (item.showMore && item.fieldType === FieldType.TEXT_AREA) {
      if (typeof item.value === 'string') {
        if (item.value.length > 1300)
          return (
            <TruncatedText
              text={item.value}
              truncateLimit={1300}
              expandText="Read"
              allowAfterExpand
            />
          );
      }
    }
    if (item.fieldType === FieldType.RICH_TEXT_EDITOR) {
      if (typeof item.value === 'string') {
        return (
          <OnlyReadRichTextEditor
            name={item.id}
            json={item.json || ''}
            value={item.value}
          />
        );
      }
    }
    return (
      <div className={classNames({ 'truncate max-w-[40ch]': item.truncate })}>
        {item.fieldType === FieldType.PHONE_INPUT
          ? phoneNumberFormatter((values[item.id] as string) ?? '')
          : (values[item.id] as string)}
      </div>
    );
  }, [
    item.fieldType,
    item.id,
    item.json,
    item?.selectOptions,
    item.showMore,
    item.truncate,
    item.value,
    values,
  ]);

  return (
    <div key={item.id} className="flex flex-col gap-2">
      <label
        htmlFor={item.id}
        className="inline-flex text-sm font-medium text-headraceBlack-800 "
      >
        {edit && item.editLabel ? item.editLabel : item.label}{' '}
        {!edit && item.labelIcon}
      </label>

      <span className="flex-grow text-gray-900">
        {edit ? renderInputField : showValue}
      </span>
    </div>
  );
};

export default GeneralFormFields;
