import { Form, Formik, useFormikContext } from 'formik';
import type { Dispatch, SetStateAction, VFC } from 'react';
import * as Yup from 'yup';

import Button from '../../../Button';
import { Input, SubmitButton } from '../../../forms';
import Modal from '../../../Modal';
import type { PlacementForm } from './PlacementFormModal';

interface Props {
  open: boolean;
  onClose: () => void;
  createRoleTitle?: (role: string) => Promise<string | null>;
  loading: boolean;
  setInitialValues: Dispatch<SetStateAction<PlacementForm>>;
}

interface RoleTitleForm {
  role: string;
}

const CreateRoleTitleModal: VFC<Props> = ({
  open,
  onClose,
  createRoleTitle,
  loading,
  setInitialValues,
}) => {
  const { values: placementValues } = useFormikContext<PlacementForm>();

  const onCloseModal = (): void => {
    onClose();
    setInitialValues(placementValues);
  };

  const onSubmit = async (values: RoleTitleForm): Promise<void> => {
    if (!createRoleTitle) return;
    const roleTitleId = await createRoleTitle(values.role);
    setInitialValues({
      ...placementValues,
      roleTitleId: roleTitleId || '',
    });
  };

  const CreateRoleTitleSchema = Yup.object().shape({
    role: Yup.string().required('Role name is required'),
  });

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      title="Add new role name"
      className="sm:min-w-[40%]"
    >
      <Formik
        initialValues={{
          role: '',
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={CreateRoleTitleSchema}
        onSubmit={async (values): Promise<void> => {
          await onSubmit(values);
          onClose();
        }}
      >
        <Form>
          <div className="flex flex-col gap-4">
            <Input name="role" label="Role" placeholder="Role name" />
            <div className="flex justify-end gap-3">
              <Button buttonType="secondary" onClick={onCloseModal}>
                Cancel
              </Button>
              <SubmitButton loading={loading}>Add</SubmitButton>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default CreateRoleTitleModal;
