import { MailIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import Link from 'next/link';
import type { VFC } from 'react';

import { UserCircleIcon } from './CustomIcons';

interface Props {
  email: string;
  name: string;
  avatar?: string | null;
  badge?: JSX.Element;
}

const UserCard: VFC<Props> = ({ email, name, avatar, badge }) => (
  <div className="flex items-center gap-4">
    <div className="">
      {avatar ? (
        <Image
          className="block mx-auto h-8 w-8 rounded-full"
          src={avatar}
          alt=""
          width={48}
          height={48}
        />
      ) : (
        <UserCircleIcon height={48} width={48} />
      )}
    </div>
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 h-full">
        <span className="text-sm font-medium truncate">{name}</span>
        {badge}
      </div>

      <div className="flex items-center text-sm">
        <Link href={`mailto:${email}`} passHref>
          <a>
            <MailIcon
              className="flex-shrink-0 mr-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </a>
        </Link>
        <Link href={`mailto:${email}`} passHref>
          <a>
            <div className="truncate text-gray-500">{email}</div>
          </a>
        </Link>
      </div>
    </div>
  </div>
);

export default UserCard;
