import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
}

const TypeItalicIcon: VFC<Props> = ({
  className = 'bi bi-type-italic',
  width = '8',
  height = '10',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.7998 9.38184L4.72461 9.63477H0.910156L1.00586 9.38184C1.38867 9.37272 1.6416 9.34082 1.76465 9.28613C1.96517 9.20866 2.11328 9.10156 2.20898 8.96484C2.35938 8.75065 2.51432 8.36784 2.67383 7.81641L4.28711 2.22461C4.42383 1.75977 4.49219 1.40885 4.49219 1.17188C4.49219 1.05339 4.46257 0.953125 4.40332 0.871094C4.34408 0.789062 4.25293 0.727539 4.12988 0.686523C4.01139 0.640951 3.77669 0.618164 3.42578 0.618164L3.50781 0.365234H7.08984L7.01465 0.618164C6.72298 0.613607 6.50651 0.645508 6.36523 0.713867C6.16016 0.805013 6.00293 0.934896 5.89355 1.10352C5.78874 1.27214 5.65202 1.64583 5.4834 2.22461L3.87695 7.81641C3.73112 8.33138 3.6582 8.6595 3.6582 8.80078C3.6582 8.91471 3.68555 9.0127 3.74023 9.09473C3.79948 9.1722 3.89062 9.23372 4.01367 9.2793C4.14128 9.32031 4.40332 9.35449 4.7998 9.38184Z"
      fill="#4B5563"
    />
  </svg>
);

export default TypeItalicIcon;
