const colors = {
  headraceYellow: {
    100: '#FFF8E1',
    200: '#FFECB3',
    300: '#FFE082',
    400: '#FFD54F',
    500: '#FFCA28',
    600: '#FFC107',
    700: '#FFA300',
  },
  headraceBlack: {
    100: '#A7A9B6',
    200: '#9395A3',
    300: '#7F8291',
    400: '#6D6F7F',
    500: '#5B5D6D',
    600: '#4a4C5B',
    700: '#3A3C49',
    800: '#000000',
  },
};

export default colors;
