import classNames from 'classnames';

type CheckboxProps = (props: {
  name: string;
  description?: string;
  label?: string;
  checked: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
}) => JSX.Element;

const Checkbox: CheckboxProps = ({
  name,
  description,
  label,
  checked,
  onChange,
  value,
  className,
  labelClassName,
  disabled,
}) => {
  const checboxClassName = classNames('flex items-center', className);
  return (
    <div className={checboxClassName}>
      <div className="self-start flex items-center h-5">
        <input
          type="checkbox"
          name={name}
          id={name}
          onChange={onChange}
          className={classNames(
            'rounded border-gray-300 text-headraceYellow-700 focus:ring-transparent active:ring-headraceYellow-700 black-checkbox cursor-pointer',
            {
              '!cursor-not-allowed !pointer-events-none !text-gray-400 bg-gray-100':
                disabled,
            }
          )}
          value={value || ''}
          checked={!!checked}
          disabled={disabled}
        />
      </div>

      {(label || description) && (
        <div className="ml-3 text-sm">
          {label && (
            <label
              htmlFor={name}
              className={classNames(
                'font-medium text-gray-700',
                labelClassName
              )}
            >
              {label}
            </label>
          )}
          {description && <p className="text-gray-500">{description}</p>}
        </div>
      )}
    </div>
  );
};

export default Checkbox;
