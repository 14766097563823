import { useAuth0 } from '@auth0/auth0-react';
import {
  AuthenticationCheck,
  AuthorizedApolloProvider,
  isAdmin,
} from '@headrace/auth';
import { POLICY_VERSION, TERMS_VERSION } from '@headrace/constants';
import {
  useApiVersionLazyQuery,
  useUpdateUserTermsAndPolicyMutation,
} from '@headrace/graphql';
import { EntityTypeEnum, UserConsentType } from '@headrace/types';
import {
  AccessDeniedPage,
  BecomeUserHeader,
  PageLoader,
  TailwindLayout,
  TermsAndPolicy,
} from '@headrace/ui';
import {
  checkApiVersion,
  formatApolloError,
  getFromStorage,
} from '@headrace/utils';
import { HomeIcon } from '@heroicons/react/outline';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';

import EmployerForm from '@/components/EmployeeForm';
import RoleDetailsLayout from '@/components/Role/RoleDetails/RoleDetailsLayout';
import AllowedPathsEmployerAI from '@/constants/AllowedPathsEmployerAI';
import { navigation } from '@/constants/NavigationItems';
import EmployeeProvider, { useEmployee } from '@/lib/EmployeeProvider';

interface FormValues {
  tou: boolean;
}

const DefaultLayout: React.VFC<{ children: JSX.Element }> = (props) => {
  const hotjarId = 3213739;
  const { children } = props;
  const { employee, loading, fetchEmployee } = useEmployee();

  const isHeadRaceAI = employee?.employer.isHeadRaceAI;

  const [getApiVersion] = useApiVersionLazyQuery();

  const { logout, user } = useAuth0();
  const { pathname, reload, asPath, replace } = useRouter();

  const isUserAdmin = isAdmin(user);
  const isUserKNNVisualizationPath = pathname.includes('/knn-visualization');

  const [, , removeCookie] = useCookies(['become-user-id']);

  const [updateUserTermsAndPolicy, { loading: loadingTerms }] =
    useUpdateUserTermsAndPolicyMutation({
      onCompleted: () => fetchEmployee(),
      onError: (_error) => toast.error(formatApolloError(_error)),
    });

  const handleUpdateTerms = async (values: FormValues): Promise<void> => {
    await updateUserTermsAndPolicy({
      variables: {
        data: {
          tou: values.tou,
          entity: EntityTypeEnum.EMPLOYEE,
        },
      },
    });
  };

  const userNavigation = useMemo(
    () => [
      {
        name: 'Settings',
        href: '/settings',
      },
      {
        name: 'Sign out',
        onClick: (e: React.MouseEvent<HTMLButtonElement>): void => {
          e.preventDefault();
          removeCookie('become-user-id', { path: '/' });
          logout({ returnTo: window.location.origin });
        },
      },
    ],
    [logout, removeCookie]
  );

  const navigationItems = useMemo(() => {
    if (isUserAdmin && isUserKNNVisualizationPath) {
      return [
        {
          name: 'Dashboard',
          href: '/',
          icon: HomeIcon,
          showNavigation: true,
        },
      ];
    }
    return navigation(employee, employee?.roles);
  }, [employee, isUserAdmin, isUserKNNVisualizationPath]);

  const getLastApiVersion = async (): Promise<string> => {
    const apiVersionData = await getApiVersion();
    const apiVersion = apiVersionData.data?.apiVersion;
    return apiVersion || '';
  };

  useEffect(() => {
    if (isHeadRaceAI && employee?.roles) {
      if (
        pathname === '/roles' ||
        (pathname !== '/roles' &&
          pathname !== '/' &&
          !AllowedPathsEmployerAI.some((p) => pathname.includes(p)))
      ) {
        if (employee.roles.length > 0) {
          // Check if we have a lastRoleIdViewed in local storage
          const lastRoleIdViewed = getFromStorage('lastRoleIdViewed');
          if (lastRoleIdViewed) {
            replace(`/roles/${lastRoleIdViewed}`).catch(null);
            return;
          }
          replace(`/roles/${employee.roles[0].id}`).catch(null);
          return;
        }
        replace('/roles/create').catch(null);
      }
    }
  }, [employee?.roles, isHeadRaceAI, pathname, replace]);

  const home = useMemo(() => {
    // User has switched back to the tab
    const onFocus = async (): Promise<void> => {
      // Check api version
      await checkApiVersion({
        getApiVersion: getLastApiVersion,
        frontendVersion: process.env.npm_package_version || '',
        reload,
      });
    };
    return (
      <>
        {isUserAdmin && <BecomeUserHeader adminUserEmail={user?.email} />}
        <TailwindLayout
          currentPath={pathname}
          navigation={navigationItems}
          profileImg={employee?.user.photoUrl}
          userNavigation={userNavigation}
          hotjarId={hotjarId}
          onFocus={onFocus}
          withHidableSidebar={!isHeadRaceAI || isUserKNNVisualizationPath}
          currentUrl={asPath}
        >
          {children}
        </TailwindLayout>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    navigationItems,
    pathname,
    employee?.user.photoUrl,
    userNavigation,
    children,
    getApiVersion,
    isUserAdmin,
  ]);

  // Loading
  if (loading) {
    return <PageLoader />;
  }

  // Headrace admin
  if (employee && employee.user.isHeadRaceAdmin) {
    return home;
  }

  // Is not HeadRace AI
  if (employee && !isHeadRaceAI) {
    return (
      <div className="h-screen w-screen flex items-center justify-center">
        <AccessDeniedPage
          logOutAction={(): void => {
            removeCookie('become-user-id', { path: '/' });
            logout({ returnTo: window.location.origin });
          }}
        />
        ;
      </div>
    );
  }

  // Employee exist
  if (employee && employee.user.userConsents.length > 0) {
    const haveNewVersionPolicy = employee.user.userConsents.find(
      (item) =>
        item.userConsentType === UserConsentType.PRIVACY_POLICY &&
        item.versionNumber === POLICY_VERSION
    );
    const haveNewVersionTerms = employee.user.userConsents.find(
      (item) =>
        item.userConsentType === UserConsentType.TERMS_OF_USE &&
        item.versionNumber === TERMS_VERSION
    );
    if (haveNewVersionPolicy && haveNewVersionTerms) {
      return home;
    }
    return (
      <TermsAndPolicy
        handleSubmit={handleUpdateTerms}
        loadingTerms={loadingTerms}
      />
    );
  }

  return (
    <>
      <Head>
        <title>Employee Registration</title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content="Employee Registration" />
        <meta name="description" content="Employee Registration" key="desc" />
        <meta property="og:description" content="Employee Registration" />
        <link rel="icon" href="/headrace_icon.svg" />
        <meta property="og:image" content="/headrace_icon.svg" />
      </Head>
      <EmployerForm onCompleted={fetchEmployee} employee={employee} />
    </>
  );
};

const ChildrenComponent: React.VFC<{ children: JSX.Element }> = ({
  children,
}) => {
  const router = useRouter();
  const { employee } = useEmployee();
  const childrenComponent = useMemo(() => {
    // Add RoleDetailsLayout to RoleDetailsPage only
    let includeInRoleDetailsLayout = false;
    const roleDetailsPage = navigation(employee, employee?.roles).find(
      (nav) => nav.href === '/roles/[roleId]'
    );
    if (roleDetailsPage && roleDetailsPage.children) {
      includeInRoleDetailsLayout = roleDetailsPage.children.some((child) => {
        if (child === router.pathname) {
          return true;
        }
        return false;
      });
    }
    const isRoleDetailsPage = router.pathname === '/roles/[roleId]';
    if (includeInRoleDetailsLayout || isRoleDetailsPage) {
      return (
        <RoleDetailsLayout>
          <div className="flex flex-col flex-1">{children}</div>
        </RoleDetailsLayout>
      );
    }
    return children;
  }, [children, router.pathname, employee]);

  return childrenComponent;
};

/* DefaultLayoutWrapper
 * Wraps over DefaultLayout to pass on the required providers
 */
const WrappedDefaultLayout: React.VFC<{ children: JSX.Element }> = ({
  children,
}) => {
  const api = process.env.NEXT_PUBLIC_HEADRACE_API_URI ?? '';

  return (
    <AuthorizedApolloProvider uri={`${api}/graphql/employer`}>
      {/* AuthenticationCheck loads user (required before EmployeeProvider) */}
      <AuthenticationCheck>
        <EmployeeProvider>
          <DefaultLayout>
            <ChildrenComponent>{children}</ChildrenComponent>
          </DefaultLayout>
        </EmployeeProvider>
      </AuthenticationCheck>
    </AuthorizedApolloProvider>
  );
};

export default WrappedDefaultLayout;
