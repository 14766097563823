import { Form, Formik } from 'formik';
import Image from 'next/image';
import React from 'react';
import * as Yup from 'yup';

import Card from '../Card';
import { SubmitButton } from '../forms';
import { CheckboxField } from '../forms/fields/formik';

interface FormValues {
  tou: boolean;
}

interface CarouselProps {
  handleSubmit: (values: FormValues) => void;
  loadingTerms: boolean;
}

const getTermsAndPolicyComponent = (): React.ReactElement => (
  <>
    <a
      className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
      target="_blank"
      rel="noreferrer"
      href="https://www.headrace.com/info/terms-of-use"
    >
      Terms of Use
    </a>{' '}
    and{' '}
    <a
      className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
      target="_blank"
      rel="noreferrer"
      href="https://www.headrace.com/info/privacy-policy"
    >
      Privacy Policy
    </a>
  </>
);

const TermsAndPolicy: React.VFC<CarouselProps> = ({
  handleSubmit,
  loadingTerms,
}) => (
  <Card className="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-10 py-8 leading-4">
    <div className="mx-auto h-12 w-auto relative mb-8">
      <Image layout="fill" src="/headrace_icon.svg" alt="HeadRace Logo" />
    </div>
    <div className="text-gray-900 font-bold text-2xl text-center mb-6">
      Our terms have been updated
    </div>
    <div className="text-headraceBlack-800 text-base font-normal">
      To provide the best customer experience, HeadRace has updated our{' '}
      {getTermsAndPolicyComponent()}
      <br />
      <br />
      Please review and accept HeadRace’s Terms of Use and Privacy Policy to
      continue using HeadRace.
    </div>{' '}
    <Formik
      initialValues={{
        tou: false,
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        tou: Yup.boolean().required('Required'),
      })}
    >
      <Form className="space-y-6 mt-6">
        <div className="flex">
          <CheckboxField name="tou" label="" required />
          <label htmlFor="tou" className="inline-block text-sm text-gray-900">
            I agree to HeadRace {getTermsAndPolicyComponent()}
          </label>
        </div>
        <div>
          <SubmitButton disabled={loadingTerms} className="sm:w-full w-full">
            Submit
          </SubmitButton>
        </div>
      </Form>
    </Formik>
  </Card>
);

export default TermsAndPolicy;
