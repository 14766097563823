import { BASIC_URL } from '@headrace/constants';
import { RequestRoleType } from '@headrace/types';
import { currencyFormatter } from '@headrace/utils';
import * as Yup from 'yup';

const CreateRoleFormSchema = (
  typeSchema: RequestRoleType,
  importRole?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const name = {
    name: Yup.string().when(() =>
      importRole
        ? Yup.string().nullable()
        : Yup.string()
            .max(50, 'Role name has a maximum limit of 50 characters.')
            .required('Role name is required')
    ),
  };
  const roleSeniority = {
    roleSeniority: Yup.string().required('Please, select a seniority'),
  };
  const roleFunction = {
    roleFunction: Yup.string().required('Please, select a function'),
  };
  const cities = {
    cities: Yup.array()
      .of(Yup.string())
      .min(1, '1 location as minimum')
      .required('Office locations are required'),
  };
  const quantity = {
    quantity: Yup.number()
      .integer('Quantity must be an integer.')
      .nullable()
      .required('This field is required'),
  };
  const expectedFirstYearSalaryMin = {
    expectedFirstYearSalaryMin: Yup.number()
      .integer('Please provide a salary with no decimal places.')
      .nullable()
      .required('This field is required'),
  };
  const expectedFirstYearSalaryMax = {
    expectedFirstYearSalaryMax: Yup.number()
      .integer('Please provide a salary with no decimal places.')
      .nullable()
      .min(
        Yup.ref('expectedFirstYearSalaryMin'),
        ({ min }) => `Must not be less than ${currencyFormatter().format(min)}`
      )
      .required('This field is required'),
  };
  const expectedFirstYearShareCount = {
    expectedFirstYearShareCount: Yup.number().integer().nullable(),
  };
  const jobDescriptionLink = {
    jobDescriptionLink: Yup.string().matches(BASIC_URL, 'Invalid URL'),
  };
  const firstName = {
    firstName: Yup.string().required('First name is required'),
  };
  const lastName = {
    lastName: Yup.string().required('Last name is required'),
  };
  const email = {
    email: Yup.string().email('Invalid email').required('Email is required'),
  };

  if (typeSchema === RequestRoleType.CREATE_IA_ROLE) {
    return Yup.object().shape({
      ...name,
      ...expectedFirstYearSalaryMin,
      ...expectedFirstYearSalaryMax,
      ...jobDescriptionLink,
    });
  }

  if (typeSchema === RequestRoleType.INVITE_HIRING_MANAGER) {
    return Yup.object().shape({
      ...name,
      ...roleSeniority,
      ...roleFunction,
      ...cities,
      ...quantity,
      ...expectedFirstYearSalaryMin,
      ...expectedFirstYearSalaryMax,
      ...expectedFirstYearShareCount,
      ...jobDescriptionLink,
      ...firstName,
      ...lastName,
      ...email,
    });
  }

  if (typeSchema === RequestRoleType.SELECT_HIRING_MANAGER) {
    return Yup.object().shape({
      ...name,
      ...roleSeniority,
      ...roleFunction,
      ...cities,
      ...quantity,
      ...expectedFirstYearSalaryMin,
      ...expectedFirstYearSalaryMax,
      ...expectedFirstYearShareCount,
      ...jobDescriptionLink,
      hiringManager: Yup.string().required('Hiring manager is required'),
    });
  }
  return null;
};

export default CreateRoleFormSchema;
