import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import type { FC } from 'react';
import { useRef } from 'react';

interface SimpleModalProps {
  open: boolean;
  onClose: () => void;
  className?: string;
  closeIconClassName?: string;
}

const SimpleModal: FC<SimpleModalProps> = ({
  open,
  onClose,
  children,
  className,
  closeIconClassName = 'text-gray-400 w-5',
}) => {
  const initialFocusRef = useRef(null);
  const bodyClassName = classNames(
    'flex flex-col bg-white  w-96 p-6 text-center rounded-lg',
    className
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className={classNames(
        'fixed inset-0 z-30 flex items-center justify-center',
        {
          'bg-gray-500 bg-opacity-75 transition-opacity': open,
        }
      )}
    >
      <div className={bodyClassName}>
        <div ref={initialFocusRef}>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="outline-none z-10"
            >
              <XIcon className={closeIconClassName} />
            </button>
          </div>
        </div>
        {children}
      </div>
    </Dialog>
  );
};

export default SimpleModal;
