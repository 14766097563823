import type { RoleFactsCardData, RoleFactsFormValues } from '@headrace/types';
import { currencyFormatter, numberFormatter } from '@headrace/utils';
import {
  CashIcon,
  IdentificationIcon,
  TrendingUpIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import type { VFC } from 'react';
import { useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';

import EditCard from '../../EditCard';
import type { OptionsProps } from '../../forms';
import RoleFactsForm from './RoleFactsForm';

export interface RoleFactsCardProps extends RoleFactsCardData {
  editMode?: boolean;
  roleSeniorityOptions?: OptionsProps[];
}

const RoleFactsFormSchema = Yup.object().shape({
  roleSeniorityId: Yup.string().required('Please, select a seniority'),
  quantity: Yup.number()
    .integer('Quantity must be an integer.')
    .nullable()
    .required('This field is required'),
  expectedFirstYearSalaryMin: Yup.number()
    .integer('Please provide a salary with no decimal places.')
    .nullable()
    .required('This field is required'),
  expectedFirstYearSalaryMax: Yup.number()
    .integer('Please provide a salary with no decimal places.')
    .nullable()
    .min(
      Yup.ref('expectedFirstYearSalaryMin'),
      ({ min }) => `Must not be less than ${numberFormatter().format(min)}`
    )
    .required('This field is required'),
  expectedFirstYearShareCount: Yup.number().integer().nullable(),
});

const RoleFactItem = ({
  value,
  title,
  icon,
}: {
  value: string;
  title: string;
  icon: React.ReactNode;
}): JSX.Element | null => (
  <div className="flex gap-4 text-gray-500 tex-base items-center">
    <div className="flex-shrink-0">{icon}</div>
    <div className="flex flex-col">
      <div className="text-gray-500 font-medium">{title}</div>
      <div className="font-base">{value}</div>
    </div>
  </div>
);

const RoleFactsCard: VFC<RoleFactsCardProps> = ({
  saveAction,
  cancelAction,
  loading,
  data,
  editMode,
  roleSeniorityOptions,
}) => {
  const formRef = useRef<FormikProps<RoleFactsFormValues>>(null);
  const [activeEditMode, setActiveEditMode] = useState(false);
  const viewComponent = useMemo(() => {
    const seniorityLevel = {
      title: 'Level',
      value: data.roleSeniorityName || '',
      icon: <IdentificationIcon className="w-9 stroke-1" />,
    };
    const salaryRange = {
      title: 'Salary range',
      value:
        data.expectedFirstYearSalaryMin && data.expectedFirstYearSalaryMax
          ? `${currencyFormatter().format(
              data.expectedFirstYearSalaryMin
            )} - ${currencyFormatter().format(data.expectedFirstYearSalaryMax)}`
          : '',
      icon: <CashIcon className="w-9 stroke-1" />,
    };
    const roleOpenings = {
      title: 'Role openings',
      value: data.quantity ? numberFormatter().format(data.quantity) : '',
      icon: <UserGroupIcon className="w-9 stroke-1" />,
    };
    const roleFacts = [salaryRange];

    if (data.roleSeniorityName) {
      roleFacts.unshift(seniorityLevel);
    }
    if (data.quantity) {
      roleFacts.push(roleOpenings);
    }

    if (data.expectedFirstYearShareCount) {
      roleFacts.splice(2, 0, {
        title: 'Target equity count',
        value: data.expectedFirstYearShareCount
          ? numberFormatter().format(data.expectedFirstYearShareCount)
          : '',
        icon: <TrendingUpIcon className="w-9 stroke-1" />,
      });
    }
    return (
      <div className="flex flex-col gap-6">
        {roleFacts.map((roleFact) => (
          <RoleFactItem
            key={roleFact.title}
            value={roleFact.value}
            title={roleFact.title}
            icon={roleFact.icon}
          />
        ))}
      </div>
    );
  }, [
    data.expectedFirstYearSalaryMax,
    data.expectedFirstYearSalaryMin,
    data.expectedFirstYearShareCount,
    data.quantity,
    data.roleSeniorityName,
  ]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={data}
      onSubmit={(values): void => {
        if (saveAction) saveAction(values);
        setActiveEditMode(false);
      }}
      validationSchema={RoleFactsFormSchema}
      enableReinitialize
    >
      {({ resetForm }): JSX.Element => (
        <Form>
          <EditCard
            saveAction={async (): Promise<void> => {
              await formRef.current?.submitForm();
            }}
            cancelAction={(): void => {
              setActiveEditMode(false);
              resetForm();
              if (cancelAction) cancelAction();
            }}
            viewStateComponent={viewComponent}
            editStateComponent={
              <RoleFactsForm
                roleSeniorityOptions={roleSeniorityOptions || []}
              />
            }
            title="Role facts"
            loading={loading}
            editMode={editMode}
            activeEditMode={activeEditMode}
            closeOnSave={false}
            onEdit={(): void => setActiveEditMode(true)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default RoleFactsCard;
