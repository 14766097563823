import { RadioGroup } from '@headlessui/react';
import type { RequestRoleType } from '@headrace/types';
import { CheckCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

interface RadioButtonOptionProps {
  title: string;
  value: number | RequestRoleType | string;
}

const RadioButtonOption: React.VFC<RadioButtonOptionProps> = (props) => {
  const { title, value } = props;

  return (
    <RadioGroup.Option
      key={value}
      value={value}
      className={({ checked }): string =>
        classNames(
          checked ? 'border-transparent' : 'border-gray-300',
          'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
        )
      }
    >
      {({ checked }): React.ReactElement => (
        <>
          <span className="flex-1 flex">
            <span className="flex flex-col">
              <RadioGroup.Label
                as="span"
                className="block text-sm font-medium text-gray-900"
              >
                {title}
              </RadioGroup.Label>
            </span>
          </span>
          <CheckCircleIcon
            className={classNames(
              !checked ? 'invisible' : '',
              'h-5 w-5 text-headraceYellow-600'
            )}
            aria-hidden="true"
          />
          <span
            className={classNames(
              checked ? 'border-headraceYellow-500' : 'border-transparent',
              'absolute -inset-px rounded-lg pointer-events-none border'
            )}
            aria-hidden="true"
          />
        </>
      )}
    </RadioGroup.Option>
  );
};

export default RadioButtonOption;
