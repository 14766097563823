import { isValidHttpUrl } from '@headrace/utils';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

export interface CardColumnItem {
  title: string | JSX.Element;
  subtitle: string | number | JSX.Element;
  url?: string;
  component?: React.ReactElement;
}

interface Props {
  className?: string;
  content: CardColumnItem[];
  titleDescription?: string;
  description?: string | JSX.Element;
  attachments?: React.ReactNode;
  numberOfColumns?: number;
  descriptionLink?: string | null;
  hiddeTitleDescription?: boolean;
}

const ColumnsCard: React.VFC<Props> = (props) => {
  const {
    className,
    content,
    titleDescription,
    description,
    attachments,
    numberOfColumns = 2,
    descriptionLink,
    hiddeTitleDescription = false,
  } = props;

  const containerClassName = classNames(
    'grid grid-cols-1 gap-x-4 gap-y-8',
    className,
    {
      'sm:grid-cols-2': numberOfColumns === 2,
      'sm:grid-cols-3': numberOfColumns === 3,
      'sm:grid-cols-4': numberOfColumns === 4,
      'sm:grid-cols-5': numberOfColumns === 5,
      'sm:grid-cols-6': numberOfColumns === 6,
    }
  );

  return (
    <div className="bg-white">
      <div className={containerClassName}>
        {content.map((item) => (
          <div key={item.title.toString()} className="col-span-1 flex flex-col">
            <div className="text-sm font-medium">{item.title}</div>
            {item.url ? (
              <a
                href={item.url}
                target="_blank"
                rel="noreferrer"
                className="link break-words font-medium"
              >
                <div className="mt-2 text-sm break-words">
                  {item.subtitle} {item.component && item.component}
                </div>
              </a>
            ) : (
              <div className="mt-2 text-sm break-words font-normal">
                {item.subtitle}
              </div>
            )}
          </div>
        ))}
        {description && titleDescription && (
          <div className={`sm:col-span-${numberOfColumns}`}>
            <div className="text-sm font-medium flex">
              {hiddeTitleDescription ? '' : titleDescription}{' '}
              {descriptionLink && (
                <a
                  href={
                    isValidHttpUrl(descriptionLink)
                      ? descriptionLink
                      : `https://${descriptionLink}`
                  }
                  target="_blank"
                  className="text-blue-600 flex items-center ml-1"
                  rel="noreferrer"
                >
                  <ExternalLinkIcon className="w-4 h-4" />
                </a>
              )}
            </div>
            <div className="mt-2 text-sm self-stretch">{description}</div>
          </div>
        )}
        {attachments && (
          <div className={`sm:col-span-${numberOfColumns}`}>
            <div className="text-sm font-medium">Attachments</div>
            {attachments}
          </div>
        )}
      </div>
    </div>
  );
};

export default ColumnsCard;
