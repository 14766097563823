import { CandidateRejectedReason } from '@headrace/constants';
import type { CandidateActivityHistoryEntityType } from '@headrace/types';
import { CandidateRejectedReasonEnum } from '@headrace/types';
import type { OptionsProps } from '@headrace/ui';
import { enumKeys } from '@headrace/utils';
import { Form } from 'formik';
import type { Dispatch, SetStateAction, VFC } from 'react';

import { Select, TextArea } from '../../forms';
import SimpleToggle from '../../SimpleToggle';

const rejectReasonOptions: OptionsProps[] = enumKeys(
  CandidateRejectedReasonEnum
).map((key) => ({
  label: CandidateRejectedReason[CandidateRejectedReasonEnum[key]].title,
  value: key,
}));

interface Props {
  share: boolean;
  setShare: Dispatch<SetStateAction<boolean>>;
  shareWith: CandidateActivityHistoryEntityType;
}

const CandidateRejectReasonForm: VFC<Props> = ({
  share,
  setShare,
  shareWith,
}) => (
  <Form className="grid gap-4 grid-cols-1">
    <div>
      <div className="flex-1 mt-1 sm:mt-0">
        <Select name="reason" options={rejectReasonOptions} label="Reason" />
      </div>
    </div>
    <div>
      <div className="flex-1 mt-1 sm:mt-0">
        <TextArea name="feedback" label="Feedback" />
      </div>
    </div>
    <div>
      <div className="flex-1 mt-1 sm:mt-0">
        <SimpleToggle
          checked={share}
          onChange={setShare}
          label={`Share feedback with ${shareWith.toLowerCase()}`}
        />
      </div>
    </div>
  </Form>
);

export default CandidateRejectReasonForm;
