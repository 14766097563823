import type { ApolloError } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { employerDemoAccounts } from '@headrace/constants';
import { checkDemoAccount } from '@headrace/utils';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';
import { hotjar } from 'react-hotjar';

import type {
  CurrentEmployeeQuery,
  CurrentEmployeeQueryResult,
} from '@/graphql/generated';
import { useCurrentEmployeeLazyQuery } from '@/graphql/generated';

import { ampli } from './ampli';

interface EmployeeContextData {
  employee?: CurrentEmployeeQuery['currentEmployee'];
  loading: boolean;
  fetchEmployee: () => void;
  isDemoAccount: boolean;
  loadingEmployee: boolean;
}

interface EmployeeStateData {
  loading?: boolean;
  error?: { message: string };
  data?: CurrentEmployeeQueryResult['data'];
}

const EmployeeContext = React.createContext<EmployeeContextData>({
  employee: undefined,
  loading: false,
  fetchEmployee: () => {},
  isDemoAccount: false,
  loadingEmployee: false,
});

const EmployeeProvider: React.FC = ({ children }) => {
  const dataFetchedOnce = useRef<boolean>(false);
  const [{ loading, error, data }, setEmployeeData] =
    useState<EmployeeStateData>({});
  const { isLoading, user } = useAuth0();
  const [isDemoAccount, setIsDemoAccount] = useState<boolean>(false);
  const [cookies] = useCookies(['become-user-id']);

  const [lazyGetEmployee, { loading: loadingEmployee }] =
    useCurrentEmployeeLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const fetchEmployee = useCallback(() => {
    if (!dataFetchedOnce.current) dataFetchedOnce.current = true;

    lazyGetEmployee()
      .then((employeeResponse) => {
        setEmployeeData({
          loading: employeeResponse.loading,
          error: employeeResponse.error,
          data: employeeResponse.data,
        });

        if (employeeResponse.data && employeeResponse.data.currentEmployee) {
          setIsDemoAccount(
            checkDemoAccount(
              employeeResponse.data.currentEmployee.employer.id,
              employerDemoAccounts
            )
          );
        }
      })
      .catch((errorMessage: ApolloError) => {
        setEmployeeData({
          loading: false,
          error: errorMessage,
          data: undefined,
        });
      });
  }, [lazyGetEmployee]);

  const employeeContextState: EmployeeContextData = useMemo(
    () => ({
      employee: data?.currentEmployee,
      // loading is true until employee have been fetched once
      loading: loading || isLoading || !dataFetchedOnce.current,
      fetchEmployee,
      isDemoAccount,
      loadingEmployee,
    }),
    [
      data?.currentEmployee,
      loading,
      isLoading,
      fetchEmployee,
      isDemoAccount,
      loadingEmployee,
    ]
  );

  // First query execution (after Auth0's user is loaded)
  useEffect(() => {
    if (isLoading) return;
    ampli.loginAuth0UserLoaded({
      app: 'Employer',
    });
    fetchEmployee();
  }, [isLoading, user, fetchEmployee]);

  // Handle error in GetCurrentEmployeeQuery
  useEffect(() => {
    if (!loading && error) {
      // Temporary toast, refactor to redirect to Error page
      toast.error('An error has ocurred while loading your data', {
        duration: 5000,
      });
    }
  }, [loading, error]);

  // Set user props in Amplitude
  useEffect(() => {
    if (!data || !data.currentEmployee || !user) return;
    const { currentEmployee } = data;
    ampli.identify(user.sub, {
      'employee id': currentEmployee.id,
      'employer id': currentEmployee.employer.id,
      'is test account': currentEmployee.employer.isTestAccount,
      'is headrace admin': !!cookies['become-user-id'],
    });
    // Check if Hotjar has been initialized before calling its methods
    if (hotjar.initialized()) {
      hotjar.identify(currentEmployee.id, {
        employerId: currentEmployee.employer.id,
        isTestAccount: currentEmployee.employer.isTestAccount,
      });
    }
  }, [data, user, cookies]);
  useEffect(() => {
    if (isDemoAccount) ampli.client.setOptOut(true);
  }, [isDemoAccount]);

  return (
    <EmployeeContext.Provider value={employeeContextState}>
      {children}
    </EmployeeContext.Provider>
  );
};

export const useEmployee = (): EmployeeContextData =>
  React.useContext(EmployeeContext);

export default EmployeeProvider;
