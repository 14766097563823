/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-props-no-spreading */
import {
  CandidateInterestVerificationType,
  MarketplaceVisibilityEnum,
} from '@headrace/types';
import { formatDate, isValidHttpUrl } from '@headrace/utils';
import {
  PersonAvailable,
  PersonClock,
  PersonProhibited,
  PersonQuestionMark,
} from '@styled-icons/fluentui-system-filled';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC, ReactNode } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';

import {
  DirectIcon,
  InviteSearchIcon,
  LinkedinIcon,
  MailIcon,
  PublicSearchIcon,
  SplitSearchIcon,
} from '../CustomIcons';
import type { CandidateData } from './types';

interface Props {
  candidate: CandidateData;
  getOptionsMenu: (
    candidateId: string,
    candidateStatus: string,
    _searchAgreementId?: string | null,
    marketplaceVisibility?: string,
    agency?: {
      id: string;
      name: string;
      recruiterId: string;
    }
  ) => ReactNode;
  showSplitSearchIcon?: boolean;
  index: number;
  employerSide?: boolean;
  handleCandidateDetails?: (candidateId: string) => void;
}

const getCandidateIcon = (candidate: CandidateData) => {
  let candidateIconData: { icon: React.ReactNode; name: string } = {
    icon: undefined,
    name: '',
  };
  switch (
    candidate?.searchAgreementRole?.searchAgreement?.marketplaceVisibility
  ) {
    case MarketplaceVisibilityEnum.DIRECT:
      candidateIconData = {
        icon: <DirectIcon />,
        name: 'Direct',
      };
      break;
    case MarketplaceVisibilityEnum.PUBLIC:
      candidateIconData = {
        icon: <PublicSearchIcon />,
        name: 'Public',
      };
      break;
    case MarketplaceVisibilityEnum.INVITE:
      candidateIconData = {
        icon: <InviteSearchIcon />,
        name: 'Invite-only',
      };
      break;
    default:
      break;
  }

  return (
    <>
      <span
        className="w-6"
        data-for={`${candidate.id}-marketplaceVisibility`}
        data-tip={candidateIconData.name}
      >
        {candidateIconData.icon}
      </span>
      <ReactTooltip
        id={`${candidate.id}-marketplaceVisibility`}
        place="top"
        effect="solid"
        arrowColor="#30323D"
      />
    </>
  );
};

const getCandidateInterestVerificationStatus = (candidate: CandidateData) => {
  let candidateIconData: { icon: React.ReactNode; name: string } = {
    icon: null,
    name: '',
  };
  switch (candidate?.candidateInterestVerificationStatus) {
    case CandidateInterestVerificationType.CANDIDATE_VERIFICATION_REQUESTED:
      candidateIconData = {
        icon: <PersonQuestionMark />,
        name: 'Candidate interest verification pending',
      };
      break;
    case CandidateInterestVerificationType.CANDIDATE_INTERESTED:
      candidateIconData = {
        icon: <PersonAvailable className="text-green-600" />,
        name: 'Candidate confirmed interest in role',
      };
      break;
    case CandidateInterestVerificationType.CANDIDATE_NOT_INTERESTED:
      candidateIconData = {
        icon: <PersonProhibited className="text-red-600" />,
        name: 'Candidate not interested in role',
      };
      break;
    case CandidateInterestVerificationType.CANDIDATE_VERIFICATION_EXPIRED:
      candidateIconData = {
        icon: <PersonClock />,
        name: 'Candidate verification window expired',
      };
      break;
    default:
      break;
  }
  if (candidateIconData.icon === null) {
    return null;
  }
  return (
    <>
      <span
        className="w-5 -mt-1 text-headraceYellow-700"
        data-for={`${candidate.id}-marketplaceVisibility`}
        data-tip={candidateIconData.name}
      >
        {candidateIconData.icon}
      </span>
      <ReactTooltip
        id={`${candidate.id}-marketplaceVisibility`}
        place="top"
        effect="solid"
        arrowColor="#30323D"
      />
    </>
  );
};

const DraggableCandidate: FC<Props> = ({
  candidate,
  getOptionsMenu,
  index,
  showSplitSearchIcon,
  employerSide,
  handleCandidateDetails,
}) => {
  const { push } = useRouter();
  return (
    <Draggable key={candidate.id} draggableId={candidate.id} index={index}>
      {(prov, snapshot) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          ref={prov.innerRef}
          {...prov.draggableProps}
          {...prov.dragHandleProps}
          onClick={() => {
            if (employerSide && handleCandidateDetails) {
              handleCandidateDetails(candidate.id);
            } else push(`/candidates/${candidate.id}`).catch(null);
          }}
          className={classNames(
            snapshot.isDragging ? 'bg-gray-200' : 'bg-white',
            'h-30 rounded-lg shadow-sm p-3 border my-2 pr-0 w-full'
          )}
        >
          <div className="flex">
            <p className="grow text-gray-600 font-medium">
              {candidate.talent.name}
            </p>
            <div className="flex-none -mt-2">
              {' '}
              {getOptionsMenu(
                candidate.id,
                candidate.status,
                null,
                candidate.marketplaceVisibility,
                {
                  id: candidate.recruiter?.agency.id || '',
                  name: candidate.recruiter?.agency.name || '',
                  recruiterId: candidate.recruiter?.id || '',
                }
              )}
            </div>
          </div>
          <div className="flex">
            {isValidHttpUrl(candidate.talent.linkedIn) && (
              <Link href={candidate.talent.linkedIn} passHref>
                <a
                  role="button"
                  aria-hidden="true"
                  className="inline-flex"
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <LinkedinIcon
                    className="flex-shrink-0 mr-1 w-[23.52px] h-[20px]"
                    aria-hidden="true"
                  />
                </a>
              </Link>
            )}
            <Link
              href={
                candidate.talent.email ? `mailto:${candidate.talent.email}` : ''
              }
              passHref
            >
              <a
                aria-hidden="true"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <MailIcon
                  className="flex-shrink-0 mr-1 h-5 w-5 text-blue-500"
                  aria-hidden="true"
                />
              </a>
            </Link>
            {showSplitSearchIcon && (
              <SplitSearchIcon
                className="flex-shrink-0 h-5 w-5 text-blue-500"
                aria-hidden="true"
              />
            )}

            {employerSide && <>{getCandidateIcon(candidate)}</>}
            {!employerSide && (
              <>{getCandidateInterestVerificationStatus(candidate)}</>
            )}
          </div>

          <div className="text-gray-500 text-sm mt-4">
            {formatDate(candidate.createdAt.toString(), 'MMMM dd, yyyy')}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableCandidate;
