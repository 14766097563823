import classNames from 'classnames';
import React from 'react';

import Card from '../Card';
import LoadingSpinner from '../LoadingSpinner';

const RoleMetrics: React.FC<{
  title: string | React.ReactElement;
  count: number | string;
  className?: string;
  loading: boolean;
  titleClassName?: string;
  countClassName?: string;
}> = ({
  title,
  count,
  className,
  loading,
  titleClassName = 'w-8/12 font-normal text-xl xl:text-2xl',
  countClassName = classNames(
    'font-bold text-headraceBlack-800 text-[42px] self-center pr-4',
    {
      'pr-3 text-[37px]': !Number.isNaN(Number(count)) && Number(count) > 99,
    }
  ),
}) => {
  const isCountLarge = !Number.isNaN(Number(count)) && Number(count) > 99;

  return (
    <Card
      className={classNames(
        'flex justify-start items-center px-7 lg:px-5 xl:px-10 py-6 ',
        {
          'sm:px-7 lg:px-4 xl:px-4 text-[37px]': isCountLarge,
        },
        className
      )}
    >
      {loading ? (
        <LoadingSpinner className="p-4" />
      ) : (
        <>
          <div className={countClassName}>{count}</div>
          <div className="border-l border-headraceYellow-700 h-2/4" />
          <div
            className={classNames(
              'self-center pl-4',
              {
                'pl-2': isCountLarge,
              },
              titleClassName
            )}
          >
            {title}
          </div>
        </>
      )}
    </Card>
  );
};

export default RoleMetrics;
