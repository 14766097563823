import { BellIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';

import type { NavigationItem } from '.';
import type { UserNavigationItem } from './ProfileMenu';
import ProfileMenu from './ProfileMenu';

type TailwindFixedSidebarProps = {
  active: string;
  logo: string;
  navigation: NavigationItem[];
  profileImg?: string | null;
  userNavigation: UserNavigationItem[];
};

const TailwindFixedSidebar: React.FC<TailwindFixedSidebarProps> = (props) => {
  const { active, logo, navigation, profileImg, userNavigation } = props;
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleEnter = (): void => {
    setIsMouseOver(true);
  };

  const handleLeave = (): void => {
    setIsMouseOver(false);
  };

  return (
    <div
      className="hidden sticky h-screen md:flex md:flex-col md:inset-y-0 w-auto z-10"
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex flex-col flex-grow items-center justify-center px-3 pt-7 bg-headraceBlack-700 overflow-y-auto">
        <div className="flex items-center justify-center flex-shrink-0">
          <Link href="/" passHref>
            <a>
              <Image src={logo} alt="HeadRace Logo" width={32} height={32} />
            </a>
          </Link>
        </div>
        <div className="mt-5 flex-1 flex flex-col">
          <nav className="flex-1 pb-4 space-y-1">
            {navigation.map((item) => (
              <Link key={item.name} href={item.href} passHref>
                <a
                  className={classNames(
                    {
                      'bg-headraceBlack-800 text-white':
                        active === item.href ||
                        (item.href !== '/' && active.startsWith(item.href)),
                      'text-gray-400 hover:bg-headraceBlack-800':
                        item.href !== active &&
                        !(item.href !== '/' && active.startsWith(item.href)),
                    },
                    'pt-2 group flex flex-col items-center justify-center h-16 text-sm font-medium rounded-md transition-all duration-500'
                  )}
                >
                  {item.icon ? (
                    <>
                      <div className="relative">
                        <item.icon
                          className="mb-2 h-6 w-6"
                          aria-hidden="true"
                        />
                        {item.notificationBadge &&
                        item.notificationBadge > 0 ? (
                          <div className="absolute flex h-5 w-5 text-xs items-center justify-center  bg-red-600 text-whit rounded-full -top-2 -right-2">
                            {item.notificationBadge}
                          </div>
                        ) : null}
                      </div>

                      <span
                        className={classNames(
                          {
                            'h-6 opacity-1': isMouseOver === true,
                            'h-0 opacity-0': isMouseOver === false,
                            'text-white':
                              active === item.href ||
                              (item.href !== '/' &&
                                active.startsWith(item.href)),
                            'text-gray-400': item.href !== active,
                          },
                          'px-1 overflow-hidden  transition-all duration-300'
                        )}
                      >
                        {item.name}
                      </span>
                    </>
                  ) : (
                    ''
                  )}
                </a>
              </Link>
            ))}
          </nav>
        </div>
        {/* Remove the hidden when notifications will be used */}
        <button
          type="button"
          className="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mb-10 hidden"
        >
          <span className="sr-only">View notifications</span>
          <BellIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </button>
        <ProfileMenu userNavigation={userNavigation} profileImg={profileImg} />
      </div>
    </div>
  );
};

export default TailwindFixedSidebar;
