import classNames from 'classnames';
import type { VFC } from 'react';
import React, {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { MultiValue, SingleValue } from 'react-select';

import { BasicSelect } from '../forms';
import SearchBar from '../SearchBar';

interface ChildrenProps {
  filterText?: string;
}

export interface NavigationOption {
  name: string;
  component: React.ReactElement<ChildrenProps>;
  clickingTabFunction?: () => void;
  pill?: string;
}

export interface OptionsProps {
  label: string;
  value: string;
}

export interface TabComponentProps {
  navigationOptions: NavigationOption[];
  className?: string;
  disabled?: boolean;
  searchBar?: {
    label: string;
    action: (value: string) => void;
    value: string;
  };
  renderIconNextToSeachBar?: (tabName: string) => React.ReactElement | null;
  onChange?: (index?: number) => void;
  childrenClassName?: string;
  changeTab?: number;
}

const TabComponent: VFC<TabComponentProps> = ({
  navigationOptions,
  className,
  disabled = false,
  searchBar,
  renderIconNextToSeachBar,
  onChange,
  childrenClassName,
  changeTab,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const selectOptions: OptionsProps[] = navigationOptions.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  useEffect(() => {
    if (changeTab != null) {
      setCurrentIndex(changeTab);
    }
  }, [changeTab]);

  const currentSelected: OptionsProps = useMemo(
    () => ({
      label: navigationOptions[currentIndex].name,
      value: navigationOptions[currentIndex].name,
    }),
    [currentIndex, navigationOptions]
  );

  const findNavigation = (value?: SingleValue<OptionsProps>): number | null => {
    if (!value) {
      return null;
    }
    return navigationOptions.findIndex((x) => x.name === value.label);
  };

  const handleChange = (
    selectedOption: MultiValue<OptionsProps> | SingleValue<OptionsProps>
  ): void => {
    if (selectedOption && 'value' in selectedOption) {
      const finded = findNavigation(selectedOption);
      if (finded != null) {
        if (searchBar) searchBar.action('');
        if (onChange) onChange();
        setCurrentIndex(finded);
      }
    }
  };
  const handleClickingTab = (index: number): void => {
    const tab = navigationOptions[index];
    if (onChange) onChange(index);
    setCurrentIndex(index);
    if (searchBar) searchBar.action('');
    if (tab?.clickingTabFunction) {
      const { clickingTabFunction } = tab;
      clickingTabFunction();
    }
  };

  const isCurrentTab = (id: string): boolean =>
    id === navigationOptions[currentIndex].name;

  const childrenWithProps = useMemo(
    () =>
      Children.map(navigationOptions[currentIndex].component, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            filterText: searchBar?.value,
          });
        }
        return child;
      }),
    [currentIndex, navigationOptions, searchBar?.value]
  );

  return (
    <div className={classNames('bg-white rounded-lg shadow', className)}>
      {/* Mobile Select Tabs */}
      <div className="sm:hidden block border-b border-gray-200 mb-4 p-2">
        <BasicSelect
          id="selected-tab"
          options={selectOptions}
          onChange={handleChange}
          value={currentSelected}
          isDisabled={disabled}
        />
      </div>

      {/* Desktop */}
      <div className="px-4 sm:px-6 md:px-0">
        <div className="border-b border-gray-200 sm:block hidden">
          <div className="flex justify-between items-center">
            <nav className="-mb-px flex space-x-10 px-6">
              {navigationOptions.map((tab, index) => (
                <button
                  key={tab.name}
                  type="button"
                  className={classNames(
                    isCurrentTab(tab.name)
                      ? 'border-headraceYellow-700 text-headraceBlack-800'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 font-medium text-sm py-6 outline-none'
                  )}
                  onClick={(): void => handleClickingTab(index)}
                  disabled={disabled}
                >
                  {tab.name}
                  {tab.pill && (
                    <span className="rounded-[10px] inline-flex ml-2 bg-headraceYellow-700 !h-[20px] px-[10px] text-xs leading-4 text-center text-gray-800 items-center">
                      {tab.pill}
                    </span>
                  )}
                </button>
              ))}
            </nav>
            {searchBar && (
              <div className="h-9 flex justify-end items-end px-6">
                {renderIconNextToSeachBar &&
                  renderIconNextToSeachBar(
                    navigationOptions[currentIndex].name
                  )}
                <SearchBar
                  value={searchBar.value}
                  onChange={searchBar.action}
                  placeholder={searchBar.label}
                />
              </div>
            )}
          </div>
        </div>

        <div
          className={classNames(
            'divide-y divide-gray-200 p-6',
            childrenClassName
          )}
        >
          {childrenWithProps}
        </div>
      </div>
    </div>
  );
};

export default TabComponent;
