import classNames from 'classnames';
import type { VFC } from 'react';

interface Props {
  className?: string;
}
const LargeQuoteIcon: VFC<Props> = ({ className }) => {
  const svgClassName = classNames(className, 'fill-[#FFA300] inline-block');
  return (
    <span className={svgClassName}>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1080 1080"
      >
        <path
          className="st0"
          d="M548.16,325.08l4.86-89.86C-86.94,340.87,6.56,894.61,245.18,894.61c287.19,0,216.76-416.52-39.24-311.84
	c0.05-0.08,0.1-0.16,0.16-0.24C274.93,385.8,437.65,343.3,548.16,325.08z"
        />
        <path
          className="st0"
          d="M1023.57,325.08l4.86-89.86C388.47,340.87,481.97,894.61,720.59,894.61c287.19,0,216.76-416.52-39.24-311.84
	c0.05-0.08,0.1-0.16,0.16-0.24C750.34,385.8,913.07,343.3,1023.57,325.08z"
        />
      </svg>
    </span>
  );
};
export default LargeQuoteIcon;
