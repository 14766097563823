import type { VFC } from 'react';

interface Props {
  className?: string;
}

const MailIcon: VFC<Props> = ({ className }) => (
  <svg
    width="21"
    height="15"
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.522305 2.355L10.5186 7.3525L20.5148 2.355C20.4778 1.71806 20.1987 1.11936 19.7346 0.681534C19.2705 0.243707 18.6566 -0.000116981 18.0186 4.21043e-08H3.01855C2.38054 -0.000116981 1.76661 0.243707 1.30253 0.681534C0.83844 1.11936 0.55931 1.71806 0.522305 2.355Z"
      fill="currentColor"
    />
    <path
      d="M20.5186 5.1475L10.5186 10.1475L0.518555 5.1475V12.5C0.518555 13.163 0.781947 13.7989 1.25079 14.2678C1.71963 14.7366 2.35551 15 3.01855 15H18.0186C18.6816 15 19.3175 14.7366 19.7863 14.2678C20.2552 13.7989 20.5186 13.163 20.5186 12.5V5.1475Z"
      fill="currentColor"
    />
  </svg>
);

export default MailIcon;
