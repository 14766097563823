import { formatApolloError } from '@headrace/utils';
import React, { useCallback, useMemo } from 'react';

import type { RoleCollaboratorsQuery } from '@/graphql/generated';
import { useRoleCollaboratorsQuery } from '@/graphql/generated';

interface RoleCollaboratorsContextData {
  data?: RoleCollaboratorsQuery['roleCollaborators'];
  loading: boolean;
  error?: string | null;
  refetch: () => Promise<void>;
}

interface RoleCollaboratorsProviderProps {
  roleId: string;
}

const RoleCollaboratorsContext =
  React.createContext<RoleCollaboratorsContextData>({
    loading: false,
    refetch: () => Promise.resolve(),
  });

const RoleCollaboratorsProvider: React.FC<RoleCollaboratorsProviderProps> = ({
  children,
  roleId,
}) => {
  const {
    data: roleCollaboratorsData,
    loading: roleCollaboratorsLoading,
    error: roleCollaboratorsError,
    refetch: roleCollaboratorsRefetch,
  } = useRoleCollaboratorsQuery({
    variables: { roleId },
  });

  const refetchCollaborators = useCallback(async (): Promise<void> => {
    await roleCollaboratorsRefetch();
  }, [roleCollaboratorsRefetch]);

  const roleCollaboratorsContextState: RoleCollaboratorsContextData = useMemo(
    () => ({
      data: roleCollaboratorsData?.roleCollaborators,
      loading: roleCollaboratorsLoading,
      error: roleCollaboratorsError
        ? formatApolloError(roleCollaboratorsError)
        : null,
      refetch: refetchCollaborators,
    }),
    [
      refetchCollaborators,
      roleCollaboratorsData?.roleCollaborators,
      roleCollaboratorsError,
      roleCollaboratorsLoading,
    ]
  );

  return (
    <RoleCollaboratorsContext.Provider value={roleCollaboratorsContextState}>
      {children}
    </RoleCollaboratorsContext.Provider>
  );
};

export const useRoleCollaborators = (): RoleCollaboratorsContextData =>
  React.useContext(RoleCollaboratorsContext);

export default RoleCollaboratorsProvider;
