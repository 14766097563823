import type { FeeScheduleTypeEnum } from '@headrace/types';
import { MarketplaceVisibilityEnum } from '@headrace/types';
import { CalculatorIcon, ChatIcon, ClockIcon } from '@heroicons/react/outline';
import { BadgeCheckIcon, InformationCircleIcon } from '@heroicons/react/solid';
import { differenceInHours, formatDistanceToNow } from 'date-fns';
import type { VFC } from 'react';
import { useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import Button from '../Button';
import Card from '../Card';
import {
  InviteSearchIcon,
  NounMoneyIcon,
  PublicSearchIcon,
} from '../CustomIcons';
import CalculatorModal from './CalculatorModal';

export interface RoleDossierProductSectionProps {
  name: string;
  marketplaceVisibility?: string | null;
  amount?: string;
  fee: number;
  feeType: FeeScheduleTypeEnum;
  salaryMin: number;
  salaryMax: number;
  primaryButton: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
  };
  secondaryButton: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
  };
  createdAt?: Date;
}

const ProductItem: VFC<RoleDossierProductSectionProps> = ({
  name,
  marketplaceVisibility,
  amount,
  primaryButton,
  secondaryButton,
  createdAt,
  fee,
  salaryMin,
  salaryMax,
  feeType,
}) => {
  const [openCalculatorModal, setOpenCalculatorModal] = useState(false);

  const getDisplayDate = useMemo(() => {
    if (!createdAt) return '';
    const distance = differenceInHours(new Date(), new Date(createdAt));
    if (distance > 24)
      return `Posted ${formatDistanceToNow(new Date(createdAt), {
        addSuffix: true,
      })}`;

    return 'Posted 1 day ago';
  }, [createdAt]);

  const visibilityTypeSection = useMemo(() => {
    let searchName = '';
    let icon: JSX.Element | null = null;
    let dataTooltip = '';
    switch (marketplaceVisibility) {
      case MarketplaceVisibilityEnum.INVITE:
        searchName = 'Invite-only role';
        icon = <InviteSearchIcon className="w-8 h-8" />;
        dataTooltip =
          'Invite-only is a marketplace role open <br/> to multiple recruiters with a <br/> standard placement fee';
        break;
      case MarketplaceVisibilityEnum.PUBLIC:
        searchName = 'Public role';
        icon = <PublicSearchIcon className="w-8 h-8" />;
        dataTooltip =
          'Public roles are open to the <br/>marketplace and can be priced as a <br/>fixed fee or a % of first year salary';

        break;
      default:
        break;
    }
    return (
      <div className="flex gap-2 items-center py-4">
        {icon}
        <span className="font-medium text-gray-500 text-lg">{searchName}</span>
        <div data-for="info" data-tip={dataTooltip}>
          <InformationCircleIcon className="h-4 w-4 text-gray-400" />
        </div>
        <ReactTooltip
          id="info"
          place="top"
          effect="solid"
          html
          arrowColor="transparent"
          className="!opacity-100 !bg-headraceBlack-800 !px-[12px] !text-xs !font-normal !text-center"
        />
      </div>
    );
  }, [marketplaceVisibility]);

  return (
    <>
      <Card className="flex flex-col p-6 divide-y h-fit">
        <div className="flex gap-4 pb-4">
          <NounMoneyIcon className="h-16 w-16 text-headraceYellow-700" />
          <div className="flex flex-col gap-1">
            <span className="text-4xl font-bold text-gray-700 leading-7">
              {amount}
            </span>
            <div className="flex gap-1">
              <span className="text-2xl font-medium text-gray-400">{name}</span>
              <Button
                buttonType="link"
                className="!m-0 outline-none"
                onClick={(): void => {
                  setOpenCalculatorModal(true);
                }}
              >
                <CalculatorIcon className="w-6 h-6 text-blue-500" />
              </Button>
            </div>
          </div>
        </div>
        {visibilityTypeSection}
        <div className="flex flex-col gap-2 h-full pt-4">
          <div className="flex flex-col gap-2 mb-2">
            <div className="flex gap-3">
              <BadgeCheckIcon className="h-5 w-5 text-green-500" />
              <span className="text-sm font-base text-gray-500">
                HeadRace verified employer
              </span>
            </div>
            <div className="flex gap-3">
              <ClockIcon className="h-5 w-5 text-gray-400" />
              <span className="text-sm font-base text-gray-500">
                {getDisplayDate}
              </span>
            </div>
            <div className="flex gap-3">
              <ChatIcon className="h-5 w-5 text-headraceYellow-700" />
              <span className="text-sm font-base text-gray-500">
                Candidate feedback shared
              </span>
            </div>
          </div>
          <Button
            onClick={primaryButton.onClick}
            disabled={primaryButton.disabled}
          >
            {primaryButton.label}
          </Button>
          <Button
            buttonType="secondary"
            onClick={secondaryButton.onClick}
            disabled={secondaryButton.disabled}
          >
            {secondaryButton.label}
          </Button>
        </div>
      </Card>
      <CalculatorModal
        open={openCalculatorModal}
        onClose={(): void => {
          setOpenCalculatorModal(false);
        }}
        marketplaceVisibility={marketplaceVisibility}
        fee={fee}
        salary={(salaryMin + salaryMax) / 2}
        feeType={feeType}
      />
    </>
  );
};
export default ProductItem;
