import { useRouter } from 'next/router';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import type { NavigationItem } from '.';

interface HeaderTabsContextData {
  tabs: NavigationItem[];
  setTabs: (props: NavigationItem[]) => void;
  rightContent: JSX.Element | null;
  setRightContent: (content: JSX.Element | null) => void;
}

const HeaderTabsContext = React.createContext<HeaderTabsContextData>({
  tabs: [],
  setTabs: () => {},
  rightContent: null,
  setRightContent: () => {},
});

const HeaderTabsProvider: React.FC = ({ children }) => {
  const { pathname } = useRouter();
  const [tabs, setTabs] = useState<NavigationItem[]>([]);
  const [rightContent, setRightContent] = useState<JSX.Element | null>(null);

  const lastVisitedPage = useRef<string>(pathname);
  const lastVisitedPageRightContent = useRef<string>(pathname);

  const customSetTabs = useCallback(
    (newState: NavigationItem[]): void => {
      lastVisitedPage.current = pathname;
      setTabs(newState);
    },
    [pathname]
  );

  const customSetRightContent = useCallback(
    (newState: JSX.Element | null): void => {
      lastVisitedPageRightContent.current = pathname;
      setRightContent(newState);
    },
    [pathname]
  );

  const contextState: HeaderTabsContextData = useMemo(
    () => ({
      tabs,
      setTabs: customSetTabs,
      rightContent,
      setRightContent: customSetRightContent,
    }),
    [customSetRightContent, customSetTabs, rightContent, tabs]
  );

  useEffect(() => {
    if (pathname !== lastVisitedPage.current) {
      lastVisitedPage.current = pathname;
      setTabs([]);
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname !== lastVisitedPageRightContent.current) {
      lastVisitedPageRightContent.current = pathname;
      setRightContent(null);
    }
  }, [pathname]);

  return (
    <HeaderTabsContext.Provider value={contextState}>
      {children}
    </HeaderTabsContext.Provider>
  );
};

export const useHeaderTabs = (): HeaderTabsContextData =>
  React.useContext(HeaderTabsContext);

export default HeaderTabsProvider;
