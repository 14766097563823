import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import { Children, cloneElement, isValidElement, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

interface ChildrenProps extends React.HTMLAttributes<HTMLDivElement> {
  closeMenu: () => void;
}

interface OverlayDialogProps {
  Icon:
    | ((props: React.ComponentProps<'svg'>) => JSX.Element)
    | React.VFC<{ className: string }>;
  iconClassName?: string;
  iconContainerClassName?: string;
  children: React.ReactElement<ChildrenProps>;
  panelClassName?: string;
  stylePanel?: React.CSSProperties;
  portalElement?: string;
  withPortal?: boolean;
}

/* OverlayDialog
This element will render `children` only when the popover is Open
When it closes the `children` components will be unmounted
we pass a `close` function to all children to close the popover
*/
const OverlayDialog: React.FC<OverlayDialogProps> = ({
  Icon,
  iconClassName,
  iconContainerClassName,
  panelClassName,
  children,
  stylePanel,
  portalElement = 'body',
  withPortal = false,
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
  });

  let bodyElement: HTMLElement | null = null;
  if (typeof document !== 'undefined') {
    // will run in client's browser only
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bodyElement = document.querySelector(portalElement)!;
  }

  return (
    <Popover as="div" className="relative inline-block text-left">
      {({ open, close }): JSX.Element => {
        const childrenWithProps = Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child, {
              closeMenu: close,
            });
          }
          return child;
        });
        return (
          <>
            <Popover.Button
              as="div"
              ref={setReferenceElement}
              className={classNames(
                'inline-flex items-center bg-transparent p-2 text-sm font-medium outline-none focus:outline-none',
                {
                  'text-gray-500': open,
                  'text-gray-300': !open,
                },
                iconContainerClassName
              )}
            >
              <span className="sr-only">Open options menu</span>
              <Icon className={classNames('h-5 w-5', iconClassName)} />
            </Popover.Button>
            {withPortal ? (
              bodyElement &&
              ReactDOM.createPortal(
                <Popover.Panel
                  style={stylePanel || styles.popper}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...attributes.popper}
                  ref={setPopperElement}
                  // eslint-disable-next-line react/jsx-props-no-spreading

                  className={classNames(
                    'z-50 absolute right-0 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                    panelClassName
                  )}
                >
                  {childrenWithProps}
                </Popover.Panel>,
                bodyElement
              )
            ) : (
              <Popover.Panel
                // eslint-disable-next-line react/jsx-props-no-spreading
                ref={setPopperElement}
                style={stylePanel || styles.popper}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...attributes.popper}
                className={classNames(
                  'z-[1000] absolute right-0 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                  panelClassName
                )}
              >
                {childrenWithProps}
              </Popover.Panel>
            )}
          </>
        );
      }}
    </Popover>
  );
};

export default OverlayDialog;
