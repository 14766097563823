import type { VFC } from 'react';

interface Props {
  className?: string;
}

const CandidatesIcon: VFC<Props> = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 737 738"
    strokeWidth={2}
    stroke="currentColor"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M471.739 422.227C457.283 411.501 441.443 402.566 424.545 395.705C446.767 379.039 463.181 355.803 471.463 329.289C479.746 302.776 479.476 274.328 470.692 247.976C461.908 221.624 445.055 198.704 422.521 182.463C399.987 166.221 372.914 157.481 345.137 157.481C317.359 157.481 290.286 166.221 267.752 182.463C245.218 198.704 228.366 221.624 219.582 247.976C210.798 274.328 210.528 302.776 218.81 329.289C227.092 355.803 243.507 379.039 265.729 395.705C221.043 413.847 183.762 446.497 159.886 488.4C158.407 490.779 157.421 493.431 156.987 496.199C156.553 498.966 156.68 501.793 157.36 504.51C158.04 507.228 159.26 509.781 160.946 512.017C162.633 514.254 164.752 516.129 167.177 517.53C169.603 518.932 172.285 519.831 175.066 520.175C177.846 520.519 180.667 520.3 183.361 519.532C186.055 518.764 188.567 517.462 190.747 515.704C192.928 513.946 194.733 511.767 196.055 509.297C227.609 454.756 283.334 422.227 345.137 422.227C378.616 422.227 410.312 431.778 437.348 449.199C447.962 435.758 448.35 439.248 471.739 422.227ZM254.584 289.88C254.584 271.97 259.895 254.463 269.845 239.572C279.795 224.68 293.937 213.074 310.484 206.22C327.03 199.366 345.237 197.573 362.803 201.067C380.368 204.561 396.503 213.185 409.167 225.85C421.831 238.514 430.456 254.649 433.95 272.214C437.444 289.78 435.651 307.987 428.797 324.533C421.943 341.08 410.337 355.222 395.445 365.172C380.554 375.122 363.046 380.433 345.137 380.433C321.129 380.406 298.113 370.856 281.137 353.88C264.161 336.904 254.611 313.888 254.584 289.88Z"
      fill="currentColor"
    />
    <path
      d="M543.513 682.341C619.799 682.341 681.639 620.501 681.639 544.215C681.639 467.929 619.799 406.089 543.513 406.089C467.227 406.089 405.387 467.929 405.387 544.215C405.387 620.501 467.227 682.341 543.513 682.341Z"
      stroke="currentColor"
      strokeWidth="50"
      strokeLinejoin="round"
    />
    <path
      d="M642.818 643.519L711.759 712.46"
      stroke="currentColor"
      strokeWidth="50"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M339.461 652.756C166.124 652.756 25.6123 512.244 25.6123 338.907C25.6123 165.57 166.124 25.0583 339.461 25.0583C512.798 25.0583 653.31 165.57 653.31 338.907"
      stroke="currentColor "
      strokeWidth="50"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CandidatesIcon;
