import 'react-datepicker/dist/react-datepicker.css';

import { CalendarIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useRef } from 'react';
import ReactDatePicker from 'react-datepicker';

interface DatePickerProps {
  name?: string;
  label?: string;
  endDate?: Date | null;
  startDate?: Date | null;
  value?: Date | null;
  onChange: (value: Date | [Date | null, Date | null] | null) => void;
  onBlur?: () => void;
  description?: string;
  disabled?: boolean;
  minDate?: Date;
  selectsRange?: boolean;
  isClearable?: boolean;
  placeholder: string;
}

const DatePicker: React.VFC<DatePickerProps> = (props) => {
  const {
    endDate,
    startDate,
    name,
    label,
    value,
    onChange,
    onBlur,
    description,
    disabled,
    minDate,
    selectsRange,
    isClearable,
    placeholder,
  } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const className = classNames(
    'focus:ring-transparent focus:border-gray-300 w-full text-sm border border-gray-300 block rounded-none rounded-l-md flex-1 text-gray-500',
    {
      'opacity-50': disabled,
      'cursor-not-allowed': disabled,
    }
  );

  return (
    <div className="flex flex-col gap-1">
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="flex rounded-md shadow-sm">
        <div className="relative w-full">
          <ReactDatePicker
            placeholderText={placeholder}
            endDate={endDate || null}
            startDate={startDate || null}
            selectsRange={selectsRange}
            name={name}
            id={name}
            className={className}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            selected={value || null}
            minDate={minDate || null}
            autoComplete="off"
            isClearable={isClearable}
            ref={(datePickerRef): void => {
              // Missing "input" prop in react-datepicker library
              const customDatePickerRef =
                datePickerRef as typeof datePickerRef & {
                  input: HTMLInputElement;
                };
              if (inputRef && customDatePickerRef?.input) {
                inputRef.current = customDatePickerRef.input;
              }
            }}
          />
        </div>
        <span className="inline-flex items-center py-[9px] pr-[17px] pl-[15px] rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
          <CalendarIcon
            className="h-4 w-4 text-headraceBlack-700 stroke-2"
            aria-hidden="true"
          />
        </span>
      </div>
      {description && (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      )}
    </div>
  );
};

export default DatePicker;
