import { InboxIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { RefObject } from 'react';
import { useCallback, useEffect, useState } from 'react';

import Card from '../Card';
import SearchBar from '../SearchBar';
import SimpleEmpty from '../SimpleEmpty';
import Pagination from './Pagination';

export interface PaginatedListProps<D> {
  data: D[];
  formatData: (data: D[], curentPage: number) => JSX.Element[] | JSX.Element;
  onSearch?: (searchValue: string, dataItem: D) => boolean;
  searchPlaceholder?: string;
  entriesPerPage?: number;
  className?: string;
  sameHeightItems?: boolean;
  scrollToTop?: boolean;
  scrollIntoOnPageChange?: RefObject<HTMLElement>;
  emptyText?: string;
  filterButton?: JSX.Element;
  gap?: number | string;
  containerClassName?: string;
  paginationClassName?: string;
  dontJumpToFirstPage?: boolean;
  scrollRef?: RefObject<HTMLDivElement>;
}

const PaginatedList = <D extends object>(
  props: PaginatedListProps<D>
): JSX.Element => {
  const {
    className,
    data,
    formatData,
    entriesPerPage = 10,
    onSearch,
    searchPlaceholder,
    sameHeightItems,
    scrollToTop,
    emptyText,
    filterButton,
    gap = 6,
    containerClassName,
    scrollIntoOnPageChange,
    paginationClassName,
    dontJumpToFirstPage = false,
    scrollRef,
  } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const [paginatedData, setPaginatedData] = useState<D[]>([]);
  const [filteredData, setFilteredData] = useState<D[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(
    Math.floor(entriesPerPage ? Math.ceil(data.length / entriesPerPage) : 1)
  );

  const changeToPage = useCallback(
    (index: number): void => {
      setCurrentPage(index);
      if (scrollRef)
        scrollRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
    },
    [scrollRef]
  );

  const calculateTotalPages = useCallback(
    (dataLength: number): void => {
      if (!entriesPerPage) return;
      setTotalPages(Math.ceil(dataLength / entriesPerPage));
    },
    [entriesPerPage]
  );

  useEffect(() => {
    if (dontJumpToFirstPage) return;
    changeToPage(1);
  }, [changeToPage, data, dontJumpToFirstPage]);

  useEffect(() => {
    if (scrollToTop) window.scrollTo(0, 0);
    else scrollIntoOnPageChange?.current?.scrollIntoView({ block: 'center' });
  }, [currentPage, scrollIntoOnPageChange, scrollToTop]);

  useEffect(() => {
    const start = (currentPage - 1) * entriesPerPage;
    const end = currentPage * entriesPerPage;
    setPaginatedData(filteredData.slice(start, end));
  }, [currentPage, entriesPerPage, setPaginatedData, filteredData]);

  useEffect(() => {
    if (searchValue === '') {
      calculateTotalPages(data.length);
      setFilteredData(data);
      return;
    }
    let filtered = data;
    if (searchValue !== '' && onSearch) {
      filtered = filtered.filter((dataItem) => onSearch(searchValue, dataItem));
    }
    calculateTotalPages(filtered.length);
    setFilteredData(filtered);
  }, [calculateTotalPages, data, onSearch, searchValue]);

  return (
    <div className={classNames(className)}>
      {(onSearch || filterButton) && (
        <div className="flex items-center gap-4 h-[38px] w-full mb-6">
          {onSearch && (
            <SearchBar
              className="flex-auto"
              onChange={(value): void => {
                setSearchValue(value);
                changeToPage(1);
              }}
              value={searchValue}
              placeholder={searchPlaceholder}
            />
          )}
          {filterButton}
        </div>
      )}
      <div
        className={classNames(
          `gap-${typeof gap === 'number' ? gap : `[${gap}]`}`,
          {
            'grid grid-cols-1 auto-rows-fr': sameHeightItems,
            'flex flex-col': !sameHeightItems,
          },
          containerClassName
        )}
      >
        {paginatedData.length ? (
          formatData(paginatedData, currentPage)
        ) : (
          <Card className="p-6">
            <SimpleEmpty
              title={emptyText ?? 'No data found'}
              icon={<InboxIcon className="w-14" />}
            />
          </Card>
        )}
      </div>
      {entriesPerPage && totalPages > 1 ? (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          changeToPage={changeToPage}
          paginationClassName={paginationClassName}
        />
      ) : null}
    </div>
  );
};

export default PaginatedList;
