import type { VFC } from 'react';

import Button from './Button';

interface Props {
  text: React.ReactNode;
  action?: {
    text: string;
    onClick: () => void;
  };
}

const LowerBanner: VFC<Props> = ({ text, action }) => (
  <div className="fixed md:left-[92px] left-0 right-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white py-4 ring-1 ring-gray-900/10 md:flex-row md:items-center px-8">
    <p className="max-w-4xl text-sm leading-6 text-headraceBlack-700">{text}</p>
    {action && (
      <div className="flex flex-none items-center gap-x-5">
        <Button size="sm" onClick={action.onClick}>
          {action.text}
        </Button>
      </div>
    )}
  </div>
);

export default LowerBanner;
