import classNames from 'classnames';
import type { FC } from 'react';

interface Props {
  className?: string;
}

const Card: FC<Props> = ({ className, children }) => {
  const boxClassName = classNames(
    className,
    'bg-white shadow sm:rounded-lg h-full w-full'
  );
  return <div className={boxClassName}>{children}</div>;
};

export default Card;
