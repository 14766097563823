import { FolderAddIcon } from '@heroicons/react/outline';

interface Props {
  emptyTitle: string;
  emptySubtitle?: string;
}

const EmptyState: React.VFC<Props> = ({ emptyTitle, emptySubtitle }) => (
  <div className="text-center items-center text-gray-500">
    <FolderAddIcon className="mx-auto h-12 w-12 stroke-1" />
    <h3 className="mt-1">{emptyTitle}</h3>
    {emptySubtitle && <p className="text-gray-400">{emptySubtitle}</p>}
  </div>
);

export default EmptyState;
