/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { ImageProps } from 'next/image';
import Image from 'next/image';
import type { VFC } from 'react';
import { useEffect, useState } from 'react';

interface Props extends ImageProps {
  fallbackSrc: string;
}

const ImageWithFallback: VFC<Props> = (props) => {
  const { src, fallbackSrc, ...rest } = props;
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <Image
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      src={imgSrc}
      onError={(): void => {
        setImgSrc(fallbackSrc);
      }}
    />
  );
};

export default ImageWithFallback;
