const PagePanel: React.FC<{ className?: string }> = ({
  children,
  className = '',
}) => (
  <div
    className={`bg-white rounded-lg shadow text-headraceBlack-700 min-h-[200px] ${className}`}
  >
    {children}
  </div>
);

export default PagePanel;
