import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useState } from 'react';

import type { AccessData } from './AccessModal';
import SimpleContent from './SimpleContent';
import type { SimpleNavigation } from './SimpleHeader';
import SimpleHeader from './SimpleHeader';
import SimpleHideableSidebar from './SimpleHideableSidebar';

const AccessModal = dynamic(() => import('./AccessModal'));

export interface SendAccesEmailState {
  loading: boolean;
  successful: boolean;
}

export interface HandleHeaderEvents {
  onGetAccessClicked: () => void;
  onLoginClicked: () => void;
}

interface SimpleLayoutProps {
  navigation: SimpleNavigation[];
  loginPathEmployer?: string;
  loginPathRecruiter?: string;
  accessAction: (values: AccessData) => void;
  onCloseGetAccess: () => void;
  sendAccesEmailState: SendAccesEmailState;
  handleEvents: HandleHeaderEvents;
  showLoginAndGetAccessButton?: boolean;
  showHamburger?: boolean;
}

const SimpleLayout: React.FC<SimpleLayoutProps> = (props) => {
  const {
    children,
    navigation,
    loginPathRecruiter,
    loginPathEmployer,
    accessAction,
    onCloseGetAccess,
    sendAccesEmailState,
    handleEvents,
    showLoginAndGetAccessButton,
    showHamburger,
  } = props;

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleOpenSidebarClick = (): void => {
    setSidebarOpen(true);
  };

  const [openAccess, setOpenAccess] = useState(false);

  const closeAccess = (): void => {
    onCloseGetAccess();
    setOpenAccess(false);
  };

  return (
    <div className="flex flex-row h-full min-h-screen">
      <Head>
        <title>
          {' '}
          HeadRace | Web App {loginPathRecruiter
            ? 'Recruiter'
            : 'Employer'}{' '}
          Page{' '}
        </title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content="HeadRace" />
        <meta name="description" content="HeadRace" key="desc" />
        <meta property="og:description" content="HeadRace" />
        <link rel="icon" href="/headrace_icon.svg" />
        <meta property="og:image" content="/headrace_icon.svg" />
      </Head>
      <SimpleHideableSidebar
        logo="/headrace_icon.svg"
        navigation={navigation}
        open={sidebarOpen}
        setOpen={setSidebarOpen}
      />
      <AccessModal
        open={openAccess}
        onClose={closeAccess}
        onSubmit={accessAction}
        sendAccesEmailState={sendAccesEmailState}
      />
      <div className="flex flex-col flex-1">
        <SimpleHeader
          onOpenSidebarClick={handleOpenSidebarClick}
          navigation={navigation}
          loginPathEmployer={loginPathEmployer}
          loginPathRecruiter={loginPathRecruiter}
          setOpenAccess={setOpenAccess}
          handleEvents={handleEvents}
          showLoginAndGetAccessButton={showLoginAndGetAccessButton}
          showHamburger={showHamburger}
        />
        <main>
          <SimpleContent>{children}</SimpleContent>
        </main>
      </div>
    </div>
  );
};

export default SimpleLayout;
