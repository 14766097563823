import classNames from 'classnames';
import type { VFC } from 'react';

import type { RichTextEditorProps } from '../forms/fields/formik/RichTextEditor/OnlyReadRichTextEditor';
import OnlyReadRichTextEditor from '../forms/fields/formik/RichTextEditor/OnlyReadRichTextEditor';
import SimpleModal from '../SimpleModal';

interface RichTextModalProps extends RichTextEditorProps {
  open: boolean;
  onClose: () => void;
}

const RichTextModal: VFC<RichTextModalProps> = ({
  name,
  json,
  value,
  className,
  open,
  onClose,
}) => (
  <SimpleModal
    open={open}
    onClose={onClose}
    className="lg:w-[80%] xl:w-[50%] 2xl:w-[50%] md:w-[80%] sm:w-[80%] !p-8 max-h-[70%]"
  >
    <div className="text-left overflow-y-auto">
      <OnlyReadRichTextEditor
        name={name}
        json={json}
        value={value}
        className={classNames(className)}
        limitHeight={false}
      />
    </div>
  </SimpleModal>
);

export default RichTextModal;
