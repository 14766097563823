interface Props {
  className?: string;
}

const SplitSearchIcon: React.VFC<Props> = ({
  className = 'h-full w-full text-gray-300',
}) => (
  <svg
    className={className}
    viewBox="0 0 33 33"
    fill="none"
    preserveAspectRatio="none"
  >
    <path
      d="M16.6325 5.84131C16.2931 5.84131 15.9675 5.9762 15.7274 6.21622C15.4874 6.45632 15.3525 6.78184 15.3525 7.12131V25.0413C15.3525 25.4986 15.5965 25.9212 15.9925 26.1498C16.3886 26.3785 16.8765 26.3785 17.2725 26.1498C17.6686 25.9212 17.9125 25.4986 17.9125 25.0413V7.12131C17.9125 6.78184 17.7776 6.4563 17.5376 6.21622C17.2975 5.97622 16.972 5.84131 16.6325 5.84131Z"
      fill="#FFA300"
    />
    <path
      d="M12.0817 12.0716C12.4765 11.8599 12.7318 11.4573 12.7551 11.0099C12.7783 10.5627 12.5661 10.1358 12.1954 9.88431C11.8248 9.63285 11.3495 9.59349 10.9426 9.78035C9.37269 10.5629 8.15138 11.9032 7.51805 13.539C6.88461 15.1746 6.88461 16.9881 7.51805 18.6236C8.15149 20.2593 9.37274 21.5996 10.9426 22.3822C11.3496 22.5691 11.8248 22.5297 12.1954 22.2783C12.5661 22.0268 12.7783 21.5999 12.7551 21.1526C12.7318 20.7053 12.4765 20.3026 12.0817 20.091C11.0838 19.5926 10.3078 18.7399 9.90528 17.6997C9.50266 16.6595 9.50266 15.5065 9.90528 14.4664C10.3078 13.4261 11.0838 12.5735 12.0817 12.0751"
      fill="#FFA300"
    />
    <path
      d="M21.1823 20.091C20.8676 20.2355 20.6249 20.5015 20.5095 20.8281C20.3942 21.1546 20.4162 21.5139 20.5704 21.824C20.7245 22.1341 20.9978 22.3685 21.3278 22.4737C21.6578 22.5789 22.0162 22.5459 22.3214 22.3822C23.8913 21.5997 25.1126 20.2594 25.746 18.6236C26.3794 16.9879 26.3794 15.1745 25.746 13.539C25.1125 11.9033 23.8913 10.563 22.3214 9.78036C22.0162 9.61671 21.6578 9.58369 21.3278 9.6889C20.9978 9.79411 20.7245 10.0285 20.5704 10.3386C20.4162 10.6487 20.3942 11.008 20.5095 11.3345C20.6249 11.6611 20.8676 11.927 21.1823 12.0716C22.1802 12.57 22.9562 13.4227 23.3587 14.4629C23.7613 15.5031 23.7613 16.6561 23.3587 17.6962C22.9562 18.7365 22.1802 19.5891 21.1823 20.0875"
      fill="#FFA300"
    />
  </svg>
);

export default SplitSearchIcon;
