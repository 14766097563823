import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useCallback } from 'react';

import QuestionMarkIcon from '../QuestionMarkIcon';
import type { FilterProps } from './TableFilter';
import TableFilter from './TableFilter';
import type { Column, MinTableData } from './TableTd';

export interface SortProps {
  columnName: string;
  type: 'up' | 'down' | 'none';
}

interface Props<D extends MinTableData> {
  columns: Column<D>[];
  stickyHeader?: boolean;
  filterState: FilterProps[];
  selectColumnToFilter: (newFilterState: FilterProps) => void;
  columnSort: SortProps[];
  selectColumnToSort: (columndId: string) => void;
}

const SortIcon: React.VFC<{ type: SortProps['type']; onClick: () => void }> = ({
  type,
  onClick,
}) => (
  <button type="button" onClick={onClick}>
    <ChevronUpIcon
      className={classNames('w-2.5 h-2.5 stroke-[3px] -mb-1', {
        'stroke-headraceBlack-800': type === 'up',
        'stroke-gray-400': type !== 'up',
      })}
    />
    <ChevronDownIcon
      className={classNames('w-2.5 h-2.5 stroke-[3px]', {
        'stroke-headraceBlack-800': type === 'down',
        'stroke-gray-400': type !== 'down',
      })}
    />
  </button>
);

const TableHeader = <D extends MinTableData>({
  columns,
  stickyHeader = false,
  filterState,
  selectColumnToFilter,
  columnSort,
  selectColumnToSort,
}: Props<D>): JSX.Element => {
  const filterIcon = useCallback(
    (column: Column<D>) => {
      const selectedColumnFilter = filterState.find(
        ({ columnName }) => columnName === column.header
      );
      if (!selectedColumnFilter) return null;
      return (
        <TableFilter
          column={column}
          selectedColumnFilter={selectedColumnFilter}
          selectColumnToFilter={selectColumnToFilter}
        />
      );
    },
    [filterState, selectColumnToFilter]
  );
  const sortIcon = useCallback(
    (column: Column<D>) => {
      const selectedColumnSort = columnSort.find(
        (col) => col.columnName === column.header
      );
      if (!selectedColumnSort) return null;
      const onClick = (): void =>
        selectColumnToSort(selectedColumnSort.columnName);
      return <SortIcon type={selectedColumnSort.type} onClick={onClick} />;
    },
    [columnSort, selectColumnToSort]
  );

  const helperIcon = useCallback((column: Column<D>) => {
    if (!column.helper) return null;
    return (
      <div className="text-white text-xs font-normal">
        <QuestionMarkIcon
          tooltip={column.helper}
          fieldName={`table-header-${column.header}`}
          forInput={false}
          customTooltipStyles="w-48 !p-2.5 text-left !bg-headraceBlack-800"
          iconClassName="w-3.5 h-3.5 font-normal"
        />
      </div>
    );
  }, []);

  return (
    <thead>
      <tr
        className={classNames('bg-white shadow-sm', {
          'sticky top-0': stickyHeader,
        })}
      >
        {columns.map((column) => (
          <th
            key={column.header}
            className={classNames(
              'px-5 text-xs rounded-t-lg',
              {
                'sticky top-0': stickyHeader,
              },
              column.headerClassName
            )}
          >
            <div
              className={classNames('flex items-center gap-2', {
                'justify-center': column.justifyHeader === 'center',
                'justify-right': column.justifyHeader === 'right',
              })}
            >
              <p className="py-3 uppercase font-base text-gray-500">
                {column.header}
              </p>
              {!!column.helper && helperIcon(column)}
              {!!column.filterOptions && filterIcon(column)}
              {!!column.sortFunction && sortIcon(column)}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
