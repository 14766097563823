import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { useRef } from 'react';
import type { MultiValue, SingleValue, StylesConfig } from 'react-select';
import ReactTooltip from 'react-tooltip';

import type { OptionsProps, SelectAction } from '../BasicSelect';
import BasicSelect from '../BasicSelect';
import ErrorText from '../ErrorText';
import useFocusOnError from './errorHooks';
import InputErrorIcon from './ErrorIcon';

interface SelectProps {
  label?: string;
  description?: string;
  name: string;
  placeholder?: string;
  helper?: string | JSX.Element;
  disabled?: boolean;
  validatioError?: string;
  maxMenuHeight?: number;
  options: OptionsProps[];
  isSearchable?: boolean;
  onChange?: (value: string) => void;
  className?: string;
  containerClassName?: string;
  actions?: SelectAction[];
  optionsMessage?: boolean;
  customNoOptionsMessage?: string;
  basicSelectCustomStyles?: StylesConfig<OptionsProps>;
  errorClassName?: string;
  info?: string;
  infoIcon?: JSX.Element;
}

const SingleSelect: React.VFC<SelectProps> = (props) => {
  const {
    label,
    description,
    name,
    placeholder = '',
    helper,
    disabled = false,
    options,
    maxMenuHeight = 200,
    isSearchable = false,
    onChange,
    actions,
    className,
    containerClassName,
    optionsMessage = true,
    customNoOptionsMessage = 'No options',
    basicSelectCustomStyles = {},
    errorClassName,
    info,
    infoIcon = <QuestionMarkCircleIcon className="w-4 h-4 text-gray-400" />,
  } = props;
  const [field, meta] = useField<string>(name);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { setFieldValue } = useFormikContext();
  const inputContainerClassName = classNames(
    'flex flex-col gap-1',
    containerClassName
  );
  const inputClassName = classNames(
    className,
    'w-full h-[38px]',
    'sm:text-sm',
    {
      block: !!helper,
      'rounded-md': !helper,
      'rounded-none': !!helper,
      'rounded-r-md': !!helper,
      'flex-1': !!helper,
    },
    {
      'opacity-50': disabled,
      'cursor-not-allowed': disabled,
    }
  );
  const getValueSelect = (value: string): OptionsProps => {
    const valueSelect = options.find((item) => item.value === value);
    return valueSelect || { value: '', label: '' };
  };
  const onChangeSelect = (
    newValue: MultiValue<OptionsProps> | SingleValue<OptionsProps>
  ): void => {
    const newSelectValue = newValue as OptionsProps;
    if (newSelectValue) {
      if (onChange) {
        setFieldValue(name, newSelectValue.value);
        onChange(newSelectValue.value);
      }
      setFieldValue(name, newSelectValue.value);
    }
  };
  useFocusOnError(inputRef, name);

  return (
    <div>
      <div className={inputContainerClassName}>
        {label && (
          <label
            htmlFor={field.name}
            className="flex gap-2 items-center text-sm font-medium text-headraceBlack-800"
          >
            {label}
            {info && (
              <div>
                <div data-for={`${field.name}-info`} data-tip={info}>
                  {infoIcon}
                </div>
                <ReactTooltip
                  id={`${field.name}-info`}
                  place="top"
                  effect="solid"
                  html
                  arrowColor="transparent"
                  className="!opacity-100 !bg-headraceBlack-800 !px-[12px] !text-xs !font-normal"
                />
              </div>
            )}
          </label>
        )}
        <div className="flex relative rounded-md shadow-sm">
          {helper && (
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
              {helper}
            </span>
          )}
          <BasicSelect
            aria-label={label}
            name={field.name}
            id={field.name}
            className={inputClassName}
            onChange={onChangeSelect}
            onBlur={field.onBlur}
            isDisabled={disabled}
            options={options}
            actions={actions}
            value={getValueSelect(field.value)}
            placeholder={placeholder}
            maxMenuHeight={maxMenuHeight}
            isSearchable={isSearchable}
            hasError={Boolean(meta.touched && meta.error)}
            inputRef={inputRef}
            optionsMessage={optionsMessage}
            customNoOptionsMessage={customNoOptionsMessage}
            styles={basicSelectCustomStyles}
          />
          {meta.touched && meta.error && <InputErrorIcon className="right-7" />}
        </div>
        {description && (
          <p className="mt-2 text-sm text-gray-500">{description}</p>
        )}
      </div>
      <ErrorText
        id={`${field.name}-error`}
        error={meta.error || null}
        touched={meta.touched}
        className={errorClassName}
      />
    </div>
  );
};

export default SingleSelect;
