import classNames from 'classnames';
import Link from 'next/link';
import type { VFC } from 'react';

export type QueryParam = { [key: string]: string };

export interface ItemsMenu {
  label: string;
  action: string | (() => void);
  disabled?: boolean;
  queryParam?: QueryParam;
}

interface ItemsMenuProps {
  options: ItemsMenu[];
  closeMenu?: () => void;
}

const MenuActions: VFC<ItemsMenuProps> = ({ options, closeMenu }) => (
  <div className="p-0">
    {options.map((item) => {
      if (typeof item.action === 'string') {
        return (
          <Link
            key={item.label}
            href={{ pathname: item.action, query: item.queryParam }}
            passHref
          >
            <a
              role="button"
              className="block py-2 px-4 text-sm text-headraceBlack-800 h-9 hover:bg-gray-100"
              onClick={(e): void => {
                e.stopPropagation();
              }}
              aria-hidden="true"
            >
              {item.label}
            </a>
          </Link>
        );
      }
      return (
        <button
          type="button"
          key={item.label}
          className={classNames(
            'block py-2 px-4 text-sm border-none bg-none w-full text-left text-headraceBlack-800 h-9 hover:bg-gray-100',
            {
              'text-headraceBlack-300': item.disabled,
            }
          )}
          onClick={(e): void => {
            e.stopPropagation();
            if (item.action instanceof Function) {
              item.action();
            }
            if (closeMenu) {
              closeMenu();
            }
          }}
          disabled={item.disabled}
        >
          {item.label}
        </button>
      );
    })}
  </div>
);

export default MenuActions;
