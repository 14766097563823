import { CandidateActivityHistoryActivityType } from '@headrace/types';
import { formatDate, isValidHttpUrl } from '@headrace/utils';
import { CheckCircleIcon as CheckCircleIconOutline } from '@heroicons/react/outline';
import {
  CheckCircleIcon,
  MailIcon,
  StarIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import Link from 'next/link';
import type { ReactNode, VFC } from 'react';
import { useMemo } from 'react';

import { LinkedinIcon, UserCircleIcon } from '../../CustomIcons';

interface Props {
  urlDetails?: string;
  activityType: string;
  id: string;
  name: string;
  linkedIn: string;
  email: string;
  createdAt: Date | string;
  searchAgreementId?: string | null;
  marketplaceVisibility?: string;
  recruiterData?: { name: string | JSX.Element; agencyName?: string };
  employerSide?: boolean;
  handleClick?: () => void;
  getOptionsMenu: (
    candidateId: string,
    candidateStatus: string,
    searchAgreementId?: string | null,
    marketplaceVisibility?: string
  ) => ReactNode;
}

const CandidateCard: VFC<Props> = ({
  urlDetails,
  activityType,
  getOptionsMenu,
  id,
  name,
  linkedIn,
  email,
  createdAt,
  searchAgreementId,
  marketplaceVisibility,
  recruiterData,
  employerSide = false,
  handleClick,
}) => {
  const candidateStatus = useMemo(() => {
    switch (activityType) {
      case CandidateActivityHistoryActivityType.OFFER_ACCEPTED:
        return (
          <>
            <StarIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-headraceYellow-700"
              aria-hidden="true"
            />
            Hired
          </>
        );
      case CandidateActivityHistoryActivityType.REJECTED:
        return (
          <>
            <XCircleIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            {employerSide ? 'Candidate passed' : 'Candidate rejected'}
          </>
        );
      case CandidateActivityHistoryActivityType.IN_PROCESS:
        return (
          <>
            <CheckCircleIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
              aria-hidden="true"
            />
            Interviewing
          </>
        );
      case CandidateActivityHistoryActivityType.EXPIRED:
        return (
          <>
            <XCircleIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            Expired
          </>
        );
      default:
        return (
          <>
            <CheckCircleIconOutline
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            In review
          </>
        );
    }
  }, [activityType, employerSide]);
  const recruiterComponent = useMemo(() => {
    if (!recruiterData) return null;
    if (typeof recruiterData.name === 'string') {
      return ` by ${recruiterData.name} ${
        recruiterData.agencyName ? `| ${recruiterData.agencyName}` : ''
      }`;
    }
    return <div>by {recruiterData.name} </div>;
  }, [recruiterData]);
  return (
    <div className="flex items-center md:px-8 md:py-4 border md:border-0 border-gray-200 rounded-md">
      <div
        className={classNames('min-w-0 flex-1 md:pr-4 lg:grid  lg:gap-4', {
          'lg:grid-cols-3': employerSide,
          'lg:grid-cols-2': !employerSide,
        })}
      >
        <div
          className={classNames(
            'flex items-center mb-[14px] lg:mb-0 px-6 pt-4 md:p-0 ',
            {
              'lg:col-span-1': employerSide,
            }
          )}
        >
          {!employerSide && (
            <Link href={urlDetails || `/candidates/${id}`} passHref>
              <a className="flex-shrink-0 mr-[22px]">
                <UserCircleIcon className="w-10 h-10 md:w-9 md:h-9" />
              </a>
            </Link>
          )}
          <div className="flex flex-col flex-1 gap-[5px] md:gap-[9.5px] truncate">
            {employerSide ? (
              <button
                type="button"
                onClick={handleClick}
                className="text-sm text-left font-medium text-blue-500 truncate h-full"
              >
                {name}
              </button>
            ) : (
              <Link href={urlDetails || `/candidates/${id}`} passHref>
                <a>
                  <div className="text-sm font-medium text-blue-500 truncate h-full">
                    {name}
                  </div>
                </a>
              </Link>
            )}
            <div className="flex items-center text-sm text-gray-500f flex-auto">
              {isValidHttpUrl(linkedIn) && (
                <Link href={linkedIn} passHref>
                  <a className="inline-flex" target="_blank" rel="noreferrer">
                    <LinkedinIcon
                      className="flex-shrink-0 mr-2 w-[23.52px] h-[20px]"
                      aria-hidden="true"
                    />
                  </a>
                </Link>
              )}
              <Link href={`mailto:${email}`} passHref>
                <a>
                  <MailIcon
                    className="flex-shrink-0 mr-2 h-5 w-5 text-blue-500"
                    aria-hidden="true"
                  />
                </a>
              </Link>
              {!employerSide && (
                <Link href={`mailto:${email}`} passHref>
                  <a className="hidden md:inline">
                    <span className="truncate text-blue-500">{email}</span>
                  </a>
                </Link>
              )}
            </div>
          </div>
          <div className="flex md:hidden ml-auto">
            {getOptionsMenu(
              id,
              activityType,
              searchAgreementId,
              marketplaceVisibility
            )}
          </div>
        </div>
        <div
          className={classNames({
            'lg:col-span-2': employerSide,
          })}
        >
          {employerSide ? (
            <button
              type="button"
              onClick={handleClick}
              className="divide-y divide-gray-200 md:divide-y-0"
            >
              <p
                className="mb-0 flex flex-wrap sm:gap-1 gap-0 md:mb-2 px-6 pb-4 md:p-0 font-normal text-xs md:text-sm text-gray-500 md:text-gray-900 truncate"
                title={id}
              >
                Submitted on {formatDate(createdAt.toString(), 'MMMM dd, yyyy')}
                <br className="block md:hidden" />
                {recruiterComponent}
              </p>
              <p className="p-4 md:p-0 flex items-center text-sm text-gray-500">
                {candidateStatus}
              </p>
            </button>
          ) : (
            <Link href={urlDetails || `/candidates/${id}`} passHref>
              <a className="divide-y divide-gray-200 md:divide-y-0">
                <p
                  className="mb-0 flex flex-wrap sm:gap-1 gap-0 md:mb-2 px-6 pb-4 md:p-0 font-normal text-xs md:text-sm text-gray-500 md:text-gray-900 truncate"
                  title={id}
                >
                  Submitted on{' '}
                  {formatDate(createdAt.toString(), 'MMMM dd, yyyy')}
                  <br className="block md:hidden" />
                  {recruiterComponent}
                </p>
                <p className="p-4 md:p-0 flex items-center text-sm text-gray-500">
                  {candidateStatus}
                </p>
              </a>
            </Link>
          )}
        </div>
      </div>
      <div className="hidden md:block">
        {getOptionsMenu(
          id,
          activityType,
          searchAgreementId,
          marketplaceVisibility
        )}
      </div>
    </div>
  );
};

export default CandidateCard;
