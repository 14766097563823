import type { VFC } from 'react';

import type { OptionsProps } from '../../forms';
import { NumberInput } from '../../forms';
import SingleSelect from '../../forms/fields/formik/Select';

interface RoleFactsFormProps {
  roleSeniorityOptions: OptionsProps[];
}

const RoleFactsForm: VFC<RoleFactsFormProps> = ({ roleSeniorityOptions }) => (
  <div className="flex flex-col gap-8">
    <SingleSelect
      name="roleSeniorityId"
      label="Level"
      options={roleSeniorityOptions}
    />
    <NumberInput
      name="expectedFirstYearSalaryMin"
      label="Minimum salary"
      thousandSeparator
      helper="$"
    />
    <NumberInput
      name="expectedFirstYearSalaryMax"
      label="Maximum salary"
      thousandSeparator
      helper="$"
    />
    <NumberInput
      name="expectedFirstYearShareCount"
      label="Target equity"
      placeholder="# of units"
      thousandSeparator
    />
    <NumberInput name="quantity" label="Role openings" thousandSeparator />
  </div>
);

export default RoleFactsForm;
