import type { Dispatch, SetStateAction, VFC } from 'react';
import { useEffect, useState } from 'react';

import { DatePicker } from '../forms';
import Modal from '../Modal';

interface StartDateModalProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  handleSubmit: (dateValue: Date | null) => Promise<void>;
  candidateName: string;
  defaultValue: Date | null;
}

const StartDateModal: VFC<StartDateModalProps> = (props) => {
  const {
    isOpen,
    setOpen,
    handleSubmit,
    candidateName,
    isLoading,
    defaultValue,
  } = props;
  const [dateValue, setDateValue] = useState<Date | null>(defaultValue);

  useEffect(() => {
    setDateValue(defaultValue);
  }, [defaultValue]);

  const handleOnClose = (): void => {
    setOpen(false);
    setDateValue(defaultValue);
  };

  return (
    <Modal
      onClose={handleOnClose}
      open={isOpen}
      title={`Edit ${candidateName}'s start date`}
      className="sm:min-w-[500px]"
      buttons={[
        {
          type: 'primary',
          label: 'Save',
          action: async (): Promise<void> => {
            await handleSubmit(dateValue);
          },
          loading: isLoading,
        },
        {
          type: 'secondary',
          label: 'Cancel',
          action: handleOnClose,
        },
      ]}
    >
      <DatePicker
        label="Candidate start date"
        placeholder="Select a valid date"
        onChange={(value): void => {
          if (value === null || value instanceof Date) setDateValue(value);
        }}
        value={dateValue}
        selectsRange={false}
        minDate={new Date()}
      />
    </Modal>
  );
};

export default StartDateModal;
