import type { CandidateActivityHistoryActivityType } from '@headrace/types';
import type { CandidateActivityHistoryEntityType } from '@headrace/types';
import type { CandidateDocumentTypeEnum } from '@headrace/types';
import type { CandidateExpirationReasonEnum } from '@headrace/types';
import type { CandidateInterestVerificationType } from '@headrace/types';
import type { CandidateRejectedReasonEnum } from '@headrace/types';
import type { CollaborationStatus } from '@headrace/types';
import type { CompanyDataIngestSource } from '@headrace/types';
import type { CompanyPerkEnum } from '@headrace/types';
import type { EmployerSearchAgreementActivityCategory } from '@headrace/types';
import type { EnrichmentProspectStatus } from '@headrace/types';
import type { EntityTypeEnum } from '@headrace/types';
import type { EvaluationStatus } from '@headrace/types';
import type { ExpectedPlacementsEnum } from '@headrace/types';
import type { FeeAdjustmentTypeEnum } from '@headrace/types';
import type { FeeScheduleTypeEnum } from '@headrace/types';
import type { FeeTriggerTypeEnum } from '@headrace/types';
import type { InvitationStatus } from '@headrace/types';
import type { LatestFundingRoundTypeEnum } from '@headrace/types';
import type { LeadStatusEnum } from '@headrace/types';
import type { MarketplaceRoleRecruiterStatus } from '@headrace/types';
import type { MarketplaceVisibilityEnum } from '@headrace/types';
import type { MeetRequirementEnum } from '@headrace/types';
import type { MessageDirectionType } from '@headrace/types';
import type { OnboardingStatus } from '@headrace/types';
import type { OutreachChannelEnum } from '@headrace/types';
import type { OutreachMessageType } from '@headrace/types';
import type { OutreachSequenceStatus } from '@headrace/types';
import type { PayoutTypeEnum } from '@headrace/types';
import type { PricingTypeEnum } from '@headrace/types';
import type { ProspectAssigneeEnum } from '@headrace/types';
import type { ProspectSearchType } from '@headrace/types';
import type { ProspectStatus } from '@headrace/types';
import type { RemoteWorkPolicyEnum } from '@headrace/types';
import type { RoleCollaboratorTypeEnum } from '@headrace/types';
import type { RoleQuestionStatus } from '@headrace/types';
import type { RoleTypeEnum } from '@headrace/types';
import type { SearchPreferenceEnum } from '@headrace/types';
import type { SlackChannelType } from '@headrace/types';
import type { UserNotificationChannel } from '@headrace/types';
import type { UserNotificationEnum } from '@headrace/types';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: Date;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AcceptCandidateInput = {
  candidateId: Scalars['String'];
  candidateNote?: InputMaybe<Scalars['String']>;
  interviewerId?: InputMaybe<Scalars['String']>;
  interviewerInvited?: InputMaybe<InterviewerInvited>;
  scheduleLink?: InputMaybe<Scalars['String']>;
  skipScheduleConfirmation?: InputMaybe<Scalars['Boolean']>;
  updateOnlyState?: InputMaybe<Scalars['Boolean']>;
};

export type ActiveRecruitersOutput = {
  __typename?: 'ActiveRecruitersOutput';
  acceptanceRatePercent: Scalars['Float'];
  agency: Agency;
  agencyId: Scalars['String'];
  allowRecruiterVerifiedInterestOverride: Scalars['Boolean'];
  avgAcceptanceRate: Scalars['Float'];
  avgRequirementsScore: Scalars['Float'];
  bio?: Maybe<Scalars['String']>;
  bioJSON?: Maybe<Scalars['String']>;
  canceledSearchAgreements: Array<SearchAgreement>;
  candidateHiredCount: Scalars['Float'];
  candidateInterviewingCount: Scalars['Float'];
  candidateSubmittedCount: Scalars['Float'];
  candidates: Array<Candidate>;
  cities: Array<City>;
  createdAt: Scalars['DateTime'];
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  endorsements: Array<Endorsement>;
  id: Scalars['ID'];
  isMarketplaceRecruiter: Scalars['Boolean'];
  linkedIn?: Maybe<Scalars['String']>;
  location?: Maybe<City>;
  manualTimeToPlacement: Scalars['Float'];
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  marketplaceStatus?: Maybe<MarketplaceRoleRecruiterStatus>;
  maxPricePercent?: Maybe<Scalars['Int']>;
  minPricePercent?: Maybe<Scalars['Int']>;
  onboardingStatus: OnboardingStatus;
  placedCandidatesCount: Scalars['Float'];
  placements: Array<Placement>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  requestedLeads: Array<Lead>;
  roleFunctions: Array<RoleFunction>;
  schedulingLink15Min?: Maybe<Scalars['String']>;
  schedulingLink30Min?: Maybe<Scalars['String']>;
  schedulingLink60Min?: Maybe<Scalars['String']>;
  searchPreference?: Maybe<SearchPreferenceEnum>;
  searchReviews: Array<SearchAgreementReview>;
  showProfile: Scalars['Boolean'];
  signedSearchAgreements: Array<SearchAgreement>;
  timezone?: Maybe<Timezone>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type Agency = {
  __typename?: 'Agency';
  createdAt: Scalars['DateTime'];
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  id: Scalars['ID'];
  isTestAccount: Scalars['Boolean'];
  leads: Array<Lead>;
  linkedIn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payouts: Array<Payout>;
  recruiters: Array<Recruiter>;
  searchAgreements: Array<SearchAgreement>;
  singularEmployers: Array<Employer>;
  slackChannelAbbreviation?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  website?: Maybe<Scalars['String']>;
};

export type AtsIntegration = {
  __typename?: 'AtsIntegration';
  accountToken: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employer: Employer;
  id: Scalars['ID'];
  mergeJobs: MergeJob;
  name: Scalars['String'];
  shareCandidateFeedback: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  userEmail: Scalars['String'];
};

export type Candidate = {
  __typename?: 'Candidate';
  baseSalary?: Maybe<Scalars['Float']>;
  candidateActivityHistories: Array<CandidateActivityHistory>;
  candidateInterestVerificationStatus: CandidateInterestVerificationType;
  collaboration?: Maybe<Collaboration>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  documents?: Maybe<Array<CandidateDocument>>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isExpiringSoon: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  justificationJSON?: Maybe<Scalars['String']>;
  lastInterestVerificationSentAt?: Maybe<Scalars['DateTime']>;
  mergeApplication?: Maybe<MergeApplication>;
  recruiter?: Maybe<Recruiter>;
  recruiterId: Scalars['String'];
  roleRequirements?: Maybe<Array<CandidateRoleRequirement>>;
  searchAgreementRole: SearchAgreementRole;
  searchAgreementRoleId: Scalars['String'];
  shareCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: CandidateActivityHistoryActivityType;
  statusWithInterestVerification: Scalars['String'];
  talent: Talent;
  updatedAt: Scalars['DateTime'];
};

export type CandidateAcceptOfferInput = {
  baseSalary?: InputMaybe<Scalars['Float']>;
  candidateId: Scalars['String'];
  shareCount?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CandidateActivityHistoryActivityType>;
};

export type CandidateActivityHistory = {
  __typename?: 'CandidateActivityHistory';
  activityMeta?: Maybe<CandidateActivityHistoryActivityMetaType>;
  activityType: CandidateActivityHistoryActivityType;
  candidate: Candidate;
  candidateId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  entityId?: Maybe<Scalars['String']>;
  entityType: CandidateActivityHistoryEntityType;
  eventOccurredAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type CandidateActivityHistoryActivityMetaType = {
  __typename?: 'CandidateActivityHistoryActivityMetaType';
  expirationReasonCategory?: Maybe<CandidateExpirationReasonEnum>;
  mergeInterviewId?: Maybe<Scalars['String']>;
  mergeRejectReasonId?: Maybe<Scalars['String']>;
  rejectReasonCategory?: Maybe<CandidateRejectedReasonEnum>;
  rejectReasonDetails?: Maybe<Scalars['String']>;
  shareRejectionInfo?: Maybe<Scalars['Boolean']>;
};

export { CandidateActivityHistoryActivityType };

export { CandidateActivityHistoryEntityType };

export type CandidateActivityHistoryOutput = {
  __typename?: 'CandidateActivityHistoryOutput';
  candidateHistory: Array<CandidateActivityHistory>;
};

export type CandidateDetailsOutput = {
  __typename?: 'CandidateDetailsOutput';
  baseSalary?: Maybe<Scalars['Float']>;
  candidateActivityHistories: Array<CandidateActivityHistory>;
  candidateInterestVerificationStatus: CandidateInterestVerificationType;
  collaboration?: Maybe<Collaboration>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  documents?: Maybe<Array<CandidateDocument>>;
  feeSplitPercentage?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isCollaborationCandidate?: Maybe<Scalars['Boolean']>;
  isCollaborationRecruiter: Scalars['Boolean'];
  isExpiringSoon: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  justificationJSON?: Maybe<Scalars['String']>;
  lastInterestVerificationSentAt?: Maybe<Scalars['DateTime']>;
  mergeApplication?: Maybe<MergeApplication>;
  placementFee?: Maybe<Scalars['Float']>;
  placementFeeType?: Maybe<Scalars['String']>;
  recruiter?: Maybe<Recruiter>;
  recruiterId: Scalars['String'];
  roleRequirements?: Maybe<Array<CandidateRoleRequirement>>;
  searchAgreementRole: SearchAgreementRole;
  searchAgreementRoleId: Scalars['String'];
  shareCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: CandidateActivityHistoryActivityType;
  statusWithInterestVerification: Scalars['String'];
  talent: Talent;
  updatedAt: Scalars['DateTime'];
};

export type CandidateDocument = {
  __typename?: 'CandidateDocument';
  candidate: Candidate;
  candidateId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  document: Scalars['String'];
  documentPath?: Maybe<Scalars['String']>;
  documentType?: Maybe<CandidateDocumentTypeEnum>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export { CandidateDocumentTypeEnum };

export { CandidateExpirationReasonEnum };

export { CandidateInterestVerificationType };

export type CandidateMetrics = {
  __typename?: 'CandidateMetrics';
  hiredCount: Scalars['Float'];
  interviewingCount: Scalars['Float'];
  submittedCount: Scalars['Float'];
};

export type CandidateMetricsByEmployerOutput = {
  __typename?: 'CandidateMetricsByEmployerOutput';
  hiredCount: Scalars['Float'];
  interviewingCount: Scalars['Float'];
  submittedCount: Scalars['Float'];
};

export { CandidateRejectedReasonEnum };

export type CandidateRoleRequirement = {
  __typename?: 'CandidateRoleRequirement';
  aiMeetRequirement?: Maybe<Scalars['String']>;
  aiMeetRequirementExplanation?: Maybe<Scalars['String']>;
  candidate: Candidate;
  candidateExplanation?: Maybe<Scalars['String']>;
  candidateMeetsRequirement?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  meetRequirement?: Maybe<MeetRequirementEnum>;
  meetRequirementOverride?: Maybe<MeetRequirementEnum>;
  mustHave: Scalars['Boolean'];
  prompt: Scalars['String'];
  roleRequirement?: Maybe<RoleRequirement>;
  roleRequirementId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vellumMeetRequirement?: Maybe<Scalars['String']>;
  vellumMeetRequirementExplanation?: Maybe<Scalars['String']>;
};

export type CandidateRoleRequirementInput = {
  explanation?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  meetRequirement: MeetRequirementEnum;
  mustHave: Scalars['Boolean'];
  prompt: Scalars['String'];
};

export type CandidateStatusMetricsOutput = {
  __typename?: 'CandidateStatusMetricsOutput';
  hiredCount: Scalars['Float'];
  interviewingCount: Scalars['Float'];
  submittedCount: Scalars['Float'];
};

export type CandidateWithExpiration = {
  __typename?: 'CandidateWithExpiration';
  baseSalary?: Maybe<Scalars['Float']>;
  candidateActivityHistories: Array<CandidateActivityHistory>;
  candidateInterestVerificationStatus: CandidateInterestVerificationType;
  collaboration?: Maybe<Collaboration>;
  createdAt: Scalars['DateTime'];
  daysUntilExpirationDate: Scalars['Float'];
  deletedAt: Scalars['DateTime'];
  documents?: Maybe<Array<CandidateDocument>>;
  fee?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isExpiringSoon: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  justificationJSON?: Maybe<Scalars['String']>;
  lastInterestVerificationSentAt?: Maybe<Scalars['DateTime']>;
  marketplaceVisibility: Scalars['String'];
  mergeApplication?: Maybe<MergeApplication>;
  recruiter?: Maybe<Recruiter>;
  recruiterId: Scalars['String'];
  roleRequirements?: Maybe<Array<CandidateRoleRequirement>>;
  roleRequirementsScore: Scalars['Float'];
  searchAgreementRole: SearchAgreementRole;
  searchAgreementRoleId: Scalars['String'];
  shareCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: CandidateActivityHistoryActivityType;
  statusWithInterestVerification: Scalars['String'];
  talent: Talent;
  updatedAt: Scalars['DateTime'];
};

export type CandidatesAiMetricsOutput = {
  __typename?: 'CandidatesAIMetricsOutput';
  candidateAcceptedPercentage: Scalars['Float'];
  candidatesPerWeekCount: Scalars['Float'];
  totalCandidatesCount: Scalars['Float'];
};

export type CandidatesAiRoleOutput = {
  __typename?: 'CandidatesAIRoleOutput';
  candidate: Candidate;
  dateSourced?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  labelCandidateInterest: Scalars['String'];
  prospect: Prospect;
  roleRequirementScore: Scalars['Float'];
  roleRequirementsByPriority: Array<RoleRequirementsByRole>;
};

export type CandidatesByStatusOutput = {
  __typename?: 'CandidatesByStatusOutput';
  candidates: Array<CandidatesMetrics>;
};

export type CandidatesMetrics = {
  __typename?: 'CandidatesMetrics';
  baseSalary?: Maybe<Scalars['Float']>;
  candidateActivityHistories: Array<CandidateActivityHistory>;
  candidateInterestVerificationStatus: CandidateInterestVerificationType;
  collaboration?: Maybe<Collaboration>;
  createdAt: Scalars['DateTime'];
  daysUntilExpirationDate: Scalars['Float'];
  deletedAt: Scalars['DateTime'];
  documents?: Maybe<Array<CandidateDocument>>;
  fee: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isExpiringSoon: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  justificationJSON?: Maybe<Scalars['String']>;
  lastInterestVerificationSentAt?: Maybe<Scalars['DateTime']>;
  mergeApplication?: Maybe<MergeApplication>;
  recruiter?: Maybe<Recruiter>;
  recruiterId: Scalars['String'];
  roleRequirements?: Maybe<Array<CandidateRoleRequirement>>;
  roleRequirementsScore: Scalars['Float'];
  searchAgreementRole: SearchAgreementRole;
  searchAgreementRoleId: Scalars['String'];
  shareCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: CandidateActivityHistoryActivityType;
  statusWithInterestVerification: Scalars['String'];
  talent: Talent;
  updatedAt: Scalars['DateTime'];
};

export type City = {
  __typename?: 'City';
  cityCode: Scalars['ID'];
  cityName: Scalars['String'];
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  placements: Array<Placement>;
  region?: Maybe<Scalars['String']>;
  stateAbbrev: Scalars['String'];
  stateName: Scalars['String'];
  timezone?: Maybe<Timezone>;
  timezoneId?: Maybe<Scalars['String']>;
};

export type CityMultiOptionsOutput = {
  __typename?: 'CityMultiOptionsOutput';
  cityOptions: Array<Options>;
};

export type ClientCompanyStage = {
  __typename?: 'ClientCompanyStage';
  count: Scalars['Float'];
  name: Scalars['String'];
};

export type Collaboration = {
  __typename?: 'Collaboration';
  agency: Agency;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isCandidateOwnerAgnostic?: Maybe<Scalars['Boolean']>;
  primaryRecruiter: Recruiter;
  recruiter: Recruiter;
  searchAgreementRole: SearchAgreementRole;
  splitPercentage: Scalars['Float'];
  status: CollaborationStatus;
  updatedAt: Scalars['DateTime'];
};

export { CollaborationStatus };

export type CommonEducationInstitution = {
  __typename?: 'CommonEducationInstitution';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
  placementsCount: Scalars['Float'];
};

export type CommonIndustries = {
  __typename?: 'CommonIndustries';
  companies: Array<Company>;
  id: Scalars['ID'];
  name: Scalars['String'];
  placementsCount: Scalars['Float'];
};

export type Company = {
  __typename?: 'Company';
  companyDataIngestRaw?: Maybe<Array<CompanyDataIngestRaw>>;
  companyStage?: Maybe<CompanyStage>;
  companyStageId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  crunchbase?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employeeCount?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  industry?: Maybe<Industry>;
  industryId?: Maybe<Scalars['String']>;
  intro?: Maybe<Scalars['String']>;
  introJSON?: Maybe<Scalars['String']>;
  investors?: Maybe<Scalars['String']>;
  latestFundingRoundAmount?: Maybe<Scalars['Float']>;
  latestFundingRoundType?: Maybe<LatestFundingRoundTypeEnum>;
  linkedIn?: Maybe<Scalars['String']>;
  location?: Maybe<City>;
  logo?: Maybe<Scalars['String']>;
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  overviewJSON?: Maybe<Scalars['String']>;
  perks: Array<CompanyPerk>;
  placements: Array<Placement>;
  press: Array<CompanyPress>;
  revenue?: Maybe<Scalars['Float']>;
  tagline?: Maybe<Scalars['String']>;
  tam?: Maybe<Scalars['Float']>;
  teamMembers: Array<CompanyTeamMember>;
  totalFunding?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  website?: Maybe<Scalars['String']>;
  yoyGrowth?: Maybe<Scalars['Float']>;
};

export type CompanyDataIngestRaw = {
  __typename?: 'CompanyDataIngestRaw';
  company?: Maybe<Array<Company>>;
  companyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  linkedInCategories?: Maybe<Array<Scalars['String']>>;
  linkedInCompanySizeOnLinkedIn?: Maybe<Scalars['Float']>;
  linkedInCompanyType?: Maybe<Scalars['String']>;
  linkedInCrunchbaseProfileUrl?: Maybe<Scalars['String']>;
  linkedInDescription?: Maybe<Scalars['String']>;
  linkedInFoundedYear?: Maybe<Scalars['Int']>;
  linkedInFundingData?: Maybe<Array<LinkedInFundingRoundMetaType>>;
  linkedInHq?: Maybe<LinkedInCompanyLocationMeta>;
  linkedInIndustry?: Maybe<Scalars['String']>;
  linkedInTagline?: Maybe<Scalars['String']>;
  linkedInTotalFundingAmount?: Maybe<Scalars['Float']>;
  linkedInWebsite?: Maybe<Scalars['String']>;
  source: CompanyDataIngestSource;
  updatedAt: Scalars['DateTime'];
};

export { CompanyDataIngestSource };

export type CompanyPerk = {
  __typename?: 'CompanyPerk';
  company: Company;
  id: Scalars['ID'];
  type: CompanyPerkEnum;
};

export { CompanyPerkEnum };

export type CompanyPress = {
  __typename?: 'CompanyPress';
  company: Company;
  id: Scalars['ID'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type CompanyStage = {
  __typename?: 'CompanyStage';
  companies: Array<Company>;
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
};

export type CompanyTeamMember = {
  __typename?: 'CompanyTeamMember';
  company: Company;
  id: Scalars['ID'];
  linkedin: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
};

export type ConvertProspectToCandidateInput = {
  document?: InputMaybe<CreateCandidateDocumentInput>;
  prospectPublicDossierId: Scalars['String'];
  roleRequirements?: InputMaybe<Array<CandidateRoleRequirementInput>>;
  talent: CreateTalentInput;
};

export type CreateAiRoleInput = {
  expectedFirstYearSalaryMax?: InputMaybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: InputMaybe<Scalars['Float']>;
  jobDescriptionLink?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateCandidateDocumentInput = {
  candidateId?: InputMaybe<Scalars['String']>;
  document: Scalars['String'];
  documentType: CandidateDocumentTypeEnum;
  name: Scalars['String'];
};

export type CreateEmployerInput = {
  description?: InputMaybe<Scalars['String']>;
  linkedIn: Scalars['String'];
  name: Scalars['String'];
  tou?: InputMaybe<Scalars['Boolean']>;
  user: CreateUserInput;
};

export type CreateMediaUploadLinkInput = {
  filename: Scalars['String'];
  folder: Scalars['String'];
};

export type CreateRoleInput = {
  citiesId: Array<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  emailHiringManager?: InputMaybe<Scalars['String']>;
  expectedFirstYearSalaryMax?: InputMaybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: InputMaybe<Scalars['Float']>;
  expectedFirstYearShareCount?: InputMaybe<Scalars['Int']>;
  firstNameHiringManager?: InputMaybe<Scalars['String']>;
  hiringManager?: InputMaybe<Scalars['String']>;
  intakeSource?: InputMaybe<Scalars['String']>;
  isLightCreation?: InputMaybe<Scalars['Boolean']>;
  jobDescriptionLink?: InputMaybe<Scalars['String']>;
  lastNameHiringManager?: InputMaybe<Scalars['String']>;
  marketplaceVisibility?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  placementFee?: InputMaybe<Scalars['Float']>;
  quantity: Scalars['Int'];
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  roleFunctionId: Scalars['String'];
  roleSeniorityId: Scalars['String'];
};

export type CreateRoleRequirementInput = {
  mustHave?: InputMaybe<Scalars['Boolean']>;
  prompt: Scalars['String'];
  roleId: Scalars['String'];
};

export type CreateTalentInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  linkedIn: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CurrentEmployee = {
  __typename?: 'CurrentEmployee';
  acceptedLeads: Array<Lead>;
  canceledSearchAgreements: Array<SearchAgreement>;
  createdAt: Scalars['DateTime'];
  employer: Employer;
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  id: Scalars['ID'];
  linkedIn?: Maybe<Scalars['String']>;
  location?: Maybe<City>;
  requestedRoles: Array<Role>;
  roles: Array<Role>;
  showSearches: Scalars['Boolean'];
  signedSearchAgreements: Array<SearchAgreement>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type DiscoverRecruiterOutput = {
  __typename?: 'DiscoverRecruiterOutput';
  id: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  profileImage?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DownloadDocumentsResultOutput = {
  __typename?: 'DownloadDocumentsResultOutput';
  data: Scalars['String'];
};

export type EducationDegree = {
  __typename?: 'EducationDegree';
  id: Scalars['ID'];
  placements: Array<Placement>;
  type: Scalars['String'];
};

export type EducationInstitution = {
  __typename?: 'EducationInstitution';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
};

export type EducationMajor = {
  __typename?: 'EducationMajor';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
};

export type Employee = {
  __typename?: 'Employee';
  acceptedLeads: Array<Lead>;
  canceledSearchAgreements: Array<SearchAgreement>;
  createdAt: Scalars['DateTime'];
  employer: Employer;
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  id: Scalars['ID'];
  linkedIn?: Maybe<Scalars['String']>;
  location?: Maybe<City>;
  requestedRoles: Array<Role>;
  roles: Array<Role>;
  signedSearchAgreements: Array<SearchAgreement>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type EmployeeSignedSearchAgreementInput = {
  anvilDocumentGroupEid: Scalars['String'];
  anvilEmployeeSignerEid: Scalars['String'];
  anvilEtchPacketEid: Scalars['String'];
  searchAgreementId: Scalars['String'];
};

export type Employer = {
  __typename?: 'Employer';
  company: Company;
  createdAt: Scalars['DateTime'];
  defaultMarketplacePrice: Scalars['Int'];
  employees: Array<Employee>;
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  frontChannelId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ignoreForRecruiterAcceptanceRate: Scalars['Boolean'];
  isHeadRaceAI: Scalars['Boolean'];
  isTestAccount: Scalars['Boolean'];
  leads: Array<Lead>;
  outreachChannel: OutreachChannelEnum;
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  primaryAgency?: Maybe<Agency>;
  primaryAgencyId?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  searchAgreements: Array<SearchAgreement>;
  slackChannelAbbreviation?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type EmployerAiRolesOutput = {
  __typename?: 'EmployerAIRolesOutput';
  IsRoleAIActive: Scalars['Boolean'];
  acceptanceRateVisibilityThreshold: Scalars['Float'];
  allDirectSearchAgreements: Array<SearchAgreement>;
  allSearchAgreements: Array<SearchAgreement>;
  allowedAgencies: Array<RoleAgencyAllowList>;
  candidateNeedsReviewCount: Scalars['Float'];
  candidateNote?: Maybe<Scalars['String']>;
  cities: Array<City>;
  collaborators: Array<RoleCollaborator>;
  continuationScoreThreshold?: Maybe<Scalars['Float']>;
  countActiveRecruiters: Scalars['Float'];
  countActiveSearchAgreements: Scalars['Float'];
  countCandidates: Scalars['Float'];
  countCandidatesInProcess: Scalars['Float'];
  countInterestedCandidates: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionJSON?: Maybe<Scalars['String']>;
  directSearchAgreement?: Maybe<SearchAgreement>;
  employer: Employer;
  employerId: Scalars['String'];
  employerRoleRequirements: Array<RoleRequirement>;
  enableKnnBackgroundWorkflow: Scalars['Boolean'];
  enableOutreachBackgroundWorkflow: Scalars['Boolean'];
  expectedFirstYearSalaryMax?: Maybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: Maybe<Scalars['Float']>;
  expectedFirstYearShareCount?: Maybe<Scalars['Int']>;
  expectedSalaryUpdatedAt?: Maybe<Scalars['DateTime']>;
  feeGuidance?: Maybe<Scalars['String']>;
  hiringManager?: Maybe<Employee>;
  id: Scalars['ID'];
  idealCandidateDescription?: Maybe<Scalars['String']>;
  idealCandidateProfiles: Array<IdealCandidateProfile>;
  idealCandidateSearchFilters: SearchFilters;
  idealCandidateSearchQuery?: Maybe<Scalars['String']>;
  interviewer?: Maybe<Employee>;
  interviewerId?: Maybe<Scalars['String']>;
  inviteSearchAgreement?: Maybe<SearchAgreement>;
  isArchived: Scalars['Boolean'];
  isDossierShareable: Scalars['Boolean'];
  isRoleAlreadyLunched: Scalars['Boolean'];
  jobDescriptionLink?: Maybe<Scalars['String']>;
  knnProspectLimit: Scalars['Int'];
  leads: Array<Lead>;
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  marketplaceSearchAgreement?: Maybe<SearchAgreement>;
  marketplaceStatusForEmployer: Scalars['String'];
  mergeJob?: Maybe<MergeJob>;
  mustHaveRoleRequirementsLimitOverride?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  noteJSON?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  oldExpectedSalaryRange?: Maybe<Scalars['String']>;
  outreachSequenceThreshold: Scalars['Int'];
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  prospectSearches: Array<ProspectSearch>;
  prospects: Array<Prospect>;
  publicDossiers: Array<PublicDossier>;
  publicSearchAgreement?: Maybe<SearchAgreement>;
  quantity?: Maybe<Scalars['Int']>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  requestedByEmployee: Employee;
  requestedByEmployeeId?: Maybe<Scalars['String']>;
  roleFunction?: Maybe<RoleFunction>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniority?: Maybe<RoleSeniority>;
  roleTitle?: Maybe<RoleTitle>;
  roleType: RoleTypeEnum;
  scheduleLink?: Maybe<Scalars['String']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  showProspectsAndMetrics: Scalars['Boolean'];
  skipScheduleConfirmation: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type EmployerCandidateWithMetrics = {
  __typename?: 'EmployerCandidateWithMetrics';
  candidateMetrics: CandidateMetrics;
  candidates: Array<CandidateWithExpiration>;
  candidatesLast7Days?: Maybe<Array<Candidate>>;
  globalCandidateMetrics?: Maybe<CandidateMetrics>;
  submissionAvailableDate?: Maybe<Scalars['String']>;
};

export type EmployerInvitationByRecruiter = {
  __typename?: 'EmployerInvitationByRecruiter';
  agency: Agency;
  createdAt: Scalars['DateTime'];
  employee: Employee;
  employer: Employer;
  id: Scalars['ID'];
  recruiter: Recruiter;
  status: InvitationStatus;
};

export { EmployerSearchAgreementActivityCategory };

export type EmployerSearchReviewInput = {
  candidateSubmissionQuality: Scalars['Float'];
  communication: Scalars['Float'];
  overallRating: Scalars['Float'];
  recruiterId: Scalars['String'];
  review: Scalars['String'];
  timeToPlacement: Scalars['Float'];
  timeliness: Scalars['Float'];
};

export type EmployerTerminatedSearchAgreementInput = {
  reasonCategory: EmployerSearchAgreementActivityCategory;
  reasonDetails: Scalars['String'];
  searchAgreementId: Scalars['String'];
};

export type EmployerUpdateCompanyOverviewInput = {
  overviewJSON?: InputMaybe<Scalars['String']>;
};

export type EmployerUpdateCompanyPerkInput = {
  type: CompanyPerkEnum;
};

export type EmployerUpdateCompanyPressInput = {
  title: Scalars['String'];
  url: Scalars['String'];
};

export type EmployerUpdateCompanyTeamMemberInput = {
  linkedin: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
};

export type EmployerUpdateRoleDescriptionInput = {
  description?: InputMaybe<Scalars['String']>;
  descriptionJSON?: InputMaybe<Scalars['String']>;
  roleId: Scalars['String'];
};

export type EmployerUpdateRoleFactsInput = {
  expectedFirstYearSalaryMax?: InputMaybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: InputMaybe<Scalars['Float']>;
  expectedFirstYearShareCount?: InputMaybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  roleId: Scalars['String'];
  roleSeniorityId?: InputMaybe<Scalars['String']>;
};

export type Endorsement = {
  __typename?: 'Endorsement';
  endorsement: Scalars['String'];
  endorserCompany: Scalars['String'];
  endorserTitle: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Float'];
  recruiter: Recruiter;
  recruiterId: Scalars['String'];
};

export { EnrichmentProspectStatus };

export { EntityTypeEnum };

export { EvaluationStatus };

export { ExpectedPlacementsEnum };

export { FeeAdjustmentTypeEnum };

export { FeeScheduleTypeEnum };

export { FeeTriggerTypeEnum };

export type FrontEmailMessage = {
  __typename?: 'FrontEmailMessage';
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  from: Scalars['String'];
  frontConversationId: Scalars['String'];
  frontConversationLink: Scalars['String'];
  frontMessageId: Scalars['String'];
  id: Scalars['ID'];
  outreachMessage?: Maybe<OutreachMessage>;
  outreachMessageId?: Maybe<Scalars['String']>;
  outreachSequence: OutreachSequence;
  outreachSequenceId: Scalars['String'];
  to: Scalars['String'];
  type: MessageDirectionType;
};

export type IdealCandidateProfile = {
  __typename?: 'IdealCandidateProfile';
  id: Scalars['ID'];
  linkedIn: Scalars['String'];
  prospectSearch?: Maybe<ProspectSearch>;
  prospectSearchId?: Maybe<Scalars['String']>;
  role: Role;
  roleId: Scalars['String'];
};

export type IdealCandidateProfileObject = {
  id: Scalars['String'];
  linkedIn: Scalars['String'];
  roleId: Scalars['String'];
};

export type IdealCandidatesProfileInput = {
  deletedIdealCandidatesProfile?: InputMaybe<Array<Scalars['String']>>;
  idealCandidatesProfile: Array<IdealCandidateProfileObject>;
};

export type Industry = {
  __typename?: 'Industry';
  companies: Array<Company>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InterviewerInvited = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export { InvitationStatus };

export type InviteTeamMemberInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export { LatestFundingRoundTypeEnum };

export type Lead = {
  __typename?: 'Lead';
  acceptedByEmployee?: Maybe<Employee>;
  acceptedByEmployeeId?: Maybe<Scalars['String']>;
  agency: Agency;
  agencyId: Scalars['String'];
  collaboration?: Maybe<Collaboration>;
  collaborationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayStatus: Scalars['String'];
  employerStatus?: Maybe<LeadStatusEnum>;
  id: Scalars['ID'];
  recruiterStatus: LeadStatusEnum;
  requestedRecruiter?: Maybe<Recruiter>;
  requestedRecruiterId?: Maybe<Scalars['String']>;
  role: Role;
  searchAgreement?: Maybe<SearchAgreement>;
  searchAgreementId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export { LeadStatusEnum };

export type LinkedInCompanyLocationMeta = {
  __typename?: 'LinkedInCompanyLocationMeta';
  city: Scalars['String'];
  country: Scalars['String'];
  is_hq: Scalars['Boolean'];
  line_1: Scalars['String'];
  postal_code: Scalars['String'];
  state: Scalars['String'];
};

export type LinkedInDateMetaType = {
  __typename?: 'LinkedInDateMetaType';
  day: Scalars['Int'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type LinkedInFundingRoundMetaType = {
  __typename?: 'LinkedInFundingRoundMetaType';
  announced_date: LinkedInDateMetaType;
  funding_type: Scalars['String'];
  investor_list: Array<LinkedInInvestorMetaType>;
  money_raised: Scalars['Float'];
  number_of_investor: Scalars['Int'];
};

export type LinkedInInvestorMetaType = {
  __typename?: 'LinkedInInvestorMetaType';
  linkedin_profile_url: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type LinkedInProfile = {
  __typename?: 'LinkedInProfile';
  agency: Agency;
  agencyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  html: Scalars['String'];
  id: Scalars['ID'];
  parsedLinkedInProfile?: Maybe<ParsedLinkedInProfile>;
  talent?: Maybe<Talent>;
  talentId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlPath: Scalars['String'];
};

export type LinkedInProfileRaw = {
  __typename?: 'LinkedInProfileRaw';
  background_cover_image_url: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Scalars['JSON']>>;
  experiences?: Maybe<Array<Scalars['JSON']>>;
  firstName: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  lastRefreshDate: Scalars['DateTime'];
  last_name?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  occupation?: Maybe<Scalars['String']>;
  profile_pic_url?: Maybe<Scalars['String']>;
  public_identifier: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
};

export type MarketplaceRoleRecruiter = {
  __typename?: 'MarketplaceRoleRecruiter';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  marketplaceVisibility: MarketplaceVisibilityEnum;
  recruiter: Recruiter;
  role: Role;
  status: MarketplaceRoleRecruiterStatus;
  updatedAt: Scalars['DateTime'];
};

export { MarketplaceRoleRecruiterStatus };

export { MarketplaceVisibilityEnum };

export type MediaUploadLink = {
  __typename?: 'MediaUploadLink';
  fields: MediaUploadLinkFields;
  url: Scalars['String'];
};

export type MediaUploadLinkFields = {
  __typename?: 'MediaUploadLinkFields';
  Policy: Scalars['String'];
  X_Amz_Algorithm: Scalars['String'];
  X_Amz_Credential: Scalars['String'];
  X_Amz_Date: Scalars['String'];
  X_Amz_Security_Token?: Maybe<Scalars['String']>;
  X_Amz_Signature: Scalars['String'];
  bucket: Scalars['String'];
  key: Scalars['String'];
};

export { MeetRequirementEnum };

export type MergeApplication = {
  __typename?: 'MergeApplication';
  appliedAt?: Maybe<Scalars['DateTime']>;
  candidate: Candidate;
  candidateId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currentMergeJobInterviewStageId?: Maybe<Scalars['String']>;
  deletedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mergeCandidate: MergeCandidate;
  mergeCandidateId?: Maybe<Scalars['String']>;
  mergeInterviews: Array<MergeInterview>;
  mergeJob: MergeJob;
  mergeJobId?: Maybe<Scalars['String']>;
  mergeRejectReason: MergeRejectReason;
  mergeRejectReasonId?: Maybe<Scalars['String']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  remoteId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeCandidate = {
  __typename?: 'MergeCandidate';
  atsIntegration: AtsIntegration;
  company?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastInteractionAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  mergeApplications: Array<MergeApplication>;
  remoteCreatedAt?: Maybe<Scalars['DateTime']>;
  remoteId?: Maybe<Scalars['String']>;
  remoteUpdatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeDepartment = {
  __typename?: 'MergeDepartment';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type MergeInterview = {
  __typename?: 'MergeInterview';
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  endAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  mergeApplication: MergeApplication;
  mergeApplicationId: Scalars['String'];
  mergeJobInterviewStage: MergeJobInterviewStage;
  mergeJobInterviewStageId?: Maybe<Scalars['String']>;
  mergeScorecards?: Maybe<Array<MergeScorecard>>;
  remoteCreatedAt?: Maybe<Scalars['DateTime']>;
  remoteId?: Maybe<Scalars['String']>;
  remoteUpdatedAt?: Maybe<Scalars['DateTime']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeJob = {
  __typename?: 'MergeJob';
  atsIntegration: AtsIntegration;
  confidential: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mergeDepartments: Array<MergeDepartment>;
  mergeHiringManagers: Array<MergeUser>;
  mergeOffices: Array<MergeOffice>;
  mergeRecruiters: Array<MergeUser>;
  name?: Maybe<Scalars['String']>;
  remoteCreatedAt: Scalars['DateTime'];
  remoteId: Scalars['String'];
  remoteUpdatedAt: Scalars['DateTime'];
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MergeJobInterviewStage = {
  __typename?: 'MergeJobInterviewStage';
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mergeJobId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  remoteId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeOffice = {
  __typename?: 'MergeOffice';
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MergeRejectReason = {
  __typename?: 'MergeRejectReason';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  remoteId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeScorecard = {
  __typename?: 'MergeScorecard';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mergeApplicationId?: Maybe<Scalars['String']>;
  mergeInterview: MergeInterview;
  mergeInterviewId?: Maybe<Scalars['String']>;
  mergeInterviewerId?: Maybe<Scalars['String']>;
  overallRecommendation?: Maybe<Scalars['String']>;
  remoteCreatedAt?: Maybe<Scalars['DateTime']>;
  remoteId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeUser = {
  __typename?: 'MergeUser';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export { MessageDirectionType };

export type Mutation = {
  __typename?: 'Mutation';
  acceptAICandidateForReview: Scalars['Boolean'];
  acceptCandidate: Candidate;
  archiveSearchAgreement?: Maybe<Scalars['String']>;
  cancelSearchAgreement?: Maybe<Scalars['String']>;
  candidateAcceptOffer: Candidate;
  closeAIRole: Scalars['Boolean'];
  contactHeadraceViaSlack: SlackChannelWithInvitationCheck;
  convertProspectToCandidate: Scalars['Boolean'];
  createAIRole: Scalars['String'];
  createCandidateDocument: Candidate;
  createEmployer: Employer;
  createOrUpdateIdealCandidatesProfile: Scalars['Boolean'];
  createOrUpdateRoleRequirements: Scalars['Boolean'];
  createRole: Scalars['String'];
  createRoleRequirement: RoleRequirement;
  createRoleRequirements: Array<RoleRequirement>;
  createSearchReview: Scalars['Boolean'];
  deleteRoleQuestion: Scalars['Boolean'];
  deleteRoleRequirement: Scalars['Boolean'];
  deleteRoleRequirements: Scalars['Boolean'];
  employeeSignedSearchAgreement?: Maybe<Scalars['String']>;
  generateEtchSignURL?: Maybe<Scalars['String']>;
  inviteMarketplaceRecruiterByEmployer: Scalars['Boolean'];
  inviteTeamMember: Scalars['String'];
  joinEmployerSlackChannels: Scalars['Boolean'];
  launchAIRole: Scalars['Boolean'];
  loadFixtures: Scalars['Boolean'];
  pauseMarketplaceRole?: Maybe<Scalars['Boolean']>;
  prospectNotInterested: Scalars['Boolean'];
  publishRole: Scalars['Boolean'];
  rejectAICandidate: Scalars['Boolean'];
  rejectCandidate: Candidate;
  removeEmployerPaymentMethod: Scalars['Boolean'];
  removeInviteRecruiter?: Maybe<Scalars['Boolean']>;
  removeRoleCollaborator: Scalars['Boolean'];
  removeTeamMember: Scalars['Boolean'];
  requestProposal?: Maybe<Scalars['String']>;
  resumeMarketplaceRole?: Maybe<Scalars['Boolean']>;
  setUserNotificationSetting: Scalars['Boolean'];
  terminateMarketplaceRole?: Maybe<Scalars['Boolean']>;
  unarchiveSearchAgreement?: Maybe<Scalars['String']>;
  updateCandidate: Candidate;
  updateCompanyOverview: Scalars['Boolean'];
  updateCompanyPerks: Scalars['Boolean'];
  updateCompanyPress: Scalars['Boolean'];
  updateCompanyTeamMembers: Scalars['Boolean'];
  updateDefaultMarketplacePrice: Scalars['Float'];
  updateEmployee: Employee;
  updateInterviewProcess: Scalars['Boolean'];
  updateProspect: Scalars['Boolean'];
  updateRole: Scalars['Boolean'];
  updateRoleCollaborators: Scalars['Boolean'];
  updateRoleDescription: Scalars['Boolean'];
  updateRoleFacts: Scalars['Boolean'];
  updateRoleNote: Scalars['Boolean'];
  updateRoleOverview: Scalars['Boolean'];
  updateRoleQuestion: RoleQuestion;
  updateRoleRequirement: RoleRequirement;
  updateRoleRequirements: Array<RoleRequirement>;
  updateUser: User;
  updateUserTermsAndPolicy: User;
  voidSearchAgreement?: Maybe<Scalars['String']>;
};

export type MutationAcceptAiCandidateForReviewArgs = {
  candidateId: Scalars['String'];
};

export type MutationAcceptCandidateArgs = {
  input: AcceptCandidateInput;
};

export type MutationArchiveSearchAgreementArgs = {
  searchAgreementId: Scalars['String'];
};

export type MutationCancelSearchAgreementArgs = {
  input: EmployerTerminatedSearchAgreementInput;
};

export type MutationCandidateAcceptOfferArgs = {
  input: CandidateAcceptOfferInput;
};

export type MutationCloseAiRoleArgs = {
  roleId: Scalars['String'];
};

export type MutationConvertProspectToCandidateArgs = {
  input: ConvertProspectToCandidateInput;
};

export type MutationCreateAiRoleArgs = {
  input: CreateAiRoleInput;
};

export type MutationCreateCandidateDocumentArgs = {
  input: CreateCandidateDocumentInput;
};

export type MutationCreateEmployerArgs = {
  input: CreateEmployerInput;
};

export type MutationCreateOrUpdateIdealCandidatesProfileArgs = {
  input: IdealCandidatesProfileInput;
};

export type MutationCreateOrUpdateRoleRequirementsArgs = {
  input: UpdateRoleRequirementsInput;
};

export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};

export type MutationCreateRoleRequirementArgs = {
  input: CreateRoleRequirementInput;
};

export type MutationCreateRoleRequirementsArgs = {
  inputs: Array<CreateRoleRequirementInput>;
};

export type MutationCreateSearchReviewArgs = {
  input: Array<EmployerSearchReviewInput>;
  searchAgreementRoleId: Scalars['String'];
};

export type MutationDeleteRoleQuestionArgs = {
  roleQuestionId: Scalars['String'];
};

export type MutationDeleteRoleRequirementArgs = {
  roleRequirementId: Scalars['String'];
};

export type MutationDeleteRoleRequirementsArgs = {
  roleIds: Array<Scalars['String']>;
};

export type MutationEmployeeSignedSearchAgreementArgs = {
  input: EmployeeSignedSearchAgreementInput;
};

export type MutationGenerateEtchSignUrlArgs = {
  searchAgreementId: Scalars['String'];
};

export type MutationInviteMarketplaceRecruiterByEmployerArgs = {
  recruiterId: Scalars['String'];
  roleId: Scalars['String'];
};

export type MutationInviteTeamMemberArgs = {
  data: InviteTeamMemberInput;
};

export type MutationLaunchAiRoleArgs = {
  roleId: Scalars['String'];
};

export type MutationPauseMarketplaceRoleArgs = {
  roleId: Scalars['String'];
};

export type MutationProspectNotInterestedArgs = {
  publicDossierId: Scalars['String'];
};

export type MutationPublishRoleArgs = {
  input: PublishRoleInput;
};

export type MutationRejectAiCandidateArgs = {
  candidateId: Scalars['String'];
};

export type MutationRejectCandidateArgs = {
  input: RejectCandidateInput;
};

export type MutationRemoveEmployerPaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
};

export type MutationRemoveInviteRecruiterArgs = {
  recruiterId: Scalars['String'];
  roleId: Scalars['String'];
};

export type MutationRemoveRoleCollaboratorArgs = {
  roleId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationRemoveTeamMemberArgs = {
  userId: Scalars['String'];
};

export type MutationRequestProposalArgs = {
  requestProposalInput: RequestProposalEmployerInput;
};

export type MutationResumeMarketplaceRoleArgs = {
  newPrice?: InputMaybe<Scalars['String']>;
  roleId: Scalars['String'];
};

export type MutationSetUserNotificationSettingArgs = {
  input: Array<SetUserNotificationSettingInput>;
};

export type MutationTerminateMarketplaceRoleArgs = {
  input: TerminateMarketplaceRoleInput;
};

export type MutationUnarchiveSearchAgreementArgs = {
  searchAgreementId: Scalars['String'];
};

export type MutationUpdateCandidateArgs = {
  input: UpdateCandidateInput;
};

export type MutationUpdateCompanyOverviewArgs = {
  input: EmployerUpdateCompanyOverviewInput;
};

export type MutationUpdateCompanyPerksArgs = {
  perks: Array<EmployerUpdateCompanyPerkInput>;
};

export type MutationUpdateCompanyPressArgs = {
  pressList: Array<EmployerUpdateCompanyPressInput>;
};

export type MutationUpdateCompanyTeamMembersArgs = {
  members: Array<EmployerUpdateCompanyTeamMemberInput>;
};

export type MutationUpdateDefaultMarketplacePriceArgs = {
  publicPrice: Scalars['Float'];
};

export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
};

export type MutationUpdateInterviewProcessArgs = {
  input: UpdateInterviewProcessInput;
};

export type MutationUpdateProspectArgs = {
  input: UpdateProspectInput;
  prospectId: Scalars['String'];
};

export type MutationUpdateRoleArgs = {
  id: Scalars['String'];
  input: UpdateRoleInput;
};

export type MutationUpdateRoleCollaboratorsArgs = {
  input: RoleCollaboratorInput;
};

export type MutationUpdateRoleDescriptionArgs = {
  input: EmployerUpdateRoleDescriptionInput;
};

export type MutationUpdateRoleFactsArgs = {
  input: EmployerUpdateRoleFactsInput;
};

export type MutationUpdateRoleNoteArgs = {
  noteJSON?: InputMaybe<Scalars['String']>;
  roleId: Scalars['String'];
};

export type MutationUpdateRoleOverviewArgs = {
  input: UpdateRoleOverviewInput;
};

export type MutationUpdateRoleQuestionArgs = {
  input: UpdateRoleQuestionInput;
};

export type MutationUpdateRoleRequirementArgs = {
  input: UpdateRoleRequirementInput;
};

export type MutationUpdateRoleRequirementsArgs = {
  inputs: Array<UpdateRoleRequirementInput>;
};

export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};

export type MutationUpdateUserTermsAndPolicyArgs = {
  data: UpdateUserTermsAndPolicyInput;
};

export type MutationVoidSearchAgreementArgs = {
  input: EmployerTerminatedSearchAgreementInput;
};

export { OnboardingStatus };

export type Options = {
  __typename?: 'Options';
  label: Scalars['String'];
  value: Scalars['String'];
};

export { OutreachChannelEnum };

export type OutreachFinalizedMetrics = {
  __typename?: 'OutreachFinalizedMetrics';
  interested: Scalars['Float'];
  noResponse: Scalars['Float'];
  notInterested: Scalars['Float'];
};

export type OutreachInProgressMetrics = {
  __typename?: 'OutreachInProgressMetrics';
  firstFollowUp: Scalars['Float'];
  initialOutreach: Scalars['Float'];
  secondFollowUp: Scalars['Float'];
};

export type OutreachMessage = {
  __typename?: 'OutreachMessage';
  createdAt: Scalars['DateTime'];
  frontEmailMessage?: Maybe<FrontEmailMessage>;
  id: Scalars['ID'];
  message: Scalars['String'];
  outreachSequence: OutreachSequence;
  outreachSequenceId: Scalars['String'];
  scheduledAt?: Maybe<Scalars['DateTime']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  type: OutreachMessageType;
  updatedAt: Scalars['DateTime'];
};

export { OutreachMessageType };

export type OutreachRoleMetricsOutput = {
  __typename?: 'OutreachRoleMetricsOutput';
  outreachFinalizedMetrics: OutreachFinalizedMetrics;
  outreachInProgressMetrics: OutreachInProgressMetrics;
};

export type OutreachSequence = {
  __typename?: 'OutreachSequence';
  assignee?: Maybe<ProspectAssigneeEnum>;
  candidateResponse?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  endedAt?: Maybe<Scalars['DateTime']>;
  frontEmailMessages: Array<FrontEmailMessage>;
  id: Scalars['ID'];
  isBackfill: Scalars['Boolean'];
  lastFrontEmailMessage?: Maybe<FrontEmailMessage>;
  lastMessageSent?: Maybe<OutreachMessage>;
  lastScheduleMessage?: Maybe<OutreachMessage>;
  messageToSend?: Maybe<OutreachMessage>;
  messagesStage?: Maybe<OutreachMessageType>;
  outreachChannel: OutreachChannelEnum;
  outreachMessages: Array<OutreachMessage>;
  prospect: Prospect;
  prospectId: Scalars['String'];
  scheduledAt?: Maybe<Scalars['DateTime']>;
  status: OutreachSequenceStatus;
};

export type OutreachSequenceOutput = {
  __typename?: 'OutreachSequenceOutput';
  candidate: Scalars['String'];
  dateSource: Scalars['DateTime'];
  frontEmailMessages: Array<FrontEmailMessage>;
  id: Scalars['String'];
  linkedIn: Scalars['String'];
  outreachChannel: OutreachChannelEnum;
  outreachMessageType?: Maybe<OutreachMessageType>;
  outreachMessages: Array<OutreachMessage>;
  profilePicture?: Maybe<Scalars['String']>;
  stage: OutreachSequenceStatus;
};

export { OutreachSequenceStatus };

export type ParsedLinkedInProfile = {
  __typename?: 'ParsedLinkedInProfile';
  headline?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  linkedInProfile: LinkedInProfile;
  name: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  workExperience?: Maybe<Array<ParsedWorkExpirienceType>>;
};

export type ParsedWorkExpirienceType = {
  __typename?: 'ParsedWorkExpirienceType';
  company: Company;
  roleTitle: Scalars['String'];
};

export type PaymentInvoice = {
  __typename?: 'PaymentInvoice';
  agencyName: Scalars['String'];
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  feeTriggerType: Scalars['String'];
  id: Scalars['String'];
  invoiceStatus: Scalars['String'];
  invoiceUrl: Scalars['String'];
  searchAgreementId: Scalars['String'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type Payout = {
  __typename?: 'Payout';
  agency: Agency;
  amount: Scalars['Float'];
  amountWithoutAdjustment: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  paidAt?: Maybe<Scalars['DateTime']>;
  payoutType: PayoutTypeEnum;
  searchAgreement?: Maybe<SearchAgreement>;
};

export { PayoutTypeEnum };

export type Placement = {
  __typename?: 'Placement';
  candidate?: Maybe<Candidate>;
  candidateId?: Maybe<Scalars['String']>;
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['String']>;
  company: Company;
  companyId: Scalars['String'];
  companyStage?: Maybe<CompanyStage>;
  companyStageId?: Maybe<Scalars['String']>;
  educationDegree: EducationDegree;
  educationDegreeId?: Maybe<Scalars['String']>;
  educationInstitution: EducationInstitution;
  educationInstitutionId?: Maybe<Scalars['String']>;
  educationMajor: EducationMajor;
  educationMajorId?: Maybe<Scalars['String']>;
  firstYearBaseSalary?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  linkedIn: Scalars['String'];
  placedAt: Scalars['DateTime'];
  recruiter: Recruiter;
  recruiterId: Scalars['String'];
  roleFunction: RoleFunction;
  roleFunctionId: Scalars['String'];
  roleSeniority: RoleSeniority;
  roleSeniorityId: Scalars['String'];
  roleTitle?: Maybe<RoleTitle>;
  roleTitleId?: Maybe<Scalars['String']>;
  tenureMonths?: Maybe<Scalars['Float']>;
  yoeMonth?: Maybe<Scalars['Float']>;
};

export type PlacementFeeOutput = {
  __typename?: 'PlacementFeeOutput';
  amount: Scalars['Float'];
  feeType: FeeScheduleTypeEnum;
};

export type PlacementGeographyGraphData = {
  __typename?: 'PlacementGeographyGraphData';
  cityCode: Scalars['ID'];
  cityName: Scalars['String'];
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  placements: Array<Placement>;
  placementsCount: Scalars['Float'];
  region?: Maybe<Scalars['String']>;
  stateAbbrev: Scalars['String'];
  stateName: Scalars['String'];
  timezone?: Maybe<Timezone>;
  timezoneId?: Maybe<Scalars['String']>;
};

export { PricingTypeEnum };

export type Prospect = {
  __typename?: 'Prospect';
  averageRoleRequirementsScore: Scalars['Float'];
  candidate?: Maybe<Candidate>;
  candidateId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  emailEnrichmentStatus: EnrichmentProspectStatus;
  evaluationScore?: Maybe<EvaluationStatus>;
  id: Scalars['ID'];
  isQualified?: Maybe<Scalars['Boolean']>;
  linkedInProfile: LinkedInProfileRaw;
  manualDisqualifyOverride: Scalars['Boolean'];
  matchPercentage: Scalars['Float'];
  outreachSequence?: Maybe<OutreachSequence>;
  outreachSequenceExists: Scalars['Boolean'];
  phoneNumberEnrichmentStatus: EnrichmentProspectStatus;
  prospectPhoneNumbers?: Maybe<Array<ProspectPhoneNumber>>;
  prospectRoleRequirements: Array<ProspectRoleRequirement>;
  prospectSearch: ProspectSearch;
  prospectSearchId: Scalars['String'];
  publicIdentifier: Scalars['String'];
  role: Role;
  roleId: Scalars['String'];
  roleMeetRequirementsCount: Scalars['Float'];
  roleRequirementsEvaluatedCount: Scalars['Float'];
  rrMustHaveFailingCount: Scalars['Float'];
  rrPassingPercent: Scalars['Float'];
  score: Scalars['Float'];
  source: Scalars['String'];
  status: ProspectStatus;
};

export { ProspectAssigneeEnum };

export type ProspectMetricsOutput = {
  __typename?: 'ProspectMetricsOutput';
  candidatesInterestedCount: Scalars['Float'];
  outreachSequencesCount: Scalars['Float'];
  prospectQualifiedCount: Scalars['Float'];
  prospectReviewedCount: Scalars['Float'];
};

export type ProspectPhoneNumber = {
  __typename?: 'ProspectPhoneNumber';
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  prospect?: Maybe<Prospect>;
  prospectId?: Maybe<Scalars['String']>;
};

export type ProspectPublicDossierOutput = {
  __typename?: 'ProspectPublicDossierOutput';
  candidate?: Maybe<Candidate>;
  candidateRoleRequirements: Array<RoleRequirement>;
  dossierURL: Scalars['String'];
  id: Scalars['ID'];
  prospect?: Maybe<Prospect>;
  role: Role;
  shortCode: Scalars['String'];
  status?: Maybe<OutreachSequenceStatus>;
};

export type ProspectRoleRequirement = {
  __typename?: 'ProspectRoleRequirement';
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  llmEvaluatorType: Scalars['String'];
  meetRequirement?: Maybe<Scalars['String']>;
  meetRequirementExplanation?: Maybe<Scalars['String']>;
  parentWorkflowId: Scalars['String'];
  prospect: Prospect;
  prospectId: Scalars['String'];
  roleRequirement?: Maybe<RoleRequirement>;
  roleRequirementId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workflowId?: Maybe<Scalars['String']>;
};

export type ProspectSearch = {
  __typename?: 'ProspectSearch';
  continuationScore?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastExpansionTime?: Maybe<Scalars['DateTime']>;
  lastKLimit?: Maybe<Scalars['Float']>;
  lastSearchDuplicateCount?: Maybe<Scalars['Float']>;
  lastSearchNewCount?: Maybe<Scalars['Float']>;
  linkedInPublicIdentifier?: Maybe<Scalars['String']>;
  parentProspectSearchId?: Maybe<Scalars['String']>;
  prospects: Array<Prospect>;
  prospectsInterestedCount: Scalars['Float'];
  qualifiedProspectPercentage: Scalars['Float'];
  qualifiedProspectsCount: Scalars['Float'];
  query?: Maybe<Scalars['String']>;
  role: Role;
  roleId: Scalars['String'];
  searchFilters: SearchFilters;
  searchType: ProspectSearchType;
  showQualifiedProspectsCreatedBefore?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export { ProspectSearchType };

export { ProspectStatus };

export type ProspectsByRoleIdOutput = {
  __typename?: 'ProspectsByRoleIdOutput';
  averageRoleRequirementsScore: Scalars['Float'];
  candidate?: Maybe<Candidate>;
  candidateId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  emailEnrichmentStatus: EnrichmentProspectStatus;
  evaluationScore?: Maybe<EvaluationStatus>;
  id: Scalars['ID'];
  isQualified?: Maybe<Scalars['Boolean']>;
  linkedInProfile: LinkedInProfileRaw;
  manualDisqualifyOverride: Scalars['Boolean'];
  matchPercentage: Scalars['Float'];
  outreachSequence?: Maybe<OutreachSequence>;
  outreachSequenceExists: Scalars['Boolean'];
  phoneNumberEnrichmentStatus: EnrichmentProspectStatus;
  prospectPhoneNumbers?: Maybe<Array<ProspectPhoneNumber>>;
  prospectRoleRequirements: Array<ProspectRoleRequirement>;
  prospectSearch: ProspectSearch;
  prospectSearchId: Scalars['String'];
  publicIdentifier: Scalars['String'];
  role: Role;
  roleId: Scalars['String'];
  roleMeetRequirementsCount: Scalars['Float'];
  roleRequirementPercentage: Scalars['Float'];
  roleRequirements: Array<RoleRequirementsLlms>;
  roleRequirementsEvaluatedCount: Scalars['Float'];
  rrMustHaveFailingCount: Scalars['Float'];
  rrPassingPercent: Scalars['Float'];
  score: Scalars['Float'];
  source: Scalars['String'];
  status: ProspectStatus;
};

export type PublicDossier = {
  __typename?: 'PublicDossier';
  candidate?: Maybe<Candidate>;
  dossierURL: Scalars['String'];
  id: Scalars['ID'];
  prospect?: Maybe<Prospect>;
  role: Role;
  shortCode: Scalars['String'];
};

export type PublicRecruiterOutput = {
  __typename?: 'PublicRecruiterOutput';
  bio?: Maybe<Scalars['String']>;
  bioJSON?: Maybe<Scalars['String']>;
  commonPlacedIndustries: Array<CommonIndustries>;
  companyStages: Array<ClientCompanyStage>;
  endorsements: Array<Endorsement>;
  id: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  maxPricePercent?: Maybe<Scalars['Float']>;
  minPricePercent?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  placements: Array<Placement>;
  placementsCompanies: Array<Company>;
  placementsGeographies: Array<PlacementGeographyGraphData>;
  profileImage?: Maybe<Scalars['String']>;
  roleFunctions: Array<RoleFunction>;
  searchPreference?: Maybe<SearchPreferenceEnum>;
  timeToPlacement: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  topRoleFunctions: Array<TopRoleFunctions>;
  topRoleSeniority: Array<TopRoleSeniority>;
};

export type PublishRoleInput = {
  bountyType: FeeScheduleTypeEnum;
  marketplaceVisibility: MarketplaceVisibilityEnum;
  price: Scalars['Float'];
  roleId: Scalars['String'];
  status: Scalars['String'];
  updateSearchId?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  activeRoleRecruiters: Array<ActiveRecruitersOutput>;
  activeRolesNumberByEmployer: Scalars['Int'];
  aiRoleList: Array<Role>;
  apiVersion: Scalars['String'];
  archivedSearchAgreementByEmployer: Array<SearchAgreement>;
  availableRolesAgency?: Maybe<Array<Role>>;
  avalaibleRequestProposal: Scalars['Boolean'];
  calculateTimeToPlacementByRecruiter: Scalars['Float'];
  candidateActivityHistoryByCandidateId?: Maybe<CandidateActivityHistoryOutput>;
  candidateById: CandidateDetailsOutput;
  candidateMarketplaceMetrics: CandidateStatusMetricsOutput;
  candidateMetricsByEmployer: CandidateMetricsByEmployerOutput;
  candidatesAIMetrics: CandidatesAiMetricsOutput;
  candidatesAIRole: Array<CandidatesAiRoleOutput>;
  candidatesByEmployer: CandidatesByStatusOutput;
  candidatesByRole: EmployerCandidateWithMetrics;
  candidatesToReview: Array<Candidate>;
  checkSlackConnection: Scalars['Boolean'];
  cities: Array<City>;
  citiesMultiOptions: CityMultiOptionsOutput;
  clientCompanyStages: Array<ClientCompanyStage>;
  companyStages?: Maybe<Array<CompanyStage>>;
  createMediaUploadLink: MediaUploadLink;
  currentEmployee: CurrentEmployee;
  discoverRecruiters: Array<DiscoverRecruiterOutput>;
  employees: Array<Employee>;
  employeesByEmployer: Array<Employee>;
  employerAIRoleById: EmployerAiRolesOutput;
  employerRoleById?: Maybe<RoleWithStatusAndMarketplace>;
  endorsementsByRecruiter: Array<Endorsement>;
  getAnvilSignaturedFiles?: Maybe<DownloadDocumentsResultOutput>;
  getCustomerBillingInformation: StripeCustomer;
  getEmployerBillingInformationUpdateLink: Scalars['String'];
  getUser?: Maybe<User>;
  hasArchivedSearchAgreements: Scalars['Boolean'];
  industries: Array<Industry>;
  listEmployerPaymentInvoices: Array<PaymentInvoice>;
  listEmployerPaymentMethods: Array<PaymentMethod>;
  listUserNotificationSetting: Array<UserNotificationSetting>;
  openRolesByEmployer: Array<Role>;
  outreachRoleMetrics: OutreachRoleMetricsOutput;
  outreachRoleSequences: Array<OutreachSequenceOutput>;
  placementAverageTenure: Scalars['Float'];
  placementCompanies: Array<Company>;
  placementsByRecruiter: Array<Placement>;
  prospectMetrics: ProspectMetricsOutput;
  prospectPublicDossier?: Maybe<ProspectPublicDossierOutput>;
  prospectSearchesByRoleId: Array<ProspectSearch>;
  prospectsByRoleId: Array<ProspectsByRoleIdOutput>;
  publicRecruiterById?: Maybe<PublicRecruiterOutput>;
  publicRecruiterIds: Array<Scalars['String']>;
  recommendedRecruitersByRole: Array<RecommendedRecruiterOutput>;
  recruiterById: Recruiter;
  recruiterCommonIndustries: Array<CommonIndustries>;
  recruiterCommonPlacedInstitutions: Array<CommonEducationInstitution>;
  recruiterPlacementGeographies: Array<PlacementGeographyGraphData>;
  recruiterRelevancesByRole: Array<RecruiterRelevanceOutput>;
  recruiterTopSeniority: Array<TopRoleSeniority>;
  reviewsByRecruiter: ReviewWithAverage;
  roleCollaborators: Array<RoleCollaborator>;
  roleDossier: RoleDossierOutput;
  roleFunctions?: Maybe<Array<RoleFunction>>;
  roleQuestions: Array<RoleQuestion>;
  roleRequirementTemplates: Array<RoleRequirementTemplate>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniorities?: Maybe<Array<RoleSeniority>>;
  roles: Array<RoleList>;
  searchAgreementByEmployer: Array<SearchAgreement>;
  searchAgreementById?: Maybe<SearchAgreement>;
  sendEmailToGetAccess: Scalars['Boolean'];
  sendReportRecruiterReviewEmail: Scalars['Boolean'];
  setupIntentClientSecret?: Maybe<Scalars['String']>;
  setupIntentStatusById: Scalars['String'];
  successfulPlacementsByRecruiter: Scalars['Float'];
  timeToReviewCandidate: Scalars['String'];
  topRoleFunctions: Array<TopRoleFunctions>;
};

export type QueryActiveRoleRecruitersArgs = {
  roleId: Scalars['String'];
};

export type QueryAvailableRolesAgencyArgs = {
  recruiterId: Scalars['String'];
};

export type QueryAvalaibleRequestProposalArgs = {
  recruiterId: Scalars['String'];
};

export type QueryCalculateTimeToPlacementByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryCandidateActivityHistoryByCandidateIdArgs = {
  id: Scalars['String'];
};

export type QueryCandidateByIdArgs = {
  id: Scalars['String'];
};

export type QueryCandidateMarketplaceMetricsArgs = {
  id: Scalars['String'];
};

export type QueryCandidatesAiMetricsArgs = {
  roleId: Scalars['String'];
};

export type QueryCandidatesAiRoleArgs = {
  roleId: Scalars['String'];
};

export type QueryCandidatesByRoleArgs = {
  roleId: Scalars['String'];
};

export type QueryClientCompanyStagesArgs = {
  recruiterId: Scalars['String'];
};

export type QueryCreateMediaUploadLinkArgs = {
  data: CreateMediaUploadLinkInput;
};

export type QueryDiscoverRecruitersArgs = {
  recruiterId: Scalars['String'];
};

export type QueryEmployerAiRoleByIdArgs = {
  id: Scalars['String'];
};

export type QueryEmployerRoleByIdArgs = {
  id: Scalars['String'];
};

export type QueryEndorsementsByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryGetAnvilSignaturedFilesArgs = {
  searchAgreementId: Scalars['String'];
};

export type QueryOutreachRoleMetricsArgs = {
  roleId: Scalars['String'];
};

export type QueryOutreachRoleSequencesArgs = {
  roleId: Scalars['String'];
};

export type QueryPlacementAverageTenureArgs = {
  recruiterId: Scalars['String'];
};

export type QueryPlacementCompaniesArgs = {
  recruiterId: Scalars['String'];
};

export type QueryPlacementsByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryProspectMetricsArgs = {
  roleId: Scalars['String'];
};

export type QueryProspectPublicDossierArgs = {
  id: Scalars['String'];
};

export type QueryProspectSearchesByRoleIdArgs = {
  roleId: Scalars['String'];
};

export type QueryProspectsByRoleIdArgs = {
  roleId: Scalars['String'];
};

export type QueryPublicRecruiterByIdArgs = {
  id: Scalars['String'];
};

export type QueryRecommendedRecruitersByRoleArgs = {
  roleId: Scalars['String'];
};

export type QueryRecruiterByIdArgs = {
  id: Scalars['String'];
};

export type QueryRecruiterCommonIndustriesArgs = {
  recruiterId: Scalars['String'];
};

export type QueryRecruiterCommonPlacedInstitutionsArgs = {
  recruiterId: Scalars['String'];
};

export type QueryRecruiterPlacementGeographiesArgs = {
  recruiterId: Scalars['String'];
};

export type QueryRecruiterRelevancesByRoleArgs = {
  roleId: Scalars['String'];
};

export type QueryRecruiterTopSeniorityArgs = {
  recruiterId: Scalars['String'];
};

export type QueryReviewsByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryRoleCollaboratorsArgs = {
  roleId: Scalars['String'];
};

export type QueryRoleDossierArgs = {
  roleId: Scalars['String'];
};

export type QueryRoleQuestionsArgs = {
  filter: RoleQuestionFilter;
};

export type QueryRoleRequirementTemplatesArgs = {
  filter: RoleRequirementTemplateFilter;
};

export type QueryRoleRequirementsArgs = {
  filter: RoleRequirementFilter;
};

export type QuerySearchAgreementByIdArgs = {
  id: Scalars['String'];
};

export type QuerySendEmailToGetAccessArgs = {
  input: SendEmailToGetAccessInput;
};

export type QuerySendReportRecruiterReviewEmailArgs = {
  input: SendReportRecruiterReviewInput;
};

export type QuerySetupIntentStatusByIdArgs = {
  setupIntentId: Scalars['String'];
};

export type QuerySuccessfulPlacementsByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryTopRoleFunctionsArgs = {
  recruiterId: Scalars['String'];
};

export type RecommendedRecruiter = {
  __typename?: 'RecommendedRecruiter';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isInvitedToMarketplaceRole: Scalars['Boolean'];
  isPrimaryAgency: Scalars['Boolean'];
  isRequested: Scalars['Boolean'];
  isRequestedToDirect: Scalars['Boolean'];
  recruiter: Recruiter;
  recruiterId: Scalars['String'];
  role: Role;
  roleId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RecommendedRecruiterOutput = {
  __typename?: 'RecommendedRecruiterOutput';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isInvitedToMarketplaceRole: Scalars['Boolean'];
  isPrimaryAgency: Scalars['Boolean'];
  isRequested: Scalars['Boolean'];
  isRequestedToDirect: Scalars['Boolean'];
  placementsByFunctionCount: Scalars['Int'];
  placementsCompanyStages: Array<Scalars['String']>;
  recruiter: Recruiter;
  recruiterId: Scalars['String'];
  role: Role;
  roleId: Scalars['String'];
  timeToPlacement: Scalars['Float'];
  topRoleSeniority?: Maybe<TopRoleSeniority>;
  updatedAt: Scalars['DateTime'];
};

export type Recruiter = {
  __typename?: 'Recruiter';
  acceptanceRatePercent: Scalars['Float'];
  agency: Agency;
  agencyId: Scalars['String'];
  allowRecruiterVerifiedInterestOverride: Scalars['Boolean'];
  bio?: Maybe<Scalars['String']>;
  bioJSON?: Maybe<Scalars['String']>;
  canceledSearchAgreements: Array<SearchAgreement>;
  candidates: Array<Candidate>;
  cities: Array<City>;
  createdAt: Scalars['DateTime'];
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  endorsements: Array<Endorsement>;
  id: Scalars['ID'];
  isMarketplaceRecruiter: Scalars['Boolean'];
  linkedIn?: Maybe<Scalars['String']>;
  location?: Maybe<City>;
  manualTimeToPlacement: Scalars['Float'];
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  maxPricePercent?: Maybe<Scalars['Int']>;
  minPricePercent?: Maybe<Scalars['Int']>;
  onboardingStatus: OnboardingStatus;
  placedCandidatesCount: Scalars['Float'];
  placements: Array<Placement>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  requestedLeads: Array<Lead>;
  roleFunctions: Array<RoleFunction>;
  schedulingLink15Min?: Maybe<Scalars['String']>;
  schedulingLink30Min?: Maybe<Scalars['String']>;
  schedulingLink60Min?: Maybe<Scalars['String']>;
  searchPreference?: Maybe<SearchPreferenceEnum>;
  searchReviews: Array<SearchAgreementReview>;
  showProfile: Scalars['Boolean'];
  signedSearchAgreements: Array<SearchAgreement>;
  timezone?: Maybe<Timezone>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type RecruiterRelevanceOutput = {
  __typename?: 'RecruiterRelevanceOutput';
  acceptanceRatePercent: Scalars['Float'];
  agency: Agency;
  agencyId: Scalars['String'];
  allowRecruiterVerifiedInterestOverride: Scalars['Boolean'];
  bio?: Maybe<Scalars['String']>;
  bioJSON?: Maybe<Scalars['String']>;
  canceledSearchAgreements: Array<SearchAgreement>;
  candidates: Array<Candidate>;
  cities: Array<City>;
  createdAt: Scalars['DateTime'];
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  endorsements: Array<Endorsement>;
  id: Scalars['ID'];
  isInvitedToMarketplaceRole: Scalars['Boolean'];
  isMarketplaceRecruiter: Scalars['Boolean'];
  isRequested: Scalars['Boolean'];
  isRequestedToDirect: Scalars['Boolean'];
  linkedIn?: Maybe<Scalars['String']>;
  location?: Maybe<City>;
  manualTimeToPlacement: Scalars['Float'];
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  maxPricePercent?: Maybe<Scalars['Int']>;
  minPricePercent?: Maybe<Scalars['Int']>;
  onboardingStatus: OnboardingStatus;
  placedCandidatesCount: Scalars['Float'];
  placements: Array<Placement>;
  placementsByFunctionCount: Scalars['Int'];
  placementsCompanyStages: Array<Scalars['String']>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  requestedLeads: Array<Lead>;
  roleFunctions: Array<RoleFunction>;
  schedulingLink15Min?: Maybe<Scalars['String']>;
  schedulingLink30Min?: Maybe<Scalars['String']>;
  schedulingLink60Min?: Maybe<Scalars['String']>;
  searchPreference?: Maybe<SearchPreferenceEnum>;
  searchReviews: Array<SearchAgreementReview>;
  showProfile: Scalars['Boolean'];
  signedSearchAgreements: Array<SearchAgreement>;
  timeToPlacement: Scalars['Float'];
  timezone?: Maybe<Timezone>;
  title?: Maybe<Scalars['String']>;
  topRoleSeniority?: Maybe<TopRoleSeniority>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type RejectCandidateInput = {
  candidateId: Scalars['String'];
  rejectFeedback?: InputMaybe<Scalars['String']>;
  rejectReason?: InputMaybe<CandidateRejectedReasonEnum>;
  shareRejectionInfo?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<CandidateActivityHistoryActivityType>;
};

export { RemoteWorkPolicyEnum };

export type RequestProposalEmployerInput = {
  requestedRecruiterId: Scalars['String'];
  roleId: Scalars['String'];
};

export type ReviewAverages = {
  __typename?: 'ReviewAverages';
  candidateSubmissionQualityAverage: Scalars['Float'];
  comunicationAverage: Scalars['Float'];
  overallRatingAverage: Scalars['Float'];
  timeToPlacementAverage: Scalars['Float'];
  timelessAverage: Scalars['Float'];
};

export type ReviewWithAverage = {
  __typename?: 'ReviewWithAverage';
  reviewAverages: ReviewAverages;
  searchReviews: Array<SearchAgreementReview>;
};

export type Role = {
  __typename?: 'Role';
  IsRoleAIActive: Scalars['Boolean'];
  acceptanceRateVisibilityThreshold: Scalars['Float'];
  allDirectSearchAgreements: Array<SearchAgreement>;
  allSearchAgreements: Array<SearchAgreement>;
  allowedAgencies: Array<RoleAgencyAllowList>;
  candidateNote?: Maybe<Scalars['String']>;
  cities: Array<City>;
  collaborators: Array<RoleCollaborator>;
  continuationScoreThreshold?: Maybe<Scalars['Float']>;
  countActiveRecruiters: Scalars['Float'];
  countActiveSearchAgreements: Scalars['Float'];
  countCandidates: Scalars['Float'];
  countCandidatesInProcess: Scalars['Float'];
  countInterestedCandidates: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionJSON?: Maybe<Scalars['String']>;
  directSearchAgreement?: Maybe<SearchAgreement>;
  employer: Employer;
  employerId: Scalars['String'];
  employerRoleRequirements: Array<RoleRequirement>;
  enableKnnBackgroundWorkflow: Scalars['Boolean'];
  enableOutreachBackgroundWorkflow: Scalars['Boolean'];
  expectedFirstYearSalaryMax?: Maybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: Maybe<Scalars['Float']>;
  expectedFirstYearShareCount?: Maybe<Scalars['Int']>;
  expectedSalaryUpdatedAt?: Maybe<Scalars['DateTime']>;
  feeGuidance?: Maybe<Scalars['String']>;
  hiringManager?: Maybe<Employee>;
  id: Scalars['ID'];
  idealCandidateDescription?: Maybe<Scalars['String']>;
  idealCandidateProfiles: Array<IdealCandidateProfile>;
  idealCandidateSearchFilters: SearchFilters;
  idealCandidateSearchQuery?: Maybe<Scalars['String']>;
  interviewer?: Maybe<Employee>;
  interviewerId?: Maybe<Scalars['String']>;
  inviteSearchAgreement?: Maybe<SearchAgreement>;
  isArchived: Scalars['Boolean'];
  isDossierShareable: Scalars['Boolean'];
  isRoleAlreadyLunched: Scalars['Boolean'];
  jobDescriptionLink?: Maybe<Scalars['String']>;
  knnProspectLimit: Scalars['Int'];
  leads: Array<Lead>;
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  marketplaceSearchAgreement?: Maybe<SearchAgreement>;
  marketplaceStatusForEmployer: Scalars['String'];
  mergeJob?: Maybe<MergeJob>;
  mustHaveRoleRequirementsLimitOverride?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  noteJSON?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  oldExpectedSalaryRange?: Maybe<Scalars['String']>;
  outreachSequenceThreshold: Scalars['Int'];
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  prospectSearches: Array<ProspectSearch>;
  prospects: Array<Prospect>;
  publicDossiers: Array<PublicDossier>;
  publicSearchAgreement?: Maybe<SearchAgreement>;
  quantity?: Maybe<Scalars['Int']>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  requestedByEmployee: Employee;
  requestedByEmployeeId?: Maybe<Scalars['String']>;
  roleFunction?: Maybe<RoleFunction>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniority?: Maybe<RoleSeniority>;
  roleTitle?: Maybe<RoleTitle>;
  roleType: RoleTypeEnum;
  scheduleLink?: Maybe<Scalars['String']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  showProspectsAndMetrics: Scalars['Boolean'];
  skipScheduleConfirmation: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type RoleAgencyAllowList = {
  __typename?: 'RoleAgencyAllowList';
  agency: Agency;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role: Role;
};

export type RoleCollaborator = {
  __typename?: 'RoleCollaborator';
  collaboratorType: RoleCollaboratorTypeEnum;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role: Role;
  roleId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type RoleCollaboratorInput = {
  collaboratorIds: Array<Scalars['String']>;
  roleId: Scalars['String'];
};

export { RoleCollaboratorTypeEnum };

export type RoleDossierOutput = {
  __typename?: 'RoleDossierOutput';
  IsRoleAIActive: Scalars['Boolean'];
  acceptanceRateVisibilityThreshold: Scalars['Float'];
  allDirectSearchAgreements: Array<SearchAgreement>;
  allSearchAgreements: Array<SearchAgreement>;
  allowedAgencies: Array<RoleAgencyAllowList>;
  candidateNote?: Maybe<Scalars['String']>;
  cities: Array<City>;
  collaborators: Array<RoleCollaborator>;
  continuationScoreThreshold?: Maybe<Scalars['Float']>;
  countActiveRecruiters: Scalars['Float'];
  countActiveSearchAgreements: Scalars['Float'];
  countCandidates: Scalars['Float'];
  countCandidatesInProcess: Scalars['Float'];
  countInterestedCandidates: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionJSON?: Maybe<Scalars['String']>;
  directSearchAgreement?: Maybe<SearchAgreement>;
  employer: Employer;
  employerId: Scalars['String'];
  employerRoleRequirements: Array<RoleRequirement>;
  enableKnnBackgroundWorkflow: Scalars['Boolean'];
  enableOutreachBackgroundWorkflow: Scalars['Boolean'];
  expectedFirstYearSalaryMax?: Maybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: Maybe<Scalars['Float']>;
  expectedFirstYearShareCount?: Maybe<Scalars['Int']>;
  expectedSalaryUpdatedAt?: Maybe<Scalars['DateTime']>;
  feeGuidance?: Maybe<Scalars['String']>;
  hiringManager?: Maybe<Employee>;
  id: Scalars['ID'];
  idealCandidateDescription?: Maybe<Scalars['String']>;
  idealCandidateProfiles: Array<IdealCandidateProfile>;
  idealCandidateSearchFilters: SearchFilters;
  idealCandidateSearchQuery?: Maybe<Scalars['String']>;
  interviewer?: Maybe<Employee>;
  interviewerId?: Maybe<Scalars['String']>;
  inviteSearchAgreement?: Maybe<SearchAgreement>;
  isArchived: Scalars['Boolean'];
  isDossierShareable: Scalars['Boolean'];
  isRoleAlreadyLunched: Scalars['Boolean'];
  jobDescriptionLink?: Maybe<Scalars['String']>;
  knnProspectLimit: Scalars['Int'];
  leads: Array<Lead>;
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  marketplaceSearchAgreement?: Maybe<SearchAgreement>;
  marketplaceStatusForEmployer: Scalars['String'];
  marketplaceVisibility?: Maybe<Scalars['String']>;
  mergeJob?: Maybe<MergeJob>;
  mustHaveRoleRequirementsLimitOverride?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  noteJSON?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  oldExpectedSalaryRange?: Maybe<Scalars['String']>;
  outreachSequenceThreshold: Scalars['Int'];
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  placementFee?: Maybe<PlacementFeeOutput>;
  prospectSearches: Array<ProspectSearch>;
  prospects: Array<Prospect>;
  publicDossierUrl?: Maybe<Scalars['String']>;
  publicDossiers: Array<PublicDossier>;
  publicSearchAgreement?: Maybe<SearchAgreement>;
  quantity?: Maybe<Scalars['Int']>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  requestedByEmployee: Employee;
  requestedByEmployeeId?: Maybe<Scalars['String']>;
  roleFunction?: Maybe<RoleFunction>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniority?: Maybe<RoleSeniority>;
  roleTitle?: Maybe<RoleTitle>;
  roleType: RoleTypeEnum;
  scheduleLink?: Maybe<Scalars['String']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  searchStatus?: Maybe<Scalars['String']>;
  showProspectsAndMetrics: Scalars['Boolean'];
  skipScheduleConfirmation: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type RoleFunction = {
  __typename?: 'RoleFunction';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
  roles: Array<Role>;
};

export type RoleList = {
  __typename?: 'RoleList';
  IsRoleAIActive: Scalars['Boolean'];
  acceptanceRateVisibilityThreshold: Scalars['Float'];
  allDirectSearchAgreements: Array<SearchAgreement>;
  allSearchAgreements: Array<SearchAgreement>;
  allowedAgencies: Array<RoleAgencyAllowList>;
  candidateNote?: Maybe<Scalars['String']>;
  cities: Array<City>;
  collaborators: Array<RoleCollaborator>;
  continuationScoreThreshold?: Maybe<Scalars['Float']>;
  countActiveRecruiters: Scalars['Float'];
  countActiveSearchAgreements: Scalars['Float'];
  countCandidates: Scalars['Float'];
  countCandidatesInProcess: Scalars['Float'];
  countInterestedCandidates: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionJSON?: Maybe<Scalars['String']>;
  directSearchAgreement?: Maybe<SearchAgreement>;
  employer: Employer;
  employerId: Scalars['String'];
  employerRoleRequirements: Array<RoleRequirement>;
  enableKnnBackgroundWorkflow: Scalars['Boolean'];
  enableOutreachBackgroundWorkflow: Scalars['Boolean'];
  expectedFirstYearSalaryMax?: Maybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: Maybe<Scalars['Float']>;
  expectedFirstYearShareCount?: Maybe<Scalars['Int']>;
  expectedSalaryUpdatedAt?: Maybe<Scalars['DateTime']>;
  feeGuidance?: Maybe<Scalars['String']>;
  hiringManager?: Maybe<Employee>;
  id: Scalars['ID'];
  idealCandidateDescription?: Maybe<Scalars['String']>;
  idealCandidateProfiles: Array<IdealCandidateProfile>;
  idealCandidateSearchFilters: SearchFilters;
  idealCandidateSearchQuery?: Maybe<Scalars['String']>;
  interviewer?: Maybe<Employee>;
  interviewerId?: Maybe<Scalars['String']>;
  inviteSearchAgreement?: Maybe<SearchAgreement>;
  isArchived: Scalars['Boolean'];
  isDossierShareable: Scalars['Boolean'];
  isRoleAlreadyLunched: Scalars['Boolean'];
  jobDescriptionLink?: Maybe<Scalars['String']>;
  knnProspectLimit: Scalars['Int'];
  leads: Array<Lead>;
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  marketplaceSearchAgreement?: Maybe<SearchAgreement>;
  marketplaceStatusForEmployer: Scalars['String'];
  mergeJob?: Maybe<MergeJob>;
  mostActiveSearchAgreementRole?: Maybe<SearchAgreementRole>;
  mustHaveRoleRequirementsLimitOverride?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  noteJSON?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  oldExpectedSalaryRange?: Maybe<Scalars['String']>;
  outreachSequenceThreshold: Scalars['Int'];
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  prospectSearches: Array<ProspectSearch>;
  prospects: Array<Prospect>;
  publicDossiers: Array<PublicDossier>;
  publicSearchAgreement?: Maybe<SearchAgreement>;
  quantity?: Maybe<Scalars['Int']>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  requestedByEmployee: Employee;
  requestedByEmployeeId?: Maybe<Scalars['String']>;
  roleFunction?: Maybe<RoleFunction>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniority?: Maybe<RoleSeniority>;
  roleTitle?: Maybe<RoleTitle>;
  roleType: RoleTypeEnum;
  scheduleLink?: Maybe<Scalars['String']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  showProspectsAndMetrics: Scalars['Boolean'];
  skipScheduleConfirmation: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type RoleQuestion = {
  __typename?: 'RoleQuestion';
  answer?: Maybe<Scalars['String']>;
  answerer?: Maybe<Employee>;
  answererId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  question: Scalars['String'];
  status: RoleQuestionStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RoleQuestionFilter = {
  roleId: Scalars['String'];
};

export { RoleQuestionStatus };

export type RoleRequirement = {
  __typename?: 'RoleRequirement';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceCandidateReview: Scalars['Boolean'];
  id: Scalars['ID'];
  isCritical: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  mustHave: Scalars['Boolean'];
  prompt: Scalars['String'];
  prospectRoleRequirements: Array<ProspectRoleRequirement>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RoleRequirementFilter = {
  roleId: Scalars['String'];
};

export type RoleRequirementInput = {
  id: Scalars['String'];
  mustHave?: InputMaybe<Scalars['Boolean']>;
  prompt: Scalars['String'];
};

export type RoleRequirementTemplate = {
  __typename?: 'RoleRequirementTemplate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  template: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RoleRequirementTemplateFilter = {
  roleFunctionId: Scalars['String'];
};

export type RoleRequirementsByRole = {
  __typename?: 'RoleRequirementsByRole';
  id: Scalars['String'];
  isCandidateEvaluation: Scalars['Boolean'];
  meetRequirement: MeetRequirementEnum;
  mustHave: Scalars['Boolean'];
  prompt: Scalars['String'];
  requirementExplanation: Scalars['String'];
};

export type RoleRequirementsLlms = {
  __typename?: 'RoleRequirementsLlms';
  id: Scalars['String'];
  meetRequirement: MeetRequirementEnum;
  mustHave: Scalars['Boolean'];
  prompt: Scalars['String'];
  requirementExplanation: Scalars['String'];
};

export type RoleSeniority = {
  __typename?: 'RoleSeniority';
  id: Scalars['ID'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  placements: Array<Placement>;
  roles: Array<Role>;
};

export type RoleTitle = {
  __typename?: 'RoleTitle';
  createdByRecruiter?: Maybe<Recruiter>;
  createdByRecruiterId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  needsReview: Scalars['Boolean'];
  placements: Array<Placement>;
  roles: Array<Role>;
};

export { RoleTypeEnum };

export type RoleWithStatusAndMarketplace = {
  __typename?: 'RoleWithStatusAndMarketplace';
  IsRoleAIActive: Scalars['Boolean'];
  acceptanceRateVisibilityThreshold: Scalars['Float'];
  allDirectSearchAgreements: Array<SearchAgreement>;
  allSearchAgreements: Array<SearchAgreement>;
  allowedAgencies: Array<RoleAgencyAllowList>;
  candidateNote?: Maybe<Scalars['String']>;
  cities: Array<City>;
  closedAt?: Maybe<Scalars['DateTime']>;
  collaborators: Array<RoleCollaborator>;
  continuationScoreThreshold?: Maybe<Scalars['Float']>;
  countActiveRecruiters: Scalars['Float'];
  countActiveSearchAgreements: Scalars['Float'];
  countCandidates: Scalars['Float'];
  countCandidatesInProcess: Scalars['Float'];
  countInterestedCandidates: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionJSON?: Maybe<Scalars['String']>;
  directPrice?: Maybe<Scalars['String']>;
  directSearchAgreement?: Maybe<SearchAgreement>;
  employer: Employer;
  employerId: Scalars['String'];
  employerRoleRequirements: Array<RoleRequirement>;
  enableKnnBackgroundWorkflow: Scalars['Boolean'];
  enableOutreachBackgroundWorkflow: Scalars['Boolean'];
  expectedFirstYearSalaryMax?: Maybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: Maybe<Scalars['Float']>;
  expectedFirstYearShareCount?: Maybe<Scalars['Int']>;
  expectedSalaryUpdatedAt?: Maybe<Scalars['DateTime']>;
  feeGuidance?: Maybe<Scalars['String']>;
  hasRequirements: Scalars['Boolean'];
  hiringManager?: Maybe<Employee>;
  id: Scalars['ID'];
  idealCandidateDescription?: Maybe<Scalars['String']>;
  idealCandidateProfiles: Array<IdealCandidateProfile>;
  idealCandidateSearchFilters: SearchFilters;
  idealCandidateSearchQuery?: Maybe<Scalars['String']>;
  interviewer?: Maybe<Employee>;
  interviewerId?: Maybe<Scalars['String']>;
  invitePrice?: Maybe<Scalars['String']>;
  inviteSearchAgreement?: Maybe<SearchAgreement>;
  isActive: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isDossierShareable: Scalars['Boolean'];
  isHybridRole: Scalars['Boolean'];
  isRoleAlreadyLunched: Scalars['Boolean'];
  jobDescriptionLink?: Maybe<Scalars['String']>;
  knnProspectLimit: Scalars['Int'];
  lastUpdate?: Maybe<Scalars['DateTime']>;
  leads: Array<Lead>;
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  marketplaceSearchAgreement?: Maybe<SearchAgreement>;
  marketplaceStatusForEmployer: Scalars['String'];
  marketplaceVisibility: Scalars['String'];
  mergeJob?: Maybe<MergeJob>;
  mustHaveRoleRequirementsLimitOverride?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  noteJSON?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  oldExpectedSalaryRange?: Maybe<Scalars['String']>;
  outreachSequenceThreshold: Scalars['Int'];
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  placementFee?: Maybe<PlacementFeeOutput>;
  primarySearchAgreement?: Maybe<SearchAgreement>;
  primarySearchAgreementRoleId?: Maybe<Scalars['String']>;
  prospectSearches: Array<ProspectSearch>;
  prospects: Array<Prospect>;
  publicDossiers: Array<PublicDossier>;
  publicPrice?: Maybe<Scalars['String']>;
  publicSearchAgreement?: Maybe<SearchAgreement>;
  quantity?: Maybe<Scalars['Int']>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  requestedByEmployee: Employee;
  requestedByEmployeeId?: Maybe<Scalars['String']>;
  roleFunction?: Maybe<RoleFunction>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniority?: Maybe<RoleSeniority>;
  roleTitle?: Maybe<RoleTitle>;
  roleType: RoleTypeEnum;
  scheduleLink?: Maybe<Scalars['String']>;
  searchAgreementId?: Maybe<Scalars['String']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  searchAgreementStatus: Scalars['String'];
  showProspectsAndMetrics: Scalars['Boolean'];
  showRequirementsBillboard?: Maybe<Scalars['Boolean']>;
  skipScheduleConfirmation: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type SearchAgreement = {
  __typename?: 'SearchAgreement';
  addendums: Array<SearchAgreementAddendum>;
  agency?: Maybe<Agency>;
  anvilDocumentGroupEid?: Maybe<Scalars['String']>;
  archivedStatus: Scalars['String'];
  beginAt?: Maybe<Scalars['DateTime']>;
  canceledByEmployee?: Maybe<Employee>;
  canceledByRecruiter?: Maybe<Recruiter>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedByEmployerId?: Maybe<Scalars['String']>;
  deletedByRecruiterId?: Maybe<Scalars['String']>;
  effectiveAt?: Maybe<Scalars['DateTime']>;
  employeeCanceledAt?: Maybe<Scalars['DateTime']>;
  employeeSignedAt?: Maybe<Scalars['DateTime']>;
  employeeVoidedAt?: Maybe<Scalars['DateTime']>;
  employer: Employer;
  endAt?: Maybe<Scalars['DateTime']>;
  exclusive: Scalars['Boolean'];
  exclusivityCarveOutCandidates?: Maybe<Scalars['String']>;
  expectedPlacements: ExpectedPlacementsEnum;
  feeSchedules: Array<SearchAgreementFeeSchedule>;
  feeType: Scalars['String'];
  fixedMarketplacePercentFee?: Maybe<Scalars['Float']>;
  fulfilledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  includeReplacementSearch: Scalars['Boolean'];
  isExclusivityCarveOutCandidatesSourcedByEmployer: Scalars['Boolean'];
  isExclusivityCarveOutExplicitListCandidates: Scalars['Boolean'];
  isPaused: Scalars['Boolean'];
  isProspect: Scalars['Boolean'];
  isSignedOffPlatform: Scalars['Boolean'];
  marketplacePercentFee: Scalars['Float'];
  marketplaceVisibility: MarketplaceVisibilityEnum;
  payouts: Array<Payout>;
  placementFeeSchedule?: Maybe<SearchAgreementFeeSchedule>;
  previousPriceChangeSearchAgreementId?: Maybe<Scalars['String']>;
  pricingType: PricingTypeEnum;
  recruiterCanceledAt?: Maybe<Scalars['DateTime']>;
  recruiterSignedAt?: Maybe<Scalars['DateTime']>;
  recruiterVoidedAt?: Maybe<Scalars['DateTime']>;
  replacementSearchNotificationWindowDays?: Maybe<Scalars['Int']>;
  replacementSearchTerminateDays?: Maybe<Scalars['Int']>;
  replacementSearchWindowMonths?: Maybe<Scalars['Int']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  signedByEmployee?: Maybe<Employee>;
  signedByEmployeeId?: Maybe<Scalars['String']>;
  signedByRecruiter?: Maybe<Recruiter>;
  signedByRecruiterId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  voidByEmployee: Employee;
  voidByRecruiter: Recruiter;
  whenFirstInvoice: Scalars['String'];
};

export type SearchAgreementAddendum = {
  __typename?: 'SearchAgreementAddendum';
  description: Scalars['String'];
  id: Scalars['ID'];
  searchAgreement: SearchAgreement;
  searchAgreementId: Scalars['String'];
};

export type SearchAgreementFeeSchedule = {
  __typename?: 'SearchAgreementFeeSchedule';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  feeAdjustmentType?: Maybe<FeeAdjustmentTypeEnum>;
  feeAmount: Scalars['Float'];
  feePaymentTermNetDays: Scalars['Int'];
  feeScheduleType: FeeScheduleTypeEnum;
  feeTriggerLimit?: Maybe<Scalars['Int']>;
  feeTriggerType: FeeTriggerTypeEnum;
  id: Scalars['ID'];
  oneTimeFeeRelativeSigningDays?: Maybe<Scalars['Int']>;
  oneTimeFeeTriggerAt?: Maybe<Scalars['DateTime']>;
  performanceCandidateAcceptQuantity?: Maybe<Scalars['Int']>;
  searchAgreement: SearchAgreement;
  updatedAt: Scalars['DateTime'];
};

export type SearchAgreementReview = {
  __typename?: 'SearchAgreementReview';
  candidateSubmissionQuality?: Maybe<Scalars['Float']>;
  communication: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  employer?: Maybe<Employer>;
  id: Scalars['ID'];
  overallRating: Scalars['Float'];
  recruiter?: Maybe<Recruiter>;
  recruiterId?: Maybe<Scalars['String']>;
  responsiveness?: Maybe<Scalars['Float']>;
  review?: Maybe<Scalars['String']>;
  reviewedByEmployee?: Maybe<Employee>;
  reviewedByEmployeeId?: Maybe<Scalars['String']>;
  reviewedByRecruiter?: Maybe<Recruiter>;
  reviewedByRecruiterId?: Maybe<Scalars['String']>;
  searchAgreementRole: SearchAgreementRole;
  timeToPlacement?: Maybe<Scalars['Float']>;
  timeliness: Scalars['Float'];
};

export type SearchAgreementRole = {
  __typename?: 'SearchAgreementRole';
  candidates: Array<Candidate>;
  collaborations: Array<Collaboration>;
  countActiveRecruiters: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role: Role;
  roleId: Scalars['String'];
  searchAgreement: SearchAgreement;
  searchAgreementId: Scalars['String'];
  searchReviews: Array<SearchAgreementReview>;
  updatedAt: Scalars['DateTime'];
};

export type SearchFilters = {
  __typename?: 'SearchFilters';
  must: Array<Scalars['JSON']>;
  must_not: Array<Scalars['JSON']>;
  should: Array<Scalars['JSON']>;
};

export { SearchPreferenceEnum };

export type SendEmailToGetAccessInput = {
  company: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
};

export type SendReportRecruiterReviewInput = {
  employerName: Scalars['String'];
  reason: Scalars['String'];
  recruiterId: Scalars['String'];
  recruiterName: Scalars['String'];
  review: Scalars['String'];
  reviewDate: Scalars['String'];
  reviewId: Scalars['String'];
};

export type SetUserNotificationSettingInput = {
  channel: UserNotificationChannel;
  enabled: Scalars['Boolean'];
  type: UserNotificationEnum;
};

export { SlackChannelType };

export type SlackChannelWithInvitationCheck = {
  __typename?: 'SlackChannelWithInvitationCheck';
  agency?: Maybe<Agency>;
  channelId: Scalars['String'];
  channelName: Scalars['String'];
  channelType: SlackChannelType;
  createdAt: Scalars['DateTime'];
  employer?: Maybe<Employer>;
  hasInvitation: Scalars['Boolean'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  address: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type Talent = {
  __typename?: 'Talent';
  agency?: Maybe<Agency>;
  candidates: Array<Candidate>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  linkedIn: Scalars['String'];
  linkedInProfile?: Maybe<LinkedInProfile>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type TerminateMarketplaceRoleInput = {
  reasonCategory?: InputMaybe<EmployerSearchAgreementActivityCategory>;
  roleId: Scalars['String'];
};

export type Timezone = {
  __typename?: 'Timezone';
  cities: Array<City>;
  id: Scalars['ID'];
  recruiters: Array<Recruiter>;
  timezoneAbbrev: Scalars['String'];
  timezoneName: Scalars['String'];
  timezoneOffset: Scalars['String'];
};

export type TopRoleFunctions = {
  __typename?: 'TopRoleFunctions';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
  placementsCount: Scalars['Float'];
  roles: Array<Role>;
};

export type TopRoleSeniority = {
  __typename?: 'TopRoleSeniority';
  id: Scalars['ID'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  placements: Array<Placement>;
  placementsCount: Scalars['Float'];
  roles: Array<Role>;
};

export type UpdateCandidateInput = {
  baseSalary?: InputMaybe<Scalars['Float']>;
  candidateId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  justification?: InputMaybe<Scalars['String']>;
  justificationJSON?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedIn?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  shareCount?: InputMaybe<Scalars['Float']>;
  skipScheduleConfirmation?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type UpdateEmployeeInput = {
  employer?: InputMaybe<UpdateEmployerInput>;
  linkedIn?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateEmployerInput = {
  companyStage?: InputMaybe<Scalars['String']>;
  crunchbase?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  linkedIn?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateInterviewProcessInput = {
  candidateNote?: InputMaybe<Scalars['String']>;
  interviewerId?: InputMaybe<Scalars['String']>;
  interviewerInvited?: InputMaybe<InterviewerInvited>;
  roleId: Scalars['String'];
  scheduleLink: Scalars['String'];
};

export type UpdateProspectInput = {
  evaluationScore?: InputMaybe<EvaluationStatus>;
};

export type UpdateRoleInput = {
  citiesId?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  expectedFirstYearSalaryMax?: InputMaybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: InputMaybe<Scalars['Float']>;
  expectedFirstYearShareCount?: InputMaybe<Scalars['Int']>;
  hiringManager?: InputMaybe<Scalars['String']>;
  idealCandidateDescription?: InputMaybe<Scalars['String']>;
  isDossierShareable?: InputMaybe<Scalars['Boolean']>;
  jobDescriptionLink?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  remoteWorkPolicy?: InputMaybe<RemoteWorkPolicyEnum>;
  roleFunctionId?: InputMaybe<Scalars['String']>;
  roleSeniorityId?: InputMaybe<Scalars['String']>;
};

export type UpdateRoleOverviewInput = {
  companyIntro?: InputMaybe<Scalars['String']>;
  companyIntroJSON?: InputMaybe<Scalars['String']>;
  companyStageId?: InputMaybe<Scalars['String']>;
  companyTagline?: InputMaybe<Scalars['String']>;
  companyUrl?: InputMaybe<Scalars['String']>;
  crunchbase?: InputMaybe<Scalars['String']>;
  hiringManager?: InputMaybe<Scalars['String']>;
  investors?: InputMaybe<Scalars['String']>;
  latestFundingRoundAmount?: InputMaybe<Scalars['Float']>;
  latestFundingRoundType?: InputMaybe<LatestFundingRoundTypeEnum>;
  linkedIn?: InputMaybe<Scalars['String']>;
  locationIds?: InputMaybe<Array<Scalars['String']>>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfEmployees?: InputMaybe<Scalars['String']>;
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  revenue?: InputMaybe<Scalars['Float']>;
  roleFunctionId?: InputMaybe<Scalars['String']>;
  roleId: Scalars['String'];
  tam?: InputMaybe<Scalars['Float']>;
  totalFunding?: InputMaybe<Scalars['Float']>;
  yoyGrowth?: InputMaybe<Scalars['Float']>;
};

export type UpdateRoleQuestionInput = {
  answer: Scalars['String'];
  isPublic: Scalars['Boolean'];
  roleQuestionId: Scalars['String'];
};

export type UpdateRoleRequirementInput = {
  id: Scalars['String'];
  mustHave?: InputMaybe<Scalars['Boolean']>;
  prompt: Scalars['String'];
  roleId: Scalars['String'];
};

export type UpdateRoleRequirementsInput = {
  deletedRequirements: Array<RoleRequirementInput>;
  roleId: Scalars['String'];
  roleRequirements: Array<RoleRequirementInput>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
};

export type UpdateUserTermsAndPolicyInput = {
  entity?: InputMaybe<EntityTypeEnum>;
  tou: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  employee?: Maybe<Employee>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isHeadRaceAdmin: Scalars['Boolean'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  notificationSettings: Array<UserNotificationSetting>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoUrl: Scalars['String'];
  recruiter?: Maybe<Recruiter>;
  slackUserId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userConsents: Array<UserConsent>;
};

export type UserConsent = {
  __typename?: 'UserConsent';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  user: User;
  userAgent: Scalars['String'];
  userConsentType: Scalars['String'];
  userId: Scalars['String'];
  versionNumber: Scalars['String'];
};

export { UserNotificationChannel };

export { UserNotificationEnum };

export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  channel: UserNotificationChannel;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  type: UserNotificationEnum;
  user: User;
};

export type AiRoleListQueryVariables = Exact<{ [key: string]: never }>;

export type AiRoleListQuery = {
  __typename?: 'Query';
  aiRoleList: Array<{
    __typename?: 'Role';
    id: string;
    name: string;
    employer: {
      __typename?: 'Employer';
      company: { __typename?: 'Company'; name: string };
    };
  }>;
};

export type AcceptAiCandidateForReviewMutationVariables = Exact<{
  candidateId: Scalars['String'];
}>;

export type AcceptAiCandidateForReviewMutation = {
  __typename?: 'Mutation';
  acceptAICandidateForReview: boolean;
};

export type AcceptCandidateMutationVariables = Exact<{
  input: AcceptCandidateInput;
}>;

export type AcceptCandidateMutation = {
  __typename?: 'Mutation';
  acceptCandidate: { __typename?: 'Candidate'; id: string };
};

export type ActiveRoleRecruitersQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type ActiveRoleRecruitersQuery = {
  __typename?: 'Query';
  activeRoleRecruiters: Array<{
    __typename?: 'ActiveRecruitersOutput';
    linkedIn?: string | null;
    id: string;
    candidateSubmittedCount: number;
    candidateInterviewingCount: number;
    candidateHiredCount: number;
    avgAcceptanceRate: number;
    avgRequirementsScore: number;
    marketplaceStatus?: MarketplaceRoleRecruiterStatus | null;
    schedulingLink15Min?: string | null;
    schedulingLink30Min?: string | null;
    schedulingLink60Min?: string | null;
    agency: {
      __typename?: 'Agency';
      id: string;
      name?: string | null;
      website?: string | null;
    };
    user: {
      __typename?: 'User';
      name: string;
      email: string;
      phoneNumber?: string | null;
      photoUrl: string;
    };
  }>;
};

export type AnvilSignaturedFilesQueryVariables = Exact<{
  searchAgreementId: Scalars['String'];
}>;

export type AnvilSignaturedFilesQuery = {
  __typename?: 'Query';
  getAnvilSignaturedFiles?: {
    __typename?: 'DownloadDocumentsResultOutput';
    data: string;
  } | null;
};

export type ArchiveSearchAgreementMutationVariables = Exact<{
  searchAgreementId: Scalars['String'];
}>;

export type ArchiveSearchAgreementMutation = {
  __typename?: 'Mutation';
  archiveSearchAgreement?: string | null;
};

export type ArchivedSearchAgreementByEmployerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ArchivedSearchAgreementByEmployerQuery = {
  __typename?: 'Query';
  archivedSearchAgreementByEmployer: Array<{
    __typename?: 'SearchAgreement';
    id: string;
    status: string;
    updatedAt: Date;
    signedByEmployeeId?: string | null;
    anvilDocumentGroupEid?: string | null;
    marketplaceVisibility: MarketplaceVisibilityEnum;
    employer: {
      __typename?: 'Employer';
      company: { __typename?: 'Company'; name: string };
    };
    agency?: { __typename?: 'Agency'; name?: string | null } | null;
    searchAgreementRoles: Array<{
      __typename?: 'SearchAgreementRole';
      role: { __typename?: 'Role'; id: string; name: string };
    }>;
  }>;
};

export type AvailableRolesAgencyQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type AvailableRolesAgencyQuery = {
  __typename?: 'Query';
  availableRolesAgency?: Array<{
    __typename?: 'Role';
    id: string;
    name: string;
  }> | null;
};

export type AvalaibleRequestProposalQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type AvalaibleRequestProposalQuery = {
  __typename?: 'Query';
  avalaibleRequestProposal: boolean;
};

export type CancelSearchAgreementMutationVariables = Exact<{
  input: EmployerTerminatedSearchAgreementInput;
}>;

export type CancelSearchAgreementMutation = {
  __typename?: 'Mutation';
  cancelSearchAgreement?: string | null;
};

export type CandidateAcceptOfferMutationVariables = Exact<{
  input: CandidateAcceptOfferInput;
}>;

export type CandidateAcceptOfferMutation = {
  __typename?: 'Mutation';
  candidateAcceptOffer: { __typename?: 'Candidate'; id: string };
};

export type CandidateActivityHistoryByCandidateIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CandidateActivityHistoryByCandidateIdQuery = {
  __typename?: 'Query';
  candidateActivityHistoryByCandidateId?: {
    __typename?: 'CandidateActivityHistoryOutput';
    candidateHistory: Array<{
      __typename?: 'CandidateActivityHistory';
      id: string;
      createdAt: Date;
      activityType: CandidateActivityHistoryActivityType;
      entityType: CandidateActivityHistoryEntityType;
      activityMeta?: {
        __typename?: 'CandidateActivityHistoryActivityMetaType';
        rejectReasonCategory?: CandidateRejectedReasonEnum | null;
        rejectReasonDetails?: string | null;
        shareRejectionInfo?: boolean | null;
      } | null;
      candidate: {
        __typename?: 'Candidate';
        talent: { __typename?: 'Talent'; firstName: string; lastName: string };
        recruiter?: {
          __typename?: 'Recruiter';
          id: string;
          agency: { __typename?: 'Agency'; name?: string | null };
          user: { __typename?: 'User'; name: string };
        } | null;
      };
    }>;
  } | null;
};

export type CandidateByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CandidateByIdQuery = {
  __typename?: 'Query';
  candidateById: {
    __typename?: 'CandidateDetailsOutput';
    id: string;
    createdAt: Date;
    status: CandidateActivityHistoryActivityType;
    justification?: string | null;
    justificationJSON?: string | null;
    baseSalary?: number | null;
    shareCount?: number | null;
    startDate?: Date | null;
    isCollaborationCandidate?: boolean | null;
    placementFee?: number | null;
    placementFeeType?: string | null;
    collaboration?: {
      __typename?: 'Collaboration';
      primaryRecruiter: {
        __typename?: 'Recruiter';
        id: string;
        user: { __typename?: 'User'; name: string; photoUrl: string };
        agency: { __typename?: 'Agency'; id: string; name?: string | null };
      };
    } | null;
    roleRequirements?: Array<{
      __typename?: 'CandidateRoleRequirement';
      prompt: string;
      id: string;
      mustHave: boolean;
      meetRequirement?: MeetRequirementEnum | null;
    }> | null;
    talent: {
      __typename?: 'Talent';
      firstName: string;
      lastName: string;
      linkedIn: string;
      email?: string | null;
      phone?: string | null;
      name: string;
    };
    documents?: Array<{
      __typename?: 'CandidateDocument';
      documentPath?: string | null;
      id: string;
      createdAt: Date;
      name: string;
      document: string;
      documentType?: CandidateDocumentTypeEnum | null;
    }> | null;
    searchAgreementRole: {
      __typename?: 'SearchAgreementRole';
      id: string;
      searchAgreementId: string;
      searchReviews: Array<{
        __typename?: 'SearchAgreementReview';
        reviewedByEmployeeId?: string | null;
        recruiterId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        id: string;
        name: string;
        description?: string | null;
        candidateNote?: string | null;
        interviewerId?: string | null;
        scheduleLink?: string | null;
        skipScheduleConfirmation: boolean;
        employer: {
          __typename?: 'Employer';
          company: { __typename?: 'Company'; name: string };
        };
      };
      searchAgreement: {
        __typename?: 'SearchAgreement';
        marketplaceVisibility: MarketplaceVisibilityEnum;
      };
    };
    recruiter?: {
      __typename?: 'Recruiter';
      id: string;
      agency: { __typename?: 'Agency'; id: string; name?: string | null };
      user: {
        __typename?: 'User';
        firstName: string;
        lastName: string;
        name: string;
        email: string;
        photoUrl: string;
      };
    } | null;
  };
};

export type CandidateMarketplaceMetricsQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CandidateMarketplaceMetricsQuery = {
  __typename?: 'Query';
  candidateMarketplaceMetrics: {
    __typename?: 'CandidateStatusMetricsOutput';
    submittedCount: number;
    interviewingCount: number;
    hiredCount: number;
  };
};

export type CandidatesAiMetricsQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type CandidatesAiMetricsQuery = {
  __typename?: 'Query';
  candidatesAIMetrics: {
    __typename?: 'CandidatesAIMetricsOutput';
    candidateAcceptedPercentage: number;
    candidatesPerWeekCount: number;
    totalCandidatesCount: number;
  };
};

export type CandidatesAiRoleQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type CandidatesAiRoleQuery = {
  __typename?: 'Query';
  candidatesAIRole: Array<{
    __typename?: 'CandidatesAIRoleOutput';
    id: string;
    labelCandidateInterest: string;
    roleRequirementScore: number;
    dateSourced?: Date | null;
    candidate: {
      __typename?: 'Candidate';
      id: string;
      status: CandidateActivityHistoryActivityType;
      talent: { __typename?: 'Talent'; name: string; linkedIn: string };
      documents?: Array<{
        __typename?: 'CandidateDocument';
        id: string;
        name: string;
        documentType?: CandidateDocumentTypeEnum | null;
        documentPath?: string | null;
      }> | null;
      searchAgreementRole: {
        __typename?: 'SearchAgreementRole';
        role: {
          __typename?: 'Role';
          employer: {
            __typename?: 'Employer';
            company: { __typename?: 'Company'; name: string };
          };
        };
      };
    };
    prospect: {
      __typename?: 'Prospect';
      id: string;
      publicIdentifier: string;
      linkedInProfile: {
        __typename?: 'LinkedInProfileRaw';
        profile_pic_url?: string | null;
        experiences?: Array<any> | null;
        education?: Array<any> | null;
      };
    };
    roleRequirementsByPriority: Array<{
      __typename?: 'RoleRequirementsByRole';
      prompt: string;
      id: string;
      mustHave: boolean;
      meetRequirement: MeetRequirementEnum;
      requirementExplanation: string;
      isCandidateEvaluation: boolean;
    }>;
  }>;
};

export type CandidatesByEmployerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CandidatesByEmployerQuery = {
  __typename?: 'Query';
  candidatesByEmployer: {
    __typename?: 'CandidatesByStatusOutput';
    candidates: Array<{
      __typename?: 'CandidatesMetrics';
      id: string;
      daysUntilExpirationDate: number;
      roleRequirementsScore: number;
      status: CandidateActivityHistoryActivityType;
      justification?: string | null;
      justificationJSON?: string | null;
      updatedAt: Date;
      fee: string;
      candidateActivityHistories: Array<{
        __typename?: 'CandidateActivityHistory';
        activityMeta?: {
          __typename?: 'CandidateActivityHistoryActivityMetaType';
          rejectReasonCategory?: CandidateRejectedReasonEnum | null;
          shareRejectionInfo?: boolean | null;
        } | null;
      }>;
      roleRequirements?: Array<{
        __typename?: 'CandidateRoleRequirement';
        id: string;
        mustHave: boolean;
        prompt: string;
        meetRequirement?: MeetRequirementEnum | null;
      }> | null;
      documents?: Array<{
        __typename?: 'CandidateDocument';
        id: string;
        documentPath?: string | null;
        documentType?: CandidateDocumentTypeEnum | null;
        name: string;
      }> | null;
      collaboration?: {
        __typename?: 'Collaboration';
        id: string;
        primaryRecruiter: {
          __typename?: 'Recruiter';
          id: string;
          user: { __typename?: 'User'; name: string; photoUrl: string };
          agency: { __typename?: 'Agency'; id: string; name?: string | null };
        };
      } | null;
      recruiter?: {
        __typename?: 'Recruiter';
        id: string;
        user: { __typename?: 'User'; name: string; photoUrl: string };
        agency: { __typename?: 'Agency'; id: string; name?: string | null };
      } | null;
      talent: {
        __typename?: 'Talent';
        name: string;
        linkedIn: string;
        email?: string | null;
        phone?: string | null;
        createdAt: Date;
      };
      searchAgreementRole: {
        __typename?: 'SearchAgreementRole';
        id: string;
        searchReviews: Array<{
          __typename?: 'SearchAgreementReview';
          reviewedByEmployeeId?: string | null;
          reviewedByRecruiterId?: string | null;
          recruiterId?: string | null;
        }>;
        searchAgreement: {
          __typename?: 'SearchAgreement';
          id: string;
          marketplaceVisibility: MarketplaceVisibilityEnum;
        };
        role: {
          __typename?: 'Role';
          id: string;
          name: string;
          candidateNote?: string | null;
          interviewerId?: string | null;
          scheduleLink?: string | null;
          skipScheduleConfirmation: boolean;
          employer: {
            __typename?: 'Employer';
            id: string;
            company: { __typename?: 'Company'; name: string };
          };
          hiringManager?: {
            __typename?: 'Employee';
            user: { __typename?: 'User'; name: string };
          } | null;
        };
      };
    }>;
  };
};

export type CandidatesByRoleQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type CandidatesByRoleQuery = {
  __typename?: 'Query';
  candidatesByRole: {
    __typename?: 'EmployerCandidateWithMetrics';
    candidates: Array<{
      __typename?: 'CandidateWithExpiration';
      id: string;
      daysUntilExpirationDate: number;
      roleRequirementsScore: number;
      createdAt: Date;
      updatedAt: Date;
      status: CandidateActivityHistoryActivityType;
      justification?: string | null;
      justificationJSON?: string | null;
      fee?: string | null;
      talent: {
        __typename?: 'Talent';
        id: string;
        firstName: string;
        lastName: string;
        linkedIn: string;
        name: string;
        email?: string | null;
        createdAt: Date;
        phone?: string | null;
      };
      recruiter?: {
        __typename?: 'Recruiter';
        id: string;
        user: {
          __typename?: 'User';
          firstName: string;
          lastName: string;
          name: string;
          email: string;
          photoUrl: string;
        };
        agency: { __typename?: 'Agency'; id: string; name?: string | null };
      } | null;
      candidateActivityHistories: Array<{
        __typename?: 'CandidateActivityHistory';
        activityMeta?: {
          __typename?: 'CandidateActivityHistoryActivityMetaType';
          rejectReasonCategory?: CandidateRejectedReasonEnum | null;
          shareRejectionInfo?: boolean | null;
        } | null;
      }>;
      roleRequirements?: Array<{
        __typename?: 'CandidateRoleRequirement';
        id: string;
        mustHave: boolean;
        prompt: string;
        meetRequirement?: MeetRequirementEnum | null;
      }> | null;
      documents?: Array<{
        __typename?: 'CandidateDocument';
        id: string;
        documentPath?: string | null;
        documentType?: CandidateDocumentTypeEnum | null;
        name: string;
      }> | null;
      collaboration?: {
        __typename?: 'Collaboration';
        id: string;
        primaryRecruiter: {
          __typename?: 'Recruiter';
          id: string;
          user: {
            __typename?: 'User';
            firstName: string;
            lastName: string;
            name: string;
            photoUrl: string;
          };
          agency: { __typename?: 'Agency'; id: string; name?: string | null };
        };
      } | null;
      searchAgreementRole: {
        __typename?: 'SearchAgreementRole';
        id: string;
        roleId: string;
        searchReviews: Array<{
          __typename?: 'SearchAgreementReview';
          reviewedByEmployeeId?: string | null;
          reviewedByRecruiterId?: string | null;
          recruiterId?: string | null;
        }>;
        searchAgreement: {
          __typename?: 'SearchAgreement';
          id: string;
          marketplaceVisibility: MarketplaceVisibilityEnum;
        };
        role: {
          __typename?: 'Role';
          id: string;
          name: string;
          candidateNote?: string | null;
          interviewerId?: string | null;
          scheduleLink?: string | null;
          skipScheduleConfirmation: boolean;
          employer: {
            __typename?: 'Employer';
            id: string;
            company: { __typename?: 'Company'; name: string };
          };
          hiringManager?: {
            __typename?: 'Employee';
            user: { __typename?: 'User'; name: string };
          } | null;
        };
      };
    }>;
    candidateMetrics: {
      __typename?: 'CandidateMetrics';
      submittedCount: number;
      interviewingCount: number;
      hiredCount: number;
    };
  };
};

export type CandidateMetricsByEmployerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CandidateMetricsByEmployerQuery = {
  __typename?: 'Query';
  candidateMetricsByEmployer: {
    __typename?: 'CandidateMetricsByEmployerOutput';
    submittedCount: number;
    interviewingCount: number;
    hiredCount: number;
  };
};

export type CandidatesToReviewQueryVariables = Exact<{ [key: string]: never }>;

export type CandidatesToReviewQuery = {
  __typename?: 'Query';
  candidatesToReview: Array<{
    __typename?: 'Candidate';
    id: string;
    isExpiringSoon: boolean;
    createdAt: Date;
    status: CandidateActivityHistoryActivityType;
    talent: {
      __typename?: 'Talent';
      id: string;
      name: string;
      email?: string | null;
      linkedIn: string;
    };
    searchAgreementRole: {
      __typename?: 'SearchAgreementRole';
      id: string;
      role: { __typename?: 'Role'; id: string; name: string };
    };
  }>;
};

export type CheckSlackConnectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CheckSlackConnectionQuery = {
  __typename?: 'Query';
  checkSlackConnection: boolean;
};

export type CitiesQueryVariables = Exact<{ [key: string]: never }>;

export type CitiesQuery = {
  __typename?: 'Query';
  cities: Array<{
    __typename?: 'City';
    cityName: string;
    stateAbbrev: string;
    id: string;
  }>;
};

export type CitiesMultiOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type CitiesMultiOptionsQuery = {
  __typename?: 'Query';
  citiesMultiOptions: {
    __typename?: 'CityMultiOptionsOutput';
    cityOptions: Array<{
      __typename?: 'Options';
      value: string;
      label: string;
    }>;
  };
};

export type CloseAiRoleMutationVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type CloseAiRoleMutation = {
  __typename?: 'Mutation';
  closeAIRole: boolean;
};

export type CompanyStagesQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyStagesQuery = {
  __typename?: 'Query';
  companyStages?: Array<{
    __typename?: 'CompanyStage';
    name: string;
    id: string;
  }> | null;
};

export type ContactHeadraceViaSlackMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ContactHeadraceViaSlackMutation = {
  __typename?: 'Mutation';
  contactHeadraceViaSlack: {
    __typename?: 'SlackChannelWithInvitationCheck';
    channelId: string;
    hasInvitation: boolean;
  };
};

export type ConvertProspectToCandidateMutationVariables = Exact<{
  input: ConvertProspectToCandidateInput;
}>;

export type ConvertProspectToCandidateMutation = {
  __typename?: 'Mutation';
  convertProspectToCandidate: boolean;
};

export type CreateAiRoleMutationVariables = Exact<{
  input: CreateAiRoleInput;
}>;

export type CreateAiRoleMutation = {
  __typename?: 'Mutation';
  createAIRole: string;
};

export type CreateCandidateDocumentMutationVariables = Exact<{
  input: CreateCandidateDocumentInput;
}>;

export type CreateCandidateDocumentMutation = {
  __typename?: 'Mutation';
  createCandidateDocument: { __typename?: 'Candidate'; id: string };
};

export type CreateEmployerMutationVariables = Exact<{
  input: CreateEmployerInput;
}>;

export type CreateEmployerMutation = {
  __typename?: 'Mutation';
  createEmployer: { __typename?: 'Employer'; id: string };
};

export type CreateMediaUploadLinkQueryVariables = Exact<{
  data: CreateMediaUploadLinkInput;
}>;

export type CreateMediaUploadLinkQuery = {
  __typename?: 'Query';
  createMediaUploadLink: {
    __typename?: 'MediaUploadLink';
    url: string;
    fields: {
      __typename?: 'MediaUploadLinkFields';
      Policy: string;
      X_Amz_Algorithm: string;
      X_Amz_Credential: string;
      X_Amz_Date: string;
      X_Amz_Signature: string;
      X_Amz_Security_Token?: string | null;
      bucket: string;
      key: string;
    };
  };
};

export type CreateOrUpdateIdealCandidatesProfileMutationVariables = Exact<{
  input: IdealCandidatesProfileInput;
}>;

export type CreateOrUpdateIdealCandidatesProfileMutation = {
  __typename?: 'Mutation';
  createOrUpdateIdealCandidatesProfile: boolean;
};

export type CreateOrUpdateRoleRequirementsMutationVariables = Exact<{
  input: UpdateRoleRequirementsInput;
}>;

export type CreateOrUpdateRoleRequirementsMutation = {
  __typename?: 'Mutation';
  createOrUpdateRoleRequirements: boolean;
};

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput;
}>;

export type CreateRoleMutation = {
  __typename?: 'Mutation';
  createRole: string;
};

export type CreateRoleRequirementMutationVariables = Exact<{
  input: CreateRoleRequirementInput;
}>;

export type CreateRoleRequirementMutation = {
  __typename?: 'Mutation';
  createRoleRequirement: {
    __typename?: 'RoleRequirement';
    createdAt: Date;
    deletedAt?: Date | null;
    id: string;
    mustHave: boolean;
    prompt: string;
    updatedAt?: Date | null;
  };
};

export type CreateRoleRequirementsMutationVariables = Exact<{
  inputs: Array<CreateRoleRequirementInput> | CreateRoleRequirementInput;
}>;

export type CreateRoleRequirementsMutation = {
  __typename?: 'Mutation';
  createRoleRequirements: Array<{
    __typename?: 'RoleRequirement';
    createdAt: Date;
    deletedAt?: Date | null;
    id: string;
    mustHave: boolean;
    prompt: string;
    updatedAt?: Date | null;
  }>;
};

export type CreateSearchReviewMutationVariables = Exact<{
  input: Array<EmployerSearchReviewInput> | EmployerSearchReviewInput;
  searchAgreementRoleId: Scalars['String'];
}>;

export type CreateSearchReviewMutation = {
  __typename?: 'Mutation';
  createSearchReview: boolean;
};

export type CurrentEmployeeQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentEmployeeQuery = {
  __typename?: 'Query';
  currentEmployee: {
    __typename?: 'CurrentEmployee';
    id: string;
    title?: string | null;
    linkedIn?: string | null;
    showSearches: boolean;
    location?: { __typename?: 'City'; id: string; cityName: string } | null;
    user: {
      __typename?: 'User';
      firstName: string;
      lastName: string;
      name: string;
      phoneNumber?: string | null;
      photoUrl: string;
      isHeadRaceAdmin: boolean;
      userConsents: Array<{
        __typename?: 'UserConsent';
        id: string;
        versionNumber: string;
        userConsentType: string;
      }>;
    };
    employer: {
      __typename?: 'Employer';
      id: string;
      isHeadRaceAI: boolean;
      defaultMarketplacePrice: number;
      isTestAccount: boolean;
      primaryAgencyId?: string | null;
      company: {
        __typename?: 'Company';
        name: string;
        description?: string | null;
        logoUrl: string;
        linkedIn?: string | null;
        website?: string | null;
        companyStageId?: string | null;
        crunchbase?: string | null;
        industry?: { __typename?: 'Industry'; id: string; name: string } | null;
        location?: { __typename?: 'City'; id: string; cityName: string } | null;
      };
      primaryAgency?: { __typename?: 'Agency'; name?: string | null } | null;
    };
    employerInvitationsByRecruiter: Array<{
      __typename?: 'EmployerInvitationByRecruiter';
      recruiter: {
        __typename?: 'Recruiter';
        user: { __typename?: 'User'; name: string };
      };
    }>;
    roles: Array<{ __typename?: 'Role'; id: string; name: string }>;
  };
};

export type CustomerBillingInformationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CustomerBillingInformationQuery = {
  __typename?: 'Query';
  getCustomerBillingInformation: {
    __typename?: 'StripeCustomer';
    id: string;
    name: string;
    email: string;
    address: string;
    phone: string;
  };
};

export type DeleteRoleQuestionMutationVariables = Exact<{
  roleQuestionId: Scalars['String'];
}>;

export type DeleteRoleQuestionMutation = {
  __typename?: 'Mutation';
  deleteRoleQuestion: boolean;
};

export type DeleteRoleRequirementMutationVariables = Exact<{
  roleRequirementId: Scalars['String'];
}>;

export type DeleteRoleRequirementMutation = {
  __typename?: 'Mutation';
  deleteRoleRequirement: boolean;
};

export type DeleteRoleRequirementsMutationVariables = Exact<{
  roleIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteRoleRequirementsMutation = {
  __typename?: 'Mutation';
  deleteRoleRequirements: boolean;
};

export type EmployeesQueryVariables = Exact<{ [key: string]: never }>;

export type EmployeesQuery = {
  __typename?: 'Query';
  employees: Array<{
    __typename?: 'Employee';
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      isHeadRaceAdmin: boolean;
      userConsents: Array<{
        __typename?: 'UserConsent';
        versionNumber: string;
      }>;
    };
    employer: {
      __typename?: 'Employer';
      company: { __typename?: 'Company'; name: string };
    };
  }>;
};

export type EmployeesByEmployerQueryVariables = Exact<{ [key: string]: never }>;

export type EmployeesByEmployerQuery = {
  __typename?: 'Query';
  employeesByEmployer: Array<{
    __typename?: 'Employee';
    id: string;
    user: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      name: string;
      photoUrl: string;
    };
  }>;
};

export type EmployerAiRoleByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type EmployerAiRoleByIdQuery = {
  __typename?: 'Query';
  employerAIRoleById: {
    __typename?: 'EmployerAIRolesOutput';
    id: string;
    isRoleAlreadyLunched: boolean;
    mustHaveRoleRequirementsLimitOverride?: number | null;
    candidateNeedsReviewCount: number;
    name: string;
    description?: string | null;
    createdAt: Date;
    updatedAt: Date;
    quantity?: number | null;
    expectedFirstYearSalaryMin?: number | null;
    expectedFirstYearSalaryMax?: number | null;
    expectedFirstYearShareCount?: number | null;
    jobDescriptionLink?: string | null;
    remoteWorkPolicy: RemoteWorkPolicyEnum;
    scheduleLink?: string | null;
    candidateNote?: string | null;
    idealCandidateDescription?: string | null;
    employer: {
      __typename?: 'Employer';
      id: string;
      primaryAgencyId?: string | null;
      company: { __typename?: 'Company'; name: string };
    };
    interviewer?: {
      __typename?: 'Employee';
      id: string;
      user: { __typename?: 'User'; name: string };
    } | null;
    cities: Array<{
      __typename?: 'City';
      id: string;
      cityName: string;
      stateAbbrev: string;
    }>;
    employerRoleRequirements: Array<{
      __typename?: 'RoleRequirement';
      mustHave: boolean;
      id: string;
      prompt: string;
    }>;
    idealCandidateProfiles: Array<{
      __typename?: 'IdealCandidateProfile';
      id: string;
      linkedIn: string;
    }>;
    searchAgreementRoles: Array<{
      __typename?: 'SearchAgreementRole';
      searchAgreement: {
        __typename?: 'SearchAgreement';
        id: string;
        status: string;
        createdAt: Date;
      };
    }>;
  };
};

export type EmployerBillingInformationUpdateLinkQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EmployerBillingInformationUpdateLinkQuery = {
  __typename?: 'Query';
  getEmployerBillingInformationUpdateLink: string;
};

export type EmployerRolesQueryVariables = Exact<{ [key: string]: never }>;

export type EmployerRolesQuery = {
  __typename?: 'Query';
  roles: Array<{
    __typename?: 'RoleList';
    id: string;
    name: string;
    description?: string | null;
    quantity?: number | null;
    createdAt: Date;
    updatedAt: Date;
    expectedFirstYearSalaryMin?: number | null;
    expectedFirstYearSalaryMax?: number | null;
    countCandidates: number;
    countCandidatesInProcess: number;
    countInterestedCandidates: number;
    roleFunction?: { __typename?: 'RoleFunction'; name: string } | null;
    employer: {
      __typename?: 'Employer';
      defaultMarketplacePrice: number;
      company: { __typename?: 'Company'; name: string };
    };
    inviteSearchAgreement?: {
      __typename?: 'SearchAgreement';
      marketplaceVisibility: MarketplaceVisibilityEnum;
    } | null;
    searchAgreementRoles: Array<{
      __typename?: 'SearchAgreementRole';
      searchAgreement: {
        __typename?: 'SearchAgreement';
        marketplaceVisibility: MarketplaceVisibilityEnum;
      };
    }>;
    mostActiveSearchAgreementRole?: {
      __typename?: 'SearchAgreementRole';
      countActiveRecruiters: number;
      searchAgreement: {
        __typename?: 'SearchAgreement';
        id: string;
        status: string;
        marketplaceVisibility: MarketplaceVisibilityEnum;
      };
    } | null;
    hiringManager?: {
      __typename?: 'Employee';
      user: { __typename?: 'User'; name: string };
    } | null;
  }>;
};

export type HasArchivedSearchAgreementsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type HasArchivedSearchAgreementsQuery = {
  __typename?: 'Query';
  hasArchivedSearchAgreements: boolean;
};

export type InviteMarketplaceRecruiterByEmployerMutationVariables = Exact<{
  roleId: Scalars['String'];
  recruiterId: Scalars['String'];
}>;

export type InviteMarketplaceRecruiterByEmployerMutation = {
  __typename?: 'Mutation';
  inviteMarketplaceRecruiterByEmployer: boolean;
};

export type JoinEmployerSlackChannelsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type JoinEmployerSlackChannelsMutation = {
  __typename?: 'Mutation';
  joinEmployerSlackChannels: boolean;
};

export type LaunchAiRoleMutationVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type LaunchAiRoleMutation = {
  __typename?: 'Mutation';
  launchAIRole: boolean;
};

export type ListEmployerPaymentInvoicesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListEmployerPaymentInvoicesQuery = {
  __typename?: 'Query';
  listEmployerPaymentInvoices: Array<{
    __typename?: 'PaymentInvoice';
    id: string;
    feeTriggerType: string;
    amount: number;
    date: Date;
    agencyName: string;
    invoiceStatus: string;
    searchAgreementId: string;
    invoiceUrl: string;
  }>;
};

export type ListEmployerPaymentMethodsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListEmployerPaymentMethodsQuery = {
  __typename?: 'Query';
  listEmployerPaymentMethods: Array<{
    __typename?: 'PaymentMethod';
    id: string;
    type: string;
    name: string;
    description: string;
  }>;
};

export type LoadFixturesMutationVariables = Exact<{ [key: string]: never }>;

export type LoadFixturesMutation = {
  __typename?: 'Mutation';
  loadFixtures: boolean;
};

export type OpenRolesByEmployerQueryVariables = Exact<{ [key: string]: never }>;

export type OpenRolesByEmployerQuery = {
  __typename?: 'Query';
  openRolesByEmployer: Array<{
    __typename?: 'Role';
    id: string;
    name: string;
    description?: string | null;
    quantity?: number | null;
    createdAt: Date;
    updatedAt: Date;
    expectedFirstYearSalaryMin?: number | null;
    expectedFirstYearSalaryMax?: number | null;
    employer: {
      __typename?: 'Employer';
      company: { __typename?: 'Company'; name: string };
    };
    cities: Array<{
      __typename?: 'City';
      cityName: string;
      stateAbbrev: string;
    }>;
  }>;
};

export type OutreachRoleMetricsQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type OutreachRoleMetricsQuery = {
  __typename?: 'Query';
  outreachRoleMetrics: {
    __typename?: 'OutreachRoleMetricsOutput';
    outreachInProgressMetrics: {
      __typename?: 'OutreachInProgressMetrics';
      initialOutreach: number;
      firstFollowUp: number;
      secondFollowUp: number;
    };
    outreachFinalizedMetrics: {
      __typename?: 'OutreachFinalizedMetrics';
      interested: number;
      notInterested: number;
      noResponse: number;
    };
  };
};

export type OutreachRoleSequencesQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type OutreachRoleSequencesQuery = {
  __typename?: 'Query';
  outreachRoleSequences: Array<{
    __typename?: 'OutreachSequenceOutput';
    id: string;
    candidate: string;
    stage: OutreachSequenceStatus;
    outreachMessageType?: OutreachMessageType | null;
    linkedIn: string;
    dateSource: Date;
    profilePicture?: string | null;
    outreachChannel: OutreachChannelEnum;
    frontEmailMessages: Array<{
      __typename?: 'FrontEmailMessage';
      id: string;
      body?: string | null;
      type: MessageDirectionType;
      createdAt: Date;
      outreachMessage?: {
        __typename?: 'OutreachMessage';
        id: string;
        scheduledAt?: Date | null;
        sentAt?: Date | null;
        type: OutreachMessageType;
      } | null;
    }>;
    outreachMessages: Array<{
      __typename?: 'OutreachMessage';
      id: string;
      scheduledAt?: Date | null;
      sentAt?: Date | null;
      type: OutreachMessageType;
      message: string;
    }>;
  }>;
};

export type PauseMarketplaceRoleMutationVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type PauseMarketplaceRoleMutation = {
  __typename?: 'Mutation';
  pauseMarketplaceRole?: boolean | null;
};

export type PlacementCompaniesQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type PlacementCompaniesQuery = {
  __typename?: 'Query';
  placementCompanies: Array<{
    __typename?: 'Company';
    id: string;
    name: string;
    logoUrl: string;
  }>;
};

export type ProspectMetricsQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type ProspectMetricsQuery = {
  __typename?: 'Query';
  prospectMetrics: {
    __typename?: 'ProspectMetricsOutput';
    prospectReviewedCount: number;
    prospectQualifiedCount: number;
    outreachSequencesCount: number;
    candidatesInterestedCount: number;
  };
};

export type ProspectNotInterestedMutationVariables = Exact<{
  publicDossierId: Scalars['String'];
}>;

export type ProspectNotInterestedMutation = {
  __typename?: 'Mutation';
  prospectNotInterested: boolean;
};

export type ProspectPublicDossierQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ProspectPublicDossierQuery = {
  __typename?: 'Query';
  prospectPublicDossier?: {
    __typename?: 'ProspectPublicDossierOutput';
    id: string;
    status?: OutreachSequenceStatus | null;
    prospect?: {
      __typename?: 'Prospect';
      id: string;
      candidateId?: string | null;
      linkedInProfile: {
        __typename?: 'LinkedInProfileRaw';
        public_identifier: string;
        first_name?: string | null;
        last_name?: string | null;
      };
    } | null;
    candidateRoleRequirements: Array<{
      __typename?: 'RoleRequirement';
      mustHave: boolean;
      id: string;
      prompt: string;
    }>;
    role: {
      __typename?: 'Role';
      name: string;
      createdAt: Date;
      quantity?: number | null;
      description?: string | null;
      descriptionJSON?: string | null;
      expectedFirstYearSalaryMin?: number | null;
      expectedFirstYearSalaryMax?: number | null;
      expectedFirstYearShareCount?: number | null;
      remoteWorkPolicy: RemoteWorkPolicyEnum;
      employer: {
        __typename?: 'Employer';
        isHeadRaceAI: boolean;
        company: {
          __typename?: 'Company';
          name: string;
          logo?: string | null;
          logoUrl: string;
          linkedIn?: string | null;
          crunchbase?: string | null;
          website?: string | null;
          intro?: string | null;
          introJSON?: string | null;
          description?: string | null;
          tagline?: string | null;
          overviewJSON?: string | null;
          revenue?: number | null;
          totalFunding?: number | null;
          yoyGrowth?: number | null;
          tam?: number | null;
          latestFundingRoundAmount?: number | null;
          latestFundingRoundType?: LatestFundingRoundTypeEnum | null;
          investors?: string | null;
          employeeCount?: string | null;
          teamMembers: Array<{
            __typename?: 'CompanyTeamMember';
            id: string;
            name: string;
            title: string;
            linkedin: string;
          }>;
          perks: Array<{
            __typename?: 'CompanyPerk';
            id: string;
            type: CompanyPerkEnum;
          }>;
          press: Array<{
            __typename?: 'CompanyPress';
            id: string;
            title: string;
            url: string;
          }>;
          companyStage?: {
            __typename?: 'CompanyStage';
            id: string;
            name: string;
          } | null;
        };
      };
      roleSeniority?: {
        __typename?: 'RoleSeniority';
        id: string;
        name: string;
      } | null;
      roleFunction?: {
        __typename?: 'RoleFunction';
        id: string;
        name: string;
      } | null;
      cities: Array<{
        __typename?: 'City';
        id: string;
        cityName: string;
        stateAbbrev: string;
      }>;
      roleRequirements: Array<{
        __typename?: 'RoleRequirement';
        mustHave: boolean;
        id: string;
        prompt: string;
      }>;
    };
  } | null;
};

export type ProspectSearchesByRoleIdQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type ProspectSearchesByRoleIdQuery = {
  __typename?: 'Query';
  prospectSearchesByRoleId: Array<{
    __typename?: 'ProspectSearch';
    id: string;
    createdAt: Date;
    query?: string | null;
    parentProspectSearchId?: string | null;
    linkedInPublicIdentifier?: string | null;
    searchType: ProspectSearchType;
    qualifiedProspectPercentage: number;
    prospectsInterestedCount: number;
    prospects: Array<{
      __typename?: 'Prospect';
      createdAt: Date;
      publicIdentifier: string;
      evaluationScore?: EvaluationStatus | null;
      status: ProspectStatus;
      matchPercentage: number;
      linkedInProfile: {
        __typename?: 'LinkedInProfileRaw';
        full_name?: string | null;
      };
    }>;
  }>;
};

export type ProspectsByRoleIdQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type ProspectsByRoleIdQuery = {
  __typename?: 'Query';
  prospectsByRoleId: Array<{
    __typename?: 'ProspectsByRoleIdOutput';
    id: string;
    status: ProspectStatus;
    publicIdentifier: string;
    evaluationScore?: EvaluationStatus | null;
    roleRequirementPercentage: number;
    createdAt: Date;
    roleMeetRequirementsCount: number;
    roleRequirementsEvaluatedCount: number;
    roleRequirements: Array<{
      __typename?: 'RoleRequirementsLlms';
      id: string;
      prompt: string;
      requirementExplanation: string;
      meetRequirement: MeetRequirementEnum;
      mustHave: boolean;
    }>;
    linkedInProfile: {
      __typename?: 'LinkedInProfileRaw';
      name: string;
      occupation?: string | null;
      city?: string | null;
      country?: string | null;
      experiences?: Array<any> | null;
      education?: Array<any> | null;
      profile_pic_url?: string | null;
    };
    outreachSequence?: {
      __typename?: 'OutreachSequence';
      status: OutreachSequenceStatus;
    } | null;
  }>;
};

export type PublishRoleMutationVariables = Exact<{
  input: PublishRoleInput;
}>;

export type PublishRoleMutation = {
  __typename?: 'Mutation';
  publishRole: boolean;
};

export type RecommendedRecruitersByRoleQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type RecommendedRecruitersByRoleQuery = {
  __typename?: 'Query';
  recommendedRecruitersByRole: Array<{
    __typename?: 'RecommendedRecruiterOutput';
    id: string;
    isRequested: boolean;
    isPrimaryAgency: boolean;
    placementsCompanyStages: Array<string>;
    placementsByFunctionCount: number;
    timeToPlacement: number;
    isRequestedToDirect: boolean;
    isInvitedToMarketplaceRole: boolean;
    topRoleSeniority?: { __typename?: 'TopRoleSeniority'; name: string } | null;
    role: {
      __typename?: 'Role';
      id: string;
      leads: Array<{
        __typename?: 'Lead';
        requestedRecruiterId?: string | null;
      }>;
    };
    recruiter: {
      __typename?: 'Recruiter';
      id: string;
      linkedIn?: string | null;
      minPricePercent?: number | null;
      maxPricePercent?: number | null;
      schedulingLink15Min?: string | null;
      schedulingLink30Min?: string | null;
      schedulingLink60Min?: string | null;
      title?: string | null;
      bio?: string | null;
      searchPreference?: SearchPreferenceEnum | null;
      user: {
        __typename?: 'User';
        name: string;
        email: string;
        phoneNumber?: string | null;
        photoUrl: string;
      };
      agency: {
        __typename?: 'Agency';
        id: string;
        name?: string | null;
        website?: string | null;
      };
      location?: {
        __typename?: 'City';
        id: string;
        cityName: string;
        stateAbbrev: string;
      } | null;
      roleFunctions: Array<{
        __typename?: 'RoleFunction';
        id: string;
        name: string;
      }>;
    };
  }>;
};

export type RecruiterCommonPlacedInstitutionsQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type RecruiterCommonPlacedInstitutionsQuery = {
  __typename?: 'Query';
  recruiterCommonPlacedInstitutions: Array<{
    __typename?: 'CommonEducationInstitution';
    id: string;
    name: string;
    placementsCount: number;
  }>;
};

export type RecruiterPlacementAverageTenureQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type RecruiterPlacementAverageTenureQuery = {
  __typename?: 'Query';
  placementAverageTenure: number;
};

export type RecruiterPlacementGeographiesQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type RecruiterPlacementGeographiesQuery = {
  __typename?: 'Query';
  recruiterPlacementGeographies: Array<{
    __typename?: 'PlacementGeographyGraphData';
    cityCode: string;
    cityName: string;
    placementsCount: number;
    longitude: number;
    latitude: number;
  }>;
};

export type RecruiterRelevancesByRoleQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type RecruiterRelevancesByRoleQuery = {
  __typename?: 'Query';
  recruiterRelevancesByRole: Array<{
    __typename?: 'RecruiterRelevanceOutput';
    id: string;
    linkedIn?: string | null;
    minPricePercent?: number | null;
    maxPricePercent?: number | null;
    timeToPlacement: number;
    schedulingLink15Min?: string | null;
    schedulingLink30Min?: string | null;
    schedulingLink60Min?: string | null;
    title?: string | null;
    bio?: string | null;
    searchPreference?: SearchPreferenceEnum | null;
    placementsCompanyStages: Array<string>;
    placementsByFunctionCount: number;
    isRequested: boolean;
    isRequestedToDirect: boolean;
    isInvitedToMarketplaceRole: boolean;
    user: {
      __typename?: 'User';
      name: string;
      email: string;
      phoneNumber?: string | null;
      photoUrl: string;
    };
    agency: {
      __typename?: 'Agency';
      id: string;
      name?: string | null;
      website?: string | null;
    };
    location?: {
      __typename?: 'City';
      id: string;
      cityName: string;
      stateAbbrev: string;
    } | null;
    roleFunctions: Array<{
      __typename?: 'RoleFunction';
      id: string;
      name: string;
    }>;
    topRoleSeniority?: { __typename?: 'TopRoleSeniority'; name: string } | null;
  }>;
};

export type RejectAiCandidateMutationVariables = Exact<{
  candidateId: Scalars['String'];
}>;

export type RejectAiCandidateMutation = {
  __typename?: 'Mutation';
  rejectAICandidate: boolean;
};

export type RejectCandidateMutationVariables = Exact<{
  input: RejectCandidateInput;
}>;

export type RejectCandidateMutation = {
  __typename?: 'Mutation';
  rejectCandidate: { __typename?: 'Candidate'; id: string };
};

export type RemoveEmployerPaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
}>;

export type RemoveEmployerPaymentMethodMutation = {
  __typename?: 'Mutation';
  removeEmployerPaymentMethod: boolean;
};

export type RemoveInviteRecruiterMutationVariables = Exact<{
  recruiterId: Scalars['String'];
  roleId: Scalars['String'];
}>;

export type RemoveInviteRecruiterMutation = {
  __typename?: 'Mutation';
  removeInviteRecruiter?: boolean | null;
};

export type RemoveRoleCollaboratorMutationVariables = Exact<{
  roleId: Scalars['String'];
  userId: Scalars['String'];
}>;

export type RemoveRoleCollaboratorMutation = {
  __typename?: 'Mutation';
  removeRoleCollaborator: boolean;
};

export type RequestProposalMutationVariables = Exact<{
  requestProposalInput: RequestProposalEmployerInput;
}>;

export type RequestProposalMutation = {
  __typename?: 'Mutation';
  requestProposal?: string | null;
};

export type ResumeMarketplaceRoleMutationVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type ResumeMarketplaceRoleMutation = {
  __typename?: 'Mutation';
  resumeMarketplaceRole?: boolean | null;
};

export type RoleByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type RoleByIdQuery = {
  __typename?: 'Query';
  employerRoleById?: {
    __typename?: 'RoleWithStatusAndMarketplace';
    id: string;
    skipScheduleConfirmation: boolean;
    scheduleLink?: string | null;
    candidateNote?: string | null;
    invitePrice?: string | null;
    directPrice?: string | null;
    publicPrice?: string | null;
    roleType: RoleTypeEnum;
    name: string;
    description?: string | null;
    createdAt: Date;
    updatedAt: Date;
    quantity?: number | null;
    expectedFirstYearSalaryMin?: number | null;
    expectedFirstYearSalaryMax?: number | null;
    expectedFirstYearShareCount?: number | null;
    jobDescriptionLink?: string | null;
    hasRequirements: boolean;
    remoteWorkPolicy: RemoteWorkPolicyEnum;
    noteJSON?: string | null;
    showRequirementsBillboard?: boolean | null;
    marketplaceStatusForEmployer: string;
    status?: string | null;
    isHybridRole: boolean;
    primarySearchAgreementRoleId?: string | null;
    interviewer?: {
      __typename?: 'Employee';
      id: string;
      user: { __typename?: 'User'; name: string };
    } | null;
    employer: {
      __typename?: 'Employer';
      id: string;
      primaryAgencyId?: string | null;
      company: { __typename?: 'Company'; name: string };
    };
    hiringManager?: {
      __typename?: 'Employee';
      id: string;
      user: {
        __typename?: 'User';
        name: string;
        email: string;
        phoneNumber?: string | null;
      };
    } | null;
    roleSeniority?: {
      __typename?: 'RoleSeniority';
      id: string;
      name: string;
    } | null;
    roleFunction?: {
      __typename?: 'RoleFunction';
      id: string;
      name: string;
    } | null;
    cities: Array<{
      __typename?: 'City';
      id: string;
      cityName: string;
      stateAbbrev: string;
    }>;
    inviteSearchAgreement?: {
      __typename?: 'SearchAgreement';
      id: string;
      status: string;
      createdAt: Date;
    } | null;
    publicSearchAgreement?: {
      __typename?: 'SearchAgreement';
      id: string;
      status: string;
      createdAt: Date;
    } | null;
    directSearchAgreement?: {
      __typename?: 'SearchAgreement';
      id: string;
      status: string;
      createdAt: Date;
    } | null;
    placementFee?: {
      __typename?: 'PlacementFeeOutput';
      feeType: FeeScheduleTypeEnum;
      amount: number;
    } | null;
    primarySearchAgreement?: {
      __typename?: 'SearchAgreement';
      id: string;
      status: string;
      marketplaceVisibility: MarketplaceVisibilityEnum;
      createdAt: Date;
      placementFeeSchedule?: {
        __typename?: 'SearchAgreementFeeSchedule';
        feeScheduleType: FeeScheduleTypeEnum;
        feeAmount: number;
      } | null;
    } | null;
    allSearchAgreements: Array<{
      __typename?: 'SearchAgreement';
      id: string;
      status: string;
      createdAt: Date;
      marketplaceVisibility: MarketplaceVisibilityEnum;
      placementFeeSchedule?: {
        __typename?: 'SearchAgreementFeeSchedule';
        feeScheduleType: FeeScheduleTypeEnum;
        feeAmount: number;
      } | null;
    }>;
    allDirectSearchAgreements: Array<{
      __typename?: 'SearchAgreement';
      id: string;
      status: string;
      exclusive: boolean;
      whenFirstInvoice: string;
      beginAt?: Date | null;
      feeSchedules: Array<{
        __typename?: 'SearchAgreementFeeSchedule';
        feeScheduleType: FeeScheduleTypeEnum;
        feeAmount: number;
        feeTriggerType: FeeTriggerTypeEnum;
      }>;
      agency?: { __typename?: 'Agency'; name?: string | null } | null;
    }>;
  } | null;
};

export type RoleCollaboratorsQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type RoleCollaboratorsQuery = {
  __typename?: 'Query';
  roleCollaborators: Array<{
    __typename?: 'RoleCollaborator';
    user: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      photoUrl: string;
    };
  }>;
};

export type RoleEnrichmentOptionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RoleEnrichmentOptionsQuery = {
  __typename?: 'Query';
  roleFunctions?: Array<{
    __typename?: 'RoleFunction';
    id: string;
    name: string;
  }> | null;
  roleSeniorities?: Array<{
    __typename?: 'RoleSeniority';
    id: string;
    name: string;
  }> | null;
  cities: Array<{
    __typename?: 'City';
    id: string;
    cityName: string;
    stateName: string;
    stateAbbrev: string;
  }>;
};

export type RoleQuestionsQueryVariables = Exact<{
  filter: RoleQuestionFilter;
}>;

export type RoleQuestionsQuery = {
  __typename?: 'Query';
  roleQuestions: Array<{
    __typename?: 'RoleQuestion';
    id: string;
    question: string;
    answer?: string | null;
    updatedAt?: Date | null;
    isPublic: boolean;
    answerer?: {
      __typename?: 'Employee';
      user: { __typename?: 'User'; photoUrl: string; name: string };
    } | null;
  }>;
};

export type RoleRequirementTemplatesQueryVariables = Exact<{
  filter: RoleRequirementTemplateFilter;
}>;

export type RoleRequirementTemplatesQuery = {
  __typename?: 'Query';
  roleRequirementTemplates: Array<{
    __typename?: 'RoleRequirementTemplate';
    createdAt: Date;
    id: string;
    template: string;
    updatedAt: Date;
  }>;
};

export type RoleRequirementsQueryVariables = Exact<{
  filter: RoleRequirementFilter;
}>;

export type RoleRequirementsQuery = {
  __typename?: 'Query';
  roleRequirements: Array<{
    __typename?: 'RoleRequirement';
    createdAt: Date;
    deletedAt?: Date | null;
    mustHave: boolean;
    id: string;
    prompt: string;
    updatedAt?: Date | null;
  }>;
};

export type ActiveRolesNumberByEmployerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ActiveRolesNumberByEmployerQuery = {
  __typename?: 'Query';
  activeRolesNumberByEmployer: number;
};

export type SearchAgreementByEmployerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SearchAgreementByEmployerQuery = {
  __typename?: 'Query';
  searchAgreementByEmployer: Array<{
    __typename?: 'SearchAgreement';
    id: string;
    status: string;
    updatedAt: Date;
    signedByEmployeeId?: string | null;
    anvilDocumentGroupEid?: string | null;
    marketplaceVisibility: MarketplaceVisibilityEnum;
    employer: {
      __typename?: 'Employer';
      company: { __typename?: 'Company'; name: string };
    };
    agency?: { __typename?: 'Agency'; name?: string | null } | null;
    searchAgreementRoles: Array<{
      __typename?: 'SearchAgreementRole';
      role: { __typename?: 'Role'; id: string; name: string };
    }>;
  }>;
};

export type SearchAgreementByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type SearchAgreementByIdQuery = {
  __typename?: 'Query';
  searchAgreementById?: {
    __typename?: 'SearchAgreement';
    id: string;
    status: string;
    createdAt: Date;
    beginAt?: Date | null;
    effectiveAt?: Date | null;
    expectedPlacements: ExpectedPlacementsEnum;
    whenFirstInvoice: string;
    anvilDocumentGroupEid?: string | null;
    employeeSignedAt?: Date | null;
    feeType: string;
    recruiterSignedAt?: Date | null;
    employeeCanceledAt?: Date | null;
    recruiterCanceledAt?: Date | null;
    fulfilledAt?: Date | null;
    endAt?: Date | null;
    exclusive: boolean;
    isSignedOffPlatform: boolean;
    marketplaceVisibility: MarketplaceVisibilityEnum;
    employer: {
      __typename?: 'Employer';
      company: {
        __typename?: 'Company';
        name: string;
        description?: string | null;
        linkedIn?: string | null;
        crunchbase?: string | null;
      };
    };
    searchAgreementRoles: Array<{
      __typename?: 'SearchAgreementRole';
      role: {
        __typename?: 'Role';
        id: string;
        name: string;
        expectedFirstYearSalaryMin?: number | null;
        expectedFirstYearSalaryMax?: number | null;
        expectedFirstYearShareCount?: number | null;
        cities: Array<{
          __typename?: 'City';
          cityName: string;
          stateAbbrev: string;
        }>;
      };
    }>;
    agency?: {
      __typename?: 'Agency';
      name?: string | null;
      website?: string | null;
      linkedIn?: string | null;
    } | null;
    feeSchedules: Array<{
      __typename?: 'SearchAgreementFeeSchedule';
      id: string;
      feeScheduleType: FeeScheduleTypeEnum;
      feeAmount: number;
      feeTriggerType: FeeTriggerTypeEnum;
      feeAdjustmentType?: FeeAdjustmentTypeEnum | null;
      feePaymentTermNetDays: number;
      feeTriggerLimit?: number | null;
      performanceCandidateAcceptQuantity?: number | null;
      oneTimeFeeTriggerAt?: Date | null;
      oneTimeFeeRelativeSigningDays?: number | null;
    }>;
    signedByEmployee?: {
      __typename?: 'Employee';
      id: string;
      user: {
        __typename?: 'User';
        firstName: string;
        lastName: string;
        email: string;
        name: string;
      };
    } | null;
    signedByRecruiter?: {
      __typename?: 'Recruiter';
      id: string;
      linkedIn?: string | null;
      bio?: string | null;
      bioJSON?: string | null;
      user: {
        __typename?: 'User';
        firstName: string;
        lastName: string;
        name: string;
        email: string;
      };
    } | null;
    canceledByEmployee?: {
      __typename?: 'Employee';
      user: { __typename?: 'User'; name: string };
    } | null;
    canceledByRecruiter?: {
      __typename?: 'Recruiter';
      user: { __typename?: 'User'; name: string };
    } | null;
  } | null;
};

export type SenEmailToGetAccessQueryVariables = Exact<{
  input: SendEmailToGetAccessInput;
}>;

export type SenEmailToGetAccessQuery = {
  __typename?: 'Query';
  sendEmailToGetAccess: boolean;
};

export type SetupIntentClientSecretQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SetupIntentClientSecretQuery = {
  __typename?: 'Query';
  setupIntentClientSecret?: string | null;
};

export type SetupIntentStatusByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type SetupIntentStatusByIdQuery = {
  __typename?: 'Query';
  setupIntentStatusById: string;
};

export type TerminateMarketplaceRoleMutationVariables = Exact<{
  input: TerminateMarketplaceRoleInput;
}>;

export type TerminateMarketplaceRoleMutation = {
  __typename?: 'Mutation';
  terminateMarketplaceRole?: boolean | null;
};

export type TimeToReviewCandidateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TimeToReviewCandidateQuery = {
  __typename?: 'Query';
  timeToReviewCandidate: string;
};

export type UnarchiveSearchAgreementMutationVariables = Exact<{
  searchAgreementId: Scalars['String'];
}>;

export type UnarchiveSearchAgreementMutation = {
  __typename?: 'Mutation';
  unarchiveSearchAgreement?: string | null;
};

export type UpdateCandidateMutationVariables = Exact<{
  input: UpdateCandidateInput;
}>;

export type UpdateCandidateMutation = {
  __typename?: 'Mutation';
  updateCandidate: { __typename?: 'Candidate'; id: string };
};

export type UpdateCompanyOverviewMutationVariables = Exact<{
  input: EmployerUpdateCompanyOverviewInput;
}>;

export type UpdateCompanyOverviewMutation = {
  __typename?: 'Mutation';
  updateCompanyOverview: boolean;
};

export type UpdateCompanyPerksMutationVariables = Exact<{
  perks: Array<EmployerUpdateCompanyPerkInput> | EmployerUpdateCompanyPerkInput;
}>;

export type UpdateCompanyPerksMutation = {
  __typename?: 'Mutation';
  updateCompanyPerks: boolean;
};

export type UpdateCompanyPressMutationVariables = Exact<{
  pressList:
    | Array<EmployerUpdateCompanyPressInput>
    | EmployerUpdateCompanyPressInput;
}>;

export type UpdateCompanyPressMutation = {
  __typename?: 'Mutation';
  updateCompanyPress: boolean;
};

export type UpdateCompanyTeamMembersMutationVariables = Exact<{
  members:
    | Array<EmployerUpdateCompanyTeamMemberInput>
    | EmployerUpdateCompanyTeamMemberInput;
}>;

export type UpdateCompanyTeamMembersMutation = {
  __typename?: 'Mutation';
  updateCompanyTeamMembers: boolean;
};

export type UpdateEmployeeMutationVariables = Exact<{
  input: UpdateEmployeeInput;
}>;

export type UpdateEmployeeMutation = {
  __typename?: 'Mutation';
  updateEmployee: {
    __typename?: 'Employee';
    employer: {
      __typename?: 'Employer';
      company: { __typename?: 'Company'; name: string; logoUrl: string };
    };
  };
};

export type UpdateInterviewProcessMutationVariables = Exact<{
  input: UpdateInterviewProcessInput;
}>;

export type UpdateInterviewProcessMutation = {
  __typename?: 'Mutation';
  updateInterviewProcess: boolean;
};

export type UpdateDefaultMarketplacePriceMutationVariables = Exact<{
  publicPrice: Scalars['Float'];
}>;

export type UpdateDefaultMarketplacePriceMutation = {
  __typename?: 'Mutation';
  updateDefaultMarketplacePrice: number;
};

export type UpdateProspectMutationVariables = Exact<{
  prospectId: Scalars['String'];
  input: UpdateProspectInput;
}>;

export type UpdateProspectMutation = {
  __typename?: 'Mutation';
  updateProspect: boolean;
};

export type UpdateRoleCollaboratorsMutationVariables = Exact<{
  input: RoleCollaboratorInput;
}>;

export type UpdateRoleCollaboratorsMutation = {
  __typename?: 'Mutation';
  updateRoleCollaborators: boolean;
};

export type UpdateRoleDescriptionMutationVariables = Exact<{
  input: EmployerUpdateRoleDescriptionInput;
}>;

export type UpdateRoleDescriptionMutation = {
  __typename?: 'Mutation';
  updateRoleDescription: boolean;
};

export type UpdateRoleFactsMutationVariables = Exact<{
  input: EmployerUpdateRoleFactsInput;
}>;

export type UpdateRoleFactsMutation = {
  __typename?: 'Mutation';
  updateRoleFacts: boolean;
};

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateRoleInput;
}>;

export type UpdateRoleMutation = {
  __typename?: 'Mutation';
  updateRole: boolean;
};

export type UpdateRoleNoteMutationVariables = Exact<{
  roleId: Scalars['String'];
  noteJSON?: InputMaybe<Scalars['String']>;
}>;

export type UpdateRoleNoteMutation = {
  __typename?: 'Mutation';
  updateRoleNote: boolean;
};

export type UpdateRoleOverviewMutationVariables = Exact<{
  input: UpdateRoleOverviewInput;
}>;

export type UpdateRoleOverviewMutation = {
  __typename?: 'Mutation';
  updateRoleOverview: boolean;
};

export type UpdateRoleQuestionMutationVariables = Exact<{
  input: UpdateRoleQuestionInput;
}>;

export type UpdateRoleQuestionMutation = {
  __typename?: 'Mutation';
  updateRoleQuestion: { __typename?: 'RoleQuestion'; id: string };
};

export type UpdateRoleRequirementsMutationVariables = Exact<{
  inputs: Array<UpdateRoleRequirementInput> | UpdateRoleRequirementInput;
}>;

export type UpdateRoleRequirementsMutation = {
  __typename?: 'Mutation';
  updateRoleRequirements: Array<{
    __typename?: 'RoleRequirement';
    createdAt: Date;
    deletedAt?: Date | null;
    id: string;
    mustHave: boolean;
    prompt: string;
    updatedAt?: Date | null;
  }>;
};

export type UpdateUserMutationVariables = Exact<{
  data: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: { __typename?: 'User'; id: string; photoUrl: string };
};

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = {
  __typename?: 'Query';
  getUser?: {
    __typename?: 'User';
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    userConsents: Array<{ __typename?: 'UserConsent'; id: string }>;
  } | null;
};

export type VoidSearchAgreementMutationVariables = Exact<{
  input: EmployerTerminatedSearchAgreementInput;
}>;

export type VoidSearchAgreementMutation = {
  __typename?: 'Mutation';
  voidSearchAgreement?: string | null;
};

export const AiRoleListDocument = gql`
  query AIRoleList {
    aiRoleList {
      id
      name
      employer {
        company {
          name
        }
      }
    }
  }
`;

/**
 * __useAiRoleListQuery__
 *
 * To run a query within a React component, call `useAiRoleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiRoleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiRoleListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAiRoleListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AiRoleListQuery,
    AiRoleListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AiRoleListQuery, AiRoleListQueryVariables>(
    AiRoleListDocument,
    options
  );
}
export function useAiRoleListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AiRoleListQuery,
    AiRoleListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AiRoleListQuery, AiRoleListQueryVariables>(
    AiRoleListDocument,
    options
  );
}
export type AiRoleListQueryHookResult = ReturnType<typeof useAiRoleListQuery>;
export type AiRoleListLazyQueryHookResult = ReturnType<
  typeof useAiRoleListLazyQuery
>;
export type AiRoleListQueryResult = Apollo.QueryResult<
  AiRoleListQuery,
  AiRoleListQueryVariables
>;
export function refetchAiRoleListQuery(variables?: AiRoleListQueryVariables) {
  return { query: AiRoleListDocument, variables: variables };
}
export const AcceptAiCandidateForReviewDocument = gql`
  mutation AcceptAICandidateForReview($candidateId: String!) {
    acceptAICandidateForReview(candidateId: $candidateId)
  }
`;
export type AcceptAiCandidateForReviewMutationFn = Apollo.MutationFunction<
  AcceptAiCandidateForReviewMutation,
  AcceptAiCandidateForReviewMutationVariables
>;

/**
 * __useAcceptAiCandidateForReviewMutation__
 *
 * To run a mutation, you first call `useAcceptAiCandidateForReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAiCandidateForReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAiCandidateForReviewMutation, { data, loading, error }] = useAcceptAiCandidateForReviewMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useAcceptAiCandidateForReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptAiCandidateForReviewMutation,
    AcceptAiCandidateForReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptAiCandidateForReviewMutation,
    AcceptAiCandidateForReviewMutationVariables
  >(AcceptAiCandidateForReviewDocument, options);
}
export type AcceptAiCandidateForReviewMutationHookResult = ReturnType<
  typeof useAcceptAiCandidateForReviewMutation
>;
export type AcceptAiCandidateForReviewMutationResult =
  Apollo.MutationResult<AcceptAiCandidateForReviewMutation>;
export type AcceptAiCandidateForReviewMutationOptions =
  Apollo.BaseMutationOptions<
    AcceptAiCandidateForReviewMutation,
    AcceptAiCandidateForReviewMutationVariables
  >;
export const AcceptCandidateDocument = gql`
  mutation AcceptCandidate($input: AcceptCandidateInput!) {
    acceptCandidate(input: $input) {
      id
    }
  }
`;
export type AcceptCandidateMutationFn = Apollo.MutationFunction<
  AcceptCandidateMutation,
  AcceptCandidateMutationVariables
>;

/**
 * __useAcceptCandidateMutation__
 *
 * To run a mutation, you first call `useAcceptCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCandidateMutation, { data, loading, error }] = useAcceptCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptCandidateMutation,
    AcceptCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptCandidateMutation,
    AcceptCandidateMutationVariables
  >(AcceptCandidateDocument, options);
}
export type AcceptCandidateMutationHookResult = ReturnType<
  typeof useAcceptCandidateMutation
>;
export type AcceptCandidateMutationResult =
  Apollo.MutationResult<AcceptCandidateMutation>;
export type AcceptCandidateMutationOptions = Apollo.BaseMutationOptions<
  AcceptCandidateMutation,
  AcceptCandidateMutationVariables
>;
export const ActiveRoleRecruitersDocument = gql`
  query ActiveRoleRecruiters($roleId: String!) {
    activeRoleRecruiters(roleId: $roleId) {
      linkedIn
      id
      candidateSubmittedCount
      candidateInterviewingCount
      candidateHiredCount
      avgAcceptanceRate
      avgRequirementsScore
      marketplaceStatus
      schedulingLink15Min
      schedulingLink30Min
      schedulingLink60Min
      agency {
        id
        name
        website
      }
      user {
        name
        email
        phoneNumber
        photoUrl
      }
    }
  }
`;

/**
 * __useActiveRoleRecruitersQuery__
 *
 * To run a query within a React component, call `useActiveRoleRecruitersQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveRoleRecruitersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveRoleRecruitersQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useActiveRoleRecruitersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActiveRoleRecruitersQuery,
    ActiveRoleRecruitersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActiveRoleRecruitersQuery,
    ActiveRoleRecruitersQueryVariables
  >(ActiveRoleRecruitersDocument, options);
}
export function useActiveRoleRecruitersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveRoleRecruitersQuery,
    ActiveRoleRecruitersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActiveRoleRecruitersQuery,
    ActiveRoleRecruitersQueryVariables
  >(ActiveRoleRecruitersDocument, options);
}
export type ActiveRoleRecruitersQueryHookResult = ReturnType<
  typeof useActiveRoleRecruitersQuery
>;
export type ActiveRoleRecruitersLazyQueryHookResult = ReturnType<
  typeof useActiveRoleRecruitersLazyQuery
>;
export type ActiveRoleRecruitersQueryResult = Apollo.QueryResult<
  ActiveRoleRecruitersQuery,
  ActiveRoleRecruitersQueryVariables
>;
export function refetchActiveRoleRecruitersQuery(
  variables: ActiveRoleRecruitersQueryVariables
) {
  return { query: ActiveRoleRecruitersDocument, variables: variables };
}
export const AnvilSignaturedFilesDocument = gql`
  query AnvilSignaturedFiles($searchAgreementId: String!) {
    getAnvilSignaturedFiles(searchAgreementId: $searchAgreementId) {
      data
    }
  }
`;

/**
 * __useAnvilSignaturedFilesQuery__
 *
 * To run a query within a React component, call `useAnvilSignaturedFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnvilSignaturedFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnvilSignaturedFilesQuery({
 *   variables: {
 *      searchAgreementId: // value for 'searchAgreementId'
 *   },
 * });
 */
export function useAnvilSignaturedFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnvilSignaturedFilesQuery,
    AnvilSignaturedFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnvilSignaturedFilesQuery,
    AnvilSignaturedFilesQueryVariables
  >(AnvilSignaturedFilesDocument, options);
}
export function useAnvilSignaturedFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnvilSignaturedFilesQuery,
    AnvilSignaturedFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnvilSignaturedFilesQuery,
    AnvilSignaturedFilesQueryVariables
  >(AnvilSignaturedFilesDocument, options);
}
export type AnvilSignaturedFilesQueryHookResult = ReturnType<
  typeof useAnvilSignaturedFilesQuery
>;
export type AnvilSignaturedFilesLazyQueryHookResult = ReturnType<
  typeof useAnvilSignaturedFilesLazyQuery
>;
export type AnvilSignaturedFilesQueryResult = Apollo.QueryResult<
  AnvilSignaturedFilesQuery,
  AnvilSignaturedFilesQueryVariables
>;
export function refetchAnvilSignaturedFilesQuery(
  variables: AnvilSignaturedFilesQueryVariables
) {
  return { query: AnvilSignaturedFilesDocument, variables: variables };
}
export const ArchiveSearchAgreementDocument = gql`
  mutation ArchiveSearchAgreement($searchAgreementId: String!) {
    archiveSearchAgreement(searchAgreementId: $searchAgreementId)
  }
`;
export type ArchiveSearchAgreementMutationFn = Apollo.MutationFunction<
  ArchiveSearchAgreementMutation,
  ArchiveSearchAgreementMutationVariables
>;

/**
 * __useArchiveSearchAgreementMutation__
 *
 * To run a mutation, you first call `useArchiveSearchAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSearchAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSearchAgreementMutation, { data, loading, error }] = useArchiveSearchAgreementMutation({
 *   variables: {
 *      searchAgreementId: // value for 'searchAgreementId'
 *   },
 * });
 */
export function useArchiveSearchAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveSearchAgreementMutation,
    ArchiveSearchAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveSearchAgreementMutation,
    ArchiveSearchAgreementMutationVariables
  >(ArchiveSearchAgreementDocument, options);
}
export type ArchiveSearchAgreementMutationHookResult = ReturnType<
  typeof useArchiveSearchAgreementMutation
>;
export type ArchiveSearchAgreementMutationResult =
  Apollo.MutationResult<ArchiveSearchAgreementMutation>;
export type ArchiveSearchAgreementMutationOptions = Apollo.BaseMutationOptions<
  ArchiveSearchAgreementMutation,
  ArchiveSearchAgreementMutationVariables
>;
export const ArchivedSearchAgreementByEmployerDocument = gql`
  query ArchivedSearchAgreementByEmployer {
    archivedSearchAgreementByEmployer {
      id
      status
      updatedAt
      signedByEmployeeId
      anvilDocumentGroupEid
      employer {
        company {
          name
        }
      }
      agency {
        name
      }
      searchAgreementRoles {
        role {
          id
          name
        }
      }
      marketplaceVisibility
    }
  }
`;

/**
 * __useArchivedSearchAgreementByEmployerQuery__
 *
 * To run a query within a React component, call `useArchivedSearchAgreementByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchivedSearchAgreementByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchivedSearchAgreementByEmployerQuery({
 *   variables: {
 *   },
 * });
 */
export function useArchivedSearchAgreementByEmployerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ArchivedSearchAgreementByEmployerQuery,
    ArchivedSearchAgreementByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ArchivedSearchAgreementByEmployerQuery,
    ArchivedSearchAgreementByEmployerQueryVariables
  >(ArchivedSearchAgreementByEmployerDocument, options);
}
export function useArchivedSearchAgreementByEmployerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArchivedSearchAgreementByEmployerQuery,
    ArchivedSearchAgreementByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ArchivedSearchAgreementByEmployerQuery,
    ArchivedSearchAgreementByEmployerQueryVariables
  >(ArchivedSearchAgreementByEmployerDocument, options);
}
export type ArchivedSearchAgreementByEmployerQueryHookResult = ReturnType<
  typeof useArchivedSearchAgreementByEmployerQuery
>;
export type ArchivedSearchAgreementByEmployerLazyQueryHookResult = ReturnType<
  typeof useArchivedSearchAgreementByEmployerLazyQuery
>;
export type ArchivedSearchAgreementByEmployerQueryResult = Apollo.QueryResult<
  ArchivedSearchAgreementByEmployerQuery,
  ArchivedSearchAgreementByEmployerQueryVariables
>;
export function refetchArchivedSearchAgreementByEmployerQuery(
  variables?: ArchivedSearchAgreementByEmployerQueryVariables
) {
  return {
    query: ArchivedSearchAgreementByEmployerDocument,
    variables: variables,
  };
}
export const AvailableRolesAgencyDocument = gql`
  query AvailableRolesAgency($recruiterId: String!) {
    availableRolesAgency(recruiterId: $recruiterId) {
      id
      name
    }
  }
`;

/**
 * __useAvailableRolesAgencyQuery__
 *
 * To run a query within a React component, call `useAvailableRolesAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableRolesAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableRolesAgencyQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useAvailableRolesAgencyQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableRolesAgencyQuery,
    AvailableRolesAgencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableRolesAgencyQuery,
    AvailableRolesAgencyQueryVariables
  >(AvailableRolesAgencyDocument, options);
}
export function useAvailableRolesAgencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableRolesAgencyQuery,
    AvailableRolesAgencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableRolesAgencyQuery,
    AvailableRolesAgencyQueryVariables
  >(AvailableRolesAgencyDocument, options);
}
export type AvailableRolesAgencyQueryHookResult = ReturnType<
  typeof useAvailableRolesAgencyQuery
>;
export type AvailableRolesAgencyLazyQueryHookResult = ReturnType<
  typeof useAvailableRolesAgencyLazyQuery
>;
export type AvailableRolesAgencyQueryResult = Apollo.QueryResult<
  AvailableRolesAgencyQuery,
  AvailableRolesAgencyQueryVariables
>;
export function refetchAvailableRolesAgencyQuery(
  variables: AvailableRolesAgencyQueryVariables
) {
  return { query: AvailableRolesAgencyDocument, variables: variables };
}
export const AvalaibleRequestProposalDocument = gql`
  query AvalaibleRequestProposal($recruiterId: String!) {
    avalaibleRequestProposal(recruiterId: $recruiterId)
  }
`;

/**
 * __useAvalaibleRequestProposalQuery__
 *
 * To run a query within a React component, call `useAvalaibleRequestProposalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvalaibleRequestProposalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvalaibleRequestProposalQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useAvalaibleRequestProposalQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvalaibleRequestProposalQuery,
    AvalaibleRequestProposalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvalaibleRequestProposalQuery,
    AvalaibleRequestProposalQueryVariables
  >(AvalaibleRequestProposalDocument, options);
}
export function useAvalaibleRequestProposalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvalaibleRequestProposalQuery,
    AvalaibleRequestProposalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvalaibleRequestProposalQuery,
    AvalaibleRequestProposalQueryVariables
  >(AvalaibleRequestProposalDocument, options);
}
export type AvalaibleRequestProposalQueryHookResult = ReturnType<
  typeof useAvalaibleRequestProposalQuery
>;
export type AvalaibleRequestProposalLazyQueryHookResult = ReturnType<
  typeof useAvalaibleRequestProposalLazyQuery
>;
export type AvalaibleRequestProposalQueryResult = Apollo.QueryResult<
  AvalaibleRequestProposalQuery,
  AvalaibleRequestProposalQueryVariables
>;
export function refetchAvalaibleRequestProposalQuery(
  variables: AvalaibleRequestProposalQueryVariables
) {
  return { query: AvalaibleRequestProposalDocument, variables: variables };
}
export const CancelSearchAgreementDocument = gql`
  mutation CancelSearchAgreement(
    $input: EmployerTerminatedSearchAgreementInput!
  ) {
    cancelSearchAgreement(input: $input)
  }
`;
export type CancelSearchAgreementMutationFn = Apollo.MutationFunction<
  CancelSearchAgreementMutation,
  CancelSearchAgreementMutationVariables
>;

/**
 * __useCancelSearchAgreementMutation__
 *
 * To run a mutation, you first call `useCancelSearchAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSearchAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSearchAgreementMutation, { data, loading, error }] = useCancelSearchAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSearchAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSearchAgreementMutation,
    CancelSearchAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelSearchAgreementMutation,
    CancelSearchAgreementMutationVariables
  >(CancelSearchAgreementDocument, options);
}
export type CancelSearchAgreementMutationHookResult = ReturnType<
  typeof useCancelSearchAgreementMutation
>;
export type CancelSearchAgreementMutationResult =
  Apollo.MutationResult<CancelSearchAgreementMutation>;
export type CancelSearchAgreementMutationOptions = Apollo.BaseMutationOptions<
  CancelSearchAgreementMutation,
  CancelSearchAgreementMutationVariables
>;
export const CandidateAcceptOfferDocument = gql`
  mutation CandidateAcceptOffer($input: CandidateAcceptOfferInput!) {
    candidateAcceptOffer(input: $input) {
      id
    }
  }
`;
export type CandidateAcceptOfferMutationFn = Apollo.MutationFunction<
  CandidateAcceptOfferMutation,
  CandidateAcceptOfferMutationVariables
>;

/**
 * __useCandidateAcceptOfferMutation__
 *
 * To run a mutation, you first call `useCandidateAcceptOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateAcceptOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateAcceptOfferMutation, { data, loading, error }] = useCandidateAcceptOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCandidateAcceptOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CandidateAcceptOfferMutation,
    CandidateAcceptOfferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CandidateAcceptOfferMutation,
    CandidateAcceptOfferMutationVariables
  >(CandidateAcceptOfferDocument, options);
}
export type CandidateAcceptOfferMutationHookResult = ReturnType<
  typeof useCandidateAcceptOfferMutation
>;
export type CandidateAcceptOfferMutationResult =
  Apollo.MutationResult<CandidateAcceptOfferMutation>;
export type CandidateAcceptOfferMutationOptions = Apollo.BaseMutationOptions<
  CandidateAcceptOfferMutation,
  CandidateAcceptOfferMutationVariables
>;
export const CandidateActivityHistoryByCandidateIdDocument = gql`
  query CandidateActivityHistoryByCandidateId($id: String!) {
    candidateActivityHistoryByCandidateId(id: $id) {
      candidateHistory {
        id
        createdAt
        activityType
        entityType
        activityMeta {
          rejectReasonCategory
          rejectReasonDetails
          shareRejectionInfo
        }
        candidate {
          talent {
            firstName
            lastName
          }
          recruiter {
            id
            agency {
              name
            }
            user {
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCandidateActivityHistoryByCandidateIdQuery__
 *
 * To run a query within a React component, call `useCandidateActivityHistoryByCandidateIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateActivityHistoryByCandidateIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateActivityHistoryByCandidateIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateActivityHistoryByCandidateIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateActivityHistoryByCandidateIdQuery,
    CandidateActivityHistoryByCandidateIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateActivityHistoryByCandidateIdQuery,
    CandidateActivityHistoryByCandidateIdQueryVariables
  >(CandidateActivityHistoryByCandidateIdDocument, options);
}
export function useCandidateActivityHistoryByCandidateIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateActivityHistoryByCandidateIdQuery,
    CandidateActivityHistoryByCandidateIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateActivityHistoryByCandidateIdQuery,
    CandidateActivityHistoryByCandidateIdQueryVariables
  >(CandidateActivityHistoryByCandidateIdDocument, options);
}
export type CandidateActivityHistoryByCandidateIdQueryHookResult = ReturnType<
  typeof useCandidateActivityHistoryByCandidateIdQuery
>;
export type CandidateActivityHistoryByCandidateIdLazyQueryHookResult =
  ReturnType<typeof useCandidateActivityHistoryByCandidateIdLazyQuery>;
export type CandidateActivityHistoryByCandidateIdQueryResult =
  Apollo.QueryResult<
    CandidateActivityHistoryByCandidateIdQuery,
    CandidateActivityHistoryByCandidateIdQueryVariables
  >;
export function refetchCandidateActivityHistoryByCandidateIdQuery(
  variables: CandidateActivityHistoryByCandidateIdQueryVariables
) {
  return {
    query: CandidateActivityHistoryByCandidateIdDocument,
    variables: variables,
  };
}
export const CandidateByIdDocument = gql`
  query CandidateById($id: String!) {
    candidateById(id: $id) {
      id
      createdAt
      status
      justification
      justificationJSON
      baseSalary
      shareCount
      startDate
      isCollaborationCandidate
      collaboration {
        primaryRecruiter {
          id
          user {
            name
            photoUrl
          }
          agency {
            id
            name
          }
        }
      }
      placementFee
      placementFeeType
      roleRequirements {
        prompt
        id
        mustHave
        meetRequirement
      }
      talent {
        firstName
        lastName
        linkedIn
        email
        phone
        name
      }
      documents {
        documentPath
        id
        createdAt
        name
        document
        documentType
      }
      searchAgreementRole {
        id
        searchReviews {
          reviewedByEmployeeId
          recruiterId
        }
        role {
          id
          name
          description
          employer {
            company {
              name
            }
          }
          candidateNote
          interviewerId
          scheduleLink
          skipScheduleConfirmation
        }
        searchAgreementId
        searchAgreement {
          marketplaceVisibility
        }
      }
      recruiter {
        id
        agency {
          id
          name
        }
        user {
          firstName
          lastName
          name
          email
          photoUrl
        }
      }
    }
  }
`;

/**
 * __useCandidateByIdQuery__
 *
 * To run a query within a React component, call `useCandidateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateByIdQuery,
    CandidateByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CandidateByIdQuery, CandidateByIdQueryVariables>(
    CandidateByIdDocument,
    options
  );
}
export function useCandidateByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateByIdQuery,
    CandidateByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CandidateByIdQuery, CandidateByIdQueryVariables>(
    CandidateByIdDocument,
    options
  );
}
export type CandidateByIdQueryHookResult = ReturnType<
  typeof useCandidateByIdQuery
>;
export type CandidateByIdLazyQueryHookResult = ReturnType<
  typeof useCandidateByIdLazyQuery
>;
export type CandidateByIdQueryResult = Apollo.QueryResult<
  CandidateByIdQuery,
  CandidateByIdQueryVariables
>;
export function refetchCandidateByIdQuery(
  variables: CandidateByIdQueryVariables
) {
  return { query: CandidateByIdDocument, variables: variables };
}
export const CandidateMarketplaceMetricsDocument = gql`
  query CandidateMarketplaceMetrics($id: String!) {
    candidateMarketplaceMetrics(id: $id) {
      submittedCount
      interviewingCount
      hiredCount
    }
  }
`;

/**
 * __useCandidateMarketplaceMetricsQuery__
 *
 * To run a query within a React component, call `useCandidateMarketplaceMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateMarketplaceMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateMarketplaceMetricsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateMarketplaceMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidateMarketplaceMetricsQuery,
    CandidateMarketplaceMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateMarketplaceMetricsQuery,
    CandidateMarketplaceMetricsQueryVariables
  >(CandidateMarketplaceMetricsDocument, options);
}
export function useCandidateMarketplaceMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateMarketplaceMetricsQuery,
    CandidateMarketplaceMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateMarketplaceMetricsQuery,
    CandidateMarketplaceMetricsQueryVariables
  >(CandidateMarketplaceMetricsDocument, options);
}
export type CandidateMarketplaceMetricsQueryHookResult = ReturnType<
  typeof useCandidateMarketplaceMetricsQuery
>;
export type CandidateMarketplaceMetricsLazyQueryHookResult = ReturnType<
  typeof useCandidateMarketplaceMetricsLazyQuery
>;
export type CandidateMarketplaceMetricsQueryResult = Apollo.QueryResult<
  CandidateMarketplaceMetricsQuery,
  CandidateMarketplaceMetricsQueryVariables
>;
export function refetchCandidateMarketplaceMetricsQuery(
  variables: CandidateMarketplaceMetricsQueryVariables
) {
  return { query: CandidateMarketplaceMetricsDocument, variables: variables };
}
export const CandidatesAiMetricsDocument = gql`
  query CandidatesAIMetrics($roleId: String!) {
    candidatesAIMetrics(roleId: $roleId) {
      candidateAcceptedPercentage
      candidatesPerWeekCount
      totalCandidatesCount
    }
  }
`;

/**
 * __useCandidatesAiMetricsQuery__
 *
 * To run a query within a React component, call `useCandidatesAiMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatesAiMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatesAiMetricsQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useCandidatesAiMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidatesAiMetricsQuery,
    CandidatesAiMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidatesAiMetricsQuery,
    CandidatesAiMetricsQueryVariables
  >(CandidatesAiMetricsDocument, options);
}
export function useCandidatesAiMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidatesAiMetricsQuery,
    CandidatesAiMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidatesAiMetricsQuery,
    CandidatesAiMetricsQueryVariables
  >(CandidatesAiMetricsDocument, options);
}
export type CandidatesAiMetricsQueryHookResult = ReturnType<
  typeof useCandidatesAiMetricsQuery
>;
export type CandidatesAiMetricsLazyQueryHookResult = ReturnType<
  typeof useCandidatesAiMetricsLazyQuery
>;
export type CandidatesAiMetricsQueryResult = Apollo.QueryResult<
  CandidatesAiMetricsQuery,
  CandidatesAiMetricsQueryVariables
>;
export function refetchCandidatesAiMetricsQuery(
  variables: CandidatesAiMetricsQueryVariables
) {
  return { query: CandidatesAiMetricsDocument, variables: variables };
}
export const CandidatesAiRoleDocument = gql`
  query CandidatesAIRole($roleId: String!) {
    candidatesAIRole(roleId: $roleId) {
      id
      candidate {
        id
        status
        talent {
          name
          linkedIn
        }
        documents {
          id
          name
          documentType
          documentPath
        }
        searchAgreementRole {
          role {
            employer {
              company {
                name
              }
            }
          }
        }
      }
      labelCandidateInterest
      roleRequirementScore
      dateSourced
      prospect {
        id
        publicIdentifier
        linkedInProfile {
          profile_pic_url
          experiences
          education
        }
      }
      roleRequirementsByPriority {
        prompt
        id
        mustHave
        meetRequirement
        requirementExplanation
        isCandidateEvaluation
      }
    }
  }
`;

/**
 * __useCandidatesAiRoleQuery__
 *
 * To run a query within a React component, call `useCandidatesAiRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatesAiRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatesAiRoleQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useCandidatesAiRoleQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidatesAiRoleQuery,
    CandidatesAiRoleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CandidatesAiRoleQuery, CandidatesAiRoleQueryVariables>(
    CandidatesAiRoleDocument,
    options
  );
}
export function useCandidatesAiRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidatesAiRoleQuery,
    CandidatesAiRoleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidatesAiRoleQuery,
    CandidatesAiRoleQueryVariables
  >(CandidatesAiRoleDocument, options);
}
export type CandidatesAiRoleQueryHookResult = ReturnType<
  typeof useCandidatesAiRoleQuery
>;
export type CandidatesAiRoleLazyQueryHookResult = ReturnType<
  typeof useCandidatesAiRoleLazyQuery
>;
export type CandidatesAiRoleQueryResult = Apollo.QueryResult<
  CandidatesAiRoleQuery,
  CandidatesAiRoleQueryVariables
>;
export function refetchCandidatesAiRoleQuery(
  variables: CandidatesAiRoleQueryVariables
) {
  return { query: CandidatesAiRoleDocument, variables: variables };
}
export const CandidatesByEmployerDocument = gql`
  query CandidatesByEmployer {
    candidatesByEmployer {
      candidates {
        id
        daysUntilExpirationDate
        roleRequirementsScore
        status
        justification
        justificationJSON
        updatedAt
        fee
        candidateActivityHistories {
          activityMeta {
            rejectReasonCategory
            shareRejectionInfo
          }
        }
        roleRequirements {
          id
          mustHave
          prompt
          meetRequirement
        }
        documents {
          id
          documentPath
          documentType
          name
        }
        collaboration {
          id
          primaryRecruiter {
            id
            user {
              name
              photoUrl
            }
            agency {
              id
              name
            }
          }
        }
        recruiter {
          id
          user {
            name
            photoUrl
          }
          agency {
            id
            name
          }
        }
        talent {
          name
          linkedIn
          email
          phone
          createdAt
        }
        searchAgreementRole {
          id
          searchReviews {
            reviewedByEmployeeId
            reviewedByRecruiterId
            recruiterId
          }
          searchAgreement {
            id
            marketplaceVisibility
          }
          role {
            id
            name
            employer {
              id
              company {
                name
              }
            }
            hiringManager {
              user {
                name
              }
            }
            candidateNote
            interviewerId
            scheduleLink
            skipScheduleConfirmation
          }
        }
      }
    }
  }
`;

/**
 * __useCandidatesByEmployerQuery__
 *
 * To run a query within a React component, call `useCandidatesByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatesByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatesByEmployerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCandidatesByEmployerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CandidatesByEmployerQuery,
    CandidatesByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidatesByEmployerQuery,
    CandidatesByEmployerQueryVariables
  >(CandidatesByEmployerDocument, options);
}
export function useCandidatesByEmployerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidatesByEmployerQuery,
    CandidatesByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidatesByEmployerQuery,
    CandidatesByEmployerQueryVariables
  >(CandidatesByEmployerDocument, options);
}
export type CandidatesByEmployerQueryHookResult = ReturnType<
  typeof useCandidatesByEmployerQuery
>;
export type CandidatesByEmployerLazyQueryHookResult = ReturnType<
  typeof useCandidatesByEmployerLazyQuery
>;
export type CandidatesByEmployerQueryResult = Apollo.QueryResult<
  CandidatesByEmployerQuery,
  CandidatesByEmployerQueryVariables
>;
export function refetchCandidatesByEmployerQuery(
  variables?: CandidatesByEmployerQueryVariables
) {
  return { query: CandidatesByEmployerDocument, variables: variables };
}
export const CandidatesByRoleDocument = gql`
  query CandidatesByRole($roleId: String!) {
    candidatesByRole(roleId: $roleId) {
      candidates {
        id
        daysUntilExpirationDate
        roleRequirementsScore
        createdAt
        updatedAt
        status
        justification
        justificationJSON
        fee
        talent {
          id
          firstName
          lastName
          linkedIn
          name
          email
          createdAt
          phone
        }
        recruiter {
          id
          user {
            firstName
            lastName
            name
            email
            photoUrl
          }
          agency {
            id
            name
          }
        }
        candidateActivityHistories {
          activityMeta {
            rejectReasonCategory
            shareRejectionInfo
          }
        }
        roleRequirements {
          id
          mustHave
          prompt
          meetRequirement
        }
        documents {
          id
          documentPath
          documentType
          name
        }
        collaboration {
          id
          primaryRecruiter {
            id
            user {
              firstName
              lastName
              name
              photoUrl
            }
            agency {
              id
              name
            }
          }
        }
        searchAgreementRole {
          id
          roleId
          searchReviews {
            reviewedByEmployeeId
            reviewedByRecruiterId
            recruiterId
          }
          searchAgreement {
            id
            marketplaceVisibility
          }
          role {
            id
            name
            employer {
              id
              company {
                name
              }
            }
            hiringManager {
              user {
                name
              }
            }
            candidateNote
            interviewerId
            scheduleLink
            skipScheduleConfirmation
          }
        }
      }
      candidateMetrics {
        submittedCount
        interviewingCount
        hiredCount
      }
    }
  }
`;

/**
 * __useCandidatesByRoleQuery__
 *
 * To run a query within a React component, call `useCandidatesByRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatesByRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatesByRoleQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useCandidatesByRoleQuery(
  baseOptions: Apollo.QueryHookOptions<
    CandidatesByRoleQuery,
    CandidatesByRoleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CandidatesByRoleQuery, CandidatesByRoleQueryVariables>(
    CandidatesByRoleDocument,
    options
  );
}
export function useCandidatesByRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidatesByRoleQuery,
    CandidatesByRoleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidatesByRoleQuery,
    CandidatesByRoleQueryVariables
  >(CandidatesByRoleDocument, options);
}
export type CandidatesByRoleQueryHookResult = ReturnType<
  typeof useCandidatesByRoleQuery
>;
export type CandidatesByRoleLazyQueryHookResult = ReturnType<
  typeof useCandidatesByRoleLazyQuery
>;
export type CandidatesByRoleQueryResult = Apollo.QueryResult<
  CandidatesByRoleQuery,
  CandidatesByRoleQueryVariables
>;
export function refetchCandidatesByRoleQuery(
  variables: CandidatesByRoleQueryVariables
) {
  return { query: CandidatesByRoleDocument, variables: variables };
}
export const CandidateMetricsByEmployerDocument = gql`
  query CandidateMetricsByEmployer {
    candidateMetricsByEmployer {
      submittedCount
      interviewingCount
      hiredCount
    }
  }
`;

/**
 * __useCandidateMetricsByEmployerQuery__
 *
 * To run a query within a React component, call `useCandidateMetricsByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateMetricsByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateMetricsByEmployerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCandidateMetricsByEmployerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CandidateMetricsByEmployerQuery,
    CandidateMetricsByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidateMetricsByEmployerQuery,
    CandidateMetricsByEmployerQueryVariables
  >(CandidateMetricsByEmployerDocument, options);
}
export function useCandidateMetricsByEmployerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidateMetricsByEmployerQuery,
    CandidateMetricsByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidateMetricsByEmployerQuery,
    CandidateMetricsByEmployerQueryVariables
  >(CandidateMetricsByEmployerDocument, options);
}
export type CandidateMetricsByEmployerQueryHookResult = ReturnType<
  typeof useCandidateMetricsByEmployerQuery
>;
export type CandidateMetricsByEmployerLazyQueryHookResult = ReturnType<
  typeof useCandidateMetricsByEmployerLazyQuery
>;
export type CandidateMetricsByEmployerQueryResult = Apollo.QueryResult<
  CandidateMetricsByEmployerQuery,
  CandidateMetricsByEmployerQueryVariables
>;
export function refetchCandidateMetricsByEmployerQuery(
  variables?: CandidateMetricsByEmployerQueryVariables
) {
  return { query: CandidateMetricsByEmployerDocument, variables: variables };
}
export const CandidatesToReviewQueryDocument = gql`
  query CandidatesToReviewQuery {
    candidatesToReview {
      id
      isExpiringSoon
      createdAt
      status
      talent {
        id
        name
        email
        linkedIn
      }
      searchAgreementRole {
        id
        role {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useCandidatesToReviewQuery__
 *
 * To run a query within a React component, call `useCandidatesToReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatesToReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatesToReviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useCandidatesToReviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CandidatesToReviewQuery,
    CandidatesToReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CandidatesToReviewQuery,
    CandidatesToReviewQueryVariables
  >(CandidatesToReviewQueryDocument, options);
}
export function useCandidatesToReviewQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CandidatesToReviewQuery,
    CandidatesToReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CandidatesToReviewQuery,
    CandidatesToReviewQueryVariables
  >(CandidatesToReviewQueryDocument, options);
}
export type CandidatesToReviewQueryHookResult = ReturnType<
  typeof useCandidatesToReviewQuery
>;
export type CandidatesToReviewQueryLazyQueryHookResult = ReturnType<
  typeof useCandidatesToReviewQueryLazyQuery
>;
export type CandidatesToReviewQueryQueryResult = Apollo.QueryResult<
  CandidatesToReviewQuery,
  CandidatesToReviewQueryVariables
>;
export function refetchCandidatesToReviewQuery(
  variables?: CandidatesToReviewQueryVariables
) {
  return { query: CandidatesToReviewQueryDocument, variables: variables };
}
export const CheckSlackConnectionDocument = gql`
  query CheckSlackConnection {
    checkSlackConnection
  }
`;

/**
 * __useCheckSlackConnectionQuery__
 *
 * To run a query within a React component, call `useCheckSlackConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckSlackConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckSlackConnectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckSlackConnectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CheckSlackConnectionQuery,
    CheckSlackConnectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckSlackConnectionQuery,
    CheckSlackConnectionQueryVariables
  >(CheckSlackConnectionDocument, options);
}
export function useCheckSlackConnectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckSlackConnectionQuery,
    CheckSlackConnectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckSlackConnectionQuery,
    CheckSlackConnectionQueryVariables
  >(CheckSlackConnectionDocument, options);
}
export type CheckSlackConnectionQueryHookResult = ReturnType<
  typeof useCheckSlackConnectionQuery
>;
export type CheckSlackConnectionLazyQueryHookResult = ReturnType<
  typeof useCheckSlackConnectionLazyQuery
>;
export type CheckSlackConnectionQueryResult = Apollo.QueryResult<
  CheckSlackConnectionQuery,
  CheckSlackConnectionQueryVariables
>;
export function refetchCheckSlackConnectionQuery(
  variables?: CheckSlackConnectionQueryVariables
) {
  return { query: CheckSlackConnectionDocument, variables: variables };
}
export const CitiesDocument = gql`
  query Cities {
    cities {
      cityName
      stateAbbrev
      id
    }
  }
`;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(
    CitiesDocument,
    options
  );
}
export function useCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(
    CitiesDocument,
    options
  );
}
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<
  CitiesQuery,
  CitiesQueryVariables
>;
export function refetchCitiesQuery(variables?: CitiesQueryVariables) {
  return { query: CitiesDocument, variables: variables };
}
export const CitiesMultiOptionsDocument = gql`
  query CitiesMultiOptions {
    citiesMultiOptions {
      cityOptions {
        value
        label
      }
    }
  }
`;

/**
 * __useCitiesMultiOptionsQuery__
 *
 * To run a query within a React component, call `useCitiesMultiOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesMultiOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesMultiOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCitiesMultiOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CitiesMultiOptionsQuery,
    CitiesMultiOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CitiesMultiOptionsQuery,
    CitiesMultiOptionsQueryVariables
  >(CitiesMultiOptionsDocument, options);
}
export function useCitiesMultiOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CitiesMultiOptionsQuery,
    CitiesMultiOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CitiesMultiOptionsQuery,
    CitiesMultiOptionsQueryVariables
  >(CitiesMultiOptionsDocument, options);
}
export type CitiesMultiOptionsQueryHookResult = ReturnType<
  typeof useCitiesMultiOptionsQuery
>;
export type CitiesMultiOptionsLazyQueryHookResult = ReturnType<
  typeof useCitiesMultiOptionsLazyQuery
>;
export type CitiesMultiOptionsQueryResult = Apollo.QueryResult<
  CitiesMultiOptionsQuery,
  CitiesMultiOptionsQueryVariables
>;
export function refetchCitiesMultiOptionsQuery(
  variables?: CitiesMultiOptionsQueryVariables
) {
  return { query: CitiesMultiOptionsDocument, variables: variables };
}
export const CloseAiRoleDocument = gql`
  mutation CloseAIRole($roleId: String!) {
    closeAIRole(roleId: $roleId)
  }
`;
export type CloseAiRoleMutationFn = Apollo.MutationFunction<
  CloseAiRoleMutation,
  CloseAiRoleMutationVariables
>;

/**
 * __useCloseAiRoleMutation__
 *
 * To run a mutation, you first call `useCloseAiRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseAiRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeAiRoleMutation, { data, loading, error }] = useCloseAiRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useCloseAiRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseAiRoleMutation,
    CloseAiRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseAiRoleMutation, CloseAiRoleMutationVariables>(
    CloseAiRoleDocument,
    options
  );
}
export type CloseAiRoleMutationHookResult = ReturnType<
  typeof useCloseAiRoleMutation
>;
export type CloseAiRoleMutationResult =
  Apollo.MutationResult<CloseAiRoleMutation>;
export type CloseAiRoleMutationOptions = Apollo.BaseMutationOptions<
  CloseAiRoleMutation,
  CloseAiRoleMutationVariables
>;
export const CompanyStagesDocument = gql`
  query CompanyStages {
    companyStages {
      name
      id
    }
  }
`;

/**
 * __useCompanyStagesQuery__
 *
 * To run a query within a React component, call `useCompanyStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyStagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyStagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyStagesQuery,
    CompanyStagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyStagesQuery, CompanyStagesQueryVariables>(
    CompanyStagesDocument,
    options
  );
}
export function useCompanyStagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyStagesQuery,
    CompanyStagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyStagesQuery, CompanyStagesQueryVariables>(
    CompanyStagesDocument,
    options
  );
}
export type CompanyStagesQueryHookResult = ReturnType<
  typeof useCompanyStagesQuery
>;
export type CompanyStagesLazyQueryHookResult = ReturnType<
  typeof useCompanyStagesLazyQuery
>;
export type CompanyStagesQueryResult = Apollo.QueryResult<
  CompanyStagesQuery,
  CompanyStagesQueryVariables
>;
export function refetchCompanyStagesQuery(
  variables?: CompanyStagesQueryVariables
) {
  return { query: CompanyStagesDocument, variables: variables };
}
export const ContactHeadraceViaSlackDocument = gql`
  mutation ContactHeadraceViaSlack {
    contactHeadraceViaSlack {
      channelId
      hasInvitation
    }
  }
`;
export type ContactHeadraceViaSlackMutationFn = Apollo.MutationFunction<
  ContactHeadraceViaSlackMutation,
  ContactHeadraceViaSlackMutationVariables
>;

/**
 * __useContactHeadraceViaSlackMutation__
 *
 * To run a mutation, you first call `useContactHeadraceViaSlackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactHeadraceViaSlackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactHeadraceViaSlackMutation, { data, loading, error }] = useContactHeadraceViaSlackMutation({
 *   variables: {
 *   },
 * });
 */
export function useContactHeadraceViaSlackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContactHeadraceViaSlackMutation,
    ContactHeadraceViaSlackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContactHeadraceViaSlackMutation,
    ContactHeadraceViaSlackMutationVariables
  >(ContactHeadraceViaSlackDocument, options);
}
export type ContactHeadraceViaSlackMutationHookResult = ReturnType<
  typeof useContactHeadraceViaSlackMutation
>;
export type ContactHeadraceViaSlackMutationResult =
  Apollo.MutationResult<ContactHeadraceViaSlackMutation>;
export type ContactHeadraceViaSlackMutationOptions = Apollo.BaseMutationOptions<
  ContactHeadraceViaSlackMutation,
  ContactHeadraceViaSlackMutationVariables
>;
export const ConvertProspectToCandidateDocument = gql`
  mutation ConvertProspectToCandidate(
    $input: ConvertProspectToCandidateInput!
  ) {
    convertProspectToCandidate(input: $input)
  }
`;
export type ConvertProspectToCandidateMutationFn = Apollo.MutationFunction<
  ConvertProspectToCandidateMutation,
  ConvertProspectToCandidateMutationVariables
>;

/**
 * __useConvertProspectToCandidateMutation__
 *
 * To run a mutation, you first call `useConvertProspectToCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertProspectToCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertProspectToCandidateMutation, { data, loading, error }] = useConvertProspectToCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertProspectToCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConvertProspectToCandidateMutation,
    ConvertProspectToCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConvertProspectToCandidateMutation,
    ConvertProspectToCandidateMutationVariables
  >(ConvertProspectToCandidateDocument, options);
}
export type ConvertProspectToCandidateMutationHookResult = ReturnType<
  typeof useConvertProspectToCandidateMutation
>;
export type ConvertProspectToCandidateMutationResult =
  Apollo.MutationResult<ConvertProspectToCandidateMutation>;
export type ConvertProspectToCandidateMutationOptions =
  Apollo.BaseMutationOptions<
    ConvertProspectToCandidateMutation,
    ConvertProspectToCandidateMutationVariables
  >;
export const CreateAiRoleDocument = gql`
  mutation CreateAIRole($input: CreateAIRoleInput!) {
    createAIRole(input: $input)
  }
`;
export type CreateAiRoleMutationFn = Apollo.MutationFunction<
  CreateAiRoleMutation,
  CreateAiRoleMutationVariables
>;

/**
 * __useCreateAiRoleMutation__
 *
 * To run a mutation, you first call `useCreateAiRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiRoleMutation, { data, loading, error }] = useCreateAiRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAiRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAiRoleMutation,
    CreateAiRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAiRoleMutation,
    CreateAiRoleMutationVariables
  >(CreateAiRoleDocument, options);
}
export type CreateAiRoleMutationHookResult = ReturnType<
  typeof useCreateAiRoleMutation
>;
export type CreateAiRoleMutationResult =
  Apollo.MutationResult<CreateAiRoleMutation>;
export type CreateAiRoleMutationOptions = Apollo.BaseMutationOptions<
  CreateAiRoleMutation,
  CreateAiRoleMutationVariables
>;
export const CreateCandidateDocumentDocument = gql`
  mutation CreateCandidateDocument($input: CreateCandidateDocumentInput!) {
    createCandidateDocument(input: $input) {
      id
    }
  }
`;
export type CreateCandidateDocumentMutationFn = Apollo.MutationFunction<
  CreateCandidateDocumentMutation,
  CreateCandidateDocumentMutationVariables
>;

/**
 * __useCreateCandidateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateCandidateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCandidateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCandidateDocumentMutation, { data, loading, error }] = useCreateCandidateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCandidateDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCandidateDocumentMutation,
    CreateCandidateDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCandidateDocumentMutation,
    CreateCandidateDocumentMutationVariables
  >(CreateCandidateDocumentDocument, options);
}
export type CreateCandidateDocumentMutationHookResult = ReturnType<
  typeof useCreateCandidateDocumentMutation
>;
export type CreateCandidateDocumentMutationResult =
  Apollo.MutationResult<CreateCandidateDocumentMutation>;
export type CreateCandidateDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateCandidateDocumentMutation,
  CreateCandidateDocumentMutationVariables
>;
export const CreateEmployerDocument = gql`
  mutation CreateEmployer($input: CreateEmployerInput!) {
    createEmployer(input: $input) {
      id
    }
  }
`;
export type CreateEmployerMutationFn = Apollo.MutationFunction<
  CreateEmployerMutation,
  CreateEmployerMutationVariables
>;

/**
 * __useCreateEmployerMutation__
 *
 * To run a mutation, you first call `useCreateEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployerMutation, { data, loading, error }] = useCreateEmployerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEmployerMutation,
    CreateEmployerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEmployerMutation,
    CreateEmployerMutationVariables
  >(CreateEmployerDocument, options);
}
export type CreateEmployerMutationHookResult = ReturnType<
  typeof useCreateEmployerMutation
>;
export type CreateEmployerMutationResult =
  Apollo.MutationResult<CreateEmployerMutation>;
export type CreateEmployerMutationOptions = Apollo.BaseMutationOptions<
  CreateEmployerMutation,
  CreateEmployerMutationVariables
>;
export const CreateMediaUploadLinkDocument = gql`
  query CreateMediaUploadLink($data: CreateMediaUploadLinkInput!) {
    createMediaUploadLink(data: $data) {
      url
      fields {
        Policy
        X_Amz_Algorithm
        X_Amz_Credential
        X_Amz_Date
        X_Amz_Signature
        X_Amz_Security_Token
        bucket
        key
      }
    }
  }
`;

/**
 * __useCreateMediaUploadLinkQuery__
 *
 * To run a query within a React component, call `useCreateMediaUploadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaUploadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateMediaUploadLinkQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMediaUploadLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreateMediaUploadLinkQuery,
    CreateMediaUploadLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CreateMediaUploadLinkQuery,
    CreateMediaUploadLinkQueryVariables
  >(CreateMediaUploadLinkDocument, options);
}
export function useCreateMediaUploadLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreateMediaUploadLinkQuery,
    CreateMediaUploadLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CreateMediaUploadLinkQuery,
    CreateMediaUploadLinkQueryVariables
  >(CreateMediaUploadLinkDocument, options);
}
export type CreateMediaUploadLinkQueryHookResult = ReturnType<
  typeof useCreateMediaUploadLinkQuery
>;
export type CreateMediaUploadLinkLazyQueryHookResult = ReturnType<
  typeof useCreateMediaUploadLinkLazyQuery
>;
export type CreateMediaUploadLinkQueryResult = Apollo.QueryResult<
  CreateMediaUploadLinkQuery,
  CreateMediaUploadLinkQueryVariables
>;
export function refetchCreateMediaUploadLinkQuery(
  variables: CreateMediaUploadLinkQueryVariables
) {
  return { query: CreateMediaUploadLinkDocument, variables: variables };
}
export const CreateOrUpdateIdealCandidatesProfileDocument = gql`
  mutation CreateOrUpdateIdealCandidatesProfile(
    $input: IdealCandidatesProfileInput!
  ) {
    createOrUpdateIdealCandidatesProfile(input: $input)
  }
`;
export type CreateOrUpdateIdealCandidatesProfileMutationFn =
  Apollo.MutationFunction<
    CreateOrUpdateIdealCandidatesProfileMutation,
    CreateOrUpdateIdealCandidatesProfileMutationVariables
  >;

/**
 * __useCreateOrUpdateIdealCandidatesProfileMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateIdealCandidatesProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateIdealCandidatesProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateIdealCandidatesProfileMutation, { data, loading, error }] = useCreateOrUpdateIdealCandidatesProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateIdealCandidatesProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateIdealCandidatesProfileMutation,
    CreateOrUpdateIdealCandidatesProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateIdealCandidatesProfileMutation,
    CreateOrUpdateIdealCandidatesProfileMutationVariables
  >(CreateOrUpdateIdealCandidatesProfileDocument, options);
}
export type CreateOrUpdateIdealCandidatesProfileMutationHookResult = ReturnType<
  typeof useCreateOrUpdateIdealCandidatesProfileMutation
>;
export type CreateOrUpdateIdealCandidatesProfileMutationResult =
  Apollo.MutationResult<CreateOrUpdateIdealCandidatesProfileMutation>;
export type CreateOrUpdateIdealCandidatesProfileMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrUpdateIdealCandidatesProfileMutation,
    CreateOrUpdateIdealCandidatesProfileMutationVariables
  >;
export const CreateOrUpdateRoleRequirementsDocument = gql`
  mutation CreateOrUpdateRoleRequirements(
    $input: UpdateRoleRequirementsInput!
  ) {
    createOrUpdateRoleRequirements(input: $input)
  }
`;
export type CreateOrUpdateRoleRequirementsMutationFn = Apollo.MutationFunction<
  CreateOrUpdateRoleRequirementsMutation,
  CreateOrUpdateRoleRequirementsMutationVariables
>;

/**
 * __useCreateOrUpdateRoleRequirementsMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateRoleRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateRoleRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateRoleRequirementsMutation, { data, loading, error }] = useCreateOrUpdateRoleRequirementsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateRoleRequirementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateRoleRequirementsMutation,
    CreateOrUpdateRoleRequirementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateRoleRequirementsMutation,
    CreateOrUpdateRoleRequirementsMutationVariables
  >(CreateOrUpdateRoleRequirementsDocument, options);
}
export type CreateOrUpdateRoleRequirementsMutationHookResult = ReturnType<
  typeof useCreateOrUpdateRoleRequirementsMutation
>;
export type CreateOrUpdateRoleRequirementsMutationResult =
  Apollo.MutationResult<CreateOrUpdateRoleRequirementsMutation>;
export type CreateOrUpdateRoleRequirementsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrUpdateRoleRequirementsMutation,
    CreateOrUpdateRoleRequirementsMutationVariables
  >;
export const CreateRoleDocument = gql`
  mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input)
  }
`;
export type CreateRoleMutationFn = Apollo.MutationFunction<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(
    CreateRoleDocument,
    options
  );
}
export type CreateRoleMutationHookResult = ReturnType<
  typeof useCreateRoleMutation
>;
export type CreateRoleMutationResult =
  Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;
export const CreateRoleRequirementDocument = gql`
  mutation CreateRoleRequirement($input: CreateRoleRequirementInput!) {
    createRoleRequirement(input: $input) {
      createdAt
      deletedAt
      id
      mustHave
      prompt
      updatedAt
    }
  }
`;
export type CreateRoleRequirementMutationFn = Apollo.MutationFunction<
  CreateRoleRequirementMutation,
  CreateRoleRequirementMutationVariables
>;

/**
 * __useCreateRoleRequirementMutation__
 *
 * To run a mutation, you first call `useCreateRoleRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleRequirementMutation, { data, loading, error }] = useCreateRoleRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoleRequirementMutation,
    CreateRoleRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRoleRequirementMutation,
    CreateRoleRequirementMutationVariables
  >(CreateRoleRequirementDocument, options);
}
export type CreateRoleRequirementMutationHookResult = ReturnType<
  typeof useCreateRoleRequirementMutation
>;
export type CreateRoleRequirementMutationResult =
  Apollo.MutationResult<CreateRoleRequirementMutation>;
export type CreateRoleRequirementMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleRequirementMutation,
  CreateRoleRequirementMutationVariables
>;
export const CreateRoleRequirementsDocument = gql`
  mutation CreateRoleRequirements($inputs: [CreateRoleRequirementInput!]!) {
    createRoleRequirements(inputs: $inputs) {
      createdAt
      deletedAt
      id
      mustHave
      prompt
      updatedAt
    }
  }
`;
export type CreateRoleRequirementsMutationFn = Apollo.MutationFunction<
  CreateRoleRequirementsMutation,
  CreateRoleRequirementsMutationVariables
>;

/**
 * __useCreateRoleRequirementsMutation__
 *
 * To run a mutation, you first call `useCreateRoleRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleRequirementsMutation, { data, loading, error }] = useCreateRoleRequirementsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCreateRoleRequirementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoleRequirementsMutation,
    CreateRoleRequirementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRoleRequirementsMutation,
    CreateRoleRequirementsMutationVariables
  >(CreateRoleRequirementsDocument, options);
}
export type CreateRoleRequirementsMutationHookResult = ReturnType<
  typeof useCreateRoleRequirementsMutation
>;
export type CreateRoleRequirementsMutationResult =
  Apollo.MutationResult<CreateRoleRequirementsMutation>;
export type CreateRoleRequirementsMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleRequirementsMutation,
  CreateRoleRequirementsMutationVariables
>;
export const CreateSearchReviewDocument = gql`
  mutation CreateSearchReview(
    $input: [EmployerSearchReviewInput!]!
    $searchAgreementRoleId: String!
  ) {
    createSearchReview(
      input: $input
      searchAgreementRoleId: $searchAgreementRoleId
    )
  }
`;
export type CreateSearchReviewMutationFn = Apollo.MutationFunction<
  CreateSearchReviewMutation,
  CreateSearchReviewMutationVariables
>;

/**
 * __useCreateSearchReviewMutation__
 *
 * To run a mutation, you first call `useCreateSearchReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSearchReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSearchReviewMutation, { data, loading, error }] = useCreateSearchReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *      searchAgreementRoleId: // value for 'searchAgreementRoleId'
 *   },
 * });
 */
export function useCreateSearchReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSearchReviewMutation,
    CreateSearchReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSearchReviewMutation,
    CreateSearchReviewMutationVariables
  >(CreateSearchReviewDocument, options);
}
export type CreateSearchReviewMutationHookResult = ReturnType<
  typeof useCreateSearchReviewMutation
>;
export type CreateSearchReviewMutationResult =
  Apollo.MutationResult<CreateSearchReviewMutation>;
export type CreateSearchReviewMutationOptions = Apollo.BaseMutationOptions<
  CreateSearchReviewMutation,
  CreateSearchReviewMutationVariables
>;
export const CurrentEmployeeDocument = gql`
  query CurrentEmployee {
    currentEmployee {
      id
      title
      linkedIn
      location {
        id
        cityName
      }
      user {
        firstName
        lastName
        name
        phoneNumber
        photoUrl
        userConsents {
          id
          versionNumber
          userConsentType
        }
        isHeadRaceAdmin
      }
      employer {
        id
        isHeadRaceAI
        defaultMarketplacePrice
        company {
          name
          description
          logoUrl
          linkedIn
          website
          industry {
            id
            name
          }
          companyStageId
          location {
            id
            cityName
          }
          crunchbase
        }
        isTestAccount
        primaryAgencyId
        primaryAgency {
          name
        }
      }
      employerInvitationsByRecruiter {
        recruiter {
          user {
            name
          }
        }
      }
      showSearches
      roles {
        id
        name
      }
    }
  }
`;

/**
 * __useCurrentEmployeeQuery__
 *
 * To run a query within a React component, call `useCurrentEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentEmployeeQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentEmployeeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentEmployeeQuery,
    CurrentEmployeeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentEmployeeQuery, CurrentEmployeeQueryVariables>(
    CurrentEmployeeDocument,
    options
  );
}
export function useCurrentEmployeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentEmployeeQuery,
    CurrentEmployeeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentEmployeeQuery,
    CurrentEmployeeQueryVariables
  >(CurrentEmployeeDocument, options);
}
export type CurrentEmployeeQueryHookResult = ReturnType<
  typeof useCurrentEmployeeQuery
>;
export type CurrentEmployeeLazyQueryHookResult = ReturnType<
  typeof useCurrentEmployeeLazyQuery
>;
export type CurrentEmployeeQueryResult = Apollo.QueryResult<
  CurrentEmployeeQuery,
  CurrentEmployeeQueryVariables
>;
export function refetchCurrentEmployeeQuery(
  variables?: CurrentEmployeeQueryVariables
) {
  return { query: CurrentEmployeeDocument, variables: variables };
}
export const CustomerBillingInformationDocument = gql`
  query CustomerBillingInformation {
    getCustomerBillingInformation {
      id
      name
      email
      address
      phone
    }
  }
`;

/**
 * __useCustomerBillingInformationQuery__
 *
 * To run a query within a React component, call `useCustomerBillingInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerBillingInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerBillingInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerBillingInformationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomerBillingInformationQuery,
    CustomerBillingInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CustomerBillingInformationQuery,
    CustomerBillingInformationQueryVariables
  >(CustomerBillingInformationDocument, options);
}
export function useCustomerBillingInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerBillingInformationQuery,
    CustomerBillingInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomerBillingInformationQuery,
    CustomerBillingInformationQueryVariables
  >(CustomerBillingInformationDocument, options);
}
export type CustomerBillingInformationQueryHookResult = ReturnType<
  typeof useCustomerBillingInformationQuery
>;
export type CustomerBillingInformationLazyQueryHookResult = ReturnType<
  typeof useCustomerBillingInformationLazyQuery
>;
export type CustomerBillingInformationQueryResult = Apollo.QueryResult<
  CustomerBillingInformationQuery,
  CustomerBillingInformationQueryVariables
>;
export function refetchCustomerBillingInformationQuery(
  variables?: CustomerBillingInformationQueryVariables
) {
  return { query: CustomerBillingInformationDocument, variables: variables };
}
export const DeleteRoleQuestionDocument = gql`
  mutation DeleteRoleQuestion($roleQuestionId: String!) {
    deleteRoleQuestion(roleQuestionId: $roleQuestionId)
  }
`;
export type DeleteRoleQuestionMutationFn = Apollo.MutationFunction<
  DeleteRoleQuestionMutation,
  DeleteRoleQuestionMutationVariables
>;

/**
 * __useDeleteRoleQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteRoleQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleQuestionMutation, { data, loading, error }] = useDeleteRoleQuestionMutation({
 *   variables: {
 *      roleQuestionId: // value for 'roleQuestionId'
 *   },
 * });
 */
export function useDeleteRoleQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRoleQuestionMutation,
    DeleteRoleQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRoleQuestionMutation,
    DeleteRoleQuestionMutationVariables
  >(DeleteRoleQuestionDocument, options);
}
export type DeleteRoleQuestionMutationHookResult = ReturnType<
  typeof useDeleteRoleQuestionMutation
>;
export type DeleteRoleQuestionMutationResult =
  Apollo.MutationResult<DeleteRoleQuestionMutation>;
export type DeleteRoleQuestionMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoleQuestionMutation,
  DeleteRoleQuestionMutationVariables
>;
export const DeleteRoleRequirementDocument = gql`
  mutation DeleteRoleRequirement($roleRequirementId: String!) {
    deleteRoleRequirement(roleRequirementId: $roleRequirementId)
  }
`;
export type DeleteRoleRequirementMutationFn = Apollo.MutationFunction<
  DeleteRoleRequirementMutation,
  DeleteRoleRequirementMutationVariables
>;

/**
 * __useDeleteRoleRequirementMutation__
 *
 * To run a mutation, you first call `useDeleteRoleRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleRequirementMutation, { data, loading, error }] = useDeleteRoleRequirementMutation({
 *   variables: {
 *      roleRequirementId: // value for 'roleRequirementId'
 *   },
 * });
 */
export function useDeleteRoleRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRoleRequirementMutation,
    DeleteRoleRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRoleRequirementMutation,
    DeleteRoleRequirementMutationVariables
  >(DeleteRoleRequirementDocument, options);
}
export type DeleteRoleRequirementMutationHookResult = ReturnType<
  typeof useDeleteRoleRequirementMutation
>;
export type DeleteRoleRequirementMutationResult =
  Apollo.MutationResult<DeleteRoleRequirementMutation>;
export type DeleteRoleRequirementMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoleRequirementMutation,
  DeleteRoleRequirementMutationVariables
>;
export const DeleteRoleRequirementsDocument = gql`
  mutation DeleteRoleRequirements($roleIds: [String!]!) {
    deleteRoleRequirements(roleIds: $roleIds)
  }
`;
export type DeleteRoleRequirementsMutationFn = Apollo.MutationFunction<
  DeleteRoleRequirementsMutation,
  DeleteRoleRequirementsMutationVariables
>;

/**
 * __useDeleteRoleRequirementsMutation__
 *
 * To run a mutation, you first call `useDeleteRoleRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleRequirementsMutation, { data, loading, error }] = useDeleteRoleRequirementsMutation({
 *   variables: {
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useDeleteRoleRequirementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRoleRequirementsMutation,
    DeleteRoleRequirementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRoleRequirementsMutation,
    DeleteRoleRequirementsMutationVariables
  >(DeleteRoleRequirementsDocument, options);
}
export type DeleteRoleRequirementsMutationHookResult = ReturnType<
  typeof useDeleteRoleRequirementsMutation
>;
export type DeleteRoleRequirementsMutationResult =
  Apollo.MutationResult<DeleteRoleRequirementsMutation>;
export type DeleteRoleRequirementsMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoleRequirementsMutation,
  DeleteRoleRequirementsMutationVariables
>;
export const EmployeesDocument = gql`
  query Employees {
    employees {
      user {
        id
        name
        isHeadRaceAdmin
        userConsents {
          versionNumber
        }
      }
      employer {
        company {
          name
        }
      }
    }
  }
`;

/**
 * __useEmployeesQuery__
 *
 * To run a query within a React component, call `useEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeesQuery(
  baseOptions?: Apollo.QueryHookOptions<EmployeesQuery, EmployeesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmployeesQuery, EmployeesQueryVariables>(
    EmployeesDocument,
    options
  );
}
export function useEmployeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployeesQuery,
    EmployeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmployeesQuery, EmployeesQueryVariables>(
    EmployeesDocument,
    options
  );
}
export type EmployeesQueryHookResult = ReturnType<typeof useEmployeesQuery>;
export type EmployeesLazyQueryHookResult = ReturnType<
  typeof useEmployeesLazyQuery
>;
export type EmployeesQueryResult = Apollo.QueryResult<
  EmployeesQuery,
  EmployeesQueryVariables
>;
export function refetchEmployeesQuery(variables?: EmployeesQueryVariables) {
  return { query: EmployeesDocument, variables: variables };
}
export const EmployeesByEmployerDocument = gql`
  query EmployeesByEmployer {
    employeesByEmployer {
      id
      user {
        id
        firstName
        lastName
        email
        name
        photoUrl
      }
    }
  }
`;

/**
 * __useEmployeesByEmployerQuery__
 *
 * To run a query within a React component, call `useEmployeesByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesByEmployerQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeesByEmployerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmployeesByEmployerQuery,
    EmployeesByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmployeesByEmployerQuery,
    EmployeesByEmployerQueryVariables
  >(EmployeesByEmployerDocument, options);
}
export function useEmployeesByEmployerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployeesByEmployerQuery,
    EmployeesByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmployeesByEmployerQuery,
    EmployeesByEmployerQueryVariables
  >(EmployeesByEmployerDocument, options);
}
export type EmployeesByEmployerQueryHookResult = ReturnType<
  typeof useEmployeesByEmployerQuery
>;
export type EmployeesByEmployerLazyQueryHookResult = ReturnType<
  typeof useEmployeesByEmployerLazyQuery
>;
export type EmployeesByEmployerQueryResult = Apollo.QueryResult<
  EmployeesByEmployerQuery,
  EmployeesByEmployerQueryVariables
>;
export function refetchEmployeesByEmployerQuery(
  variables?: EmployeesByEmployerQueryVariables
) {
  return { query: EmployeesByEmployerDocument, variables: variables };
}
export const EmployerAiRoleByIdDocument = gql`
  query EmployerAIRoleById($id: String!) {
    employerAIRoleById(id: $id) {
      id
      isRoleAlreadyLunched
      mustHaveRoleRequirementsLimitOverride
      candidateNeedsReviewCount
      employer {
        id
        company {
          name
        }
        primaryAgencyId
      }
      name
      description
      createdAt
      updatedAt
      quantity
      expectedFirstYearSalaryMin
      expectedFirstYearSalaryMax
      expectedFirstYearShareCount
      jobDescriptionLink
      remoteWorkPolicy
      scheduleLink
      candidateNote
      interviewer {
        id
        user {
          name
        }
      }
      cities {
        id
        cityName
        stateAbbrev
      }
      employerRoleRequirements {
        mustHave
        id
        prompt
      }
      idealCandidateDescription
      idealCandidateProfiles {
        id
        linkedIn
      }
      searchAgreementRoles {
        searchAgreement {
          id
          status
          createdAt
        }
      }
    }
  }
`;

/**
 * __useEmployerAiRoleByIdQuery__
 *
 * To run a query within a React component, call `useEmployerAiRoleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployerAiRoleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployerAiRoleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployerAiRoleByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmployerAiRoleByIdQuery,
    EmployerAiRoleByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmployerAiRoleByIdQuery,
    EmployerAiRoleByIdQueryVariables
  >(EmployerAiRoleByIdDocument, options);
}
export function useEmployerAiRoleByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployerAiRoleByIdQuery,
    EmployerAiRoleByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmployerAiRoleByIdQuery,
    EmployerAiRoleByIdQueryVariables
  >(EmployerAiRoleByIdDocument, options);
}
export type EmployerAiRoleByIdQueryHookResult = ReturnType<
  typeof useEmployerAiRoleByIdQuery
>;
export type EmployerAiRoleByIdLazyQueryHookResult = ReturnType<
  typeof useEmployerAiRoleByIdLazyQuery
>;
export type EmployerAiRoleByIdQueryResult = Apollo.QueryResult<
  EmployerAiRoleByIdQuery,
  EmployerAiRoleByIdQueryVariables
>;
export function refetchEmployerAiRoleByIdQuery(
  variables: EmployerAiRoleByIdQueryVariables
) {
  return { query: EmployerAiRoleByIdDocument, variables: variables };
}
export const EmployerBillingInformationUpdateLinkDocument = gql`
  query EmployerBillingInformationUpdateLink {
    getEmployerBillingInformationUpdateLink
  }
`;

/**
 * __useEmployerBillingInformationUpdateLinkQuery__
 *
 * To run a query within a React component, call `useEmployerBillingInformationUpdateLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployerBillingInformationUpdateLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployerBillingInformationUpdateLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployerBillingInformationUpdateLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmployerBillingInformationUpdateLinkQuery,
    EmployerBillingInformationUpdateLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmployerBillingInformationUpdateLinkQuery,
    EmployerBillingInformationUpdateLinkQueryVariables
  >(EmployerBillingInformationUpdateLinkDocument, options);
}
export function useEmployerBillingInformationUpdateLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployerBillingInformationUpdateLinkQuery,
    EmployerBillingInformationUpdateLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmployerBillingInformationUpdateLinkQuery,
    EmployerBillingInformationUpdateLinkQueryVariables
  >(EmployerBillingInformationUpdateLinkDocument, options);
}
export type EmployerBillingInformationUpdateLinkQueryHookResult = ReturnType<
  typeof useEmployerBillingInformationUpdateLinkQuery
>;
export type EmployerBillingInformationUpdateLinkLazyQueryHookResult =
  ReturnType<typeof useEmployerBillingInformationUpdateLinkLazyQuery>;
export type EmployerBillingInformationUpdateLinkQueryResult =
  Apollo.QueryResult<
    EmployerBillingInformationUpdateLinkQuery,
    EmployerBillingInformationUpdateLinkQueryVariables
  >;
export function refetchEmployerBillingInformationUpdateLinkQuery(
  variables?: EmployerBillingInformationUpdateLinkQueryVariables
) {
  return {
    query: EmployerBillingInformationUpdateLinkDocument,
    variables: variables,
  };
}
export const EmployerRolesDocument = gql`
  query EmployerRoles {
    roles {
      id
      name
      description
      quantity
      createdAt
      updatedAt
      expectedFirstYearSalaryMin
      expectedFirstYearSalaryMax
      countCandidates
      countCandidatesInProcess
      countInterestedCandidates
      roleFunction {
        name
      }
      employer {
        defaultMarketplacePrice
        company {
          name
        }
      }
      inviteSearchAgreement {
        marketplaceVisibility
      }
      searchAgreementRoles {
        searchAgreement {
          marketplaceVisibility
        }
      }
      mostActiveSearchAgreementRole {
        countActiveRecruiters
        searchAgreement {
          id
          status
          marketplaceVisibility
        }
      }
      hiringManager {
        user {
          name
        }
      }
    }
  }
`;

/**
 * __useEmployerRolesQuery__
 *
 * To run a query within a React component, call `useEmployerRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployerRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployerRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployerRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmployerRolesQuery,
    EmployerRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmployerRolesQuery, EmployerRolesQueryVariables>(
    EmployerRolesDocument,
    options
  );
}
export function useEmployerRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployerRolesQuery,
    EmployerRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmployerRolesQuery, EmployerRolesQueryVariables>(
    EmployerRolesDocument,
    options
  );
}
export type EmployerRolesQueryHookResult = ReturnType<
  typeof useEmployerRolesQuery
>;
export type EmployerRolesLazyQueryHookResult = ReturnType<
  typeof useEmployerRolesLazyQuery
>;
export type EmployerRolesQueryResult = Apollo.QueryResult<
  EmployerRolesQuery,
  EmployerRolesQueryVariables
>;
export function refetchEmployerRolesQuery(
  variables?: EmployerRolesQueryVariables
) {
  return { query: EmployerRolesDocument, variables: variables };
}
export const HasArchivedSearchAgreementsDocument = gql`
  query HasArchivedSearchAgreements {
    hasArchivedSearchAgreements
  }
`;

/**
 * __useHasArchivedSearchAgreementsQuery__
 *
 * To run a query within a React component, call `useHasArchivedSearchAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasArchivedSearchAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasArchivedSearchAgreementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasArchivedSearchAgreementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HasArchivedSearchAgreementsQuery,
    HasArchivedSearchAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HasArchivedSearchAgreementsQuery,
    HasArchivedSearchAgreementsQueryVariables
  >(HasArchivedSearchAgreementsDocument, options);
}
export function useHasArchivedSearchAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasArchivedSearchAgreementsQuery,
    HasArchivedSearchAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HasArchivedSearchAgreementsQuery,
    HasArchivedSearchAgreementsQueryVariables
  >(HasArchivedSearchAgreementsDocument, options);
}
export type HasArchivedSearchAgreementsQueryHookResult = ReturnType<
  typeof useHasArchivedSearchAgreementsQuery
>;
export type HasArchivedSearchAgreementsLazyQueryHookResult = ReturnType<
  typeof useHasArchivedSearchAgreementsLazyQuery
>;
export type HasArchivedSearchAgreementsQueryResult = Apollo.QueryResult<
  HasArchivedSearchAgreementsQuery,
  HasArchivedSearchAgreementsQueryVariables
>;
export function refetchHasArchivedSearchAgreementsQuery(
  variables?: HasArchivedSearchAgreementsQueryVariables
) {
  return { query: HasArchivedSearchAgreementsDocument, variables: variables };
}
export const InviteMarketplaceRecruiterByEmployerDocument = gql`
  mutation InviteMarketplaceRecruiterByEmployer(
    $roleId: String!
    $recruiterId: String!
  ) {
    inviteMarketplaceRecruiterByEmployer(
      roleId: $roleId
      recruiterId: $recruiterId
    )
  }
`;
export type InviteMarketplaceRecruiterByEmployerMutationFn =
  Apollo.MutationFunction<
    InviteMarketplaceRecruiterByEmployerMutation,
    InviteMarketplaceRecruiterByEmployerMutationVariables
  >;

/**
 * __useInviteMarketplaceRecruiterByEmployerMutation__
 *
 * To run a mutation, you first call `useInviteMarketplaceRecruiterByEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMarketplaceRecruiterByEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMarketplaceRecruiterByEmployerMutation, { data, loading, error }] = useInviteMarketplaceRecruiterByEmployerMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useInviteMarketplaceRecruiterByEmployerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteMarketplaceRecruiterByEmployerMutation,
    InviteMarketplaceRecruiterByEmployerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteMarketplaceRecruiterByEmployerMutation,
    InviteMarketplaceRecruiterByEmployerMutationVariables
  >(InviteMarketplaceRecruiterByEmployerDocument, options);
}
export type InviteMarketplaceRecruiterByEmployerMutationHookResult = ReturnType<
  typeof useInviteMarketplaceRecruiterByEmployerMutation
>;
export type InviteMarketplaceRecruiterByEmployerMutationResult =
  Apollo.MutationResult<InviteMarketplaceRecruiterByEmployerMutation>;
export type InviteMarketplaceRecruiterByEmployerMutationOptions =
  Apollo.BaseMutationOptions<
    InviteMarketplaceRecruiterByEmployerMutation,
    InviteMarketplaceRecruiterByEmployerMutationVariables
  >;
export const JoinEmployerSlackChannelsDocument = gql`
  mutation JoinEmployerSlackChannels {
    joinEmployerSlackChannels
  }
`;
export type JoinEmployerSlackChannelsMutationFn = Apollo.MutationFunction<
  JoinEmployerSlackChannelsMutation,
  JoinEmployerSlackChannelsMutationVariables
>;

/**
 * __useJoinEmployerSlackChannelsMutation__
 *
 * To run a mutation, you first call `useJoinEmployerSlackChannelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinEmployerSlackChannelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinEmployerSlackChannelsMutation, { data, loading, error }] = useJoinEmployerSlackChannelsMutation({
 *   variables: {
 *   },
 * });
 */
export function useJoinEmployerSlackChannelsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinEmployerSlackChannelsMutation,
    JoinEmployerSlackChannelsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinEmployerSlackChannelsMutation,
    JoinEmployerSlackChannelsMutationVariables
  >(JoinEmployerSlackChannelsDocument, options);
}
export type JoinEmployerSlackChannelsMutationHookResult = ReturnType<
  typeof useJoinEmployerSlackChannelsMutation
>;
export type JoinEmployerSlackChannelsMutationResult =
  Apollo.MutationResult<JoinEmployerSlackChannelsMutation>;
export type JoinEmployerSlackChannelsMutationOptions =
  Apollo.BaseMutationOptions<
    JoinEmployerSlackChannelsMutation,
    JoinEmployerSlackChannelsMutationVariables
  >;
export const LaunchAiRoleDocument = gql`
  mutation LaunchAIRole($roleId: String!) {
    launchAIRole(roleId: $roleId)
  }
`;
export type LaunchAiRoleMutationFn = Apollo.MutationFunction<
  LaunchAiRoleMutation,
  LaunchAiRoleMutationVariables
>;

/**
 * __useLaunchAiRoleMutation__
 *
 * To run a mutation, you first call `useLaunchAiRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLaunchAiRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [launchAiRoleMutation, { data, loading, error }] = useLaunchAiRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useLaunchAiRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LaunchAiRoleMutation,
    LaunchAiRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LaunchAiRoleMutation,
    LaunchAiRoleMutationVariables
  >(LaunchAiRoleDocument, options);
}
export type LaunchAiRoleMutationHookResult = ReturnType<
  typeof useLaunchAiRoleMutation
>;
export type LaunchAiRoleMutationResult =
  Apollo.MutationResult<LaunchAiRoleMutation>;
export type LaunchAiRoleMutationOptions = Apollo.BaseMutationOptions<
  LaunchAiRoleMutation,
  LaunchAiRoleMutationVariables
>;
export const ListEmployerPaymentInvoicesDocument = gql`
  query ListEmployerPaymentInvoices {
    listEmployerPaymentInvoices {
      id
      feeTriggerType
      amount
      date
      agencyName
      invoiceStatus
      searchAgreementId
      invoiceUrl
    }
  }
`;

/**
 * __useListEmployerPaymentInvoicesQuery__
 *
 * To run a query within a React component, call `useListEmployerPaymentInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployerPaymentInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployerPaymentInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEmployerPaymentInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListEmployerPaymentInvoicesQuery,
    ListEmployerPaymentInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListEmployerPaymentInvoicesQuery,
    ListEmployerPaymentInvoicesQueryVariables
  >(ListEmployerPaymentInvoicesDocument, options);
}
export function useListEmployerPaymentInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListEmployerPaymentInvoicesQuery,
    ListEmployerPaymentInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListEmployerPaymentInvoicesQuery,
    ListEmployerPaymentInvoicesQueryVariables
  >(ListEmployerPaymentInvoicesDocument, options);
}
export type ListEmployerPaymentInvoicesQueryHookResult = ReturnType<
  typeof useListEmployerPaymentInvoicesQuery
>;
export type ListEmployerPaymentInvoicesLazyQueryHookResult = ReturnType<
  typeof useListEmployerPaymentInvoicesLazyQuery
>;
export type ListEmployerPaymentInvoicesQueryResult = Apollo.QueryResult<
  ListEmployerPaymentInvoicesQuery,
  ListEmployerPaymentInvoicesQueryVariables
>;
export function refetchListEmployerPaymentInvoicesQuery(
  variables?: ListEmployerPaymentInvoicesQueryVariables
) {
  return { query: ListEmployerPaymentInvoicesDocument, variables: variables };
}
export const ListEmployerPaymentMethodsDocument = gql`
  query ListEmployerPaymentMethods {
    listEmployerPaymentMethods {
      id
      type
      name
      description
    }
  }
`;

/**
 * __useListEmployerPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useListEmployerPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployerPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployerPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEmployerPaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListEmployerPaymentMethodsQuery,
    ListEmployerPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListEmployerPaymentMethodsQuery,
    ListEmployerPaymentMethodsQueryVariables
  >(ListEmployerPaymentMethodsDocument, options);
}
export function useListEmployerPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListEmployerPaymentMethodsQuery,
    ListEmployerPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListEmployerPaymentMethodsQuery,
    ListEmployerPaymentMethodsQueryVariables
  >(ListEmployerPaymentMethodsDocument, options);
}
export type ListEmployerPaymentMethodsQueryHookResult = ReturnType<
  typeof useListEmployerPaymentMethodsQuery
>;
export type ListEmployerPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useListEmployerPaymentMethodsLazyQuery
>;
export type ListEmployerPaymentMethodsQueryResult = Apollo.QueryResult<
  ListEmployerPaymentMethodsQuery,
  ListEmployerPaymentMethodsQueryVariables
>;
export function refetchListEmployerPaymentMethodsQuery(
  variables?: ListEmployerPaymentMethodsQueryVariables
) {
  return { query: ListEmployerPaymentMethodsDocument, variables: variables };
}
export const LoadFixturesDocument = gql`
  mutation LoadFixtures {
    loadFixtures
  }
`;
export type LoadFixturesMutationFn = Apollo.MutationFunction<
  LoadFixturesMutation,
  LoadFixturesMutationVariables
>;

/**
 * __useLoadFixturesMutation__
 *
 * To run a mutation, you first call `useLoadFixturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadFixturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadFixturesMutation, { data, loading, error }] = useLoadFixturesMutation({
 *   variables: {
 *   },
 * });
 */
export function useLoadFixturesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoadFixturesMutation,
    LoadFixturesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoadFixturesMutation,
    LoadFixturesMutationVariables
  >(LoadFixturesDocument, options);
}
export type LoadFixturesMutationHookResult = ReturnType<
  typeof useLoadFixturesMutation
>;
export type LoadFixturesMutationResult =
  Apollo.MutationResult<LoadFixturesMutation>;
export type LoadFixturesMutationOptions = Apollo.BaseMutationOptions<
  LoadFixturesMutation,
  LoadFixturesMutationVariables
>;
export const OpenRolesByEmployerDocument = gql`
  query OpenRolesByEmployer {
    openRolesByEmployer {
      id
      name
      description
      quantity
      createdAt
      updatedAt
      expectedFirstYearSalaryMin
      expectedFirstYearSalaryMax
      employer {
        company {
          name
        }
      }
      cities {
        cityName
        stateAbbrev
      }
    }
  }
`;

/**
 * __useOpenRolesByEmployerQuery__
 *
 * To run a query within a React component, call `useOpenRolesByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenRolesByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenRolesByEmployerQuery({
 *   variables: {
 *   },
 * });
 */
export function useOpenRolesByEmployerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OpenRolesByEmployerQuery,
    OpenRolesByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OpenRolesByEmployerQuery,
    OpenRolesByEmployerQueryVariables
  >(OpenRolesByEmployerDocument, options);
}
export function useOpenRolesByEmployerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OpenRolesByEmployerQuery,
    OpenRolesByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OpenRolesByEmployerQuery,
    OpenRolesByEmployerQueryVariables
  >(OpenRolesByEmployerDocument, options);
}
export type OpenRolesByEmployerQueryHookResult = ReturnType<
  typeof useOpenRolesByEmployerQuery
>;
export type OpenRolesByEmployerLazyQueryHookResult = ReturnType<
  typeof useOpenRolesByEmployerLazyQuery
>;
export type OpenRolesByEmployerQueryResult = Apollo.QueryResult<
  OpenRolesByEmployerQuery,
  OpenRolesByEmployerQueryVariables
>;
export function refetchOpenRolesByEmployerQuery(
  variables?: OpenRolesByEmployerQueryVariables
) {
  return { query: OpenRolesByEmployerDocument, variables: variables };
}
export const OutreachRoleMetricsDocument = gql`
  query OutreachRoleMetrics($roleId: String!) {
    outreachRoleMetrics(roleId: $roleId) {
      outreachInProgressMetrics {
        initialOutreach
        firstFollowUp
        secondFollowUp
      }
      outreachFinalizedMetrics {
        interested
        notInterested
        noResponse
      }
    }
  }
`;

/**
 * __useOutreachRoleMetricsQuery__
 *
 * To run a query within a React component, call `useOutreachRoleMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutreachRoleMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutreachRoleMetricsQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useOutreachRoleMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OutreachRoleMetricsQuery,
    OutreachRoleMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OutreachRoleMetricsQuery,
    OutreachRoleMetricsQueryVariables
  >(OutreachRoleMetricsDocument, options);
}
export function useOutreachRoleMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OutreachRoleMetricsQuery,
    OutreachRoleMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OutreachRoleMetricsQuery,
    OutreachRoleMetricsQueryVariables
  >(OutreachRoleMetricsDocument, options);
}
export type OutreachRoleMetricsQueryHookResult = ReturnType<
  typeof useOutreachRoleMetricsQuery
>;
export type OutreachRoleMetricsLazyQueryHookResult = ReturnType<
  typeof useOutreachRoleMetricsLazyQuery
>;
export type OutreachRoleMetricsQueryResult = Apollo.QueryResult<
  OutreachRoleMetricsQuery,
  OutreachRoleMetricsQueryVariables
>;
export function refetchOutreachRoleMetricsQuery(
  variables: OutreachRoleMetricsQueryVariables
) {
  return { query: OutreachRoleMetricsDocument, variables: variables };
}
export const OutreachRoleSequencesDocument = gql`
  query OutreachRoleSequences($roleId: String!) {
    outreachRoleSequences(roleId: $roleId) {
      id
      candidate
      stage
      outreachMessageType
      linkedIn
      dateSource
      profilePicture
      frontEmailMessages {
        id
        body
        type
        createdAt
        outreachMessage {
          id
          scheduledAt
          sentAt
          type
        }
      }
      outreachMessages {
        id
        scheduledAt
        sentAt
        type
        message
      }
      outreachChannel
    }
  }
`;

/**
 * __useOutreachRoleSequencesQuery__
 *
 * To run a query within a React component, call `useOutreachRoleSequencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutreachRoleSequencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutreachRoleSequencesQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useOutreachRoleSequencesQuery(
  baseOptions: Apollo.QueryHookOptions<
    OutreachRoleSequencesQuery,
    OutreachRoleSequencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OutreachRoleSequencesQuery,
    OutreachRoleSequencesQueryVariables
  >(OutreachRoleSequencesDocument, options);
}
export function useOutreachRoleSequencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OutreachRoleSequencesQuery,
    OutreachRoleSequencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OutreachRoleSequencesQuery,
    OutreachRoleSequencesQueryVariables
  >(OutreachRoleSequencesDocument, options);
}
export type OutreachRoleSequencesQueryHookResult = ReturnType<
  typeof useOutreachRoleSequencesQuery
>;
export type OutreachRoleSequencesLazyQueryHookResult = ReturnType<
  typeof useOutreachRoleSequencesLazyQuery
>;
export type OutreachRoleSequencesQueryResult = Apollo.QueryResult<
  OutreachRoleSequencesQuery,
  OutreachRoleSequencesQueryVariables
>;
export function refetchOutreachRoleSequencesQuery(
  variables: OutreachRoleSequencesQueryVariables
) {
  return { query: OutreachRoleSequencesDocument, variables: variables };
}
export const PauseMarketplaceRoleDocument = gql`
  mutation PauseMarketplaceRole($roleId: String!) {
    pauseMarketplaceRole(roleId: $roleId)
  }
`;
export type PauseMarketplaceRoleMutationFn = Apollo.MutationFunction<
  PauseMarketplaceRoleMutation,
  PauseMarketplaceRoleMutationVariables
>;

/**
 * __usePauseMarketplaceRoleMutation__
 *
 * To run a mutation, you first call `usePauseMarketplaceRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseMarketplaceRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseMarketplaceRoleMutation, { data, loading, error }] = usePauseMarketplaceRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function usePauseMarketplaceRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PauseMarketplaceRoleMutation,
    PauseMarketplaceRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PauseMarketplaceRoleMutation,
    PauseMarketplaceRoleMutationVariables
  >(PauseMarketplaceRoleDocument, options);
}
export type PauseMarketplaceRoleMutationHookResult = ReturnType<
  typeof usePauseMarketplaceRoleMutation
>;
export type PauseMarketplaceRoleMutationResult =
  Apollo.MutationResult<PauseMarketplaceRoleMutation>;
export type PauseMarketplaceRoleMutationOptions = Apollo.BaseMutationOptions<
  PauseMarketplaceRoleMutation,
  PauseMarketplaceRoleMutationVariables
>;
export const PlacementCompaniesDocument = gql`
  query PlacementCompanies($recruiterId: String!) {
    placementCompanies(recruiterId: $recruiterId) {
      id
      name
      logoUrl
    }
  }
`;

/**
 * __usePlacementCompaniesQuery__
 *
 * To run a query within a React component, call `usePlacementCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacementCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacementCompaniesQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function usePlacementCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlacementCompaniesQuery,
    PlacementCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlacementCompaniesQuery,
    PlacementCompaniesQueryVariables
  >(PlacementCompaniesDocument, options);
}
export function usePlacementCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlacementCompaniesQuery,
    PlacementCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlacementCompaniesQuery,
    PlacementCompaniesQueryVariables
  >(PlacementCompaniesDocument, options);
}
export type PlacementCompaniesQueryHookResult = ReturnType<
  typeof usePlacementCompaniesQuery
>;
export type PlacementCompaniesLazyQueryHookResult = ReturnType<
  typeof usePlacementCompaniesLazyQuery
>;
export type PlacementCompaniesQueryResult = Apollo.QueryResult<
  PlacementCompaniesQuery,
  PlacementCompaniesQueryVariables
>;
export function refetchPlacementCompaniesQuery(
  variables: PlacementCompaniesQueryVariables
) {
  return { query: PlacementCompaniesDocument, variables: variables };
}
export const ProspectMetricsDocument = gql`
  query ProspectMetrics($roleId: String!) {
    prospectMetrics(roleId: $roleId) {
      prospectReviewedCount
      prospectQualifiedCount
      outreachSequencesCount
      candidatesInterestedCount
    }
  }
`;

/**
 * __useProspectMetricsQuery__
 *
 * To run a query within a React component, call `useProspectMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectMetricsQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useProspectMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProspectMetricsQuery,
    ProspectMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProspectMetricsQuery, ProspectMetricsQueryVariables>(
    ProspectMetricsDocument,
    options
  );
}
export function useProspectMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProspectMetricsQuery,
    ProspectMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProspectMetricsQuery,
    ProspectMetricsQueryVariables
  >(ProspectMetricsDocument, options);
}
export type ProspectMetricsQueryHookResult = ReturnType<
  typeof useProspectMetricsQuery
>;
export type ProspectMetricsLazyQueryHookResult = ReturnType<
  typeof useProspectMetricsLazyQuery
>;
export type ProspectMetricsQueryResult = Apollo.QueryResult<
  ProspectMetricsQuery,
  ProspectMetricsQueryVariables
>;
export function refetchProspectMetricsQuery(
  variables: ProspectMetricsQueryVariables
) {
  return { query: ProspectMetricsDocument, variables: variables };
}
export const ProspectNotInterestedDocument = gql`
  mutation prospectNotInterested($publicDossierId: String!) {
    prospectNotInterested(publicDossierId: $publicDossierId)
  }
`;
export type ProspectNotInterestedMutationFn = Apollo.MutationFunction<
  ProspectNotInterestedMutation,
  ProspectNotInterestedMutationVariables
>;

/**
 * __useProspectNotInterestedMutation__
 *
 * To run a mutation, you first call `useProspectNotInterestedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProspectNotInterestedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prospectNotInterestedMutation, { data, loading, error }] = useProspectNotInterestedMutation({
 *   variables: {
 *      publicDossierId: // value for 'publicDossierId'
 *   },
 * });
 */
export function useProspectNotInterestedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProspectNotInterestedMutation,
    ProspectNotInterestedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProspectNotInterestedMutation,
    ProspectNotInterestedMutationVariables
  >(ProspectNotInterestedDocument, options);
}
export type ProspectNotInterestedMutationHookResult = ReturnType<
  typeof useProspectNotInterestedMutation
>;
export type ProspectNotInterestedMutationResult =
  Apollo.MutationResult<ProspectNotInterestedMutation>;
export type ProspectNotInterestedMutationOptions = Apollo.BaseMutationOptions<
  ProspectNotInterestedMutation,
  ProspectNotInterestedMutationVariables
>;
export const ProspectPublicDossierDocument = gql`
  query ProspectPublicDossier($id: String!) {
    prospectPublicDossier(id: $id) {
      id
      status
      prospect {
        id
        linkedInProfile {
          public_identifier
          first_name
          last_name
        }
        candidateId
      }
      candidateRoleRequirements {
        mustHave
        id
        prompt
      }
      role {
        name
        createdAt
        quantity
        description
        descriptionJSON
        expectedFirstYearSalaryMin
        expectedFirstYearSalaryMax
        expectedFirstYearShareCount
        remoteWorkPolicy
        employer {
          isHeadRaceAI
          company {
            name
            logo
            logoUrl
            linkedIn
            crunchbase
            website
            intro
            introJSON
            description
            tagline
            overviewJSON
            revenue
            totalFunding
            yoyGrowth
            tam
            latestFundingRoundAmount
            latestFundingRoundType
            investors
            employeeCount
            teamMembers {
              id
              name
              title
              linkedin
            }
            perks {
              id
              type
            }
            press {
              id
              title
              url
            }
            companyStage {
              id
              name
            }
          }
        }
        roleSeniority {
          id
          name
        }
        roleFunction {
          id
          name
        }
        cities {
          id
          cityName
          stateAbbrev
        }
        roleRequirements {
          mustHave
          id
          prompt
        }
      }
    }
  }
`;

/**
 * __useProspectPublicDossierQuery__
 *
 * To run a query within a React component, call `useProspectPublicDossierQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectPublicDossierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectPublicDossierQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProspectPublicDossierQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProspectPublicDossierQuery,
    ProspectPublicDossierQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProspectPublicDossierQuery,
    ProspectPublicDossierQueryVariables
  >(ProspectPublicDossierDocument, options);
}
export function useProspectPublicDossierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProspectPublicDossierQuery,
    ProspectPublicDossierQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProspectPublicDossierQuery,
    ProspectPublicDossierQueryVariables
  >(ProspectPublicDossierDocument, options);
}
export type ProspectPublicDossierQueryHookResult = ReturnType<
  typeof useProspectPublicDossierQuery
>;
export type ProspectPublicDossierLazyQueryHookResult = ReturnType<
  typeof useProspectPublicDossierLazyQuery
>;
export type ProspectPublicDossierQueryResult = Apollo.QueryResult<
  ProspectPublicDossierQuery,
  ProspectPublicDossierQueryVariables
>;
export function refetchProspectPublicDossierQuery(
  variables: ProspectPublicDossierQueryVariables
) {
  return { query: ProspectPublicDossierDocument, variables: variables };
}
export const ProspectSearchesByRoleIdDocument = gql`
  query ProspectSearchesByRoleId($roleId: String!) {
    prospectSearchesByRoleId(roleId: $roleId) {
      id
      createdAt
      query
      parentProspectSearchId
      linkedInPublicIdentifier
      searchType
      qualifiedProspectPercentage
      prospectsInterestedCount
      prospects {
        createdAt
        publicIdentifier
        evaluationScore
        status
        matchPercentage
        linkedInProfile {
          full_name
        }
      }
    }
  }
`;

/**
 * __useProspectSearchesByRoleIdQuery__
 *
 * To run a query within a React component, call `useProspectSearchesByRoleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectSearchesByRoleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectSearchesByRoleIdQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useProspectSearchesByRoleIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProspectSearchesByRoleIdQuery,
    ProspectSearchesByRoleIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProspectSearchesByRoleIdQuery,
    ProspectSearchesByRoleIdQueryVariables
  >(ProspectSearchesByRoleIdDocument, options);
}
export function useProspectSearchesByRoleIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProspectSearchesByRoleIdQuery,
    ProspectSearchesByRoleIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProspectSearchesByRoleIdQuery,
    ProspectSearchesByRoleIdQueryVariables
  >(ProspectSearchesByRoleIdDocument, options);
}
export type ProspectSearchesByRoleIdQueryHookResult = ReturnType<
  typeof useProspectSearchesByRoleIdQuery
>;
export type ProspectSearchesByRoleIdLazyQueryHookResult = ReturnType<
  typeof useProspectSearchesByRoleIdLazyQuery
>;
export type ProspectSearchesByRoleIdQueryResult = Apollo.QueryResult<
  ProspectSearchesByRoleIdQuery,
  ProspectSearchesByRoleIdQueryVariables
>;
export function refetchProspectSearchesByRoleIdQuery(
  variables: ProspectSearchesByRoleIdQueryVariables
) {
  return { query: ProspectSearchesByRoleIdDocument, variables: variables };
}
export const ProspectsByRoleIdDocument = gql`
  query ProspectsByRoleId($roleId: String!) {
    prospectsByRoleId(roleId: $roleId) {
      id
      status
      publicIdentifier
      evaluationScore
      roleRequirementPercentage
      createdAt
      roleRequirements {
        id
        prompt
        requirementExplanation
        meetRequirement
        mustHave
      }
      linkedInProfile {
        name
        occupation
        city
        country
        experiences
        education
        profile_pic_url
      }
      roleMeetRequirementsCount
      roleRequirementsEvaluatedCount
      outreachSequence {
        status
      }
    }
  }
`;

/**
 * __useProspectsByRoleIdQuery__
 *
 * To run a query within a React component, call `useProspectsByRoleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectsByRoleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectsByRoleIdQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useProspectsByRoleIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProspectsByRoleIdQuery,
    ProspectsByRoleIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProspectsByRoleIdQuery,
    ProspectsByRoleIdQueryVariables
  >(ProspectsByRoleIdDocument, options);
}
export function useProspectsByRoleIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProspectsByRoleIdQuery,
    ProspectsByRoleIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProspectsByRoleIdQuery,
    ProspectsByRoleIdQueryVariables
  >(ProspectsByRoleIdDocument, options);
}
export type ProspectsByRoleIdQueryHookResult = ReturnType<
  typeof useProspectsByRoleIdQuery
>;
export type ProspectsByRoleIdLazyQueryHookResult = ReturnType<
  typeof useProspectsByRoleIdLazyQuery
>;
export type ProspectsByRoleIdQueryResult = Apollo.QueryResult<
  ProspectsByRoleIdQuery,
  ProspectsByRoleIdQueryVariables
>;
export function refetchProspectsByRoleIdQuery(
  variables: ProspectsByRoleIdQueryVariables
) {
  return { query: ProspectsByRoleIdDocument, variables: variables };
}
export const PublishRoleDocument = gql`
  mutation PublishRole($input: PublishRoleInput!) {
    publishRole(input: $input)
  }
`;
export type PublishRoleMutationFn = Apollo.MutationFunction<
  PublishRoleMutation,
  PublishRoleMutationVariables
>;

/**
 * __usePublishRoleMutation__
 *
 * To run a mutation, you first call `usePublishRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishRoleMutation, { data, loading, error }] = usePublishRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishRoleMutation,
    PublishRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PublishRoleMutation, PublishRoleMutationVariables>(
    PublishRoleDocument,
    options
  );
}
export type PublishRoleMutationHookResult = ReturnType<
  typeof usePublishRoleMutation
>;
export type PublishRoleMutationResult =
  Apollo.MutationResult<PublishRoleMutation>;
export type PublishRoleMutationOptions = Apollo.BaseMutationOptions<
  PublishRoleMutation,
  PublishRoleMutationVariables
>;
export const RecommendedRecruitersByRoleDocument = gql`
  query RecommendedRecruitersByRole($roleId: String!) {
    recommendedRecruitersByRole(roleId: $roleId) {
      id
      isRequested
      isPrimaryAgency
      topRoleSeniority {
        name
      }
      role {
        id
        leads {
          requestedRecruiterId
        }
      }
      recruiter {
        id
        linkedIn
        minPricePercent
        maxPricePercent
        schedulingLink15Min
        schedulingLink30Min
        schedulingLink60Min
        title
        bio
        searchPreference
        user {
          name
          email
          phoneNumber
          photoUrl
        }
        agency {
          id
          name
          website
        }
        location {
          id
          cityName
          stateAbbrev
        }
        roleFunctions {
          id
          name
        }
      }
      placementsCompanyStages
      placementsByFunctionCount
      timeToPlacement
      isRequestedToDirect
      isInvitedToMarketplaceRole
    }
  }
`;

/**
 * __useRecommendedRecruitersByRoleQuery__
 *
 * To run a query within a React component, call `useRecommendedRecruitersByRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedRecruitersByRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedRecruitersByRoleQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useRecommendedRecruitersByRoleQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecommendedRecruitersByRoleQuery,
    RecommendedRecruitersByRoleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecommendedRecruitersByRoleQuery,
    RecommendedRecruitersByRoleQueryVariables
  >(RecommendedRecruitersByRoleDocument, options);
}
export function useRecommendedRecruitersByRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecommendedRecruitersByRoleQuery,
    RecommendedRecruitersByRoleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecommendedRecruitersByRoleQuery,
    RecommendedRecruitersByRoleQueryVariables
  >(RecommendedRecruitersByRoleDocument, options);
}
export type RecommendedRecruitersByRoleQueryHookResult = ReturnType<
  typeof useRecommendedRecruitersByRoleQuery
>;
export type RecommendedRecruitersByRoleLazyQueryHookResult = ReturnType<
  typeof useRecommendedRecruitersByRoleLazyQuery
>;
export type RecommendedRecruitersByRoleQueryResult = Apollo.QueryResult<
  RecommendedRecruitersByRoleQuery,
  RecommendedRecruitersByRoleQueryVariables
>;
export function refetchRecommendedRecruitersByRoleQuery(
  variables: RecommendedRecruitersByRoleQueryVariables
) {
  return { query: RecommendedRecruitersByRoleDocument, variables: variables };
}
export const RecruiterCommonPlacedInstitutionsDocument = gql`
  query RecruiterCommonPlacedInstitutions($recruiterId: String!) {
    recruiterCommonPlacedInstitutions(recruiterId: $recruiterId) {
      id
      name
      placementsCount
    }
  }
`;

/**
 * __useRecruiterCommonPlacedInstitutionsQuery__
 *
 * To run a query within a React component, call `useRecruiterCommonPlacedInstitutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterCommonPlacedInstitutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterCommonPlacedInstitutionsQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useRecruiterCommonPlacedInstitutionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecruiterCommonPlacedInstitutionsQuery,
    RecruiterCommonPlacedInstitutionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecruiterCommonPlacedInstitutionsQuery,
    RecruiterCommonPlacedInstitutionsQueryVariables
  >(RecruiterCommonPlacedInstitutionsDocument, options);
}
export function useRecruiterCommonPlacedInstitutionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecruiterCommonPlacedInstitutionsQuery,
    RecruiterCommonPlacedInstitutionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecruiterCommonPlacedInstitutionsQuery,
    RecruiterCommonPlacedInstitutionsQueryVariables
  >(RecruiterCommonPlacedInstitutionsDocument, options);
}
export type RecruiterCommonPlacedInstitutionsQueryHookResult = ReturnType<
  typeof useRecruiterCommonPlacedInstitutionsQuery
>;
export type RecruiterCommonPlacedInstitutionsLazyQueryHookResult = ReturnType<
  typeof useRecruiterCommonPlacedInstitutionsLazyQuery
>;
export type RecruiterCommonPlacedInstitutionsQueryResult = Apollo.QueryResult<
  RecruiterCommonPlacedInstitutionsQuery,
  RecruiterCommonPlacedInstitutionsQueryVariables
>;
export function refetchRecruiterCommonPlacedInstitutionsQuery(
  variables: RecruiterCommonPlacedInstitutionsQueryVariables
) {
  return {
    query: RecruiterCommonPlacedInstitutionsDocument,
    variables: variables,
  };
}
export const RecruiterPlacementAverageTenureDocument = gql`
  query RecruiterPlacementAverageTenure($recruiterId: String!) {
    placementAverageTenure(recruiterId: $recruiterId)
  }
`;

/**
 * __useRecruiterPlacementAverageTenureQuery__
 *
 * To run a query within a React component, call `useRecruiterPlacementAverageTenureQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterPlacementAverageTenureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterPlacementAverageTenureQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useRecruiterPlacementAverageTenureQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecruiterPlacementAverageTenureQuery,
    RecruiterPlacementAverageTenureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecruiterPlacementAverageTenureQuery,
    RecruiterPlacementAverageTenureQueryVariables
  >(RecruiterPlacementAverageTenureDocument, options);
}
export function useRecruiterPlacementAverageTenureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecruiterPlacementAverageTenureQuery,
    RecruiterPlacementAverageTenureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecruiterPlacementAverageTenureQuery,
    RecruiterPlacementAverageTenureQueryVariables
  >(RecruiterPlacementAverageTenureDocument, options);
}
export type RecruiterPlacementAverageTenureQueryHookResult = ReturnType<
  typeof useRecruiterPlacementAverageTenureQuery
>;
export type RecruiterPlacementAverageTenureLazyQueryHookResult = ReturnType<
  typeof useRecruiterPlacementAverageTenureLazyQuery
>;
export type RecruiterPlacementAverageTenureQueryResult = Apollo.QueryResult<
  RecruiterPlacementAverageTenureQuery,
  RecruiterPlacementAverageTenureQueryVariables
>;
export function refetchRecruiterPlacementAverageTenureQuery(
  variables: RecruiterPlacementAverageTenureQueryVariables
) {
  return {
    query: RecruiterPlacementAverageTenureDocument,
    variables: variables,
  };
}
export const RecruiterPlacementGeographiesDocument = gql`
  query RecruiterPlacementGeographies($recruiterId: String!) {
    recruiterPlacementGeographies(recruiterId: $recruiterId) {
      cityCode
      cityName
      placementsCount
      longitude
      latitude
    }
  }
`;

/**
 * __useRecruiterPlacementGeographiesQuery__
 *
 * To run a query within a React component, call `useRecruiterPlacementGeographiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterPlacementGeographiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterPlacementGeographiesQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useRecruiterPlacementGeographiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecruiterPlacementGeographiesQuery,
    RecruiterPlacementGeographiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecruiterPlacementGeographiesQuery,
    RecruiterPlacementGeographiesQueryVariables
  >(RecruiterPlacementGeographiesDocument, options);
}
export function useRecruiterPlacementGeographiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecruiterPlacementGeographiesQuery,
    RecruiterPlacementGeographiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecruiterPlacementGeographiesQuery,
    RecruiterPlacementGeographiesQueryVariables
  >(RecruiterPlacementGeographiesDocument, options);
}
export type RecruiterPlacementGeographiesQueryHookResult = ReturnType<
  typeof useRecruiterPlacementGeographiesQuery
>;
export type RecruiterPlacementGeographiesLazyQueryHookResult = ReturnType<
  typeof useRecruiterPlacementGeographiesLazyQuery
>;
export type RecruiterPlacementGeographiesQueryResult = Apollo.QueryResult<
  RecruiterPlacementGeographiesQuery,
  RecruiterPlacementGeographiesQueryVariables
>;
export function refetchRecruiterPlacementGeographiesQuery(
  variables: RecruiterPlacementGeographiesQueryVariables
) {
  return { query: RecruiterPlacementGeographiesDocument, variables: variables };
}
export const RecruiterRelevancesByRoleDocument = gql`
  query RecruiterRelevancesByRole($roleId: String!) {
    recruiterRelevancesByRole(roleId: $roleId) {
      id
      linkedIn
      minPricePercent
      maxPricePercent
      timeToPlacement
      schedulingLink15Min
      schedulingLink30Min
      schedulingLink60Min
      title
      bio
      searchPreference
      placementsCompanyStages
      placementsByFunctionCount
      user {
        name
        email
        phoneNumber
        photoUrl
      }
      agency {
        id
        name
        website
      }
      location {
        id
        cityName
        stateAbbrev
      }
      isRequested
      roleFunctions {
        id
        name
      }
      topRoleSeniority {
        name
      }
      isRequestedToDirect
      isInvitedToMarketplaceRole
    }
  }
`;

/**
 * __useRecruiterRelevancesByRoleQuery__
 *
 * To run a query within a React component, call `useRecruiterRelevancesByRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterRelevancesByRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterRelevancesByRoleQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useRecruiterRelevancesByRoleQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecruiterRelevancesByRoleQuery,
    RecruiterRelevancesByRoleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecruiterRelevancesByRoleQuery,
    RecruiterRelevancesByRoleQueryVariables
  >(RecruiterRelevancesByRoleDocument, options);
}
export function useRecruiterRelevancesByRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecruiterRelevancesByRoleQuery,
    RecruiterRelevancesByRoleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecruiterRelevancesByRoleQuery,
    RecruiterRelevancesByRoleQueryVariables
  >(RecruiterRelevancesByRoleDocument, options);
}
export type RecruiterRelevancesByRoleQueryHookResult = ReturnType<
  typeof useRecruiterRelevancesByRoleQuery
>;
export type RecruiterRelevancesByRoleLazyQueryHookResult = ReturnType<
  typeof useRecruiterRelevancesByRoleLazyQuery
>;
export type RecruiterRelevancesByRoleQueryResult = Apollo.QueryResult<
  RecruiterRelevancesByRoleQuery,
  RecruiterRelevancesByRoleQueryVariables
>;
export function refetchRecruiterRelevancesByRoleQuery(
  variables: RecruiterRelevancesByRoleQueryVariables
) {
  return { query: RecruiterRelevancesByRoleDocument, variables: variables };
}
export const RejectAiCandidateDocument = gql`
  mutation RejectAICandidate($candidateId: String!) {
    rejectAICandidate(candidateId: $candidateId)
  }
`;
export type RejectAiCandidateMutationFn = Apollo.MutationFunction<
  RejectAiCandidateMutation,
  RejectAiCandidateMutationVariables
>;

/**
 * __useRejectAiCandidateMutation__
 *
 * To run a mutation, you first call `useRejectAiCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectAiCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectAiCandidateMutation, { data, loading, error }] = useRejectAiCandidateMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useRejectAiCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectAiCandidateMutation,
    RejectAiCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RejectAiCandidateMutation,
    RejectAiCandidateMutationVariables
  >(RejectAiCandidateDocument, options);
}
export type RejectAiCandidateMutationHookResult = ReturnType<
  typeof useRejectAiCandidateMutation
>;
export type RejectAiCandidateMutationResult =
  Apollo.MutationResult<RejectAiCandidateMutation>;
export type RejectAiCandidateMutationOptions = Apollo.BaseMutationOptions<
  RejectAiCandidateMutation,
  RejectAiCandidateMutationVariables
>;
export const RejectCandidateDocument = gql`
  mutation RejectCandidate($input: RejectCandidateInput!) {
    rejectCandidate(input: $input) {
      id
    }
  }
`;
export type RejectCandidateMutationFn = Apollo.MutationFunction<
  RejectCandidateMutation,
  RejectCandidateMutationVariables
>;

/**
 * __useRejectCandidateMutation__
 *
 * To run a mutation, you first call `useRejectCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectCandidateMutation, { data, loading, error }] = useRejectCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectCandidateMutation,
    RejectCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RejectCandidateMutation,
    RejectCandidateMutationVariables
  >(RejectCandidateDocument, options);
}
export type RejectCandidateMutationHookResult = ReturnType<
  typeof useRejectCandidateMutation
>;
export type RejectCandidateMutationResult =
  Apollo.MutationResult<RejectCandidateMutation>;
export type RejectCandidateMutationOptions = Apollo.BaseMutationOptions<
  RejectCandidateMutation,
  RejectCandidateMutationVariables
>;
export const RemoveEmployerPaymentMethodDocument = gql`
  mutation RemoveEmployerPaymentMethod($paymentMethodId: String!) {
    removeEmployerPaymentMethod(paymentMethodId: $paymentMethodId)
  }
`;
export type RemoveEmployerPaymentMethodMutationFn = Apollo.MutationFunction<
  RemoveEmployerPaymentMethodMutation,
  RemoveEmployerPaymentMethodMutationVariables
>;

/**
 * __useRemoveEmployerPaymentMethodMutation__
 *
 * To run a mutation, you first call `useRemoveEmployerPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEmployerPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEmployerPaymentMethodMutation, { data, loading, error }] = useRemoveEmployerPaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useRemoveEmployerPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveEmployerPaymentMethodMutation,
    RemoveEmployerPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveEmployerPaymentMethodMutation,
    RemoveEmployerPaymentMethodMutationVariables
  >(RemoveEmployerPaymentMethodDocument, options);
}
export type RemoveEmployerPaymentMethodMutationHookResult = ReturnType<
  typeof useRemoveEmployerPaymentMethodMutation
>;
export type RemoveEmployerPaymentMethodMutationResult =
  Apollo.MutationResult<RemoveEmployerPaymentMethodMutation>;
export type RemoveEmployerPaymentMethodMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveEmployerPaymentMethodMutation,
    RemoveEmployerPaymentMethodMutationVariables
  >;
export const RemoveInviteRecruiterDocument = gql`
  mutation RemoveInviteRecruiter($recruiterId: String!, $roleId: String!) {
    removeInviteRecruiter(recruiterId: $recruiterId, roleId: $roleId)
  }
`;
export type RemoveInviteRecruiterMutationFn = Apollo.MutationFunction<
  RemoveInviteRecruiterMutation,
  RemoveInviteRecruiterMutationVariables
>;

/**
 * __useRemoveInviteRecruiterMutation__
 *
 * To run a mutation, you first call `useRemoveInviteRecruiterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInviteRecruiterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInviteRecruiterMutation, { data, loading, error }] = useRemoveInviteRecruiterMutation({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useRemoveInviteRecruiterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveInviteRecruiterMutation,
    RemoveInviteRecruiterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveInviteRecruiterMutation,
    RemoveInviteRecruiterMutationVariables
  >(RemoveInviteRecruiterDocument, options);
}
export type RemoveInviteRecruiterMutationHookResult = ReturnType<
  typeof useRemoveInviteRecruiterMutation
>;
export type RemoveInviteRecruiterMutationResult =
  Apollo.MutationResult<RemoveInviteRecruiterMutation>;
export type RemoveInviteRecruiterMutationOptions = Apollo.BaseMutationOptions<
  RemoveInviteRecruiterMutation,
  RemoveInviteRecruiterMutationVariables
>;
export const RemoveRoleCollaboratorDocument = gql`
  mutation RemoveRoleCollaborator($roleId: String!, $userId: String!) {
    removeRoleCollaborator(roleId: $roleId, userId: $userId)
  }
`;
export type RemoveRoleCollaboratorMutationFn = Apollo.MutationFunction<
  RemoveRoleCollaboratorMutation,
  RemoveRoleCollaboratorMutationVariables
>;

/**
 * __useRemoveRoleCollaboratorMutation__
 *
 * To run a mutation, you first call `useRemoveRoleCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoleCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRoleCollaboratorMutation, { data, loading, error }] = useRemoveRoleCollaboratorMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveRoleCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveRoleCollaboratorMutation,
    RemoveRoleCollaboratorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveRoleCollaboratorMutation,
    RemoveRoleCollaboratorMutationVariables
  >(RemoveRoleCollaboratorDocument, options);
}
export type RemoveRoleCollaboratorMutationHookResult = ReturnType<
  typeof useRemoveRoleCollaboratorMutation
>;
export type RemoveRoleCollaboratorMutationResult =
  Apollo.MutationResult<RemoveRoleCollaboratorMutation>;
export type RemoveRoleCollaboratorMutationOptions = Apollo.BaseMutationOptions<
  RemoveRoleCollaboratorMutation,
  RemoveRoleCollaboratorMutationVariables
>;
export const RequestProposalDocument = gql`
  mutation RequestProposal(
    $requestProposalInput: RequestProposalEmployerInput!
  ) {
    requestProposal(requestProposalInput: $requestProposalInput)
  }
`;
export type RequestProposalMutationFn = Apollo.MutationFunction<
  RequestProposalMutation,
  RequestProposalMutationVariables
>;

/**
 * __useRequestProposalMutation__
 *
 * To run a mutation, you first call `useRequestProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestProposalMutation, { data, loading, error }] = useRequestProposalMutation({
 *   variables: {
 *      requestProposalInput: // value for 'requestProposalInput'
 *   },
 * });
 */
export function useRequestProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestProposalMutation,
    RequestProposalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestProposalMutation,
    RequestProposalMutationVariables
  >(RequestProposalDocument, options);
}
export type RequestProposalMutationHookResult = ReturnType<
  typeof useRequestProposalMutation
>;
export type RequestProposalMutationResult =
  Apollo.MutationResult<RequestProposalMutation>;
export type RequestProposalMutationOptions = Apollo.BaseMutationOptions<
  RequestProposalMutation,
  RequestProposalMutationVariables
>;
export const ResumeMarketplaceRoleDocument = gql`
  mutation ResumeMarketplaceRole($roleId: String!) {
    resumeMarketplaceRole(roleId: $roleId)
  }
`;
export type ResumeMarketplaceRoleMutationFn = Apollo.MutationFunction<
  ResumeMarketplaceRoleMutation,
  ResumeMarketplaceRoleMutationVariables
>;

/**
 * __useResumeMarketplaceRoleMutation__
 *
 * To run a mutation, you first call `useResumeMarketplaceRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeMarketplaceRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeMarketplaceRoleMutation, { data, loading, error }] = useResumeMarketplaceRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useResumeMarketplaceRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResumeMarketplaceRoleMutation,
    ResumeMarketplaceRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResumeMarketplaceRoleMutation,
    ResumeMarketplaceRoleMutationVariables
  >(ResumeMarketplaceRoleDocument, options);
}
export type ResumeMarketplaceRoleMutationHookResult = ReturnType<
  typeof useResumeMarketplaceRoleMutation
>;
export type ResumeMarketplaceRoleMutationResult =
  Apollo.MutationResult<ResumeMarketplaceRoleMutation>;
export type ResumeMarketplaceRoleMutationOptions = Apollo.BaseMutationOptions<
  ResumeMarketplaceRoleMutation,
  ResumeMarketplaceRoleMutationVariables
>;
export const RoleByIdDocument = gql`
  query RoleById($id: String!) {
    employerRoleById(id: $id) {
      id
      skipScheduleConfirmation
      scheduleLink
      interviewer {
        id
        user {
          name
        }
      }
      candidateNote
      invitePrice
      directPrice
      publicPrice
      roleType
      employer {
        id
        company {
          name
        }
        primaryAgencyId
      }
      name
      description
      createdAt
      updatedAt
      quantity
      expectedFirstYearSalaryMin
      expectedFirstYearSalaryMax
      expectedFirstYearShareCount
      jobDescriptionLink
      hasRequirements
      remoteWorkPolicy
      noteJSON
      hiringManager {
        id
        user {
          name
          email
          phoneNumber
        }
      }
      roleSeniority {
        id
        name
      }
      roleFunction {
        id
        name
      }
      cities {
        id
        cityName
        stateAbbrev
      }
      showRequirementsBillboard
      marketplaceStatusForEmployer
      inviteSearchAgreement {
        id
        status
        createdAt
      }
      publicSearchAgreement {
        id
        status
        createdAt
      }
      directSearchAgreement {
        id
        status
        createdAt
      }
      placementFee {
        feeType
        amount
      }
      status
      isHybridRole
      primarySearchAgreement {
        id
        status
        marketplaceVisibility
        placementFeeSchedule {
          feeScheduleType
          feeAmount
        }
        createdAt
      }
      primarySearchAgreementRoleId
      allSearchAgreements {
        id
        status
        createdAt
        marketplaceVisibility
        placementFeeSchedule {
          feeScheduleType
          feeAmount
        }
      }
      allDirectSearchAgreements {
        id
        status
        exclusive
        feeSchedules {
          feeScheduleType
          feeAmount
          feeTriggerType
        }
        whenFirstInvoice
        beginAt
        agency {
          name
        }
      }
    }
  }
`;

/**
 * __useRoleByIdQuery__
 *
 * To run a query within a React component, call `useRoleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleByIdQuery(
  baseOptions: Apollo.QueryHookOptions<RoleByIdQuery, RoleByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleByIdQuery, RoleByIdQueryVariables>(
    RoleByIdDocument,
    options
  );
}
export function useRoleByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleByIdQuery,
    RoleByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RoleByIdQuery, RoleByIdQueryVariables>(
    RoleByIdDocument,
    options
  );
}
export type RoleByIdQueryHookResult = ReturnType<typeof useRoleByIdQuery>;
export type RoleByIdLazyQueryHookResult = ReturnType<
  typeof useRoleByIdLazyQuery
>;
export type RoleByIdQueryResult = Apollo.QueryResult<
  RoleByIdQuery,
  RoleByIdQueryVariables
>;
export function refetchRoleByIdQuery(variables: RoleByIdQueryVariables) {
  return { query: RoleByIdDocument, variables: variables };
}
export const RoleCollaboratorsDocument = gql`
  query RoleCollaborators($roleId: String!) {
    roleCollaborators(roleId: $roleId) {
      user {
        id
        firstName
        lastName
        photoUrl
      }
    }
  }
`;

/**
 * __useRoleCollaboratorsQuery__
 *
 * To run a query within a React component, call `useRoleCollaboratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleCollaboratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleCollaboratorsQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useRoleCollaboratorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoleCollaboratorsQuery,
    RoleCollaboratorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RoleCollaboratorsQuery,
    RoleCollaboratorsQueryVariables
  >(RoleCollaboratorsDocument, options);
}
export function useRoleCollaboratorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleCollaboratorsQuery,
    RoleCollaboratorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RoleCollaboratorsQuery,
    RoleCollaboratorsQueryVariables
  >(RoleCollaboratorsDocument, options);
}
export type RoleCollaboratorsQueryHookResult = ReturnType<
  typeof useRoleCollaboratorsQuery
>;
export type RoleCollaboratorsLazyQueryHookResult = ReturnType<
  typeof useRoleCollaboratorsLazyQuery
>;
export type RoleCollaboratorsQueryResult = Apollo.QueryResult<
  RoleCollaboratorsQuery,
  RoleCollaboratorsQueryVariables
>;
export function refetchRoleCollaboratorsQuery(
  variables: RoleCollaboratorsQueryVariables
) {
  return { query: RoleCollaboratorsDocument, variables: variables };
}
export const RoleEnrichmentOptionsDocument = gql`
  query RoleEnrichmentOptions {
    roleFunctions {
      id
      name
    }
    roleSeniorities {
      id
      name
    }
    cities {
      id
      cityName
      stateName
      stateAbbrev
    }
  }
`;

/**
 * __useRoleEnrichmentOptionsQuery__
 *
 * To run a query within a React component, call `useRoleEnrichmentOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleEnrichmentOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleEnrichmentOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoleEnrichmentOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RoleEnrichmentOptionsQuery,
    RoleEnrichmentOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RoleEnrichmentOptionsQuery,
    RoleEnrichmentOptionsQueryVariables
  >(RoleEnrichmentOptionsDocument, options);
}
export function useRoleEnrichmentOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleEnrichmentOptionsQuery,
    RoleEnrichmentOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RoleEnrichmentOptionsQuery,
    RoleEnrichmentOptionsQueryVariables
  >(RoleEnrichmentOptionsDocument, options);
}
export type RoleEnrichmentOptionsQueryHookResult = ReturnType<
  typeof useRoleEnrichmentOptionsQuery
>;
export type RoleEnrichmentOptionsLazyQueryHookResult = ReturnType<
  typeof useRoleEnrichmentOptionsLazyQuery
>;
export type RoleEnrichmentOptionsQueryResult = Apollo.QueryResult<
  RoleEnrichmentOptionsQuery,
  RoleEnrichmentOptionsQueryVariables
>;
export function refetchRoleEnrichmentOptionsQuery(
  variables?: RoleEnrichmentOptionsQueryVariables
) {
  return { query: RoleEnrichmentOptionsDocument, variables: variables };
}
export const RoleQuestionsDocument = gql`
  query RoleQuestions($filter: RoleQuestionFilter!) {
    roleQuestions(filter: $filter) {
      id
      question
      answer
      updatedAt
      isPublic
      answerer {
        user {
          photoUrl
          name
        }
      }
    }
  }
`;

/**
 * __useRoleQuestionsQuery__
 *
 * To run a query within a React component, call `useRoleQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleQuestionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRoleQuestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoleQuestionsQuery,
    RoleQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleQuestionsQuery, RoleQuestionsQueryVariables>(
    RoleQuestionsDocument,
    options
  );
}
export function useRoleQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleQuestionsQuery,
    RoleQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RoleQuestionsQuery, RoleQuestionsQueryVariables>(
    RoleQuestionsDocument,
    options
  );
}
export type RoleQuestionsQueryHookResult = ReturnType<
  typeof useRoleQuestionsQuery
>;
export type RoleQuestionsLazyQueryHookResult = ReturnType<
  typeof useRoleQuestionsLazyQuery
>;
export type RoleQuestionsQueryResult = Apollo.QueryResult<
  RoleQuestionsQuery,
  RoleQuestionsQueryVariables
>;
export function refetchRoleQuestionsQuery(
  variables: RoleQuestionsQueryVariables
) {
  return { query: RoleQuestionsDocument, variables: variables };
}
export const RoleRequirementTemplatesDocument = gql`
  query RoleRequirementTemplates($filter: RoleRequirementTemplateFilter!) {
    roleRequirementTemplates(filter: $filter) {
      createdAt
      id
      template
      updatedAt
    }
  }
`;

/**
 * __useRoleRequirementTemplatesQuery__
 *
 * To run a query within a React component, call `useRoleRequirementTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleRequirementTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleRequirementTemplatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRoleRequirementTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoleRequirementTemplatesQuery,
    RoleRequirementTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RoleRequirementTemplatesQuery,
    RoleRequirementTemplatesQueryVariables
  >(RoleRequirementTemplatesDocument, options);
}
export function useRoleRequirementTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleRequirementTemplatesQuery,
    RoleRequirementTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RoleRequirementTemplatesQuery,
    RoleRequirementTemplatesQueryVariables
  >(RoleRequirementTemplatesDocument, options);
}
export type RoleRequirementTemplatesQueryHookResult = ReturnType<
  typeof useRoleRequirementTemplatesQuery
>;
export type RoleRequirementTemplatesLazyQueryHookResult = ReturnType<
  typeof useRoleRequirementTemplatesLazyQuery
>;
export type RoleRequirementTemplatesQueryResult = Apollo.QueryResult<
  RoleRequirementTemplatesQuery,
  RoleRequirementTemplatesQueryVariables
>;
export function refetchRoleRequirementTemplatesQuery(
  variables: RoleRequirementTemplatesQueryVariables
) {
  return { query: RoleRequirementTemplatesDocument, variables: variables };
}
export const RoleRequirementsDocument = gql`
  query RoleRequirements($filter: RoleRequirementFilter!) {
    roleRequirements(filter: $filter) {
      createdAt
      deletedAt
      mustHave
      id
      prompt
      updatedAt
    }
  }
`;

/**
 * __useRoleRequirementsQuery__
 *
 * To run a query within a React component, call `useRoleRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleRequirementsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRoleRequirementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoleRequirementsQuery,
    RoleRequirementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleRequirementsQuery, RoleRequirementsQueryVariables>(
    RoleRequirementsDocument,
    options
  );
}
export function useRoleRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleRequirementsQuery,
    RoleRequirementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RoleRequirementsQuery,
    RoleRequirementsQueryVariables
  >(RoleRequirementsDocument, options);
}
export type RoleRequirementsQueryHookResult = ReturnType<
  typeof useRoleRequirementsQuery
>;
export type RoleRequirementsLazyQueryHookResult = ReturnType<
  typeof useRoleRequirementsLazyQuery
>;
export type RoleRequirementsQueryResult = Apollo.QueryResult<
  RoleRequirementsQuery,
  RoleRequirementsQueryVariables
>;
export function refetchRoleRequirementsQuery(
  variables: RoleRequirementsQueryVariables
) {
  return { query: RoleRequirementsDocument, variables: variables };
}
export const ActiveRolesNumberByEmployerDocument = gql`
  query ActiveRolesNumberByEmployer {
    activeRolesNumberByEmployer
  }
`;

/**
 * __useActiveRolesNumberByEmployerQuery__
 *
 * To run a query within a React component, call `useActiveRolesNumberByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveRolesNumberByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveRolesNumberByEmployerQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveRolesNumberByEmployerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ActiveRolesNumberByEmployerQuery,
    ActiveRolesNumberByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActiveRolesNumberByEmployerQuery,
    ActiveRolesNumberByEmployerQueryVariables
  >(ActiveRolesNumberByEmployerDocument, options);
}
export function useActiveRolesNumberByEmployerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveRolesNumberByEmployerQuery,
    ActiveRolesNumberByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActiveRolesNumberByEmployerQuery,
    ActiveRolesNumberByEmployerQueryVariables
  >(ActiveRolesNumberByEmployerDocument, options);
}
export type ActiveRolesNumberByEmployerQueryHookResult = ReturnType<
  typeof useActiveRolesNumberByEmployerQuery
>;
export type ActiveRolesNumberByEmployerLazyQueryHookResult = ReturnType<
  typeof useActiveRolesNumberByEmployerLazyQuery
>;
export type ActiveRolesNumberByEmployerQueryResult = Apollo.QueryResult<
  ActiveRolesNumberByEmployerQuery,
  ActiveRolesNumberByEmployerQueryVariables
>;
export function refetchActiveRolesNumberByEmployerQuery(
  variables?: ActiveRolesNumberByEmployerQueryVariables
) {
  return { query: ActiveRolesNumberByEmployerDocument, variables: variables };
}
export const SearchAgreementByEmployerDocument = gql`
  query SearchAgreementByEmployer {
    searchAgreementByEmployer {
      id
      status
      updatedAt
      signedByEmployeeId
      anvilDocumentGroupEid
      employer {
        company {
          name
        }
      }
      agency {
        name
      }
      searchAgreementRoles {
        role {
          id
          name
        }
      }
      marketplaceVisibility
    }
  }
`;

/**
 * __useSearchAgreementByEmployerQuery__
 *
 * To run a query within a React component, call `useSearchAgreementByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAgreementByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAgreementByEmployerQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchAgreementByEmployerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchAgreementByEmployerQuery,
    SearchAgreementByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAgreementByEmployerQuery,
    SearchAgreementByEmployerQueryVariables
  >(SearchAgreementByEmployerDocument, options);
}
export function useSearchAgreementByEmployerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAgreementByEmployerQuery,
    SearchAgreementByEmployerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAgreementByEmployerQuery,
    SearchAgreementByEmployerQueryVariables
  >(SearchAgreementByEmployerDocument, options);
}
export type SearchAgreementByEmployerQueryHookResult = ReturnType<
  typeof useSearchAgreementByEmployerQuery
>;
export type SearchAgreementByEmployerLazyQueryHookResult = ReturnType<
  typeof useSearchAgreementByEmployerLazyQuery
>;
export type SearchAgreementByEmployerQueryResult = Apollo.QueryResult<
  SearchAgreementByEmployerQuery,
  SearchAgreementByEmployerQueryVariables
>;
export function refetchSearchAgreementByEmployerQuery(
  variables?: SearchAgreementByEmployerQueryVariables
) {
  return { query: SearchAgreementByEmployerDocument, variables: variables };
}
export const SearchAgreementByIdDocument = gql`
  query SearchAgreementById($id: String!) {
    searchAgreementById(id: $id) {
      id
      status
      createdAt
      beginAt
      effectiveAt
      expectedPlacements
      whenFirstInvoice
      anvilDocumentGroupEid
      employer {
        company {
          name
          description
          linkedIn
          crunchbase
        }
      }
      searchAgreementRoles {
        role {
          id
          name
          expectedFirstYearSalaryMin
          expectedFirstYearSalaryMax
          expectedFirstYearShareCount
          cities {
            cityName
            stateAbbrev
          }
        }
      }
      agency {
        name
        website
        linkedIn
      }
      feeSchedules {
        id
        feeScheduleType
        feeAmount
        feeTriggerType
        feeAdjustmentType
        feePaymentTermNetDays
        feeTriggerLimit
        performanceCandidateAcceptQuantity
        oneTimeFeeTriggerAt
        oneTimeFeeRelativeSigningDays
      }
      signedByEmployee {
        id
        user {
          firstName
          lastName
          email
          name
        }
      }
      employeeSignedAt
      feeType
      signedByRecruiter {
        id
        user {
          firstName
          lastName
          name
          email
        }
        linkedIn
        bio
        bioJSON
      }
      recruiterSignedAt
      canceledByEmployee {
        user {
          name
        }
      }
      employeeCanceledAt
      canceledByRecruiter {
        user {
          name
        }
      }
      recruiterCanceledAt
      fulfilledAt
      endAt
      exclusive
      isSignedOffPlatform
      marketplaceVisibility
    }
  }
`;

/**
 * __useSearchAgreementByIdQuery__
 *
 * To run a query within a React component, call `useSearchAgreementByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAgreementByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAgreementByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSearchAgreementByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAgreementByIdQuery,
    SearchAgreementByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAgreementByIdQuery,
    SearchAgreementByIdQueryVariables
  >(SearchAgreementByIdDocument, options);
}
export function useSearchAgreementByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAgreementByIdQuery,
    SearchAgreementByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAgreementByIdQuery,
    SearchAgreementByIdQueryVariables
  >(SearchAgreementByIdDocument, options);
}
export type SearchAgreementByIdQueryHookResult = ReturnType<
  typeof useSearchAgreementByIdQuery
>;
export type SearchAgreementByIdLazyQueryHookResult = ReturnType<
  typeof useSearchAgreementByIdLazyQuery
>;
export type SearchAgreementByIdQueryResult = Apollo.QueryResult<
  SearchAgreementByIdQuery,
  SearchAgreementByIdQueryVariables
>;
export function refetchSearchAgreementByIdQuery(
  variables: SearchAgreementByIdQueryVariables
) {
  return { query: SearchAgreementByIdDocument, variables: variables };
}
export const SenEmailToGetAccessDocument = gql`
  query SenEmailToGetAccess($input: SendEmailToGetAccessInput!) {
    sendEmailToGetAccess(input: $input)
  }
`;

/**
 * __useSenEmailToGetAccessQuery__
 *
 * To run a query within a React component, call `useSenEmailToGetAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useSenEmailToGetAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSenEmailToGetAccessQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSenEmailToGetAccessQuery(
  baseOptions: Apollo.QueryHookOptions<
    SenEmailToGetAccessQuery,
    SenEmailToGetAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SenEmailToGetAccessQuery,
    SenEmailToGetAccessQueryVariables
  >(SenEmailToGetAccessDocument, options);
}
export function useSenEmailToGetAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SenEmailToGetAccessQuery,
    SenEmailToGetAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SenEmailToGetAccessQuery,
    SenEmailToGetAccessQueryVariables
  >(SenEmailToGetAccessDocument, options);
}
export type SenEmailToGetAccessQueryHookResult = ReturnType<
  typeof useSenEmailToGetAccessQuery
>;
export type SenEmailToGetAccessLazyQueryHookResult = ReturnType<
  typeof useSenEmailToGetAccessLazyQuery
>;
export type SenEmailToGetAccessQueryResult = Apollo.QueryResult<
  SenEmailToGetAccessQuery,
  SenEmailToGetAccessQueryVariables
>;
export function refetchSenEmailToGetAccessQuery(
  variables: SenEmailToGetAccessQueryVariables
) {
  return { query: SenEmailToGetAccessDocument, variables: variables };
}
export const SetupIntentClientSecretDocument = gql`
  query SetupIntentClientSecret {
    setupIntentClientSecret
  }
`;

/**
 * __useSetupIntentClientSecretQuery__
 *
 * To run a query within a React component, call `useSetupIntentClientSecretQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupIntentClientSecretQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupIntentClientSecretQuery({
 *   variables: {
 *   },
 * });
 */
export function useSetupIntentClientSecretQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SetupIntentClientSecretQuery,
    SetupIntentClientSecretQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SetupIntentClientSecretQuery,
    SetupIntentClientSecretQueryVariables
  >(SetupIntentClientSecretDocument, options);
}
export function useSetupIntentClientSecretLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SetupIntentClientSecretQuery,
    SetupIntentClientSecretQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SetupIntentClientSecretQuery,
    SetupIntentClientSecretQueryVariables
  >(SetupIntentClientSecretDocument, options);
}
export type SetupIntentClientSecretQueryHookResult = ReturnType<
  typeof useSetupIntentClientSecretQuery
>;
export type SetupIntentClientSecretLazyQueryHookResult = ReturnType<
  typeof useSetupIntentClientSecretLazyQuery
>;
export type SetupIntentClientSecretQueryResult = Apollo.QueryResult<
  SetupIntentClientSecretQuery,
  SetupIntentClientSecretQueryVariables
>;
export function refetchSetupIntentClientSecretQuery(
  variables?: SetupIntentClientSecretQueryVariables
) {
  return { query: SetupIntentClientSecretDocument, variables: variables };
}
export const SetupIntentStatusByIdDocument = gql`
  query SetupIntentStatusById($id: String!) {
    setupIntentStatusById(setupIntentId: $id)
  }
`;

/**
 * __useSetupIntentStatusByIdQuery__
 *
 * To run a query within a React component, call `useSetupIntentStatusByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupIntentStatusByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupIntentStatusByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetupIntentStatusByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SetupIntentStatusByIdQuery,
    SetupIntentStatusByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SetupIntentStatusByIdQuery,
    SetupIntentStatusByIdQueryVariables
  >(SetupIntentStatusByIdDocument, options);
}
export function useSetupIntentStatusByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SetupIntentStatusByIdQuery,
    SetupIntentStatusByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SetupIntentStatusByIdQuery,
    SetupIntentStatusByIdQueryVariables
  >(SetupIntentStatusByIdDocument, options);
}
export type SetupIntentStatusByIdQueryHookResult = ReturnType<
  typeof useSetupIntentStatusByIdQuery
>;
export type SetupIntentStatusByIdLazyQueryHookResult = ReturnType<
  typeof useSetupIntentStatusByIdLazyQuery
>;
export type SetupIntentStatusByIdQueryResult = Apollo.QueryResult<
  SetupIntentStatusByIdQuery,
  SetupIntentStatusByIdQueryVariables
>;
export function refetchSetupIntentStatusByIdQuery(
  variables: SetupIntentStatusByIdQueryVariables
) {
  return { query: SetupIntentStatusByIdDocument, variables: variables };
}
export const TerminateMarketplaceRoleDocument = gql`
  mutation TerminateMarketplaceRole($input: TerminateMarketplaceRoleInput!) {
    terminateMarketplaceRole(input: $input)
  }
`;
export type TerminateMarketplaceRoleMutationFn = Apollo.MutationFunction<
  TerminateMarketplaceRoleMutation,
  TerminateMarketplaceRoleMutationVariables
>;

/**
 * __useTerminateMarketplaceRoleMutation__
 *
 * To run a mutation, you first call `useTerminateMarketplaceRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateMarketplaceRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateMarketplaceRoleMutation, { data, loading, error }] = useTerminateMarketplaceRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminateMarketplaceRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TerminateMarketplaceRoleMutation,
    TerminateMarketplaceRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TerminateMarketplaceRoleMutation,
    TerminateMarketplaceRoleMutationVariables
  >(TerminateMarketplaceRoleDocument, options);
}
export type TerminateMarketplaceRoleMutationHookResult = ReturnType<
  typeof useTerminateMarketplaceRoleMutation
>;
export type TerminateMarketplaceRoleMutationResult =
  Apollo.MutationResult<TerminateMarketplaceRoleMutation>;
export type TerminateMarketplaceRoleMutationOptions =
  Apollo.BaseMutationOptions<
    TerminateMarketplaceRoleMutation,
    TerminateMarketplaceRoleMutationVariables
  >;
export const TimeToReviewCandidateDocument = gql`
  query TimeToReviewCandidate {
    timeToReviewCandidate
  }
`;

/**
 * __useTimeToReviewCandidateQuery__
 *
 * To run a query within a React component, call `useTimeToReviewCandidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeToReviewCandidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeToReviewCandidateQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeToReviewCandidateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TimeToReviewCandidateQuery,
    TimeToReviewCandidateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TimeToReviewCandidateQuery,
    TimeToReviewCandidateQueryVariables
  >(TimeToReviewCandidateDocument, options);
}
export function useTimeToReviewCandidateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimeToReviewCandidateQuery,
    TimeToReviewCandidateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TimeToReviewCandidateQuery,
    TimeToReviewCandidateQueryVariables
  >(TimeToReviewCandidateDocument, options);
}
export type TimeToReviewCandidateQueryHookResult = ReturnType<
  typeof useTimeToReviewCandidateQuery
>;
export type TimeToReviewCandidateLazyQueryHookResult = ReturnType<
  typeof useTimeToReviewCandidateLazyQuery
>;
export type TimeToReviewCandidateQueryResult = Apollo.QueryResult<
  TimeToReviewCandidateQuery,
  TimeToReviewCandidateQueryVariables
>;
export function refetchTimeToReviewCandidateQuery(
  variables?: TimeToReviewCandidateQueryVariables
) {
  return { query: TimeToReviewCandidateDocument, variables: variables };
}
export const UnarchiveSearchAgreementDocument = gql`
  mutation UnarchiveSearchAgreement($searchAgreementId: String!) {
    unarchiveSearchAgreement(searchAgreementId: $searchAgreementId)
  }
`;
export type UnarchiveSearchAgreementMutationFn = Apollo.MutationFunction<
  UnarchiveSearchAgreementMutation,
  UnarchiveSearchAgreementMutationVariables
>;

/**
 * __useUnarchiveSearchAgreementMutation__
 *
 * To run a mutation, you first call `useUnarchiveSearchAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveSearchAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveSearchAgreementMutation, { data, loading, error }] = useUnarchiveSearchAgreementMutation({
 *   variables: {
 *      searchAgreementId: // value for 'searchAgreementId'
 *   },
 * });
 */
export function useUnarchiveSearchAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveSearchAgreementMutation,
    UnarchiveSearchAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnarchiveSearchAgreementMutation,
    UnarchiveSearchAgreementMutationVariables
  >(UnarchiveSearchAgreementDocument, options);
}
export type UnarchiveSearchAgreementMutationHookResult = ReturnType<
  typeof useUnarchiveSearchAgreementMutation
>;
export type UnarchiveSearchAgreementMutationResult =
  Apollo.MutationResult<UnarchiveSearchAgreementMutation>;
export type UnarchiveSearchAgreementMutationOptions =
  Apollo.BaseMutationOptions<
    UnarchiveSearchAgreementMutation,
    UnarchiveSearchAgreementMutationVariables
  >;
export const UpdateCandidateDocument = gql`
  mutation UpdateCandidate($input: UpdateCandidateInput!) {
    updateCandidate(input: $input) {
      id
    }
  }
`;
export type UpdateCandidateMutationFn = Apollo.MutationFunction<
  UpdateCandidateMutation,
  UpdateCandidateMutationVariables
>;

/**
 * __useUpdateCandidateMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateMutation, { data, loading, error }] = useUpdateCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCandidateMutation,
    UpdateCandidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCandidateMutation,
    UpdateCandidateMutationVariables
  >(UpdateCandidateDocument, options);
}
export type UpdateCandidateMutationHookResult = ReturnType<
  typeof useUpdateCandidateMutation
>;
export type UpdateCandidateMutationResult =
  Apollo.MutationResult<UpdateCandidateMutation>;
export type UpdateCandidateMutationOptions = Apollo.BaseMutationOptions<
  UpdateCandidateMutation,
  UpdateCandidateMutationVariables
>;
export const UpdateCompanyOverviewDocument = gql`
  mutation UpdateCompanyOverview($input: EmployerUpdateCompanyOverviewInput!) {
    updateCompanyOverview(input: $input)
  }
`;
export type UpdateCompanyOverviewMutationFn = Apollo.MutationFunction<
  UpdateCompanyOverviewMutation,
  UpdateCompanyOverviewMutationVariables
>;

/**
 * __useUpdateCompanyOverviewMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyOverviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyOverviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyOverviewMutation, { data, loading, error }] = useUpdateCompanyOverviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyOverviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyOverviewMutation,
    UpdateCompanyOverviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanyOverviewMutation,
    UpdateCompanyOverviewMutationVariables
  >(UpdateCompanyOverviewDocument, options);
}
export type UpdateCompanyOverviewMutationHookResult = ReturnType<
  typeof useUpdateCompanyOverviewMutation
>;
export type UpdateCompanyOverviewMutationResult =
  Apollo.MutationResult<UpdateCompanyOverviewMutation>;
export type UpdateCompanyOverviewMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyOverviewMutation,
  UpdateCompanyOverviewMutationVariables
>;
export const UpdateCompanyPerksDocument = gql`
  mutation UpdateCompanyPerks($perks: [EmployerUpdateCompanyPerkInput!]!) {
    updateCompanyPerks(perks: $perks)
  }
`;
export type UpdateCompanyPerksMutationFn = Apollo.MutationFunction<
  UpdateCompanyPerksMutation,
  UpdateCompanyPerksMutationVariables
>;

/**
 * __useUpdateCompanyPerksMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyPerksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyPerksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyPerksMutation, { data, loading, error }] = useUpdateCompanyPerksMutation({
 *   variables: {
 *      perks: // value for 'perks'
 *   },
 * });
 */
export function useUpdateCompanyPerksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyPerksMutation,
    UpdateCompanyPerksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanyPerksMutation,
    UpdateCompanyPerksMutationVariables
  >(UpdateCompanyPerksDocument, options);
}
export type UpdateCompanyPerksMutationHookResult = ReturnType<
  typeof useUpdateCompanyPerksMutation
>;
export type UpdateCompanyPerksMutationResult =
  Apollo.MutationResult<UpdateCompanyPerksMutation>;
export type UpdateCompanyPerksMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyPerksMutation,
  UpdateCompanyPerksMutationVariables
>;
export const UpdateCompanyPressDocument = gql`
  mutation UpdateCompanyPress($pressList: [EmployerUpdateCompanyPressInput!]!) {
    updateCompanyPress(pressList: $pressList)
  }
`;
export type UpdateCompanyPressMutationFn = Apollo.MutationFunction<
  UpdateCompanyPressMutation,
  UpdateCompanyPressMutationVariables
>;

/**
 * __useUpdateCompanyPressMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyPressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyPressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyPressMutation, { data, loading, error }] = useUpdateCompanyPressMutation({
 *   variables: {
 *      pressList: // value for 'pressList'
 *   },
 * });
 */
export function useUpdateCompanyPressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyPressMutation,
    UpdateCompanyPressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanyPressMutation,
    UpdateCompanyPressMutationVariables
  >(UpdateCompanyPressDocument, options);
}
export type UpdateCompanyPressMutationHookResult = ReturnType<
  typeof useUpdateCompanyPressMutation
>;
export type UpdateCompanyPressMutationResult =
  Apollo.MutationResult<UpdateCompanyPressMutation>;
export type UpdateCompanyPressMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyPressMutation,
  UpdateCompanyPressMutationVariables
>;
export const UpdateCompanyTeamMembersDocument = gql`
  mutation UpdateCompanyTeamMembers(
    $members: [EmployerUpdateCompanyTeamMemberInput!]!
  ) {
    updateCompanyTeamMembers(members: $members)
  }
`;
export type UpdateCompanyTeamMembersMutationFn = Apollo.MutationFunction<
  UpdateCompanyTeamMembersMutation,
  UpdateCompanyTeamMembersMutationVariables
>;

/**
 * __useUpdateCompanyTeamMembersMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyTeamMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyTeamMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyTeamMembersMutation, { data, loading, error }] = useUpdateCompanyTeamMembersMutation({
 *   variables: {
 *      members: // value for 'members'
 *   },
 * });
 */
export function useUpdateCompanyTeamMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyTeamMembersMutation,
    UpdateCompanyTeamMembersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanyTeamMembersMutation,
    UpdateCompanyTeamMembersMutationVariables
  >(UpdateCompanyTeamMembersDocument, options);
}
export type UpdateCompanyTeamMembersMutationHookResult = ReturnType<
  typeof useUpdateCompanyTeamMembersMutation
>;
export type UpdateCompanyTeamMembersMutationResult =
  Apollo.MutationResult<UpdateCompanyTeamMembersMutation>;
export type UpdateCompanyTeamMembersMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCompanyTeamMembersMutation,
    UpdateCompanyTeamMembersMutationVariables
  >;
export const UpdateEmployeeDocument = gql`
  mutation UpdateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      employer {
        company {
          name
          logoUrl
        }
      }
    }
  }
`;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<
  UpdateEmployeeMutation,
  UpdateEmployeeMutationVariables
>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmployeeMutation,
    UpdateEmployeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEmployeeMutation,
    UpdateEmployeeMutationVariables
  >(UpdateEmployeeDocument, options);
}
export type UpdateEmployeeMutationHookResult = ReturnType<
  typeof useUpdateEmployeeMutation
>;
export type UpdateEmployeeMutationResult =
  Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmployeeMutation,
  UpdateEmployeeMutationVariables
>;
export const UpdateInterviewProcessDocument = gql`
  mutation UpdateInterviewProcess($input: UpdateInterviewProcessInput!) {
    updateInterviewProcess(input: $input)
  }
`;
export type UpdateInterviewProcessMutationFn = Apollo.MutationFunction<
  UpdateInterviewProcessMutation,
  UpdateInterviewProcessMutationVariables
>;

/**
 * __useUpdateInterviewProcessMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewProcessMutation, { data, loading, error }] = useUpdateInterviewProcessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewProcessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInterviewProcessMutation,
    UpdateInterviewProcessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInterviewProcessMutation,
    UpdateInterviewProcessMutationVariables
  >(UpdateInterviewProcessDocument, options);
}
export type UpdateInterviewProcessMutationHookResult = ReturnType<
  typeof useUpdateInterviewProcessMutation
>;
export type UpdateInterviewProcessMutationResult =
  Apollo.MutationResult<UpdateInterviewProcessMutation>;
export type UpdateInterviewProcessMutationOptions = Apollo.BaseMutationOptions<
  UpdateInterviewProcessMutation,
  UpdateInterviewProcessMutationVariables
>;
export const UpdateDefaultMarketplacePriceDocument = gql`
  mutation UpdateDefaultMarketplacePrice($publicPrice: Float!) {
    updateDefaultMarketplacePrice(publicPrice: $publicPrice)
  }
`;
export type UpdateDefaultMarketplacePriceMutationFn = Apollo.MutationFunction<
  UpdateDefaultMarketplacePriceMutation,
  UpdateDefaultMarketplacePriceMutationVariables
>;

/**
 * __useUpdateDefaultMarketplacePriceMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultMarketplacePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultMarketplacePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultMarketplacePriceMutation, { data, loading, error }] = useUpdateDefaultMarketplacePriceMutation({
 *   variables: {
 *      publicPrice: // value for 'publicPrice'
 *   },
 * });
 */
export function useUpdateDefaultMarketplacePriceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDefaultMarketplacePriceMutation,
    UpdateDefaultMarketplacePriceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDefaultMarketplacePriceMutation,
    UpdateDefaultMarketplacePriceMutationVariables
  >(UpdateDefaultMarketplacePriceDocument, options);
}
export type UpdateDefaultMarketplacePriceMutationHookResult = ReturnType<
  typeof useUpdateDefaultMarketplacePriceMutation
>;
export type UpdateDefaultMarketplacePriceMutationResult =
  Apollo.MutationResult<UpdateDefaultMarketplacePriceMutation>;
export type UpdateDefaultMarketplacePriceMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDefaultMarketplacePriceMutation,
    UpdateDefaultMarketplacePriceMutationVariables
  >;
export const UpdateProspectDocument = gql`
  mutation UpdateProspect($prospectId: String!, $input: UpdateProspectInput!) {
    updateProspect(prospectId: $prospectId, input: $input)
  }
`;
export type UpdateProspectMutationFn = Apollo.MutationFunction<
  UpdateProspectMutation,
  UpdateProspectMutationVariables
>;

/**
 * __useUpdateProspectMutation__
 *
 * To run a mutation, you first call `useUpdateProspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProspectMutation, { data, loading, error }] = useUpdateProspectMutation({
 *   variables: {
 *      prospectId: // value for 'prospectId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProspectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProspectMutation,
    UpdateProspectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProspectMutation,
    UpdateProspectMutationVariables
  >(UpdateProspectDocument, options);
}
export type UpdateProspectMutationHookResult = ReturnType<
  typeof useUpdateProspectMutation
>;
export type UpdateProspectMutationResult =
  Apollo.MutationResult<UpdateProspectMutation>;
export type UpdateProspectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProspectMutation,
  UpdateProspectMutationVariables
>;
export const UpdateRoleCollaboratorsDocument = gql`
  mutation UpdateRoleCollaborators($input: RoleCollaboratorInput!) {
    updateRoleCollaborators(input: $input)
  }
`;
export type UpdateRoleCollaboratorsMutationFn = Apollo.MutationFunction<
  UpdateRoleCollaboratorsMutation,
  UpdateRoleCollaboratorsMutationVariables
>;

/**
 * __useUpdateRoleCollaboratorsMutation__
 *
 * To run a mutation, you first call `useUpdateRoleCollaboratorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleCollaboratorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleCollaboratorsMutation, { data, loading, error }] = useUpdateRoleCollaboratorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleCollaboratorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleCollaboratorsMutation,
    UpdateRoleCollaboratorsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRoleCollaboratorsMutation,
    UpdateRoleCollaboratorsMutationVariables
  >(UpdateRoleCollaboratorsDocument, options);
}
export type UpdateRoleCollaboratorsMutationHookResult = ReturnType<
  typeof useUpdateRoleCollaboratorsMutation
>;
export type UpdateRoleCollaboratorsMutationResult =
  Apollo.MutationResult<UpdateRoleCollaboratorsMutation>;
export type UpdateRoleCollaboratorsMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleCollaboratorsMutation,
  UpdateRoleCollaboratorsMutationVariables
>;
export const UpdateRoleDescriptionDocument = gql`
  mutation UpdateRoleDescription($input: EmployerUpdateRoleDescriptionInput!) {
    updateRoleDescription(input: $input)
  }
`;
export type UpdateRoleDescriptionMutationFn = Apollo.MutationFunction<
  UpdateRoleDescriptionMutation,
  UpdateRoleDescriptionMutationVariables
>;

/**
 * __useUpdateRoleDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateRoleDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleDescriptionMutation, { data, loading, error }] = useUpdateRoleDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleDescriptionMutation,
    UpdateRoleDescriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRoleDescriptionMutation,
    UpdateRoleDescriptionMutationVariables
  >(UpdateRoleDescriptionDocument, options);
}
export type UpdateRoleDescriptionMutationHookResult = ReturnType<
  typeof useUpdateRoleDescriptionMutation
>;
export type UpdateRoleDescriptionMutationResult =
  Apollo.MutationResult<UpdateRoleDescriptionMutation>;
export type UpdateRoleDescriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleDescriptionMutation,
  UpdateRoleDescriptionMutationVariables
>;
export const UpdateRoleFactsDocument = gql`
  mutation UpdateRoleFacts($input: EmployerUpdateRoleFactsInput!) {
    updateRoleFacts(input: $input)
  }
`;
export type UpdateRoleFactsMutationFn = Apollo.MutationFunction<
  UpdateRoleFactsMutation,
  UpdateRoleFactsMutationVariables
>;

/**
 * __useUpdateRoleFactsMutation__
 *
 * To run a mutation, you first call `useUpdateRoleFactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleFactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleFactsMutation, { data, loading, error }] = useUpdateRoleFactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleFactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleFactsMutation,
    UpdateRoleFactsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRoleFactsMutation,
    UpdateRoleFactsMutationVariables
  >(UpdateRoleFactsDocument, options);
}
export type UpdateRoleFactsMutationHookResult = ReturnType<
  typeof useUpdateRoleFactsMutation
>;
export type UpdateRoleFactsMutationResult =
  Apollo.MutationResult<UpdateRoleFactsMutation>;
export type UpdateRoleFactsMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleFactsMutation,
  UpdateRoleFactsMutationVariables
>;
export const UpdateRoleDocument = gql`
  mutation UpdateRole($id: String!, $input: UpdateRoleInput!) {
    updateRole(id: $id, input: $input)
  }
`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(
    UpdateRoleDocument,
    options
  );
}
export type UpdateRoleMutationHookResult = ReturnType<
  typeof useUpdateRoleMutation
>;
export type UpdateRoleMutationResult =
  Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;
export const UpdateRoleNoteDocument = gql`
  mutation UpdateRoleNote($roleId: String!, $noteJSON: String) {
    updateRoleNote(roleId: $roleId, noteJSON: $noteJSON)
  }
`;
export type UpdateRoleNoteMutationFn = Apollo.MutationFunction<
  UpdateRoleNoteMutation,
  UpdateRoleNoteMutationVariables
>;

/**
 * __useUpdateRoleNoteMutation__
 *
 * To run a mutation, you first call `useUpdateRoleNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleNoteMutation, { data, loading, error }] = useUpdateRoleNoteMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      noteJSON: // value for 'noteJSON'
 *   },
 * });
 */
export function useUpdateRoleNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleNoteMutation,
    UpdateRoleNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRoleNoteMutation,
    UpdateRoleNoteMutationVariables
  >(UpdateRoleNoteDocument, options);
}
export type UpdateRoleNoteMutationHookResult = ReturnType<
  typeof useUpdateRoleNoteMutation
>;
export type UpdateRoleNoteMutationResult =
  Apollo.MutationResult<UpdateRoleNoteMutation>;
export type UpdateRoleNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleNoteMutation,
  UpdateRoleNoteMutationVariables
>;
export const UpdateRoleOverviewDocument = gql`
  mutation UpdateRoleOverview($input: UpdateRoleOverviewInput!) {
    updateRoleOverview(input: $input)
  }
`;
export type UpdateRoleOverviewMutationFn = Apollo.MutationFunction<
  UpdateRoleOverviewMutation,
  UpdateRoleOverviewMutationVariables
>;

/**
 * __useUpdateRoleOverviewMutation__
 *
 * To run a mutation, you first call `useUpdateRoleOverviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleOverviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleOverviewMutation, { data, loading, error }] = useUpdateRoleOverviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleOverviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleOverviewMutation,
    UpdateRoleOverviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRoleOverviewMutation,
    UpdateRoleOverviewMutationVariables
  >(UpdateRoleOverviewDocument, options);
}
export type UpdateRoleOverviewMutationHookResult = ReturnType<
  typeof useUpdateRoleOverviewMutation
>;
export type UpdateRoleOverviewMutationResult =
  Apollo.MutationResult<UpdateRoleOverviewMutation>;
export type UpdateRoleOverviewMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleOverviewMutation,
  UpdateRoleOverviewMutationVariables
>;
export const UpdateRoleQuestionDocument = gql`
  mutation UpdateRoleQuestion($input: UpdateRoleQuestionInput!) {
    updateRoleQuestion(input: $input) {
      id
    }
  }
`;
export type UpdateRoleQuestionMutationFn = Apollo.MutationFunction<
  UpdateRoleQuestionMutation,
  UpdateRoleQuestionMutationVariables
>;

/**
 * __useUpdateRoleQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateRoleQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleQuestionMutation, { data, loading, error }] = useUpdateRoleQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleQuestionMutation,
    UpdateRoleQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRoleQuestionMutation,
    UpdateRoleQuestionMutationVariables
  >(UpdateRoleQuestionDocument, options);
}
export type UpdateRoleQuestionMutationHookResult = ReturnType<
  typeof useUpdateRoleQuestionMutation
>;
export type UpdateRoleQuestionMutationResult =
  Apollo.MutationResult<UpdateRoleQuestionMutation>;
export type UpdateRoleQuestionMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleQuestionMutation,
  UpdateRoleQuestionMutationVariables
>;
export const UpdateRoleRequirementsDocument = gql`
  mutation UpdateRoleRequirements($inputs: [UpdateRoleRequirementInput!]!) {
    updateRoleRequirements(inputs: $inputs) {
      createdAt
      deletedAt
      id
      mustHave
      prompt
      updatedAt
    }
  }
`;
export type UpdateRoleRequirementsMutationFn = Apollo.MutationFunction<
  UpdateRoleRequirementsMutation,
  UpdateRoleRequirementsMutationVariables
>;

/**
 * __useUpdateRoleRequirementsMutation__
 *
 * To run a mutation, you first call `useUpdateRoleRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleRequirementsMutation, { data, loading, error }] = useUpdateRoleRequirementsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateRoleRequirementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleRequirementsMutation,
    UpdateRoleRequirementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRoleRequirementsMutation,
    UpdateRoleRequirementsMutationVariables
  >(UpdateRoleRequirementsDocument, options);
}
export type UpdateRoleRequirementsMutationHookResult = ReturnType<
  typeof useUpdateRoleRequirementsMutation
>;
export type UpdateRoleRequirementsMutationResult =
  Apollo.MutationResult<UpdateRoleRequirementsMutation>;
export type UpdateRoleRequirementsMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleRequirementsMutation,
  UpdateRoleRequirementsMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      id
      photoUrl
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UserDocument = gql`
  query User {
    getUser {
      email
      firstName
      lastName
      phoneNumber
      userConsents {
        id
      }
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(
  baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export function refetchUserQuery(variables?: UserQueryVariables) {
  return { query: UserDocument, variables: variables };
}
export const VoidSearchAgreementDocument = gql`
  mutation VoidSearchAgreement(
    $input: EmployerTerminatedSearchAgreementInput!
  ) {
    voidSearchAgreement(input: $input)
  }
`;
export type VoidSearchAgreementMutationFn = Apollo.MutationFunction<
  VoidSearchAgreementMutation,
  VoidSearchAgreementMutationVariables
>;

/**
 * __useVoidSearchAgreementMutation__
 *
 * To run a mutation, you first call `useVoidSearchAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidSearchAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidSearchAgreementMutation, { data, loading, error }] = useVoidSearchAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoidSearchAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VoidSearchAgreementMutation,
    VoidSearchAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VoidSearchAgreementMutation,
    VoidSearchAgreementMutationVariables
  >(VoidSearchAgreementDocument, options);
}
export type VoidSearchAgreementMutationHookResult = ReturnType<
  typeof useVoidSearchAgreementMutation
>;
export type VoidSearchAgreementMutationResult =
  Apollo.MutationResult<VoidSearchAgreementMutation>;
export type VoidSearchAgreementMutationOptions = Apollo.BaseMutationOptions<
  VoidSearchAgreementMutation,
  VoidSearchAgreementMutationVariables
>;
