import 'yup-phone-lite';

import * as Yup from 'yup';

const EmployerFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phoneNumber: Yup.string()
    .phone('US', 'Invalid phone number')
    .required('Phone number is required'),
  tou: Yup.boolean().required(),
});

export default EmployerFormSchema;
