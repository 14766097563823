import type {
  NotificationStructureOption,
  UserNotificationEnum,
} from '@headrace/types';

import { Checkbox } from '../../../forms';

const SettingsNotificationOption: React.VFC<{
  notification: NotificationStructureOption;
  verifyChecked: (value: string) => boolean;
  onChange?: (value: UserNotificationEnum, checked: boolean) => void;
  disabled?: boolean;
}> = ({ notification, verifyChecked, onChange, disabled }) => {
  const onChangeCallback = onChange ?? ((): void => {});
  return (
    <div className="flex h-10 items-center">
      <Checkbox
        name={notification.label}
        checked={verifyChecked(notification.value)}
        onChange={(ev): void =>
          onChangeCallback(notification.value, ev.target.checked)
        }
        disabled={disabled}
      />
    </div>
  );
};

export default SettingsNotificationOption;
