import classNames from 'classnames';

import LoadingSpinner from './LoadingSpinner';

type ButtonType = 'primary' | 'secondary' | 'link' | 'red-sm';

interface ButtonProps {
  buttonType?: ButtonType;
  className?: string;
  icon?: React.ReactNode | null;
  iconPosition?: 'leading' | 'trailing';
  onClick?:
    | React.ButtonHTMLAttributes<HTMLButtonElement>['onClick']
    | (() => void);
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  title?: string;
  loading?: boolean;
  size?: 'sm' | 'md';
  width?: string;
  childrenClassName?: string;
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    buttonType = 'primary',
    children,
    className = '',
    icon = null,
    iconPosition = 'leading',
    onClick,
    type = 'button',
    disabled = false,
    title = '',
    loading = false,
    size = 'md',
    width = 'sm:w-auto sm:h-auto',
    childrenClassName = '',
  } = props;

  const classes = classNames(
    {
      'inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium shadow-sm  active:outline-none outline-none':
        buttonType !== 'link',
      'sm:px-4 sm:py-2': size === 'md' && buttonType !== 'link',
      'sm:px-3 sm:py-1': size === 'sm' && buttonType !== 'link',
      'text-gray-400  cursor-not-allowed': disabled,
      'bg-headraceYellow-700/50': buttonType === 'primary' && disabled,
      'text-headraceBlack-700 bg-headraceYellow-700 hover:bg-headraceYellow-800':
        buttonType === 'primary' && !disabled,
      'text-gray-700 bg-white border-gray-300 hover:bg-gray-50':
        buttonType === 'secondary' && !disabled,
      'flex items-center': icon,
      'font-medium text-blue-500 hover:text-blue-600 mx-6':
        buttonType === 'link',
      'bg-transparent hover:bg-transparent opacity-50':
        buttonType === 'link' && disabled,
      'active:ring-red-700 bg-red-600 hover:bg-red-700 text-white':
        buttonType === 'red-sm',
      'bg-red-300 text-red-700 cursor-not-allowed':
        disabled && buttonType === 'red-sm',
    },
    width,
    className
  );

  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      // button type will be set always but eslint is not able to recognize that
      // type is non nullable
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classes}
      title={title}
    >
      <div className={classNames('flex items-center gap-2', childrenClassName)}>
        {loading && <LoadingSpinner svgClassName="text-white !w-4 !h-4" />}
        {icon && iconPosition === 'leading' && icon}
        {children}
        {icon && iconPosition === 'trailing' && icon}
      </div>
    </button>
  );
};

export default Button;
