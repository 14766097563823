import 'yup-phone-lite';

import { MailIcon } from '@heroicons/react/solid';
import { Form, Formik } from 'formik';
import type { VFC } from 'react';
import * as Yup from 'yup';

import Button from '../../Button';
import { Input, PhoneInput, SubmitButton } from '../../forms';
import Modal from '../../Modal';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: InviteModalFormI) => Promise<void>;
  loading: boolean;
  requiredPhoneNumber?: boolean;
}

export interface InviteModalFormI {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

const InviteMemberModal: VFC<Props> = ({
  open,
  onClose,
  onSubmit,
  loading,
  requiredPhoneNumber = false,
}) => {
  const phoneSchema = Yup.string().phone('US', 'Invalid phone number');
  const formValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phoneNumber: requiredPhoneNumber
      ? phoneSchema.required('Phone number is required')
      : Yup.string()
          .transform((value: string) => (value.length > 2 ? value : ''))
          .test('test-phone', 'Invalid phone number', (value) => {
            if (value && value.length > 2)
              return phoneSchema.isValidSync(value);
            return true;
          }),
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Add team member"
      subtitle={`Please enter your team member's details`}
    >
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
        }}
        onSubmit={async (values): Promise<void> => {
          const newValues = {
            ...values,
            phoneNumber:
              values.phoneNumber.length > 2 ? values.phoneNumber : '',
          };
          await onSubmit(newValues);
        }}
        validationSchema={formValidationSchema}
      >
        <Form>
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-3 ">
              <Input
                name="firstName"
                label="First Name"
                autoComplete="first-name"
                boxClassName="text-left"
              />
            </div>
            <div className="col-span-3 ">
              <Input
                name="lastName"
                label="Last Name"
                autoComplete="last-name"
                boxClassName="text-left"
              />
            </div>
            <div className="col-span-3 ">
              <Input
                name="email"
                label="Email"
                autoComplete="email"
                boxClassName="text-left"
                icon={
                  <MailIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                }
              />
            </div>
            <div className="col-span-3 ">
              <PhoneInput
                label="Phone number"
                labelClassName="text-left"
                name="phoneNumber"
              />
            </div>
          </div>
          <div className="flex justify-end mt-4 gap-3">
            <Button buttonType="secondary" onClick={onClose}>
              Cancel
            </Button>
            <SubmitButton loading={loading} disabled={loading}>
              Send invite
            </SubmitButton>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default InviteMemberModal;
