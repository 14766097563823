const FilterActive: React.VFC<{ className: string }> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.39999 2.40001C2.39999 1.95818 2.75817 1.60001 3.19999 1.60001H12.8C13.2418 1.60001 13.6 1.95818 13.6 2.40001V4.80001C13.6 5.01218 13.5157 5.21566 13.3657 5.36569L9.59999 9.13138V12C9.59999 12.2122 9.51571 12.4157 9.36568 12.5657L7.76568 14.1657C7.53688 14.3945 7.19279 14.4629 6.89385 14.3391C6.59491 14.2153 6.39999 13.9236 6.39999 13.6V9.13138L2.63431 5.36569C2.48428 5.21566 2.39999 5.01218 2.39999 4.80001V2.40001Z"
      fill="#30323D"
    />
  </svg>
);

export default FilterActive;
