import type { VFC } from 'react';

interface Props {
  className?: string;
}

const GlobalIcon: VFC<Props> = ({ className }) => (
  <span className={className}>
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.03662 6.33333H2.33333C3.06971 6.33333 3.66667 6.93029 3.66667 7.66667V8.33333C3.66667 9.06971 4.26362 9.66667 5 9.66667C5.73638 9.66667 6.33333 10.2636 6.33333 11V12.9634M4.33333 1.62368V2.66667C4.33333 3.58714 5.07953 4.33333 6 4.33333H6.33333C7.06971 4.33333 7.66667 4.93029 7.66667 5.66667C7.66667 6.40305 8.26362 7 9 7C9.73638 7 10.3333 6.40305 10.3333 5.66667C10.3333 4.93029 10.9303 4.33333 11.6667 4.33333L12.3763 4.33333M9 12.6586V11C9 10.2636 9.59695 9.66667 10.3333 9.66667H12.3763M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
        stroke="#9CA3AF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default GlobalIcon;
