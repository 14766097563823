import React from 'react';

import LoadingSpinner from './LoadingSpinner';

const PageLoader: React.VFC = () => (
  <div className="h-screen w-full">
    <LoadingSpinner className="h-full" svgClassName="md:w-28 md:h-28" />
  </div>
);

export default PageLoader;
