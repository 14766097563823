import { FeeScheduleTypeEnum } from '@headrace/types';

import currencyFormatter from './currencyFormatter';

const formatBounty = (
  bounty: number,
  bountyType: FeeScheduleTypeEnum
): string => {
  if (bountyType === FeeScheduleTypeEnum.CASH_FIXED) {
    return currencyFormatter().format(bounty);
  }
  if (bountyType === FeeScheduleTypeEnum.CASH_PERCENT) {
    return `${bounty}%`;
  }
  return '';
};

export default formatBounty;
