import { useFormikContext } from 'formik';
import type React from 'react';
import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';

import toastMessage from '../../../NotificationMessage';

type UseFocusOnerrorProps = (
  fieldRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
  name: string
) => void;

const useFocusOnError: UseFocusOnerrorProps = (fieldRef, name) => {
  const formik = useFormikContext();
  const prevSubmitCountRef = useRef<number>();
  const firstErrorKey = Object.keys(formik.errors)[0];
  useEffect(() => {
    if (!formik.isValid && formik.dirty) {
      if (fieldRef.current && firstErrorKey === name) {
        if (formik.submitCount !== 0)
          fieldRef.current.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
        if (prevSubmitCountRef.current !== formik.submitCount) {
          fieldRef.current.focus({ preventScroll: true });
          toast.error(
            toastMessage({
              title: 'Form submission error',
              subtitle: 'There are invalid form fields that need review',
            })
          );
        }
      }
      formik.validateForm().finally(() => {}); // Handle post filled input data
    }
    prevSubmitCountRef.current = formik.submitCount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.submitCount, formik.isValid, firstErrorKey]);
};

export default useFocusOnError;
