import type { CardRecruiterDataType } from '@headrace/types';
import { SearchPreferenceEnum } from '@headrace/types';
import { numberFormatter } from '@headrace/utils';
import {
  DocumentTextIcon,
  StarIcon,
  TagIcon,
  UserIcon as OutlineUserIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import type { MouseEvent } from 'react';
import { useMemo } from 'react';

import Button from '../Button';
import { TopMatchIcon } from '../CustomIcons';
import SummaryCard from '../SummaryCard';

interface InvitesAction {
  action: (recruiterId: string) => void;
}

interface ContactDiscoveryCardProps {
  className?: string;
  recruiterData: CardRecruiterDataType;
  onClick?: (id: string) => void | Promise<void>;
  roleFunction?: { id: string; name: string };
  screenWidth: number;
  recruiterSelected?: (dataRecruiter: CardRecruiterDataType) => void;
  invitesActions?: {
    inviteMarketplace: InvitesAction;
    inviteDirect: InvitesAction;
  };
  roleType: {
    isInvite: boolean;
    isDirect: boolean;
  };
  rolePaused?: boolean;
}

const ContactDiscoveryCard: React.VFC<ContactDiscoveryCardProps> = (props) => {
  const {
    className,
    recruiterData,
    onClick,
    roleFunction,
    screenWidth,
    recruiterSelected,
    invitesActions,
    roleType,
    rolePaused,
  } = props;

  const agencyText = useMemo(
    () => recruiterData.recruiter.agency.name || 'Unknown agency',
    [recruiterData]
  );

  const locationText = useMemo(
    () =>
      recruiterData.recruiter.location
        ? ` | ${
            recruiterData.recruiter.location.cityName
              ? `${recruiterData.recruiter.location.cityName}, `
              : ''
          }${recruiterData.recruiter.location.stateAbbrev || ''}`
        : '',
    [recruiterData]
  );

  const inviteButton = useMemo(() => {
    const onClickEvent = (evt: MouseEvent<HTMLButtonElement>): void => {
      evt.preventDefault();
      evt.stopPropagation();
    };
    if (roleType.isInvite) {
      if (recruiterData.isInvitedToMarketplaceRole) {
        return (
          <Button className="!w-40" disabled>
            Invite sent
          </Button>
        );
      }
      return (
        <Button
          className="!w-40"
          onClick={(e): void => {
            onClickEvent(e);
            if (recruiterSelected && invitesActions) {
              recruiterSelected(recruiterData);
              invitesActions.inviteMarketplace.action(
                recruiterData.recruiter.id
              );
            }
          }}
          disabled={rolePaused}
        >
          Invite to role
        </Button>
      );
    }
    if (roleType.isDirect) {
      if (recruiterData.isRequestedToDirect) {
        return (
          <Button className="!w-40" disabled>
            Request sent
          </Button>
        );
      }
      return (
        <Button
          key={`${recruiterData.recruiter.id}_button`}
          className="!w-40"
          onClick={(e): void => {
            onClickEvent(e);
            if (recruiterSelected && invitesActions) {
              recruiterSelected(recruiterData);
              invitesActions.inviteDirect.action(recruiterData.recruiter.id);
            }
          }}
        >
          Request a meeting
        </Button>
      );
    }
    return null;
  }, [
    invitesActions,
    recruiterData,
    recruiterSelected,
    rolePaused,
    roleType.isDirect,
    roleType.isInvite,
  ]);

  const searchPreference = useMemo(() => {
    switch (recruiterData.recruiter.searchPreference) {
      case SearchPreferenceEnum.RETAINED:
        return 'Retained';
      case SearchPreferenceEnum.CONTINGENT:
        return 'Contingent';
      default:
        return 'Contingent | Retained';
    }
  }, [recruiterData.recruiter.searchPreference]);

  return (
    <SummaryCard
      className={classNames(className)}
      onClick={async (): Promise<void> => {
        if (onClick) await onClick(recruiterData.id);
      }}
      photo={{
        photoUrl: recruiterData.recruiter.user.photoUrl,
        showEmptyPhoto: true,
        emptyUserPhoto: true,
        circleShape: true,
        containerClassName: '!shadow-none',
      }}
      title={{
        titleContent: recruiterData.recruiter.user.name,
        titleContainerClassName:
          'justify-center md:justify-start lg:justify-center xl:justify-start',
        titleBadge: recruiterData.isRecommended ? (
          <>
            <TopMatchIcon />
            Top match
          </>
        ) : undefined,
      }}
      subtitle={{
        subtitleContent: (
          <>
            <p
              className="font-normal text-base text-gray-500 xl:max-w-[40ch] xl:truncate"
              title={recruiterData.recruiter.title ?? ''}
            >
              {recruiterData.recruiter.title ?? ''}
            </p>
            <div className="w-[30px] border-solid border-b-2 border-headraceYellow-700 self-center md:self-auto lg:self-center xl:self-auto" />
            <p
              className="font-medium text-base text-gray-500 xl:max-w-[40ch] xl:truncate"
              title={agencyText + locationText}
            >
              {agencyText + locationText}
            </p>
          </>
        ),
        subtitleBadges:
          recruiterData.recruiter.roleFunctions
            ?.map((rf) => rf.name)
            .splice(0, 3) ?? [],
        subtitleBadgesClassName:
          'max-w-[50ch] justify-center md:justify-start lg:justify-center xl:justify-start',
      }}
      rightContent={inviteButton}
      rightContentClassName="h-fit w-40 ml-0 md:ml-auto lg:ml-0 xl:ml-auto whitespace-nowrap"
      headerClassName="flex-wrap flex-col md:flex-row lg:flex-col xl:flex-row justify-center md:justify-start lg:justify-center xl:justify-start items-center md:items-start lg:items-center xl:items-start"
      headerContentClassName="lg:flex-col xl:flex-row"
      innerContainerClassName="cursor-pointer px-10 pb-10 pt-2 md:pt-10 lg:pt-2 xl:pt-10"
      content={recruiterData.recruiter.bio ?? ''}
      screenWidth={screenWidth}
      truncateProps={{ breakPoint: 768, maxLines: 2, responsiveLines: 7 }}
      facts={{
        factItems: [
          {
            icon: (
              <DocumentTextIcon className="text-headraceYellow-700 w-6 flex-shrink-0" />
            ),
            title: 'Search preference',
            content: searchPreference,
          },
          {
            icon: (
              <TagIcon className="text-headraceYellow-700 w-6 flex-shrink-0" />
            ),
            title: 'Placement fee',
            content: `${recruiterData.recruiter.minPricePercent ?? 'x'}–${
              recruiterData.recruiter.maxPricePercent ?? 'x'
            }%`,
          },
          {
            icon: (
              <StarIcon className="text-headraceYellow-700 w-6 flex-shrink-0" />
            ),
            title: 'Placements in function',
            content: roleFunction
              ? `${numberFormatter({
                  compactDisplay: 'short',
                  notation: 'compact',
                }).format(recruiterData.placementsByFunctionCount ?? 0)} in ${
                  roleFunction.name
                }`
              : '-',
          },
          {
            icon: (
              <OutlineUserIcon className="text-headraceYellow-700 w-6 flex-shrink-0" />
            ),
            title: 'Most placed seniority',
            content: recruiterData.topRoleSeniority?.name || '-',
          },
        ],
      }}
    />
  );
};

export default ContactDiscoveryCard;
