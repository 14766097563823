import React from 'react';

interface ToastMessage {
  (props: { title: string; subtitle: React.ReactNode }): JSX.Element;
}

const toastMessage: ToastMessage = ({ title, subtitle }) => (
  <div className="w-full">
    <div className="font-bold">{title}</div>
    <div className="font-normal text-gray-500">{subtitle}</div>
  </div>
);

export default toastMessage;
