import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { SimpleLayout } from '@headrace/ui';
import { formatApolloError } from '@headrace/utils';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { useSenEmailToGetAccessLazyQuery } from '@/graphql/generated';
import { ampli } from '@/lib/ampli';

interface Navigation {
  label: string;
  link: string;
}

const WrappedLayout: React.FC = (props) => {
  const { children } = props;
  const { asPath, query } = useRouter();
  const [successful, setSuccessful] = useState(false);
  const [navigation, setNavigation] = useState<Navigation[]>([]);
  const [showLoginAndGetAccessButton, setShowLoginAndGetAccessButton] =
    useState(true);
  const [showHamburger, setShowHamburger] = useState(true);

  const [senGetAccessEmail, { loading }] = useSenEmailToGetAccessLazyQuery({
    onCompleted: () => {
      setSuccessful(true);
    },
    onError(error) {
      toast.error(formatApolloError(error));
    },
  });

  const recruiterId = useMemo(() => {
    if (Array.isArray(query.recruiterId)) return query.recruiterId[0];
    return query.recruiterId ?? '';
  }, [query.recruiterId]);

  useEffect(() => {
    if (asPath.includes('dossiers')) {
      // AIRole aren't going to have this header
      setNavigation([]);
      setShowLoginAndGetAccessButton(false);
      setShowHamburger(false);
    } else {
      setNavigation([
        {
          label: 'Home',
          link: 'https://headrace.com',
        },
        {
          label: 'For recruiters',
          link: 'https://headrace.com/for-recruiters',
        },
        {
          label: 'For employers',
          link: 'https://headrace.com/for-employers',
        },
        {
          label: 'Content',
          link: 'https://headrace.com/content',
        },
      ]);
    }
  }, [asPath]);

  const loginPath = useMemo(() => {
    if (asPath.includes('recruiter'))
      return asPath.replace('recruiters', 'profiles');
    return asPath;
  }, [asPath]);

  const handleGetAccessClick = (): void => {
    ampli.publicProfileGetAccessClicked({
      app: 'Employer',
      'recruiter id': recruiterId,
    });
  };

  const handleLoginlClick = (): void => {
    ampli.publicProfileLoginClicked({
      app: 'Employer',
      'recruiter id': recruiterId,
    });
  };

  const handleSubmitGetAccess = (): void => {
    ampli.publicProfileSubmitGetAccessFormClicked({
      app: 'Employer',
      'recruiter id': recruiterId,
    });
  };

  return (
    <SimpleLayout
      navigation={navigation}
      loginPathEmployer={loginPath}
      accessAction={async (values): Promise<void> => {
        await senGetAccessEmail({
          variables: { input: { ...values, id: recruiterId } },
        });
        handleSubmitGetAccess();
      }}
      onCloseGetAccess={(): void => {
        setSuccessful(false);
      }}
      sendAccesEmailState={{
        loading,
        successful,
      }}
      handleEvents={{
        onGetAccessClicked: handleGetAccessClick,
        onLoginClicked: handleLoginlClick,
      }}
      showLoginAndGetAccessButton={showLoginAndGetAccessButton}
      showHamburger={showHamburger}
    >
      {children}
    </SimpleLayout>
  );
};

const PublicLayout: React.FC = (props) => {
  const { children } = props;

  const api = process.env.NEXT_PUBLIC_HEADRACE_API_URI ?? '';

  const httpLink = createHttpLink({ uri: `${api}/graphql/employer` });

  const apolloClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache({
      addTypename: false,
    }),
    connectToDevTools: true,
  });

  return (
    <ApolloProvider client={apolloClient}>
      <WrappedLayout>{children}</WrappedLayout>
    </ApolloProvider>
  );
};

export default PublicLayout;
