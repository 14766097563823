import type { VFC } from 'react';

interface TitleWithButtonsProps {
  title: string;
  buttons: React.ReactNode;
}

const TitleWithButtons: VFC<TitleWithButtonsProps> = (props) => {
  const { title, buttons } = props;
  return (
    <div className="flex justify-between">
      <div className="px-6 mt-5 sm:px-6 w-full">
        <div className="relative flex py-5 items-center justify-between md:justify-items-center flex-wrap">
          <h3 className="flex-shrink mr-4 ml-1 md:ml-4 text-headraceBlack-800 text-lg font-medium w-4/6 md:w-fit">
            {title}
          </h3>
          <div className="flex-grow border-t border-headRaceBlack-700 hidden md:block" />
          <div className="md:absolute right-0 border rounded-md w-fit">
            {buttons}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleWithButtons;
