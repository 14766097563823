import type { ScriptableContext } from 'chart.js';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import ChartAnnotations from 'chartjs-plugin-annotation';
import classNames from 'classnames';
import type { ReactElement, VFC } from 'react';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

interface TriangleChartProps {
  value?: number;
  data: number[];
  labels: string[];
  title?: string;
  subtitle?: string;
  footer?: string;
}

const TriangleChart: VFC<TriangleChartProps> = ({
  value,
  data: values,
  labels,
  title,
  subtitle,
  footer,
}): ReactElement => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    ChartAnnotations
  );

  const getContent = useMemo(() => {
    const data = {
      labels: values,
      datasets: [
        {
          fill: true,
          label: 'Dataset 1',
          data: values,
          borderColor: 'rgba(53, 162, 235, 0.0)',
          backgroundColor: (
            context: ScriptableContext<'line'>
          ): CanvasGradient => {
            const { ctx } = context.chart;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, '#3FC8A9');
            gradient.addColorStop(1, '#A7F3D0');

            return gradient;
          },
        },
      ],
    };

    return (
      <div
        className={classNames(
          'flex flex-col justify-center content-center h-full w-full'
        )}
      >
        {title && (
          <div
            className={classNames(
              'text-center text-gray-400 text-sm font-medium',
              {
                '!text-gray-500': !value,
              }
            )}
          >
            {title}
          </div>
        )}
        {subtitle && (
          <div className="text-center text-gray-400 text-[0.625rem] font-base flex justify-center items-center">
            <div className="w-40">{subtitle}</div>
          </div>
        )}
        <div className={classNames('w-full')}>
          <Line
            options={{
              elements: {
                point: {
                  radius: 0,
                },
              },
              responsive: true,
              layout: {
                padding: {
                  top: 30,
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  display: false,
                  max: 20,
                  min: -5,
                },
                x: {
                  display: false,
                  position: {
                    y: 0,
                  },
                  grid: {
                    display: false,
                  },
                  ticks: {
                    stepSize: 20,
                    align: 'inner',
                    color: 'rgba(48, 50, 61, 1)',
                    font: {
                      size: 14,
                      weight: 'bold',
                    },
                    padding: 0,
                    backdropPadding: 0,
                  },
                },
              },

              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
                datalabels: {
                  display: false,
                },
                annotation: {
                  clip: false,
                  common: {
                    drawTime: 'afterDraw',
                  },
                  annotations: {
                    annotation: {
                      borderDashOffset: 5,
                      type: 'line',
                      borderColor: 'black',
                      borderWidth: 1,
                      display: !!value,
                      label: {
                        display: false,
                        content: 'Now',
                        position: 'end',
                      },
                      scaleID: 'x',
                      value: value || 0,
                    },
                  },
                },
              },
              maintainAspectRatio: false,
            }}
            data={data}
            plugins={[ChartAnnotations]}
          />
        </div>
        <div
          className={classNames(
            'flex justify-between -mt-7 text-gray-500 font-normal text-base',
            {
              '!-mt-6': !value,
            }
          )}
        >
          {labels.map((label) => (
            <span key={label}>{label}</span>
          ))}
        </div>
      </div>
    );
  }, [labels, subtitle, title, value, values]);

  return (
    <div className="w-full flex items-center flex-col  h-full">
      {getContent}
      {footer && (
        <div className="text-center text-gray-400 text-sm font-medium mt-2">
          {footer}
        </div>
      )}
    </div>
  );
};

export default TriangleChart;
