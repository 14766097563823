import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Link from 'next/link';
import type { FC } from 'react';
import React, { Fragment, useMemo, useRef } from 'react';

import Button from './Button';

export interface ModalButtonItem {
  type: 'primary' | 'secondary';
  label: string;
  action: string | (() => void) | (() => Promise<void>);
  hidden?: boolean;
  loading?: boolean;
  className?: string;
}

interface ModalProps {
  open: boolean;
  onClose?: () => void;
  title: string;
  subtitle?: string | React.ReactNode;
  alignHeader?: 'left' | 'center' | 'right';
  fullWidth?: boolean;
  className?: string;
  closeIconClassName?: string;
  buttons?: ModalButtonItem[];
  showXicon?: boolean;
}

const Modal: FC<ModalProps> = ({
  open,
  onClose = (): void => {},
  children,
  title,
  subtitle,
  alignHeader = 'left',
  fullWidth = false,
  className,
  closeIconClassName = 'text-red-500 w-6',
  buttons,
  showXicon = true,
}) => {
  const initialFocusRef = useRef(null);

  const classNameHeader = classNames({
    'text-left': alignHeader === 'left',
    'text-center': alignHeader === 'center',
    'text-right': alignHeader === 'right',
  });

  const bodyClassName = classNames(
    'inline-block align-bottom bg-white rounded-lg p-6  text-left overflow-visible shadow-xl transform transition-all sm:align-middle sm:max-w-[512px] sm:min-w-[300px] min-w-[300px]',
    {
      'w-full': fullWidth,
      'sm:w-5/12': !fullWidth,
    },
    className
  );

  const actionButtons = useMemo(() => {
    if (buttons?.length) {
      const items: {
        extras: React.ReactNode[];
        buttons: React.ReactNode[];
      } = { buttons: [], extras: [] };
      items.buttons = buttons?.map((item) => {
        if (
          (item.type === 'primary' || item.type === 'secondary') &&
          !item.hidden
        ) {
          if (typeof item.action === 'function') {
            const buttonClassName = classNames(
              'mx-0 px-3 py-1',
              item.className
            );
            return (
              <Button
                key={item.label}
                buttonType={item.type}
                onClick={item.action}
                loading={item.loading}
                className={buttonClassName}
              >
                {item.label}
              </Button>
            );
          }
          return (
            <Link key={item.label} href={item.action}>
              <a className="text-gray-500 hover:text-gray-700">
                <Button buttonType={item.type} className="mx-0 px-3 py-1">
                  {item.label}
                </Button>
              </a>
            </Link>
          );
        }
        return null;
      });
      return items;
    }
    return null;
  }, [buttons]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={classNames('fixed z-30 inset-0 overflow-y-auto')}
        onClose={onClose}
        initialFocus={initialFocusRef}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={bodyClassName}>
              <div>
                <div className={classNameHeader} ref={initialFocusRef}>
                  <div className="flex justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    {showXicon && (
                      <button type="button" onClick={onClose}>
                        <XIcon className={closeIconClassName} />
                      </button>
                    )}
                  </div>
                  <div className="w-8 border-t-2 border-headraceYellow-700 my-2" />
                  {subtitle && (
                    <div className="">
                      <div className="text-sm text-gray-500">{subtitle}</div>
                    </div>
                  )}
                </div>
                <div className="mt-4">{children}</div>
              </div>
              {actionButtons?.buttons && (
                <div className="mt-5 sm:mt-4 gap-3 flex flex-row-reverse">
                  {actionButtons.buttons}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
