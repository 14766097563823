import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useCookies } from 'react-cookie';

import isAdmin from './isAdmin';

type AuthorizedApolloProviderProps = {
  uri: string;
};

const AuthorizedApolloProvider: React.FC<AuthorizedApolloProviderProps> = (
  props
) => {
  const [cookies] = useCookies(['become-user-id']);
  const becomeUserId = cookies['become-user-id'] as string;

  const { children, uri } = props;

  const { getAccessTokenSilently, user } = useAuth0();

  const httpLink = createHttpLink({ uri });

  const authLink = setContext(
    async (_, { headers }: { headers: Record<string, string> }) => {
      const accessToken = await getAccessTokenSilently();

      return {
        headers: {
          ...headers,
          authorization: accessToken ? `Bearer ${accessToken}` : '',
          ...(becomeUserId && isAdmin(user)
            ? { 'headrace-become-user-id': becomeUserId }
            : {}),
        },
      };
    }
  );

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    connectToDevTools: true,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
