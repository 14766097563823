import type { NavigationItem } from '@headrace/ui';
import { CandidatesIcon } from '@headrace/ui';
import {
  DocumentTextIcon,
  HomeIcon,
  PencilAltIcon,
  UsersIcon,
} from '@heroicons/react/outline';

import { candidatesNavigation } from '@/components/CandidatesLayout';
import { settingsNavigation } from '@/components/Settings/MainLayout';
import type { CurrentEmployeeQuery } from '@/graphql/generated';

// eslint-disable-next-line import/prefer-default-export
export const navigation = (
  employer?: CurrentEmployeeQuery['currentEmployee'],
  roles: CurrentEmployeeQuery['currentEmployee']['roles'] = []
): NavigationItem[] => {
  let mainNavigation: NavigationItem[] = [
    {
      name: 'Dashboard',
      href: '/',
      icon: HomeIcon,
      showNavigation: true,
    },
    {
      name: 'Roles',
      title: 'Roles',
      href: '/roles',
      icon: UsersIcon,
      showNavigation: true,
    },
    {
      name: 'Role details',
      href: '/roles/[roleId]',
      backArrow: '/roles',
      children: [
        '/roles/[roleId]/recruiters',
        '/roles/[roleId]/dossier',
        '/roles/[roleId]/sourcing',
      ],
    },
    {
      name: 'Roles',
      title: 'Candidates',
      href: '/roles/[roleId]/candidates/[candidateId]',
      backArrow: true,
    },
    {
      name: 'Request a search',
      title: 'Request a search',
      href: '/roles/create',
    },
    {
      name: 'Searches',
      href: '/searches',
      icon: DocumentTextIcon,
      showNavigation:
        employer?.showSearches != null ? employer?.showSearches : true,
    },
    {
      name: 'Search agreement details',
      title: 'Search agreement details',
      href: '/searches/[searchId]',
      icon: DocumentTextIcon,
      yellowStripe: true,
    },
    {
      name: 'Recruiter Profile',
      title: 'Recruiter Profile',
      href: '/profiles/[recruiterId]',
      icon: DocumentTextIcon,
      backArrow: true,
    },
    {
      name: 'Find a recruiter',
      title: 'Find a recruiter',
      href: '/roles/[roleId]/find',
      backArrow: true,
    },
    {
      name: 'Candidates',
      title: 'Candidates',
      href: '/candidates',
      icon: CandidatesIcon,
      showNavigation: true,
    },
  ];

  if (employer?.employer.isHeadRaceAI) {
    mainNavigation = [
      {
        name: 'Roles',
        title: 'Roles',
        href: '/roles',
        icon: UsersIcon,
        showNavigation: roles.length > 0,
        subNavigation: roles.map((role) => ({
          name: role.name,
          href: `/roles/${role.id}`,
        })),
        alwaysOpen: true,
        fullHeight: true,
      },
      {
        name: 'Role details',
        href: '/roles/[roleId]',
        children: [
          '/roles/[roleId]/prospecting',
          '/roles/[roleId]/outreach',
          '/roles/[roleId]/candidate-review',
        ],
      },
      {
        name: 'Create role',
        href: '/roles/create',
        icon: PencilAltIcon,
        showNavigation: true,
      },
    ];
  }

  const navigationItems: NavigationItem[] = [
    ...mainNavigation,
    ...candidatesNavigation,
    ...settingsNavigation,
  ];

  return navigationItems;
};
