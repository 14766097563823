const phoneNumberFormatter = (phoneNumber?: string | null): string => {
  if (!phoneNumber) return '';
  const cleaned = phoneNumber.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1}|\d{2}|\d{3})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return [
      match[1] ? `+${match[1]} ` : '',
      '(',
      match[2],
      ') ',
      match[3],
      '-',
      match[4],
    ].join('');
  }
  return '';
};

export default phoneNumberFormatter;
