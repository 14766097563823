import type { ApolloError } from '@apollo/client';
import { useHeaderTabs } from '@headrace/ui';
import { removeFromStorage } from '@headrace/utils';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo, useState } from 'react';

import {
  type EmployerAiRoleByIdQuery,
  useEmployerAiRoleByIdQuery,
} from '@/graphql/generated';

export interface RoleAIDetailsContextData {
  roleData: {
    data?: EmployerAiRoleByIdQuery['employerAIRoleById'];
    loading: boolean;
    error?: ApolloError;
  };
  refetchRole: () => Promise<void>;
  handleSetPreviousRoleId: (oldRoleId: string) => void;
  previousRoleId: string;
}

interface RoleAIDetailsProviderProps {
  roleId: string;
}

const RoleAIDetailsContext = React.createContext<RoleAIDetailsContextData>({
  refetchRole: () => Promise.resolve(),
  roleData: {
    loading: false,
  },
  handleSetPreviousRoleId: () => {},
  previousRoleId: '',
});

const RoleAIDetailsProvider: React.FC<RoleAIDetailsProviderProps> = ({
  children,
  roleId,
}) => {
  const { replace: routerReplace } = useRouter();
  const { tabs, setTabs } = useHeaderTabs();
  const [previousRoleId, setPreviousRoleId] = useState('');

  const handleSetPreviousRoleId = (oldRoleId: string): void => {
    setPreviousRoleId(oldRoleId);
  };

  const { data, loading, error, refetch } = useEmployerAiRoleByIdQuery({
    variables: { id: roleId },
    onCompleted: () => {
      if (data?.employerAIRoleById.isRoleAlreadyLunched) {
        setTabs(tabs.map((tab) => ({ ...tab, disabled: false })));
      } else {
        setTabs(
          tabs.map((tab) => {
            if (tab.name !== 'Setup + Configuration') {
              return { ...tab, disabled: true };
            }
            return tab;
          })
        );
        setTabs([
          {
            name: 'Setup + Configuration',
            href: `/roles/${roleId}`,
            disabled: false,
          },
          {
            name: 'Prospecting',
            href: `/roles/${roleId}/prospecting`,
            disabled: true,
          },
          {
            name: 'Outreach',
            href: `/roles/${roleId}/outreach`,
            disabled: true,
          },
          {
            name: 'Candidate review',
            href: `/roles/${roleId}/candidate-review`,
            disabled: true,
          },
        ]);
      }
    },
    onError: () => {
      if (!data) {
        removeFromStorage('lastRoleIdViewed');
        routerReplace('/roles').finally(() => {});
      }
    },
  });

  const refetchRole = useCallback(async (): Promise<void> => {
    await refetch();
  }, [refetch]);

  const roleAIDetailsContextState: RoleAIDetailsContextData = useMemo(
    () => ({
      refetchRole,
      roleData: {
        data: data?.employerAIRoleById,
        loading,
        error,
      },
      handleSetPreviousRoleId,
      previousRoleId,
    }),
    [refetchRole, data?.employerAIRoleById, loading, error, previousRoleId]
  );

  return (
    <RoleAIDetailsContext.Provider value={roleAIDetailsContextState}>
      {children}
    </RoleAIDetailsContext.Provider>
  );
};

export const useRoleAIDetails = (): RoleAIDetailsContextData =>
  React.useContext(RoleAIDetailsContext);

export default RoleAIDetailsProvider;
