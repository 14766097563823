import { lt } from 'semver';

const checkApiVersion = async ({
  getApiVersion,
  frontendVersion,
  reload,
}: {
  getApiVersion: () => Promise<string>;
  frontendVersion: string;
  reload: () => void;
}): Promise<void> => {
  // Check the last time we checked for a new version
  const lastChecked = localStorage.getItem('lastChecked');
  const now = new Date().getTime();
  const lastCheckedTime = lastChecked ? new Date(lastChecked).getTime() : 0;
  const timeSinceLastCheck = now - lastCheckedTime;
  // If it's been more than 10 minutes, check again
  if (timeSinceLastCheck > 10 * 60 * 1000) {
    localStorage.setItem('lastChecked', new Date().toISOString());
    const apiVersion = await getApiVersion();
    if (frontendVersion && apiVersion && lt(frontendVersion, apiVersion)) {
      reload();
    }
  }
};

export default checkApiVersion;
