import classNames from 'classnames';
import type { VFC } from 'react';
import { useEffect, useRef, useState } from 'react';

import Button from '../Button';
import SimpleModal from '../SimpleModal';

interface Props {
  steps: React.ReactNode[];
  open: boolean;
  onClose: () => void;
  modalClassName?: string;
  onSubmit: () => void;
  header: (currentReview: number, currentStep: number) => React.ReactNode;
  numberOfReviews?: number;
  validate: (
    currentReview: number,
    currentStep: number,
    currentIndex: number
  ) => void;
  disableNextButton?: boolean;
}

const Modal: VFC<Props> = ({
  steps,
  open,
  onClose,
  modalClassName,
  onSubmit,
  header,
  numberOfReviews = 1,
  validate,
  disableNextButton = false,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentReview, setCurrentReview] = useState(0);
  const [count, setCount] = useState(1);

  const slideRef = useRef<HTMLDivElement>(null);

  const removeAnimation = (): void => {
    slideRef?.current?.classList.remove('fade-anim');
  };

  const handleOnNextClick = (): void => {
    const currenStep = currentIndex;
    setCurrentIndex((prev) => prev + 1);
    slideRef.current?.classList.add('fade-anim');
    if (currenStep % numberOfReviews === 0) {
      setCurrentReview((prev) => prev + 1);
    }
  };
  const handleOnPrevClick = (): void => {
    const stepsLength = steps.length;
    setCurrentIndex((prev) => (prev === 0 ? stepsLength - 1 : prev - 1));
    slideRef.current?.classList.add('fade-anim');

    if (count === 0) {
      setCurrentReview((prev) => prev - 1);
    }
  };

  const handleOnClose = (): void => {
    onClose();
    setCurrentIndex(0);
    setCurrentReview(0);
    setCount(1);
  };

  useEffect(() => {
    setCount((prev) => {
      const currentStep = currentIndex - 1;
      let countStep = prev;
      if (currentStep % numberOfReviews === 0 && prev === numberOfReviews - 1) {
        countStep = 0;
      } else {
        countStep = currentStep % numberOfReviews;
      }
      validate(currentReview, countStep, currentIndex);
      return countStep;
    });
  }, [currentIndex, currentReview, numberOfReviews, validate]);

  useEffect(() => {
    slideRef.current?.addEventListener('animationend', removeAnimation);
    // eslint-disable-next-line
  }, []);

  return (
    <SimpleModal
      open={open}
      onClose={handleOnClose}
      className={classNames(
        modalClassName,
        'lg:w-[50%] xl:w-[38%] md:w-[60%] sm:w-[80%]'
      )}
    >
      <div ref={slideRef} className="w-full select-none  h-full">
        <div>{header(currentReview, currentIndex)}</div>
        <div className="aspect-w-16 aspect-h-9 h-full">
          {steps[currentIndex]}
        </div>
        <div
          className={classNames('flex  gap-4', {
            'justify-end': currentIndex === 0,
            'justify-between': currentIndex > 0,
          })}
        >
          {currentIndex > 0 ? (
            <Button onClick={handleOnPrevClick} buttonType="secondary">
              Back
            </Button>
          ) : (
            <Button onClick={handleOnClose} buttonType="secondary">
              Cancel
            </Button>
          )}
          {currentIndex < steps.length - 1 ? (
            <Button onClick={handleOnNextClick} disabled={disableNextButton}>
              Next
            </Button>
          ) : (
            <Button
              onClick={(): void => {
                onSubmit();
                handleOnClose();
              }}
              disabled={disableNextButton}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </SimpleModal>
  );
};

export default Modal;
