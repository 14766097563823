import { useRouter } from 'next/router';
import type { VFC } from 'react';
import { useCookies } from 'react-cookie';

import Button from '../Button';

interface BecomeUserHeaderProps {
  adminUserEmail?: string;
}

const BecomeUserHeader: VFC<BecomeUserHeaderProps> = ({ adminUserEmail }) => {
  const [cookies, , removeCookie] = useCookies(['become-user-id']);

  const { push, reload } = useRouter();

  const becomeUserId = cookies['become-user-id'] as string;

  if (!becomeUserId) {
    return null;
  }

  const onClick = async (): Promise<void> => {
    removeCookie('become-user-id', { path: '/' });
    await push('/');
    reload();
  };

  return (
    <div className="flex items-center justify-between p-5 bg-red-700">
      <div>Impersonating UserId: {becomeUserId}</div>
      <Button onClick={onClick}>Revert back to {adminUserEmail}</Button>
    </div>
  );
};

export default BecomeUserHeader;
