import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
  color?: string;
}

const MentalHealthIcon: VFC<Props> = ({
  className,
  width = '34',
  height = '34',
  color = '#FFA300',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1504 26.2057L12.1836 32.4024C12.2925 33.0564 11.8508 33.6744 11.1977 33.7837C10.5469 33.8921 9.92611 33.4541 9.81644 32.7977L8.78324 26.6001H3.85524C2.81161 26.6001 1.89484 25.9344 1.57281 24.9429L0.357973 21.1989C-0.236827 19.3693 0.90434 17.4336 2.78557 17.0609C4.36161 16.7505 5.93844 17.6632 6.44991 19.1877L6.82387 20.3029L9.43024 19.4586C10.0582 19.2553 10.7374 19.5997 10.9414 20.2302C11.1459 20.8605 10.8003 21.5373 10.1698 21.7414L6.43384 22.9518C5.81224 23.155 5.13541 22.8158 4.92627 22.1917L4.17471 19.9506C4.04593 19.567 3.64997 19.3382 3.25191 19.4151C2.77624 19.5095 2.49187 19.9983 2.64151 20.4575L3.85634 24.2028L8.78301 24.2002C9.96217 24.2002 10.9577 25.0438 11.1502 26.2058L11.1504 26.2057Z"
      fill={color}
    />
    <path
      d="M4.9999 15.7999C6.98507 15.7999 8.5999 14.185 8.5999 12.1999C8.5999 10.2147 6.98507 8.59985 4.9999 8.59985C3.01474 8.59985 1.3999 10.2147 1.3999 12.1999C1.3999 14.185 3.01474 15.7999 4.9999 15.7999ZM4.9999 10.9999C5.6615 10.9999 6.1999 11.5383 6.1999 12.1999C6.1999 12.8615 5.6615 13.3999 4.9999 13.3999C4.3383 13.3999 3.7999 12.8615 3.7999 12.1999C3.7999 11.5383 4.3383 10.9999 4.9999 10.9999Z"
      fill={color}
    />
    <path
      d="M30.2001 8.59985C28.2149 8.59985 26.6001 10.2147 26.6001 12.1999C26.6001 14.185 28.2149 15.7999 30.2001 15.7999C32.1853 15.7999 33.8001 14.185 33.8001 12.1999C33.8001 10.2147 32.1853 8.59985 30.2001 8.59985ZM30.2001 13.3999C29.5385 13.3999 29.0001 12.8615 29.0001 12.1999C29.0001 11.5383 29.5385 10.9999 30.2001 10.9999C30.8617 10.9999 31.4001 11.5383 31.4001 12.1999C31.4001 12.8615 30.8617 13.3999 30.2001 13.3999Z"
      fill={color}
    />
    <path
      d="M7.4002 29V32.6C7.4002 33.2628 6.86296 33.8 6.20019 33.8C5.53743 33.8 5.0002 33.2628 5.0002 32.6V30.2H2.6002V32.6C2.6002 33.2628 2.06296 33.8 1.4002 33.8C0.737429 33.8 0.200195 33.2628 0.200195 32.6V29C0.200195 28.3373 0.737429 27.8 1.4002 27.8H6.20019C6.86296 27.8 7.4002 28.3373 7.4002 29Z"
      fill={color}
    />
    <path
      d="M31.4002 29V32.6C31.4002 33.2628 30.863 33.8 30.2002 33.8C29.5374 33.8 29.0002 33.2628 29.0002 32.6V30.2H26.6002V32.6C26.6002 33.2628 26.063 33.8 25.4002 33.8C24.7374 33.8 24.2002 33.2628 24.2002 32.6V29C24.2002 28.3373 24.7374 27.8 25.4002 27.8H30.2002C30.863 27.8 31.4002 28.3373 31.4002 29Z"
      fill={color}
    />
    <path
      d="M24.2003 8.59995V2.59995C24.2003 1.27638 23.1239 0.199951 21.8003 0.199951H12.2003C10.8767 0.199951 9.80029 1.27638 9.80029 2.59995V12.2C9.80029 12.6424 10.0439 13.0492 10.4343 13.2584C10.8223 13.4664 11.2955 13.4449 11.6659 13.1988L14.9635 10.9999H21.8005C23.124 10.9999 24.2005 9.92352 24.2005 8.59995L24.2003 8.59995ZM14.6003 8.59995C14.3634 8.59995 14.1316 8.67039 13.9347 8.80152L12.2003 9.95751V2.59985H21.8003V8.59985L14.6003 8.59995Z"
      fill={color}
    />
    <path
      d="M27.7447 26.6C28.7883 26.6 29.7051 25.9343 30.0271 24.9428L31.2412 21.1998C31.8367 19.3734 30.7 17.4341 28.8136 17.0605C27.2383 16.7481 25.6612 17.6633 25.1509 19.184L23.4523 24.1997H22.8168C21.6392 24.1997 20.6436 25.0429 20.4496 26.2049L19.4164 32.4026C19.3075 33.0566 19.7492 33.6746 20.4023 33.7838C21.0543 33.8927 21.6743 33.4522 21.7836 32.7979L22.8168 26.5996H27.7448L27.7447 26.6ZM27.4255 19.9506C27.5543 19.5663 27.9498 19.3338 28.3479 19.4151C28.8222 19.5091 29.1095 19.995 28.9591 20.4579L27.7446 24.1999H25.9862L27.4255 19.9506Z"
      fill={color}
    />
  </svg>
);

export default MentalHealthIcon;
