import { MenuAlt2Icon } from '@heroicons/react/outline';
import { UserIcon } from '@heroicons/react/solid';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';

import type { HandleHeaderEvents } from '.';

const LoginModal = dynamic(() => import('./LoginModal'));

export interface SimpleNavigation {
  label: string;
  link: string;
}

interface SimpleHeaderProps {
  onOpenSidebarClick: () => void;
  showBackArrow?: boolean;
  navigation: SimpleNavigation[];
  loginPathEmployer?: string;
  loginPathRecruiter?: string;
  setOpenAccess: Dispatch<SetStateAction<boolean>>;
  handleEvents: HandleHeaderEvents;
  showLoginAndGetAccessButton?: boolean;
  showHamburger?: boolean;
}

const SimpleHeader: React.VFC<SimpleHeaderProps> = (props) => {
  const {
    onOpenSidebarClick,
    navigation,
    loginPathRecruiter,
    loginPathEmployer,
    setOpenAccess,
    handleEvents,
    showLoginAndGetAccessButton,
    showHamburger = true,
  } = props;

  const [openLogin, setOpenLogin] = useState(false);

  const closeLogin = (): void => setOpenLogin(false);

  return (
    <>
      <div className="sticky top-0 flex-shrink-0 flex sm:min-h-[4rem] bg-white shadow z-20">
        {showHamburger && (
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none xl:hidden"
            onClick={onOpenSidebarClick}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        )}
        <div className="flex items-center gap-2 justify-end sm:justify-between flex-wrap md:flex-nowrap w-full px-2 sm:px-[60px] py-3.5 uppercase text-[#63646D]  md:flex-row flex-col">
          <div className="flex-1 flex flex-wrap md:whitespace-nowrap justify-between w-full gap-2">
            <div className="flex">
              <a href="https://headrace.com">
                <Image
                  src="/headrace-logo-horizontal.svg"
                  alt="HeadRace Logo"
                  width={200}
                  height={32}
                />
              </a>
            </div>
            <div className="items-center gap-10 text-sm font-bold hidden xl:flex">
              {navigation.map((item) => (
                <a
                  key={item.label}
                  className="hover:text-gray-400"
                  href={item.link}
                >
                  {item.label}
                </a>
              ))}
            </div>

            <div className="flex items-center gap-5">
              {showLoginAndGetAccessButton && (
                <>
                  <button
                    className="flex gap-1 items-center justify-center uppercase"
                    type="button"
                    onClick={(): void => {
                      setOpenLogin(true);
                      handleEvents.onLoginClicked();
                    }}
                  >
                    <UserIcon className="w-4 text-headraceYellow-700" />
                    <span className="text-sm font-bold">Log in</span>
                  </button>
                  <button
                    className="inline-flex uppercase  items-center justify-center rounded-tl-xl border border-transparent  px-10 py-2 text-sm font-bold shadow-sm  active:outline-none sm:w-auto sm:h-auto outline-none text-headraceBlack-700 bg-headraceYellow-700 hover:bg-headraceYellow-800"
                    type="button"
                    onClick={(): void => {
                      setOpenAccess(true);
                      handleEvents.onGetAccessClicked();
                    }}
                  >
                    Get access
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <LoginModal
        open={openLogin}
        onClose={closeLogin}
        loginPathEmployer={loginPathEmployer}
        loginPathRecruiter={loginPathRecruiter}
      />
    </>
  );
};

export default SimpleHeader;
