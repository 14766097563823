import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import Card from './Card';
import LoadingSpinner from './LoadingSpinner';

interface Metric {
  metric: number;
  icon?: {
    Icon:
      | ((props: React.ComponentProps<'svg'>) => JSX.Element)
      | React.VFC<{ className: string }>;
    tooltipText?: string;
  };
  title?: string;
}

interface MetricItemProps extends Metric {
  titleCard: string;
  index: number;
}

const MetricItem: React.VFC<MetricItemProps> = ({
  metric,
  icon,
  index,
  title,
  titleCard,
}) => (
  <div className={classNames('flex flex-col w-[50%] justify-center p-2')}>
    {title && (
      <div className="text-headraceBlack-700 font-medium text-base text-center">
        {title}
      </div>
    )}
    <div className="flex justify-center">
      {icon && (
        <icon.Icon
          className="w-8 mr-4 text-gray-700 outline-none"
          data-for={`${titleCard}_${index}_tooltip`}
          data-tip={icon.tooltipText}
        />
      )}
      {metric}
      {icon?.tooltipText && (
        <ReactTooltip
          id={`${titleCard}_${index}_tooltip`}
          arrowColor="transparent"
          place="top"
          effect="solid"
          html
          offset={{ top: -13 }}
          className={classNames('bg-gray-300 w-40 !tex-sm leading-normal !p-3')}
        />
      )}
    </div>
  </div>
);

const MetricCard: React.FC<{
  title: string;
  metrics: Metric[];
  className?: string;
  loading: boolean;
  titleClassName?: string;
  metricClassName?: string;
}> = ({
  title,
  metrics,
  className,
  loading,
  titleClassName,
  metricClassName = 'font-bold text-headraceBlack-800 text-xl xl:text-2xl',
}) => (
  <Card
    className={classNames(
      'flex flex-col justify-start px-7 xl:px-10 py-6 ',
      className
    )}
  >
    {loading ? (
      <LoadingSpinner className="p-4" />
    ) : (
      <>
        <div
          className={classNames(
            'text-xl xl:text-2xl text-center font-normal',
            titleClassName
          )}
        >
          {title}
        </div>
        <div
          className={classNames(
            'flex flex-row justify-center w-full mt-4 self-center divide-x divide-headraceYellow-700',
            metricClassName
          )}
        >
          {metrics.map((metric, index) => (
            <MetricItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${title}-${metric.metric}-${index}`}
              index={index}
              title={metric.title}
              metric={metric.metric}
              icon={metric.icon}
              titleCard={title}
            />
          ))}
        </div>
      </>
    )}
  </Card>
);

export default MetricCard;
