import type { User } from '@auth0/auth0-react';

type UserWithClaims = User & {
  'https://headrace.com/roles'?: string[];
};

const isAdmin = (user?: User): boolean => {
  if (!user) {
    return false;
  }
  const roles = (<UserWithClaims>user)['https://headrace.com/roles'] || [];
  return roles.some((role) => role === 'Admin');
};

export default isAdmin;
