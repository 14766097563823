import type { OptionsProps } from '@headrace/ui/src/forms/fields/BasicSelect';

export type SettingsFormValues = Partial<Record<string, unknown>>;

export enum SettingsContentType {
  USER,
  RECRUITER,
}

export enum FieldType {
  INPUT,
  PHONE_INPUT,
  TEXT_AREA,
  SELECT,
  URL_INPUT,
  MULTI_SELECT,
  SLIDER,
  RICH_TEXT_EDITOR,
}

export interface SettingsContentProps {
  id: string;
  label: string;
  nameJSON?: string;
  labelIcon?: React.ReactNode;
  editLabel?: string;
  value: string | OptionsProps[];
  json?: string;
  disabled?: boolean;
  contentType: SettingsContentType;
  fieldType?: FieldType;
  helper?: string | JSX.Element;
  placeholder?: string;
  selectOptions?: OptionsProps[];
  oneCol?: boolean;
  hideOnEdit?: boolean;
  hide?: boolean;
  truncate?: boolean;
  validate?: (value: string) => void;
  onChangeSelect?: (value: OptionsProps[]) => void;
  validateSelect?: (values: OptionsProps[]) => boolean;
  className?: string;
  helperClassName?: string;
  showMore?: boolean;
}
