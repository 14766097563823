import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

interface CarouselProps {
  elements: JSX.Element[];
  className?: string;
  cardsPerPage?: number;
}

const Carousel: React.VFC<CarouselProps> = ({
  elements,
  className = '',
  cardsPerPage = 4,
}) => {
  const [offset, setOffset] = useState<number>(0);
  const [touchPosition, setTouchPosition] = useState<number | null>(null);
  const [pageZise, setPageZise] = useState<number>(cardsPerPage);
  const itemsToShow = elements.filter(
    (_element, index) => index >= offset && index < offset + pageZise
  );

  const [width, setWidth] = useState<number | null>(null);

  const handleWindowSizeChange = (): void => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      };
    }
    return undefined;
  }, []);

  useEffect(() => {
    if (width && width < 768) {
      setPageZise(1);
    } else if (width && width >= 768 && width < 1024) {
      setPageZise(2);
    } else if (width && width >= 1024 && width < 1280) {
      setPageZise(3);
    } else {
      setPageZise(cardsPerPage);
    }
  }, [cardsPerPage, width]);

  const indicators = elements.length / pageZise;
  const dotsArray = Array.from(Array(Math.ceil(indicators)).keys());

  const handlePrev = (): void => {
    if (offset - pageZise >= 0) {
      setOffset(offset - pageZise);
    }
  };
  const handleNext = (): void => {
    if (offset + pageZise < elements.length) {
      setOffset(offset + pageZise);
    }
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>): void => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>): void => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      handleNext();
    }

    if (diff < -5) {
      handlePrev();
    }

    setTouchPosition(null);
  };

  return (
    <div>
      <div
        className={classNames(
          'grid  gap-6 snap-x',
          {
            'sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 md:grid-cols-2':
              pageZise === 3,
            'sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  md:grid-cols-2':
              pageZise === 4,
          },
          className
        )}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {itemsToShow}
      </div>
      {elements.length > pageZise && (
        <div className="flex justify-between mt-6">
          <button type="button" onClick={handlePrev}>
            <ArrowNarrowLeftIcon
              className={classNames('w-5 h-5  stroke-headraceBlack-800', {
                '!stroke-headraceBlack-400 text-headraceBlack-400':
                  offset - pageZise < 0,
              })}
            />
          </button>
          <div className="flex gap-1">
            {dotsArray.map((item) => {
              if (item === offset / pageZise) {
                return (
                  <button type="button">
                    <div
                      key={item}
                      className="w-2 h-2 rounded-full bg-headraceYellow-700"
                    />
                  </button>
                );
              }
              return (
                <button type="button">
                  <div
                    key={item}
                    className="w-2 h-2 rounded-full bg-headraceGray-100"
                  />
                </button>
              );
            })}
          </div>
          <button type="button" onClick={handleNext}>
            <ArrowNarrowRightIcon
              className={classNames('w-5 h-5  stroke-headraceBlack-800', {
                '!stroke-headraceBlack-400 text-headraceBlack-400':
                  offset + pageZise >= elements.length,
              })}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default Carousel;
