import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import classNames from 'classnames';
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import React, { useEffect, useState } from 'react';

import {
  ArrowRedoIcon,
  ArrowUndoIcon,
  ListOlIcon,
  ListUlIcon,
  TextCenterIcon,
  TextJustifyIcon,
  TextLeftIcon,
  TextRightIcon,
  TypeBoldIcon,
  TypeItalicIcon,
  TypeUnderLineIcon,
} from '../../../../CustomIcons/RichTextEditorIcons';

const ToolbarPlugin: React.VFC = () => {
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [blockType, setBlockType] = useState('paragraph');

  const updateToolbar = React.useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }

      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
    }
  }, [editor]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        })
      ),
    [updateToolbar, editor]
  );

  const formatBulletList = (): void => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = (): void => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  return (
    <div className="absolute  bottom-0 p-2 rounded-t-md flex flex-wrap items-center gap-1 border border-grey-300 w-full bg-white border-gray-300">
      <button
        type="button"
        className="hover:bg-gray-200 transition-colors duration-100 ease-in px-2 py-1 rounded "
        onClick={(): void => {
          editor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
      >
        <ArrowUndoIcon />
      </button>
      <button
        type="button"
        className="hover:bg-gray-200 transition-colors duration-100 ease-in px-2 py-1 rounded "
        onClick={(): void => {
          editor.dispatchCommand(REDO_COMMAND, undefined);
        }}
      >
        <ArrowRedoIcon />
      </button>
      <span className="w-[1px] bg-gray-300 block h-5" />
      <button
        type="button"
        className={classNames(
          'hover:bg-gray-200 transition-colors duration-100 ease-in px-2 py-1 rounded ',
          isBold ? 'bg-gray-200' : 'bg-transparent'
        )}
        onClick={(): void => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
      >
        <TypeBoldIcon />
      </button>
      <button
        type="button"
        className={classNames(
          'hover:bg-gray-200 transition-colors duration-100 ease-in px-2 py-1 rounded ',
          isItalic ? 'bg-gray-200' : 'bg-transparent'
        )}
        onClick={(): void => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
      >
        <TypeItalicIcon />
      </button>
      <button
        type="button"
        className={classNames(
          'hover:bg-gray-200 transition-colors duration-100 ease-in px-2 py-1 rounded ',
          isUnderline ? 'bg-gray-200' : 'bg-transparent'
        )}
        onClick={(): void => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
      >
        <TypeUnderLineIcon />
      </button>
      <span className="w-[1px] bg-gray-300 block h-5" />
      <button
        type="button"
        className="hover:bg-gray-200 transition-colors duration-100 ease-in px-2 py-1 rounded "
        onClick={(): void => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
        }}
      >
        <TextLeftIcon />
      </button>
      <button
        type="button"
        className="hover:bg-gray-200 transition-colors duration-100 ease-in px-2 py-1 rounded "
        onClick={(): void => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
        }}
      >
        <TextCenterIcon />
      </button>
      <button
        type="button"
        className="hover:bg-gray-200 transition-colors duration-100 ease-in px-2 py-1 rounded "
        onClick={(): void => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
        }}
      >
        <TextRightIcon />
      </button>
      <button
        type="button"
        className="hover:bg-gray-200 transition-colors duration-100 ease-in px-2 py-1 rounded "
        onClick={(): void => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
        }}
      >
        <TextJustifyIcon />
      </button>
      <span className="w-[1px] bg-gray-300 block h-5" />
      <button
        type="button"
        className="hover:bg-gray-200 transition-colors duration-100 ease-in px-2 py-1 rounded "
        onClick={formatBulletList}
      >
        <ListUlIcon />
      </button>
      <button
        type="button"
        className="hover:bg-gray-200 transition-colors duration-100 ease-in px-2 py-1 rounded "
        onClick={formatNumberedList}
      >
        <ListOlIcon />
      </button>
    </div>
  );
};

export default ToolbarPlugin;
