import classNames from 'classnames';
import type { FC } from 'react';
import { useMemo, useState } from 'react';

import { BasicSelect } from './forms';
import type { NavigationItem } from './TailwindLayout';

export interface OptionsProps {
  label: string;
  value: string;
}

interface NavigationOption extends NavigationItem {
  helperComponent?: JSX.Element | null;
  disabled?: boolean;
  onClick: () => void;
  iconTab?: JSX.Element;
}

export interface SimpleTabsProps {
  navigationOptions: NavigationOption[];
  className?: string;
}

const SimpleTabsButtons: FC<SimpleTabsProps> = ({
  navigationOptions,
  className,
  children,
}) => {
  const [currentTab, setCurrentTab] = useState<NavigationOption>(
    navigationOptions[0]
  );

  const optionsSelect = navigationOptions
    .filter((i) => !i.disabled)
    .map((item) => ({
      value: item.name,
      label: item.name,
    }));

  const valueSelect = useMemo((): OptionsProps => {
    const tab = optionsSelect.find(
      (option) => option.label === currentTab.name
    );
    return tab || { value: '', label: '' };
  }, [currentTab, optionsSelect]);

  return (
    <div className="flex flex-col gap-6">
      <div
        className={classNames(
          'bg-white rounded-lg shadow overflow-hidden',
          className
        )}
      >
        {/* Mobile Select Tabs */}
        <div
          className={classNames('', {
            'lg:hidden': navigationOptions.length > 1,
            hidden: navigationOptions.length <= 1,
          })}
        >
          <BasicSelect
            id="selected-tab"
            value={valueSelect}
            options={optionsSelect}
            onChange={(value): void => {
              const newValue = value as OptionsProps;
              const tab =
                navigationOptions.find(
                  (option) => option.name === newValue.label
                ) || navigationOptions[0];
              setCurrentTab(tab);
              tab.onClick();
            }}
            isSearchable={false}
          />
        </div>
        {/* Desktop Navigation Tabs */}
        <div
          className={classNames(
            'flex justify-between items-center w-full border-b border-gray-200',
            {
              'hidden lg:block': navigationOptions.length > 1,
            }
          )}
        >
          <nav className="-mb-px flex w-full">
            {navigationOptions.map((tab) => (
              <button
                onClick={(): void => {
                  setCurrentTab(tab);
                  tab.onClick();
                }}
                type="button"
                key={tab.name}
                disabled={tab.disabled}
                className={classNames(
                  currentTab.name === tab.name
                    ? 'border-headraceYellow-700 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 hover:bg-gray-100',
                  'whitespace-nowrap border-b-2 font-medium text-sm py-4 outline-none w-full text-center',
                  {
                    'cursor-not-allowed': tab.disabled,
                  }
                )}
              >
                <div className="flex justify-center items-center gap-1">
                  {tab.icon}
                  {tab.name}
                  {tab.helperComponent}
                </div>
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default SimpleTabsButtons;
