import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
}

const TypeUnderLineIcon: VFC<Props> = ({
  className = 'bi bi-type-underline',
  width = '14',
  height = '14',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4999 1.75002C10.3839 1.75002 10.2726 1.79609 10.1906 1.87816C10.1085 1.96019 10.0624 2.07148 10.0624 2.18752V6.12502C10.0624 6.93726 9.7398 7.71623 9.16548 8.29058C8.59113 8.8649 7.81215 9.18755 6.99991 9.18755C6.18768 9.18755 5.4087 8.8649 4.83435 8.29058C4.26003 7.71623 3.93738 6.93725 3.93738 6.12502V2.18752C3.93738 2.03121 3.85398 1.88678 3.71863 1.80862C3.58327 1.73046 3.41648 1.73046 3.28113 1.80862C3.14577 1.88678 3.06238 2.03119 3.06238 2.18752V6.12502C3.06238 7.16932 3.47723 8.17088 4.21565 8.90927C4.95407 9.64769 5.95562 10.0625 6.9999 10.0625C8.04418 10.0625 9.04577 9.64769 9.78415 8.90927C10.5226 8.17085 10.9374 7.1693 10.9374 6.12502V2.18752C10.9374 2.07149 10.8914 1.9602 10.8093 1.87816C10.7272 1.79609 10.616 1.75002 10.4999 1.75002H10.4999Z"
      fill="#4B5563"
    />
    <path
      d="M10.5 11.375H3.50002C3.34371 11.375 3.19928 11.4584 3.12112 11.5938C3.04296 11.7291 3.04296 11.8959 3.12112 12.0312C3.19928 12.1666 3.34369 12.25 3.50002 12.25H10.5C10.6563 12.25 10.8007 12.1666 10.8789 12.0312C10.9571 11.8959 10.9571 11.7291 10.8789 11.5938C10.8008 11.4584 10.6563 11.375 10.5 11.375Z"
      fill="#4B5563"
    />
  </svg>
);

export default TypeUnderLineIcon;
