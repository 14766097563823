import classNames from 'classnames';
import type { VFC } from 'react';
import { useEffect, useRef, useState } from 'react';

import Rating from '../Rating';

interface RatingData {
  review: number;
  onChange: (value: number) => void;
  label?: string;
  bold?: boolean;
}

interface Props {
  ratings: RatingData[];
  setHeight: (height: number) => void;
}

const RatingInput: VFC<Props> = ({ ratings, setHeight }) => {
  const [width, setWidth] = useState<number | null>(null);
  const [iconSize, setIconSize] = useState<string>('40');
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, [ref, setHeight]);

  const handleWindowSizeChange = (): void => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      };
    }
    return undefined;
  }, []);

  useEffect(() => {
    if (width && width < 768) {
      setIconSize('20');
    } else if (width && width >= 768 && width < 1024) {
      setIconSize('30');
    } else if (width && width >= 1024 && width < 1280) {
      setIconSize('40');
    } else {
      setIconSize('40');
    }
  }, [width]);
  return (
    <div className="flex items-center flex-col py-6 gap-2 " ref={ref}>
      {ratings.map(({ review, onChange, label, bold }) => (
        <div
          className={classNames('flex w-full items-center px-4', {
            'border border-headraceYellow-700 py-4 bg-gray-50 rounded': bold,
          })}
          key={label}
        >
          <span
            className={classNames('w-6/12 text-left', {
              'font-medium text-lg': !bold,
              'font-bold text-xl': bold,
            })}
          >
            {label}
          </span>
          <div className="w-6/12 flex justify-end">
            <Rating
              initialRating={review}
              iconSize={iconSize}
              enableUserInteraction
              onChange={onChange}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default RatingInput;
