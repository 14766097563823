import { useAuth0 } from '@auth0/auth0-react';
import type { RegistrationFormValues } from '@headrace/ui';
import { RegistrationForm, RegistrationFormType } from '@headrace/ui';
import { formatApolloError } from '@headrace/utils';
import React, { useState } from 'react';

import type {
  CreateEmployerInput,
  CurrentEmployeeQuery,
} from '@/graphql/generated';
import { useCreateEmployerMutation, useUserQuery } from '@/graphql/generated';
import { useEmployee } from '@/lib/EmployeeProvider';
import { EmployerFormSchema } from '@/validations';

interface EmployerFormProps {
  onCompleted: () => void;
  employee?: CurrentEmployeeQuery['currentEmployee'];
}

const EmployerForm: React.VFC<EmployerFormProps> = ({
  onCompleted,
  employee,
}) => {
  const { user, isLoading } = useAuth0();
  const [error, setError] = useState('');
  const { loadingEmployee } = useEmployee();
  const [createEmployer, { loading }] = useCreateEmployerMutation({
    onCompleted,
    onError: (_error) => setError(formatApolloError(_error)),
  });
  const { data, loading: userLoading } = useUserQuery();

  const handleSubmit = async (
    values: RegistrationFormValues
  ): Promise<void> => {
    const input: CreateEmployerInput = {
      name: values.companyName ?? '',
      description: values.companyDescription,
      user: {
        firstName: values.firstName ?? '',
        lastName: values.lastName ?? '',
        email: values.email ?? '',
      },
      tou: values.tou,
      linkedIn: values.linkedIn ?? '',
    };
    await createEmployer({ variables: { input } });
  };

  return (
    <RegistrationForm
      initialValues={{
        companyName: employee?.employer.company.name ?? '',
        companyDescription: employee?.employer.company.description ?? '',
        firstName: user?.given_name ?? data?.getUser?.firstName ?? '',
        lastName: user?.family_name ?? data?.getUser?.lastName ?? '',
        email: user?.email ?? '',
        tou: false,
        linkedIn: employee?.employer.company.linkedIn
          ? employee?.employer.company.linkedIn.replace('https://', '')
          : '',
      }}
      validationSchema={EmployerFormSchema}
      onSubmit={handleSubmit}
      error={error}
      loading={loading || isLoading || userLoading || loadingEmployee}
      type={RegistrationFormType.EMPLOYER}
    />
  );
};

export default EmployerForm;
