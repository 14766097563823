import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
  color?: string;
}

const ToothIcon: VFC<Props> = ({
  className,
  width = '32',
  height = '32',
  color = '#FFA300',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.88016 31.88C3.32016 31.88 3.04016 20.2 3.04016 17.88C3.04016 17.12 2.60019 15.88 2.16022 14.72C1.08029 11.72 -0.439778 7.59996 2.2002 3.71996C3.88013 1.27999 5.8402 0.0799561 8.1602 0.0799561C9.60016 0.0799561 10.9602 0.519923 12.2802 0.959889C13.4802 1.35989 14.6802 1.75989 15.8402 1.75989C17.0002 1.75989 18.0802 1.39986 19.3602 0.999856H19.4002C20.6801 0.559889 22.0002 0.119923 23.4802 0.119923C25.8002 0.119923 27.7602 1.27996 29.5602 3.67992C32.3602 7.43992 30.8401 11.6399 29.7602 14.7199V14.7599C29.3602 15.9199 28.9202 17.1599 28.9202 17.88C28.9202 22.08 28.3602 31.84 23.0802 31.84C19.0802 31.84 18.1202 27.72 17.5202 24.96C16.8403 21.92 16.4403 21.32 15.9602 21.32C15.1202 21.32 14.5202 23.32 13.9602 25.4C13.2401 28.16 12.2401 31.88 8.88016 31.88ZM8.16022 2.11999C6.48029 2.11999 5.16022 2.95996 3.84022 4.88002C1.76029 7.88002 2.96029 11.16 4.00025 14.04C4.52032 15.44 5.00025 16.7601 5.00025 17.88C5.00025 23.68 6.36028 29.88 8.84025 29.88C10.5603 29.88 11.2402 27.8001 12.0003 24.92C12.6802 22.32 13.4802 19.36 15.9203 19.36C18.2803 19.36 18.8803 22 19.4403 24.56C20.1603 27.6801 20.8003 29.88 23.0403 29.88C25.6802 29.88 26.8803 23.88 26.8803 17.92C26.8803 16.8401 27.3602 15.56 27.8403 14.16V14.1201C28.8803 11.16 30.0803 7.84005 27.9203 4.92005C26.5203 3.04012 25.1203 2.16002 23.4403 2.16002C22.3202 2.16002 21.1202 2.56002 20.0003 2.92005H19.9603C18.6402 3.36002 17.2804 3.79998 15.8003 3.79998C14.3204 3.79998 12.9204 3.32005 11.6003 2.87992C10.4801 2.52002 9.28012 2.12002 8.16029 2.12002L8.16022 2.11999Z"
      fill={color}
    />
  </svg>
);

export default ToothIcon;
