import { HEADRACE_FEE } from '@headrace/constants';
import { FeeScheduleTypeEnum } from '@headrace/types';

export default function headraceFee(
  feeType: FeeScheduleTypeEnum,
  salaryValue: number,
  fee: number
): number {
  switch (feeType) {
    case FeeScheduleTypeEnum.CASH_PERCENT:
      return salaryValue * (fee / 100) * ((100 - HEADRACE_FEE) / 100);
    case FeeScheduleTypeEnum.CASH_FIXED:
      return fee > salaryValue
        ? salaryValue * ((100 - HEADRACE_FEE) / 100)
        : fee * ((100 - HEADRACE_FEE) / 100);
    default:
      return 0;
  }
}
