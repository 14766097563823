import type { Environment } from './ampli';
import { ampli } from './ampli';

const initAmplitude = (): void => {
  let environment: Environment;
  if (process.env.NEXT_PUBLIC_HEADRACE_ENV === 'production') {
    environment = 'production';
  } else {
    environment = 'development';
  }
  ampli.load({ environment });
};

export default initAmplitude;
