import type { VFC } from 'react';

interface RecurringMonthlyFeeProps {
  amount: string;
  feeTriggerLimit?: number;
}

const RecurringMonthlyFee: VFC<RecurringMonthlyFeeProps> = ({
  amount,
  feeTriggerLimit,
}) => (
  <>
    <div>
      <span>${amount}</span>
    </div>
    <div>
      <span>For {feeTriggerLimit} months</span>
    </div>
  </>
);

export default RecurringMonthlyFee;
