import { MeetRequirementEnum } from '@headrace/types';
import {
  CheckCircleIcon as CheckCircleIconOutline,
  MinusCircleIcon as MinusCircleIconOutline,
  PencilIcon,
  XCircleIcon as XCircleIconOutline,
} from '@heroicons/react/outline';
import {
  CheckCircleIcon,
  MinusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import Button from '../../Button';
import type {
  CandidateRequerimentInformation as RequerimentInformation,
  RequirementsCandidateProps,
  UpdatedRequirement,
} from '../types';

const RequirementsCandidate: FC<RequirementsCandidateProps> = ({
  requirements,
  allowEdit = false,
  onSubmit,
  submitMode = false,
  requirementsWithErrors,
}) => {
  const [initialRequirementState, setInitialRequirementState] =
    useState<RequerimentInformation>();
  const [requirementInformation, setRequirementInformation] =
    useState<RequerimentInformation>();
  const [requirementsToUpdate, setRequirementsToUpdate] = useState<
    UpdatedRequirement[]
  >([]);
  const [isEditing, setIsEditting] = useState<boolean>(submitMode);

  const requirementParsedInfo = useMemo(() => {
    const mustHave = {
      totalYes: requirements.filter(
        (Requirement) =>
          Requirement.mustHave === true &&
          Requirement.meetRequirement === MeetRequirementEnum.YES
      ).length,
      requirements: requirements
        .filter((Requirement) => Requirement.mustHave === true)
        .sort((a, b) => a.prompt.localeCompare(b.prompt)),
    };

    const niceToHave = {
      totalYes: requirements.filter(
        (Requirement) =>
          Requirement.mustHave === false &&
          Requirement.meetRequirement === MeetRequirementEnum.YES
      ).length,
      requirements: requirements
        .filter((Requirement) => Requirement.mustHave === false)
        .sort((a, b) => a.prompt.localeCompare(b.prompt)),
    };

    const requirementInfo = {
      isEditing: !!submitMode,
      mustHave,
      niceToHave,
      totalYes: mustHave.totalYes + niceToHave.totalYes,
      totalRequirements: requirements.length,
    };
    return requirementInfo;
  }, [requirements, submitMode]);

  useEffect(() => {
    const parsedInfoCopyString = JSON.stringify(requirementParsedInfo);

    if (!isEditing || submitMode) {
      setRequirementInformation(
        JSON.parse(parsedInfoCopyString) as RequerimentInformation
      );
      setInitialRequirementState(
        JSON.parse(parsedInfoCopyString) as RequerimentInformation
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirements]);

  const updateRequirement = (id: string, status: MeetRequirementEnum): void => {
    if (!requirementInformation) return;

    const newInfo = JSON.parse(
      JSON.stringify(requirementInformation)
    ) as RequerimentInformation;
    newInfo.mustHave.requirements =
      requirementInformation.mustHave.requirements.map((req) => {
        if (req.id === id) {
          req.meetRequirement = status;
        }
        return req;
      });

    newInfo.niceToHave.requirements =
      requirementInformation.niceToHave.requirements.map((req) => {
        if (req.id === id) {
          req.meetRequirement = status;
        }
        return req;
      });

    const toUpdate = requirementsToUpdate;

    // detect if the requirement is already in the requirementsToUpdate and update the meetREqueriment if already exists
    const index = requirementsToUpdate.findIndex((item) => item.id === id);
    if (index !== -1) {
      toUpdate[index].meetRequirement = status;
    } else {
      toUpdate.push({ id, meetRequirement: status });
    }

    setRequirementsToUpdate(toUpdate);
    setRequirementInformation(newInfo);

    if (submitMode && onSubmit) {
      onSubmit(requirementsToUpdate);
    }
  };

  const getRequirementStatus = (
    id: string,
    status?: MeetRequirementEnum,
    error?: boolean
  ): React.ReactNode => {
    const OkIcon =
      status === MeetRequirementEnum.YES
        ? CheckCircleIcon
        : CheckCircleIconOutline;
    const NotOkIcon =
      status === MeetRequirementEnum.NO ? XCircleIcon : XCircleIconOutline;
    const NotSureIcon =
      status === MeetRequirementEnum.UNSURE
        ? MinusCircleIcon
        : MinusCircleIconOutline;

    const node = (
      <div className="grid grid-cols-3 w-full gap-6">
        {(status === MeetRequirementEnum.YES || isEditing) && (
          <div className="col-span-3 lg:col-span-1  flex flex-inline">
            <Button
              buttonType="link"
              className={classNames(
                'flex flex-inline !m-0',
                isEditing ? 'cursor-pointer' : 'cursor-default'
              )}
              onClick={(): void => {
                if (isEditing) updateRequirement(id, MeetRequirementEnum.YES);
              }}
            >
              <OkIcon
                className={classNames(
                  'h-6 w-6',
                  status === MeetRequirementEnum.YES
                    ? 'text-green-500'
                    : 'text-headraceBlack-800'
                )}
              />
              <span
                className={classNames(
                  status === MeetRequirementEnum.YES
                    ? 'font-medium'
                    : 'font-normal',
                  'text-sm text-headraceBlack-800'
                )}
              >
                Yes
              </span>
            </Button>
          </div>
        )}
        {(status === MeetRequirementEnum.NO || isEditing) && (
          <div className="col-span-3 lg:col-span-1 flex flex-inline">
            <Button
              buttonType="link"
              className={classNames(
                'flex flex-inline !m-0',
                isEditing ? 'cursor-pointer' : 'cursor-default'
              )}
              onClick={(): void => {
                if (isEditing) updateRequirement(id, MeetRequirementEnum.NO);
              }}
            >
              <NotOkIcon
                className={classNames(
                  'h-6 w-6',
                  status === MeetRequirementEnum.NO
                    ? 'text-red-500'
                    : 'text-headraceBlack-800'
                )}
              />
              <span
                className={classNames(
                  status === MeetRequirementEnum.NO
                    ? 'font-bold'
                    : 'font-normal',
                  'text-sm text-headraceBlack-800'
                )}
              >
                No
              </span>
            </Button>
          </div>
        )}
        {(status === MeetRequirementEnum.UNSURE || isEditing) && (
          <div className="col-span-3 lg:col-span-1  flex flex-inline">
            <Button
              buttonType="link"
              className={classNames(
                'flex flex-inline !m-0',
                isEditing ? 'cursor-pointer' : 'cursor-default'
              )}
              onClick={(): void => {
                if (isEditing)
                  updateRequirement(id, MeetRequirementEnum.UNSURE);
              }}
            >
              <NotSureIcon
                className={classNames(
                  'h-6 w-6',
                  status === MeetRequirementEnum.UNSURE
                    ? 'text-headraceYellow-700'
                    : 'text-headraceBlack-800'
                )}
              />
              <span
                className={classNames(
                  status === MeetRequirementEnum.UNSURE
                    ? 'font-medium'
                    : 'font-normal',
                  'text-sm text-headraceBlack-800'
                )}
              >
                Unsure
              </span>
            </Button>
          </div>
        )}
        {error ? (
          <div className="col-span-3 lg:col-span-3  flex flex-inline">
            <p className="text-red-600">Please make a selection</p>
          </div>
        ) : null}
      </div>
    );

    return <div className="flex flex-inline justify-between">{node}</div>;
  };

  if (!requirementInformation) return <> </>;

  return (
    <div className="bg-white shadow rounded-lg divide-y divide-gray-200 text-headraceBlack-700 font-medium text-lg items-center flex-wrap sm:flex-nowrap mb-6">
      <div className="grid grid-cols-1 sm:grid-cols-3 w-full">
        <div className="col-span-1 relative px-6 py-5">
          <span className="font-medium text-lg ">
            Role requirements{' '}
            {allowEdit && !isEditing && (
              <Button
                className="!m-0"
                buttonType="link"
                onClick={(): void => {
                  setIsEditting(true);
                }}
              >
                <PencilIcon
                  className="h-4 w-4 text-blue-600"
                  aria-hidden="true"
                />
              </Button>
            )}
          </span>
          {isEditing && (
            <div className="text-gray-500 text-sm font-normal">
              Share candidate qualifications
            </div>
          )}
          <div className="flex-shrink-1 flex text-gray-400 text-base font-normal mt-4">
            <span className="text-gray-600 text-sm font-normal mr-1">
              {requirementInformation.totalYes}/
              {requirementInformation.totalRequirements}
            </span>
            <span className="text-gray-400 text-sm font-normal">
              requirements met
            </span>
          </div>
          {isEditing && !submitMode && (
            <div className="flex flex-inline gap-3 mt-3">
              <Button
                buttonType="secondary"
                size="sm"
                onClick={(): void => {
                  setIsEditting(false);
                  setRequirementInformation(
                    JSON.parse(
                      JSON.stringify(initialRequirementState)
                    ) as RequerimentInformation
                  );
                }}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                onClick={(): void => {
                  if (onSubmit) {
                    onSubmit(requirementsToUpdate);
                    setIsEditting(false);
                  }
                }}
              >
                Save
              </Button>
            </div>
          )}
        </div>
        <div className="col-span-1 relative border-b border-l border-r  py-5 ">
          <div className="flex flex-inline pb-6 justify-between px-6 border-b">
            <span className="md:flex-shrink-1 sm:flex-shrink-1 font-medium text-lg">
              Must have
            </span>
            <div className="flex-shrink-1 flex text-gray-400 text-base font-normal">
              {requirementInformation.mustHave.totalYes}/
              {requirementInformation.mustHave.requirements.length}
            </div>
          </div>
          {requirementInformation.mustHave.requirements.map((req, idx) => (
            <div className="px-6 text-sm font-normal mt-6" key={req.id}>
              <div className="m-h-12 mb-8">{req.prompt}</div>
              <div
                className={classNames(
                  'flex flex-inline justify-between pb-3',
                  idx !==
                    requirementInformation.mustHave.requirements.length - 1 &&
                    'border-b'
                )}
              >
                {getRequirementStatus(
                  req.id,
                  req.meetRequirement,
                  requirementsWithErrors?.includes(req.id)
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="col-span-1 relative pt-5 sm:py-5">
          <div className="flex flex-inline pb-6 justify-between px-6 border-b">
            <span className="md:flex-shrink-1 sm:flex-shrink-1 font-medium text-lg">
              Nice to have
            </span>
            <div className="flex-shrink-1 flex text-gray-400 text-base font-normal">
              {requirementInformation.niceToHave.totalYes}/
              {requirementInformation.niceToHave.requirements.length}
            </div>
          </div>
          {requirementInformation.niceToHave.requirements.map((req, idx) => (
            <div className="px-6 text-sm font-normal mt-6" key={req.id}>
              <div className="m-h-12 mb-8">{req.prompt}</div>
              <div
                className={classNames(
                  'flex flex-inline justify-between pb-3',
                  idx !==
                    requirementInformation.niceToHave.requirements.length - 1 &&
                    'border-b'
                )}
              >
                {getRequirementStatus(
                  req.id,
                  req.meetRequirement,
                  requirementsWithErrors?.includes(req.id)
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RequirementsCandidate;
