import type { VFC } from 'react';

interface Props {
  className?: string;
}

const TopMatchIcon: VFC<Props> = ({ className }) => (
  <span className={className}>
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4101 6.72618H23.4452C23.8461 6.72618 24.1328 7.14901 23.936 7.54024L22.7477 10.0286L23.9433 12.4757C24.1256 12.8475 23.8437 13.2752 23.4452 13.2752H20.4101C20.393 13.8244 19.1245 14.3639 18.9399 14.6385C18.6775 15.2266 18.7552 16.3978 18.1453 16.5509L16.6386 16.9397L15.6982 18.1888C15.5718 18.3565 15.3726 18.4318 15.1781 18.4051L13.6302 18.1864L12.2815 18.981C12.0992 19.0903 11.8806 19.0806 11.7128 18.9785L10.3715 18.1864L8.82103 18.4051C8.60474 18.4367 8.39821 18.3346 8.28397 18.1645L7.36297 16.9398L5.84901 16.5485C5.24879 16.3955 5.3217 15.2193 5.0592 14.6385C4.87448 14.3591 3.61091 13.8269 3.59146 13.2753C3.12241 13.2753 0.558663 13.3384 0.313418 13.2169C0.0364228 13.0833 -0.0778397 12.7503 0.0558756 12.4758L1.25151 10.0286C-0.568677 6.21332 -0.821332 6.72604 3.58933 6.72604C3.60878 6.17685 4.87722 5.63733 5.0595 5.36279C5.32438 4.77226 5.24657 3.60096 5.85656 3.44789L7.3632 3.05908L8.30363 1.80998C8.42757 1.64471 8.62928 1.567 8.82127 1.59369L10.3717 1.81242L11.7179 1.01782C11.9002 0.910894 12.1188 0.918221 12.289 1.02271L13.6304 1.81251L15.1784 1.59378C15.3971 1.56465 15.6036 1.66425 15.7179 1.83675L16.6389 3.05907L18.1504 3.45032C18.7506 3.60338 18.6802 4.77956 18.9402 5.36277C19.1272 5.63967 20.3882 6.17213 20.4101 6.72618ZM12.5318 5.96544C12.3763 5.44294 11.6278 5.4357 11.4675 5.96544L10.6947 8.53646L8.22098 8.53157C7.66446 8.51945 7.44337 9.20226 7.85885 9.52302L9.91224 11.1171L9.12486 13.7027C8.95715 14.2325 9.56714 14.6432 9.98994 14.3127L11.9996 12.7405L14.0093 14.3127C14.4321 14.6432 15.0372 14.2301 14.8793 13.715L14.0871 11.1171L16.1429 9.52297C16.5561 9.19976 16.33 8.53153 15.8027 8.53153L13.307 8.53641L12.5318 5.96544Z"
        fill="#10B981"
      />
    </svg>
  </span>
);

export default TopMatchIcon;
