import { isValidHttpUrl, phoneNumberFormatter } from '@headrace/utils';
import { LinkIcon, MailIcon, PhoneIcon } from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';

import { LinkedinIcon, SplitSearchIcon } from '../CustomIcons';

interface Props {
  email: string;
  linkedIn?: string | null;
  phone?: string | null;
  website?: string | null;
  containerClassNames?: string;
  linksClassNames?: string;
  isSplitSearch?: boolean | null;
}

const ContactLinks: React.VFC<Props> = ({
  email,
  linkedIn,
  phone,
  website,
  containerClassNames = 'flex justify-center',
  linksClassNames = 'flex gap-5',
  isSplitSearch = false,
}) => (
  <div className={containerClassNames}>
    <div className={linksClassNames}>
      {linkedIn && isValidHttpUrl(linkedIn) && (
        <a
          href={linkedIn}
          onClick={(e): void => e.stopPropagation()}
          target="_blank"
          rel="noreferrer"
        >
          <LinkedinIcon aria-hidden="true" className="!fill-gray-400 w-5 h-5" />
        </a>
      )}
      <a href={`mailto:${email}`} onClick={(e): void => e.stopPropagation()}>
        <MailIcon className="w-5 h-5 stroke-gray-400 stroke-3 text-blue-500" />
      </a>
      {website && isValidHttpUrl(website) && (
        <a
          href={website}
          className="w-5 h-5"
          target="_blank"
          rel="noreferrer"
          onClick={(e): void => e.stopPropagation()}
        >
          <LinkIcon className=" stroke-gray-400 stroke-3" />
        </a>
      )}
      {phone && (
        <>
          <a href={`tel:${phone}`} onClick={(e): void => e.stopPropagation()}>
            <PhoneIcon
              className="w-5 h-5 stroke-gray-400 stroke-3 focus:outline-none"
              data-for="phoneIcon"
              data-tip={phoneNumberFormatter(phone)}
            />
          </a>
          <ReactTooltip
            id="phoneIcon"
            place="top"
            effect="solid"
            multiline
            offset={{ top: -5 }}
            className="bg-gray-300"
          />
        </>
      )}
      {isSplitSearch && <SplitSearchIcon className="w-8 h-8 -ml-2" />}
    </div>
  </div>
);
export default ContactLinks;
