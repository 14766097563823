import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useField } from 'formik';
import { useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import ErrorText from '../ErrorText';
import useFocusOnError from './errorHooks';

interface TextAreaProps {
  label?: string;
  description?: string;
  subtitle?: string | JSX.Element;
  name: string;
  rows?: number;
  placeholder?: string;
  maxLength?: number;
  inputClassName?: string;
  countDown?: boolean;
  containerClassName?: string;
  info?: string;
  infoIcon?: JSX.Element;
}

const TextArea: React.VFC<TextAreaProps> = (props) => {
  const {
    label,
    description,
    subtitle,
    rows = 3,
    placeholder = '',
    name,
    maxLength,
    inputClassName,
    countDown = false,
    containerClassName,
    info,
    infoIcon = <QuestionMarkCircleIcon className="w-4 h-4 text-gray-400" />,
  } = props;
  const [field, meta] = useField<string>(name);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const [count, setCount] = useState(field.value.length);
  const className = classNames(
    'focus:ring-gray-200',
    'focus:border-gray-200',
    'w-full',
    'sm:text-sm',
    'border',
    'border-gray-300',
    'rounded-md',
    'block',
    inputClassName,
    {
      'border-red-500': meta.touched && meta.error,
      'text-red-900': meta.touched && meta.error,
      'placeholder-red-300': meta.touched && meta.error,
      'focus-outline-nodes': meta.touched && meta.error,
      'focus:ring-red-500': meta.touched && meta.error,
      'focus:border-red-500': meta.touched && meta.error,
    }
  );
  useFocusOnError(inputRef, name);
  return (
    <div className={classNames('flex flex-col gap-1', containerClassName)}>
      {label && (
        <div>
          <label
            htmlFor={field.name}
            className="flex gap-2 items-center text-sm font-medium text-headraceBlack-800"
          >
            {label}
            {info && (
              <div>
                <div data-for={`${field.name}-info`} data-tip={info}>
                  {infoIcon}
                </div>
                <ReactTooltip
                  id={`${field.name}-info`}
                  place="top"
                  effect="solid"
                  html
                  arrowColor="transparent"
                  className="!opacity-100 !bg-headraceBlack-800 !px-[12px] !text-xs !font-normal"
                />
              </div>
            )}
          </label>
          {subtitle && <p className="text-xs text-gray-500">{subtitle}</p>}
        </div>
      )}
      <div>
        <textarea
          id={field.name}
          name={field.name}
          rows={rows}
          value={field.value}
          className={className}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={(e): void => {
            field.onChange(e);
            setCount(e.target.value.length);
          }}
          onBlur={field.onBlur}
          ref={inputRef}
        />
      </div>
      {countDown && maxLength && (
        <p className="mt-2 text-sm text-gray-500">{`Characters left | ${
          maxLength - count
        }`}</p>
      )}
      {description && <p className="text-sm text-gray-500">{description}</p>}
      <ErrorText
        id={`${field.name}-error`}
        error={meta.error || null}
        touched={meta.touched}
      />
    </div>
  );
};

export default TextArea;
