import { DocumentAddIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import React from 'react';
import toast from 'react-hot-toast';

import Button from '../Button';

interface DragDropFileProps {
  onFileAdded: (file: File) => void;
  multiple?: boolean;
  acceptedExtensions?: string[];
  displayMimeTypes?: string[];
  maxSize?: number;
  label?: string;
  handleClick?: () => void;
}

const DragDropFile: React.VFC<DragDropFileProps> = ({
  onFileAdded,
  acceptedExtensions = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/msword',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
  ],
  maxSize = 25,
  label,
  handleClick,
  displayMimeTypes = ['PNG', 'JPEG', 'DOCX', 'PDF', 'PPT'],
}) => {
  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const validateFile = (file: File): boolean => {
    if (acceptedExtensions && !acceptedExtensions?.includes(file.type)) {
      toast.error('File type not accepted');
      return false;
    }
    if (file.size > maxSize * 1024 * 1024) {
      toast.error('File size too big');
      return false;
    }
    return true;
  };

  const handleDrag = (e: React.DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (validateFile(e.dataTransfer.files[0]))
        onFileAdded(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      if (validateFile(e.target.files[0])) onFileAdded(e.target.files[0]);
    }
  };

  const onButtonClick = (): void => {
    if (inputRef.current) inputRef.current.click();
    if (handleClick) handleClick();
  };

  return (
    <>
      {label && (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className="block text-sm font-medium text-headraceBlack-800">
          {label}
        </label>
      )}
      <div
        className={classNames(
          'relative flex flex-col justify-center items-center pt-5 pb-6 text-sm border-2 border-dashed rounded-md gap-1 h-[140px]',
          {
            'border-zinc-400': dragActive,
          }
        )}
        onDragEnter={handleDrag}
      >
        {dragActive && (
          <div
            className="absolute h-full w-full top-0 right-0 left-0 bottom-0"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
        <DocumentAddIcon width={48} height={48} className="text-gray-400" />
        <p className="text-sm text-gray-500 dark:text-gray-400 font-medium">
          <span className="text-indigo-600">
            <Button onClick={onButtonClick} buttonType="link" className="!m-0">
              Upload a file
            </Button>
          </span>{' '}
          or drag and drop
        </p>
        <p className=" text-gray-500 dark:text-gray-400">
          {displayMimeTypes.join(', ')} up to {maxSize}MB
        </p>
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple
          onChange={handleChange}
          className="hidden"
        />
      </div>
    </>
  );
};
export default DragDropFile;
