import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
  color?: string;
}

const PetIcon: VFC<Props> = ({
  className,
  width = '36',
  height = '28',
  color = '#FFA300',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 36 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.6948 5.61775C24.7396 5.62257 24.7844 5.62452 24.8284 5.62452C25.4863 5.62452 26.0508 5.12649 26.1191 4.45772C26.218 3.49052 26.9336 2.76099 27.7827 2.76099C28.635 2.76099 29.3523 3.49055 29.4514 4.45772C29.5246 5.17125 30.1599 5.69289 30.8757 5.61775C31.5893 5.54458 32.1089 4.90655 32.0358 4.19342C31.8006 1.89575 29.9717 0.163086 27.7824 0.163086C25.5956 0.163086 23.7694 1.89589 23.5341 4.19342C23.4616 4.90695 23.9807 5.54498 24.6948 5.61775Z"
      fill={color}
    />
    <path
      d="M5.12412 5.61776C5.83976 5.69302 6.47569 5.17179 6.54846 4.45772C6.64768 3.49016 7.36486 2.76099 8.21719 2.76099C9.06679 2.76099 9.78202 3.49056 9.88126 4.45772C9.94962 5.12646 10.5141 5.62452 11.172 5.62452C11.216 5.62452 11.2608 5.62257 11.306 5.61775C12.0195 5.54458 12.5392 4.90655 12.466 4.19342C12.2309 1.89575 10.4041 0.163086 8.21771 0.163086C6.02851 0.163086 4.19971 1.89589 3.96437 4.19342C3.89133 4.90695 4.41059 5.54499 5.12412 5.61776Z"
      fill={color}
    />
    <path
      d="M9.64721 27.8368C13.1555 27.8368 16.3052 25.938 18.0002 23.0048C19.6946 25.9376 22.8445 27.8368 26.3539 27.8368C31.5702 27.8368 35.8979 23.5958 36.0002 18.3828C36.0143 17.6656 35.4442 17.0724 34.727 17.0585C34.0047 17.0369 33.4166 17.6145 33.4027 18.3317C33.328 22.1407 30.1659 25.2387 26.354 25.2387C23.1372 25.2387 20.3267 23.0566 19.52 19.9347C19.3861 19.4115 19.3137 18.8739 19.3048 18.3358C19.3044 18.3046 19.3032 18.2806 19.3004 18.249V18.2341C19.78 18.0561 20.202 17.7818 20.5247 17.4193L24.8264 12.5896C25.4696 11.8676 25.6264 10.93 25.246 10.0817C24.7857 9.05526 23.6304 8.39209 22.3024 8.39209L13.6988 8.39222C12.3712 8.39222 11.2156 9.05539 10.7551 10.0818C10.3748 10.9303 10.5316 11.8678 11.1748 12.5898L15.4764 17.4198C15.7992 17.7825 16.222 18.057 16.7021 18.235V18.2379C16.6993 18.2707 16.6973 18.3035 16.6969 18.3367C16.6881 18.8735 16.616 19.411 16.4821 19.9326C15.6749 23.057 12.8647 25.239 9.64807 25.239C5.83607 25.239 2.67373 22.1401 2.59873 18.332C2.58428 17.6152 1.99676 17.0256 1.2744 17.0588C0.557198 17.0729 -0.0128353 17.666 0.0012314 18.3831C0.103185 23.5955 4.43057 27.8368 9.64757 27.8368L9.64721 27.8368ZM13.2902 11.0592C13.3854 11.0219 13.5202 10.9904 13.6987 10.9904H22.302C22.4804 10.9904 22.6152 11.022 22.7105 11.0592L18.5841 15.6918C18.5081 15.7766 18.2778 15.8675 18.0001 15.8675C17.7225 15.8675 17.4922 15.7771 17.4161 15.6918L13.2902 11.0592Z"
      fill={color}
    />
    <path
      d="M28.0501 21.2815C28.0501 21.8407 27.5967 22.2942 27.0373 22.2942C26.4779 22.2942 26.0244 21.8407 26.0244 21.2815C26.0244 20.7221 26.4779 20.2686 27.0373 20.2686C27.5967 20.2686 28.0501 20.7221 28.0501 21.2815Z"
      fill={color}
    />
    <path
      d="M30.6408 17.5754C30.6408 18.1346 30.1874 18.5882 29.628 18.5882C29.0686 18.5882 28.6152 18.1347 28.6152 17.5754C28.6152 17.016 29.0686 16.5625 29.628 16.5625C30.1874 16.5625 30.6408 17.016 30.6408 17.5754Z"
      fill={color}
    />
    <path
      d="M25.4597 17.5754C25.4597 18.1346 25.0062 18.5882 24.4468 18.5882C23.8876 18.5882 23.4341 18.1347 23.4341 17.5754C23.4341 17.016 23.8876 16.5625 24.4468 16.5625C25.0062 16.5625 25.4597 17.016 25.4597 17.5754Z"
      fill={color}
    />
    <path
      d="M9.97586 21.2815C9.97586 21.8407 9.52236 22.2942 8.96296 22.2942C8.40356 22.2942 7.9502 21.8407 7.9502 21.2815C7.9502 20.7221 8.4036 20.2686 8.96296 20.2686C9.52233 20.2686 9.97586 20.7221 9.97586 21.2815Z"
      fill={color}
    />
    <path
      d="M12.5667 17.5754C12.5667 18.1346 12.1132 18.5882 11.5539 18.5882C10.9945 18.5882 10.541 18.1347 10.541 17.5754C10.541 17.016 10.9945 16.5625 11.5539 16.5625C12.1131 16.5625 12.5667 17.016 12.5667 17.5754Z"
      fill={color}
    />
    <path
      d="M7.3854 17.5754C7.3854 18.1346 6.932 18.5882 6.37263 18.5882C5.81326 18.5882 5.35986 18.1347 5.35986 17.5754C5.35986 17.016 5.81326 16.5625 6.37263 16.5625C6.932 16.5625 7.3854 17.016 7.3854 17.5754Z"
      fill={color}
    />
  </svg>
);

export default PetIcon;
