import type { ApolloError } from '@apollo/client';
import { formatApolloError } from '@headrace/utils';
import { useState } from 'react';

import ErrorMessageBox from '../../ErrorMessageBox';
import LoadingSpinner from '../../LoadingSpinner';
import type { ItemsMenu } from '../../Menu';
import MenuActions from '../../Menu';
import SimpleAlert from '../../SimpleAlert';
import Table from '../../Table';
import UserCard from '../../UserCard';
import type { Member } from './TeamManagement';

interface TeamTableProps {
  data: Member[];
  loading: boolean;
  error?: ApolloError;
  userId?: string;
  deleteMember: (userId?: string) => Promise<void>;
  removing: boolean;
}

const TeamTable: React.VFC<TeamTableProps> = ({
  data,
  loading,
  error,
  userId,
  deleteMember,
  removing,
}) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [userData, setUserData] = useState<Member>();

  const getOptionsMenu = (memberData: Member): React.ReactNode => {
    const optionsMenu: ItemsMenu[] = [
      {
        label: 'Remove',
        action: (): void => {
          setOpenConfirmationModal(true);
          setUserData(memberData);
        },
      },
    ];
    return <MenuActions options={optionsMenu} />;
  };

  if (loading) return <LoadingSpinner className="p-4" />;
  if (error) return <ErrorMessageBox error={formatApolloError(error)} />;

  const userDataCard = (memberData: Member): React.ReactNode => (
    <UserCard
      email={memberData.user.email}
      name={memberData.user.name}
      avatar={memberData.user.photoUrl}
    />
  );

  return (
    <>
      <Table
        data={data}
        columns={[
          {
            header: 'Team Member',
            formatter: (memberData) => userDataCard(memberData),
            headerClassName: 'w-[80%]',
            className: 'break-words truncate',
          },
          {
            header: '',
            className: 'text-right',
            formatter: (memberData) =>
              userId !== memberData.id && getOptionsMenu(memberData),
          },
        ]}
        tableFixed
        emptyTitle="No team members found"
      />
      <SimpleAlert
        onClose={(): void => {
          setOpenConfirmationModal(false);
        }}
        open={openConfirmationModal}
        message="Are you sure you want to remove this account and permissions from your team? This action cannot be undone."
        title="Remove Account"
        cancelText="Cancel"
        confirmText="Confirm"
        onConfirm={async (): Promise<void> => {
          await deleteMember(userData?.id);
          setOpenConfirmationModal(false);
        }}
        loading={removing}
      />
    </>
  );
};

export default TeamTable;
