import type { NavigationItem } from '@headrace/ui';
import { LoadingSpinner, useHeaderTabs, useSecondaryNav } from '@headrace/ui';
import { getScreenProperties, setToStorage } from '@headrace/utils';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

import { ampli } from '@/lib/ampli';
import { useEmployee } from '@/lib/EmployeeProvider';

import Collaborators from './Collaborators';
import RoleCollaboratorsProvider, {
  useRoleCollaborators,
} from './CollaboratorsProvider';
import RoleAIDetailsProvider, {
  useRoleAIDetails,
} from './RoleAIDetailsProvider';
import RoleDetailsProvider, { useRoleDetails } from './RoleDetailsProvider';

const RoleDetailsLayout: React.FC = ({ children }) => {
  const { query } = useRouter();
  const { employee } = useEmployee();
  const roleId = useMemo(() => {
    if (Array.isArray(query.roleId)) return query.roleId[0];
    return query.roleId ?? '';
  }, [query.roleId]);

  const { setTitle } = useSecondaryNav();
  const { setTabs } = useHeaderTabs();

  const { roleData, roleQuestions } = useRoleDetails();

  const { roleData: roleAIData } = useRoleAIDetails();

  const {
    data: roleCollaborators,
    refetch: roleCollaboratorsRefetch,
    error: roleCollaboratorsError,
    loading: roleCollaboratorsLoading,
  } = useRoleCollaborators();

  const isHeadRaceAI = employee?.employer.isHeadRaceAI;

  // Set lastRoleIdViewed in local storage
  useEffect(() => {
    if (roleAIData.data && isHeadRaceAI) {
      setToStorage('lastRoleIdViewed', roleAIData.data.id);
    }
  }, [isHeadRaceAI, roleAIData.data]);

  useEffect(() => {
    if (roleData.data) {
      setTitle(roleData.data.name);
    } else if (roleAIData.data && isHeadRaceAI) {
      setTitle(roleAIData.data.name);
    }
  }, [isHeadRaceAI, roleAIData.data, roleData.data, setTitle]);

  useEffect(() => {
    let role = {
      id: '',
      employer: {
        id: '',
      },
    };
    if (isHeadRaceAI) {
      role = {
        id: roleAIData.data?.id ?? '',
        employer: {
          id: roleAIData.data?.employer.id ?? '',
        },
      };
    } else {
      role = {
        id: roleData.data?.id ?? '',
        employer: {
          id: roleData.data?.employer.id ?? '',
        },
      };
    }

    // Amplitude tracking: role viewed
    if (role) {
      const { viewport, screenResolution } = getScreenProperties();
      ampli.roleRoleViewed(
        {
          app: 'Employer',
          'employer id': role.employer.id,
          'role id': role.id,
        },
        {
          user_properties: {
            viewport,
            'screen resolution': screenResolution,
          },
        }
      );
    }
  }, [
    isHeadRaceAI,
    roleAIData.data?.employer.id,
    roleAIData.data?.id,
    roleData.data,
  ]);

  useEffect(() => {
    const totalQuestions = roleQuestions.data
      ? roleQuestions.data.filter((rq) => !rq.answer).length
      : 0;
    let tabsOptions: NavigationItem[] = [
      {
        name: 'Overview',
        href: `/roles/${roleId}`,
      },
      {
        name: 'Role dossier',
        href: `/roles/${roleId}/dossier`,
      },
      {
        name: 'Recruiters',
        href: `/roles/${roleId}/recruiters`,
      },
      {
        name: 'Sourcing',
        href: `/roles/${roleId}/sourcing`,
        pill: totalQuestions > 0 ? totalQuestions : null,
      },
    ];
    if (isHeadRaceAI) {
      tabsOptions = [
        {
          name: 'Setup + Configuration',
          href: `/roles/${roleId}`,
        },
        {
          name: 'Prospecting',
          href: `/roles/${roleId}/prospecting`,
        },
        {
          name: 'Outreach',
          href: `/roles/${roleId}/outreach`,
        },
        {
          name: 'Candidate review',
          href: `/roles/${roleId}/candidate-review`,
          pill:
            roleAIData.data?.candidateNeedsReviewCount === 0
              ? undefined
              : roleAIData.data?.candidateNeedsReviewCount.toString(),
          pillType: 'PRIMARY',
        },
      ];
    }
    setTabs(tabsOptions);
  }, [
    roleQuestions.data,
    roleId,
    setTabs,
    isHeadRaceAI,
    roleAIData.data?.candidateNeedsReviewCount,
  ]);

  const refetchCollaborators = async (): Promise<void> => {
    await roleCollaboratorsRefetch();
  };

  return (
    <div className="flex flex-col mb-12">
      {roleData.loading || roleAIData.loading ? (
        <div className="flex flex-col items-center justify-center h-96">
          <div className="flex flex-col items-center justify-center">
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        children
      )}
      {employee && !employee.employer.isHeadRaceAI ? (
        <Collaborators
          loading={roleCollaboratorsLoading}
          refetch={refetchCollaborators}
          roleCollaborators={roleCollaborators ?? []}
          error={roleCollaboratorsError}
        />
      ) : null}
    </div>
  );
};

const WrapperRoleDetailsLayout: React.FC = ({ children }) => {
  const { query, pathname } = useRouter();
  const { employee } = useEmployee();
  const roleId = useMemo(() => {
    if (Array.isArray(query.roleId)) return query.roleId[0];
    return query.roleId ?? '';
  }, [query.roleId]);

  if (employee?.employer.isHeadRaceAI) {
    return (
      <RoleAIDetailsProvider roleId={roleId}>
        <RoleDetailsLayout>{children}</RoleDetailsLayout>
      </RoleAIDetailsProvider>
    );
  }

  // This is a workaround to prevent the RoleDetailsProvider to be rendered in the RoleDossierTab
  if (pathname.includes('roles/[roleId]/dossier')) {
    return (
      <RoleCollaboratorsProvider roleId={roleId}>
        <RoleDetailsLayout>{children}</RoleDetailsLayout>
      </RoleCollaboratorsProvider>
    );
  }
  return (
    <RoleDetailsProvider roleId={roleId}>
      <RoleCollaboratorsProvider roleId={roleId}>
        <RoleDetailsLayout>{children}</RoleDetailsLayout>
      </RoleCollaboratorsProvider>
    </RoleDetailsProvider>
  );
};

export default WrapperRoleDetailsLayout;
