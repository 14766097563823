import classNames from 'classnames';
import type { VFC } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';

import Modal from './Modal';

interface Props {
  text: string;
  title: string;
  fixedHeight: number;
  textClassName?: string;
}

const LargeText: VFC<Props> = ({
  text,
  title,
  fixedHeight,
  textClassName,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  useLayoutEffect(() => {
    if (ref.current && ref.current.scrollHeight > fixedHeight) {
      setIsOverflow(true);
      ref.current.style.height = `${fixedHeight}px`;
    } else {
      setIsOverflow(false);
      if (ref.current) ref.current.style.height = 'auto';
    }
  }, [fixedHeight, text]);

  return (
    <>
      <div>
        <div
          ref={ref}
          className={classNames(
            'font-base overflow-y-hidden',
            {
              'overflow-y-auto': isOverflow,
            },
            textClassName
          )}
        >
          {text}
        </div>
        {isOverflow && (
          <div className="w-full flex justify-end">
            <button
              className="text-blue-500 underline outline-none focus:outline-none"
              type="button"
              onClick={(): void => {
                setIsShowMore(true);
              }}
            >
              Show More
            </button>
          </div>
        )}
      </div>
      <Modal
        open={isShowMore}
        onClose={(): void => {
          setIsShowMore(false);
        }}
        className="!max-w-[50%]"
        title={title}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="mt-4 text-base text-headraceBlack-700">{text}</div>
        </div>
      </Modal>
    </>
  );
};

export default LargeText;
