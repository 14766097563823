import type { VFC } from 'react';

import LoadingSpinner from '../../LoadingSpinner';

const LoadingPage: VFC = () => (
  <div className="flex flex-col justify-center items-center bg-white min-h-[600px]">
    <LoadingSpinner className="p-4" />
  </div>
);

export default LoadingPage;
