import type { VFC } from 'react';

interface Props {
  className?: string;
}

const UserGroupIcon: VFC<Props> = ({ className }) => (
  <span className={className}>
    <svg
      width="48"
      height="38"
      viewBox="0 0 48 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.25 37H46.5V32.5C46.5 28.7721 43.4779 25.75 39.75 25.75C37.5999 25.75 35.6846 26.7553 34.4484 28.3215M35.25 37H12.75M35.25 37V32.5C35.25 31.0235 34.9655 29.6134 34.4484 28.3215M12.75 37H1.5V32.5C1.5 28.7721 4.52208 25.75 8.25 25.75C10.4001 25.75 12.3154 26.7553 13.5516 28.3215M12.75 37V32.5C12.75 31.0235 13.0345 29.6134 13.5516 28.3215M13.5516 28.3215C15.2104 24.1773 19.2633 21.25 24 21.25C28.7367 21.25 32.7896 24.1773 34.4484 28.3215M30.75 7.75C30.75 11.4779 27.7279 14.5 24 14.5C20.2721 14.5 17.25 11.4779 17.25 7.75C17.25 4.02208 20.2721 1 24 1C27.7279 1 30.75 4.02208 30.75 7.75ZM44.25 14.5C44.25 16.9853 42.2353 19 39.75 19C37.2647 19 35.25 16.9853 35.25 14.5C35.25 12.0147 37.2647 10 39.75 10C42.2353 10 44.25 12.0147 44.25 14.5ZM12.75 14.5C12.75 16.9853 10.7353 19 8.25 19C5.76472 19 3.75 16.9853 3.75 14.5C3.75 12.0147 5.76472 10 8.25 10C10.7353 10 12.75 12.0147 12.75 14.5Z"
        stroke="#9CA3AF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default UserGroupIcon;
