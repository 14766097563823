import 'react-datepicker/dist/react-datepicker.css';
import './tooltip/Tooltip.css';

import { CalendarIcon } from '@heroicons/react/outline';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { useRef } from 'react';
import DatePicker from 'react-datepicker';
import ReactTooltip from 'react-tooltip';

import ErrorText from '../ErrorText';
import useFocusOnError from './errorHooks';
import InputErrorIcon from './ErrorIcon';

interface DatePickerFieldProps {
  label?: string;
  description?: string;
  name: string;
  disabled?: boolean;
  validatioError?: string;
  containerClassName?: string;
  minDate?: Date;
  maxDate?: Date;
  tooltip?: string;
}

const DatePickerField: React.VFC<DatePickerFieldProps> = (props) => {
  const {
    label,
    description,
    name,
    disabled,
    containerClassName,
    minDate,
    maxDate,
    tooltip,
  } = props;
  const [field, meta] = useField<string>(name);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { setFieldValue } = useFormikContext();
  const className = classNames(
    'focus:ring-transparent',
    'focus:border-gray-300',
    'w-full',
    'sm:text-sm',
    'border',
    'border-gray-300',
    'block',
    'rounded-none',
    'rounded-l-md',
    'flex-1',
    'text-gray-500',
    {
      'border-red-500': meta.touched && meta.error,
      'text-red-900': meta.touched && meta.error,
      'placeholder-red-300': meta.touched && meta.error,
      'focus-outline-nodes': meta.touched && meta.error,
      'focus:ring-red-500': meta.touched && meta.error,
      'focus:border-red-500': meta.touched && meta.error,
    },
    {
      'opacity-50': disabled,
      'cursor-not-allowed': disabled,
    }
  );
  const inputContainerClassName = classNames(
    'flex flex-col gap-1',
    containerClassName
  );
  useFocusOnError(inputRef, name);

  return (
    <>
      <div className={inputContainerClassName}>
        {label && (
          <label
            htmlFor={field.name}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}
        <div className="flex rounded-md shadow-sm">
          <div className="relative w-full">
            {tooltip && (
              <span className="absolute z-10 right-0 top-1/3 mr-1 ">
                {' '}
                <QuestionMarkCircleIcon
                  data-for={`${field.name}-helper`}
                  data-tip={tooltip}
                  className="h-4 w-4 text-gray-400 ml-1 focus:outline-0"
                  aria-hidden="true"
                />
              </span>
            )}
            <DatePicker
              name={field.name}
              id={field.name}
              className={className}
              onChange={(date): void => setFieldValue(field.name, date)}
              onBlur={field.onBlur}
              disabled={disabled}
              selected={(field.value && new Date(field.value)) || null}
              minDate={minDate || null}
              maxDate={maxDate || null}
              autoComplete="off"
              ref={(datePickerRef): void => {
                // Missing "input" prop in react-datepicker library
                const customDatePickerRef =
                  datePickerRef as typeof datePickerRef & {
                    input: HTMLInputElement;
                  };
                if (inputRef && customDatePickerRef?.input) {
                  inputRef.current = customDatePickerRef.input;
                }
              }}
            />
            {meta.touched && meta.error && <InputErrorIcon />}
          </div>
          <span
            className="inline-flex items-center py-[9px] pr-[17px] pl-[15px] rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"
            data-for={`${field.name}-helper`}
            data-tip={tooltip}
          >
            <CalendarIcon
              className="h-4 w-4 headraceBlack-700 stroke-2"
              aria-hidden="true"
            />
          </span>
          <ReactTooltip
            id={`${field.name}-helper`}
            place="top"
            effect="solid"
            html
            arrowColor="#30323D"
            className="!opacity-100 !bg-headraceBlack-800 !px-[12px]"
          />
        </div>
        {description && (
          <p className="mt-2 text-sm text-gray-500">{description}</p>
        )}
      </div>
      <ErrorText
        id={`${field.name}-error`}
        error={meta.error || null}
        touched={meta.touched}
      />
    </>
  );
};

export default DatePickerField;
