import type { VFC } from 'react';

import CardWithHeader from '../CardWithHeader';
import ErrorMessageBox from '../ErrorMessageBox';
import RoleMetrics from '../Role/RoleMetrics';

export interface GlobalSubmissionsProps {
  error?: string | null;
  loading: boolean;
  data: {
    inReviewCount?: number;
    interviewingCount: number;
    hiredCount: number;
  };
}

const GlobalSubmissions: VFC<GlobalSubmissionsProps> = ({
  error,
  loading,
  data,
}) => {
  if (error) return <ErrorMessageBox error={error} />;
  return (
    <CardWithHeader title="Global Submissions">
      <div className="grid grid-cols-1 gap-5">
        <RoleMetrics
          className="col-span-full !px-6 !py-4 xl:px-6 text-headraceBlack-700 ring-1 ring-gray-200"
          title="Candidates in review"
          count={data.inReviewCount ?? 0}
          loading={loading}
          countClassName="font-bold text-2xl pr-4"
          titleClassName="font-normal text-base"
        />
        <RoleMetrics
          className="col-span-full !px-6 !py-4 xl:px-6 text-headraceBlack-700 ring-1 ring-gray-200"
          title="Candidates interviewing"
          count={data.interviewingCount}
          loading={loading}
          countClassName="font-bold text-2xl pr-4"
          titleClassName="font-normal text-base"
        />
        <RoleMetrics
          className="col-span-full !px-6 !py-4 xl:px-6 text-headraceBlack-700 ring-1 ring-gray-200"
          title="Candidates hired"
          count={data.hiredCount}
          loading={loading}
          countClassName="font-bold text-2xl pr-4"
          titleClassName="font-normal text-base"
        />
      </div>
    </CardWithHeader>
  );
};

export default GlobalSubmissions;
