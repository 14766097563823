import type { ApolloError } from '@apollo/client';
import type { CandidateActivityHistoryRejectionMeta } from '@headrace/types';
import { CandidateActivityHistoryActivityType } from '@headrace/types';
import { CheckCircleIcon } from '@heroicons/react/outline';
import {
  CheckCircleIcon as SolidCheckCircleIcon,
  StarIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import {
  MailInboxArrowDown,
  PersonAvailable,
} from '@styled-icons/fluentui-system-filled';
import { format } from 'date-fns-tz';
import Link from 'next/link';
import type { VFC } from 'react';
import { useMemo } from 'react';

import type { OverviewListItem } from '../../OverviewList';
import OverviewList from '../../OverviewList';

const activityTypeChangeOptions = (
  historyItem: CandidateActivityTypeLog,
  app: 'recruiter' | 'employer'
): {
  title: string | JSX.Element;
  icon?: JSX.Element;
  showHyperLinks?: boolean;
} => {
  switch (historyItem.activityType) {
    case CandidateActivityHistoryActivityType.SUBMITTED: {
      let recruiter = historyItem.candidate?.recruiter;
      if (historyItem.candidate?.collaboration?.primaryRecruiter) {
        recruiter = historyItem.candidate.collaboration.primaryRecruiter;
      }
      return {
        title:
          historyItem.candidate && recruiter ? (
            <>
              Candidate submitted by{' '}
              {historyItem.showHyperLinks ? (
                <Link href={`/profiles/${recruiter.id}`} passHref>
                  <a className="text-blue-500 font-medium">
                    {recruiter.user.name}
                    {recruiter.agency.name && ` | ${recruiter.agency.name}`}
                  </a>
                </Link>
              ) : (
                <>
                  {recruiter.user.name}
                  {recruiter.agency.name && ` | ${recruiter.agency.name}`}
                </>
              )}
            </>
          ) : (
            'Candidate submitted'
          ),
        icon: (
          <CheckCircleIcon width={32} height={32} className="text-gray-400" />
        ),
      };
    }
    case CandidateActivityHistoryActivityType.IN_PROCESS:
      return {
        title: 'Candidate accepted',
        icon: (
          <SolidCheckCircleIcon
            width={32}
            height={32}
            className="text-green-400"
          />
        ),
      };
    case CandidateActivityHistoryActivityType.CANDIDATE_INTERESTED:
      return {
        title: 'Candidate interested',
        icon: (
          <PersonAvailable width={32} height={32} className="text-green-600" />
        ),
      };
    case CandidateActivityHistoryActivityType.OFFER_ACCEPTED:
      return {
        title: 'Hired',
        icon: (
          <StarIcon
            width={32}
            height={32}
            className="text-headraceYellow-700"
          />
        ),
      };
    case CandidateActivityHistoryActivityType.REJECTED:
      return {
        title: app === 'employer' ? 'Candidate passed' : 'Candidate rejected',
        icon: <XCircleIcon width={32} height={32} className="text-red-500" />,
      };
    case CandidateActivityHistoryActivityType.EXPIRED:
      return {
        title: 'Candidate expired',
        icon: <XCircleIcon width={32} height={32} className="text-red-500" />,
      };
    case CandidateActivityHistoryActivityType.SUBMITTED_TO_RECRUITER:
      return {
        title: historyItem.candidate ? (
          <>
            Candidate submitted by{' '}
            <>
              {historyItem.candidate.recruiter?.user.name}
              {historyItem.candidate.recruiter?.agency.name &&
                ` | ${historyItem.candidate.recruiter.agency.name}`}{' '}
              to{' '}
              {historyItem.candidate.collaboration?.primaryRecruiter.user.name}
              {historyItem.candidate.collaboration?.primaryRecruiter.agency
                .name &&
                ` | ${historyItem.candidate.collaboration?.primaryRecruiter.agency.name}`}{' '}
            </>
          </>
        ) : (
          'Candidate submitted'
        ),
        icon: (
          <CheckCircleIcon width={32} height={32} className="text-gray-400" />
        ),
      };
    case CandidateActivityHistoryActivityType.DRAFT:
      return {
        title: 'Candidate imported',
        icon: (
          <MailInboxArrowDown
            width={32}
            height={32}
            className="text-gray-700"
          />
        ),
      };
    default:
      return { title: '' };
  }
};

const candidateLogProps = (
  historyItem: CandidateActivityTypeLog,
  app: 'recruiter' | 'employer'
): OverviewListItem => {
  const description = format(
    new Date(historyItem.createdAt),
    "MMMM dd, yyyy 'at' h:mmaaa z",
    {
      locale: {
        code: 'en-US',
      },
    }
  );
  if (
    historyItem.activityType !== CandidateActivityHistoryActivityType.REJECTED
  )
    return {
      ...activityTypeChangeOptions(historyItem, app),
      description,
      activityType: historyItem.activityType,
    };

  return {
    ...activityTypeChangeOptions(historyItem, app),
    description,
    activityType: historyItem.activityType,
    activityMeta: historyItem.activityMeta,
  };
};

interface CandidateActivityTypeLog {
  id: string;
  createdAt: Date;
  activityType: CandidateActivityHistoryActivityType;
  activityMeta?: CandidateActivityHistoryRejectionMeta | null | undefined;
  showHyperLinks?: boolean;
  candidate?: {
    recruiter?: {
      id: string;
      agency: { name?: string | null };
      user: { name: string };
    } | null;
    collaboration?: {
      primaryRecruiter: {
        id: string;
        agency: { name?: string | null };
        user: { name: string };
      };
    } | null;
  };
}

interface CandidateHistoryListProps {
  candidateHistoryData?: CandidateActivityTypeLog[];
  loading: boolean;
  error?: ApolloError;
  showHyperLinks?: boolean;
  app: 'recruiter' | 'employer';
}

const CandidateHistoryList: VFC<CandidateHistoryListProps> = (props) => {
  const {
    candidateHistoryData,
    loading,
    error,
    showHyperLinks = true,
    app,
  } = props;

  const candidateHistory = useMemo<OverviewListItem[]>(() => {
    const historyList: OverviewListItem[] = [];
    if (candidateHistoryData?.length) {
      historyList.push(
        ...candidateHistoryData.map((historyItem) =>
          candidateLogProps({ ...historyItem, showHyperLinks }, app)
        )
      );
    }
    return historyList;
  }, [app, candidateHistoryData, showHyperLinks]);

  return (
    <OverviewList
      className="mt-4"
      title="Candidate submission history"
      emptyTitle="Nothing here"
      emptyDescription="Any status change for this candidate will be reflected here."
      items={candidateHistory}
      loading={loading}
      error={error}
      maxItems={20}
    />
  );
};

export default CandidateHistoryList;
