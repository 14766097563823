import { PencilIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import Button from './Button';
import CardWithHeader from './CardWithHeader';
import LoadingSpinner from './LoadingSpinner';

interface EditCardProps {
  title: string;
  description?: string;
  saveAction: () => void;
  cancelAction?: () => void;
  editStateComponent: React.ReactNode;
  viewStateComponent: React.ReactNode;
  loading?: boolean;
  editMode?: boolean;
  activeEditMode?: boolean;
  closeOnSave?: boolean;
  onEdit?: () => void;
  removeChildrenXPadding?: boolean;
  removeChildrenYPadding?: boolean;
  saveDisabled?: boolean;
  refetchLoading?: boolean;
  rightContentViewMode?: React.ReactNode;
  className?: string;
  childrenClassName?: string;
}

const EditCard: VFC<EditCardProps> = ({
  title,
  description,
  saveAction,
  cancelAction,
  editStateComponent,
  viewStateComponent,
  loading,
  refetchLoading,
  editMode,
  activeEditMode,
  closeOnSave = true,
  onEdit,
  removeChildrenXPadding,
  removeChildrenYPadding,
  saveDisabled,
  rightContentViewMode,
  className,
  childrenClassName,
}) => {
  const [edit, setEdit] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    if (refetchLoading == null) setLoadingData(loading || false);
    else if (loading) setLoadingData(loading);
  }, [loading, refetchLoading]);

  useEffect(() => {
    if (refetchLoading === false) setLoadingData(refetchLoading);
  }, [refetchLoading]);

  useEffect(() => {
    if (editMode && activeEditMode != null) setEdit(activeEditMode);
  }, [activeEditMode, editMode]);

  const rightContent = useMemo(() => {
    const cancelActionWithEditState = (): void => {
      if (cancelAction) cancelAction();
      setEdit(false);
    };

    const saveActionWithEditState = (): void => {
      saveAction();
      if (closeOnSave) setEdit(false);
      if (loading != null && refetchLoading != null) {
        setLoadingData(true);
      }
    };
    if (edit) {
      return (
        <div className="h-5 flex items-center gap-4">
          <Button
            buttonType="secondary"
            className="!py-2"
            onClick={cancelActionWithEditState}
          >
            Cancel
          </Button>
          <Button
            className="!py-2"
            onClick={saveActionWithEditState}
            loading={loading}
            disabled={saveDisabled}
          >
            Save
          </Button>
        </div>
      );
    }
    return (
      <div className="flex items-center gap-2">
        <button
          className="py-1"
          type="button"
          onClick={(): void => {
            setEdit(true);
            if (onEdit) onEdit();
          }}
        >
          <PencilIcon className="w-5 text-blue-500" />
        </button>
        {rightContentViewMode}
      </div>
    );
  }, [
    cancelAction,
    closeOnSave,
    edit,
    loading,
    onEdit,
    refetchLoading,
    rightContentViewMode,
    saveAction,
    saveDisabled,
  ]);

  const viewStateComponentWithLoading = useMemo(
    () =>
      loadingData ? (
        <div className="h-full min-h-[128px] rounded-md text-left gap-4 flex items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        viewStateComponent
      ),
    [viewStateComponent, loadingData]
  );

  return (
    <CardWithHeader
      title={title}
      description={description}
      rightContent={editMode ? rightContent : undefined}
      headerClassName="gap-3"
      className={classNames(className, 'h-fit')}
      removeChildrenXPadding={removeChildrenXPadding}
      removeChildrenYPadding={removeChildrenYPadding}
      childrenClassName={childrenClassName}
    >
      {edit ? editStateComponent : viewStateComponentWithLoading}
    </CardWithHeader>
  );
};

export default EditCard;
