import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
  color?: string;
}

const RemoteWorkIcon: VFC<Props> = ({
  className,
  width = '38',
  height = '35',
  color = '#FFA300',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 38 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.2158 0.53418C31.3503 0.53418 37.1343 6.31823 37.1343 13.4527H34.9812C34.9812 7.50716 30.1613 2.68726 24.2158 2.68726V0.53418ZM13.6932 7.27893C13.2808 6.89828 12.6451 6.89828 12.2328 7.27893L3.031 15.7729C2.81014 15.9766 2.68465 16.2634 2.68465 16.5639V30.9371C2.68465 31.5315 3.16655 32.0136 3.76119 32.0136H22.1645C22.7591 32.0136 23.241 31.5315 23.241 30.9371V16.5639C23.241 16.2633 23.1154 15.9765 22.8947 15.7729L13.6932 7.27893ZM10.7725 5.69693C12.0096 4.55494 13.9165 4.55494 15.1535 5.69693L24.3554 14.1909C25.0176 14.8021 25.3943 15.6625 25.3943 16.5639V30.9371C25.3943 32.7206 23.9485 34.1667 22.1646 34.1667H3.76137C1.97766 34.1667 0.531738 32.7206 0.531738 30.9371V16.5639C0.531738 15.6625 0.908361 14.8021 1.57077 14.1909L10.7725 5.69693ZM17.7975 17.7589H8.12847V23.1417H17.7975V17.7589ZM19.9506 23.2902V15.6059H5.97538V23.1417H5.93434L4.79742 27.4433L4.22854 29.5964H21.6974L21.1011 27.4433L19.9506 23.2902ZM7.59235 25.295H18.2716L18.8669 27.4435H7.02454L7.59235 25.295ZM9.14408 19.9122V20.9888H10.2206V19.9122H9.14408ZM11.2972 20.9888V19.9122H12.3737V20.9888H11.2972ZM13.4502 19.9122V20.9888H14.5268V19.9122H13.4502ZM15.6033 19.9122V20.9888H16.6799V19.9122H15.6033ZM24.2157 9.14682C26.594 9.14682 28.5218 11.0748 28.5218 13.453H26.3687C26.3687 12.2639 25.4047 11.2999 24.2157 11.2999V9.14682ZM32.828 13.453C32.828 8.69639 28.972 4.84065 24.2157 4.84065V6.99373C27.7831 6.99373 30.6749 9.88559 30.6749 13.453H32.828Z"
      fill={color}
    />
  </svg>
);

export default RemoteWorkIcon;
