import classNames from 'classnames';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useEffect, useMemo } from 'react';

interface AccordionProps {
  children: React.ReactNode;
  defaultPanel?: string;
  allwaysOpen?: boolean;
}

interface AccordionContextProps {
  selected?: string;
  toggleItem?: (id: string) => void;
  allwaysOpen?: boolean;
}

interface AccordionItemProps extends React.HTMLAttributes<HTMLElement> {
  toggle: string;
  children: React.ReactNode;
  className?: string;
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
}

interface AccordionPanelProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  id: string;
  itemClassName?: string;
  label: React.ReactNode;
  activeItem?: boolean;
}

const style = {
  item: 'focus:outline-none  p-3 w-full hover:bg-gray-100 text-gray-500',
  panel: `overflow-hidden relative md:overflow-x-hidden transition-height ease duration-300 text-gray-600  border-gray-200`,
};

const AngleUpIcon = (): JSX.Element => (
  <svg fill="none" strokeWidth="0" viewBox="0 0 17 9" className="h-2 w-4">
    <path
      d="M1.5 8L8.5 0.999999L15.5 8"
      stroke="#6B7280"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const AngleDownIcon = (): JSX.Element => (
  <svg fill="none" strokeWidth="0" viewBox="0 0 17 9" className="h-2 w-4">
    <path
      d="M15.5 1L8.5 8L1.5 1"
      stroke="#6B7280"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/* Logic */
const Context = React.createContext<AccordionContextProps>({});

const Accordion: FC<AccordionProps> = ({
  children,
  defaultPanel,
  allwaysOpen = false,
}) => {
  const [selected, setSelected] = React.useState<string>(defaultPanel || '');

  const toggleItem = React.useCallback(
    (id: string) => {
      if (!allwaysOpen) {
        setSelected((prevState) => (prevState !== id ? id : ''));
      }
    },
    [allwaysOpen]
  );

  const accordionProviderValue = useMemo(
    () => ({ selected, toggleItem, allwaysOpen }),
    [selected, toggleItem, allwaysOpen]
  );

  return (
    <Context.Provider value={accordionProviderValue}>
      <div className="border-gray-200 border divide-y rounded-t-xl">
        {children}
      </div>
    </Context.Provider>
  );
};

// custom hook to consume all accordion values
const useAccordion = (): AccordionContextProps => React.useContext(Context);

export const AccordionItem: FC<AccordionItemProps> = ({
  className,
  toggle,
  children,
  active,
  setActive,
}) => {
  const { selected, toggleItem, allwaysOpen } = useAccordion();
  const classNameAccordionItem = classNames(
    style.item,
    className,
    'bg-gray-50 text-headraceBlack-700 font-medium flex items-center justify-between'
  );
  const getIcon = (): JSX.Element => {
    if (allwaysOpen) {
      if (active) return <AngleUpIcon />;
      return <AngleDownIcon />;
    }
    if (selected === toggle) {
      return <AngleUpIcon />;
    }
    return <AngleDownIcon />;
  };
  return (
    <button
      type="button"
      onClick={(): void => {
        if (!allwaysOpen && toggleItem) {
          toggleItem(toggle);
        } else {
          setActive(!active);
        }
      }}
      className={classNameAccordionItem}
    >
      {children}
      <span className="float-right">{getIcon()}</span>
    </button>
  );
};

export const AccordionPanel: FC<AccordionPanelProps> = ({
  children,
  id,
  itemClassName,
  label,
  activeItem = false,
}) => {
  const { selected, allwaysOpen } = useAccordion();
  const ref = React.useRef<HTMLDivElement>(null);
  const [active, setActive] = React.useState(false);
  let inlineStyle =
    selected === id ? { display: 'block' } : { display: 'none' };

  if (allwaysOpen) {
    inlineStyle = active ? { display: 'block' } : { display: 'none' };
  }

  useEffect(() => {
    if (allwaysOpen) {
      setActive(activeItem);
    }
  }, [activeItem, allwaysOpen]);

  return (
    <>
      <AccordionItem
        toggle={id}
        className={itemClassName}
        active={active}
        setActive={setActive}
      >
        {label}
      </AccordionItem>
      <div ref={ref} id={id} className={style.panel} style={inlineStyle}>
        {children}
      </div>
    </>
  );
};

export default Accordion;
