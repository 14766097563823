import { type VFC, useState } from 'react';

import type { RichTextEditorProps } from '../forms/fields/formik/RichTextEditor/OnlyReadRichTextEditor';
import OnlyReadRichTextEditor from '../forms/fields/formik/RichTextEditor/OnlyReadRichTextEditor';
import RichTextModal from './RichTextModal';

interface RichTextWithModalProps extends RichTextEditorProps {
  showMoreText?: string;
  maxTextLength?: number;
  maxHtmlElementHeight?: number;
}

const RichTextWithModal: VFC<RichTextWithModalProps> = ({
  name,
  json,
  value,
  className,
  maxTextLength,
  maxHtmlElementHeight,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [longText, setLongText] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-1">
        <OnlyReadRichTextEditor
          name={name}
          json={json}
          value={value}
          className={className}
          isLongText={(boolean): void => {
            setLongText(boolean);
          }}
          hideShowMore
          maxTextLength={maxTextLength}
          maxHtmlElementHeight={maxHtmlElementHeight}
        />
        {longText && (
          <div className="flex items-end flex-col text-blue-500 pr-4">
            <button type="button" onClick={(): void => setShowMore(!showMore)}>
              Show more
            </button>
          </div>
        )}
      </div>
      <RichTextModal
        open={showMore}
        onClose={(): void => setShowMore(false)}
        name={name}
        json={json}
        value={value}
      />
    </>
  );
};

export default RichTextWithModal;
