import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { MultiValue, SingleValue } from 'react-select';

import { BasicSelect } from './forms';
import type { NavigationItem } from './TailwindLayout';

export interface OptionsProps {
  label: string;
  value: string;
}

interface NavigationOption extends NavigationItem {
  helperComponent?: JSX.Element | null;
  disabled?: boolean;
}

export interface SimpleTabsProps {
  navigationOptions: NavigationOption[];
  className?: string;
}

const SimpleTabs: FC<SimpleTabsProps> = ({
  navigationOptions,
  className,
  children,
}) => {
  const router = useRouter();

  const isCurrentTab = useCallback(
    (navItem: NavigationItem): boolean => {
      if (navItem.href === router.asPath) return true;

      if (navItem.children) return navItem.children.includes(router.asPath);
      return false;
    },
    [router.asPath]
  );
  const handleTabSelectChange = useCallback(
    async (
      newValue: MultiValue<OptionsProps> | SingleValue<OptionsProps>
    ): Promise<void> => {
      const url = newValue;
      if (url && 'value' in url) {
        await router.push(url.value, undefined, { shallow: true });
      }
    },
    [router]
  );

  const optionsSelect = navigationOptions
    .filter((i) => !i.disabled)
    .map((item) => ({
      value: item.href,
      label: item.name,
    }));

  const valueSelect = useMemo((): OptionsProps => {
    const tab = optionsSelect.find((item) => item.value === router.pathname);
    return tab || { value: '', label: '' };
  }, [optionsSelect, router.pathname]);

  const linkWrapper = (
    disabled: boolean,
    button: React.ReactNode,
    href: string
  ): React.ReactNode => {
    if (disabled) {
      return button;
    }
    return (
      <Link href={href} passHref shallow key={href}>
        {button}
      </Link>
    );
  };

  return (
    <div className="flex flex-col gap-6">
      <div
        className={classNames(
          'bg-white rounded-lg shadow overflow-hidden',
          className
        )}
      >
        {/* Mobile Select Tabs */}
        <div
          className={classNames('border-b border-gray-200 mb-4 p-2', {
            'lg:hidden': navigationOptions.length > 3,
            hidden: navigationOptions.length <= 3,
          })}
        >
          <BasicSelect
            id="selected-tab"
            value={valueSelect}
            options={optionsSelect}
            onChange={handleTabSelectChange}
            isSearchable={false}
          />
        </div>
        {/* Desktop Navigation Tabs */}
        <div
          className={classNames(
            'flex justify-between items-center w-full border-b border-gray-200',
            {
              'hidden lg:block': navigationOptions.length > 3,
            }
          )}
        >
          <nav className="-mb-px flex w-full">
            {navigationOptions.map((tab) =>
              linkWrapper(
                tab.disabled || false,
                <a
                  key={tab.href}
                  className={classNames(
                    isCurrentTab(tab)
                      ? 'border-headraceYellow-700 text-gray-900'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 font-medium text-sm py-4 outline-none w-full text-center',
                    {
                      'cursor-not-allowed': tab.disabled,
                    }
                  )}
                >
                  <div className="flex justify-center items-center gap-1">
                    {tab.name}
                    {tab.helperComponent}
                  </div>
                </a>,
                tab.href
              )
            )}
          </nav>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default SimpleTabs;
