export type PrimitiveType = string | symbol | number | boolean;

// eslint-disable-next-line import/prefer-default-export
export function isPrimitive(value: unknown): value is PrimitiveType {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    typeof value === 'symbol'
  );
}
