import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import type { VFC } from 'react';
import { Fragment } from 'react';

import StatusBadge from '../../../StatusBadge';

export enum SelectStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  INACTIVE = 'INACTIVE',
}

interface StatusOption {
  value: string;
  label: string;
  status: SelectStatus;
}

interface Props {
  defaultOption: string;
  options: StatusOption[];
  name: string;
  label?: string;
  disabled?: boolean;
}

const StatusSelect: VFC<Props> = ({
  options,
  defaultOption,
  name,
  label,
  disabled,
}) => {
  const [field] = useField<string>(name);
  const { setFieldValue } = useFormikContext();
  const defaultValue = options.find((option) => option.value === defaultOption);

  const onChangeSelect = (newValue: StatusOption | null): void => {
    if (newValue) {
      setFieldValue(name, newValue.value);
    }
  };

  const valueSelected =
    options.find((item) => item.value === field.value) ||
    defaultValue ||
    options[0];

  const statusBadge = (status: SelectStatus, text: string): JSX.Element => {
    let className = 'fill-red-700 text-red-700 bg-red-100 !border-red-100';
    switch (status) {
      case SelectStatus.PAUSED:
        className =
          'fill-headraceYellow-700 text-headraceYellow-700 bg-orange-100 !border-orange-100';
        break;
      case SelectStatus.ACTIVE: {
        className =
          'fill-green-500 text-green-700 bg-green-200 !border-green-200';
        break;
      }
      default:
        break;
    }
    return (
      <StatusBadge label={text} className={classNames(className, '!text-sm')} />
    );
  };

  return (
    <Listbox
      value={valueSelected}
      onChange={onChangeSelect}
      disabled={disabled}
    >
      {({ open }): JSX.Element => (
        <>
          {label && (
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
              {label}
            </Listbox.Label>
          )}
          <div className="relative mt-2">
            <Listbox.Button
              className={classNames(
                'relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6',
                {
                  'cursor-not-allowed opacity-50': disabled,
                }
              )}
            >
              {valueSelected
                ? statusBadge(valueSelected.status, valueSelected.label)
                : null}
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }): string =>
                      classNames(
                        active ? 'text-white bg-gray-100' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected: selectedOption }): JSX.Element => (
                      <>
                        <div className="flex items-center">
                          {statusBadge(option.status, option.label)}
                        </div>

                        {selectedOption ? (
                          <span
                            className={classNames(
                              'text-headraceYellow-700',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default StatusSelect;
