import type { CardRecruiterDataType } from '@headrace/types';
import { CheckCircleIcon, UserIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Image from 'next/image';
import type { MouseEvent } from 'react';
import { useMemo } from 'react';

import Button from '../Button';
import ContactLinks from './ContactLinks';

interface InvitesAction {
  action: (recruiterId: string) => void;
}

interface ContactCardProps {
  dataRecruiter: CardRecruiterDataType;
  onClick?: (id: string) => void;
  recruiterSelected?: (dataRecruiter: CardRecruiterDataType) => void;
  icon?: JSX.Element;
  header?: string;
  isRecommended?: boolean;
  invitesActions?: {
    inviteMarketplace: InvitesAction;
    inviteDirect: InvitesAction;
  };
  isInvite?: boolean;
  contactLinks?: boolean;
  isPaused?: boolean;
}

const ContactCard: React.VFC<ContactCardProps> = ({
  dataRecruiter,
  onClick = (): void => {},
  recruiterSelected,
  icon = (
    <CheckCircleIcon className="w-[16px] h-[16px] text-headraceYellow-700" />
  ),
  header = 'Recommended',
  isRecommended = false,
  invitesActions,
  isInvite = false,
  contactLinks = false,
  isPaused = false,
}) => {
  const inviteButton = useMemo(() => {
    const onClickEvent = (evt: MouseEvent<HTMLButtonElement>): void => {
      evt.preventDefault();
      evt.stopPropagation();
    };
    if (isInvite) {
      if (dataRecruiter.isInvitedToMarketplaceRole) {
        return <Button disabled>Invite sent</Button>;
      }
      return (
        <Button
          className="w-full"
          onClick={(e): void => {
            onClickEvent(e);
            if (recruiterSelected && invitesActions) {
              recruiterSelected(dataRecruiter);
              invitesActions.inviteMarketplace.action(
                dataRecruiter.recruiter.id
              );
            }
          }}
          disabled={isPaused}
        >
          Invite to role
        </Button>
      );
    }
    if (dataRecruiter.isRequestedToDirect) {
      return <Button disabled>Request sent</Button>;
    }
    return (
      <Button
        className="w-full"
        onClick={(e): void => {
          onClickEvent(e);
          if (recruiterSelected && invitesActions) {
            recruiterSelected(dataRecruiter);
            invitesActions.inviteDirect.action(dataRecruiter.recruiter.id);
          }
        }}
      >
        Request a meeting
      </Button>
    );
  }, [dataRecruiter, invitesActions, isPaused, isInvite, recruiterSelected]);
  return (
    <div
      aria-hidden="true"
      onClick={(): void => {
        if (onClick) {
          onClick(dataRecruiter.recruiter.id);
        }
      }}
      key={dataRecruiter.id}
      className={classNames(
        'flex flex-col max-h-[478px] px-2 pb-10 text-center bg-white rounded-lg divide-y divide-gray-200 border-solid border-b-[10px] border-headraceYellow-700 cursor-pointer',
        {
          'pt-10': !isRecommended,
        }
      )}
    >
      <div className="flex-1 flex flex-col">
        {isRecommended && (
          <div className="flex justify-center items-center pt-2">
            {icon}
            <span className="ml-2 text-lg text-gray-500 font-bold h-[28px]">
              {header}
            </span>
          </div>
        )}
        {dataRecruiter.recruiter.user.photoUrl ? (
          <div className="flex items-center mb-4 justify-center">
            <Image
              className="w-[140px] h-[140px] flex-shrink-0 mx-auto rounded-full"
              src={dataRecruiter.recruiter.user.photoUrl}
              width={140}
              height={140}
              objectFit="contain"
            />
          </div>
        ) : (
          <UserIcon className="w-[140px] h-[140px] mb-4 text-gray-300 flex-shrink-0 mx-auto rounded-full" />
        )}
        {contactLinks && (
          <div className="mb-6">
            <ContactLinks
              linkedIn={dataRecruiter.recruiter.linkedIn}
              email={dataRecruiter.recruiter.user.email}
              website={dataRecruiter.recruiter.agency.website}
              phone={dataRecruiter.recruiter.user.phoneNumber}
            />
          </div>
        )}

        <h3 className="text-headraceBlack-900 text-lg font-bold">
          {dataRecruiter.recruiter.user.name}
        </h3>
        <span className="w-[30px] my-1 mx-auto border-solid border-b-2 border-headraceYellow-700" />
        <h3 className="text-gray-500 leading-[26px] mb-4 text-base">
          {dataRecruiter.recruiter.agency.name}
        </h3>
        {recruiterSelected && !dataRecruiter.isPrimaryAgency
          ? inviteButton
          : null}
        <Button
          buttonType={recruiterSelected ? 'secondary' : 'primary'}
          onClick={(): void => onClick(dataRecruiter.recruiter.id)}
          className="mt-2"
        >
          View profile
        </Button>
      </div>
    </div>
  );
};

export default ContactCard;
