import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';

import EmptyStateIllustration from '../EmptyStateIllustration';
import TablePagination from './Pagination';
import TableBody from './TableBody';
import TableEmptyState from './TableEmptyState';
import type { FilterProps } from './TableFilter';
import type { SortProps } from './TableHeader';
import TableHeader from './TableHeader';
import type { Column, MinTableData } from './TableTd';

interface Props<D extends MinTableData> {
  data: D[];
  columns: Column<D>[];
  emptyTitle?: string;
  emptySubtitle?: string;
  className?: string;
  containerClassName?: string;
  tableClassName?: string;
  entriesPerPage?: number;
  fixedHeight?: number;
  emptyClassName?: string;
  tableFixed?: boolean;
  tableFixedDesktop?: boolean;
  onClickRow?: (id?: string) => void;
  noRowPointer?: (id?: string) => boolean;
  stickyHeader?: boolean;
  emptyCards?: EmptyMessage[];
  emptyCardsContainerClassName?: string;
  borderClassName?: string;
  classNameRow?: (id?: string) => string;
  filterOnlyFirstTime?: boolean;
  labelShowTotalRows?: string;
}

export interface EmptyMessage {
  emptyTitle: string;
  emptydescription: string;
  emptyImage: string;
  emptyTextButton?: string;
  emptyActionButton?: () => void;
  descriptionClassName?: string;
}

const Table = <D extends MinTableData>(props: Props<D>): JSX.Element => {
  const {
    data,
    columns,
    emptyTitle = 'No data',
    className = '',
    containerClassName = '',
    tableClassName = '',
    emptySubtitle,
    entriesPerPage,
    fixedHeight,
    emptyClassName,
    tableFixed = false,
    tableFixedDesktop = false,
    onClickRow,
    stickyHeader = false,
    emptyCards,
    emptyCardsContainerClassName,
    noRowPointer = (): boolean => false,
    classNameRow = (): string => '',
    borderClassName = '',
    filterOnlyFirstTime,
    labelShowTotalRows,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [filterState, setFilterState] = useState<FilterProps[]>([]);
  const [filteredData, setFilteredData] = useState<D[]>([]);
  const [columnSort, setColumnSort] = useState<SortProps[]>(
    columns.map((column) => ({ columnName: column.header, type: 'none' }))
  );
  const [sortedData, setSortedData] = useState<D[]>([]);
  const [paginatedData, setPaginatedData] = useState<D[]>([]);
  const [totalPages, setTotalPages] = useState(
    Math.floor(entriesPerPage ? Math.ceil(data.length / entriesPerPage) : 1)
  );
  const [alreadyFiltered, setAlreadyFiltered] = useState<boolean>(false);

  const changeToPage = (index: number): void => {
    setCurrentPage(index);
  };
  const selectColumnToFilter = (newFilterState: FilterProps): void => {
    setFilterState([
      ...filterState.filter(
        (column) => column.columnName !== newFilterState.columnName
      ),
      newFilterState,
    ]);

    setAlreadyFiltered(false);
  };
  const selectColumnToSort = (columndId: string): void => {
    const selectedColumn = columnSort.find(
      (column) => column.columnName === columndId
    );
    if (!selectedColumn) return;
    let sortType: SortProps['type'] = 'down';
    if (selectedColumn.type === 'none') sortType = 'down';
    if (selectedColumn.type === 'up') sortType = 'down';
    if (selectedColumn.type === 'down') sortType = 'up';
    setColumnSort(
      columnSort.map((column) => ({
        ...column,
        type: column.columnName === columndId ? sortType : 'none',
      }))
    );
  };

  const calculateTotalPages = useCallback(
    (dataLength: number): void => {
      if (!entriesPerPage) return;
      const newTotalPages = Math.ceil(dataLength / entriesPerPage);

      // We need to handle the case when the current page is greater than the new total pages
      if (currentPage >= newTotalPages) setCurrentPage(newTotalPages);

      setTotalPages(newTotalPages);
    },
    [currentPage, entriesPerPage]
  );

  useEffect(() => {
    if (filterState.length === 0) {
      setFilterState(
        columns.map((column) => ({
          columnName: column.header,
          selectedValues: [],
        }))
      );
      return;
    }

    let hasBeenFiltered = false;

    const activeFilters = filterState.filter(
      (filter) => filter.selectedValues.length > 0
    );

    const newFilteredData = data.filter((row) => {
      for (let index = 0; index < activeFilters.length; index += 1) {
        const filter = activeFilters[index];
        if (filter.selectedValues.length === 0) return true;

        hasBeenFiltered = true;

        const column = columns.find((col) => col.header === filter.columnName);
        if (column && column.filterData) {
          const dataToFilter = column.filterData(row);
          if (typeof dataToFilter === 'string') {
            const valueInFilter = filter.selectedValues.some((filterValue) => {
              if (column.filterExactMatch) return filterValue === dataToFilter;
              return dataToFilter.includes(filterValue);
            });
            if (!valueInFilter) return false;
          } else if (Array.isArray(dataToFilter)) {
            const valueInFilter = dataToFilter.some((value) =>
              filter.selectedValues.some((filterValue) => {
                if (column.filterExactMatch) return filterValue === value;
                return value.includes(filterValue);
              })
            );
            if (!valueInFilter) return false;
          }
        }
      }
      return true;
    });

    if (filterOnlyFirstTime && alreadyFiltered) return;

    if (!alreadyFiltered) {
      setAlreadyFiltered(hasBeenFiltered);
    }

    calculateTotalPages(newFilteredData.length);
    setFilteredData(newFilteredData);

    // Remove the dependency of columns to avoid loading the data again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    filterState,
    setFilteredData,
    calculateTotalPages,
    alreadyFiltered,
    filterOnlyFirstTime,
  ]);

  useEffect(() => {
    let newSortedData = [...filteredData];
    const selectedColumnSort = columnSort.find((col) => col.type !== 'none');
    if (selectedColumnSort) {
      const column = columns.find(
        (c) => c.header === selectedColumnSort.columnName
      );
      if (column && column.sortFunction) {
        newSortedData = newSortedData.sort((prevData, nextData) => {
          if (!column.sortFunction) return 0;
          return column.sortFunction(prevData, nextData);
        });
        if (selectedColumnSort.type === 'down') newSortedData.reverse();
      }
    }
    setSortedData(newSortedData);
    // Remove the dependency of columns to avoid loading the data again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnSort, filteredData]);
  useEffect(() => {
    if (!entriesPerPage) {
      setPaginatedData(sortedData);
      return;
    }
    const start = (currentPage - 1) * entriesPerPage;
    const end = currentPage * entriesPerPage;
    setPaginatedData(sortedData.slice(start, end));
  }, [
    currentPage,
    entriesPerPage,
    sortedData,
    setSortedData,
    setPaginatedData,
  ]);

  const getEmptyComponent = useMemo(() => {
    if (emptyCards) {
      return (
        <tbody>
          <tr>
            <td
              colSpan={columns.length}
              width={100}
              className={classNames(
                emptyCardsContainerClassName,
                'py-4 px-6 text-sm font-medium text-center'
              )}
            >
              <div
                className={classNames({
                  'grid grid-cols-1 lg:grid-cols-2': emptyCards.length > 1,
                })}
              >
                {emptyCards.map((item, i) => (
                  <EmptyStateIllustration
                    key={`$empty-card-${item.emptyTitle}`}
                    image={item.emptyImage}
                    title={item.emptyTitle}
                    description={item.emptydescription}
                    button={
                      item.emptyActionButton && item.emptyTextButton
                        ? {
                            label: item.emptyTextButton,
                            onClick: item.emptyActionButton,
                          }
                        : undefined
                    }
                    showSeparator={i % 2 === 0 && emptyCards.length > 1}
                    descriptionClassName={item.descriptionClassName}
                  />
                ))}
              </div>
            </td>
          </tr>
        </tbody>
      );
    }
    return (
      <TableEmptyState
        emptyTitle={emptyTitle}
        emptySubtitle={emptySubtitle}
        colSpan={columns.length}
        className={emptyClassName}
      />
    );
  }, [
    columns.length,
    emptyCards,
    emptyCardsContainerClassName,
    emptyClassName,
    emptySubtitle,
    emptyTitle,
  ]);

  return (
    <div
      className={classNames('flex flex-col', {
        'rounded-lg shadow ring-1 ring-black ring-opacity-5 h-fit':
          stickyHeader,
      })}
    >
      <div
        className={classNames('w-full', className, {
          'overflow-y-auto': fixedHeight,
        })}
        style={{
          height: fixedHeight ?? 'auto',
        }}
      >
        <div
          className={classNames(
            'min-w-full align-middle py-1 sm:px-1 px-2',
            borderClassName
          )}
        >
          <div
            className={classNames(containerClassName, {
              'scrollbar scrollbar-none scrollbar-thumb-rounded scrollbar-thumb-transparent sm:-mx-1 -mx-2':
                stickyHeader,
            })}
          >
            <table
              className={classNames(
                'w-full min-w-full divide-y divide-gray-300 ',
                tableClassName,
                {
                  'rounded-lg shadow ring-1 ring-black ring-opacity-5':
                    !stickyHeader,
                },
                {
                  'table-fixed min-w-full': tableFixed,
                  'xl:table-fixed xl:min-w-full': tableFixedDesktop,
                }
              )}
            >
              <TableHeader
                columns={columns}
                stickyHeader={stickyHeader && !!fixedHeight}
                filterState={filterState}
                selectColumnToFilter={selectColumnToFilter}
                columnSort={columnSort}
                selectColumnToSort={selectColumnToSort}
              />

              {paginatedData && paginatedData.length ? (
                <TableBody
                  data={paginatedData}
                  columns={columns}
                  onClickRow={onClickRow}
                  noRowPointer={noRowPointer}
                  classNameRow={classNameRow}
                />
              ) : (
                getEmptyComponent
              )}
            </table>
          </div>
        </div>
      </div>

      {entriesPerPage && totalPages > 1 ? (
        <TablePagination
          totalPages={totalPages}
          currentPage={currentPage}
          changeToPage={changeToPage}
        />
      ) : (
        ''
      )}
      {labelShowTotalRows && (
        <div className="flex justify-end text-gray-500 mt-4">
          <b>{labelShowTotalRows}</b>&nbsp;{sortedData.length}
        </div>
      )}
    </div>
  );
};

export default Table;
