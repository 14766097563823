import * as Yup from 'yup';

const RoleDetailEnrichmenttSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, '50 characters as maximum')
    .required('Role name is required'),
  description: Yup.string(),
  roleSeniority: Yup.string().required('Please, select a seniority'),
  roleFunction: Yup.string().required('Please, select a function'),
  cities: Yup.array()
    .of(Yup.string())
    .min(1, '1 location as minimum')
    .required('Locations are required'),
});

export default RoleDetailEnrichmenttSchema;
