import { CandidateDocumentTypeEnum } from '@headrace/types';
import * as Yup from 'yup';

const FormSchema = Yup.object().shape({
  documentType: Yup.array().of(
    Yup.string()
      .oneOf([
        CandidateDocumentTypeEnum.RESUME,
        CandidateDocumentTypeEnum.OTHER,
      ])
      .required('Please select a document type')
  ),
});

export default FormSchema;
