// Slider.js

import 'react-input-range/lib/css/index.css';
import '../formik/InputRange/Slider.css';

import classNames from 'classnames';
import React from 'react';
import InputRange from 'react-input-range';

export interface Range {
  max: number;
  min: number;
}

interface SliderInputProps {
  name: string;
  min: number;
  max: number;
  step: number;
  value: Range | number;
  label?: string;
  onChange?: (value: number | Range) => void;
  disabled?: boolean;
  className?: string;
  formatLabel?: (value: number) => string;
  hideLabels?: boolean;
}

const SliderInput: React.VFC<SliderInputProps> = (props) => {
  const {
    name,
    min,
    max,
    step,
    value,
    label,
    onChange,
    disabled,
    className,
    hideLabels,
    formatLabel = (val: number): string => val.toString(),
  } = props;
  return (
    <div
      className={classNames('slider', className, {
        'slider__hidden-label': hideLabels,
      })}
    >
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-900"
        >
          {label}
        </label>
      )}
      <InputRange
        minValue={min}
        maxValue={max}
        step={step}
        onChange={onChange || ((): void => {})}
        value={value}
        disabled={disabled}
        formatLabel={formatLabel}
      />
    </div>
  );
};

export default SliderInput;
