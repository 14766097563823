import classNames from 'classnames';
import type { FC, MouseEventHandler } from 'react';

interface BadgeProps {
  label: string | JSX.Element;
  className?: string;
  onClickHandler?: MouseEventHandler<HTMLSpanElement>;
}

const Badge: FC<BadgeProps> = (props) => {
  const { label, className, onClickHandler = (): void => {} } = props;
  const badgeClassName = classNames(
    'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-headraceBlack-800',
    className
  );
  return (
    <span
      role="presentation"
      onClick={onClickHandler}
      className={badgeClassName}
    >
      {label}
    </span>
  );
};

export default Badge;
