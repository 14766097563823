import classNames from 'classnames';

import Button from './Button';

interface TabsProps {
  tabs: {
    id: string;
    name: string | JSX.Element;
  }[];
  rightContent?: React.ReactNode;
  rightContentMobile?: React.ReactNode;
  rightContentMobileClassName?: string;
  children: React.ReactNode;
  onClick: (tabId: string) => void;
  current: string;
  subtitle?: React.ReactNode;
}

const Tabs: React.VFC<TabsProps> = ({
  tabs,
  rightContent,
  rightContentMobile,
  rightContentMobileClassName,
  current,
  onClick,
  children,
  subtitle,
}) => (
  <>
    <div
      className={classNames('pr-6 flex sm:hidden', rightContentMobileClassName)}
    >
      {rightContentMobile}
    </div>
    <div className="bg-white shadow rounded-lg divide-y divide-gray-200 text-headraceBlack-700">
      <div className="font-medium text-lg flex justify-between items-center flex-wrap sm:flex-nowrap md:flex-nowrap text-headraceBlack-700 !gap-2">
        <div className="md:flex-shrink-0 sm:flex-shrink-0 flex flex-shrink-1 w-full sm:w-fit">
          {tabs.map((tab) => (
            <Button
              key={tab.id}
              type="button"
              buttonType="link"
              className={classNames(
                '!text-gray-500 whitespace-nowrap py-6 px-0 border-b-2 font-medium text-sm flex-1 pl-5 sm:pl-0 outline-none',
                current === tab.id
                  ? 'border-headraceYellow-700 !text-headraceBlack-700 border-b-2 '
                  : 'border-transparent hover:text-headraceYellow-700 hover:border-gray-500'
              )}
              onClick={(): void => onClick(tab.id)}
            >
              <span> {tab.name}</span>
            </Button>
          ))}
        </div>
        {rightContent && (
          <div className="hidden sm:flex pr-6">{rightContent}</div>
        )}
        {subtitle && <span className="hidden sm:flex">{subtitle}</span>}
      </div>
      <div className="flex-grow border-t border-gray-300" />
      {children}
    </div>
  </>
);

export default Tabs;
