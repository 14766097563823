import './RichTextEditorStyles.css';

import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { ListItemNode, ListNode } from '@lexical/list';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import * as Sentry from '@sentry/nextjs';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import type { EditorState, LexicalEditor } from 'lexical';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import React, { useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';

import ErrorText from '../../ErrorText';
import useFocusOnError from '../errorHooks';
import DisableManager from './DisableManager';
import ToolbarPlugin from './ToolbarPlugin';

interface RichTextEditorProps {
  label?: string;
  description?: string;
  subtitle?: string;
  name: string;
  nameJSON: string;
  rows?: number;
  placeholder?: string;
  maxLength?: number;
  inputClassName?: string;
  countDown?: boolean;
  characterMinCount?: number;
  json?: string | null;
  info?: string;
  infoIcon?: JSX.Element;
  reset?: boolean;
}

const UpdateRichTextEditor: React.VFC<{
  json?: string | null;
  reset?: boolean;
}> = ({ json, reset }) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (json) {
      editor.setEditorState(editor.parseEditorState(json));
    }
  }, [editor, json]);

  const emptyValue =
    '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

  useEffect(() => {
    if (reset) {
      editor.setEditorState(editor.parseEditorState(emptyValue));
    }
  }, [editor, reset]);

  return null;
};

const RichTextEditor: React.VFC<RichTextEditorProps> = (props) => {
  const {
    label,
    description,
    name,
    subtitle,
    inputClassName,
    nameJSON,
    characterMinCount,
    maxLength,
    json,
    info,
    infoIcon = <QuestionMarkCircleIcon className="w-4 h-4 text-gray-400" />,
    reset,
  } = props;
  const [fieldName, metaName] = useField<string>(name);
  const [fieldNameJSON] = useField<string>(nameJSON);
  const { setFieldValue } = useFormikContext();
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const className = classNames(
    'w-full',
    'sm:text-sm',
    'border-x border-b',
    'border-gray-300',
    'rounded-b-md',
    'block',
    'outline-none',
    'p-4',
    'mt-4',
    'overflow-auto',
    'h-40',
    'richTextEditor',
    inputClassName,
    {
      'border-red-500 border': metaName.touched && metaName.error,
      'text-red-900': metaName.touched && metaName.error,
      'placeholder-red-300': metaName.touched && metaName.error,
      'focus-outline-nodes': metaName.touched && metaName.error,
      'focus:ring-red-500': metaName.touched && metaName.error,
      'focus:border-red-500': metaName.touched && metaName.error,
    }
  );
  useFocusOnError(inputRef, name);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onError(error: any): void {
    Sentry.captureException(error);
  }

  const onChange = (state: EditorState, editor: LexicalEditor): void => {
    state.read(() => {
      const root = $getRoot();
      let allText = '';
      root.getAllTextNodes().forEach((node) => {
        // eslint-disable-next-line no-underscore-dangle
        allText += ` ${node.__text}`;
      });

      const editorState = editor.getEditorState();
      const jsonString = JSON.stringify(editorState);
      setFieldValue(name, allText);
      if (!allText.length) {
        setFieldValue(nameJSON, null);
        return;
      }
      setFieldValue(nameJSON, jsonString);
    });
  };

  const theme = {
    ltr: 'ltr',
    rtl: 'rtl',
    placeholder: 'editor-placeholder',
    paragraph: 'editor-paragraph',
    text: {
      bold: 'font-bold',
      italic: 'italic',
      underline: 'underline',
      strikethrough: 'line-through',
    },
    list: {
      nested: {
        listitem: 'editor-nested-listitem',
      },
      ol: 'editor-list-ol',
      ul: 'editor-list-ul',
      listitem: 'editor-listitem',
    },
  };

  const initialConfig = {
    namespace: 'MyEditor',
    onError,
    theme,
    editorState:
      fieldNameJSON.value ||
      ((): void => {
        const root = $getRoot();
        const paragraphNode = $createParagraphNode();
        const textNode = $createTextNode(fieldName.value || '');
        paragraphNode.append(textNode);
        root.append(paragraphNode);
      }),
    nodes: [ListNode, ListItemNode],
  };

  return (
    <>
      <div className="flex flex-col gap-1">
        {label && (
          <label
            htmlFor={fieldName.name}
            className="flex gap-2 items-center text-sm font-medium text-headraceBlack-800"
          >
            {label}
            {info && (
              <div>
                <div data-for={`${fieldName.name}-info`} data-tip={info}>
                  {infoIcon}
                </div>
                <ReactTooltip
                  id={`${fieldName.name}-info`}
                  place="top"
                  effect="solid"
                  html
                  arrowColor="transparent"
                  className="!opacity-100 !bg-headraceBlack-800 !px-[12px] !text-xs !font-normal"
                />
              </div>
            )}
          </label>
        )}
        {subtitle && <p className="text-sm text-gray-500">{subtitle}</p>}
        <div className="relative w-full h-full sm:mt-6 mt-10">
          <div className="relative w-full h-full">
            <LexicalComposer initialConfig={initialConfig}>
              <DisableManager isEditable>
                <>
                  <div className="relative w-full">
                    <ToolbarPlugin />
                  </div>
                  <RichTextPlugin
                    contentEditable={<ContentEditable className={className} />}
                    placeholder=""
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                  <HistoryPlugin />
                  <ListPlugin />
                  <OnChangePlugin onChange={onChange} />
                  <ClearEditorPlugin />
                </>
              </DisableManager>
              <UpdateRichTextEditor json={json} reset={reset} />
            </LexicalComposer>
          </div>
        </div>
        {description && (
          <p className="mt-2 text-sm text-gray-500">{description}</p>
        )}
      </div>
      {maxLength && (
        <p
          className={classNames('mt-2 text-sm text-gray-500', {
            'text-red-600': fieldName.value.length > maxLength,
          })}
        >{`Characters left | ${maxLength - fieldName.value.length}`}</p>
      )}
      {characterMinCount && (
        <span
          className={classNames(
            'inline-block w-full text-right text-sm text-gray-400',
            {
              'text-red-600': Boolean(metaName.error),
            }
          )}
        >
          {fieldName.value.length} / {characterMinCount}
        </span>
      )}
      <ErrorText
        id={`${fieldName.name}-error`}
        error={metaName.error || null}
        touched={metaName.touched}
      />
    </>
  );
};

export default RichTextEditor;
