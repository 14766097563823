import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { useRef, useState } from 'react';
import type {
  InputAttributes,
  NumberFormatPropsBase,
  NumberFormatValues,
} from 'react-number-format';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';

import QuestionMarkIcon from '../../../QuestionMarkIcon';
import numberFormatter from '../../../utils/numberFormatter';
import ErrorText from '../ErrorText';
import useFocusOnError from './errorHooks';
import InputErrorIcon from './ErrorIcon';

interface InputProps {
  type?: string;
  label?: string;
  description?: string;
  autoComplete?: string;
  name: string;
  placeholder?: string;
  helper?: string;
  onChange?: (value: number) => void;
  disabled?: boolean;
  min?: number;
  max?: number;
  boxClassName?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  dynamicDescription?: (value: string | null) => string;
  allowLeadingZeros?: boolean;
  inputClassName?: string;
  labelHelper?: string;
  tooltip?: string;
  placeHolderBeforeCursor?: string;
  rigthHelper?: string;
  iconLabel?: JSX.Element;
  tooltipClass?: string;
  showErrorIcon?: boolean;
  showError?: boolean;
  labelClassName?: string;
  info?: string;
  infoIcon?: JSX.Element;
}

const NumberInput: React.VFC<
  InputProps & NumberFormatPropsBase<InputAttributes>
> = (props) => {
  const {
    label,
    description,
    autoComplete = 'off',
    name,
    placeholder = '',
    helper,
    disabled = false,
    onKeyPress,
    min = 0,
    max = Number.MAX_SAFE_INTEGER,
    thousandSeparator,
    decimalScale,
    allowNegative,
    boxClassName,
    dynamicDescription,
    allowLeadingZeros = false,
    inputClassName,
    labelHelper,
    tooltip,
    rigthHelper,
    iconLabel,
    placeHolderBeforeCursor,
    tooltipClass,
    showErrorIcon = true,
    showError = true,
    onChange,
    labelClassName,
    info,
    infoIcon = <QuestionMarkCircleIcon className="w-4 h-4 text-gray-400" />,
  } = props;
  const [field, meta] = useField<string>(name);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { setFieldValue } = useFormikContext();
  const initialValue =
    thousandSeparator && field.value
      ? numberFormatter({ style: 'decimal' }).format(parseFloat(field.value))
      : '';
  const [formattedValue, setFormatedValue] = useState<string>(initialValue);
  const getPlaceHolder = placeHolderBeforeCursor ? '' : helper;
  const className = classNames(
    'focus:ring-gray-200',
    'focus:border-gray-200',
    'w-full',
    'text-sm',
    'sm:text-sm',
    'border',
    'border-gray-300',
    'h-[38px]',
    ' block',
    'rounded-none',
    'rounded-r-md',
    'rounded-l-md',
    'flex-1',
    {
      'pl-6':
        (field.value == null || field.value === '') && placeHolderBeforeCursor,
    },
    inputClassName,
    {
      'border-red-500': meta.touched && meta.error,
      'text-red-900': meta.touched && meta.error,
      'placeholder-red-300': meta.touched && meta.error,
      'focus-outline-nodes': meta.touched && meta.error,
      'focus:ring-red-500': meta.touched && meta.error,
      'focus:border-red-500': meta.touched && meta.error,
    },
    {
      'opacity-50': disabled,
      'cursor-not-allowed': disabled,
    }
  );
  const divClassName = classNames('flex flex-col gap-1 relative', boxClassName);

  const withValueCap = (inputObj: NumberFormatValues): boolean => {
    const { floatValue, value } = inputObj;
    if (allowLeadingZeros && floatValue === 0) return true;
    if (floatValue && floatValue <= max && floatValue >= min) return true;
    if (value === '' || value == null) return true;
    return false;
  };
  useFocusOnError(inputRef, name);

  return (
    <div className={divClassName}>
      {iconLabel ||
        (label && (
          <div className="flex items-center">
            <label
              htmlFor={field.name}
              className={classNames(
                'flex gap-2 items-center text-sm font-medium text-headraceBlack-800',
                labelClassName
              )}
            >
              <div className="block">
                {label}
                {labelHelper && (
                  <span className="ml-1 font-normal italic text-gray-500 text-sm">
                    {labelHelper}
                  </span>
                )}
              </div>

              {info && (
                <div>
                  <div data-for={`${field.name}-info`} data-tip={info}>
                    {infoIcon}
                  </div>
                  <ReactTooltip
                    id={`${field.name}-info`}
                    place="top"
                    effect="solid"
                    html
                    arrowColor="transparent"
                    className="!opacity-100 !bg-headraceBlack-800 !px-[12px] !text-xs !font-normal"
                  />
                </div>
              )}
            </label>
            {iconLabel}
          </div>
        ))}
      <div className="flex relative rounded-md shadow-sm items-center">
        {placeHolderBeforeCursor &&
          (field.value == null || field.value === '') && (
            <span
              className={classNames(
                'inline-flex items-center px-3 text-gray-500 text-sm absolute pointer-events-none',
                { 'text-red-900': meta.touched && meta.error }
              )}
            >
              {placeHolderBeforeCursor}
            </span>
          )}
        <NumberFormat
          name={field.name}
          id={field.name}
          autoComplete={autoComplete}
          value={field.value}
          placeholder={`${placeholder || getPlaceHolder || ''} `}
          className={className}
          onValueChange={(values): void => {
            let val = values.formattedValue;
            if (helper) {
              val = val.replace(helper, '');
            }
            setFormatedValue(val.trim());
            if (values.floatValue) {
              setFieldValue(field.name, values.floatValue);
              if (onChange) onChange(values.floatValue);
            } else {
              setFieldValue(field.name, null);
            }
            if (allowLeadingZeros && values.floatValue === 0) {
              setFieldValue(field.name, values.floatValue);
            }
          }}
          onBlur={field.onBlur}
          disabled={disabled}
          onKeyPress={onKeyPress}
          min={min}
          thousandSeparator={thousandSeparator}
          decimalScale={decimalScale}
          allowNegative={allowNegative}
          isAllowed={withValueCap}
          prefix={`${helper || ''} `}
          getInputRef={inputRef}
        />
        {meta.touched && meta.error && showErrorIcon && <InputErrorIcon />}
        {tooltip && (
          <QuestionMarkIcon
            error={meta.touched && meta.error}
            tooltip={tooltip}
            fieldName={field.name}
            customTooltipStyles={tooltipClass}
            showErrorIcon={showErrorIcon}
          />
        )}
        {rigthHelper && (
          <div
            className={classNames(
              'absolute inset-y-0 right-0 flex items-center mr-3 text-gray-500 text-sm',
              {
                '!mr-8': meta.touched && meta.error && showErrorIcon,
              }
            )}
          >
            {rigthHelper}
          </div>
        )}
      </div>
      {showError && (
        <ErrorText
          id={`${field.name}-error`}
          error={meta.error || null}
          touched={meta.touched}
        />
      )}
      {description && (
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      )}
      {dynamicDescription && (
        <p className="mt-2 text-sm text-gray-500">
          {dynamicDescription(formattedValue)}
        </p>
      )}
    </div>
  );
};

export default NumberInput;
