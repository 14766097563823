import './RichTextEditorStyles.css';

import { ListItemNode, ListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import * as Sentry from '@sentry/nextjs';
import classNames from 'classnames';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import React, { useEffect, useMemo, useState } from 'react';

import DisableManager from './DisableManager';

export interface RichTextEditorProps {
  label?: string;
  description?: string;
  name: string;
  json: string | null;
  value: string | null;
  limitHeight?: boolean;
  className?: string;
  hideShowMore?: boolean;
  isLongText?: (boolean: boolean) => void;
  maxTextLength?: number;
  maxHtmlElementHeight?: number;
}

const UpdateRichTextEditor: React.VFC<{
  json: string | null;
}> = ({ json }) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (json) {
      editor.setEditorState(editor.parseEditorState(json));
    }
  }, [editor, json]);
  return null;
};

const OnlyReadRichTextEditor: React.VFC<RichTextEditorProps> = (props) => {
  const {
    label,
    description,
    name,
    value,
    json,
    limitHeight = true,
    className,
    hideShowMore = false,
    isLongText,
    maxTextLength,
    maxHtmlElementHeight = 160,
  } = props;
  const [showMore, setShowMore] = useState(!limitHeight);
  const [scrollable, setScrollable] = useState(false);

  const PluginClassName = classNames(
    'focus:ring-gray-200',
    'focus:border-gray-200',
    'w-full',
    'sm:text-sm',
    'border-gray-300',
    'rounded-b-md',
    'block',
    'outline-none',
    'richTextEditor'
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onError(error: any): void {
    Sentry.captureException(error);
  }

  const theme = useMemo(
    () => ({
      ltr: 'ltr',
      rtl: 'rtl',
      placeholder: 'editor-placeholder',
      paragraph: 'editor-paragraph',
      text: {
        bold: 'font-bold',
        italic: 'italic',
        underline: 'underline',
        strikethrough: 'line-through',
      },
      list: {
        nested: {
          listitem: 'editor-nested-listitem',
        },
        ol: 'editor-list-ol',
        ul: 'editor-list-ul',
        listitem: 'editor-listitem',
      },
    }),
    []
  );

  const initialConfig = useMemo(
    () => ({
      namespace: 'OnlyReadEditor',
      onError,
      theme,
      editorState:
        json ||
        ((): void => {
          const root = $getRoot();
          const paragraphNode = $createParagraphNode();
          const textNode = $createTextNode(value || '');
          paragraphNode.append(textNode);
          root.append(paragraphNode);
        }),
      nodes: [ListNode, ListItemNode],
    }),
    [json, theme, value]
  );

  return (
    <div className="flex flex-col gap-1">
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-headraceBlack-800"
        >
          {label}
        </label>
      )}
      <div>
        <div
          className={classNames(
            'relative w-full overflow-hidden',
            {
              'h-40': !showMore && !hideShowMore,
            },
            className
          )}
        >
          {(json || value) && (
            <LexicalComposer initialConfig={initialConfig}>
              <DisableManager
                isEditable={false}
                setScrollable={setScrollable}
                isLongText={(boolean): void => {
                  if (isLongText) {
                    isLongText(boolean);
                  }
                }}
                maxTextLength={maxTextLength}
                maxHtmlElementHeight={maxHtmlElementHeight}
              >
                <>
                  <RichTextPlugin
                    contentEditable={
                      <ContentEditable
                        id="richTextEditor"
                        className={PluginClassName}
                      />
                    }
                    placeholder=""
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                  <ListPlugin />
                </>
              </DisableManager>
              <UpdateRichTextEditor json={json} />
            </LexicalComposer>
          )}
          <div
            className={classNames({
              'rich-textEditor-fade': !showMore && scrollable && !hideShowMore,
            })}
          />
        </div>
        {scrollable && limitHeight && !hideShowMore && (
          <div className="flex items-end flex-col text-blue-500 pr-4">
            <button type="button" onClick={(): void => setShowMore(!showMore)}>
              {showMore ? 'Show less' : 'Show More'}
            </button>
          </div>
        )}
      </div>
      {description && (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      )}
    </div>
  );
};

export default OnlyReadRichTextEditor;
