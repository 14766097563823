import type { VFC } from 'react';

interface Props {
  handleChange: (value: string) => void;
  value: string | number;
  name: string;
  label: string;
  description?: string;
  style?: React.CSSProperties;
}

const ReviewInput: VFC<Props> = ({
  handleChange,
  value,
  name,
  label,
  description,
  style,
}) => (
  <div className="flex flex-col gap-1 w-full py-6" style={style}>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label
      htmlFor={name}
      className="block text-sm font-medium text-gray-700 self-start"
    >
      {label}
    </label>
    <textarea
      onChange={(evt): void => {
        handleChange(evt.target.value);
      }}
      value={value}
      id={name}
      className="rounded-md border-gray-300 h-full focus:ring-gray-200 focus:border-gray-200 sm:text-sm"
    />
    {description && (
      <p className="mt-1 text-sm text-gray-500 self-start">{description}</p>
    )}
  </div>
);

export default ReviewInput;
