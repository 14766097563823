import type { VFC } from 'react';

interface PerformanceFeeProps {
  amount: string;
  performanceCandidateAcceptQuantity?: number | null;
}

const PerformanceFee: VFC<PerformanceFeeProps> = ({
  amount,
  performanceCandidateAcceptQuantity,
}) => (
  <>
    <div>
      <span>${amount}</span>
    </div>
    <div>
      <span>For {performanceCandidateAcceptQuantity} accepted candidates</span>
    </div>
  </>
);

export default PerformanceFee;
