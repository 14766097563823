import classNames from 'classnames';
import type { VFC } from 'react';
import { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';

interface Props {
  text: string;
  type?: 'text' | 'link';
  className?: string;
}

const EllipsisText: VFC<Props> = ({ text, type = 'text', className }) => {
  const [isEllipsis, setIsEllipsis] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const { scrollWidth, clientWidth } = ref.current;
      setIsEllipsis(scrollWidth > clientWidth);
    }
  }, []);

  const cleanText = text.trim();

  return (
    <>
      <div
        ref={ref}
        className={classNames(
          'truncate',
          {
            'font-medium text-blue-500 hover:text-blue-600': type === 'link',
            'cursor-default': type === 'text',
          },
          className
        )}
        data-for={cleanText}
        data-tip={cleanText}
      >
        {cleanText}
      </div>

      <ReactTooltip
        id={cleanText}
        place="top"
        effect="solid"
        disable={!isEllipsis}
        multiline
        offset={{ top: -12 }}
        arrowColor="transparent"
      />
    </>
  );
};

export default EllipsisText;
