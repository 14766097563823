import { CollaboratorsBar } from '@headrace/ui';
import { formatApolloError } from '@headrace/utils';
import { useRouter } from 'next/router';
import { type VFC, useMemo } from 'react';
import toast from 'react-hot-toast';

import type { RoleCollaboratorsQuery } from '@/graphql/generated';
import {
  useEmployeesByEmployerQuery,
  useRemoveRoleCollaboratorMutation,
  useUpdateRoleCollaboratorsMutation,
} from '@/graphql/generated';
import { useEmployee } from '@/lib/EmployeeProvider';

interface CollaboratorsProps {
  loading: boolean;
  error?: string | null;
  refetch: () => Promise<void>;
  roleCollaborators: RoleCollaboratorsQuery['roleCollaborators'];
}

const Collaborators: VFC<CollaboratorsProps> = ({
  loading,
  error: roleCollaboratorsError,
  refetch,
  roleCollaborators,
}) => {
  const { data: employeesData } = useEmployeesByEmployerQuery();
  const { query } = useRouter();
  const { employee: currentEmployee } = useEmployee();

  const roleId = useMemo(() => {
    if (Array.isArray(query.roleId)) return query.roleId[0];
    return query.roleId ?? '';
  }, [query.roleId]);

  const [updateCollaborators, { loading: saving }] =
    useUpdateRoleCollaboratorsMutation({
      onCompleted: async () => {
        await refetch();
      },
      onError: (error) => {
        toast.error(formatApolloError(error));
      },
    });
  const [removeCollaborator, { loading: removing }] =
    useRemoveRoleCollaboratorMutation({
      onCompleted: async () => {
        await refetch();
      },
      onError: (error) => {
        toast.error(formatApolloError(error));
      },
    });
  const collaborators =
    roleCollaborators.map((collaborator) => ({
      firstName: collaborator.user.firstName,
      lastName: collaborator.user.lastName,
      avatar: collaborator.user.photoUrl,
      id: collaborator.user.id,
    })) ?? [];

  const employeeOptions =
    employeesData?.employeesByEmployer.map((employee) => ({
      firstName: employee.user.firstName,
      lastName: employee.user.lastName,
      avatar: employee.user.photoUrl,
      id: employee.user.id,
    })) ?? [];

  const onCollaboratorsSave = async (
    collaboratorIds: string[]
  ): Promise<void> => {
    await updateCollaborators({
      variables: {
        input: {
          roleId,
          collaboratorIds,
        },
      },
    });
  };

  const onRemoveCollaborator = async (
    collaboratorId: string
  ): Promise<void> => {
    await removeCollaborator({
      variables: {
        roleId,
        userId: collaboratorId,
      },
    });
  };

  return (
    <CollaboratorsBar
      collaborators={collaborators}
      allCollaborators={employeeOptions}
      loading={loading}
      error={roleCollaboratorsError}
      onCollaboratorsSave={onCollaboratorsSave}
      saving={saving}
      onRemoveCollaborator={onRemoveCollaborator}
      removing={removing}
      isHeadRaceAI={currentEmployee?.employer.isHeadRaceAI}
    />
  );
};

export default Collaborators;
