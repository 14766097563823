import { BASIC_URL } from '@headrace/constants';
import type {
  CompanyPress,
  CompanyPressCardData,
  CompanyPressFormValues,
} from '@headrace/types';
import { PlusIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { FormikProps } from 'formik';
import { FieldArray, Form, Formik } from 'formik';
import Image from 'next/image';
import type { VFC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import EditCard from '../../EditCard';
import EmptyStateIllustration from '../../EmptyStateIllustration';
import LoadingSpinner from '../../LoadingSpinner';
import PressItem from './PressItem';

const ValidationSchema = Yup.object().shape({
  pressList: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('Title required'),
      url: Yup.string()
        .matches(BASIC_URL, 'Invalid URL')
        .required('URL required'),
    })
  ),
});

export interface CompanyPressCardProps extends CompanyPressCardData {
  editMode?: boolean;
  refetchLoading?: boolean;
}

const CompanyPressCard: VFC<CompanyPressCardProps> = (props) => {
  const {
    editMode,
    pressList,
    cancelAction,
    loading,
    saveAction,
    refetchLoading,
  } = props;

  const [activeEditMode, setActiveEditMode] = useState(false);
  const [pressActualList, setPressActualList] = useState(pressList);

  const closeEditMode = (): void => {
    setActiveEditMode(false);
  };

  useEffect(() => {
    setPressActualList(pressList);
  }, [pressList]);

  const viewComponent = useMemo(
    () =>
      pressActualList.length > 0 ? (
        <div className="flex flex-col divide-y-[1px] divide-gray-200">
          {pressActualList.map((press, index) => (
            <PressItem item={press} key={`${press.id}_${index + 1}`} />
          ))}
        </div>
      ) : (
        <EmptyStateIllustration
          button={{
            label: 'Add press links',
            onClick: (): void => setActiveEditMode(true),
          }}
          title="Share where your company is in the news"
          description="Add links to press coverage your company has had that you’d like to share with candidates."
          image={
            <div className="max-w-[155px] mx-auto">
              <Image
                src="/illustrations/emptyStateDossier/company-press-empty.svg"
                alt="Company press illustration"
                layout="responsive"
                width={155}
                height={176}
                objectFit="contain"
              />
            </div>
          }
          showSeparator={false}
          contentClassName="!gap-2"
          titleClassName="!text-sm text-headraceBlack-700 font-medium"
          descriptionClassName="text-gray-500 font-normal text-sm mb-2"
          className="!p-[24px]"
        />
      ),
    [pressActualList]
  );

  const editComponent = useMemo(
    () => (
      <FieldArray name="pressList">
        {(fieldProps): JSX.Element => {
          const { form, push } = fieldProps;
          const { values } = form as FormikProps<CompanyPressFormValues>;
          return (
            <>
              <div
                className={classNames(
                  'flex flex-col divide-y-[1px] divide-gray-200',
                  {
                    'border-b-[1px] border-gray-200 mb-4':
                      values.pressList.length > 0,
                  }
                )}
              >
                {values.pressList.map((press, index) => (
                  <PressItem
                    item={press}
                    key={`${press.id}_${index + 1}`}
                    editMode={{
                      index,
                      onRemove: (): void => {
                        fieldProps.remove(index);
                      },
                    }}
                  />
                ))}
              </div>
              <div
                className={classNames('px-[24px]', {
                  'pb-4': values.pressList.length > 0,
                  'py-4': !values.pressList.length,
                })}
              >
                <div
                  role="presentation"
                  className="p-[18px] border-2 border-dashed border-gray-300 rounded-md cursor-pointer"
                  onClick={(): void => {
                    const newItem: CompanyPress = {
                      id: '',
                      title: '',
                      url: '',
                    };
                    push(newItem);
                  }}
                >
                  <div className="flex items-center justify-center gap-2">
                    <PlusIcon className="w-6 h-6 text-gray-400" />
                    <span className="whitespace-nowrap font-medium text-sm text-headraceBlack-700">
                      Add article
                    </span>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </FieldArray>
    ),
    []
  );

  return (
    <Formik<CompanyPressFormValues>
      initialValues={{ pressList: pressActualList }}
      onSubmit={(values): void => {
        if (saveAction)
          saveAction(
            values.pressList.map((press) => ({
              title: press.title,
              url: press.url,
            }))
          );
      }}
      enableReinitialize
      validationSchema={ValidationSchema}
    >
      {({ handleSubmit, resetForm, isValid }): JSX.Element => (
        <Form>
          <EditCard
            saveAction={(): void => {
              handleSubmit();
            }}
            saveDisabled={!isValid}
            cancelAction={(): void => {
              closeEditMode();
              resetForm();
              if (cancelAction) cancelAction();
            }}
            viewStateComponent={
              loading ? <LoadingSpinner className="p-6" /> : viewComponent
            }
            editStateComponent={
              loading ? <LoadingSpinner className="p-6" /> : editComponent
            }
            title="Press"
            loading={loading}
            editMode={editMode}
            activeEditMode={activeEditMode}
            removeChildrenXPadding
            removeChildrenYPadding
            refetchLoading={refetchLoading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CompanyPressCard;
