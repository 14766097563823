import 'react-phone-input-2/lib/plain.css';

import classNames from 'classnames';
import { useField } from 'formik';
import { useRef } from 'react';
import PhoneInput from 'react-phone-input-2';

import ErrorText from '../ErrorText';
import useFocusOnError from './errorHooks';
import InputErrorIcon from './ErrorIcon';

interface PhoneInputProps {
  label?: string;
  description?: string;
  name: string;
  placeholder?: string;
  helper?: string;
  disabled?: boolean;
  validatioError?: string;
  labelClassName?: string;
  allCountries?: boolean;
  onlyCountries?: string[];
  country?: string;
}
// Workaround to add missing "ref" prop in react-phone-input-2 library
const CustomPhoneInput = PhoneInput as React.FC<
  React.ComponentProps<typeof PhoneInput> & {
    ref: (phoneInputRef?: { numberInputRef: HTMLInputElement }) => void;
  }
>;

const PhoneNumberInput: React.VFC<PhoneInputProps> = (props) => {
  const {
    label,
    description,
    name,
    placeholder = '',
    helper,
    disabled = false,
    labelClassName,
    onlyCountries = ['us'],
    country = 'us',
    allCountries = false,
  } = props;
  const [field, meta, help] = useField<string>(name);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const className = classNames(
    'focus:ring-gray-200',
    'focus:border-gray-200',
    'w-full',
    'sm:text-sm',
    'border-grey-300',
    '!h-[38px]',
    'rounded-md',
    {
      '!border-red-500': meta.touched && meta.error,
      'text-red-900': meta.touched && meta.error,
      'placeholder-red-300': meta.touched && meta.error,
      'focus-outline-nodes': meta.touched && meta.error,
      'focus:ring-red-500': meta.touched && meta.error,
      'focus:border-red-500': meta.touched && meta.error,
    },
    {
      'opacity-50': disabled,
      'cursor-not-allowed': disabled,
    }
  );
  const buttonClassName = classNames('rounded-l-md', {
    '!border-0 !border-r-[1px] !border-red-300 ring-[0.5px] ring-gray-500 ring-inset':
      meta.touched && meta.error,
  });
  useFocusOnError(inputRef, name);
  return (
    <>
      <div className="flex flex-col gap-1">
        {label && (
          <label
            htmlFor={field.name}
            className={`block text-sm font-medium text-gray-700 ${
              labelClassName ?? ''
            }`}
          >
            {label}
          </label>
        )}
        <div className="flex relative rounded-md shadow-sm">
          {helper && (
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
              {helper}
            </span>
          )}
          <CustomPhoneInput
            inputProps={{
              name: field.name,
              id: field.name,
              autoComplete: 'tel',
            }}
            placeholder={placeholder}
            onChange={(value): void => {
              help.setValue(`+${value}`);
            }}
            onBlur={(event): void => field.onBlur(event)}
            value={field.value}
            onlyCountries={!allCountries ? onlyCountries : undefined}
            country={country}
            disabled={disabled}
            inputClass={className}
            buttonClass={buttonClassName}
            enableAreaCodeStretch
            countryCodeEditable={false}
            inputStyle={{ width: '100%', height: '100%' }}
            ref={(phoneInputRef): void => {
              if (inputRef && phoneInputRef?.numberInputRef) {
                inputRef.current = phoneInputRef.numberInputRef;
              }
            }}
          />
          {meta.touched && meta.error && <InputErrorIcon />}
        </div>
        {description && (
          <p className="mt-2 text-sm text-gray-500">{description}</p>
        )}
      </div>
      <ErrorText
        id={`${field.name}-error`}
        error={meta.error || null}
        touched={meta.touched}
      />
    </>
  );
};

export default PhoneNumberInput;
