import classNames from 'classnames';
import { Field } from 'formik';
import type { VFC } from 'react';

interface CheckboxProps {
  name: string;
  label: string;
  className?: string;
  description?: string;
  required?: boolean;
  labelClassName?: string;
  checkboxClassName?: string;
}

const CheckboxField: VFC<CheckboxProps> = ({
  name,
  label,
  className,
  description,
  required,
  labelClassName,
  checkboxClassName,
}) => {
  const boxClassName = classNames('flex items-start', className);
  return (
    <div className={boxClassName}>
      <div className="flex items-center h-5">
        <Field
          id={name}
          name={name}
          required={required}
          type="checkbox"
          className={classNames(
            'rounded border-gray-300 text-headraceYellow-700 focus:ring-transparent active:ring-transparent black-checkbox',
            checkboxClassName
          )}
        />
      </div>
      <div className="ml-3 text-sm">
        <label
          htmlFor={name}
          className={classNames('font-medium text-gray-700', labelClassName)}
        >
          {label}
        </label>
        {description && <p className="text-gray-500">{description}</p>}
      </div>
    </div>
  );
};

export default CheckboxField;
