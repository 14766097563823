import classNames from 'classnames';
import type { VFC } from 'react';
import type { NumberFormatValues, SourceInfo } from 'react-number-format';
import NumberFormat from 'react-number-format';

interface CalculatorFieldProps {
  value?: number;
  defaultValue: number;
  label: string;
  type: 'currency' | 'percentage';
  disabled: boolean;
  onChange?: (values: NumberFormatValues, sourceInfo: SourceInfo) => void;
}

const CalculatorField: VFC<CalculatorFieldProps> = (props) => {
  const { value, label, type, disabled, defaultValue, onChange } = props;
  const inputClassNames =
    'w-full sm:text-sm border-none font-normal text-sm text-gray-500 p-0';
  const labelClassNames =
    'font-medium text-base leading-5 text-gray-500 sm:text-right';
  return (
    <div className="flex gap-2 items-center flex-row-reverse sm:flex-row justify-end">
      <p className={labelClassNames}>{label}</p>
      <div className="flex gap-2 px-[13px] py-[9px] items-center max-w-[140px] min-w-[140px] border border-gray-300 rounded-md overflow-auto">
        {type === 'currency' && (
          <span className="font-normal text-sm text-gray-500">$</span>
        )}
        <NumberFormat
          className={classNames('focus:ring-0', inputClassNames, {
            'opacity-50': disabled,
          })}
          thousandSeparator
          decimalScale={2}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          onValueChange={onChange}
        />
        {type === 'percentage' && (
          <span className="font-normal text-sm text-gray-500">%</span>
        )}
      </div>
    </div>
  );
};

export default CalculatorField;
