/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull employer'
 *
 * Required dependencies: amplitude-js@^8.21.0
 * Tracking Plan Version: 60
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli
 *
 * [View Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/headrace/HeadRace/implementation/employer)
 */

import amplitude, { AmplitudeClient, Callback, Config } from 'amplitude-js';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '5d215cd5e61eda09cf589dc550a46061',
  development: '35613b326309ca088ca35a1a75527abf',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultOptions: ConfigExt = {
  plan: {
    version: '60',
    branch: 'main',
    source: 'employer',
    versionId: 'aadeb178-d14e-40b2-80cb-a7f3fce2024a',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '1.0.0',
    },
  },
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { options?: Partial<ConfigExt> };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; options?: Partial<ConfigExt> };
};
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: AmplitudeClient };
};

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  'agency id'?: string;
  'employee id'?: string;
  'employer id'?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | SINGULAR, PLURAL |
   */
  'employer type'?: 'SINGULAR' | 'PLURAL';
  'is headrace admin'?: boolean;
  /**
   * Designates if this user is a test account and should not be used in metrics
   */
  'is test account'?: boolean;
  'recruiter id'?: string;
  'screen resolution'?: string;
  viewport?: string;
}

export interface AppPageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
  'url path': string;
}

export interface CandidateCandidateDocumentViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'candidate id': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'role id': string;
}

export interface CandidateCandidateViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'candidate id': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'role id': string;
}

export interface LoginAuth0UserLoadedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer, Recruiter_Chrome_Extension |
   */
  app: 'Recruiter' | 'Employer' | 'Recruiter_Chrome_Extension';
}

export interface PaymentAddNewPaymentMethodClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Employer, Recruiter, Retool |
   */
  app: 'Employer' | 'Recruiter' | 'Retool';
}

export interface PaymentPaymentAddedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Employer, Recruiter, Retool |
   */
  app: 'Employer' | 'Recruiter' | 'Retool';
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'employer id': string;
}

export interface PaymentUpdateBillingInformationClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Employer, Recruiter, Retool |
   */
  app: 'Employer' | 'Recruiter' | 'Retool';
}

export interface PublicProfileDiscoveryProfileClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
  'clicked recruiter id': string;
  'recruiter id': string;
}

export interface PublicProfileGetAccessClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
  'recruiter id': string;
}

export interface PublicProfileLoginClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
  'recruiter id': string;
}

export interface PublicProfileSubmitGetAccessFormClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
  'recruiter id': string;
}

export interface RoleDashboardCandidateClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
}

export interface RoleDashboardRoleClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
}

export interface RoleRecruitersSearchedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'employer id': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  'num of recommended': number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  recruiters?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'role id': string;
}

export interface RoleRoleViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'employer id': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'role id': string;
}

export interface UserAddTeamMemberClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recruiter, Employer |
   */
  app: 'Recruiter' | 'Employer';
}

export interface DossierClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | |
   */
  'dossier id': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | |
   */
  'prospect id': string;
}

export class Identify implements BaseEvent {
  event_type = SpecialEventType.Identify;

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class AppPageViewed implements BaseEvent {
  event_type = 'App: page viewed';

  constructor(public event_properties: AppPageViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class CandidateCandidateDocumentViewed implements BaseEvent {
  event_type = 'Candidate: candidate document viewed';

  constructor(
    public event_properties: CandidateCandidateDocumentViewedProperties
  ) {
    this.event_properties = event_properties;
  }
}

export class CandidateCandidateViewed implements BaseEvent {
  event_type = 'Candidate: candidate viewed';

  constructor(public event_properties: CandidateCandidateViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class LoginAuth0UserLoaded implements BaseEvent {
  event_type = 'Login: auth0 user loaded';

  constructor(public event_properties: LoginAuth0UserLoadedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentAddNewPaymentMethodClicked implements BaseEvent {
  event_type = 'Payment: add new payment method clicked';

  constructor(
    public event_properties: PaymentAddNewPaymentMethodClickedProperties
  ) {
    this.event_properties = event_properties;
  }
}

export class PaymentPaymentAdded implements BaseEvent {
  event_type = 'Payment: payment added';

  constructor(public event_properties: PaymentPaymentAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentUpdateBillingInformationClicked implements BaseEvent {
  event_type = 'Payment: update billing information clicked';

  constructor(
    public event_properties: PaymentUpdateBillingInformationClickedProperties
  ) {
    this.event_properties = event_properties;
  }
}

export class PublicProfileDiscoveryProfileClicked implements BaseEvent {
  event_type = 'Public Profile: discovery profile clicked';

  constructor(
    public event_properties: PublicProfileDiscoveryProfileClickedProperties
  ) {
    this.event_properties = event_properties;
  }
}

export class PublicProfileGetAccessClicked implements BaseEvent {
  event_type = 'Public Profile: get access clicked';

  constructor(
    public event_properties: PublicProfileGetAccessClickedProperties
  ) {
    this.event_properties = event_properties;
  }
}

export class PublicProfileLoginClicked implements BaseEvent {
  event_type = 'Public Profile: login clicked';

  constructor(public event_properties: PublicProfileLoginClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class PublicProfileSubmitGetAccessFormClicked implements BaseEvent {
  event_type = 'Public Profile: submit get access form clicked';

  constructor(
    public event_properties: PublicProfileSubmitGetAccessFormClickedProperties
  ) {
    this.event_properties = event_properties;
  }
}

export class RoleDashboardCandidateClicked implements BaseEvent {
  event_type = 'Role: dashboard candidate clicked';

  constructor(
    public event_properties: RoleDashboardCandidateClickedProperties
  ) {
    this.event_properties = event_properties;
  }
}

export class RoleDashboardRoleClicked implements BaseEvent {
  event_type = 'Role: dashboard role clicked';

  constructor(public event_properties: RoleDashboardRoleClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class RoleRecruitersSearched implements BaseEvent {
  event_type = 'Role: recruiters searched';

  constructor(public event_properties: RoleRecruitersSearchedProperties) {
    this.event_properties = event_properties;
  }
}

export class RoleRoleViewed implements BaseEvent {
  event_type = 'Role: role viewed';

  constructor(public event_properties: RoleRoleViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class DossierClicked implements BaseEvent {
  event_type = 'Dossier clicked';

  constructor(public event_properties: DossierClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class UserAddTeamMemberClicked implements BaseEvent {
  event_type = 'User: add team member clicked';

  constructor(public event_properties: UserAddTeamMemberClickedProperties) {
    this.event_properties = event_properties;
  }
}

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: AmplitudeClient;
  private middlewares: Middleware[] = [];

  get client(): AmplitudeClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): void {
    this.disabled = options.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return;
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.getInstance();
      this.amplitude?.init(apiKey, undefined, { ...DefaultOptions, ...options.client?.options });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   * @param extra Extra unstructured data for middleware.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: IdentifyOptions,
    extra?: MiddlewareExtra
  ) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event: IdentifyEvent = {
      event_type: SpecialEventType.Identify,
      event_properties: properties,
      user_id: userId || options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({ event, extra }, payload => {
      if (payload.event.user_id) {
        this.amplitude?.setUserId(payload.event.user_id);
      }
      if (payload.event.device_id) {
        this.amplitude?.setDeviceId(payload.event.device_id);
      }

      this._identify(payload.event, options);
    });
  }

  private _identify(
    event: Event,
    options?: IdentifyOptions,
  ) {
    const amplitudeIdentify = new amplitude.Identify();
    if (event.event_properties != null) {
      for (const [key, value] of Object.entries(event.event_properties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    this.amplitude?.identify(
      amplitudeIdentify,
      options?.callback,
      // options?.errorCallback
    );
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   * @param extra Extra unstructured data for middleware.
   */
  track(event: Event, options?: EventOptions, extra?: MiddlewareExtra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const trackEvent: BaseEvent = { ...event, ...options };
    this.runMiddleware({ event: trackEvent, extra }, payload => {
      if (payload.event.user_id) {
        this.amplitude?.setUserId(payload.event.user_id);
      }
      if (payload.event.device_id) {
        this.amplitude?.setDeviceId(payload.event.device_id);
      }

      const userProperties = (payload.event as BaseEvent).user_properties;
      if (userProperties) {
        const identifyEvent: IdentifyEvent = {
          event_type: SpecialEventType.Identify,
          event_properties: userProperties,
          user_id: payload.event.user_id,
          device_id: payload.event.device_id
        };
        this._identify(identifyEvent, options);
      }

      this.amplitude?.logEvent(
        payload.event.event_type,
        payload.event.event_properties,
        options?.callback,
        // options?.errorCallback,
      );
    });
  }

  /**
   * App: page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/App%3A%20page%20viewed)
   *
   * Owner: Mateo Angel
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  appPageViewed(
    properties: AppPageViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new AppPageViewed(properties), options, extra);
  }

  /**
   * Candidate: candidate document viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Candidate%3A%20candidate%20document%20viewed)
   *
   * Owner: Hank Hwang
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  candidateCandidateDocumentViewed(
    properties: CandidateCandidateDocumentViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CandidateCandidateDocumentViewed(properties), options, extra);
  }

  /**
   * Candidate: candidate viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Candidate%3A%20candidate%20viewed)
   *
   * Owner: neldo.agustin@meltstudio.co
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  candidateCandidateViewed(
    properties: CandidateCandidateViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CandidateCandidateViewed(properties), options, extra);
  }

  /**
   * Login: auth0 user loaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Login%3A%20auth0%20user%20loaded)
   *
   * Owner: Hank Hwang
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  loginAuth0UserLoaded(
    properties: LoginAuth0UserLoadedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new LoginAuth0UserLoaded(properties), options, extra);
  }

  /**
   * Payment: add new payment method clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Payment%3A%20add%20new%20payment%20method%20clicked)
   *
   * Owner: Hank Hwang
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  paymentAddNewPaymentMethodClicked(
    properties: PaymentAddNewPaymentMethodClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PaymentAddNewPaymentMethodClicked(properties), options, extra);
  }

  /**
   * Payment: payment added
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Payment%3A%20payment%20added)
   *
   * Owner: Hank Hwang
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  paymentPaymentAdded(
    properties: PaymentPaymentAddedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PaymentPaymentAdded(properties), options, extra);
  }

  /**
   * Payment: update billing information clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Payment%3A%20update%20billing%20information%20clicked)
   *
   * Owner: neldo.agustin@meltstudio.co
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  paymentUpdateBillingInformationClicked(
    properties: PaymentUpdateBillingInformationClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PaymentUpdateBillingInformationClicked(properties), options, extra);
  }

  /**
   * Public Profile: discovery profile clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Public%20Profile%3A%20discovery%20profile%20clicked)
   *
   * Owner: Camila Eraso
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  publicProfileDiscoveryProfileClicked(
    properties: PublicProfileDiscoveryProfileClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PublicProfileDiscoveryProfileClicked(properties), options, extra);
  }

  /**
   * Public Profile: get access clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Public%20Profile%3A%20get%20access%20clicked)
   *
   * Owner: Camila Eraso
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  publicProfileGetAccessClicked(
    properties: PublicProfileGetAccessClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PublicProfileGetAccessClicked(properties), options, extra);
  }

  /**
   * Public Profile: login clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Public%20Profile%3A%20login%20clicked)
   *
   * Owner: Camila Eraso
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  publicProfileLoginClicked(
    properties: PublicProfileLoginClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PublicProfileLoginClicked(properties), options, extra);
  }

  /**
   * Public Profile: submit get access form clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Public%20Profile%3A%20submit%20get%20access%20form%20clicked)
   *
   * Owner: Camila Eraso
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  publicProfileSubmitGetAccessFormClicked(
    properties: PublicProfileSubmitGetAccessFormClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PublicProfileSubmitGetAccessFormClicked(properties), options, extra);
  }

  /**
   * Role: dashboard candidate clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Role%3A%20dashboard%20candidate%20clicked)
   *
   * Owner: neldo.agustin@meltstudio.co
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  roleDashboardCandidateClicked(
    properties: RoleDashboardCandidateClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new RoleDashboardCandidateClicked(properties), options, extra);
  }

  /**
   * Role: dashboard role clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Role%3A%20dashboard%20role%20clicked)
   *
   * Owner: neldo.agustin@meltstudio.co
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  roleDashboardRoleClicked(
    properties: RoleDashboardRoleClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new RoleDashboardRoleClicked(properties), options, extra);
  }

  /**
   * Role: recruiters searched
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Role%3A%20recruiters%20searched)
   *
   * Owner: Hank Hwang
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  roleRecruitersSearched(
    properties: RoleRecruitersSearchedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new RoleRecruitersSearched(properties), options, extra);
  }

  /**
   * Role: role viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/Role%3A%20role%20viewed)
   *
   * Owner: Hank Hwang
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  roleRoleViewed(
    properties: RoleRoleViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new RoleRoleViewed(properties), options, extra);
  }

  /**
   * User: add team member clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/headrace/HeadRace/events/main/latest/User%3A%20add%20team%20member%20clicked)
   *
   * Owner: neldo.agustin@meltstudio.co
   *
   * @param properties The event's properties (e.g. app)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  userAddTeamMemberClicked(
    properties: UserAddTeamMemberClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new UserAddTeamMemberClicked(properties), options, extra);
  }

  /**
   * Dossier: dossier clicked
   *
   * [View in Tracking Plan](https://app.amplitude.com/data/headrace/HeadRace/events/main/latest/Dossier%20clicked)
   *
   * Owner: Miguel Chaparro
   *
   * @param properties The event's properties (e.g. dossier id)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  dossierClicked(
      properties: DossierClickedProperties,
      options?: EventOptions,
      extra?: MiddlewareExtra,
  ) {
    return this.track(new DossierClicked(properties), options, extra);
  }

  addEventMiddleware(middleware: Middleware): void {
    this.middlewares.push(middleware);
  }

  private runMiddleware(payload: MiddlewarePayload, next: MiddlewareNext): void {
    let curMiddlewareIndex = -1;
    const middlewareCount = this.middlewares.length;

    const middlewareNext: MiddlewareNext = curPayload => {
      curMiddlewareIndex += 1;
      if (curMiddlewareIndex < middlewareCount) {
        this.middlewares[curMiddlewareIndex](curPayload, _next);
      } else {
        next(curPayload);
      }
    };

    const _next: MiddlewareNext = middlewareCount > 0 ? middlewareNext : next;

    _next(payload);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type ConfigExt = Partial<Config> & { plan?: Plan };

export type Plan = {
  branch?: string;
  source?: string;
  version?: string;
  versionId?: string;
};

export enum SpecialEventType {
  Identify = '$identify',
  Group = '$groupidentify',
}

export type BaseEvent = {
  event_type: string;
  event_properties?: { [key: string]: any };
  plan?: Plan;
  user_id?: string;
  device_id?: string;
  user_properties?: { [key: string]: any };
};
export type IdentifyEvent = Omit<BaseEvent, 'user_properties'> & {
  event_type: SpecialEventType.Identify;
};
export type GroupEvent = Omit<BaseEvent, 'user_properties'> & {
  event_type: SpecialEventType.Group;
};
export type Event = BaseEvent | IdentifyEvent | GroupEvent;

type BaseEventOptions = Omit<BaseEvent, 'event_type' | 'event_properties'> & {
  callback?: Callback;
  errorCallback?: Callback;
};
export type EventOptions = BaseEventOptions;
export type IdentifyOptions = Omit<BaseEventOptions, 'user_properties'>;
export type GroupOptions = Omit<BaseEventOptions, 'user_properties'>;

/**
 * Unstructured object to let users pass extra data to middleware
 */
export interface MiddlewareExtra {
  [name: string]: any;
}

/**
 * Data to be processed by middleware
 */
export interface MiddlewarePayload {
  event: Event;
  extra?: MiddlewareExtra;
}

/**
 * Function called at the end of each Middleware to run the next middleware in the chain
 */
export type MiddlewareNext = (payload: MiddlewarePayload) => void;

/**
 * A function to run on the Event stream (each logEvent call)
 *
 * @param payload The event and extra data being sent
 * @param next Function to run the next middleware in the chain, not calling next will end the middleware chain
 */
export type Middleware = (
  payload: MiddlewarePayload,
  next: MiddlewareNext
) => void;
