import currencyFormatter from './currencyFormatter';
import numberFormatter from './numberFormatter';

const formatOrTBD = (
  data?: string | number | null,
  format?: 'currency' | 'number'
): string | number => {
  if (data == null) {
    return 'TBD';
  }
  if (format === 'currency' && typeof data === 'number') {
    return currencyFormatter().format(data);
  }
  if (format === 'number' && typeof data === 'number') {
    return numberFormatter().format(data);
  }
  return data;
};

export default formatOrTBD;
