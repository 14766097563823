import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
  color?: string;
}

const MedicalKitIcon: VFC<Props> = ({
  className,
  width = '30',
  height = '28',
  color = '#FFA300',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.4666 5.60003H20.5986V2.08403C20.5986 1.7658 20.4721 1.4606 20.2471 1.23547C20.022 1.01047 19.7168 0.884033 19.3986 0.884033H10.5986C10.2803 0.884033 9.97513 1.01047 9.75 1.23547C9.525 1.4606 9.39857 1.7658 9.39857 2.08403V5.60003H1.53057C1.21234 5.60003 0.907 5.72647 0.682 5.95147C0.457 6.1766 0.330566 6.4818 0.330566 6.80003V25.916C0.330566 26.2343 0.457 26.5395 0.682 26.7646C0.907 26.9896 1.21233 27.116 1.53057 27.116H28.4666C28.7848 27.116 29.0901 26.9896 29.3151 26.7646C29.5401 26.5395 29.6666 26.2343 29.6666 25.916V6.80003C29.6666 6.4818 29.5401 6.1766 29.3151 5.95147C29.0901 5.72647 28.7848 5.60003 28.4666 5.60003ZM11.7986 3.28403H18.1986V5.60003H11.7986V3.28403ZM27.2666 24.716H2.73057V8.00003H27.2666V24.716Z"
      fill={color}
    />
    <path
      d="M16.1999 11.6481H13.7999V15.1521H10.2959V17.5521H13.7999V21.0561H16.1999V17.5521H19.7039V15.1521H16.1999V11.6481Z"
      fill={color}
    />
  </svg>
);

export default MedicalKitIcon;
