import capitalizeString from './capitalizeString';

const cityLabel = (name: string, state: string): string => {
  if (name.toLowerCase() === 'remote') {
    return capitalizeString(name);
  }
  return `${capitalizeString(name)}, ${state.toUpperCase()}`;
};

export default cityLabel;
