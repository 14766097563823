import {
  ArrowNarrowRightIcon,
  CheckCircleIcon as CheckCircleIconOutline,
} from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';
import classnames from 'classnames';
import Link from 'next/link';

import MiniDivider from '../../MiniDivider';

interface Props {
  checked?: boolean;
  icon: JSX.Element;
  title: string;
  description: string;
  titleAction: string;
  action: string | (() => void);
  className?: string;
  classNameAction?: string;
  header?: React.ReactNode;
  showCheckIcon?: boolean;
  smallCard?: boolean;
}

const Card: React.VFC<Props> = ({
  checked = false,
  icon,
  title,
  description,
  titleAction,
  action,
  className,
  classNameAction,
  header,
  showCheckIcon = true,
  smallCard,
}) => {
  const checkedIcon = checked ? (
    <span
      className="relative flex h-6 w-6 flex-shrink-0 items-center justify-center"
      aria-hidden="true"
    >
      <span className="absolute h-4 w-4 rounded-full bg-white" />
      <CheckCircleIcon className="relative block h-6 w-6 rounded-full text-green-500" />
    </span>
  ) : (
    <CheckCircleIconOutline className="w-6" />
  );
  return (
    <div
      className={classnames(
        'shadow rounded-lg text-white flex flex-col gap-8 font-normal text-base justify-between',
        { '!text-sm': smallCard },
        className
      )}
    >
      {header && <div className="p-6">{header}</div>}
      <div
        className={classnames('flex flex-col gap-4 px-6 ', {
          'pt-24': !header,
          '!pt-6': smallCard,
        })}
      >
        <div className="w-6 font-light text-xl">{icon}</div>

        <span
          className={classnames('font-medium text-xl', {
            '!text-base': smallCard,
          })}
        >
          {title}
        </span>
        <MiniDivider />
        <span>{description}</span>
      </div>

      {typeof action === 'function' ? (
        <button
          type="button"
          onClick={action}
          className={classnames(
            'flex justify-between px-6 py-7 rounded-b-lg',
            {
              '!py-4': smallCard,
            },
            classNameAction
          )}
        >
          <div className="flex items-center justify-center gap-1">
            {titleAction} <ArrowNarrowRightIcon className="w-5" />
          </div>
          {showCheckIcon && checkedIcon}
        </button>
      ) : (
        <Link href={action} passHref>
          <div
            className={classnames(
              'flex justify-between px-6 py-7 rounded-b-lg cursor-pointer',
              {
                '!py-4': smallCard,
              },
              classNameAction
            )}
          >
            <a className="flex items-center justify-center gap-2">
              {titleAction} <ArrowNarrowRightIcon className="w-5" />
            </a>
            {showCheckIcon && checkedIcon}
          </div>
        </Link>
      )}
    </div>
  );
};

export default Card;
