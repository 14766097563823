import type { VFC } from 'react';

interface Props {
  className?: string;
  color?: string;
}

const InviteSearchIcon: VFC<Props> = ({ className, color = '#FFA300' }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2339_22733)">
      <path
        d="M12.5723 10.6211H16.9214C17.1177 10.6199 17.2762 10.4641 17.2775 10.2718V9.57322C17.277 9.12504 17.1526 8.68555 16.9184 8.30071C16.6839 7.91613 16.3479 7.60086 15.9458 7.38844C16.3869 7.05802 16.6662 6.56089 16.7156 6.01855C16.7649 5.4762 16.5796 4.93858 16.2051 4.53661C15.8303 4.13488 15.3011 3.90607 14.746 3.90607C14.1909 3.90607 13.6617 4.13488 13.2869 4.53661C12.9124 4.93833 12.727 5.4762 12.7764 6.01855C12.8258 6.56089 13.1049 7.05802 13.5462 7.38844C13.1444 7.60111 12.8086 7.91638 12.5746 8.30096C12.3406 8.68555 12.2171 9.12528 12.2168 9.57322V10.2718C12.2168 10.4649 12.3763 10.6211 12.5728 10.6211H12.5723ZM13.4816 5.85334C13.4816 5.52415 13.6151 5.20839 13.8524 4.9756C14.0896 4.74281 14.4115 4.61188 14.747 4.61188C15.0825 4.61188 15.4044 4.74281 15.6416 4.9756C15.8789 5.20839 16.0124 5.52415 16.0124 5.85334C16.0124 6.18252 15.8789 6.49828 15.6416 6.73107C15.4044 6.96386 15.0825 7.09479 14.747 7.09479C14.4115 7.09479 14.0896 6.96386 13.8524 6.73107C13.6151 6.49828 13.4816 6.18252 13.4816 5.85334ZM13.8377 8.03488C14.4003 7.71613 15.0934 7.71613 15.6561 8.03488C16.2187 8.35363 16.5654 8.94243 16.5654 9.57993V9.92476H12.9286V9.57993C12.9286 8.94243 13.275 8.35363 13.8377 8.03488Z"
        fill="#30323D"
      />
      <path
        d="M24.0213 8.95426C23.9334 6.57893 22.9023 4.33155 21.1482 2.69185C19.3941 1.05215 17.0564 0.150311 14.634 0.178881C12.2114 0.207203 9.89647 1.16394 8.18291 2.84439H8.17835C6.44453 4.54819 5.46835 6.85644 5.46304 9.26456C5.45772 11.6724 6.42351 13.9847 8.14999 15.6964L6.12015 17.6879L5.39365 16.959C5.23716 16.8032 5.02344 16.7153 4.8001 16.7153C4.57675 16.7153 4.36329 16.8032 4.20654 16.959L0.277782 20.8158C0.117744 20.9688 0.0273438 21.1787 0.0273438 21.3981C0.0273438 21.6175 0.117744 21.8274 0.277782 21.9804L2.2671 23.9347C2.4236 24.0904 2.63732 24.1784 2.86066 24.1784C3.084 24.1784 3.29747 24.0904 3.45422 23.9347L7.37361 20.066C7.53238 19.9124 7.62202 19.7027 7.62202 19.4836C7.62202 19.2645 7.53238 19.0551 7.37361 18.9013L6.62812 18.1838L8.67695 16.1761C10.5103 17.7301 12.8901 18.5192 15.3081 18.3751C17.7264 18.231 19.9915 17.1649 21.6192 15.4047C23.2472 13.6446 24.1094 11.3296 24.0213 8.95426ZM6.87045 19.4064C6.91603 19.4526 6.91603 19.5256 6.87045 19.5718L2.93916 23.4264C2.91688 23.4485 2.88649 23.4609 2.85484 23.4609C2.82318 23.4609 2.7928 23.4485 2.77051 23.4264L0.78119 21.4769C0.758653 21.455 0.745992 21.4252 0.745992 21.3941C0.745992 21.3631 0.758653 21.3333 0.78119 21.3114L4.7102 17.4521C4.75781 17.4087 4.83124 17.4087 4.87885 17.4521L6.8707 19.4064H6.87045ZM20.8081 15.2323C19.2012 16.8067 17.0227 17.6906 14.7518 17.6894C12.4809 17.6884 10.3031 16.8025 8.69771 15.2269C7.09227 13.651 6.19029 11.5142 6.19029 9.28618C6.19029 7.05817 7.09227 4.92109 8.69771 3.34549C10.3031 1.76964 12.4806 0.883953 14.7518 0.882959C17.0227 0.881965 19.2012 1.76566 20.8081 3.34027C22.4136 4.91836 23.315 7.05693 23.315 9.28642C23.315 11.5159 22.4136 13.6545 20.8081 15.2326V15.2323Z"
        fill={color}
      />
      <path
        d="M14.7592 1.56403C12.6716 1.56403 10.6691 2.37717 9.19232 3.82483H9.19181C7.71577 5.27323 6.88672 7.23789 6.88672 9.28602C6.88672 11.3344 7.71628 13.2986 9.19257 14.747C10.6689 16.1954 12.6711 17.009 14.7589 17.009C16.8468 17.009 18.849 16.1954 20.3253 14.747C21.8016 13.2986 22.6309 11.3344 22.6311 9.28602C22.6311 7.23764 21.8021 5.27323 20.326 3.82483C18.849 2.37717 16.8468 1.56403 14.7592 1.56403ZM21.6783 11.1098C21.3501 12.3023 20.7056 13.3883 19.8105 14.257C18.4679 15.5728 16.6475 16.3119 14.7496 16.3111C12.8516 16.3104 11.0317 15.5703 9.69015 14.2536C8.34832 12.9366 7.59473 11.1508 7.59473 9.28875C7.59473 7.4267 8.34858 5.64092 9.69015 4.32394C11.032 3.00721 12.8519 2.26686 14.7496 2.26636C16.6472 2.26587 18.4679 3.00473 19.8105 4.32071C20.7056 5.18926 21.3501 6.27518 21.6783 7.46794C22.0064 8.66045 22.0064 9.91706 21.6783 11.1098Z"
        fill={color}
      />
      <path
        d="M18.7778 10.8651C18.6834 10.7684 18.5406 10.7332 18.4107 10.7746L16.7797 11.35C16.6612 11.3885 16.5713 11.4847 16.5421 11.6039C16.515 11.7207 16.551 11.8429 16.6371 11.9276L16.8888 12.1746L15.2436 13.7909L13.3634 11.9813C13.2971 11.9152 13.2064 11.8782 13.1117 11.8782C13.017 11.8782 12.9266 11.9155 12.86 11.9813L10.7233 14.0776C10.6215 14.1775 10.5911 14.3278 10.6463 14.4583C10.7013 14.5887 10.8312 14.6739 10.975 14.6739C11.0692 14.6729 11.1596 14.6364 11.2267 14.5715L13.1259 12.7082L14.9967 14.546C15.137 14.6814 15.3621 14.6814 15.5024 14.546L17.4016 12.6827L17.6887 12.9644C17.7558 13.0292 17.846 13.066 17.9404 13.0667C17.9665 13.0702 17.9929 13.0702 18.0187 13.0667C18.1402 13.0392 18.2377 12.9505 18.2749 12.8337L18.8637 11.2335L18.8543 11.2288C18.9019 11.1031 18.8723 10.9617 18.7776 10.8648L18.7778 10.8651Z"
        fill="#30323D"
      />
    </g>
    <defs>
      <clipPath id="clip0_2339_22733">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.0273438 0.178223)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default InviteSearchIcon;
