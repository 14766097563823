import 'yup-phone-lite';

import { BASIC_URL, LINKED_IN_URL } from '@headrace/constants';
import * as Yup from 'yup';

const SettingsGeneralCompanySchema = (
  isHeadraceAI: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const company = {
    company: Yup.string().required('Company name is required'),
  };
  const linkedin = {
    linkedin: Yup.string().matches(LINKED_IN_URL, 'Invalid LinkedIn URL'),
  };
  const location = {
    location: Yup.string().required('You must provide a valid location'),
  };
  const companyStage = {
    companyStage: Yup.string().required('Company stage is required'),
  };
  const companyStageNoRequired = {
    companyStage: Yup.string(),
  };
  const website = {
    website: Yup.string().matches(BASIC_URL, 'Invalid Website URL'),
  };
  const crunchbase = {
    crunchbase: Yup.string().matches(BASIC_URL, 'Invalid Crunchbase URL'),
  };
  const bio = {
    bio: Yup.string(),
  };
  const industry = {
    industry: Yup.string().required('Please select an industry'),
  };
  if (isHeadraceAI) {
    return Yup.object().shape({
      ...company,
      ...linkedin,
      ...location,
      ...companyStageNoRequired,
      ...website,
      ...crunchbase,
      ...bio,
      ...industry,
    });
  }
  return Yup.object().shape({
    ...company,
    ...linkedin,
    ...location,
    ...companyStage,
    ...website,
    ...crunchbase,
    ...bio,
    ...industry,
  });
};

export default SettingsGeneralCompanySchema;
