import type {
  RoleDescriptionCardData,
  RoleDescriptionFormValues,
} from '@headrace/types';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import type { VFC } from 'react';
import { useMemo, useRef, useState } from 'react';

import EditCard from '../EditCard';
import EmptyStateIllustration from '../EmptyStateIllustration';
import { RichTextEditor } from '../forms/fields/formik';
import OnlyReadRichTextEditor from '../forms/fields/formik/RichTextEditor/OnlyReadRichTextEditor';

export interface RoleDescriptionCardProps extends RoleDescriptionCardData {
  editMode?: boolean;
}

const RoleDescriptionCard: VFC<RoleDescriptionCardProps> = ({
  saveAction,
  cancelAction,
  loading,
  description,
  descriptionJSON,
  editMode,
}) => {
  const formRef = useRef<FormikProps<RoleDescriptionFormValues>>(null);
  const [activeEditMode, setActiveEditMode] = useState(false);
  const viewComponent = useMemo(
    () =>
      descriptionJSON || description ? (
        <div className="h-full min-h-[128px] flex flex-col rounded-md text-left gap-4">
          <OnlyReadRichTextEditor
            name="description"
            json={descriptionJSON}
            value={description}
            limitHeight={false}
          />
        </div>
      ) : (
        <EmptyStateIllustration
          button={{
            label: 'Add job description',
            onClick: (): void => setActiveEditMode(true),
          }}
          title="Add your job description"
          description="Add a job description and tell recruiters important details for success in this role"
          image="/illustrations/emptyStateDossier/role-description-empty.svg"
          showSeparator={false}
          descriptionClassName="w-full mb-2 !text-gray-500"
          className="!p-0"
          contentClassName="!gap-2"
          titleClassName="!text-sm"
        />
      ),
    [description, descriptionJSON]
  );

  const editComponent = useMemo(
    () => (
      <div className="h-full min-h-[128px] flex flex-col rounded-md text-left gap-4">
        <RichTextEditor
          name="description"
          nameJSON="descriptionJSON"
          inputClassName="mt-10 !h-full min-h-[128px]"
        />
      </div>
    ),
    []
  );

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        descriptionJSON,
        description,
      }}
      onSubmit={(values): void => {
        if (saveAction) saveAction(values);
      }}
    >
      <Form>
        <EditCard
          saveAction={async (): Promise<void> => {
            await formRef.current?.submitForm();
            setActiveEditMode(false);
          }}
          cancelAction={(): void => {
            setActiveEditMode(false);
            if (cancelAction) cancelAction();
          }}
          viewStateComponent={viewComponent}
          editStateComponent={editComponent}
          title="Role details"
          loading={loading}
          editMode={editMode}
          activeEditMode={activeEditMode}
        />
      </Form>
    </Formik>
  );
};

export default RoleDescriptionCard;
