import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
}

const ListUlIcon: VFC<Props> = ({
  className = 'bi bi-list-ul',
  width = '16',
  height = '16',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6615 4C4.6615 3.72386 4.88536 3.5 5.1615 3.5H13.3333C13.6094 3.5 13.8333 3.72386 13.8333 4C13.8333 4.27614 13.6094 4.5 13.3333 4.5H5.1615C4.88536 4.5 4.6615 4.27614 4.6615 4ZM4.6615 8C4.6615 7.72386 4.88536 7.5 5.1615 7.5H13.3333C13.6094 7.5 13.8333 7.72386 13.8333 8C13.8333 8.27614 13.6094 8.5 13.3333 8.5H5.1615C4.88536 8.5 4.6615 8.27614 4.6615 8ZM4.6615 12C4.6615 11.7239 4.88536 11.5 5.1615 11.5H13.3333C13.6094 11.5 13.8333 11.7239 13.8333 12C13.8333 12.2761 13.6094 12.5 13.3333 12.5H5.1615C4.88536 12.5 4.6615 12.2761 4.6615 12Z"
      fill="#4B5563"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.15625 4C2.15625 3.72386 2.38011 3.5 2.65625 3.5H2.65649C2.93264 3.5 3.15649 3.72386 3.15649 4C3.15649 4.27614 2.93264 4.5 2.65649 4.5H2.65625C2.38011 4.5 2.15625 4.27614 2.15625 4ZM2.15625 8C2.15625 7.72386 2.38011 7.5 2.65625 7.5H2.65649C2.93264 7.5 3.15649 7.72386 3.15649 8C3.15649 8.27614 2.93264 8.5 2.65649 8.5H2.65625C2.38011 8.5 2.15625 8.27614 2.15625 8ZM2.15625 12C2.15625 11.7239 2.38011 11.5 2.65625 11.5H2.65649C2.93264 11.5 3.15649 11.7239 3.15649 12C3.15649 12.2761 2.93264 12.5 2.65649 12.5H2.65625C2.38011 12.5 2.15625 12.2761 2.15625 12Z"
      fill="#4B5563"
    />
  </svg>
);

export default ListUlIcon;
