const capitalizeString = (text: string): string => {
  const lowerCaseText = text.toLowerCase();
  const textSplit = lowerCaseText
    .split(' ')
    .map(
      (item) => item.charAt(0).toUpperCase() + item.substring(1, item.length)
    );
  return textSplit.join(' ');
};

export default capitalizeString;
