import 'yup-phone-lite';

import { LINKED_IN_URL } from '@headrace/constants';
import * as Yup from 'yup';

const SettingsGeneralFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .phone('US', 'Invalid phone number')
    .required('Phone number is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  linkedin: Yup.string().matches(LINKED_IN_URL, 'Invalid LinkedIn URL'),
  location: Yup.string().required('You must provide a valid location'),
});

export default SettingsGeneralFormSchema;
