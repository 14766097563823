import { ExternalLinkIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import type { VFC } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { AvatarPlaceholderIcon } from '../CustomIcons';
import Modal from './Modal';
import RatingInput from './RatingInput';
import ReviewInput from './ReviewInput';

export interface ReviewValue {
  review: string;
  overallRating: number;
  timeToPlacement: number;
  communication: number;
  timeliness: number;
  candidateSubmissionQuality: number;
  recruiterId: string;
}

export interface RecruiterToReview {
  id: string;
  name: string;
  photoUrl: string;
  candidateName: string;
  candidateLinkedin: string;
}

interface Props {
  recruiters: RecruiterToReview[];
  onSubmit: (values: ReviewValue[]) => void;
  open: boolean;
  onClose: () => void;
}

const MultiReviewModal: VFC<Props> = ({
  recruiters,
  onSubmit,
  open,
  onClose,
}) => {
  const [height, setHeight] = useState(0);
  const initalReview = recruiters.map((recruiter) => ({
    review: '',
    recruiterId: recruiter.id,
    overallRating: 0,
    timeToPlacement: 0,
    communication: 0,
    timeliness: 0,
    candidateSubmissionQuality: 0,
  }));
  const [review, setReview] = useState<ReviewValue[]>(initalReview);
  const [disableNextButton, setDisableNextButton] = useState<boolean>(false);
  const stepsData: {
    name: keyof ReviewValue;
    type: 'text' | 'rating';
    label: string;
  }[] = useMemo(
    () => [
      {
        name: 'communication',
        type: 'rating',
        label: 'Communication',
      },
      {
        name: 'timeliness',
        type: 'rating',
        label: 'Timeliness',
      },
      {
        name: 'candidateSubmissionQuality',
        type: 'rating',
        label: 'Candidate quality',
      },
      {
        name: 'timeToPlacement',
        type: 'rating',
        label: 'Time to placement',
      },
      {
        name: 'overallRating',
        type: 'rating',
        label: 'Overall rating',
      },
      {
        name: 'review',
        type: 'text',
        label: 'Public review',
      },
    ],
    []
  );

  const onChangeReview = (
    value: ReviewValue[keyof ReviewValue],
    index: number,
    key: keyof ReviewValue
  ): void => {
    if ((typeof value === 'number' && value > 0) || value !== '') {
      setDisableNextButton(false);
    }
    setReview((prev) => {
      const newReview = [...prev];
      newReview[index] = {
        ...newReview[index],
        [key]: value,
      };
      return newReview;
    });
  };

  const validateCurrentValue = useCallback(
    (index: number, currentStepByReview: number): boolean => {
      if (index !== 0) {
        const currentReview = review[index - 1];
        if (currentStepByReview === 0) {
          return !Object.values(currentReview).some((value) => value === 0);
        }
        return currentReview.review.length > 20;
      }
      return true;
    },
    [review]
  );

  const firstStep = useMemo(
    () => (
      <div className="flex flex-col items-center justify-center w-full mb-20 mt-10">
        <div className="relative w-40 h-20 mb-4">
          {recruiters.slice(0, 3).map((recruiter, index) => (
            <div
              className={classNames('absolute  top-0', {
                'left-0': index === 0 && recruiters.length !== 1,
                'left-10': index === 1 || recruiters.length === 1,
                'left-20': index === 2,
              })}
              key={recruiter.id}
            >
              {recruiter.photoUrl ? (
                <Image
                  className="block mx-auto h-20 w-20 rounded-full"
                  src={recruiter.photoUrl}
                  alt=""
                  width={80}
                  height={80}
                />
              ) : (
                <AvatarPlaceholderIcon className="w-20 h-20" />
              )}
            </div>
          ))}
        </div>
        <div className="text-lg font-medium text-gray-900">Leave a review</div>
        <div className="w-8 border-t-2 border-headraceYellow-700 my-2" />
        <div className="text-sm text-gray-500">
          You’ve had one or more placements on this role. Please leave a review
          for the recruiters who placed a candidate on this role for you.
        </div>
      </div>
    ),
    [recruiters]
  );

  const steps = useMemo(() => {
    const getSteps = (index: number): JSX.Element[] => {
      const textSteps: JSX.Element[] = [];
      stepsData.forEach((step) => {
        if (step.type === 'text') {
          textSteps.push(
            <ReviewInput
              key={`${step.name}-${index}`}
              handleChange={(value): void => {
                onChangeReview(value, index, step.name);
              }}
              value={review[index][step.name]}
              name={`${step.name}-${index}`}
              label={step.label}
              description="Minimum of 20 characters"
              style={{ height }}
            />
          );
        }
      });
      const ratingData = stepsData
        .filter((step) => step.type === 'rating')
        .map((step) => ({
          key: `${step.name}-${index}`,
          review: review[index][step.name] as number,
          onChange: (value: number): void => {
            onChangeReview(value, index, step.name);
          },
          label: step.label,
          bold: step.name === 'overallRating',
        }));
      return [
        <RatingInput
          ratings={ratingData}
          setHeight={(h): void => {
            setHeight(h);
          }}
        />,
        ...textSteps,
      ];
    };
    const stepsByRecruiter: JSX.Element[] = [firstStep];
    for (let i = 0; i < recruiters.length; i += 1) {
      stepsByRecruiter.push(...getSteps(i));
    }
    return stepsByRecruiter;
  }, [firstStep, height, recruiters.length, review, stepsData]);

  const header = useCallback(
    (currentReview: number, currentStep: number) => {
      if (currentStep === 0) return null;
      const { name, photoUrl, id, candidateName, candidateLinkedin } =
        recruiters[currentReview - 1];
      return (
        <div className="flex items-center gap-2 -mt-4">
          {photoUrl ? (
            <Image
              className="block mx-auto h-16 w-16 rounded-full"
              src={photoUrl}
              alt=""
              width={64}
              height={64}
            />
          ) : (
            <AvatarPlaceholderIcon className="w-16 h-16" />
          )}
          <div className="text-left">
            <Link href={`/profiles/${id}`} passHref>
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-500 text-lg !leading-none font-medium"
              >
                {name}ss
              </a>
            </Link>
            <div>
              <span className="text-gray-400 text-sm font-normal">
                Candidate:{' '}
              </span>
              <a
                href={candidateLinkedin}
                target="_blank"
                rel="noreferrer"
                className=" inline-flex text-blue-500 text-sm font-medium"
              >
                {candidateName}
                <ExternalLinkIcon className="pl-1 pt-1 w-4 h-4 text-blue-600" />
              </a>
            </div>

            {recruiters.length > 1 ? (
              <div className="text-gray-400 text-sm font-normal">{`${currentReview} of ${recruiters.length} recruiters`}</div>
            ) : null}
          </div>
        </div>
      );
    },
    [recruiters]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      steps={steps}
      onSubmit={(): void => {
        onSubmit(review);
        setReview(initalReview);
      }}
      header={header}
      numberOfReviews={2}
      validate={(currentReview, currentStep, currentIndex): void => {
        const isValid = validateCurrentValue(currentReview, currentStep);
        if (currentIndex > 0 && !isValid) {
          setDisableNextButton(true);
        } else {
          setDisableNextButton(false);
        }
      }}
      disableNextButton={disableNextButton}
    />
  );
};

export default MultiReviewModal;
