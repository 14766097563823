/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import LoadingSpinner from '../LoadingSpinner';
import reorderItemsMatrix from './reorderItems';

interface DnDGridProps<T extends { id: string }> {
  items: T[];
  rows: number;
  columns: number;
  rowHeigth: number;
  formatItem: (item: T) => JSX.Element;
  onOrderChange: (newOrder: T[]) => void;
}

const DnDGrid = <T extends { id: string }>(
  props: DnDGridProps<T>
): JSX.Element => {
  const { items, formatItem, onOrderChange, columns, rows, rowHeigth } = props;

  const createMatrix = useCallback(
    (itemsArray: T[]): T[][] => {
      const matrix: T[][] = [];
      for (let index = 0; index < rows; index += 1) {
        matrix.push(itemsArray.slice(index * columns, (index + 1) * columns));
      }
      return matrix;
    },
    [columns, rows]
  );
  const [itemsMatrix, setItemsMatrix] = useState<T[][]>(createMatrix(items));

  useEffect(() => {
    setItemsMatrix(createMatrix(items));
  }, [createMatrix, items]);

  return (
    <DragDropContext
      onDragEnd={(result: DropResult): void => {
        const { source, destination } = result;
        // dropped outside
        if (!destination) return;
        // dropped in the same position
        if (
          destination.droppableId === source.droppableId &&
          destination.index === source.index
        )
          return;
        // dropped on empty position
        if (
          !itemsMatrix[Number(destination.droppableId.split('_')[1])][
            destination.index
          ]
        )
          return;
        const newOrder = reorderItemsMatrix(itemsMatrix, source, destination);
        onOrderChange(newOrder.flat());
        setItemsMatrix(newOrder);
      }}
    >
      <div className="w-full flex flex-col gap-4 mb-4 overflow-hidden">
        {itemsMatrix.length > 0 &&
          Array.apply(0, Array(rows)).map((_a, rowIndex, rowArray) => (
            <Droppable
              key={`Row_${rowArray.length - rowIndex}`}
              droppableId={`droppable_${rowIndex}`}
              direction={columns === 1 ? 'vertical' : 'horizontal'}
            >
              {(droppableProvided): JSX.Element => (
                <div
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                  style={{
                    height: `${rowHeigth}px`,
                    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
                  }}
                  className="grid gap-4"
                >
                  {Array.apply(0, Array(columns)).map((_b, columnIndex) =>
                    itemsMatrix[rowIndex] &&
                    itemsMatrix[rowIndex][columnIndex] ? (
                      <Draggable
                        key={itemsMatrix[rowIndex][columnIndex].id}
                        draggableId={itemsMatrix[rowIndex][columnIndex].id}
                        index={columnIndex}
                      >
                        {(draggableProvided): JSX.Element => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            {formatItem(itemsMatrix[rowIndex][columnIndex])}
                          </div>
                        )}
                      </Draggable>
                    ) : null
                  )}
                </div>
              )}
            </Droppable>
          ))}
        {items.length > 0 && itemsMatrix.length < 1 && (
          <LoadingSpinner className="p-4" />
        )}
      </div>
    </DragDropContext>
  );
};

export default DnDGrid;
