import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';

interface Option {
  label: string;
  value: string | boolean;
  disabled?: boolean;
  className?: string;
  description?: string;
}

interface RadioButtonProps {
  label?: string;
  name: string;
  validatioError?: string;
  className?: string;
  options: Option[];
  labelClassName?: string;
  labelPosition?: 'left' | 'top';
  onChange?: (value: string) => void;
}

const RadioButton: React.VFC<RadioButtonProps> = (props) => {
  const {
    label,
    name,
    className,
    options,
    labelClassName,
    labelPosition = 'top',
    onChange,
  } = props;

  const [field] = useField<string>(name);

  const { setFieldValue } = useFormikContext();

  const radioClass = classNames(className || 'relative inline-flex');

  return (
    <RadioGroup
      value={field.value}
      onChange={(value): void => {
        setFieldValue(name, value);
        if (onChange) {
          onChange(value);
        }
      }}
    >
      <div
        className={classNames({
          'flex item-center flex-wrap justify-between w-full gap-5':
            labelPosition === 'left',
        })}
      >
        {label && (
          <RadioGroup.Label
            className={classNames(
              'text-sm font-medium text-gray-700',
              {
                'block mb-1': labelPosition === 'top',
                'flex items-center': labelPosition === 'left',
              },
              labelClassName
            )}
          >
            {label}
          </RadioGroup.Label>
        )}
        <div className={radioClass}>
          {options.map((option) => (
            <RadioGroup.Option
              key={option.label}
              value={option.value}
              className={({ active, checked }): string =>
                classNames(
                  option.disabled
                    ? 'opacity-25 cursor-not-allowed'
                    : 'cursor-pointer focus:outline-none ',
                  checked || option.value === field.value
                    ? 'border-headraceYellow-700 border-transparent outline-none border-2'
                    : 'bg-white text-gray-900 shover:bg-gray-50 border border-gray-300',
                  'relative inline-flex items-center px-4 py-2  bg-white text-sm font-medium text-gray-700 hover:bg-gray-50',
                  active && 'outline-none border border-headraceYellow-700',
                  option.className
                )
              }
              disabled={option.disabled}
              defaultChecked={option.value === field.value}
            >
              <RadioGroup.Label as="p">
                <div
                  className={classNames({
                    'flex flex-col': option.description,
                  })}
                >
                  {option.label}
                  {option.description && (
                    <span className="text-xs text-gray-500">
                      {option.description}
                    </span>
                  )}
                </div>
              </RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </div>
    </RadioGroup>
  );
};

export default RadioButton;
