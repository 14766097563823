import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
}

const ListOlIcon: VFC<Props> = ({
  className = 'bi bi-list-ol',
  width = '16',
  height = '16',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6615 4C4.6615 3.72386 4.88536 3.5 5.1615 3.5H13.3333C13.6094 3.5 13.8333 3.72386 13.8333 4C13.8333 4.27614 13.6094 4.5 13.3333 4.5H5.1615C4.88536 4.5 4.6615 4.27614 4.6615 4ZM4.6615 8C4.6615 7.72386 4.88536 7.5 5.1615 7.5H13.3333C13.6094 7.5 13.8333 7.72386 13.8333 8C13.8333 8.27614 13.6094 8.5 13.3333 8.5H5.1615C4.88536 8.5 4.6615 8.27614 4.6615 8ZM4.6615 12C4.6615 11.7239 4.88536 11.5 5.1615 11.5H13.3333C13.6094 11.5 13.8333 11.7239 13.8333 12C13.8333 12.2761 13.6094 12.5 13.3333 12.5H5.1615C4.88536 12.5 4.6615 12.2761 4.6615 12Z"
      fill="#4B5563"
    />
    <path
      d="M2.34607 3.39233C2.40407 3.36833 2.46407 3.34133 2.52607 3.31133C2.59007 3.27933 2.65207 3.24533 2.71207 3.20933C2.77207 3.17133 2.82907 3.13233 2.88307 3.09233C2.93907 3.05033 2.98907 3.00633 3.03307 2.96033H3.34507V5.03933H2.89807V3.54233C2.83807 3.58233 2.77107 3.61933 2.69707 3.65333C2.62307 3.68533 2.55107 3.71333 2.48107 3.73733L2.34607 3.39233Z"
      fill="#4B5563"
    />
    <path
      d="M3.62409 7.58326C3.62409 7.65926 3.60909 7.73226 3.57909 7.80226C3.54909 7.87226 3.51009 7.94026 3.46209 8.00626C3.41409 8.07026 3.36009 8.13226 3.30009 8.19226C3.24009 8.25226 3.18109 8.30926 3.12309 8.36326C3.09309 8.39126 3.06009 8.42326 3.02409 8.45926C2.99009 8.49326 2.95709 8.52826 2.92509 8.56426C2.89309 8.60026 2.86409 8.63426 2.83809 8.66626C2.81409 8.69626 2.79909 8.72126 2.79309 8.74126H3.68709V9.11626H2.30109C2.29709 9.09426 2.29509 9.06626 2.29509 9.03226C2.29509 8.99826 2.29509 8.97426 2.29509 8.96026C2.29509 8.86426 2.31009 8.77626 2.34009 8.69626C2.37209 8.61626 2.41309 8.54226 2.46309 8.47426C2.51309 8.40426 2.56909 8.33926 2.63109 8.27926C2.69509 8.21926 2.75809 8.15926 2.82009 8.09926C2.86809 8.05326 2.91309 8.01026 2.95509 7.97026C2.99709 7.92826 3.03409 7.88826 3.06609 7.85026C3.09809 7.81026 3.12309 7.77126 3.14109 7.73326C3.15909 7.69326 3.16809 7.65326 3.16809 7.61326C3.16809 7.52526 3.14309 7.46326 3.09309 7.42726C3.04309 7.39126 2.98109 7.37326 2.90709 7.37326C2.85309 7.37326 2.80209 7.38226 2.75409 7.40026C2.70809 7.41626 2.66509 7.43626 2.62509 7.46026C2.58709 7.48226 2.55409 7.50526 2.52609 7.52926C2.49809 7.55126 2.47709 7.56926 2.46309 7.58326L2.24109 7.27126C2.32909 7.18926 2.43109 7.12226 2.54709 7.07026C2.66509 7.01626 2.79109 6.98926 2.92509 6.98926C3.04709 6.98926 3.15209 7.00326 3.24009 7.03126C3.32809 7.05926 3.40009 7.09926 3.45609 7.15126C3.51409 7.20126 3.55609 7.26326 3.58209 7.33726C3.61009 7.40926 3.62409 7.49126 3.62409 7.58326Z"
      fill="#4B5563"
    />
    <path
      d="M2.87553 13.2773C2.82153 13.2773 2.76453 13.2733 2.70453 13.2653C2.64453 13.2593 2.58653 13.2503 2.53053 13.2383C2.47453 13.2263 2.42353 13.2133 2.37753 13.1993C2.33153 13.1853 2.29553 13.1723 2.26953 13.1603L2.35653 12.7883C2.40853 12.8103 2.47453 12.8343 2.55453 12.8603C2.63653 12.8843 2.73753 12.8963 2.85753 12.8963C2.99553 12.8963 3.09653 12.8703 3.16053 12.8183C3.22453 12.7663 3.25653 12.6963 3.25653 12.6083C3.25653 12.5543 3.24453 12.5093 3.22053 12.4733C3.19853 12.4353 3.16753 12.4053 3.12753 12.3833C3.08753 12.3593 3.03953 12.3433 2.98353 12.3353C2.92953 12.3253 2.87153 12.3203 2.80953 12.3203H2.63553V11.9603H2.83353C2.87753 11.9603 2.91953 11.9563 2.95953 11.9483C3.00153 11.9403 3.03853 11.9273 3.07053 11.9093C3.10253 11.8893 3.12753 11.8633 3.14553 11.8313C3.16553 11.7973 3.17553 11.7553 3.17553 11.7053C3.17553 11.6673 3.16753 11.6343 3.15153 11.6063C3.13553 11.5783 3.11453 11.5553 3.08853 11.5373C3.06453 11.5193 3.03553 11.5063 3.00153 11.4983C2.96953 11.4883 2.93653 11.4833 2.90253 11.4833C2.81653 11.4833 2.73653 11.4963 2.66253 11.5223C2.59053 11.5483 2.52453 11.5803 2.46453 11.6183L2.30553 11.2913C2.33753 11.2713 2.37453 11.2503 2.41653 11.2283C2.46053 11.2063 2.50853 11.1863 2.56053 11.1683C2.61253 11.1503 2.66753 11.1353 2.72553 11.1233C2.78553 11.1113 2.84853 11.1053 2.91453 11.1053C3.03653 11.1053 3.14153 11.1203 3.22953 11.1503C3.31953 11.1783 3.39353 11.2193 3.45153 11.2733C3.50953 11.3253 3.55253 11.3873 3.58053 11.4593C3.60853 11.5293 3.62253 11.6063 3.62253 11.6903C3.62253 11.7723 3.59953 11.8523 3.55353 11.9303C3.50753 12.0063 3.44553 12.0643 3.36753 12.1043C3.47553 12.1483 3.55853 12.2143 3.61653 12.3023C3.67653 12.3883 3.70653 12.4923 3.70653 12.6143C3.70653 12.7103 3.69053 12.7993 3.65853 12.8813C3.62653 12.9613 3.57653 13.0313 3.50853 13.0913C3.44053 13.1493 3.35353 13.1953 3.24753 13.2293C3.14353 13.2613 3.01953 13.2773 2.87553 13.2773Z"
      fill="#4B5563"
    />
  </svg>
);

export default ListOlIcon;
