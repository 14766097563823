// Slider.js

import 'react-input-range/lib/css/index.css';
import './Slider.css';

import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import InputRange from 'react-input-range';

import ErrorText from '../../ErrorText';

interface SliderInputProps {
  name: string;
  min: number;
  max: number;
  step: number;
  label: string;
  disabled?: boolean;
  className?: string;
  formatLabel?: (value: number) => string;
  showTooltipFormat?: boolean;
  hideLabels?: boolean;
  showValue?: boolean;
  onChange?: (value: number) => void;
  minValueAllowed?: number;
}

const SliderInput: React.VFC<SliderInputProps> = (props) => {
  const {
    name,
    min,
    max,
    step,
    label,
    disabled,
    className,
    formatLabel = (val: number): string => val.toString(),
    showTooltipFormat = false,
    hideLabels = false,
    showValue = false,
    onChange,
    minValueAllowed,
  } = props;
  const [field, meta] = useField<number>(name);
  const { setFieldValue } = useFormikContext();

  const DEFAULT_CLASS_NAMES = {
    activeTrack: 'input-range__track input-range__track--active',
    disabledInputRange: 'input-range input-range--disabled',
    inputRange: 'input-range',
    labelContainer: 'input-range__label-container',
    maxLabel: 'input-range__label input-range__label--max',
    minLabel: 'input-range__label input-range__label--min',
    slider: 'input-range__slider',
    sliderContainer: 'input-range__slider-container',
    track: 'input-range__track input-range__track--background',
    valueLabel: 'input-range__label input-range__label--value',
  };

  return (
    <div
      className={classNames('slider', className, {
        'slider__hidden-label': hideLabels,
      })}
    >
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-900"
        >
          {label}
        </label>
      )}
      {showValue && (
        <div className="flex justify-between">
          <span />
          <span className="-mr-1.5 mb-1 text-sm">
            {formatLabel(field.value)}
          </span>
        </div>
      )}
      <InputRange
        classNames={{
          ...DEFAULT_CLASS_NAMES,
          labelContainer: showTooltipFormat
            ? 'input-range__label-container'
            : 'hidden',
        }}
        minValue={min}
        maxValue={max}
        name={field.name}
        step={step}
        onChange={(val): void => {
          const value = minValueAllowed
            ? Math.max(val as number, minValueAllowed)
            : val;
          setFieldValue(field.name, value);
          if (onChange) onChange(value as number);
        }}
        value={field.value}
        disabled={disabled}
        formatLabel={formatLabel}
      />
      <ErrorText
        id={`${field.name}-error`}
        error={meta.error || null}
        touched={meta.touched}
      />
    </div>
  );
};

export default SliderInput;
