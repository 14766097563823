import { FeeScheduleTypeEnum } from '@headrace/types';
import * as Yup from 'yup';

const RoleConfigurationSchema = Yup.object().shape({
  bountyPrice: Yup.number().when(
    'bountyType',
    (bountyType: FeeScheduleTypeEnum) =>
      bountyType === FeeScheduleTypeEnum.CASH_PERCENT
        ? Yup.number()
            .max(100, 'Percentage must be less than 100%')
            .min(10, 'Percentage must be at least 10%')
            .required('This field is required')
            .typeError('This field is required')
        : Yup.number()
            .min(2500, 'Bounty must be at least $2,500')
            .required('This field is required')
            .typeError('This field is required')
  ),
});

export default RoleConfigurationSchema;
