import classNames from 'classnames';

interface Props {
  label: string;
  value: string;
  className?: string;
}

const PaymentTermItemBox: React.VFC<Props> = ({ label, value, className }) => (
  <div className={classNames('flex flex-col gap-2 text-sm', className)}>
    <span className="font-medium">{label}</span>
    <span className="font-normal truncate">{value}</span>
  </div>
);
export default PaymentTermItemBox;
