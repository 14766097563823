import {
  FeeScheduleTypeEnum,
  FeeTriggerTypeEnum,
  FeeTypeEnum,
} from '@headrace/types';

export default function getFeeType(
  feeTriggerType: FeeTriggerTypeEnum,
  feeScheduleType: FeeScheduleTypeEnum
): FeeTypeEnum {
  switch (feeTriggerType) {
    case FeeTriggerTypeEnum.ONE_TIME_FEE_SEARCH_AGRREEMENT_EXECUTION:
      return FeeTypeEnum.INITIAL_FEE;
    case FeeTriggerTypeEnum.RECURRING:
      return FeeTypeEnum.RECURRING_MONTHLY_FEE;
    case FeeTriggerTypeEnum.PERFORMANCE_CANDIDATE_ACCEPT:
      return FeeTypeEnum.PERFORMANCE_FEE;
    case FeeTriggerTypeEnum.CANDIDATE_OFFER_SIGNED:
    case FeeTriggerTypeEnum.CANDIDATE_START_DATE:
      if (
        [
          FeeScheduleTypeEnum.CASH_FIXED,
          FeeScheduleTypeEnum.CASH_PERCENT,
        ].includes(feeScheduleType)
      )
        return FeeTypeEnum.CANDIDATE_PLACEMENT_FEE;
      return FeeTypeEnum.EQUITY_PLACEMENT_FEE;
    case FeeTriggerTypeEnum.ONE_TIME_FEE_RELATIVE:
    case FeeTriggerTypeEnum.ONE_TIME_FEE_ABSOLUTE:
      return FeeTypeEnum.CUSTOM;
    default:
      throw new Error(
        "Couldn't get fee type. Invalid fee trigger type or fee schedule type."
      );
  }
}
