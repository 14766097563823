import type { DiscoverRecruiter, PublicProfile } from '@headrace/types';
import type { VFC } from 'react';
import { useMemo } from 'react';

import OnlyReadRichTextEditor from '../../forms/fields/formik/RichTextEditor/OnlyReadRichTextEditor';
import ProfileCard from '../ProfileCard';
import {
  CommonPlacedIndustries,
  PlacementGeographies,
  TopRoleFunctions,
} from '../profileLayout';
import ClientCompanyStage from '../profileLayout/ClientCompanyStage';
import CompanyLogosCarousel from '../profileLayout/CompanyLogosCarousel';
import EndorsementsCard from '../profileLayout/EndorsementsCard';
import MostPlacedSenority from '../profileLayout/MostPlacedSenority';
import PlacementHistory from '../profileLayout/PlacementHistory';
import ProfileBasicData from '../profileLayout/ProfileBasicData';
import TimeToPlacementWidget from '../profileLayout/TimeToPlacementWidget';
import Blog from './Blog';
import DiscoverRecruiters from './DiscoverRecruiters';

export interface PublicProfileLayoutProps extends PublicProfile {
  publicRecruiters: DiscoverRecruiter[];
  handleViewProfile: (recruiterId: string) => void;
}

const PublicProfileLayout: VFC<PublicProfileLayoutProps> = ({
  timeToPlacement,
  companyStages,
  topRoleFunctions,
  placementsGeographies,
  placementsCompanies,
  commonPlacedIndustries,
  publicRecruiters,
  recruiter,
  topRoleSeniority,
  endorsements,
  handleViewProfile,
  placements,
}) => {
  const companyLogos = useMemo(
    () => placementsCompanies.filter((company) => company.logoUrl),
    [placementsCompanies]
  );

  const placementsData = placements.map((placement) => ({
    ...placement,
    cityName: placement.city
      ? `${placement.city.cityName}, ${placement.city.stateAbbrev}`
      : '',
    roleSeniority: placement.roleSeniority.name,
    roleFunction: placement.roleFunction?.name ?? '',
    roleTitle: placement.roleTitle?.name ?? '',
  }));

  return (
    <div className="flex flex-col text-headraceBlack-800">
      <div className="mb-6 mx-auto grid grid-cols-1 gap-6 xl:grid-flow-col-dense xl:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 w-full">
        <div className="w-full xl:col-span-2 lg:col-span-full">
          <ProfileBasicData
            profileImage={recruiter.profileImage}
            name={recruiter.name || 'No Name'}
            title={recruiter.title}
            roleFunctions={recruiter.roleFunctions}
            maxPricePercent={recruiter.maxPricePercent}
            minPricePercent={recruiter.minPricePercent}
            location={recruiter.location}
            publicProfile
            searchPreference={recruiter.searchPreference}
          />
        </div>
        <TimeToPlacementWidget recruiter={timeToPlacement} average={3.5} />
      </div>
      {companyLogos && companyLogos.length ? (
        <CompanyLogosCarousel placementCompanies={companyLogos} />
      ) : null}

      <ProfileCard className="mb-6">
        <div className="h-full min-h-[128px] flex flex-col rounded-md text-left gap-4">
          <div className="text-2xl font-bold">About this recruiter</div>
          <OnlyReadRichTextEditor
            name="bio"
            json={recruiter.bioJSON || ''}
            value={recruiter.bio || ''}
          />
        </div>
      </ProfileCard>

      <div className="text-headraceBlack-800 mb-6">
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-4 md:col-span-full lg:col-span-2">
            <ClientCompanyStage companyStages={companyStages} />
          </div>
          <div className="col-span-4 md:col-span-full lg:col-span-2">
            <CommonPlacedIndustries
              commonPlacedIndustries={commonPlacedIndustries}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 mb-6">
        <div className="col-span-4 lg:col-span-2 flex flex-col gap-6">
          <TopRoleFunctions topRoleFunctions={topRoleFunctions} />
        </div>
        <div className="col-span-4 lg:col-span-2 h-full">
          <div className="flex flex-col gap-4 h-full lg:justify-center">
            <MostPlacedSenority topRoleSeniority={topRoleSeniority} />
          </div>
        </div>
      </div>
      {placementsData && placementsData.length ? (
        <PlacementHistory placements={placementsData} entriesPerPage={10} />
      ) : null}
      {endorsements && endorsements.length ? (
        <EndorsementsCard endorsements={endorsements} />
      ) : null}

      <ProfileCard>
        <PlacementGeographies placementsData={placementsGeographies} />
      </ProfileCard>
      {publicRecruiters && publicRecruiters.length ? (
        <DiscoverRecruiters
          recruiters={publicRecruiters}
          handleViewProfile={handleViewProfile}
        />
      ) : null}
      <Blog />
    </div>
  );
};

export default PublicProfileLayout;
