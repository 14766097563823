import { Dialog, Transition } from '@headlessui/react';
import type { ChangeEvent, VFC } from 'react';
import { Fragment, useState } from 'react';
import Cropper from 'react-easy-crop';
import type { Area } from 'react-easy-crop/types';

import Button from '../Button';

interface Props {
  open: boolean;
  onClose: () => void;
  image: string | null;
  onUpload: (croppedArea: Area | null) => void;
  updating?: boolean;
  uploading?: boolean;
  minZoom?: number;
  aspectRatio?: number;
  cropShape?: 'rect' | 'round';
  cropSize?: { width: number; height: number };
}

const CropModal: VFC<Props> = ({
  open,
  onClose,
  image,
  onUpload,
  updating = false,
  uploading = false,
  minZoom = 0.5,
  aspectRatio = 1,
  cropShape = 'rect',
  cropSize,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(minZoom);
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);

  const handleZoom = (event: ChangeEvent<HTMLInputElement>): void => {
    setZoom(parseFloat(event.target.value));
  };

  const onCropComplete = (
    _croppedAreaPercentage: Area,
    croppedAreaPixels: Area
  ): void => setCroppedArea(croppedAreaPixels);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Crop your image
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Use the crop tool to select the part of the image you want
                      to use.
                    </p>
                  </div>
                </div>
                <div className="">
                  {image && (
                    <>
                      <div className="mt-5 relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1 ">
                        <Cropper
                          image={image}
                          crop={crop}
                          zoom={zoom}
                          cropShape={cropShape}
                          minZoom={minZoom}
                          aspect={aspectRatio}
                          showGrid={false}
                          restrictPosition={false}
                          onCropChange={setCrop}
                          onZoomChange={setZoom}
                          onCropComplete={onCropComplete}
                          cropSize={cropSize}
                        />
                      </div>
                      <div className="relative pt-1">
                        <input
                          min={minZoom}
                          max={3}
                          step={0.1}
                          type="range"
                          value={zoom}
                          onChange={handleZoom}
                          className="  form-range w-full  h-6  p-0 bg-transparent"
                          id="customRange1"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <Button
                  className="w-full sm:w-full justify-center"
                  onClick={(): void => onUpload(croppedArea)}
                  disabled={updating || uploading}
                  loading={updating || uploading}
                >
                  Upload
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CropModal;
