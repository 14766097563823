import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

// use to format a UTC date, ti is to not use the new date() on UTC date because this generate an issue
export default function formatInTimeZone(
  date: string | Date,
  formatStr: string
): string {
  const parsedTime = parseISO(new Date(date).toISOString());
  if (date !== '') return format(utcToZonedTime(parsedTime, 'UTC'), formatStr);
  return '';
}
