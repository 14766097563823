import classNames from 'classnames';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import type { MultiValue, SingleValue } from 'react-select';

import type { OptionsProps } from './forms';
import { BasicSelect } from './forms';
import TabbedNavigationItem from './TabbedNavigationItem';
import type { NavigationItem } from './TailwindLayout';

type Props = {
  navigation: NavigationItem[];
  className?: string;
};

const TabbedNavigation: FC<Props> = ({ children, navigation, className }) => {
  const router = useRouter();

  const isCurrentTab = useCallback(
    (navItem: NavigationItem): boolean => {
      if (navItem.href === router.pathname) return true;

      if (navItem.children) return navItem.children.includes(router.pathname);
      return false;
    },
    [router.pathname]
  );

  const handleTabSelectChange = useCallback(
    async (
      newValue: MultiValue<OptionsProps> | SingleValue<OptionsProps>
    ): Promise<void> => {
      const url = newValue;
      if (url && 'value' in url) {
        await router.push(url.value, undefined, { shallow: true });
      }
    },
    [router]
  );

  const optionsSelect = navigation.map((item) => ({
    value: item.href,
    label: item.name,
  }));

  const valueSelect = useMemo((): OptionsProps => {
    const tab = optionsSelect.find((item) => item.value === router.pathname);
    return tab || { value: '', label: '' };
  }, [optionsSelect, router.pathname]);

  return (
    <div className="bg-white rounded-lg shadow w-full">
      {/* Mobile Select Tabs */}
      <div className="lg:hidden border-b border-gray-200 mb-4 p-2">
        <BasicSelect
          id="selected-tab"
          value={valueSelect}
          options={optionsSelect}
          onChange={handleTabSelectChange}
          isSearchable={false}
        />
      </div>

      {/* Desktop Navigation Tabs */}
      <div className="px-4 sm:px-6 md:px-0">
        <div className="border-b border-gray-200 px-6 hidden lg:block">
          <nav className="-mb-px flex space-x-10">
            {navigation.map((tab) => (
              <TabbedNavigationItem
                isSelected={isCurrentTab(tab)}
                tab={tab}
                key={`${tab.name}-index`}
              />
            ))}
          </nav>
        </div>

        {/* Content */}
        <div
          className={classNames(
            'divide-y divide-gray-200 p-6 shadow min-h-[540px] flex',
            className
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default TabbedNavigation;
