import { CheckIcon, MinusIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';

type IconState = (props: { className?: string }) => JSX.Element;
const IconStateOff: IconState = ({ className }) => (
  <CheckIcon
    className={classNames('border border-gray-300 text-white', className)}
  />
);
const IconStateHalf: IconState = ({ className }) => (
  <MinusIcon
    className={classNames(
      'bg-headraceYellow-700 stroke-black text-black p-[1.5px]',
      className
    )}
  />
);
const IconStateOn: IconState = ({ className }) => (
  <CheckIcon
    className={classNames(
      'bg-headraceYellow-700 stroke-black text-black p-[1.5px]',
      className
    )}
  />
);

export enum NotificationCheckboxState {
  OFF = 'OFF',
  HALF = 'HALF',
  ON = 'ON',
}
type NotificationCheckboxProps = (props: {
  description?: string;
  label?: string;
  value: NotificationCheckboxState;
  onChange: (state: NotificationCheckboxState) => void;
  className?: string;
}) => JSX.Element;
const NotificationCheckbox: NotificationCheckboxProps = ({
  description,
  label,
  value,
  onChange,
  className,
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const CurrentIcon = useMemo<IconState | null>(() => {
    if (currentValue === NotificationCheckboxState.OFF) return IconStateOff;
    if (currentValue === NotificationCheckboxState.HALF) return IconStateHalf;
    if (currentValue === NotificationCheckboxState.ON) return IconStateOn;
    return null;
  }, [currentValue]);

  const switchState = useCallback(() => {
    let newState = NotificationCheckboxState.OFF;
    if (currentValue === NotificationCheckboxState.ON)
      newState = NotificationCheckboxState.OFF;
    else if (currentValue === NotificationCheckboxState.OFF)
      newState = NotificationCheckboxState.ON;
    else if (currentValue === NotificationCheckboxState.HALF)
      newState = NotificationCheckboxState.OFF;
    setCurrentValue(newState);
    onChange(newState);
  }, [currentValue, onChange]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className={classNames('flex items-center', className)}>
      <div className="self-start">
        <button type="button" onClick={switchState}>
          {CurrentIcon && (
            <CurrentIcon className="w-4 h-4 rounded active:ring-2 active:ring-offset-2 active:ring-headraceYellow-700" />
          )}
        </button>
      </div>
      {(label || description) && (
        <button
          type="button"
          className="text-left ml-3 text-sm"
          onClick={switchState}
        >
          {label && <span className="font-medium text-gray-700">{label}</span>}
          {description && <p className="text-gray-500">{description}</p>}
        </button>
      )}
    </div>
  );
};

export default NotificationCheckbox;
