import classNames from 'classnames';
import Head from 'next/head';
import Link from 'next/link';
import type { VFC } from 'react';

import Button from './Button';

interface Props {
  withHomeButton?: boolean;
  className?: string;
}

const NotFound404: VFC<Props> = ({ withHomeButton, className }) => (
  <div className={classNames('flex justify-center', className)}>
    <Head>
      <title>Page not found</title>
    </Head>
    <h1 className="text-headraceYellow-700 text-4xl sm:text-5xl font-bold px-6">
      404
    </h1>
    <div>
      <div className="border-l-2 px-6">
        <h2 className="text-4xl sm:text-5xl font-bold mb-2">Page not found</h2>
        <p className="text-gray-400 text-sm sm:text-base font-semibold">
          Please check the URL in the address bar and try again.
        </p>
      </div>
      {withHomeButton && (
        <Link href="/" passHref>
          <a>
            <Button className="mt-9">Go back home</Button>
          </a>
        </Link>
      )}
    </div>
  </div>
);

export default NotFound404;
