import classNames from 'classnames';
import { useField } from 'formik';

interface RadioButtonProps {
  label?: string;
  name: string;
  validatioError?: string;
  optionValue: string;
  className?: string;
  onChange?: InputChangeHandler;
}

interface InputChangeHandler {
  (): void;
}

const RadioButton: React.VFC<RadioButtonProps> = (props) => {
  const { label, name, optionValue, className, onChange } = props;

  const [field] = useField<string>(name);

  const radioClass = classNames(
    'peer-checked:ring-headraceYellow-500 peer-checked:border-transparent text-center',
    'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 peer-checked:z-10 peer-checked:outline-none peer-checked:ring-1',
    className
  );
  return (
    <div>
      <input
        type="radio"
        name={field.name}
        id={optionValue}
        onChange={onChange || field.onChange}
        onBlur={field.onBlur}
        checked={field.value === optionValue}
        value={optionValue}
        className="text-headraceYellow-700 focus:outline-headraceYellow-500"
      />
      {label && (
        <label htmlFor={optionValue} className={radioClass}>
          {label}
        </label>
      )}
    </div>
  );
};

export default RadioButton;
