import type { CompanyPerk, CompanyPerksCardData } from '@headrace/types';
import { CompanyPerkEnum } from '@headrace/types';
import Image from 'next/image';
import type { VFC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import EditCard from '../../EditCard';
import EmptyStateIllustration from '../../EmptyStateIllustration';
import LoadingSpinner from '../../LoadingSpinner';
import PerkContainer from './PerkContainer';

export interface CompanyPerksCardProps extends CompanyPerksCardData {
  companyName: string;
  editMode?: boolean;
  refetchLoading?: boolean;
}

const sortPerks = (perks: CompanyPerk[]): CompanyPerk[] => {
  const sortingArr = Object.keys(CompanyPerkEnum);
  const arrayForSort = [...perks];
  return arrayForSort.sort(
    (a, b) => sortingArr.indexOf(a.type) - sortingArr.indexOf(b.type)
  );
};

const CompanyPerksCard: VFC<CompanyPerksCardProps> = (props) => {
  const {
    perks,
    cancelAction,
    editMode,
    loading,
    saveAction,
    companyName,
    refetchLoading,
  } = props;

  const [activeEditMode, setActiveEditMode] = useState(false);
  const [perksList, setPerksList] = useState(perks);
  const [checkedPerks, setCheckedPerks] = useState<CompanyPerkEnum[]>(
    Object.values(CompanyPerkEnum).filter((perk) =>
      perksList.some((p) => p.type === perk)
    )
  );

  useEffect(() => {
    setPerksList(perks);
  }, [perks]);

  const viewComponent = useMemo(
    () =>
      perksList.length > 0 ? (
        <div className="xl:grid md:grid sm:flex gap-y-2 gap-x-6 mx-auto xl:grid-cols-4 md:grid-cols-3 sm:flex-wrap sm:justify-around">
          {sortPerks(perksList).map((perk, index) => (
            <PerkContainer key={perk.id} index={index} perk={perk} />
          ))}
        </div>
      ) : (
        <EmptyStateIllustration
          button={{
            label: 'Add perks',
            onClick: (): void => setActiveEditMode(true),
          }}
          title="Add some cool perks!"
          description="Tell recruiters and candidates what kind of perks they get by working with your company."
          image={
            <div className="max-w-[240px] mx-auto">
              <Image
                src="/illustrations/emptyStateDossier/company-perks-empty.svg"
                alt="Company perks illustration"
                layout="responsive"
                width={240}
                height={193.5}
                objectFit="contain"
              />
            </div>
          }
          showSeparator={false}
          className="!py-0"
          contentClassName="!gap-2"
          titleClassName="!text-sm text-headraceBlack-700 font-medium"
          descriptionClassName="text-gray-500 font-normal text-sm mb-2"
        />
      ),
    [perksList]
  );

  const editComponent = useMemo(() => {
    const aditionalPerks = Object.values(CompanyPerkEnum).filter(
      (perk) => !perksList.some((p) => p.type === perk)
    );
    return (
      <div className="xl:grid md:grid sm:flex gap-y-2 gap-x-6 mx-auto xl:grid-cols-4 md:grid-cols-3 sm:flex-wrap sm:justify-around">
        {sortPerks([
          ...perksList,
          ...aditionalPerks.map((ap) => ({ id: ap, type: ap })),
        ]).map((perk, index) => (
          <PerkContainer
            key={perk.id}
            index={index}
            perk={perk}
            edit
            checked={checkedPerks.includes(perk.type)}
            onChange={(newValue): void => {
              setCheckedPerks((prev) => {
                if (newValue && !prev.includes(perk.type)) {
                  return [...prev, perk.type];
                }
                return prev.filter((item) => item !== perk.type);
              });
            }}
          />
        ))}
      </div>
    );
  }, [checkedPerks, perksList]);

  return (
    <EditCard
      saveAction={(): void => {
        if (saveAction) {
          saveAction(checkedPerks.map((perk) => ({ type: perk })));
        }
      }}
      cancelAction={(): void => {
        setActiveEditMode(false);
        setCheckedPerks(
          Object.values(CompanyPerkEnum).filter((perk) =>
            perksList.some((p) => p.type === perk)
          )
        );
        if (cancelAction) cancelAction();
      }}
      viewStateComponent={loading ? <LoadingSpinner /> : viewComponent}
      editStateComponent={loading ? <LoadingSpinner /> : editComponent}
      title={`${companyName} perks`}
      loading={loading}
      editMode={editMode}
      activeEditMode={activeEditMode}
      refetchLoading={refetchLoading}
    />
  );
};

export default CompanyPerksCard;
