/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-props-no-spreading */
import { CandidateActivityHistoryActivityType } from '@headrace/types';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';

import DroppableCandidate from './droppableCandidate';
import type { CandidateData } from './types';

interface KanbanCandidatesProps {
  candidates: CandidateData[];
  getOptionsMenu: (
    candidateId: string,
    candidateStatus: string,
    _searchAgreementId?: string | null,
    marketplaceVisibility?: string,
    agency?: {
      id: string;
      name: string;
      recruiterId: string;
    }
  ) => ReactNode;
  onCandidateStatusChange: (
    candidateId: string,
    status: CandidateActivityHistoryActivityType
  ) => void;
  isCollaborationRecruiter?: boolean;
  showAwaitingReview?: boolean;
  disableDrag?: boolean;
  employerSide?: boolean;
  handleCandidateDetails?: (candidateId: string) => void;
}

const KanbanCandidates: React.FC<KanbanCandidatesProps> = ({
  candidates,
  getOptionsMenu,
  onCandidateStatusChange,
  showAwaitingReview = false,
  isCollaborationRecruiter = false,
  disableDrag = false,
  employerSide = false,
  handleCandidateDetails,
}) => {
  const [currentDragging, setCurrentDragging] =
    useState<CandidateActivityHistoryActivityType>();

  const [awaitingReview, setAwaitingReview] = useState<boolean>(false);

  useEffect(() => {
    if (showAwaitingReview !== undefined && showAwaitingReview !== null)
      setAwaitingReview(showAwaitingReview);
    if (
      candidates.find(
        (candidate) =>
          candidate.status ===
          CandidateActivityHistoryActivityType.SUBMITTED_TO_RECRUITER
      ) &&
      showAwaitingReview
    )
      setAwaitingReview(true);
    else setAwaitingReview(false);
  }, [candidates, showAwaitingReview]);

  const columnClassNames = 'col-span-1 snap-always snap-center';
  return (
    <DragDropContext
      onDragEnd={(result: DropResult): void => {
        if (disableDrag) return;
        const { source, destination } = result;

        // if the user drops outside of a droppable destination
        if (!destination) return;

        // If the user drags and drops back in the same position
        if (
          destination.droppableId === source.droppableId &&
          destination.index === source.index
        ) {
          return;
        }

        const candidate = candidates.find((c) => c.id === result.draggableId);
        if (!candidate) return;
        onCandidateStatusChange(
          candidate.id,
          CandidateActivityHistoryActivityType[
            result.destination
              ?.droppableId as keyof typeof CandidateActivityHistoryActivityType
          ]
        );
      }}
      onDragStart={(a): void => {
        if (disableDrag) return;
        const candidate = candidates.find((c) => c.id === a.draggableId);
        if (!candidate) return;
        setCurrentDragging(candidate.status);
      }}
    >
      <div className="bg-white shadow divide-y divide-gray-200 text-headraceBlack-700 overflow-x-auto w-[85vw] md:w-full h-full">
        <div
          className={classNames(
            'grid gap-4 py-5 m-5 snap-x  snap-mandatory min-w-[1000px]',
            awaitingReview ? 'grid-cols-5' : 'grid-cols-4'
          )}
        >
          {awaitingReview ? (
            <div className={columnClassNames}>
              <span className="text-gray-500 text-lg">
                {isCollaborationRecruiter
                  ? 'Awaiting review'
                  : 'Awaiting your review'}
              </span>
              <DroppableCandidate
                candidates={candidates.filter(
                  (candidate) =>
                    candidate.status ===
                    CandidateActivityHistoryActivityType.SUBMITTED_TO_RECRUITER
                )}
                allowedTypes={[
                  CandidateActivityHistoryActivityType.SUBMITTED_TO_RECRUITER,
                ]}
                currentDragging={currentDragging}
                droppableId={
                  CandidateActivityHistoryActivityType.SUBMITTED_TO_RECRUITER
                }
                type="CANDIDATE"
                getOptionsMenu={getOptionsMenu}
                showSplitIcon={showAwaitingReview}
                employerSide={employerSide}
                handleCandidateDetails={handleCandidateDetails}
              />
            </div>
          ) : null}
          <div className={columnClassNames}>
            <span className="text-gray-500 text-lg  inset-0">In review</span>
            <DroppableCandidate
              candidates={candidates.filter(
                (candidate) =>
                  candidate.status ===
                    CandidateActivityHistoryActivityType.SUBMITTED ||
                  candidate.status ===
                    CandidateActivityHistoryActivityType.SUBMITTED_TO_HEADRACE
              )}
              allowedTypes={[
                CandidateActivityHistoryActivityType.SUBMITTED_TO_RECRUITER,
              ]}
              currentDragging={currentDragging}
              droppableId={CandidateActivityHistoryActivityType.SUBMITTED}
              type="CANDIDATE"
              getOptionsMenu={getOptionsMenu}
              employerSide={employerSide}
              showSplitIcon={showAwaitingReview}
              handleCandidateDetails={handleCandidateDetails}
            />
          </div>
          <div className={columnClassNames}>
            <span className="text-gray-500 text-lg">Interviewing</span>
            <DroppableCandidate
              candidates={candidates.filter(
                (candidate) =>
                  candidate.status ===
                  CandidateActivityHistoryActivityType.IN_PROCESS
              )}
              allowedTypes={
                employerSide
                  ? [
                      CandidateActivityHistoryActivityType.SUBMITTED,
                      CandidateActivityHistoryActivityType.REJECTED,
                    ]
                  : [CandidateActivityHistoryActivityType.SUBMITTED]
              }
              currentDragging={currentDragging}
              droppableId={CandidateActivityHistoryActivityType.IN_PROCESS}
              type="CANDIDATE"
              getOptionsMenu={getOptionsMenu}
              employerSide={employerSide}
              showSplitIcon={showAwaitingReview}
              handleCandidateDetails={handleCandidateDetails}
            />
          </div>
          <div className={columnClassNames}>
            <span className="text-gray-500 text-lg">Hired</span>
            <DroppableCandidate
              candidates={candidates.filter(
                (candidate) =>
                  candidate.status ===
                  CandidateActivityHistoryActivityType.OFFER_ACCEPTED
              )}
              allowedTypes={[CandidateActivityHistoryActivityType.IN_PROCESS]}
              currentDragging={currentDragging}
              droppableId={CandidateActivityHistoryActivityType.OFFER_ACCEPTED}
              type="CANDIDATE"
              getOptionsMenu={getOptionsMenu}
              employerSide={employerSide}
              showSplitIcon={showAwaitingReview}
              handleCandidateDetails={handleCandidateDetails}
            />
          </div>
          <div className={columnClassNames}>
            <span className="text-gray-500 text-lg">
              {employerSide ? 'Passed' : 'Rejected'}
            </span>
            <DroppableCandidate
              candidates={candidates.filter(
                (candidate) =>
                  candidate.status ===
                    CandidateActivityHistoryActivityType.REJECTED ||
                  candidate.status ===
                    CandidateActivityHistoryActivityType.EXPIRED
              )}
              allowedTypes={[
                CandidateActivityHistoryActivityType.IN_PROCESS,
                CandidateActivityHistoryActivityType.SUBMITTED,
              ]}
              currentDragging={currentDragging}
              droppableId={CandidateActivityHistoryActivityType.REJECTED}
              type="CANDIDATE"
              getOptionsMenu={getOptionsMenu}
              employerSide={employerSide}
              showSplitIcon={showAwaitingReview}
              handleCandidateDetails={handleCandidateDetails}
            />
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default KanbanCandidates;
