import type { DraggableLocation } from 'react-beautiful-dnd';

const reorder = <T extends { id: string }>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const reorderItemsMatrix = <T extends { id: string }>(
  itemsMatrix: T[][],
  source: DraggableLocation,
  destination: DraggableLocation
): T[][] => {
  const currentRow = Number(source.droppableId.split('_')[1]);
  const destinationRow = Number(destination.droppableId.split('_')[1]);
  const current = [...itemsMatrix[currentRow]];
  const next = [...itemsMatrix[destinationRow]];
  const target = current[source.index];
  const newMatrix = Array.from(itemsMatrix);
  // if moving to same row
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index);
    newMatrix.splice(currentRow, 1, reordered);
  } else {
    // if moving to different row
    // switch places with destination item
    current.splice(source.index, 1, next[destination.index]);
    next.splice(destination.index, 1, target);
    newMatrix.splice(currentRow, 1, current);
    newMatrix.splice(destinationRow, 1, next);
  }
  return newMatrix;
};

export default reorderItemsMatrix;
