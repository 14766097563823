import type { RoleOverviewCardData } from '@headrace/types';
import type { VFC } from 'react';
import { useEffect, useRef, useState } from 'react';

import type { OptionsProps } from '../../forms';
import type { MediaUploadLinkFields } from '../../ImageUpload';
import RoleOverview from './RoleOverview';
import RoleOverviewForm from './RoleOverviewForm';

export interface RoleOverViewCardProps extends RoleOverviewCardData {
  editMode?: boolean;
  roleFunctions?: OptionsProps[];
  companyStages?: OptionsProps[];
  citiesOptions?: OptionsProps[];
  hiringManagerOptions?: OptionsProps[];
  createMediaUploadLink?: (
    fileName: string,
    folder: string
  ) => Promise<{
    data?: { fields: MediaUploadLinkFields; url: string };
  }>;
  displayDate?: string | Date;
  isHeadRaceAI?: boolean;
}

const RoleOverviewCard: VFC<RoleOverViewCardProps> = ({
  data,
  editMode,
  roleFunctions,
  companyStages,
  citiesOptions,
  hiringManagerOptions,
  createMediaUploadLink,
  saveAction,
  displayDate,
  isHeadRaceAI,
}) => {
  const [edit, setEdit] = useState(false);
  const formScrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (edit) {
      formScrollRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [edit, isHeadRaceAI]);

  return edit ? (
    <RoleOverviewForm
      data={data}
      setEdit={setEdit}
      roleFunctions={roleFunctions || []}
      companyStages={companyStages || []}
      citiesOptions={citiesOptions || []}
      hiringManagerOptions={hiringManagerOptions || []}
      createMediaUploadLink={createMediaUploadLink}
      saveAction={saveAction}
      formScrollRef={formScrollRef}
    />
  ) : (
    <RoleOverview
      data={data}
      setEdit={setEdit}
      editMode={editMode}
      displayDate={displayDate}
      isHeadRaceAI={isHeadRaceAI}
    />
  );
};

export default RoleOverviewCard;
