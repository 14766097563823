import classNames from 'classnames';
import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: number;
  height?: number;
}

const UserCircleIcon: VFC<Props> = ({ className, width = 36, height = 36 }) => {
  const svgClassName = classNames('stroke-gray-500', className);
  return (
    <svg
      viewBox="0 0 38 38"
      fill="none"
      className={svgClassName}
      width={`${width}px`}
      height={`${height}px`}
    >
      <path
        d="M5.24207 30.6075C9.30533 28.3108 13.9996 27 19 27C24.0004 27 28.6947 28.3108 32.7579 30.6075M25 15C25 18.3137 22.3137 21 19 21C15.6863 21 13 18.3137 13 15C13 11.6863 15.6863 9 19 9C22.3137 9 25 11.6863 25 15ZM37 19C37 28.9411 28.9411 37 19 37C9.05887 37 1 28.9411 1 19C1 9.05887 9.05887 1 19 1C28.9411 1 37 9.05887 37 19Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserCircleIcon;
