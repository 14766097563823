import type { CompanyPerk } from '@headrace/types';
import { capitalizeString } from '@headrace/utils';
import {
  AcademicCapIcon,
  CalendarIcon,
  ChartBarIcon,
  EyeIcon,
  HeartIcon,
  TagIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';

import {
  BabyIcon,
  DonationIcon,
  FitnessIcon,
  MealIcon,
  MedicalKitIcon,
  MentalHealthIcon,
  PetIcon,
  PetPawIcon,
  PigIcon,
  RemoteWorkIcon,
  ToothIcon,
  VolunteerIcon,
} from '../../CustomIcons';
import { Checkbox } from '../../forms';

export const companyPerkIcons = {
  MEDICAL: <MedicalKitIcon className="flex-shrink-0" />,
  DENTAL: <ToothIcon className="flex-shrink-0" />,
  VISION: (
    <EyeIcon className="w-[40px] text-headraceYellow-700" strokeWidth={1.5} />
  ),
  LIFE_INSURANCE: (
    <HeartIcon className="w-[40px] text-headraceYellow-700" strokeWidth={1.5} />
  ),
  PAID_TIME_OFF: (
    <CalendarIcon
      className="w-[40px] text-headraceYellow-700"
      strokeWidth={1.5}
    />
  ),
  MATCHING_401K: <PigIcon className="flex-shrink-0" />,
  STOCK_PLAN: (
    <ChartBarIcon
      className="w-[40px] text-headraceYellow-700"
      strokeWidth={1.5}
    />
  ),
  FITNESS_BENEFITS: <FitnessIcon className="flex-shrink-0" />,
  PRODUCT_DISCOUNTS: (
    <TagIcon className="w-[40px] text-headraceYellow-700" strokeWidth={1.5} />
  ),
  TUITION_REIMBURSEMENT: (
    <AcademicCapIcon
      className="w-[40px] text-headraceYellow-700"
      strokeWidth={1}
    />
  ),
  VOLUNTEER_TIME: <VolunteerIcon className="flex-shrink-0" />,
  PARENTAL_LEAVE: <BabyIcon className="flex-shrink-0" />,
  CATERED_MEALS: <MealIcon className="flex-shrink-0" />,
  REMOTE_WORK_POLICY: <RemoteWorkIcon className="flex-shrink-0" />,
  PET_INSURANCE: <PetIcon className="flex-shrink-0" />,
  DONATION_MATCHING: <DonationIcon className="flex-shrink-0" />,
  MENTAL_HEALTH_BENEFITS: <MentalHealthIcon className="flex-shrink-0" />,
  PET_FRIENDLY_OFFICE: <PetPawIcon className="flex-shrink-0" />,
};

const PerkContainer: VFC<{
  perk: CompanyPerk;
  index: number;
  edit?: boolean;
  checked?: boolean;
  onChange?: (newValue: boolean) => void;
}> = (props) => {
  const { index, perk, edit, checked, onChange } = props;
  return (
    <div
      key={`${perk.id}_${index + 1}`}
      className={classNames(
        'flex gap-2 py-[20px] items-center w-[175px] h-[80px]',
        { 'cursor-pointer': edit && typeof checked === 'boolean' && onChange }
      )}
      aria-hidden="true"
      onClick={
        edit && typeof checked === 'boolean' && onChange
          ? (): void => {
              onChange(!checked);
            }
          : undefined
      }
    >
      {edit && typeof checked === 'boolean' && onChange && (
        <Checkbox name={perk.type} checked={checked} />
      )}
      <div className="flex items-center justify-center w-[40px] h-[40px] flex-shrink-0">
        {companyPerkIcons[perk.type]}
      </div>
      <p className="font-medium text-sm text-gray-500">
        {capitalizeString(perk.type.replace(/_/g, ' ').toLowerCase())}
      </p>
    </div>
  );
};

export default PerkContainer;
