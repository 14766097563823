import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import type { CSSProperties, Dispatch, SetStateAction } from 'react';
import { Fragment, useMemo } from 'react';

export interface HideableFiltersBarProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string | JSX.Element;
  width?: string;
}

const HideableFiltersBar: React.FC<HideableFiltersBarProps> = (props) => {
  const { open, setOpen, title, children, width } = props;

  const widthStyle = useMemo<CSSProperties>(() => {
    if (!width) return { position: 'relative', width: '90%', left: '10%' };
    return { position: 'absolute', width, height: '100vh', right: '0' };
  }, [width]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex z-40 md:hidden"
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in duration-300 transform"
          leave="transition ease-in duration-300 transform"
          leaveFrom="-translate-x-full"
          leaveTo="-translate-x-0"
        >
          <div
            style={widthStyle}
            className="flex flex-col py-6 bg-white overflow-y-auto"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="flex flex-col px-4 justify-between h-full">
                <div className="flex flex-col flex-auto gap-6">
                  <div className="flex justify-between items-center">
                    {title}
                    <div className="flex items-center text-headraceBlack-700 font-light">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-0 focus:ring-inset focus:ring-white"
                        onClick={(): void => {
                          setOpen(false);
                        }}
                      >
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Transition.Child>
        <div>
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default HideableFiltersBar;
