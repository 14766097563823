import 'yup-phone-lite';

import { LINKED_IN_URL } from '@headrace/constants';
import { CandidateDocumentTypeEnum } from '@headrace/types';
import * as Yup from 'yup';

const ProspectFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  linkedIn: Yup.string()
    .matches(LINKED_IN_URL, 'Invalid LinkedIn URL')
    .required('LinkedIn URL is required'),
  files: Yup.array()
    .of(
      Yup.object().shape({
        documentType: Yup.string()
          .oneOf([
            CandidateDocumentTypeEnum.RESUME,
            CandidateDocumentTypeEnum.OTHER,
          ])
          .required('Please select a document type'),
      })
    )
    .min(1, 'Resume is required'),
});

export default ProspectFormSchema;
