import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { ChevronRightIcon, XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import router from 'next/router';
import { Fragment } from 'react';

import type { NavigationItem } from '.';
import type { UserNavigationItem } from './ProfileMenu';
import ProfileMenu from './ProfileMenu';

interface TailwindHidableSidebarProps {
  active: string;
  logo: string;
  navigation: NavigationItem[];
  open: boolean;
  setOpen: (open: boolean) => void;
  profileImg?: string | null;
  userNavigation: UserNavigationItem[];
}

const TailwindHidableSidebar: React.FC<TailwindHidableSidebarProps> = (
  props
) => {
  const {
    active,
    logo,
    navigation,
    open,
    setOpen,
    profileImg,
    userNavigation,
  } = props;

  const handleCloseClick = (): void => {
    setOpen(false);
  };

  const handleRedirect = (url: string): void => {
    router.push(url).catch(null);

    handleCloseClick();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex z-40 xl:hidden"
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-headraceBlack-700">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  tabIndex={-1}
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-0 focus:ring-inset focus:ring-white"
                  onClick={handleCloseClick}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon
                    className="h-6 w-6 text-slate-300"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex justify-start ml-4">
              <Link href="/" passHref>
                <a>
                  <Image
                    className="h-8 w-auto"
                    src={logo}
                    alt="HeadRace Logo"
                    width={32}
                    height={32}
                  />
                </a>
              </Link>
            </div>

            {/* Links */}
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1 flex-grow flex flex-col h-full justify-between">
                {navigation.map((item) =>
                  !item.subNavigation ? ( // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <a
                      role="button"
                      key={item.name}
                      tabIndex={0}
                      onClick={(): void => handleRedirect(item.href)}
                      className={classNames(
                        {
                          'bg-headraceBlack-800': item.href === active,
                        },
                        'text-slate-300 hover:bg-headraceBlack-800 group flex items-center px-2 py-2 text-base font-medium rounded-md'
                      )}
                    >
                      {item.icon ? (
                        <item.icon
                          className="mr-4 flex-shrink-0 h-6 w-6 text-slate-300"
                          aria-hidden="true"
                        />
                      ) : (
                        ''
                      )}
                      {item.name}
                    </a>
                  ) : (
                    <Disclosure
                      as="div"
                      defaultOpen={item.alwaysOpen}
                      key={item.name}
                    >
                      {({ open: openD }): JSX.Element => (
                        <>
                          {item.alwaysOpen ? (
                            <div
                              className={classNames(
                                {
                                  'bg-headraceBlack-800': item.href === active,
                                },
                                'flex w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-slate-300'
                              )}
                            >
                              {item.icon ? (
                                <item.icon
                                  className="h-6 w-6 shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                              ) : (
                                ''
                              )}
                              {item.name}
                            </div>
                          ) : (
                            <Disclosure.Button
                              className={classNames(
                                {
                                  'bg-headraceBlack-800': item.href === active,
                                },
                                'flex w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-slate-300'
                              )}
                            >
                              {item.icon ? (
                                <item.icon
                                  className="h-6 w-6 shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                              ) : (
                                ''
                              )}
                              {item.name}
                              <ChevronRightIcon
                                className={classNames(
                                  openD
                                    ? 'rotate-90 text-gray-500'
                                    : 'text-gray-400',
                                  'ml-auto h-5 w-5 shrink-0'
                                )}
                                aria-hidden="true"
                              />
                            </Disclosure.Button>
                          )}

                          <Disclosure.Panel
                            as="ul"
                            className="mt-1 px-2 h-[calc(100vh-200px)] overflow-y-auto"
                          >
                            {(item.subNavigation || []).map((subItem) => (
                              <li key={subItem.name}>
                                {/* 44px */}
                                <button
                                  type="button"
                                  onClick={(): void =>
                                    handleRedirect(subItem.href)
                                  }
                                  className={classNames(
                                    {
                                      'bg-headraceBlack-800': active.includes(
                                        subItem.href
                                      ),
                                      'font-medium': active.includes(
                                        subItem.href
                                      ),
                                    },
                                    'block text-left rounded-md py-2 text-sm leading-6 text-slate-300 px-1'
                                  )}
                                >
                                  {subItem.name}
                                </button>
                              </li>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )
                )}
              </nav>
            </div>
            <div className="flex px-4 pt-4">
              <ProfileMenu
                userNavigation={userNavigation}
                profileImg={profileImg}
                withLabel
              />
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default TailwindHidableSidebar;
