import { CheckIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import Button from '../Button';
import { FilterActive, FilterInactive } from '../CustomIcons';
import OverlayDialog from '../OverlayDialog';
import type { Column, MinTableData } from './TableTd';

export interface FilterProps {
  selectedValues: SelectedValuesType;
  columnName: string;
}
export type SelectedValuesType = string[];

const capitalizeString = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();

/* TableFilter
This component will be rendered only when OverlayDialog is open
when closed the component will be unmounted and all states lost
params:
  close:
    passed by OverlayDialog to all its children
    is used to close the OverlayDialog
*/
const TableFilter = <D extends MinTableData>({
  column,
  selectedColumnFilter,
  selectColumnToFilter,
  closeMenu,
}: {
  column: Column<D>;
  selectedColumnFilter: FilterProps;
  selectColumnToFilter: (newFilterState: FilterProps) => void;
  closeMenu?: () => void;
}): JSX.Element => {
  const [selected, setSelected] = useState<string[]>([]);
  const { filterOptions } = column;
  const { columnName, selectedValues } = selectedColumnFilter;

  const selectColumn = (value: string): void => {
    if (selected.includes(value)) {
      setSelected(selected.filter((val) => val !== value));
    } else {
      setSelected([...selected, value]);
    }
  };
  const resetFilters = (): void => {
    selectColumnToFilter({ columnName, selectedValues: [] });
  };
  const saveFilters = (): void => {
    selectColumnToFilter({
      columnName: column.header,
      selectedValues: selected,
    });
    if (closeMenu) closeMenu();
  };

  useEffect(() => {
    setSelected(selectedValues);
  }, [selectedValues, setSelected]);

  if (!filterOptions) return <span />;
  return (
    <div className="flex flex-col items-start">
      <div className="w-full pr-[2px]">
        <div className="max-h-40 overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-zinc-300 hover:scrollbar-thumb-zinc-300">
          {filterOptions.map((option) => (
            <button
              type="button"
              key={option}
              className={classNames(
                'w-full px-3 py-2 flex flex-row items-stretch',
                {
                  'font-bold': selected.includes(option),
                }
              )}
              onClick={(): void => selectColumn(option)}
            >
              <span className="text-headraceBlack-800 text-left text-sm font-normal grow">
                {capitalizeString(option)}
              </span>
              {selected.includes(option) && (
                <CheckIcon className="w-4 h-4 stroke-[3px] grow-0" />
              )}
            </button>
          ))}
        </div>
      </div>
      <div className="gap-2 flex flex-row w-full p-2 border-t border-gray-100">
        <Button
          buttonType="secondary"
          className="flex-1 px-[17px] py-[9px]"
          onClick={resetFilters}
        >
          Reset
        </Button>
        <Button buttonType="primary" className="flex-1" onClick={saveFilters}>
          Done
        </Button>
      </div>
    </div>
  );
};

const TableFilterContainer = <D extends MinTableData>({
  column,
  selectedColumnFilter,
  selectColumnToFilter,
}: {
  column: Column<D>;
  selectedColumnFilter: FilterProps;
  selectColumnToFilter: (newFilterState: FilterProps) => void;
}): JSX.Element => {
  const Icon =
    selectedColumnFilter.selectedValues.length === 0
      ? FilterInactive
      : FilterActive;

  return (
    <OverlayDialog
      Icon={Icon}
      iconClassName="!w-4 !h-4 text-headraceBlack-800"
      panelClassName={column.widthFilterMenu}
    >
      <TableFilter
        column={column}
        selectedColumnFilter={selectedColumnFilter}
        selectColumnToFilter={selectColumnToFilter}
      />
    </OverlayDialog>
  );
};

export default TableFilterContainer;
