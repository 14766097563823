import { BASIC_URL, LINKED_IN_URL } from '@headrace/constants';
import type { RoleOverviewFormValues } from '@headrace/types';
import {
  LatestFundingRoundTypeLabel,
  RemoteWorkPolicyEnumLabels,
} from '@headrace/types';
import { Form, Formik } from 'formik';
import type { Dispatch, SetStateAction, VFC } from 'react';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';

import Button from '../../Button';
import Card from '../../Card';
import { GlobalIcon } from '../../CustomIcons';
import type { OptionsProps } from '../../forms';
import {
  Input,
  MultiSelectField,
  NumberInput,
  RichTextEditor,
  Select,
  TextArea,
} from '../../forms/fields/formik';
import type { MediaUploadLinkFields } from '../../ImageUpload';
import ImageUpload from '../../ImageUpload';
import ImageWithFallback from '../../ImageWithFallback';

export interface RoleOverviewFormProps {
  data: RoleOverviewFormValues;
  setEdit: Dispatch<SetStateAction<boolean>>;
  saveAction?: (values: RoleOverviewFormValues) => Promise<void>;
  loading?: boolean;
  roleFunctions: OptionsProps[];
  companyStages: OptionsProps[];
  citiesOptions: OptionsProps[];
  hiringManagerOptions: OptionsProps[];
  createMediaUploadLink?: (
    fileName: string,
    folder: string
  ) => Promise<{
    data?: { fields: MediaUploadLinkFields; url: string };
  }>;
  formScrollRef?: React.RefObject<HTMLDivElement>;
}
const RoleOverviewForm: VFC<RoleOverviewFormProps> = (props) => {
  const {
    data,
    setEdit,
    loading,
    companyStages,
    roleFunctions,
    citiesOptions,
    createMediaUploadLink,
    saveAction,
    formScrollRef,
    hiringManagerOptions,
  } = props;

  const [imageUp, setImageUp] = useState<string | null>(data.logoUrl || null);

  const latestFundingRoundOptions = Object.entries(
    LatestFundingRoundTypeLabel
  ).map(([key, value]) => ({
    label: value,
    value: key,
  }));
  const numberOfEmployeesOptions = [
    { label: '0-1 employees', value: '0-1' },
    { label: '2-10 employees', value: '2-10' },
    { label: '11-50 employees', value: '11-50' },
    { label: '51-200 employees', value: '51-200' },
    { label: '201-500 employees', value: '101-500' },
    { label: '501-1000 employees', value: '501-1000' },
    { label: '1001-5000 employees', value: '1001-5000' },
    { label: '5001-10000 employees', value: '5001-10000' },
    { label: '10001+ employees', value: '10001+' },
  ];

  const remoteWorkPolicyOptions = Object.entries(
    RemoteWorkPolicyEnumLabels
  ).map(([key, val]) => ({
    label: val,
    value: key,
  }));

  const initialValues = useMemo(
    () => ({
      name: data.name,
      roleFunctionId: data.roleFunctionId,
      locationIds: data.locationIds,
      remoteWorkPolicy: data.remoteWorkPolicy,
      logo: data.logo,
      companyTagline: data.companyTagline,
      companyIntro: data.companyIntro,
      companyIntroJSON: data.companyIntroJSON,
      numberOfEmployees: data.numberOfEmployees,
      revenue: data.revenue,
      totalFunding: data.totalFunding,
      yoyGrowth: data.yoyGrowth,
      tam: data.tam,
      latestFundingRoundAmount: data.latestFundingRoundAmount,
      latestFundingRoundType: data.latestFundingRoundType,
      investors: data.investors,
      companyStageId: data.companyStageId,
      crunchbase: data.crunchbase,
      linkedIn: data.linkedIn,
      companyUrl: data.companyUrl,
      hiringManager: data.hiringManager,
    }),
    [data]
  );

  const roleOverviewFormSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    roleFunctionId: Yup.string().required('Required'),
    locationIds: Yup.array().required('Required'),
    companyTagline: Yup.string().max(120).nullable(true),
    yoyGrowth: Yup.number().max(1000).nullable(true),
    crunchbase: Yup.string()
      .matches(BASIC_URL, 'Invalid Crunchbase URL')
      .nullable(true),
    linkedIn: Yup.string()
      .matches(LINKED_IN_URL, 'Invalid LinkedIn URL')
      .nullable(true),
    companyUrl: Yup.string()
      .matches(BASIC_URL, 'Invalid Company URL')
      .nullable(true),
    companyIntro: Yup.string()
      .max(375, 'Company intro should be less than 375 characters')
      .nullable(true),
    remoteWorkPolicy: Yup.string()
      .required('Remote work policy is required')
      .nullable(true),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values): Promise<void> => {
        if (saveAction) await saveAction(values);
        setEdit(false);
      }}
      validationSchema={roleOverviewFormSchema}
    >
      {({ setFieldValue }): JSX.Element => (
        <Form>
          <Card className="flex flex-col gap-6 p-6 h-fit">
            <div className="flex items-center justify-between">
              <h3 className="font-medium text-lg text-headraceBlack-700">
                Role & Company highlights
              </h3>
              <div
                className="flex items-center gap-4 justify-end"
                ref={formScrollRef}
              >
                <Button
                  buttonType="secondary"
                  className="!py-2"
                  onClick={(): void => setEdit(false)}
                >
                  Cancel
                </Button>
                <Button type="submit" className="!py-2" loading={loading}>
                  Save
                </Button>
              </div>
            </div>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-4 gap-y-6">
              <div className="col-span-1">
                <Input name="name" label="Role" />
              </div>
              <div className="col-span-1">
                <Select
                  name="roleFunctionId"
                  options={roleFunctions}
                  label="Function"
                />
              </div>
              <div className="col-span-full">
                <MultiSelectField
                  name="locationIds"
                  options={citiesOptions}
                  label="Office location"
                />
              </div>
              <div className="col-span-full">
                <Select
                  name="remoteWorkPolicy"
                  options={remoteWorkPolicyOptions}
                  label="Remote work policy"
                />
              </div>
              <div className="col-span-full">
                <Select
                  name="hiringManager"
                  options={hiringManagerOptions}
                  placeholder="Select a manager"
                  isSearchable
                  label="Hiring manager"
                />
              </div>
            </div>
            <div className="sm:grid-cols-2 grid-cols-1 border-t gap-6 grid">
              {createMediaUploadLink && (
                <div className="sm:col-span-2 flex justify-start items-center gap-4 sm:pt-8 pt-2">
                  <div className="flex items-center sm:h-28 h-full justify-center shadow-lg">
                    <ImageWithFallback
                      className="object-scale-down shadow-lg rounded-lg"
                      src={
                        imageUp || '/placeholders/company_logo_placeholder.png'
                      }
                      alt="company-logo"
                      objectFit="contain"
                      width="100%"
                      height={60}
                      fallbackSrc="/placeholders/company_logo_placeholder.png"
                    />
                  </div>
                  <ImageUpload
                    getImageData={(image: string, key: string): void => {
                      setImageUp(image);
                      setFieldValue('logo', key);
                    }}
                    aspectRatio={16 / 9}
                    cropSize={{
                      width: 300,
                      height: 169,
                    }}
                    createMediaUploadLink={createMediaUploadLink}
                    buttonLabel="Upload logo"
                    label="Company logo"
                    helper="Global setting that <br/> affects  all role listings"
                  />
                </div>
              )}
              <div className="sm:col-span-full">
                <TextArea
                  name="companyTagline"
                  label="Company tagline"
                  countDown
                  maxLength={120}
                  info="Global setting that <br/> affects  all role listings"
                  infoIcon={<GlobalIcon />}
                />
              </div>
              <div className="sm:col-span-full">
                <RichTextEditor
                  label="Company intro"
                  name="companyIntro"
                  nameJSON="companyIntroJSON"
                  maxLength={375}
                  inputClassName="mt-10 !h-full min-h-[150px] max-h-[150px]"
                  json={data.companyIntroJSON}
                  info="Global setting that <br/> affects  all role listings"
                  infoIcon={<GlobalIcon />}
                />
              </div>
              <div className="sm:col-span-full grid sm:grid-cols-3 grid-cols-1 gap-5">
                <div className="sm:col-span-1">
                  <Input
                    name="linkedIn"
                    label="LinkedIn URL"
                    helper="http://"
                    info="Global setting that <br/> affects  all role listings"
                    infoIcon={<GlobalIcon />}
                  />
                </div>
                <div className="sm:col-span-1">
                  <Input
                    name="crunchbase"
                    label="Crunchbase URL"
                    info="Global setting that <br/> affects  all role listings"
                    helper="http://"
                    infoIcon={<GlobalIcon />}
                  />
                </div>
                <div className="sm:col-span-1">
                  <Input
                    name="companyUrl"
                    label="Company URL"
                    info="Global setting that <br/> affects  all role listings"
                    helper="http://"
                    infoIcon={<GlobalIcon />}
                  />
                </div>
              </div>

              <div className="sm:col-span-1">
                <Select
                  name="numberOfEmployees"
                  label="Number of employees"
                  options={numberOfEmployeesOptions}
                  info="Global setting that <br/> affects  all role listings"
                  infoIcon={<GlobalIcon />}
                />
              </div>
              <div className="sm:col-span-1">
                <Select
                  name="companyStageId"
                  options={companyStages}
                  label="Company stage"
                  info="Global setting that <br/> affects  all role listings"
                  infoIcon={<GlobalIcon />}
                />
              </div>
              <div className="sm:col-span-1">
                <NumberInput
                  name="revenue"
                  label="Revenue"
                  helper="$"
                  placeHolderBeforeCursor="$"
                  thousandSeparator
                  info="Global setting that <br/> affects  all role listings"
                  infoIcon={
                    <div className="flex items-center">
                      <GlobalIcon />
                      <span className="ml-2 text-gray-400 italic text-xs">
                        Ex. $10M as $10,000,000
                      </span>
                    </div>
                  }
                />
              </div>
              <div className="sm:col-span-1">
                <NumberInput
                  name="totalFunding"
                  label="Total funding"
                  helper="$"
                  placeHolderBeforeCursor="$"
                  thousandSeparator
                  info="Global setting that <br/> affects  all role listings"
                  infoIcon={
                    <div className="flex items-center">
                      <GlobalIcon />
                      <span className="ml-2 text-gray-400 italic text-xs">
                        Ex. $10M as $10,000,000
                      </span>
                    </div>
                  }
                />
              </div>
              <div className="sm:col-span-1">
                <NumberInput
                  name="yoyGrowth"
                  label="YoY growth"
                  rigthHelper="%"
                  info="Global setting that <br/> affects  all role listings"
                  infoIcon={<GlobalIcon />}
                />
              </div>
              <div className="sm:col-span-1">
                <NumberInput
                  name="tam"
                  label="Total addressable market"
                  helper="$"
                  placeHolderBeforeCursor="$"
                  thousandSeparator
                  info="Global setting that <br/> affects  all role listings"
                  infoIcon={
                    <div className="flex items-center">
                      <GlobalIcon />
                      <span className="ml-2 text-gray-400 italic text-xs">
                        Ex. $10M as $10,000,000
                      </span>
                    </div>
                  }
                />
              </div>
              <div className="sm:col-span-1 flex sm:flex-row flex-col items-center gap-1">
                <div className="w-full">
                  <NumberInput
                    name="latestFundingRoundAmount"
                    label="Latest funding round"
                    helper="$"
                    placeHolderBeforeCursor="$"
                    thousandSeparator
                    info="Global setting that <br/> affects  all role listings"
                    infoIcon={
                      <div className="flex items-center relative">
                        <GlobalIcon />
                        <span className="left-6 text-gray-400 italic text-xs absolute w-[136px]">
                          Ex. $10M as $10,000,000
                        </span>
                      </div>
                    }
                  />
                </div>
                <div className="w-full">
                  <Select
                    name="latestFundingRoundType"
                    options={latestFundingRoundOptions}
                    className="!mt-6 w-full"
                    containerClassName="w-full"
                    info="Global setting that <br/> affects  all role listings"
                  />
                </div>
              </div>
              <div className="sm:col-span-1">
                <Input
                  name="investors"
                  label="Lead investor"
                  info="Global setting that <br/> affects  all role listings"
                  infoIcon={<GlobalIcon />}
                />
              </div>
            </div>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default RoleOverviewForm;
