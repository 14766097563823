import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
}

const ArrowUndoIcon: VFC<Props> = ({
  className = 'bi bi-arrow-clockwise',
  width = '14',
  height = '14',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 4.66669H6.33333C3.38781 4.66669 1 7.0545 1 10V11.3334M13 4.66669L9 8.66669M13 4.66669L9 0.666687"
      stroke="#4B5563"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowUndoIcon;
