import { DotsVerticalIcon } from '@heroicons/react/solid';
import type { VFC } from 'react';

import OverlayDialog from '../OverlayDialog';
import type { QueryParam } from './MenuActions';
import MenuActions from './MenuActions';

export interface ItemsMenu {
  label: string;
  action: string | (() => void);
  disabled?: boolean;
  queryParam?: QueryParam;
}

interface ItemsMenuProps {
  options: ItemsMenu[];
  iconContainerClassName?: string;
  withPortal?: boolean;
}

const Menu: VFC<ItemsMenuProps> = ({
  options,
  iconContainerClassName,
  withPortal = true,
}) => (
  <OverlayDialog
    Icon={DotsVerticalIcon}
    iconClassName="hover:text-gray-500"
    withPortal={withPortal}
    iconContainerClassName={iconContainerClassName}
  >
    <MenuActions options={options} />
  </OverlayDialog>
);

export default Menu;
