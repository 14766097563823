import Head from 'next/head';
import { useEffect, useMemo, useState } from 'react';
import { hotjar } from 'react-hotjar';

import HeaderTabsProvider from './HeaderTabsProvider';
import type { UserNavigationItem } from './ProfileMenu';
import SecondaryNavProvider from './SecondaryNavProvider';
import Sidebar from './Sidebar';
import TailwindContent from './TailwindContent';
import TailwindFixedSidebar from './TailwindFixedSidebar';
import TailwindHeader from './TailwindHeader';
import TailwindHidableSidebar from './TailwindHidableSidebar';

export interface NavigationItem {
  name: string;
  href: string;
  title?: string; // Header text to show in TailwindContent
  showNavigation?: boolean; // Show Item in navigation sidebar?
  icon?:
    | ((props: React.ComponentProps<'svg'>) => JSX.Element)
    | React.VFC<unknown & object>;
  children?: string[]; // List of sub-items
  yellowStripe?: boolean; // Show yellow stripe as body's background
  customMargin?: boolean; // Show page with custom margin
  backArrow?: boolean | string; // Show back arrow in header
  pill?: string | number | null; // Show pill in header
  pillType?: 'PRIMARY' | 'SECONDARY';
  notificationBadge?: number | null; // Show notification badge
  subNavigation?: {
    name: string;
    href: string;
  }[]; // List of sub-items
  alwaysOpen?: boolean; // Always open in sidebar
  fullHeight?: boolean; // Full height sidebar
  disabled?: boolean;
}

interface TailwindLayoutProps {
  currentPath: string;
  navigation: NavigationItem[];
  profileImg?: string | null;
  userNavigation: UserNavigationItem[];
  hotjarId: number;
  onFocus: () => void;
  withHidableSidebar?: boolean;
  currentUrl?: string;
}

const TailwindLayout: React.FC<TailwindLayoutProps> = (props) => {
  const {
    children,
    currentPath,
    navigation,
    profileImg,
    userNavigation,
    hotjarId,
    onFocus,
    withHidableSidebar = true,
    currentUrl = '',
  } = props;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const env = process.env.NEXT_PUBLIC_HEADRACE_ENV;

  const sidebarNavigation = useMemo(
    () => navigation.filter((nav) => nav.showNavigation),
    [navigation]
  );

  const currentNav = useMemo(
    () => navigation.find((nav) => nav.href === currentPath),
    [currentPath, navigation]
  );
  const currentTitle = useMemo(() => {
    let selectedNav = currentNav;
    if (!currentNav || !currentNav.title) {
      const secondaryNav = navigation
        .filter((nav) => nav.href !== '/')
        .find((nav) => currentPath.startsWith(nav.href));
      if (secondaryNav) selectedNav = secondaryNav;
    }
    return (
      selectedNav?.title ||
      selectedNav?.name ||
      currentPath.split('/')[1].replace(/^\w/, (c) => c.toUpperCase())
    );
  }, [currentPath, navigation, currentNav]);

  const yellowStripe = useMemo(() => {
    if (currentNav) {
      if (currentNav.yellowStripe != null) return currentNav.yellowStripe;
    }
    return (
      !currentPath.split('/')[2] &&
      currentPath.split('/')[1] !== 'settings' &&
      !currentPath.split('/')[1].includes('profiles')
    );
  }, [currentNav, currentPath]);

  const pageWithCustomMargin = useMemo(() => {
    if (currentNav) {
      if (currentNav.customMargin != null) return currentNav.customMargin;
    }
    return false;
  }, [currentNav]);

  const handleOpenSidebarClick = (): void => {
    setSidebarOpen(true);
  };

  const showBackArrow = useMemo(() => {
    const objectNav = navigation.find(
      (nav) => nav.children && nav.children.includes(currentPath)
    );
    return currentNav?.backArrow || objectNav?.backArrow;
  }, [currentNav?.backArrow, currentPath, navigation]);

  useEffect(() => {
    if (env === 'production') {
      hotjar.initialize(hotjarId, 6);
    }
  });

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentChildrens = useMemo(() => {
    const nav = navigation.find(
      (n) => n.href === currentPath || n.children?.includes(currentPath)
    );
    return nav?.children || [];
  }, [currentPath, navigation]);

  return (
    <div id="tailwind-body" className="flex flex-row h-full min-h-screen">
      <Head>
        <title>{currentTitle} :: HeadRace</title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={`${currentTitle} :: HeadRace`} />
        <meta
          name="description"
          content={`${currentTitle} :: HeadRace`}
          key="desc"
        />
        <meta
          property="og:description"
          content={`${currentTitle} :: HeadRace`}
        />
        <link rel="icon" href="/headrace_icon.svg" />
        <meta property="og:image" content="/headrace_icon.svg" />
      </Head>
      {withHidableSidebar ? (
        <TailwindFixedSidebar
          active={currentPath}
          logo="/headrace_icon.svg"
          navigation={sidebarNavigation}
          userNavigation={userNavigation}
          profileImg={profileImg}
        />
      ) : (
        <Sidebar
          navigation={sidebarNavigation}
          active={currentUrl}
          userNavigation={userNavigation}
          profileImg={profileImg}
        />
      )}
      <TailwindHidableSidebar
        active={currentPath}
        logo="/headrace_icon.svg"
        navigation={sidebarNavigation}
        open={sidebarOpen}
        setOpen={setSidebarOpen}
        userNavigation={userNavigation}
        profileImg={profileImg}
      />

      <SecondaryNavProvider navChildrens={currentChildrens}>
        <HeaderTabsProvider>
          <div className="flex flex-col flex-1">
            <TailwindHeader
              onOpenSidebarClick={handleOpenSidebarClick}
              title={currentTitle}
              showBackArrow={showBackArrow}
            />
            <main>
              <TailwindContent
                yellowStripe={yellowStripe}
                pageWithCustomMargin={pageWithCustomMargin}
                withHidableSidebar={withHidableSidebar}
              >
                {children}
              </TailwindContent>
            </main>
          </div>
        </HeaderTabsProvider>
      </SecondaryNavProvider>
    </div>
  );
};

export default TailwindLayout;
