import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';
import { Fragment, useRef } from 'react';

import Button from './Button';

export interface SimpleAlertProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: React.ReactNode;
  confirmText: string;
  cancelText: string;
  loading?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  noIcon?: boolean;
  titleClassName?: string;
}

const SimpleAlert: VFC<SimpleAlertProps> = (props) => {
  const {
    open,
    onClose,
    onConfirm,
    title,
    message,
    confirmText,
    cancelText,
    loading = false,
    disabled = false,
    noIcon = false,
    titleClassName,
    icon = (
      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
      </div>
    ),
  } = props;
  const buttonContainerRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-y-auto"
        initialFocus={buttonContainerRef}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                {!noIcon && icon}
                <div
                  className={classNames(
                    'mt-3 text-center sm:mt-0 sm:text-left',
                    { 'md:ml-3': !noIcon }
                  )}
                >
                  <Dialog.Title
                    as="h3"
                    className={classNames(
                      'text-lg leading-6 font-medium text-gray-900',
                      titleClassName
                    )}
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="text-sm text-gray-500">{message}</div>
                  </div>
                </div>
              </div>
              <div
                ref={buttonContainerRef}
                className="mt-5 sm:mt-4 flex flex-row-reverse gap-2 sm:gap-3"
              >
                <Button
                  loading={loading}
                  disabled={disabled}
                  buttonType="primary"
                  onClick={onConfirm}
                >
                  {confirmText}
                </Button>
                <Button buttonType="secondary" onClick={onClose}>
                  {cancelText}
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default SimpleAlert;
