import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import type { MultiValue, SingleValue } from 'react-select';

import Button from '../../Button';
import { AutoResizableTextarea } from '../../forms';
import type { OptionsProps } from '../../forms/fields/BasicSelect';
import BasicSelect from '../../forms/fields/BasicSelect';
import type { Requirement, RoleRequirementsChanges } from '../types';

interface RequirementsRolesProps {
  requirements: Requirement[];
  onSave: (changes: RoleRequirementsChanges) => void;
  isMarketplaceRecruiter?: boolean;
  currentRoleId: string;
  canEdit?: boolean;
}

const RequirementsRole: FC<RequirementsRolesProps> = ({
  isMarketplaceRecruiter,
  requirements,
  onSave,
  currentRoleId,
  canEdit = true,
}) => {
  const [requirementsState, setRequirementsState] = useState<Requirement[]>([]);
  const [currentRequirements, setCurrentRequirements] = useState<
    Array<Requirement>
  >([]);
  const [editState, setEditState] = useState(false);
  const [removedRequirements, setRemovedRequirements] = useState<string[]>([]);

  const resetToMainState = (): void => {
    setRequirementsState(requirements);
    setEditState(false);
    setCurrentRequirements(requirements);
  };

  useEffect(() => {
    const roleIdSaved = sessionStorage.getItem('currentRole');
    const requirementsSavedString = sessionStorage.getItem(
      'currentRequirementsEditState'
    );
    if (roleIdSaved !== currentRoleId || !requirementsSavedString) {
      sessionStorage.removeItem('currentRequirementsEditState');
      resetToMainState();
    } else {
      const requirementsSaved = JSON.parse(
        requirementsSavedString
      ) as Requirement[];
      if (requirementsSaved) {
        setRequirementsState(requirementsSaved);
        setEditState(true);
        setCurrentRequirements(requirementsSaved);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoleId, requirements]);

  useEffect(() => {
    setCurrentRequirements(requirementsState);
    sessionStorage.setItem(
      'currentRequirementsEditState',
      JSON.stringify(requirementsState)
    );
    sessionStorage.setItem('currentRole', currentRoleId);
    if (!editState) {
      sessionStorage.removeItem('currentRequirementsEditState');
    }
  }, [requirementsState, currentRoleId, editState]);

  const stopEditing = (): Requirement[] => {
    setRequirementsState(requirementsState);
    return requirementsState;
  };

  const saveRequirements = (): void => {
    const reqsAfterEditting = stopEditing();
    let newRequirements = reqsAfterEditting.filter(
      (req) =>
        requirements.map((r) => r.id).indexOf(req.id) === -1 || req.isTemplate
    );
    // detect if a requirement has a blank prompt
    const requirementsWithBlankPrompt = reqsAfterEditting.filter(
      (req) => req.prompt === ''
    );
    if (requirementsWithBlankPrompt.length > 0) {
      newRequirements = reqsAfterEditting.map((req) => {
        if (req.prompt === '')
          return {
            ...req,
            error: true,
          };
        return req;
      });
      setRequirementsState(newRequirements);
      return;
    }
    newRequirements = newRequirements.filter(
      (req) =>
        requirements.map((r) => r.id).indexOf(req.id) === -1 || req.isTemplate
    );
    onSave({
      added: newRequirements,
      removed: removedRequirements,
      updated: reqsAfterEditting.filter((req) => {
        const ifAdded = newRequirements.find((r) => req.id === r.id);
        if (ifAdded) return false;
        const original = requirements.find((r) => r.id === req.id);
        if (!original) return false;
        return (
          req.prompt !== original.prompt || req.mustHave !== original.mustHave
        );
      }),
    });
    setEditState(false);
    setRemovedRequirements([]);
    sessionStorage.removeItem('currentRole');
    sessionStorage.removeItem('currentRequirementsEditState');
  };

  const addRequirement = (): void => {
    const newRequirement = {
      id: `${currentRequirements.length + 1} ${new Date().getTime()}`,
      mustHave: true,
      prompt: ``,
      createdAt: new Date(),
    };
    setRequirementsState([...requirementsState, newRequirement]);
  };

  const updateRequirement = (Requirement: Requirement): void => {
    const newRequirements = requirementsState.map((currentRequirement) =>
      currentRequirement.id === Requirement.id
        ? Requirement
        : currentRequirement
    );
    setRequirementsState(newRequirements);
  };

  const removeRequirement = (id: string): void => {
    const original = requirements.find((r) => r.id === id);
    if (original) {
      setRemovedRequirements([...removedRequirements, id]);
    }
    setRequirementsState(
      requirementsState.filter((Requirement) => Requirement.id !== id)
    );
  };

  return (
    <div className="!border-none" role="none">
      <div className="w-full">
        <div className="bg-gray-50 text-sm font-medium h-10 p-2 pl-6 border-y sm:border-b sm:border-t-0 text-headraceBlack-800 relative ">
          <span>Requirements</span>
          {!isMarketplaceRecruiter &&
            (editState ? (
              <div className="absolute right-0 top-0 py-1 pr-6">
                <Button
                  buttonType="secondary"
                  size="sm"
                  className="mr-2 text-xs !h-[30px]"
                  onClick={(): void => {
                    resetToMainState();
                    setEditState(false);
                    sessionStorage.removeItem('currentRole');
                    sessionStorage.removeItem('currentRequirementsEditState');
                    sessionStorage.removeItem('currentCategory');
                  }}
                >
                  Cancel
                </Button>
                <Button
                  buttonType="primary"
                  size="sm"
                  className="text-xs  !h-[30px]"
                  onClick={(): void => {
                    saveRequirements();
                  }}
                >
                  Save
                </Button>
              </div>
            ) : (
              <div className="absolute right-0 top-0 py-1 pr-6">
                {canEdit && (
                  <Button
                    buttonType="secondary"
                    size="sm"
                    className="text-xs !h-[30px]"
                    onClick={(): void => {
                      setEditState(true);
                    }}
                  >
                    Edit requirements
                  </Button>
                )}
              </div>
            ))}
        </div>
        <div className="p-6">
          <ul className="-mb-8">
            {currentRequirements.map((event, eventIdx) => (
              <li key={event.id}>
                <div className="relative pb-8">
                  {eventIdx !== currentRequirements.length - 1 ? (
                    <span
                      className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative space-x-1 md:space-x-3 gap-0 md:gap-4 flex">
                    <div
                      className={classNames(
                        'w-[32px] h-[32px] rounded-[180px] flex items-center justify-center border-2 bg-white border-headraceYellow-700 font-ubuntu font-bold mt-1 mr-1 sm:mr-0'
                      )}
                    >
                      {eventIdx + 1}
                    </div>
                    <div className="min-w-0 w-full md:w-full">
                      <div className="grid grid-cols-12 justify-between gap-3">
                        <div
                          className={classNames(
                            'col-span-12',
                            editState ? 'sm:col-span-9' : 'sm:col-span-10'
                          )}
                        >
                          {editState ? (
                            <AutoResizableTextarea
                              className={classNames(
                                'focus:ring-gray-200',
                                'focus:border-gray-200',
                                'w-full',
                                'sm:text-sm',
                                'border',
                                'border-gray-300',
                                'rounded-md',
                                'block',
                                'resize',
                                'h-[39px]',
                                'mt-1'
                              )}
                              onChange={(
                                e: ChangeEvent<HTMLTextAreaElement>
                              ): void => {
                                updateRequirement({
                                  ...(currentRequirements.find(
                                    (currentRequirement) =>
                                      currentRequirement.id === event.id
                                  ) as Requirement),
                                  prompt: e.target.value,
                                  error: e.target.value === '',
                                });
                              }}
                              value={event.prompt}
                            />
                          ) : (
                            <p className="text-base mt-1 break-words">
                              {event.prompt}
                            </p>
                          )}
                          {event.error && (
                            <div className="border-red-400 text-red-700 ">
                              <span className="block sm:inline">
                                Please enter a requirement
                              </span>
                            </div>
                          )}
                        </div>
                        <div
                          className={classNames(
                            'col-span-12 sm:mt-1 mt-2',
                            editState ? 'sm:col-span-3' : 'sm:col-span-2'
                          )}
                        >
                          <div className="flex">
                            <div className="ml-0 sm:ml-1 w-full">
                              {editState ? (
                                <BasicSelect
                                  name={`mustHave${eventIdx}`}
                                  value={{
                                    value: event.mustHave.toString(),
                                    label: event.mustHave
                                      ? 'Must have'
                                      : 'Nice to have',
                                  }}
                                  options={[
                                    { value: 'true', label: 'Must have' },
                                    { value: 'false', label: 'Nice to have' },
                                  ]}
                                  className="w-full sm:w-full h-[39px]"
                                  onChange={(
                                    value:
                                      | SingleValue<OptionsProps>
                                      | MultiValue<OptionsProps>
                                  ): void => {
                                    const newValue = value as OptionsProps;
                                    updateRequirement({
                                      ...event,
                                      mustHave:
                                        (value && newValue.value === 'true') ??
                                        false,
                                    });
                                  }}
                                />
                              ) : (
                                <p className="text-base font-bold xl:ml-[20%] 2xl:ml-[35px]">
                                  {event.mustHave
                                    ? 'Must have'
                                    : 'Nice to have'}
                                </p>
                              )}
                            </div>
                            {editState && (
                              <Button
                                buttonType="link"
                                className="!m-0 px-3 py-2"
                                onClick={(): void => {
                                  removeRequirement(event.id);
                                }}
                              >
                                <TrashIcon className="w-5 h-5 stroke-blue-400" />
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {(editState || requirementsState.length === 0) && (
            <div
              role="presentation"
              className="p-4 flex flex-col justify-center items-center border-2 border-dashed rounded-md cursor-pointer mt-6"
              onClick={(): void => {
                addRequirement();
              }}
            >
              <div className="flex items-center gap-2">
                <PlusIcon className="w-6 h-6 text-gray-400" />
                <span className="whitespace-nowrap font-medium text-sm">
                  Add requirements
                </span>
              </div>
              <p className="text-gray-500 text-sm">
                Add requirements to better align on the ideal candidate for this
                role
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequirementsRole;
