import { CheckIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';

interface CheckListItemProps {
  label: string;
  checkIconClassName?: string;
  labelClassName?: string;
}

const CheckListItem: VFC<CheckListItemProps> = (props) => {
  const { label, checkIconClassName, labelClassName } = props;

  return (
    <div className="flex">
      <CheckIcon
        className={classNames(
          'flex-shrink-0 h-5 w-5 text-green-500',
          checkIconClassName
        )}
      />
      <span className={classNames('ml-3', labelClassName)}>{label}</span>
    </div>
  );
};

export default CheckListItem;
