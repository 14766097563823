import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';

import type { SimpleNavigation } from './SimpleHeader';

interface SimpleHideableSidebarProps {
  logo: string;
  navigation: SimpleNavigation[];
  open: boolean;
  setOpen: (open: boolean) => void;
}

const SimpleHideableSidebar: React.FC<SimpleHideableSidebarProps> = (props) => {
  const { logo, navigation, open, setOpen } = props;

  const handleCloseClick = (): void => {
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex z-40 xl:hidden"
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-headraceBlack-700">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-0 focus:ring-inset focus:ring-white"
                  onClick={handleCloseClick}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon
                    className="h-6 w-6 text-slate-300"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex px-3">
              <Link href="/" passHref>
                <a>
                  <Image
                    className="h-8 w-auto"
                    src={logo}
                    alt="HeadRace Logo"
                    width={32}
                    height={32}
                  />
                </a>
              </Link>
            </div>

            {/* Links */}
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                {navigation.map((item) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <a
                    role="button"
                    key={item.label}
                    tabIndex={0}
                    href={item.link}
                    className={classNames(
                      'text-slate-300 hover:bg-headraceBlack-800 group flex items-center px-2 py-2 text-base font-medium rounded-md'
                    )}
                  >
                    {item.label}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SimpleHideableSidebar;
