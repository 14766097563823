import type { VFC } from 'react';

import type { ProgressStepState } from './Step';
import Step from './Step';

interface StepperProps {
  steps: {
    label: string;
    description?: string;
    status: ProgressStepState;
  }[];
}

const Stepper: VFC<StepperProps> = ({ steps }) => (
  <nav aria-label="Progress">
    <ol className="overflow-hidden">
      {steps.map((step, index) => (
        <Step
          key={step.label}
          status={step.status}
          number={index + 1}
          label={step.label}
          description={step.description}
          stepsLength={steps.length}
          index={index}
        />
      ))}
    </ol>
  </nav>
);

export default Stepper;
