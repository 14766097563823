import classNames from 'classnames';
import Image from 'next/image';
import type { VFC } from 'react';

import Button from './Button';

interface Props {
  button?: {
    label: string;
    onClick: () => void;
  };
  image: string | JSX.Element | null;
  title?: string;
  description?: React.ReactNode;
  showSeparator?: boolean;
  descriptionClassName?: string;
  className?: string;
  contentClassName?: string;
  titleClassName?: string;
  imageClassName?: string;
  padding?: boolean;
  subDescription?: string;
  subDescriptionClassName?: string;
}

const EmptyStateIllustration: VFC<Props> = ({
  button,
  image,
  title,
  description,
  showSeparator,
  descriptionClassName = 'w-2/3',
  className,
  contentClassName,
  titleClassName,
  imageClassName = 'w-2/4',
  padding = true,
  subDescription,
  subDescriptionClassName = 'w-2/3',
}) => (
  <div
    className={classNames(
      'flex flex-col items-center gap-4',
      {
        'lg:border-r border-b lg:border-b-0': showSeparator,
        'p-10': padding,
      },
      className
    )}
  >
    <div className={classNames('block', imageClassName)}>
      {typeof image === 'string' ? (
        <Image
          layout="responsive"
          width={300}
          height={134}
          src={image}
          alt="Empty Illustration"
          className="object-contain"
        />
      ) : (
        image
      )}
    </div>
    <div
      className={classNames(
        'gap-4 flex flex-col justify-center items-center text-headraceBlack-800',
        contentClassName
      )}
    >
      {title && (
        <div className={classNames('font-medium text-lg', titleClassName)}>
          {title}
        </div>
      )}

      {description && (
        <div
          className={classNames(
            'text-sm text-center font-normal',
            descriptionClassName
          )}
        >
          {description}
        </div>
      )}
      {subDescription && (
        <div
          className={classNames(
            'text-sm text-center font-normal italic',
            subDescriptionClassName
          )}
        >
          {subDescription}
        </div>
      )}
      {button && <Button onClick={button.onClick}>{button.label}</Button>}
    </div>
  </div>
);

export default EmptyStateIllustration;
