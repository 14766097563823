import classNames from 'classnames';
import type { VFC } from 'react';

interface ErrorProps {
  id: string;
  error: string | null;
  touched: boolean;
  customError?: string | null;
  className?: string;
}

const ErrorText: VFC<ErrorProps> = ({
  id,
  error,
  touched,
  customError,
  className,
}) => {
  if ((touched && error) || customError)
    return (
      <div
        id={id}
        className={classNames(
          className,
          'mt-2 text-sm text-red-600 text-center'
        )}
      >
        {error || customError}
      </div>
    );
  return null;
};
export default ErrorText;
