import type { VFC } from 'react';
import { useMemo, useState } from 'react';

import Button from './Button';

interface TruncatedTextProps {
  text: string;
  truncateLimit?: number;
  allowAfterExpand?: boolean; // allow re-contract of text after expanding it
  className?: string;
  expandText?: string;
  onClick?: () => void;
  showSpread?: boolean;
}

const TruncatedText: VFC<TruncatedTextProps> = (props) => {
  const {
    text,
    truncateLimit = text.length / 2,
    className,
    allowAfterExpand,
    expandText = 'See',
    onClick,
    showSpread = false,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const generatedElement = useMemo(() => {
    const mustTruncate = text.length > truncateLimit && !isExpanded;
    const truncatedDescription = mustTruncate
      ? `${text.substring(0, Math.floor(truncateLimit))}${
          showSpread ? '...' : ''
        }`
      : text;
    if (text.length > truncateLimit) {
      return (
        <>
          {truncatedDescription}{' '}
          {(mustTruncate || allowAfterExpand) && (
            <Button
              buttonType="link"
              className="mx-0"
              onClick={
                onClick
                  ? (): void => onClick()
                  : (): void => {
                      setIsExpanded(mustTruncate);
                    }
              }
            >
              {mustTruncate
                ? `${expandText} ${onClick ? '' : 'more'}`
                : `${expandText} ${onClick ? '' : 'less'}`}
            </Button>
          )}
        </>
      );
    }
    return text;
  }, [
    allowAfterExpand,
    expandText,
    isExpanded,
    onClick,
    showSpread,
    text,
    truncateLimit,
  ]);

  return <span className={className}>{generatedElement}</span>;
};

export default TruncatedText;
