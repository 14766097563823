import classNames from 'classnames';
import Link from 'next/link';
import type { VFC } from 'react';

interface BannerProps {
  className?: string;
  iconContainerClassName?: string;
  icon?: React.ReactNode;
  text: React.ReactNode;
  actionbutton?: {
    text: string;
    action: (() => void) | string;
  };
  textClassName?: string;
}

const Banner: VFC<BannerProps> = ({
  className,
  icon,
  text,
  actionbutton,
  iconContainerClassName,
  textClassName,
}) => {
  const classNameAction = classNames(
    'bg-white text-headraceBlack-700 px-4 py-2 rounded-lg text-sm font-medium'
  );
  return (
    <div
      className={classNames(
        ' text-white rounded-lg flex justify-between items-center p-3 flex-wrap gap-2',
        { 'bg-headraceGray-600': !className },
        className
      )}
    >
      <div className="flex items-center gap-3">
        {icon && (
          <div
            className={classNames(
              iconContainerClassName,
              'p-3 bg-headraceGray-700 rounded-lg'
            )}
          >
            {icon}
          </div>
        )}
        <div className={classNames('font-medium text-base', textClassName)}>
          {text}
        </div>
      </div>

      {actionbutton && (
        <div>
          {typeof actionbutton.action === 'string' ? (
            <Link href={actionbutton.action} passHref>
              <a className={classNameAction}>{actionbutton.text}</a>
            </Link>
          ) : (
            <button
              type="button"
              onClick={actionbutton.action}
              className={classNameAction}
            >
              {actionbutton.text}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Banner;
