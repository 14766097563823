import { ExclamationCircleIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { ReactNode } from 'react';

interface SimpleEmptyProps {
  icon?: React.ReactNode;
  title: string;
  content?: string;
  actionElement?: ReactNode;
  className?: string;
}

const SimpleEmpty: React.FC<SimpleEmptyProps> = (props) => {
  const { icon, title, content, actionElement, className = '' } = props;

  return (
    <div
      className={classNames(
        'text-center flex flex-col justify-center rounded-lg',
        className
      )}
    >
      {icon ? (
        <div className="mx-auto text-gray-400">{icon}</div>
      ) : (
        <ExclamationCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
      )}
      <h3 className="mt-2 text-sm font-medium text-headraceBlack-700">
        {title}
      </h3>
      {content ? <p className="mt-1 text-sm text-gray-500">{content}</p> : ''}
      {actionElement && <div className="mt-6">{actionElement}</div>}
    </div>
  );
};

export default SimpleEmpty;
