import { CandidateActivityHistoryActivityType } from '@headrace/types';
import { type NavigationItem, TabbedNavigation } from '@headrace/ui';
import classNames from 'classnames';
import { type FC, useMemo } from 'react';

import { useCandidatesByEmployerQuery } from '@/graphql/generated';

export const candidatesNavigation: NavigationItem[] = [
  {
    href: '/candidates',
    name: 'Inbox',
    yellowStripe: true,
  },
  {
    href: '/candidates/interviewing',
    name: 'Interviewing',
    pillType: 'SECONDARY',
    yellowStripe: true,
  },
  {
    href: '/candidates/hired',
    name: 'Hired',
    pillType: 'SECONDARY',
    yellowStripe: true,
  },
  {
    href: '/candidates/rejected',
    name: 'Rejected',
    pillType: 'SECONDARY',
    yellowStripe: true,
  },
];

const CandidatesLayoutWrapper: FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const { data } = useCandidatesByEmployerQuery();

  const list = useMemo(
    () => data?.candidatesByEmployer.candidates ?? [],
    [data]
  );

  const formattedNavigation = useMemo(
    () =>
      candidatesNavigation.map((nav) => {
        const newNav = { ...nav };

        switch (nav.name) {
          case 'Inbox': {
            const filtered = list.filter(
              (c) => c.status === CandidateActivityHistoryActivityType.SUBMITTED
            );

            if (filtered.length) newNav.pill = filtered.length;
            break;
          }
          case 'Interviewing': {
            const filtered = list.filter(
              (c) =>
                c.status === CandidateActivityHistoryActivityType.IN_PROCESS
            );

            if (filtered.length) newNav.pill = filtered.length;
            break;
          }
          case 'Hired': {
            const filtered = list.filter(
              (c) =>
                c.status === CandidateActivityHistoryActivityType.OFFER_ACCEPTED
            );

            if (filtered.length) newNav.pill = filtered.length;
            break;
          }
          default:
            break;
        }
        return newNav;
      }),
    [list]
  );

  return (
    <TabbedNavigation
      navigation={formattedNavigation}
      className={classNames('!min-h-[975px]', className)}
    >
      {children}
    </TabbedNavigation>
  );
};

export default CandidatesLayoutWrapper;
