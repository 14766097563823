import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
}

const TextJustifyIcon: VFC<Props> = ({
  className = 'bi bi-justify',
  width = '16',
  height = '16',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66663 4H13.3333M2.66663 8H13.3333M2.66663 12H13.3333"
      stroke="#4B5563"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TextJustifyIcon;
