import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import QuestionMarkIcon from '../../../QuestionMarkIcon';
import ErrorText from '../ErrorText';
import useFocusOnError from './errorHooks';
import InputErrorIcon from './ErrorIcon';

export interface InputChangeHandler {
  (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue?: (
      field: string,
      value: unknown,
      shouldValidate?: boolean
    ) => void
  ): void;
}

interface InputProps {
  type?: string;
  label?: string;
  subtitle?: string | JSX.Element;
  description?: string | JSX.Element;
  autoComplete?: string;
  name: string;
  placeholder?: string;
  helper?: string | JSX.Element;
  onChange?: InputChangeHandler;
  disabled?: boolean;
  min?: number;
  boxClassName?: string;
  icon?: JSX.Element;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  helperClassName?: string;
  inputClassName?: string;
  tooltip?: string;
  tooltipClass?: string;
  showError?: boolean;
  customError?: string | null;
  errorClassName?: string;
  info?: string;
  infoIcon?: JSX.Element;
  fullWidth?: boolean;
}

const Input: React.VFC<InputProps> = (props) => {
  const {
    type = 'text',
    label,
    description,
    subtitle,
    autoComplete = 'off',
    name,
    placeholder = '',
    helper,
    disabled = false,
    onChange,
    onKeyPress,
    min,
    boxClassName,
    icon,
    helperClassName,
    inputClassName,
    tooltip,
    tooltipClass,
    showError = true,
    customError,
    errorClassName,
    info,
    infoIcon = <QuestionMarkCircleIcon className="w-4 h-4" />,
    fullWidth = false,
  } = props;
  const [field, meta] = useField<string>(name);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { setFieldValue } = useFormikContext();
  const [showCustomError, setShowCustomError] = useState<string | null>(null);

  useEffect(() => {
    setShowCustomError(customError || null);
  }, [customError]);

  const className = classNames(
    'focus:ring-transparent',
    'focus:border-gray-400',
    'w-full',
    'sm:text-sm',
    'border',
    'border-gray-300',
    'h-[38px]',
    inputClassName,
    {
      'rounded-md': !helper || !icon,
      'block rounded-none rounded-r-md flex-1': !!helper,
    },
    {
      'block flex-1 pl-10': !!icon,
    },
    {
      'border-red-500 text-red-900 placeholder-red-300 focus-outline-nodes focus:ring-red-500 focus:border-red-500':
        (meta.touched && meta.error) || showCustomError,
    },
    {
      'opacity-50 cursor-not-allowed': disabled,
    }
  );

  const iconClassName = classNames(
    'absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none',
    {
      'border-red-500 text-red-900 focus-outline-nodes focus:ring-red-500 focus:border-red-500':
        (meta.touched && meta.error) || showCustomError,
    }
  );

  const divClassName = classNames('flex flex-col gap-1', boxClassName);
  useFocusOnError(inputRef, name);

  return (
    <div className={classNames('w-full', fullWidth)}>
      <div className={divClassName}>
        {label && (
          <div>
            <label
              htmlFor={field.name}
              className="text-sm font-medium text-gray-900 flex items-center gap-2"
            >
              {label}
              {info && (
                <div>
                  <div data-for={`${field.name}-info`} data-tip={info}>
                    {infoIcon}
                  </div>
                  <ReactTooltip
                    id={`${field.name}-info`}
                    place="top"
                    effect="solid"
                    html
                    arrowColor="transparent"
                    className="!opacity-100 !bg-headraceBlack-800 !px-[12px] !text-xs !font-normal"
                  />
                </div>
              )}
            </label>
            {subtitle && <p className="text-xs text-gray-500">{subtitle}</p>}
          </div>
        )}
        <div className="flex relative">
          {icon && <div className={iconClassName}>{icon}</div>}
          {helper && (
            <span
              className={classNames(
                'inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm',
                helperClassName
              )}
            >
              {helper}
            </span>
          )}
          <input
            type={type}
            name={field.name}
            id={field.name}
            autoComplete={autoComplete}
            placeholder={placeholder}
            className={className}
            onChange={
              onChange
                ? (e): void => {
                    onChange(e, setFieldValue);
                  }
                : field.onChange
            }
            onBlur={(e): void => {
              field.onBlur(e);
              const pattern = /^https?:\/\//;
              if (
                e.target.value &&
                pattern.test(e.target.value) &&
                (helper === 'http://' || helper === 'https://')
              ) {
                setFieldValue(field.name, e.target.value.replace(pattern, ''));
              }
            }}
            disabled={disabled}
            value={field.value}
            onKeyPress={onKeyPress}
            min={min}
            ref={inputRef}
            onFocus={(): void => {
              setShowCustomError(null);
            }}
          />
          {(meta.touched && meta.error) || showCustomError ? (
            <InputErrorIcon />
          ) : null}
          {tooltip && (
            <QuestionMarkIcon
              error={meta.touched && meta.error}
              tooltip={tooltip}
              fieldName={field.name}
              customTooltipStyles={tooltipClass}
            />
          )}
        </div>
        {description && <p className="text-sm text-gray-500">{description}</p>}
      </div>
      {showError && (
        <ErrorText
          id={`${field.name}-error`}
          error={meta.error || null}
          touched={meta.touched}
          customError={showCustomError}
          className={errorClassName}
        />
      )}
    </div>
  );
};

export default Input;
