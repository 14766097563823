import type { VFC } from 'react';

interface Props {
  error: string;
}
const ErrorMessageBox: VFC<Props> = ({ error }) => (
  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
    <span className="block sm:inline">{error}</span>
  </div>
);

export default ErrorMessageBox;
