import { useRouter } from 'next/router';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export type SecondaryNavigationItem =
  | {
      type: 'primary' | 'secondary';
      label: string;
      action: string | (() => void);
      hidden?: boolean;
      loading?: boolean;
      disabled?: boolean;
    }
  | {
      type: 'other';
      element: React.ReactNode;
    }
  | {
      type: 'below';
      element: React.ReactNode;
    };

interface SecondaryNavContextData {
  navigationItems: SecondaryNavigationItem[];
  setNavigationItems: (
    props:
      | SecondaryNavigationItem[]
      | ((arg0: SecondaryNavigationItem[]) => SecondaryNavigationItem[])
  ) => void;
  title: string | null | JSX.Element;
  setTitle: (title: string | JSX.Element) => void;
}

const SecondaryNavContext = React.createContext<SecondaryNavContextData>({
  navigationItems: [],
  setNavigationItems: () => {},
  title: null,
  setTitle: () => {},
});

interface SecondaryNavProviderProps {
  navChildrens?: string[];
}

const SecondaryNavProvider: React.FC<SecondaryNavProviderProps> = ({
  children,
  navChildrens = [],
}) => {
  const { pathname } = useRouter();
  const [navigationItems, setNavigationItems] = useState<
    SecondaryNavigationItem[]
  >([]);

  const [title, setTitle] = useState<string | null | JSX.Element>(null);
  const lastVisitedPage = useRef<string>(pathname);
  const lastVisitedPageWitCustomTitle = useRef<{
    path: string;
    children: string[];
  }>({
    path: pathname,
    children: navChildrens,
  });

  const customSetNavigationItems = useCallback(
    (
      newState:
        | SecondaryNavigationItem[]
        | ((arg0: SecondaryNavigationItem[]) => SecondaryNavigationItem[])
    ): void => {
      lastVisitedPage.current = pathname;
      setNavigationItems(newState);
    },
    [pathname, setNavigationItems]
  );

  const customTitle = useCallback(
    (newTitle: string | JSX.Element): void => {
      lastVisitedPageWitCustomTitle.current = {
        path: pathname,
        children: navChildrens,
      };
      setTitle(newTitle);
    },
    [navChildrens, pathname]
  );

  const contextState: SecondaryNavContextData = useMemo(
    () => ({
      navigationItems,
      setNavigationItems: customSetNavigationItems,
      title,
      setTitle: customTitle,
    }),
    [navigationItems, customSetNavigationItems, title, customTitle]
  );

  useEffect(() => {
    if (pathname !== lastVisitedPage.current) {
      lastVisitedPage.current = pathname;

      setNavigationItems([]);
    }
  }, [pathname, navigationItems]);

  useEffect(() => {
    const isSameArray =
      navChildrens.length ===
        lastVisitedPageWitCustomTitle.current.children.length &&
      navChildrens.every(
        (element, index) =>
          element === lastVisitedPageWitCustomTitle.current.children[index]
      );
    if (
      pathname !== lastVisitedPageWitCustomTitle.current.path &&
      (!isSameArray || !lastVisitedPageWitCustomTitle.current.children.length)
    ) {
      lastVisitedPageWitCustomTitle.current = {
        path: pathname,
        children: navChildrens,
      };

      setTitle(null);
    }
  }, [navChildrens, pathname, title]);

  return (
    <SecondaryNavContext.Provider value={contextState}>
      {children}
    </SecondaryNavContext.Provider>
  );
};

export const useSecondaryNav = (): SecondaryNavContextData =>
  React.useContext(SecondaryNavContext);

export default SecondaryNavProvider;
