import { PROFILE_PICTURES_FOLDER } from '@headrace/constants';
import { getCroppedImg, isValidImage } from '@headrace/utils';
import { PhotographIcon } from '@heroicons/react/outline';
import axios from 'axios';
import classNames from 'classnames';
import type { ChangeEvent, VFC } from 'react';
import { useRef, useState } from 'react';
import type { Area } from 'react-easy-crop/types';
import toast from 'react-hot-toast';
import ReactTooltip from 'react-tooltip';

import Button from './Button';
import CropModal from './CropModal';
import { GlobalIcon } from './CustomIcons';

export interface MediaUploadLinkFields {
  Policy: string;
  X_Amz_Algorithm: string;
  X_Amz_Credential: string;
  X_Amz_Date: string;
  X_Amz_Security_Token?: string | null;
  X_Amz_Signature: string;
  bucket: string;
  key: string;
}

export type CreateMediaUploadLinkFields = (
  fileName: string,
  folder: string
) => Promise<{
  data?: { fields: MediaUploadLinkFields; url: string };
}>;

interface Props {
  getImageData: (image: string, key: string) => void;
  cropShape?: 'rect' | 'round';
  aspectRatio?: number;
  minZoom?: number;
  label?: React.ReactNode;
  buttonLabel?: string;
  cropSize?: { width: number; height: number };
  createMediaUploadLink: CreateMediaUploadLinkFields;
  className?: string;
  helper?: string;
  helperIcon?: JSX.Element;
  folderName?: string;
}

const ImageUpload: VFC<Props> = ({
  getImageData,
  cropShape = 'rect',
  aspectRatio = 1,
  minZoom = 0.5,
  buttonLabel = 'Upload photo',
  label,
  cropSize,
  createMediaUploadLink,
  className,
  helper,
  folderName = PROFILE_PICTURES_FOLDER,
  helperIcon = <GlobalIcon />,
}) => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [imageMetaData, setImageMetaData] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const triggerFileSelectPopup = (): void => inputRef.current?.click();

  // To read file as Data URL
  const onSelectFile: (event: ChangeEvent<HTMLInputElement>) => void = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setImage(null);
    if (event.target.files && event.target.files.length > 0) {
      setImageMetaData(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener('load', () => {
        if (reader.result) {
          setImage(reader.result.toString());
          setOpen(true);
        }
      });
      // fix to open file selector again
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const closeModal: () => void = () => {
    setOpen(false);
  };

  // To upload the edited image
  const onUpload = async (croppedArea: Area | null): Promise<void> => {
    if (!image || !croppedArea || !imageMetaData)
      toast.error('Cannot upload an image that has not been cropped');
    else if (isValidImage(imageMetaData)) {
      const blob = await getCroppedImg(imageMetaData.type, image, croppedArea);
      const fileImage = new File([blob], imageMetaData.name, {
        type: imageMetaData.type,
      });
      setUploading(true);
      try {
        const formdata = new FormData();

        const data = await createMediaUploadLink(fileImage.name, folderName);
        if (data.data) {
          const presignedPost = data.data;
          const { url, fields } = presignedPost;
          Object.entries(fields).forEach(
            ([key, value]: [string, string | null]) => {
              if (value != null) {
                formdata.append(key.replace(/_/g, '-'), value);
              }
            }
          );
          formdata.append('file', fileImage);
          await axios.post(url, formdata);
          const blobUrl = URL.createObjectURL(blob);
          getImageData(blobUrl, fields.key);
        }
      } catch (err) {
        toast.error('Error uploading image');
      }
      setUploading(false);
    } else {
      toast.error('Invalid image');
    }

    closeModal();
  };

  return (
    <>
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        ref={inputRef}
        onChange={onSelectFile}
        style={{ display: 'none' }}
      />

      <div className={classNames(className, 'flex flex-col gap-4')}>
        {label && (
          <span className="block text-sm font-bold text-headraceBlack-700">
            <div className="flex gap-2 items-center">
              <span>{label}</span>
              {helper && (
                <div>
                  <div data-for="image-upload-helper" data-tip={helper}>
                    {helperIcon}
                  </div>
                  <ReactTooltip
                    id="image-upload-helper"
                    place="top"
                    effect="solid"
                    html
                    arrowColor="transparent"
                    className="!opacity-100 !bg-headraceBlack-800 !px-[12px] !text-xs !font-normal"
                  />
                </div>
              )}
            </div>
          </span>
        )}
        <Button
          buttonType="secondary"
          onClick={triggerFileSelectPopup}
          className="sm:w-full w-full"
          icon={<PhotographIcon className="h-4 w-4 text-headraceBlack-600" />}
        >
          {buttonLabel}
        </Button>
      </div>

      <CropModal
        open={open}
        onClose={closeModal}
        image={image}
        onUpload={onUpload}
        uploading={uploading}
        aspectRatio={aspectRatio}
        cropShape={cropShape}
        minZoom={minZoom}
        cropSize={cropSize}
      />
    </>
  );
};

export default ImageUpload;
