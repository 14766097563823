import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';
import { useEffect, useState } from 'react';

import Button from '../../../Button';
import OverlayDialog from '../../../OverlayDialog';

export interface FilterProps {
  selectedValues: SelectedValuesType;
  columnName: string;
}
export type SelectedValuesType = string[];

const capitalizeString = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();

interface Props {
  setSelectedValues: (newSelectedValues: SelectedValuesType) => void;
  selectedValues: SelectedValuesType;
  closeMenu?: () => void;
  filterOptions?: string[];
  placeholder: string;
  counterName?: string;
}

const Filter: VFC<Props> = ({
  filterOptions,
  selectedValues,
  setSelectedValues,
  closeMenu,
}) => {
  const [selected, setSelected] = useState<string[]>([]);

  const selectColumn = (value: string): void => {
    if (selected.includes(value)) {
      setSelected(selected.filter((val) => val !== value));
    } else {
      setSelected([...selected, value]);
    }
  };
  const resetFilters = (): void => {
    setSelectedValues([]);
  };
  const saveFilters = (): void => {
    setSelectedValues(selected);
    if (closeMenu) closeMenu();
  };

  useEffect(() => {
    setSelected(selectedValues);
  }, [selectedValues, setSelected]);

  if (!filterOptions) return <span />;
  return (
    <div className="flex flex-col items-start">
      <div className="w-full pr-[2px]">
        <div className="max-h-40 overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-zinc-300 hover:scrollbar-thumb-zinc-300">
          {filterOptions.map((option) => (
            <button
              type="button"
              key={option}
              className={classNames(
                'w-full px-3 py-2 flex flex-row items-stretch',
                {
                  'font-bold': selected.includes(option),
                }
              )}
              onClick={(): void => selectColumn(option)}
            >
              <span className="text-headraceBlack-800 text-left text-sm font-normal grow">
                {capitalizeString(option)}
              </span>
              {selected.includes(option) && (
                <CheckIcon className="w-4 h-4 stroke-[3px] grow-0" />
              )}
            </button>
          ))}
        </div>
      </div>
      <div className="gap-2 flex flex-row w-full p-2 border-t border-gray-100">
        <Button
          buttonType="secondary"
          className="flex-1 px-[17px] py-[9px]"
          onClick={resetFilters}
        >
          Reset
        </Button>
        <Button buttonType="primary" className="flex-1" onClick={saveFilters}>
          Done
        </Button>
      </div>
    </div>
  );
};

const FilterContainer: VFC<Props> = ({
  filterOptions,
  selectedValues,
  setSelectedValues,
  placeholder,
  counterName = 'items',
}) => {
  const selectContainer: VFC = () => (
    <div className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none sm:text-sm font-normal text-gray-500">
      <span className="block truncate">
        {selectedValues.length > 0
          ? `${selectedValues.length} ${counterName} selected`
          : placeholder}
      </span>
      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <div className="text-gray-400">
          <ChevronUpIcon className="h-3 w-3 -mb-1" />
          <ChevronDownIcon className="h-3 w-3" />
        </div>
      </span>
    </div>
  );
  return (
    <OverlayDialog
      Icon={selectContainer}
      iconContainerClassName="!p-0 !w-48"
      panelClassName="mt-2"
    >
      <Filter
        filterOptions={filterOptions}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        placeholder={placeholder}
      />
    </OverlayDialog>
  );
};

export default FilterContainer;
