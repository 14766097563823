import type { FeeScheduleTypeEnum, FeeTriggerTypeEnum } from '@headrace/types';
import type { VFC } from 'react';

import FeeFactory from './FeeFactory';
import FeeNumber from './FeeNumber';

interface FeeProps {
  amount: number;
  feeTriggerType: FeeTriggerTypeEnum;
  feeScheduleType: FeeScheduleTypeEnum;
  feeTriggerLimit?: number | null;
  index: number;
  paymentTerm: number;
  performanceCandidateAcceptQuantity?: number | null;
  substractRetainerFees: boolean;
  oneTimeFeeTriggerAt?: Date | null;
  oneTimeFeeRelativeSigningDays?: number | null;
}

const Fee: VFC<FeeProps> = ({
  amount,
  feeTriggerType,
  feeScheduleType,
  feeTriggerLimit,
  index,
  paymentTerm,
  performanceCandidateAcceptQuantity,
  substractRetainerFees,
  oneTimeFeeTriggerAt,
  oneTimeFeeRelativeSigningDays,
}) => (
  <div className="grid grid-cols-[58px_2fr_1fr_4fr_2fr_auto] rounded-md items-center border gap-4 mb-4">
    <FeeNumber index={index + 1} />
    <FeeFactory
      amount={amount}
      feeTriggerType={feeTriggerType}
      feeScheduleType={feeScheduleType}
      feeTriggerLimit={feeTriggerLimit}
      paymentTerm={paymentTerm}
      substractRetainerFees={substractRetainerFees}
      performanceCandidateAcceptQuantity={performanceCandidateAcceptQuantity}
      oneTimeFeeTriggerAt={oneTimeFeeTriggerAt}
      oneTimeFeeRelativeSigningDays={oneTimeFeeRelativeSigningDays}
    />
  </div>
);

export default Fee;
