import classNames from 'classnames';
import { useMemo } from 'react';
import Rating from 'react-rating';

import StarIcon from '../CustomIcons/StarIcon';

interface RatingProps {
  initialRating: number;
  iconSize: string;
  enableUserInteraction?: boolean;
  onChange?: (index: number) => void;
}

const RatingStars: React.VFC<RatingProps> = (props) => {
  const {
    initialRating,
    iconSize,
    enableUserInteraction = false,
    onChange,
  } = props;

  const RatingHighlighted = useMemo(
    () => (
      <StarIcon
        width={iconSize}
        height={iconSize}
        className={classNames({ 'cursor-pointer': enableUserInteraction })}
      />
    ),
    [enableUserInteraction, iconSize]
  );
  const RatingDefault = useMemo(
    () => (
      <StarIcon
        width={iconSize}
        height={iconSize}
        className={classNames({
          'cursor-pointer': enableUserInteraction,
        })}
        color="#E5E7EB"
      />
    ),
    [enableUserInteraction, iconSize]
  );

  return (
    <Rating
      initialRating={initialRating}
      onChange={onChange}
      fractions={2}
      fullSymbol={RatingHighlighted}
      emptySymbol={RatingDefault}
      readonly={!enableUserInteraction}
    />
  );
};

export default RatingStars;
