import { FeeScheduleTypeEnum } from '@headrace/types';
import type { VFC } from 'react';

interface CandidatePlacementFeeProps {
  amount: string;
  feeScheduleType: FeeScheduleTypeEnum;
  substractRetainerFees: boolean;
  trigger: string;
}

const CandidatePlacementFee: VFC<CandidatePlacementFeeProps> = ({
  amount,
  feeScheduleType,
  substractRetainerFees,
  trigger,
}) => {
  const parsedAmount =
    feeScheduleType === FeeScheduleTypeEnum.CASH_FIXED
      ? `$${amount}`
      : `${amount}%`;
  return (
    <>
      <div>
        <span>{parsedAmount}</span>
      </div>
      <div>
        <span>{trigger}</span>
        {substractRetainerFees && <span> | Subtract retainer fees </span>}
      </div>
    </>
  );
};

export default CandidatePlacementFee;
