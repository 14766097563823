import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  DotsCircleHorizontalIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';
import type { Toast } from 'react-hot-toast';
import { toast } from 'react-hot-toast';

interface Props {
  id: Toast['id'];
  message: Toast['message'] | ((t: Toast) => Toast['message']);
  type: Toast['type'];
  visible: Toast['visible'];
}

const ICONS: Record<Toast['type'], JSX.Element | null> = {
  success: (
    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
  ),
  error: <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
  loading: (
    <DotsCircleHorizontalIcon
      className="h-6 w-6 text-gray-400"
      aria-hidden="true"
    />
  ),
  blank: null,
  custom: null,
};

const SimpleNotification: React.VFC<Props> = (props) => {
  const { id, message, type, visible } = props;

  const handleCloseClick = (): void => {
    toast.dismiss(id);
  };

  return (
    <Transition
      show={visible}
      as={Fragment}
      enter="transform ease-out duration-3000 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">{ICONS[type]}</div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <div className="text-sm font-medium text-gray-900">{message}</div>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                type="button"
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleCloseClick}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default SimpleNotification;
