import { Form, Formik } from 'formik';
import Image from 'next/image';

import { CheckboxField, Input, SubmitButton, TextArea } from './forms';
import PageLoader from './PageLoader';

export enum RegistrationFormType {
  RECRUITER = 'RECRUITER',
  EMPLOYER = 'EMPLOYER',
}

export interface RegistrationFormValues {
  firstName?: string;
  lastName?: string;
  email?: string;
  tou: boolean;
  agencyName?: string;
  companyName?: string;
  companyDescription?: string;
  linkedIn?: string;
}

interface RecruiterFormProps {
  onSubmit: (values: RegistrationFormValues) => Promise<void>;
  type: RegistrationFormType;
  error?: string;
  loading?: boolean;
  initialValues: RegistrationFormValues;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema: any;
  userData?: boolean;
}

const RecruiterForm = (props: RecruiterFormProps): JSX.Element => {
  const {
    onSubmit,
    type,
    error,
    loading = false,
    initialValues,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    validationSchema,
    userData = false,
  } = props;

  if (loading) return <PageLoader />;
  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="mx-auto h-12 w-auto relative">
          <Image layout="fill" src="/headrace_icon.svg" alt="HeadRace Logo" />
        </div>

        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Complete your registration
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            validationSchema={validationSchema}
            enableReinitialize
          >
            <Form className="space-y-6">
              <div>
                <Input label="Email" type="email" name="email" disabled />
              </div>

              <div>
                <Input label="First Name" type="text" name="firstName" />
              </div>

              <div>
                <Input label="Last Name" type="text" name="lastName" />
              </div>

              {!userData && type === RegistrationFormType.RECRUITER && (
                <div>
                  <Input
                    label="Agency Name or DBA (Optional)"
                    type="text"
                    name="agencyName"
                  />
                </div>
              )}

              {type === RegistrationFormType.EMPLOYER && (
                <>
                  <div>
                    <Input
                      label="Company Name"
                      type="text"
                      name="companyName"
                    />
                  </div>
                  <div>
                    <TextArea
                      label="Company Description"
                      name="companyDescription"
                    />
                  </div>
                  <div>
                    <Input
                      label="LinkedIn Company Page URL"
                      type="text"
                      name="linkedIn"
                      helper="https://"
                    />
                  </div>
                </>
              )}

              <div className="flex">
                <CheckboxField name="tou" label="" required />
                <label
                  htmlFor="tou"
                  className="inline-block text-sm text-gray-900"
                >
                  I agree to HeadRace{' '}
                  <a
                    className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.headrace.com/info/terms-of-use"
                  >
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a
                    className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.headrace.com/info/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>

              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                  <span className="block sm:inline">{error}</span>
                </div>
              )}
              <div>
                <SubmitButton disabled={loading} className="sm:w-full w-full">
                  Continue
                </SubmitButton>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RecruiterForm;
