import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import React, { useRef } from 'react';
import type { MultiValue, SingleValue } from 'react-select';

import type { OptionsProps, SelectAction } from '../BasicSelect';
import BasicSelect from '../BasicSelect';
import ErrorText from '../ErrorText';
import useFocusOnError from './errorHooks';
import InputErrorIcon from './ErrorIcon';

interface MultiSelectFieldProps {
  label?: string;
  labelHelper?: string;
  description?: string;
  name: string;
  placeholder?: string;
  helper?: string | JSX.Element;
  disabled?: boolean;
  validatioError?: string;
  maxMenuHeight?: number;
  options: OptionsProps[];
  hideSelectedOptions?: boolean;
  displayCounter?: boolean;
  actions?: SelectAction[];
  isSearchable?: boolean;
}

const MultiSelectField: React.VFC<MultiSelectFieldProps> = (props) => {
  const {
    label,
    labelHelper,
    description,
    name,
    placeholder = '',
    helper,
    disabled = false,
    options,
    maxMenuHeight = 210,
    hideSelectedOptions = true,
    displayCounter = false,
    actions,
    isSearchable = false,
  } = props;
  const [field, meta] = useField<string>(name);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const { setFieldValue } = useFormikContext();
  const className = classNames(
    'w-full min-h-[38px]',
    'sm:text-sm',
    {
      block: !!helper,
      'rounded-md': !helper,
      'rounded-none': !!helper,
      'rounded-r-md': !!helper,
      'flex-1': !!helper,
    },
    {
      'opacity-50': disabled,
      'cursor-not-allowed': disabled,
    }
  );
  useFocusOnError(inputRef, name);

  const getValue = (): OptionsProps[] => {
    if (options && field.value) {
      return options.filter((option) => field.value.indexOf(option.value) >= 0);
    }
    return [];
  };
  const onChange = (
    option: MultiValue<OptionsProps> | SingleValue<OptionsProps>
  ): void => {
    setFieldValue(
      field.name,
      (option as OptionsProps[]).map((item: OptionsProps) => item.value)
    );
  };

  return (
    <>
      <div className="flex flex-col gap-1">
        {label && (
          <label
            htmlFor={field.name}
            className="block text-sm font-medium text-headraceBlack-800"
          >
            {label}{' '}
            {labelHelper && (
              <span className="text-xs text-gray-400 italic">
                {labelHelper}
              </span>
            )}
          </label>
        )}
        <div className="flex relative rounded-md shadow-sm">
          {helper && (
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
              {helper}
            </span>
          )}
          <BasicSelect
            options={options}
            name={field.name}
            isClearable={false}
            closeMenuOnSelect={false}
            className={className}
            placeholder={placeholder}
            maxMenuHeight={maxMenuHeight}
            isMulti
            value={getValue()}
            onChange={onChange}
            onBlur={field.onBlur}
            hideSelectedOptions={hideSelectedOptions}
            displayCounter={displayCounter}
            hasError={Boolean(meta.touched && meta.error)}
            inputRef={inputRef}
            actions={actions}
            isSearchable={isSearchable}
          />
          {meta.touched && meta.error && <InputErrorIcon className="right-7" />}
        </div>
        {description && (
          <p className="mt-2 text-sm text-gray-500">{description}</p>
        )}
      </div>
      <ErrorText
        id={`${field.name}-error`}
        error={meta.error || null}
        touched={meta.touched}
      />
    </>
  );
};

export default MultiSelectField;
