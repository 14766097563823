import type { ApolloError } from '@apollo/client';
import { UserIcon } from '@heroicons/react/outline';
import dynamic from 'next/dynamic';
import type { VFC } from 'react';
import { useMemo, useState } from 'react';

import Button from '../../Button';
import SearchBar from '../../SearchBar';
import type { InviteModalFormI } from './InviteMemberModal';
import TeamTable from './TeamTable';

const InviteMemberModal = dynamic(() => import('./InviteMemberModal'));

export interface Member {
  id: string;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    name: string;
    photoUrl: string;
  };
}

interface TeamManagementProps {
  currentUserId?: string;
  listMembers: {
    data?: Member[] | null;
    loading: boolean;
    error?: ApolloError;
  };
  refetchTeamMembers: () => Promise<void>;
  deleteMember: {
    action: (userId?: string) => Promise<void>;
    loading: boolean;
  };
  inviteMember: {
    action: (values: InviteModalFormI) => Promise<void>;
    loading: boolean;
  };
  trackAddMember: () => void;
  requiredPhoneNumber?: boolean;
}

const TeamManagement: VFC<TeamManagementProps> = ({
  currentUserId,
  listMembers,
  refetchTeamMembers,
  deleteMember,
  inviteMember,
  trackAddMember,
  requiredPhoneNumber = false,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [filterText, setFilterText] = useState<string | null>();

  const refetchAndCloseModal = async (): Promise<void> => {
    await refetchTeamMembers();
    setOpenModal(false);
  };

  const openAddTeamMemberModal = (): void => {
    trackAddMember();
    setOpenModal(true);
  };

  const filterSearch = (value: string): void => {
    if (value === '') {
      setFilterText(undefined);
      return;
    }
    setFilterText(value);
  };

  const teamMembers = useMemo(() => {
    if (!listMembers.data) return [];
    let filteredMembers = listMembers.data;
    if (filterText) {
      filteredMembers = filteredMembers.filter((member) =>
        member.user.name.toLowerCase().includes(filterText.toLowerCase())
      );
    }
    return filteredMembers;
  }, [filterText, listMembers.data]);

  const handleInviteMember = async (
    values: InviteModalFormI
  ): Promise<void> => {
    await inviteMember.action(values);
    await refetchAndCloseModal();
  };

  return (
    <div>
      <div className="h-9 flex justify-end items-end mb-5 gap-2">
        <Button
          onClick={openAddTeamMemberModal}
          icon={<UserIcon className="h-4 w-4" />}
        >
          Add
        </Button>
        <SearchBar
          value={filterText || ''}
          onChange={filterSearch}
          placeholder="Search team"
        />
      </div>
      <TeamTable
        data={teamMembers}
        loading={listMembers.loading}
        error={listMembers.error}
        deleteMember={deleteMember.action}
        removing={deleteMember.loading}
        userId={currentUserId}
      />

      <InviteMemberModal
        open={openModal}
        onClose={(): void => setOpenModal(false)}
        loading={inviteMember.loading}
        onSubmit={handleInviteMember}
        requiredPhoneNumber={requiredPhoneNumber}
      />
    </div>
  );
};

export default TeamManagement;
