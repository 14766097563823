import classNames from 'classnames';
import Link from 'next/link';
import { type FC, useMemo } from 'react';

import LoadingSpinner from '../LoadingSpinner';

export interface UserStatsItemProps {
  statLabel: string | JSX.Element;
  statValue: string | number;
  statTitle?: string;
  loading?: boolean;
  error?: string;
  redirectTo?: string;
  key?: string;
  onClick?: () => void;
}

const UserStatsItem: FC<UserStatsItemProps> = ({
  key,
  statLabel,
  statValue,
  error,
  loading,
  redirectTo,
  statTitle,
  onClick,
}) => {
  const content = useMemo(
    () => (
      <li
        key={key}
        className={classNames(
          'flex h-14 px-4 font-normal justify-center items-center flex-1 border border-gray-200',
          {
            'bg-red-50': Boolean(error),
            'underline text-blue-600 hover:text-blue-800 visited:text-purple-600':
              !!redirectTo,
          }
        )}
      >
        <button
          type="button"
          onClick={onClick}
          className={classNames({
            'cursor-default': !onClick && !redirectTo,
          })}
        >
          {loading ? (
            <LoadingSpinner className="w-10 h-10" />
          ) : (
            <div className="flex whitespace-nowrap" title={error || statTitle}>
              <p className="font-mediÍum">{statValue}</p>
              &nbsp;{statLabel}
            </div>
          )}
        </button>
      </li>
    ),
    [error, key, loading, onClick, redirectTo, statLabel, statTitle, statValue]
  );

  return redirectTo ? <Link href={redirectTo}>{content}</Link> : content;
};

export default UserStatsItem;
