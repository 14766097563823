import type {
  CandidateActivityHistoryEntityType,
  UpdateCandidateParams,
} from '@headrace/types';
import {
  CandidateActivityHistoryActivityType,
  CandidateRejectedReasonEnum,
} from '@headrace/types';
import { Formik } from 'formik';
import type { VFC } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';

import ModalWithIcon from '../../ModalWithIcon';
import CandidateRejectReasonForm from './CandidateRejectReasonForm';

const CandidateRejectedSchema = Yup.object().shape({
  reason: Yup.mixed()
    .oneOf(Object.keys(CandidateRejectedReasonEnum))
    .required(),
  feedback: Yup.string()
    .trim()
    .min(20, 'The feedback should be 20 characters at least.')
    .required('Feedback is required'),
});

interface CandidateRejectOfferModalProps {
  candidateId: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  rejectCandidate: (params: UpdateCandidateParams) => Promise<void>;
  shareWith: CandidateActivityHistoryEntityType;
  loading?: boolean;
  app: 'recruiter' | 'employer';
}

const CandidateRejectReasonModal: VFC<CandidateRejectOfferModalProps> = ({
  candidateId,
  open,
  setOpen,
  rejectCandidate,
  shareWith,
  loading,
  app,
}) => {
  const [share, setShare] = useState(true);
  const handleOnClose = (): void => {
    setOpen(false);
  };
  return (
    <Formik
      initialValues={{
        feedback: '',
        reason: null,
      }}
      onSubmit={async (values, { resetForm }): Promise<void> => {
        await rejectCandidate({
          candidateId,
          status: CandidateActivityHistoryActivityType.REJECTED,
          rejectReason: values.reason,
          rejectFeedback: values.feedback,
          shareRejectionInfo: share,
        });
        resetForm();
        handleOnClose();
      }}
      validationSchema={CandidateRejectedSchema}
    >
      {({ handleSubmit, resetForm }): JSX.Element => (
        <ModalWithIcon
          onClose={(): void => {
            resetForm();
            handleOnClose();
          }}
          open={open}
          title={
            app === 'employer' ? 'Pass on this candidate' : 'Reject candidate'
          }
          subtitle={
            app === 'employer'
              ? "Please provide more context on why you're passing on this candidate to help the recruiter better calibrate future submissions"
              : 'Please provide a reject reason and details'
          }
          className="sm:min-w-[600px]"
          buttons={[
            {
              type: 'primary',
              label: app === 'employer' ? 'Pass' : 'Reject',
              loading,
              action: (): void => {
                handleSubmit();
              },
            },
            {
              type: 'secondary',
              label: 'Cancel',
              action: () => handleOnClose(),
            },
          ]}
        >
          <CandidateRejectReasonForm
            share={share}
            setShare={setShare}
            shareWith={shareWith}
          />
        </ModalWithIcon>
      )}
    </Formik>
  );
};

export default CandidateRejectReasonModal;
