import classNames from 'classnames';
import Head from 'next/head';
import type { VFC } from 'react';

import Button from './Button';

interface Props {
  logOutAction: () => void;
  className?: string;
}

const AccessDeniedPage: VFC<Props> = ({ logOutAction, className }) => (
  <div className={classNames('flex justify-center', className)}>
    <Head>
      <title>Access Denied</title>
    </Head>
    <div>
      <div className="border-l-2 px-6">
        <h2 className="text-4xl sm:text-5xl font-bold mb-2">Access Denied</h2>
        <p className="text-gray-400 text-sm sm:text-base font-semibold">
          Sorry you don&apos;t have access to this page. Please try with another
          account.
        </p>
      </div>

      <Button className="mt-9" onClick={logOutAction}>
        Log out
      </Button>
    </div>
  </div>
);

export default AccessDeniedPage;
