import { UserIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Image from 'next/image';
import { useMemo } from 'react';
import TruncateMarkup from 'react-truncate-markup';

import Badge from '../Badge';
import Button from '../Button';
import Card from '../Card';
import ImageWithFallback from '../ImageWithFallback';
import TruncatedText from '../TruncatedText';
import type { FactsPanelProps } from './FactsPanel';
import FactsPanel from './FactsPanel';

export interface SummaryCardProps {
  className?: string;
  innerContainerClassName?: string;
  headerClassName?: string;
  onClick?: () => void | Promise<void>;
  title: {
    titleBadge?: JSX.Element;
    titleContent: string | JSX.Element;
    titleContainerClassName?: string;
  };
  photo?: {
    photoUrl?: string;
    showEmptyPhoto?: boolean;
    emptyUserPhoto?: boolean;
    circleShape?: boolean;
    containerClassName?: string;
  };
  subtitle: {
    subtitleContent: string | JSX.Element;
    subtitleBadges?: string[];
    subtitleBadgesClassName?: string;
    subtitleDescription?: string;
  };
  image?: { imageUrl?: string | null };
  rightContent?: JSX.Element | null;
  rightContentClassName?: string;
  headerContentClassName?: string;
  content: string | JSX.Element;
  contentClassName?: string;
  screenWidth: number;
  facts: {
    factItems: FactsPanelProps['items'];
    factsClassName?: string;
    factsContentClassName?: string;
  };
  truncateProps?: {
    breakPoint: number;
    maxLines: number;
    responsiveLines: number;
    ellipsis?: string | JSX.Element;
  };
  extraComponent?: JSX.Element | null;
}

const SummaryCard: React.VFC<SummaryCardProps> = (props) => {
  const {
    className,
    onClick,
    title,
    photo,
    subtitle,
    rightContent,
    content,
    screenWidth,
    facts,
    innerContainerClassName,
    rightContentClassName,
    headerClassName,
    headerContentClassName,
    contentClassName,
    truncateProps,
    image,
    extraComponent,
  } = props;

  const imageComponent = useMemo(() => {
    if (!image) return null;
    return (
      <div className="flex items-center h-36 w-36 min-w-max justify-center relative shadow-lg border-8 border-white rounded">
        {image.imageUrl ? (
          <ImageWithFallback
            className="object-contain !w-36 !h-36"
            src={image.imageUrl}
            objectFit="contain"
            width={144}
            height={144}
            fallbackSrc="/placeholders/company_logo_placeholder.png"
          />
        ) : (
          <Image
            className="object-scale-down !w-36 !h-36"
            src="/placeholders/company_logo_placeholder.png"
            alt="company-logo"
            objectFit="contain"
            width={100}
            height={100}
          />
        )}
      </div>
    );
  }, [image]);

  return (
    <Card className={classNames(className)}>
      <div
        aria-hidden="true"
        onClick={async (): Promise<void> => {
          if (onClick) await onClick();
        }}
        className={classNames(
          'relative flex flex-col justify-between gap-6 h-full',
          innerContainerClassName
        )}
      >
        <div
          className={classNames(
            'flex gap-4 justify-start items-start',
            headerClassName
          )}
        >
          <div
            className={classNames(
              'flex gap-4 flex-col sm:flex-col md:flex-row lg:flex-row text-center sm:text-left lg:text-center xl:text-left items-center md:items-start lg:items-center xl:items-start',
              headerContentClassName
            )}
          >
            {title.titleBadge && (
              <span className="inline-flex md:hidden lg:inline-flex xl:hidden -mb-[10px] font-bold text-sm leading-6 text-gray-400 gap-2 items-center whitespace-nowrap">
                {title.titleBadge}
              </span>
            )}
            {photo?.photoUrl ? (
              <div
                className={classNames(
                  'shadow-md !w-[126px] !h-[126px]',
                  {
                    'rounded-full overflow-hidden': photo.circleShape,
                  },
                  photo.containerClassName
                )}
              >
                <Image
                  className="!w-[126px] !h-[126px] flex-shrink-0 object-contain"
                  src={photo.photoUrl}
                  alt="Photo"
                  width={126}
                  height={126}
                  objectFit="cover"
                />
              </div>
            ) : (
              photo?.showEmptyPhoto &&
              (photo?.emptyUserPhoto ? (
                <div
                  className={classNames(
                    'shadow-md p-0.5 w-[126px] h-[126px] bg-gray-50',
                    {
                      'rounded-full overflow-hidden': photo.circleShape,
                    },
                    photo.containerClassName
                  )}
                >
                  <UserIcon className="text-gray-300 flex-shrink-0" />
                </div>
              ) : (
                <div
                  className={classNames(
                    'shadow-md p-0.5',
                    {
                      'rounded-full overflow-hidden': photo.circleShape,
                    },
                    photo.containerClassName
                  )}
                >
                  <Image
                    className="!w-[126px] !h-[126px] flex-shrink-0  object-contain"
                    src="/placeholders/company_logo_placeholder.png"
                    alt="logo_company"
                    width={126}
                    height={126}
                    objectFit="none"
                  />
                </div>
              ))
            )}
            {imageComponent}
            <div className="flex flex-col gap-2">
              <p
                className={classNames(
                  'font-medium text-2xl leading-6 text-blue-500 inline-flex gap-2 items-center justify-start',
                  title.titleContainerClassName
                )}
              >
                {typeof title.titleContent === 'string' ? (
                  <span
                    className="xl:max-w-[25ch] xl:truncate"
                    title={title.titleContent}
                  >
                    {title.titleContent}
                  </span>
                ) : (
                  title.titleContent
                )}
                {title.titleBadge && (
                  <span className="hidden md:inline-flex lg:hidden xl:inline-flex font-bold text-sm leading-6 text-gray-400 gap-2 items-center whitespace-nowrap">
                    {title.titleBadge}
                  </span>
                )}
              </p>
              {typeof subtitle.subtitleContent === 'string' ? (
                <p>{subtitle.subtitleContent}</p>
              ) : (
                subtitle.subtitleContent
              )}
              {subtitle.subtitleBadges && (
                <div
                  className={classNames(
                    'flex gap-2 flex-wrap justify-start',
                    subtitle.subtitleBadgesClassName
                  )}
                >
                  {subtitle.subtitleBadges.map((badgeText, index) => (
                    <Badge
                      key={`${index + 1}_${badgeText}`}
                      label={badgeText}
                      className="font-medium text-headraceBlack-800 text-xs leading-6 max-w-fit break-all"
                    />
                  ))}
                </div>
              )}
              {subtitle.subtitleDescription && (
                <TruncatedText
                  text={subtitle.subtitleDescription}
                  allowAfterExpand
                  truncateLimit={130}
                  expandText="View"
                  className="text-headraceBlack-800 font-medium text-base"
                />
              )}
            </div>
          </div>
          {rightContent && (
            <div className={rightContentClassName}>{rightContent}</div>
          )}
        </div>
        {extraComponent}
        {truncateProps ? (
          <TruncateMarkup
            lines={
              screenWidth > (truncateProps.breakPoint || 768)
                ? truncateProps.maxLines || 2
                : truncateProps.responsiveLines || 7
            }
            ellipsis={
              truncateProps.ellipsis ?? (
                <>
                  ...
                  <Button
                    buttonType="link"
                    className="mx-0"
                    onClick={async (): Promise<void> => {
                      if (onClick) await onClick();
                    }}
                  >
                    More
                  </Button>
                </>
              )
            }
          >
            <div
              className={classNames(
                'font-normal text-headraceBlack-800 text-sm',
                contentClassName
              )}
            >
              {content}
            </div>
          </TruncateMarkup>
        ) : (
          <div
            className={classNames(
              'font-normal text-headraceBlack-800 text-sm',
              contentClassName
            )}
          >
            {content}
          </div>
        )}
        <FactsPanel
          className={facts.factsClassName}
          items={facts.factItems}
          contentClassName={facts.factsContentClassName}
        />
      </div>
    </Card>
  );
};

export default SummaryCard;
