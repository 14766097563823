import type { CompanyPress } from '@headrace/types';
import { isValidHttpUrl } from '@headrace/utils';
import { TrashIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';

import Button from '../../Button';
import { Input } from '../../forms';

interface PressItemProps {
  item: CompanyPress;
  editMode?: { onRemove: () => void; index: number };
}

const PressItem: VFC<PressItemProps> = (props) => {
  const { item, editMode } = props;
  return (
    <div
      className={classNames('px-[24px] py-4', {
        'h-[72px] flex items-center': !editMode,
      })}
    >
      {editMode ? (
        <div className="flex gap-4">
          <div className="flex flex-col gap-4 flex-auto">
            <Input
              name={`pressList[${editMode.index}].title`}
              label="Article title"
              errorClassName="!text-left"
            />
            <Input
              name={`pressList[${editMode.index}].url`}
              label="Article URL"
              errorClassName="!text-left"
            />
          </div>
          <Button
            buttonType="link"
            className="!m-0"
            onClick={editMode.onRemove}
          >
            <TrashIcon className="w-[24px]" />
          </Button>
        </div>
      ) : (
        <a
          href={isValidHttpUrl(item.url) ? item.url : `https://${item.url}`}
          target="_blank"
          rel="noreferrer"
          className="decoration-none font-medium text-sm text-blue-500 line-clamp-2"
        >
          {item.title}
        </a>
      )}
    </div>
  );
};

export default PressItem;
