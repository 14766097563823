const urlGenerator = (
  subdomainPrefix: 'recruit' | 'employ',
  path: string
): string => {
  const env = process.env.NEXT_PUBLIC_HEADRACE_ENV;
  const protocol = env === 'development' ? 'http' : 'https';

  let domainPort;

  if (env === 'development') {
    const port = subdomainPrefix === 'recruit' ? '3000' : '3005';
    domainPort = `localhost:${port}`;
  } else {
    const subdomain = subdomainPrefix + (env === 'staging' ? '-staging' : '');
    domainPort = `${subdomain}.headrace.com`;
  }

  return `${protocol}://${domainPort}/${path}`;
};

export default urlGenerator;
