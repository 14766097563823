import classNames from 'classnames';
import type { FC } from 'react';

interface Props {
  children: React.ReactNode;
  yellowStripe: boolean;
  pageWithCustomMargin: boolean;
  withHidableSidebar?: boolean;
}

const TailwindContent: FC<Props> = ({
  children,
  yellowStripe,
  pageWithCustomMargin,
  withHidableSidebar = true,
}) => {
  const className = classNames({
    relative: yellowStripe,
  });

  return (
    <main className={className}>
      {yellowStripe && (
        <div className=" bg-headraceYellow-700 h-52 absolute top-0 bottom-0 w-full z-0" />
      )}
      <div
        className={classNames('relative sm:px-0 ', {
          'py-10 sm:mx-6 px-6 hs:max-w-[1240px] hs:mx-auto':
            !pageWithCustomMargin,
          'xl:mx-[60px]': !pageWithCustomMargin && withHidableSidebar,
          'xl:mx-[40px]': !pageWithCustomMargin && !withHidableSidebar,
        })}
      >
        {children}
      </div>
    </main>
  );
};

export default TailwindContent;
