import he from 'he';
import type { VFC } from 'react';
import sanitizeHtml from 'sanitize-html';

interface DecodeHtmlTextProps {
  text: string;
}

const DecodeHtmlText: VFC<DecodeHtmlTextProps> = ({ text }) => {
  // Sanitize HTML entities
  const sanitizedHTML = sanitizeHtml(text, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'br'],
    allowedAttributes: {
      a: ['href'],
    },
  });
  // Decode HTML entities
  const decodedHTML = he.decode(sanitizedHTML);

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: decodedHTML }} />;
};

export default DecodeHtmlText;
