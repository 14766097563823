import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React, { useEffect } from 'react';

interface DisableManagerProps {
  isEditable: boolean;
  children: JSX.Element;
  setScrollable?: React.Dispatch<React.SetStateAction<boolean>>;
  isLongText?: (boolean: boolean) => void;
  maxTextLength?: number;
  maxHtmlElementHeight?: number;
}

const DisableManager: React.VFC<DisableManagerProps> = (props) => {
  const {
    isEditable,
    children,
    setScrollable,
    isLongText,
    maxTextLength,
    maxHtmlElementHeight = 160,
  } = props;
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    editor.setEditable(isEditable);
    if (setScrollable) {
      const htmlElementHeight = editor.getRootElement()?.offsetHeight;
      if (htmlElementHeight && htmlElementHeight > maxHtmlElementHeight) {
        setScrollable(true);
        if (isLongText && !maxTextLength) {
          isLongText(true);
        }
      } else {
        setScrollable(false);
        if (isLongText && !maxTextLength) {
          isLongText(false);
        }
      }
    }
  }, [
    editor,
    isEditable,
    isLongText,
    maxHtmlElementHeight,
    maxTextLength,
    setScrollable,
  ]);

  useEffect(() => {
    let isListening = false;
    editor.registerTextContentListener((textContent) => {
      if (maxTextLength) isListening = true;
      if (maxTextLength && textContent.length > maxTextLength) {
        if (isLongText) {
          isLongText(true);
        }
      } else if (isLongText) {
        isLongText(false);
      }
    });
    if (isListening) {
      return;
    }

    const htmlElementHeight = editor.getRootElement()?.offsetHeight;
    if (htmlElementHeight && htmlElementHeight > maxHtmlElementHeight) {
      if (isLongText) {
        isLongText(true);
      }
    } else if (isLongText) {
      isLongText(false);
    }
  }, [editor, isLongText, maxHtmlElementHeight, maxTextLength]);

  return <div>{children}</div>;
};

export default DisableManager;
