import {
  CandidatePlacementOptions,
  SearchAgreementFeeTypes,
} from '@headrace/constants';
import type { FeeScheduleTypeEnum } from '@headrace/types';
import {
  CandidatePlacementEnum,
  FeeTriggerTypeEnum,
  FeeTypeEnum,
} from '@headrace/types';
import { numberFormatter } from '@headrace/utils';
import type { VFC } from 'react';

import getFeeType from '../../utils/getFeeType';
import CandidatePlacementFee from './CandidatePlacementFee';
import CustomFee from './CustomFee';
import EquityPlacementFee from './EquityPlacementFee';
import InitialFee from './InitialFee';
import PerformanceFee from './PerformanceFee';
import RecurringMonthlyFee from './RecurringMonthlyFee';

interface FeeFactoryProps {
  amount: number;
  feeTriggerType: FeeTriggerTypeEnum;
  feeScheduleType: FeeScheduleTypeEnum;
  feeTriggerLimit?: number | null;
  paymentTerm: number;
  performanceCandidateAcceptQuantity?: number | null;
  substractRetainerFees: boolean;
  oneTimeFeeTriggerAt?: Date | null;
  oneTimeFeeRelativeSigningDays?: number | null;
}

const FeeFactory: VFC<FeeFactoryProps> = ({
  amount,
  feeTriggerType,
  feeScheduleType,
  feeTriggerLimit,
  paymentTerm,
  performanceCandidateAcceptQuantity,
  substractRetainerFees,
  oneTimeFeeTriggerAt,
  oneTimeFeeRelativeSigningDays,
}) => {
  const formattedAmount = numberFormatter().format(amount);
  const trigger =
    feeTriggerType === FeeTriggerTypeEnum.CANDIDATE_START_DATE
      ? CandidatePlacementOptions[CandidatePlacementEnum.START_DATE]
      : CandidatePlacementOptions[CandidatePlacementEnum.SIGNING];

  const feeType = getFeeType(feeTriggerType, feeScheduleType);

  const fee: {
    [key in FeeTypeEnum]: React.ReactNode;
  } = {
    [FeeTypeEnum.INITIAL_FEE]: <InitialFee amount={formattedAmount} />,
    [FeeTypeEnum.PERFORMANCE_FEE]: (
      <PerformanceFee
        amount={formattedAmount}
        performanceCandidateAcceptQuantity={
          performanceCandidateAcceptQuantity || 0
        }
      />
    ),
    [FeeTypeEnum.RECURRING_MONTHLY_FEE]: (
      <RecurringMonthlyFee
        amount={formattedAmount}
        feeTriggerLimit={feeTriggerLimit || 0}
      />
    ),
    [FeeTypeEnum.CANDIDATE_PLACEMENT_FEE]: (
      <CandidatePlacementFee
        amount={formattedAmount}
        feeScheduleType={feeScheduleType}
        substractRetainerFees={substractRetainerFees}
        trigger={trigger}
      />
    ),
    [FeeTypeEnum.EQUITY_PLACEMENT_FEE]: (
      <EquityPlacementFee
        amount={formattedAmount}
        feeScheduleType={feeScheduleType}
        trigger={trigger}
      />
    ),
    [FeeTypeEnum.CUSTOM]: (
      <CustomFee
        amount={formattedAmount}
        feeTriggerType={feeTriggerType}
        oneTimeFeeTriggerAt={oneTimeFeeTriggerAt}
        oneTimeFeeRelativeSigningDays={oneTimeFeeRelativeSigningDays}
      />
    ),
  };

  return (
    <>
      <div>
        <span>{SearchAgreementFeeTypes[feeType]}</span>
      </div>
      {fee[feeType]}
      <div>
        <span>
          {paymentTerm > 0 ? `Net ${paymentTerm}` : 'Due immediately'}
        </span>
      </div>
    </>
  );
};

export default FeeFactory;
