import { FolderAddIcon } from '@heroicons/react/outline';

interface Props {
  emptyTitle: string;
  emptySubtitle?: string;
  colSpan: number;
  className?: string;
}

const TableEmptyState: React.VFC<Props> = ({
  colSpan,
  emptyTitle,
  emptySubtitle,
  className,
}) => (
  <tbody className={className}>
    <tr className="border-t">
      <td
        colSpan={colSpan}
        className="py-4 px-6 text-sm font-medium text-center whitespace-nowrap"
      >
        <div className="text-center items-center text-gray-500">
          <FolderAddIcon className="mx-auto h-12 w-12 stroke-1" />
          <h3 className="mt-1">{emptyTitle}</h3>
          {emptySubtitle && <p className="text-gray-400">{emptySubtitle}</p>}
        </div>
      </td>
    </tr>
  </tbody>
);

export default TableEmptyState;
