import Image from 'next/image';
import type { VFC } from 'react';

import MiniDivider from '../MiniDivider';

interface Props {
  userImageSrc?: string | null;
  userName: string;
  userSubtitle?: string | null;
  label?: string;
}

const UserCard: VFC<Props> = ({
  userImageSrc,
  userName,
  userSubtitle,
  label,
}) => (
  <div className="flex flex-col sm:flex-row items-center p-5">
    <div className="flex items-center">
      {userImageSrc ? (
        <Image
          src={userImageSrc}
          width={80}
          height={80}
          objectFit="cover"
          className="rounded-full"
        />
      ) : (
        <svg
          className="h-20 w-20 text-gray-300 bg-gray-100 rounded-full"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      )}
    </div>
    <div className="flex flex-col justify-center items-start gap-1 px-6 text-center sm:text-left font-medium text-sm text-headraceBlack">
      <div className="text-gray-600 mb-1">Welcome,</div>

      <span className="text-headraceBlack-800 font-bold text-2xl">
        {userName}
      </span>
      <MiniDivider />
      {userSubtitle}
    </div>
    {label && (
      <p className="pt-4 sm:p-0 sm:ml-auto sm:self-start font-normal text-sm text-gray-400">
        {label}
      </p>
    )}
  </div>
);

export default UserCard;
