import type { CompanyTeamMember } from '@headrace/types';
import { isValidHttpUrl } from '@headrace/utils';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Link from 'next/link';
import type { VFC } from 'react';

import Button from '../../Button';
import { LinkedinIcon } from '../../CustomIcons';

interface TeamMemberCardProps {
  className?: string;
  teamMember: CompanyTeamMember;
  onEdit?: () => void;
  onRemove?: () => void;
}

const TeamMemberCard: VFC<TeamMemberCardProps> = (props) => {
  const { className, teamMember, onEdit, onRemove } = props;
  return (
    <div
      className={classNames(
        'border border-gray-300 bg-white rounded-[4px] p-[20px] flex flex-col justify-between gap-4 items-center',
        { 'h-[126px]': !onEdit, 'h-[166px]': Boolean(onEdit) },
        className
      )}
      title={`${teamMember.name} - ${teamMember.title}`}
    >
      <p className="inline-flex flex-col font-normal text-base text-black text-center gap-1 max-w-full">
        <span className="leading-[18px] truncate">{teamMember.name}</span>
        <span className="font-normal text-sm text-gray-500 leading-4 line-clamp-2 h-[32px]">
          {teamMember.title}
        </span>
      </p>
      <Link
        href={
          isValidHttpUrl(teamMember.linkedin)
            ? teamMember.linkedin
            : `https://${teamMember.linkedin}`
        }
        passHref
      >
        <a className="inline-flex w-4" target="_blank" rel="noreferrer">
          <LinkedinIcon
            className="flex-shrink-0 mr-2 w-full"
            aria-hidden="true"
          />
        </a>
      </Link>
      {onEdit && (
        <div className="flex gap-4">
          <Button buttonType="link" className="!m-0" onClick={onEdit}>
            <PencilIcon className="w-5 text-blue-500" />
          </Button>
          <Button buttonType="link" className="!m-0" onClick={onRemove}>
            <TrashIcon className="w-5 text-blue-500" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default TeamMemberCard;
