import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Image from 'next/image';
import type { LinkProps } from 'next/link';
import Link from 'next/link';
import type { ReactElement, VFC } from 'react';
import { Fragment } from 'react';

import { AvatarPlaceholderIcon } from '../CustomIcons';

interface BaseNavigationItem {
  name: string;
}

interface LinkNavigationItem extends BaseNavigationItem {
  href: string;
}

interface ButtonNavigationItem extends BaseNavigationItem {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export type UserNavigationItem = LinkNavigationItem | ButtonNavigationItem;

const NextLink = (props: React.PropsWithChildren<LinkProps>): ReactElement => {
  const { href, passHref, children, ...rest } = props;
  return (
    <Link href={href} passHref={passHref}>
      {/* Link the Next doesn't map all the props to its children https://headlessui.dev/react/menu#integrating-with-next-js */}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <a {...rest}>{children}</a>
    </Link>
  );
};

interface ProfileMenuProps {
  userNavigation: UserNavigationItem[];
  profileImg?: string | null;
  positionClassName?: string;
  withLabel?: boolean;
}

const ProfileMenu: VFC<ProfileMenuProps> = (props) => {
  const { userNavigation, profileImg, positionClassName, withLabel } = props;
  return (
    <Menu
      as="div"
      className="flex-shrink-0 flex pb-6 ml-4 sm:ml-0 justify-start"
    >
      {({ open }): JSX.Element => (
        <>
          <Menu.Button
            className={classNames(
              'max-w-xs flex items-center text-sm rounded-full  outline-none ',
              {
                'ring-headraceYellow-500 ring-offset-2': open,
                'ring-white ring-offset-1': !open && !withLabel,
                'bg-white ring-2 ring-inset-2': !withLabel,
              }
            )}
          >
            <span className="sr-only">Open user menu</span>
            {profileImg ? (
              <Image
                className="block mx-auto h-8 w-8 rounded-2xl"
                src={profileImg}
                alt=""
                width={32}
                height={32}
              />
            ) : (
              <AvatarPlaceholderIcon className="w-8 h-8" />
            )}
            {withLabel && (
              <span className="hidden sm:block ml-2 text-sm font-medium text-white">
                Profile
              </span>
            )}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={classNames(
                'absolute  bottom-6 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-100',
                { 'left-16 sm:left-20': !positionClassName },
                positionClassName
              )}
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }): JSX.Element =>
                    'href' in item ? (
                      <NextLink href={item.href} passHref>
                        <span
                          className={classNames(
                            { 'bg-gray-100': active },
                            'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                          )}
                        >
                          {item.name}
                        </span>
                      </NextLink>
                    ) : (
                      <button
                        type="button"
                        onClick={item.onClick}
                        className={classNames(
                          { 'bg-gray-100': active },
                          'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-left w-full'
                        )}
                      >
                        {item.name}
                      </button>
                    )
                  }
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default ProfileMenu;
