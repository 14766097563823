import type { VFC } from 'react';

interface InitialFeeProps {
  amount: string;
}

const InitialFee: VFC<InitialFeeProps> = ({ amount }) => (
  <>
    <div>
      <span>${amount}</span>
    </div>
    <div>
      <span>On signing</span>
    </div>
  </>
);

export default InitialFee;
