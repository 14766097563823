import './Tooltip.css';

import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

const QuestionMarkIcon: React.VFC<{
  className?: string;
  error?: boolean | string;
  fieldName: string;
  tooltip: string;
  customTooltipStyles?: string;
  showErrorIcon?: boolean;
  forInput?: boolean;
  iconClassName?: string;
}> = ({
  className,
  error,
  fieldName,
  tooltip,
  customTooltipStyles,
  showErrorIcon = true,
  forInput = true,
  iconClassName,
}) => (
  <>
    <span
      className={classNames(
        {
          'mr-8': error && showErrorIcon,
          'absolute inset-y-0 right-0 flex items-center mr-3': forInput,
        },
        className
      )}
      data-for={`${fieldName}-questionMark`}
      data-tip={tooltip}
      data-class={customTooltipStyles || 'my-tooltip'}
    >
      <QuestionMarkCircleIcon
        className={classNames(
          'h-5 w-5 text-gray-400',
          {
            'text-red-500': error && !showErrorIcon,
          },
          iconClassName
        )}
        aria-hidden="true"
      />
    </span>
    <ReactTooltip
      id={`${fieldName}-questionMark`}
      place="top"
      effect="solid"
      multiline
      arrowColor="#30323D"
      offset={{ top: forInput ? -15 : -5 }}
    />
  </>
);

export default QuestionMarkIcon;
