import { CandidateDocumentTypeOptions } from '@headrace/constants';
import { CandidateDocumentTypeEnum } from '@headrace/types';
import { formatDate } from '@headrace/utils';
import { DownloadIcon, TrashIcon } from '@heroicons/react/outline';
import type { FormikProps } from 'formik';
import { Formik, useFormikContext } from 'formik';
import type { RefObject } from 'react';
import { useEffect, useState } from 'react';

import type { OptionsProps } from '../..';
import Button from '../../Button';
import EmptyState from '../../EmptyState';
import Select from '../../forms/fields/formik/Select';
import Table from '../../Table';
import DragDropFile from '../../UploadFiles';
import FormSchema from './FormSchema';

const documentTypeOptions: OptionsProps[] = [
  {
    label: CandidateDocumentTypeOptions[CandidateDocumentTypeEnum.RESUME],
    value: CandidateDocumentTypeEnum.RESUME,
  },
  {
    label: CandidateDocumentTypeOptions[CandidateDocumentTypeEnum.OTHER],
    value: CandidateDocumentTypeEnum.OTHER,
  },
];

export interface Document {
  id: string;
  name: string;
  documentType?: CandidateDocumentTypeEnum;
  documentPath?: string | null;
  createdAt: Date;
  file?: File;
  disabled: boolean;
}

interface DocumentsTableProps {
  documentTypeSelect: (file: Document) => JSX.Element;
  downloadAs: (path: string, name: string) => Promise<void>;
  fileNameFormatter: (fileName: string, path: string) => React.ReactNode;
  files: Document[];
  handleDelete?: (documentId: string) => void;
  inEditMode: boolean;
}

const DocumentsTable: React.VFC<DocumentsTableProps> = ({
  documentTypeSelect,
  downloadAs,
  fileNameFormatter,
  files,
  handleDelete,
  inEditMode,
}) => {
  const { getFieldProps, setFieldValue } = useFormikContext();
  useEffect(() => {
    files.forEach((_, index) => {
      setFieldValue(
        `files[${index}].documentType`,
        CandidateDocumentTypeEnum.OTHER
      );
    });
  }, [files, setFieldValue]);

  const deleteButton = (
    id: string,
    path: string,
    fileName: string,
    documentType: CandidateDocumentTypeEnum
  ): React.ReactNode => {
    if (!inEditMode) {
      return (
        <div className="text-left float-right">
          <Button
            onClick={(): Promise<void> => downloadAs(path, fileName)}
            className="font-medium text-blue-500 !m-0"
            buttonType="link"
          >
            <DownloadIcon className="w-5 h-5 stroke-blue-400 hover:stroke-blue-300" />
          </Button>
        </div>
      );
    }
    return (
      <div className="text-left float-right">
        {documentType !== CandidateDocumentTypeEnum.OFFER_LETTER && (
          <Button
            onClick={(): void => {
              if (handleDelete) {
                const docs = getFieldProps<Document[]>('files');
                setFieldValue('files', [
                  docs.value.filter((doc) => doc.id !== id),
                ]);
                handleDelete(id);
              }
            }}
            buttonType="link"
          >
            <TrashIcon className="w-5 h-5 stroke-blue-400 hover:stroke-blue-300" />
          </Button>
        )}
      </div>
    );
  };

  return (
    <Table
      data={files}
      emptyTitle="No documents uploaded"
      columns={[
        {
          header: 'File Name',
          formatter: (e) => fileNameFormatter(e.name, e.documentPath ?? ''),
        },
        {
          header: 'Document Type',
          formatter: documentTypeSelect,
        },
        {
          header: 'Upload Date',
          formatter: (e) => formatDate(e.createdAt, 'MMM d, yyyy'),
        },
        {
          header: '',
          formatter: (e) =>
            deleteButton(
              e.id,
              e.documentPath ?? '',
              e.name,
              e.documentType ?? CandidateDocumentTypeEnum.OTHER
            ),
        },
      ]}
    />
  );
};

interface FormProps {
  documents: Document[];
  formikRef?: RefObject<FormikProps<{ files: Document[] }>>;
  handleDelete?: (documentId: string) => void;
  handleDragDropFileButtonClick?: () => void;
  handleFileLinkClick?: () => void;
  hideUploadWidget?: boolean;
  inEditMode: boolean;
  onFileChange?: (doc: Document) => void;
  submitDocument?: (documents: Document[]) => Promise<void>;
}

const Form: React.VFC<FormProps> = ({
  documents,
  formikRef,
  handleDelete,
  handleDragDropFileButtonClick,
  handleFileLinkClick,
  hideUploadWidget,
  inEditMode,
  onFileChange,
  submitDocument,
}) => {
  const [files, setFiles] = useState<Array<Document>>([]);

  useEffect(() => {
    const enabledDocs = documents.filter((doc) => !doc.disabled);
    setFiles(enabledDocs);
  }, [documents]);

  const documentTypeSelect = (file: Document): JSX.Element => {
    if (file.disabled)
      return (
        <div>
          {
            CandidateDocumentTypeOptions[
              file.documentType || CandidateDocumentTypeEnum.OTHER
            ]
          }
        </div>
      );
    const index = files.findIndex((f) => f.id === file.id);
    return (
      <Select
        name={`files[${index}].documentType`}
        options={documentTypeOptions}
      />
    );
  };

  const downloadAs = async (path: string, name: string): Promise<void> => {
    await fetch(path, {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  const fileNameFormatter = (
    fileName: string,
    path: string
  ): React.ReactNode => (
    <Button
      className="font-medium text-blue-500 !m-0"
      onClick={async (): Promise<void> => {
        if (handleFileLinkClick) handleFileLinkClick();
        await downloadAs(path, fileName);
      }}
      buttonType="link"
    >
      {fileName}
    </Button>
  );

  const handleSubmit = async (values: { files: Document[] }): Promise<void> => {
    if (!submitDocument) return;
    const docs = files.map((file, idx) => ({
      ...file,
      documentType: values.files[idx].documentType,
    }));
    await submitDocument(docs);
  };

  return (
    <Formik
      initialValues={{
        files: [],
      }}
      onSubmit={handleSubmit}
      validationSchema={FormSchema}
      innerRef={formikRef}
    >
      <>
        {documents.length > 0 ? (
          <DocumentsTable
            documentTypeSelect={documentTypeSelect}
            downloadAs={downloadAs}
            fileNameFormatter={fileNameFormatter}
            files={documents}
            handleDelete={handleDelete}
            inEditMode={inEditMode}
          />
        ) : (
          !inEditMode && <EmptyState emptyTitle="No documents uploaded" />
        )}
        {inEditMode && !hideUploadWidget && (
          <div
            className={`sm:px-1 px-2 ${
              documents.length > 0 || hideUploadWidget ? 'pt-6' : ''
            }`}
          >
            <DragDropFile
              onFileAdded={(e: File): void => {
                if (onFileChange) {
                  const document = {
                    name: e.name,
                    documentType: CandidateDocumentTypeEnum.OTHER,
                    documentPath: URL.createObjectURL(e),
                    createdAt: new Date(),
                    id: e.name,
                    file: e,
                    disabled: false,
                  };

                  onFileChange(document);
                }
              }}
              handleClick={handleDragDropFileButtonClick}
            />
          </div>
        )}
      </>
    </Formik>
  );
};

export default Form;
