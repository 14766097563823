import * as Yup from 'yup';

const LocationDetailsFormSchema = Yup.object().shape({
  remoteWorkPolicy: Yup.string().required('This field is required'),
  locationIds: Yup.array()
    .min(1, 'This field is required')
    .max(3, 'You can select up to 3 locations'),
});

export default LocationDetailsFormSchema;
