/* eslint-disable no-nested-ternary */
import { CheckIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';

export enum ProgressStepState {
  NOT_STARTED = 'NOT_STARTED',
  CURRENT = 'CURRENT',
  COMPLETED = 'COMPLETED',
}

interface StepProps {
  status: ProgressStepState;
  number?: number;
  label: string;
  stepsLength: number;
  index: number;
  description?: string;
}

const Step: VFC<StepProps> = ({
  status,
  number,
  label,
  stepsLength,
  index,
  description,
}) => (
  <li
    key={label}
    className={classNames(index !== stepsLength - 1 ? 'pb-10' : '', 'relative')}
  >
    {status === ProgressStepState.COMPLETED ? (
      <>
        {index !== stepsLength - 1 ? (
          <div
            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-headraceYellow-700"
            aria-hidden="true"
          />
        ) : null}
        <div className="group relative flex items-center">
          <span className="flex h-9 items-center">
            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-headraceYellow-700 group-hover:bg-headraceYellow-800">
              <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </span>
          </span>
          <span className="ml-4 flex min-w-0 flex-col items-center">
            <span className="text-sm font-medium text-headraceBlack-700">
              {label}
            </span>
            {description && (
              <span className="text-sm text-gray-500 w-full">
                {description}
              </span>
            )}
          </span>
        </div>
      </>
    ) : status === ProgressStepState.CURRENT ? (
      <>
        {index !== stepsLength - 1 ? (
          <div
            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
            aria-hidden="true"
          />
        ) : null}
        <div className="group relative flex items-center" aria-current="step">
          <span className="flex h-9 items-center" aria-hidden="true">
            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-headraceYellow-600 bg-white text-headraceYellow-600">
              {number || (
                <span className="h-2.5 w-2.5 rounded-full bg-headraceYellow-700" />
              )}
            </span>
          </span>
          <span className="ml-4 flex min-w-0 flex-col items-center">
            <span className="text-sm font-medium text-headraceBlack-700">
              {label}
            </span>
            {description && (
              <span className="text-sm text-gray-500 w-full">
                {description}
              </span>
            )}
          </span>
        </div>
      </>
    ) : (
      <>
        {index !== stepsLength - 1 ? (
          <div
            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
            aria-hidden="true"
          />
        ) : null}
        <div className="group relative flex items-center">
          <span className="flex h-9 items-center" aria-hidden="true">
            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400 text-gray-500">
              {number || (
                <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
              )}
            </span>
          </span>
          <span className="ml-4 flex min-w-0 flex-col items-center">
            <span className="text-sm font-medium text-gray-500">{label}</span>
            {description && (
              <span className="text-sm text-gray-500 w-full">
                {description}
              </span>
            )}
          </span>
        </div>
      </>
    )}
  </li>
);

export default Step;
