import { SearchIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';

interface Props {
  value?: string;
  onChange: (value: string) => void;
  onSubmit?: () => void;
  placeholder?: string;
  className?: string;
  classNameInput?: string;
}

const SearchBar: VFC<Props> = ({
  value,
  onChange,
  onSubmit,
  placeholder = 'Search',
  className,
  classNameInput,
}) => {
  const classNameSearch = classNames(
    'flex flex-row items-center h-full outline outline-1 outline-gray-300 rounded-md px-2 py-2 bg-white',
    className
  );
  const Icon = <SearchIcon className="w-4 h-4 stroke-gray-400 stroke-[3px]" />;
  return (
    <div className={classNameSearch}>
      {onSubmit ? (
        <button
          type="submit"
          onClick={onSubmit}
          className="h-full p-2 rounded-lg focus:outline-headraceYellow-500 active:ring-2 active:ring-headraceYellow-500"
        >
          {Icon}
        </button>
      ) : (
        Icon
      )}
      <input
        type="text"
        className={classNames(
          'w-full h-9 border-0 text-sm focus:ring-0',
          classNameInput
        )}
        placeholder={placeholder}
        value={value}
        onChange={(e): void => onChange(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
