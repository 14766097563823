import { numberFormatter } from '@headrace/utils';
import classNames from 'classnames';
import type { ReactElement } from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';

export interface Props {
  value: number;
  title: string;
  maxValue: number;
  className?: string;
}

const CircularChart = ({
  value,
  title,
  maxValue,
  className,
}: Props): ReactElement => {
  const percentage = (value * 100) / maxValue;
  return (
    <div
      className={classNames('h-full flex flex-col justify-araound', className)}
    >
      <div className="lg:px-0">
        <CircularProgressbarWithChildren
          value={percentage}
          strokeWidth={8}
          styles={buildStyles({
            strokeLinecap: 'round',
            trailColor: '#F3F4F6',
            pathColor: '#ffa300',
          })}
          className="w-full align-middle inline-block"
        >
          <div className="text-center">
            <h2 className="text-[32px] font-medium">
              {numberFormatter({
                compactDisplay: 'short',
                notation: 'compact',
              }).format(value)}
            </h2>
          </div>
        </CircularProgressbarWithChildren>
      </div>
      <div className="font-bold text-base text-center text-headraceBlack-800 leading-[18px]">
        {title}
      </div>
    </div>
  );
};

export default CircularChart;
