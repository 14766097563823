import { LINKED_IN_URL } from '@headrace/constants';
import type {
  CompanyTeamCardData,
  CompanyTeamMember,
  CompanyTeamMemberFormValues,
} from '@headrace/types';
import { PlusIcon } from '@heroicons/react/outline';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import Image from 'next/image';
import type { VFC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';

import Button from '../../Button';
import DnDGrid from '../../DnDGrid';
import EditCard from '../../EditCard';
import EmptyStateIllustration from '../../EmptyStateIllustration';
import { Input } from '../../forms';
import LoadingSpinner from '../../LoadingSpinner';
import Modal from '../../Modal';
import useWindowSize from '../../utils/useWindowSize';
import TeamMemberCard from './TeamMemberCard';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  title: Yup.string().required('Title is required'),
  linkedin: Yup.string()
    .required('LinkedIn URL is required')
    .matches(LINKED_IN_URL, 'Invalid LinkedIn URL'),
});

interface CompanyTeamProps extends CompanyTeamCardData {
  editMode?: boolean;
  refetchLoading?: boolean;
}

const CompanyTeamCard: VFC<CompanyTeamProps> = (props) => {
  const {
    members,
    cancelAction,
    editMode,
    loading,
    saveAction,
    refetchLoading,
  } = props;

  const [width] = useWindowSize();
  const formRef = useRef<FormikProps<CompanyTeamMemberFormValues>>(null);
  const [activeEditMode, setActiveEditMode] = useState(false);
  const [newMembers, setNewMembers] = useState<CompanyTeamMember[]>([]);
  const [initialMembers, setInitialMembers] =
    useState<CompanyTeamMember[]>(members);
  const [removedMembersId, setRemovedMembersId] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [fullMembersList, setFullMembersList] = useState<CompanyTeamMember[]>([
    ...initialMembers,
    ...newMembers,
  ]);
  const [editingMember, setEditingMember] = useState<CompanyTeamMember | null>(
    null
  );

  // update after save
  useEffect(() => {
    setInitialMembers(members);
    setFullMembersList(members);
  }, [members]);

  // when adding, removing or editing a member
  useEffect(() => {
    setFullMembersList((prev) => {
      let newFullList = [...prev];
      const lastAdded = newMembers.filter(
        (newMember) =>
          !prev.some((prevMember) => prevMember.id === newMember.id) &&
          !removedMembersId.some((id) => id === newMember.id)
      );
      if (lastAdded)
        newFullList = [
          ...newFullList.map((member) => {
            const newMemberMatch = newMembers.find(
              (newMember) => newMember.id === member.id
            );
            if (newMemberMatch) return newMemberMatch;
            const initialMemberMatch = initialMembers.find(
              (initialMember) => initialMember.id === member.id
            );
            if (initialMemberMatch) return initialMemberMatch;
            return member;
          }),
          ...lastAdded,
        ];
      return newFullList.filter(
        (member) => !removedMembersId.some((id) => id === member.id)
      );
    });
  }, [removedMembersId, newMembers, initialMembers]);

  const closeEditMode = useCallback((): void => {
    setActiveEditMode(false);
    setNewMembers([]);
    setRemovedMembersId([]);
    setInitialMembers(members);
    setFullMembersList(members);
  }, [members]);

  const closeModal = (): void => {
    setEditingMember(null);
    setModalOpen(false);
  };

  const viewComponent = useMemo(
    () =>
      fullMembersList.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          {fullMembersList.map((member, index) => (
            <TeamMemberCard
              key={`${member.id}_${index + 1}`}
              teamMember={member}
            />
          ))}
        </div>
      ) : (
        <EmptyStateIllustration
          button={{
            label: 'Add Team',
            onClick: (): void => setActiveEditMode(true),
          }}
          title="Tell recruiters about your team"
          description="The leadership team is a core selling point, so list a few of the team members you’d like to highlight. This is global across all roles."
          image={
            <div className="max-w-[240px] mx-auto">
              <Image
                src="/illustrations/emptyStateDossier/company-team-empty.svg"
                alt="Company team illustration"
                layout="responsive"
                width={240}
                height={236.5}
                objectFit="contain"
              />
            </div>
          }
          showSeparator={false}
          contentClassName="!gap-2"
          titleClassName="!text-sm text-headraceBlack-700 font-medium"
          descriptionClassName="text-gray-500 font-normal text-sm mb-2"
          className="!py-0"
        />
      ),
    [fullMembersList]
  );

  const formateMemberCard = useCallback(
    (member: CompanyTeamMember): JSX.Element => (
      <TeamMemberCard
        key={member.id}
        teamMember={member}
        onEdit={(): void => {
          setEditingMember(member);
          setModalOpen(true);
        }}
        onRemove={(): void => {
          setRemovedMembersId([...removedMembersId, member.id]);
        }}
      />
    ),
    [removedMembersId]
  );

  const editComponent = useMemo(
    () => (
      <>
        {fullMembersList.length > 0 && (
          <DnDGrid<CompanyTeamMember>
            items={fullMembersList}
            formatItem={formateMemberCard}
            rows={
              width < 640
                ? fullMembersList.length
                : Math.ceil(fullMembersList.length / 3)
            }
            columns={width < 640 ? 1 : 3}
            rowHeigth={166}
            onOrderChange={(newOrder): void => {
              setFullMembersList(newOrder);
            }}
          />
        )}
        <div
          role="presentation"
          className="p-4 flex flex-col justify-center items-center border-2 border-dashed rounded-md cursor-pointer h-[100px]"
          onClick={(): void => {
            setModalOpen(true);
          }}
        >
          <div className="flex items-center gap-2">
            <PlusIcon className="w-6 h-6 text-gray-400" />
            <span className="whitespace-nowrap font-medium text-sm text-headraceBlack-700">
              Add team member
            </span>
          </div>
          <p className="text-gray-500 text-sm">
            Add the name, title, and LinkedIn URL
          </p>
        </div>
      </>
    ),
    [formateMemberCard, fullMembersList, width]
  );

  const handleModalSubmit = useCallback(
    (values: CompanyTeamMemberFormValues): void => {
      // add a new member
      if (!editingMember) {
        setNewMembers([
          ...newMembers,
          { ...values, id: String(newMembers.length - 1) },
        ]);
      }
      // editing a new member
      else if (newMembers.some((member) => member.id === editingMember.id)) {
        setNewMembers((prev) => {
          const newArr = [...prev];
          newArr.splice(newArr.indexOf(editingMember), 1, {
            ...editingMember,
            ...values,
          });
          return newArr;
        });
      }
      // editing an existing member
      else if (
        initialMembers.some((member) => member.id === editingMember.id)
      ) {
        setInitialMembers((prev) => {
          const newArr = [...prev];
          newArr.splice(newArr.indexOf(editingMember), 1, {
            ...editingMember,
            ...values,
          });
          return newArr;
        });
      }
      closeModal();
    },
    [editingMember, initialMembers, newMembers]
  );

  return (
    <>
      <EditCard
        saveAction={(): void => {
          if (saveAction)
            saveAction(
              fullMembersList.map((member) => ({
                name: member.name,
                linkedin: member.linkedin,
                title: member.title,
              }))
            );
          closeEditMode();
        }}
        cancelAction={(): void => {
          closeEditMode();
          if (cancelAction) cancelAction();
        }}
        viewStateComponent={loading ? <LoadingSpinner /> : viewComponent}
        editStateComponent={loading ? <LoadingSpinner /> : editComponent}
        title="Our world class team"
        loading={loading}
        editMode={editMode}
        activeEditMode={activeEditMode}
        refetchLoading={refetchLoading}
      />
      <Modal
        open={modalOpen}
        onClose={closeModal}
        title="Team member"
        alignHeader="left"
        className="!max-w-[400px] !self-center"
        showXicon={false}
      >
        <Formik<CompanyTeamMemberFormValues>
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            linkedin: editingMember?.linkedin ?? '',
            name: editingMember?.name ?? '',
            title: editingMember?.title ?? '',
          }}
          onSubmit={handleModalSubmit}
          validationSchema={ValidationSchema}
        >
          {({ submitForm }): JSX.Element => (
            <Form className="-mt-2 flex flex-col gap-2">
              <Input name="name" label="Name" errorClassName="!text-left" />
              <Input name="title" label="Title" errorClassName="!text-left" />
              <Input
                name="linkedin"
                label="LinkedIn URL"
                helper="http://"
                errorClassName="!text-left"
              />
              <div className="flex gap-[12px] mt-2">
                <Button
                  buttonType="secondary"
                  className="flex-grow"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  buttonType="primary"
                  className="flex-grow"
                  onClick={async (): Promise<void> => {
                    await submitForm();
                    closeModal();
                  }}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default CompanyTeamCard;
