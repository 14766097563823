import classNames from 'classnames';
import type { KeyboardEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import type {
  InputAttributes,
  NumberFormatPropsBase,
} from 'react-number-format';
import NumberFormat from 'react-number-format';
import PhoneInput from 'react-phone-input-2';

import Badge from '../../Badge';
import Button from '../../Button';
import type { OptionsProps } from './BasicSelect';
import ErrorText from './ErrorText';
import MultiSelect from './MultiSelect';

export enum ModularFieldTypes {
  INLINE,
  NUMBER,
  TEXT_AREA,
  MULTIPLE_SELECT,
  PHONE_NUMBER,
}
interface ModularInputProps {
  id: string;
  label: string;
  value?: string | number;
  formattedValue?: string;
  saveChange?: (
    key: string,
    value: string | number | OptionsProps[]
  ) => Promise<void>;
  loading?: boolean;
  validate?: (value: string) => void;
  validateSelect?: (values: OptionsProps[]) => boolean;
  errors?: { [k: string]: string };
  fieldtype?: ModularFieldTypes;
  disabled?: boolean;
  maxLength?: number;
  helper?: string | JSX.Element;
  customActionBotton?: JSX.Element;
  selectOptions?: OptionsProps[];
  onChangeSelect?: (value: OptionsProps[]) => void;
  valueSelect?: OptionsProps[];
  initialValues?: OptionsProps[];
}

const classNamePhoneNumber = classNames(
  'w-full',
  'sm:text-sm',
  'border',
  'border-gray-300',
  'min-h-full'
);

const ModularInput: React.VFC<
  ModularInputProps & NumberFormatPropsBase<InputAttributes>
> = (props) => {
  const {
    id,
    label,
    value,
    validate,
    maxLength,
    errors = {},
    formattedValue,
    loading = false,
    saveChange,
    fieldtype = ModularFieldTypes.INLINE,
    disabled = false,
    helper,
    decimalScale,
    allowNegative,
    customActionBotton,
    selectOptions,
    onChangeSelect,
    valueSelect,
    initialValues,
    validateSelect,
  } = props;
  const [editIsOpen, setEditOpen] = useState(false);
  const [touched, setTouched] = useState(false);
  const inputElement = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  const validateOnChange = useCallback(
    (inputValue?: string): void => {
      if (validate && inputValue) {
        validate(inputValue);
        return;
      }
      if (validate && inputElement.current)
        validate(inputElement.current.value);
    },
    [validate]
  );
  const validateOnBlur = useCallback(
    (inputValue?: string): void => {
      if (validate && inputValue) {
        validate(inputValue);
        return;
      }
      if (validate && inputElement.current)
        validate(inputElement.current.value);
      setTouched(true);
    },
    [validate]
  );
  const openEdit = useCallback(() => {
    setEditOpen(true);
  }, []);
  const closeEdit = useCallback(() => {
    setEditOpen(false);
    setTouched(false);
  }, []);
  const submitChange = useCallback(async (): Promise<void> => {
    if (fieldtype === ModularFieldTypes.MULTIPLE_SELECT && saveChange) {
      const inputValue = valueSelect || [];
      await saveChange(id, inputValue);
    }
    if (inputElement.current && saveChange) {
      const inputValue =
        inputElement.current.inputMode === 'numeric'
          ? Number(inputElement.current.value.replace(/,/g, ''))
          : inputElement.current.value;
      await saveChange(id, inputValue);
    }
  }, [id, saveChange, fieldtype, valueSelect]);
  const submitOnEnter = useCallback(
    async (event: KeyboardEvent): Promise<void> => {
      if (event.key === 'Enter') {
        event.preventDefault();
        await submitChange();
      }
    },
    [submitChange]
  );
  const buttonElement: JSX.Element = useMemo(() => {
    if (editIsOpen) {
      return (
        <>
          <Button
            buttonType="link"
            onClick={submitChange}
            disabled={loading || (Boolean(errors[id]) && touched)}
          >
            {loading ? <LoaderIcon className="!w-6 !h-6" /> : 'Update'}
          </Button>
          <span className="text-gray-300" aria-hidden="true">
            |
          </span>
          <Button buttonType="link" onClick={closeEdit}>
            Cancel
          </Button>
        </>
      );
    }
    if (customActionBotton) {
      return customActionBotton;
    }

    return (
      <Button buttonType="link" onClick={openEdit}>
        Edit
      </Button>
    );
  }, [
    editIsOpen,
    openEdit,
    submitChange,
    loading,
    errors,
    id,
    touched,
    closeEdit,
    customActionBotton,
  ]);

  const renderInputElement = useCallback(() => {
    const className = classNames(
      'focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border border-gray-300',
      { 'rounded-md': !helper, 'rounded-r-md': !!helper }
    );

    switch (fieldtype) {
      case ModularFieldTypes.NUMBER:
        return (
          <NumberFormat
            name={id}
            id={id}
            autoComplete="off"
            className={className}
            onKeyPress={submitOnEnter}
            thousandSeparator
            defaultValue={value}
            getInputRef={inputElement}
            onValueChange={(values): void => {
              validateOnChange(values.value);
            }}
            onBlur={(): void => setTouched(true)}
            decimalScale={decimalScale}
            allowNegative={allowNegative}
          />
        );
      case ModularFieldTypes.TEXT_AREA:
        return (
          <textarea
            id={id}
            name={id}
            rows={3}
            ref={inputElement as React.RefObject<HTMLTextAreaElement>}
            className={className}
            maxLength={maxLength}
            defaultValue={value}
            onKeyPress={submitOnEnter}
            onChange={(): void => validateOnChange()}
            onBlur={(): void => validateOnBlur()}
          />
        );
      case ModularFieldTypes.MULTIPLE_SELECT:
        return (
          <MultiSelect
            name={id}
            selectOptions={selectOptions}
            onChangeSelect={onChangeSelect}
            value={valueSelect}
            validateSelect={validateSelect}
          />
        );
      case ModularFieldTypes.PHONE_NUMBER:
        return (
          <PhoneInput
            inputProps={{
              name: id,
              id,
              autoComplete: 'tel',
              ref: inputElement as React.RefObject<HTMLTextAreaElement>,
            }}
            onChange={(): void => validateOnChange()}
            onBlur={(): void => validateOnBlur()}
            onlyCountries={['us']}
            value={typeof value === 'number' ? value.toString() : value}
            country="us"
            enableAreaCodeStretch
            countryCodeEditable={false}
            inputStyle={{ width: '100%' }}
            inputClass={classNamePhoneNumber}
          />
        );
      default:
        return (
          <input
            id={id}
            ref={inputElement as React.RefObject<HTMLInputElement>}
            type="text"
            className={className}
            defaultValue={value}
            onKeyPress={submitOnEnter}
            onChange={(): void => validateOnChange()}
            onBlur={(): void => validateOnBlur()}
          />
        );
    }
  }, [
    helper,
    fieldtype,
    id,
    submitOnEnter,
    value,
    decimalScale,
    allowNegative,
    maxLength,
    validateOnChange,
    validateOnBlur,
    selectOptions,
    onChangeSelect,
    valueSelect,
    validateSelect,
  ]);

  useEffect(() => {
    if (!loading) closeEdit();
  }, [loading, closeEdit]);

  return (
    <div className="px-5 py-4 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>

      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2 items-center">
        {/* Input */}
        <span className="w-3/4">
          <div
            className={classNames('mt-1 flex rounded-md', {
              'items-center': !editIsOpen,
            })}
          >
            {editIsOpen ? (
              <>
                {helper && (
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    {helper}
                  </span>
                )}
                {renderInputElement()}
              </>
            ) : (
              <span className="pl-3 overflow-hidden text-ellipsis">
                {fieldtype === ModularFieldTypes.MULTIPLE_SELECT ? (
                  <div className="flex flex-wrap justify-start">
                    {initialValues?.map((item) => (
                      <Badge
                        key={item.value}
                        label={item.label}
                        className="mr-3 mb-3"
                      >
                        {item.label}
                      </Badge>
                    ))}
                  </div>
                ) : (
                  formattedValue ?? value
                )}
              </span>
            )}

            {/* Content height helper */}
            <input type="text" className="sm:text-sm w-0 px-0 invisible" />
          </div>
          {errors[id] && editIsOpen && (
            <ErrorText
              id={`${id}-error`}
              error={errors[id]}
              touched={touched}
            />
          )}
        </span>
        {/* Buttons */}
        {!disabled && (
          <span className="ml-auto flex-shrink-0">
            <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
              {buttonElement}
            </span>
          </span>
        )}
      </dd>
    </div>
  );
};

export default ModularInput;
