import type { ApolloError } from '@apollo/client';
import { CandidateRejectedReason } from '@headrace/constants';
import type { CandidateActivityHistoryRejectionMeta } from '@headrace/types';
import {
  CandidateActivityHistoryActivityType,
  CandidateRejectedReasonEnum,
} from '@headrace/types';
import { enumKeys, formatApolloError } from '@headrace/utils';
import { EmojiSadIcon } from '@heroicons/react/outline';
import { ChevronRightIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Link from 'next/link';
import type { ReactNode } from 'react';
import React, { useCallback } from 'react';

import CardWithHeader from './CardWithHeader';
import LoadingSpinner from './LoadingSpinner';
import SimpleEmpty from './SimpleEmpty';

export interface OverviewListItem {
  title: string | JSX.Element;
  description: string;
  activityType?: CandidateActivityHistoryActivityType;
  activityMeta?: CandidateActivityHistoryRejectionMeta | null;
  link?: string;
  tooltip?: string;
  icon?: JSX.Element;
  actionMenu?: JSX.Element;
  onClick?: () => void;
}

interface UserStatsProps {
  className?: string;
  title: string;
  actionElement?: ReactNode | JSX.Element;
  items: OverviewListItem[];
  itemIcon?: JSX.Element;
  emptyTitle: string;
  emptyDescription: string;
  loading?: boolean;
  error?: ApolloError;
  maxItems?: number | null;
  wrapText?: boolean;
}

const RejectReasonCategory: React.VFC<{
  category: CandidateRejectedReasonEnum | null | undefined;
  validated: boolean;
}> = ({ category, validated }) => {
  if (!validated || !category) return null;
  // This is needed to get the enum value, since CandidateRejectedReason is a map of
  // CandidateRejectedReasonEnum values as the key. category is just the enum key and
  // needs to do the following to get the enum value
  const value = enumKeys(CandidateRejectedReasonEnum).find(
    (key) => key === category
  );
  if (!value) return null;
  const parsedCategory = CandidateRejectedReasonEnum[value];
  if (!Object.keys(CandidateRejectedReason).includes(parsedCategory))
    return null;
  return <> | {CandidateRejectedReason[parsedCategory].title}</>;
};

const RejectReasonDetails: React.VFC<{
  details: string | null | undefined;
  validated: boolean;
}> = ({ details, validated }) => {
  if (!validated || !details) return null;
  return (
    <p className="text-gray-600 max-w-[600px] whitespace-normal italic my-2">
      {details}
    </p>
  );
};

const OverviewList: React.VFC<UserStatsProps> = (props) => {
  const {
    className,
    title,
    actionElement,
    items,
    itemIcon,
    emptyTitle,
    emptyDescription,
    loading = false,
    error,
    maxItems = 5,
    wrapText = false,
  } = props;

  const renderItemContent = useCallback(
    (item: OverviewListItem): JSX.Element => {
      const itemContent = (
        <>
          {(item.icon || itemIcon) && (
            <div className="p-4">{item.icon ?? itemIcon}</div>
          )}
          <div
            className={classNames('my-4 ', {
              'whitespace-nowrap overflow-hidden text-ellipsis': !wrapText,
            })}
          >
            <span
              className={classNames({
                'text-headraceBlack-800 font-normal text-sm': !item.link,
                'text-blue-500 font-medium text-base': item.link,
              })}
            >
              {item.title}{' '}
              <RejectReasonCategory
                validated={
                  item.activityType ===
                  CandidateActivityHistoryActivityType.REJECTED
                }
                category={item.activityMeta?.rejectReasonCategory}
              />
            </span>
            <RejectReasonDetails
              validated={
                item.activityType ===
                CandidateActivityHistoryActivityType.REJECTED
              }
              details={item.activityMeta?.rejectReasonDetails}
            />
            {item.activityType !==
              CandidateActivityHistoryActivityType.REJECTED && <br />}
            <div className="text-gray-500 font-normal text-sm line-clamp-2">
              {item.description}
            </div>
          </div>
          {!item.actionMenu && item.link && (
            <div className="text-gray-400 p-6 ml-auto self-center">
              <ChevronRightIcon height={20} />
            </div>
          )}
        </>
      );
      return (
        <>
          {item.link ? (
            <Link href={item.link} passHref prefetch={false}>
              <a
                className={classNames(
                  'flex flex-auto items-center overflow-hidden',
                  {
                    'pl-6': !itemIcon,
                  }
                )}
              >
                {itemContent}
              </a>
            </Link>
          ) : (
            itemContent
          )}
          {item.actionMenu && (
            <div className="text-gray-400 p-4 self-center ml-auto bg-transparent">
              {item.actionMenu}
            </div>
          )}
        </>
      );
    },
    [itemIcon, wrapText]
  );

  const renderContent = (): JSX.Element => {
    if (loading)
      return (
        <div className="flex items-center justify-center h-full p-4">
          <LoadingSpinner />
        </div>
      );
    if (error)
      return (
        <div className="flex flex-col items-center justify-center h-full p-4 text-red-400 bg-red-50">
          <EmojiSadIcon className="w-12 h-12" />
          <p className="text-center">{formatApolloError(error)}</p>
        </div>
      );
    if (items.length) {
      return (
        <ul className="max-h-full overflow-auto">
          {items
            .slice(0, maxItems || items.length)
            .map((item, index, array) => (
              <li
                key={`${array.length - index}`}
                className={classNames(
                  'border flex border-gray-200 transition min-h-fit border-l-0 border-r-0 bg-white',
                  { 'hover:bg-blue-100': item.link }
                )}
                title={item.tooltip}
              >
                {renderItemContent(item)}
              </li>
            ))}
        </ul>
      );
    }
    return (
      <SimpleEmpty
        title={emptyTitle}
        content={emptyDescription}
        className="flex-1 p-4 lg:mt-[35%]"
      />
    );
  };

  return (
    <CardWithHeader
      title={title}
      headerClassName="bg-white"
      rightContent={actionElement}
      className={classNames(
        className,
        'flex flex-col bg-white shadow rounded-lg overflow-hidden'
      )}
      removeChildrenXPadding
      removeChildrenYPadding
      removeBorder={!!items.length}
    >
      {renderContent()}
    </CardWithHeader>
  );
};

export default OverviewList;
