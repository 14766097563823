// Slider.js

import 'react-input-range/lib/css/index.css';
import './Slider.css';

import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import InputRange from 'react-input-range';

import ErrorObjectText from '../../ErrorObjectText';
import type { Range } from './types';

interface SliderInputProps {
  name: string;
  min: number;
  max: number;
  step: number;
  label: string;
  disabled?: boolean;
  className?: string;
  formatLabel?: (value: number) => string;
}

const SliderInput: React.VFC<SliderInputProps> = (props) => {
  const {
    name,
    min,
    max,
    step,
    label,
    disabled,
    className,
    formatLabel = (val: number): string => {
      if (val) return val.toString();
      return '';
    },
  } = props;
  const [field, meta, helpers] = useField<Range>(name);
  const { setFieldValue } = useFormikContext();

  const DEFAULT_CLASS_NAMES = {
    activeTrack: 'input-range__track input-range__track--active',
    disabledInputRange: 'input-range input-range--disabled',
    inputRange: 'input-range',
    labelContainer: 'input-range__label-container',
    maxLabel: 'input-range__label input-range__label--max',
    minLabel: 'input-range__label input-range__label--min',
    slider: 'input-range__slider',
    sliderContainer: 'input-range__slider-container',
    track: 'input-range__track input-range__track--background',
    valueLabel: 'input-range__label input-range__label--value',
  };

  const onChange = (val: unknown): void => {
    setFieldValue(field.name, val);
  };

  useEffect(() => {
    if (field.value.max > max || field.value.min < min)
      helpers.setValue({
        min: field.value.min < min ? min : field.value.min,
        max: field.value.max > max ? max : field.value.max,
      });
  }, [field.value, helpers, max, min]);

  return (
    <div className={classNames('slider', className)}>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-900"
        >
          {label}
        </label>
      )}
      <div className="flex justify-between">
        <span className="mb-1 text-sm">{formatLabel(field.value.min)}</span>
        <span className="mb-1 text-sm">{formatLabel(field.value.max)}</span>
      </div>
      <InputRange
        classNames={{
          ...DEFAULT_CLASS_NAMES,
          labelContainer: 'hidden',
        }}
        minValue={min}
        maxValue={max}
        name={field.name}
        step={step}
        onChange={onChange}
        value={field.value}
        disabled={disabled}
        formatLabel={formatLabel}
        draggableTrack
      />
      <ErrorObjectText
        id={`${field.name}-error`}
        error={meta.error as unknown as { [key: string]: string }}
        touched={meta.touched}
      />
    </div>
  );
};

export default SliderInput;
