import type {
  CompanyOverviewCardData,
  CompanyOverviewFormValues,
} from '@headrace/types';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import type { VFC } from 'react';
import { useMemo, useRef, useState } from 'react';

import EditCard from '../EditCard';
import EmptyStateIllustration from '../EmptyStateIllustration';
import { RichTextEditor } from '../forms/fields/formik';
import OnlyReadRichTextEditor from '../forms/fields/formik/RichTextEditor/OnlyReadRichTextEditor';

export interface CompanyOverviewCardProps extends CompanyOverviewCardData {
  editMode?: boolean;
  refetchLoading?: boolean;
  companyDescription?: string | null;
}

const CompanyOverviewCard: VFC<CompanyOverviewCardProps> = ({
  saveAction,
  cancelAction,
  loading,
  overviewJSON,
  editMode,
  refetchLoading,
  companyDescription,
}) => {
  const formRef = useRef<FormikProps<CompanyOverviewFormValues>>(null);
  const [activeEditMode, setActiveEditMode] = useState(false);
  const viewComponent = useMemo(() => {
    if (overviewJSON)
      return (
        <div className="h-full min-h-[128px] flex flex-col rounded-md text-left gap-4">
          <OnlyReadRichTextEditor
            name="overviewJSON"
            json={overviewJSON}
            value=""
            limitHeight={false}
          />
        </div>
      );
    if (companyDescription) return companyDescription;

    return (
      <EmptyStateIllustration
        button={{
          label: 'Add company overview',
          onClick: (): void => setActiveEditMode(true),
        }}
        title="Add your company overview"
        description="Tell the world an overview about your company. This is global for all of your roles."
        image="/illustrations/emptyStateDossier/company-overview-empty.svg"
        showSeparator={false}
        descriptionClassName="w-full"
        className="!p-0"
        contentClassName="!gap-2"
        titleClassName="!text-sm"
      />
    );
  }, [companyDescription, overviewJSON]);

  const editComponent = useMemo(
    () => (
      <div className="h-full min-h-[128px] flex flex-col rounded-md text-left gap-4">
        <RichTextEditor
          name="overviewJSON"
          nameJSON="overviewJSON"
          inputClassName="mt-10 !h-full min-h-[128px]"
        />
      </div>
    ),
    []
  );

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        overviewJSON,
      }}
      onSubmit={(values): void => {
        if (saveAction) saveAction(values);
      }}
    >
      <Form>
        <EditCard
          saveAction={async (): Promise<void> => {
            await formRef.current?.submitForm();
          }}
          cancelAction={cancelAction}
          viewStateComponent={viewComponent}
          editStateComponent={editComponent}
          title="Company overview"
          loading={loading}
          editMode={editMode}
          activeEditMode={activeEditMode}
          refetchLoading={refetchLoading}
        />
      </Form>
    </Formik>
  );
};

export default CompanyOverviewCard;
