import 'yup-phone-lite';

import { COMPANY_LINKED_IN_URL } from '@headrace/constants';
import * as Yup from 'yup';

const EmployerFormSchema = Yup.object().shape({
  companyName: Yup.string().required('Company name is required'),
  companyDescription: Yup.string().required(
    'Please provide a short description of your company'
  ),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  tou: Yup.boolean().required(),
  linkedIn: Yup.string()
    .matches(COMPANY_LINKED_IN_URL, 'Invalid company LinkedIn URL')
    .required('LinkedIn URL is required'),
});

export default EmployerFormSchema;
