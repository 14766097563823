import router, { useRouter } from 'next/router';
import type { VFC } from 'react';
import { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';

import Button from '../Button';
import EllipsisText from '../EllipsisText';
import SearchBar from '../SearchBar';
import Table from '../Table';

interface User {
  id: string;
  name: string;
  agencyName?: string;
  employerName?: string;
  isUserConsented: boolean;
  isHeadRaceAdmin: boolean;
}

interface BecomeUserProps {
  users: User[];
  userType: 'RECRUITER' | 'EMPLOYEE';
}

const BecomeUser: VFC<BecomeUserProps> = ({ users, userType }) => {
  const { push, reload } = useRouter();

  const [, setCookie] = useCookies(['become-user-id']);

  const [filterText, setFilterText] = useState('');

  const submitButton = (user: User): React.ReactNode => (
    <div className="text-left float-right">
      <Button
        disabled={user.isHeadRaceAdmin ? false : !user.isUserConsented}
        onClick={async (): Promise<void> => {
          setCookie('become-user-id', user.id, { path: '/' });
          await push('/');
          reload();
        }}
      >
        Become User
        {!user.isHeadRaceAdmin && !user.isUserConsented && (
          <span>(User Not Consented)</span>
        )}
      </Button>
    </div>
  );

  const sortedUsers = users.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }

    if (a.name < b.name) {
      return -1;
    }

    return 0;
  });

  const filteredUsers = sortedUsers
    ? users.filter(
        (user) =>
          user.name.toLowerCase().startsWith(filterText.toLowerCase()) ||
          user.agencyName?.toLowerCase().startsWith(filterText.toLowerCase()) ||
          user.employerName?.toLowerCase().startsWith(filterText.toLowerCase())
      )
    : users;

  const renderAgency = useCallback(
    (user: User): JSX.Element => {
      if (userType === 'RECRUITER')
        return <EllipsisText text={user.agencyName || ''} />;
      return <EllipsisText text={user.employerName || ''} />;
    },
    [userType]
  );

  return (
    <>
      <SearchBar
        className="h-[38px] w-full inline-flex mb-4"
        onChange={(value): void => setFilterText(value)}
        placeholder={
          userType === 'RECRUITER' ? 'Search recruiters' : 'Search employees'
        }
      />
      <div className="w-[500px] md:w-full">
        <Table
          onClickRow={(id): void => {
            if (id) router.push(`/roles/${id}`).catch(null);
          }}
          data={filteredUsers}
          columns={[
            {
              header: 'Name',
              formatter: (user) => user.name,
              headerClassName: 'w-[30%]',
            },
            {
              header:
                userType === 'RECRUITER' ? 'Agency Name' : 'Employer Name',
              formatter: (user) => renderAgency(user),
              headerClassName: 'w-[28%]',
            },
            {
              header: '',
              formatter: (user) => submitButton(user),
              headerClassName: 'w-[32%]',
            },
          ]}
          emptyTitle="No users"
          tableFixedDesktop
          tableFixed
        />
      </div>
    </>
  );
};

export default BecomeUser;
