import classNames from 'classnames';
import type { FC } from 'react';

interface BadgeProps {
  label: string;
  color?: string;
  className?: string;
  labelClassName?: string;
}

const StatusBadge: FC<BadgeProps> = (props) => {
  const { label, color = '', className, labelClassName } = props;
  return (
    <span
      className={classNames(
        'inline-flex items-center px-2 py-0.5 text-xs font-medium bg-gray-white text-headraceBlack-800 border border-current border-solid rounded-full',
        className
      )}
    >
      <svg className="mr-1.5 h-2 w-2" fill={color} viewBox="0 0 8 8">
        <circle cx={4} cy={4} r={3} />
      </svg>
      <span className={classNames('text-center truncate', labelClassName)}>
        {label}
      </span>
    </span>
  );
};

export default StatusBadge;
