import type { VFC } from 'react';

const FeeNumber: VFC<{ index: number }> = ({ index }) => (
  <div className="rounded-l-md bg-headraceGreen-700 p-2 h-[58px] flex justify-center items-center">
    <div className="border-2 border-white rounded-full aspect-square flex justify-center items-center w-8">
      <p className="font-bold text-white">{index}</p>
    </div>
  </div>
);

export default FeeNumber;
