import classNames from 'classnames';

export interface FactsPanelProps {
  className?: string;
  items: Array<{ icon: JSX.Element; title: string; content: string }>;
  contentClassName?: string;
}

const FactsPanel: React.VFC<FactsPanelProps> = (props) => {
  const { className, items, contentClassName } = props;

  if (!items.length) return null;

  return (
    <div
      className={classNames(
        'p-6 flex justify-between border border-gray-200 rounded-lg shadow gap-2 flex-wrap',
        className
      )}
    >
      {items.map((item) => (
        <div key={item.content} className="flex gap-2 items-start truncate">
          <div>{item.icon}</div>
          <p className="font-medium text-sm text-gray-500 whitespace-normal md:whitespace-nowrap lg:whitespace-normal xl:whitespace-nowrap truncate">
            {item.title}
            <br />
            <span className={classNames(contentClassName, 'font-normal')}>
              {item.content}
            </span>
          </p>
        </div>
      ))}
    </div>
  );
};

export default FactsPanel;
