import classNames from 'classnames';
import type { VFC } from 'react';

interface Props {
  className?: string;
}

const CrunchbaseIcon: VFC<Props> = ({ className }) => (
  <span className={classNames('inline-block', className)}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-full h-full"
    >
      <path
        d="M21.6015 0H2.3985C1.0785 0.00375 0.00375 1.0785 0 2.3985V21.6015C0.00375 22.9215 1.0785 23.9963 2.3985 24H21.6015C22.9215 23.9963 23.9963 22.9215 24 21.6015V2.3985C23.9963 1.0785 22.9215 0.00375 21.6015 0V0Z"
        fill="white"
      />
      <path
        d="M21.6015 0H2.3985C1.0785 0.00375 0.00375 1.0785 0 2.3985V21.6015C0.00375 22.9215 1.0785 23.9963 2.3985 24H21.6015C22.9215 23.9963 23.9963 22.9215 24 21.6015V2.3985C23.9963 1.0785 22.9215 0.00375 21.6015 0V0ZM7.047 14.4645C8.10525 14.949 9.3555 14.4803 9.84 13.4213H11.5005C10.4655 17.6595 4.227 16.9095 4.227 12.546C4.227 8.17875 10.4655 7.42875 11.5005 11.6707H9.84C9.23025 10.3313 7.46925 10.0103 6.42975 11.0535C5.3865 12.0922 5.70675 13.854 7.047 14.4637V14.4645ZM18.3908 15.3082C18.0742 15.582 17.7188 15.7927 17.328 15.9412C16.2068 16.3748 14.9415 16.2345 13.941 15.5588V15.9412H12.429V5.37075H13.929V9.48C14.4645 9.117 15.0855 8.90175 15.7297 8.85075H15.999C19.413 8.84325 20.9835 13.0927 18.39 15.3075L18.3908 15.3082ZM18.168 12.543C18.1912 13.7228 17.2425 14.6955 16.0627 14.6955C14.883 14.6955 13.9335 13.7228 13.9613 12.543C14.0197 9.801 18.1058 9.801 18.168 12.543Z"
        fill="#0287D1"
      />
    </svg>
  </span>
);

export default CrunchbaseIcon;
