import { ExclamationCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

const InputErrorIcon: React.VFC<{ className?: string }> = ({ className }) => (
  <span
    className={classNames(
      'absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none',
      className
    )}
  >
    <ExclamationCircleIcon
      className="h-5 w-5 text-red-500"
      aria-hidden="true"
    />
  </span>
);

export default InputErrorIcon;
