const getScreenProperties = (): {
  viewport?: string;
  screenResolution?: string;
} => {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    const viewport = `${width}px x${height}px`;
    const screenResolution = `${window.screen.width}px x${window.screen.height}px`;
    return { viewport, screenResolution };
  }
  return { viewport: undefined, screenResolution: undefined };
};

export default getScreenProperties;
