import type {
  LatestFundingRoundTypeEnum,
  RoleOverviewFormValues,
} from '@headrace/types';
import {
  LatestFundingRoundTypeLabel,
  RemoteWorkPolicyEnumLabels,
} from '@headrace/types';
import {
  capitalizeString,
  cityLabel,
  currencyFormatter,
  isValidHttpUrl,
  remoteWorkPolicyLabel,
} from '@headrace/utils';
import {
  CashIcon,
  ChartPieIcon,
  ChartSquareBarIcon,
  CurrencyDollarIcon,
  LibraryIcon,
  LinkIcon,
  PencilIcon,
  RefreshIcon,
  TrendingUpIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import { differenceInHours, formatDistanceToNow } from 'date-fns';
import type { Dispatch, SetStateAction, VFC } from 'react';
import { useMemo } from 'react';

import { CrunchbaseIcon, LinkedinIcon } from '../../CustomIcons';
import EllipsisText from '../../EllipsisText';
import EmptyStateIllustration from '../../EmptyStateIllustration';
import OnlyReadRichTextEditor from '../../forms/fields/formik/RichTextEditor/OnlyReadRichTextEditor';
import SummaryCard from '../../SummaryCard';
import type { FactsPanelProps } from '../../SummaryCard/FactsPanel';
import useWindowSize from '../../utils/useWindowSize';

export interface RoleOverviewProps {
  data: RoleOverviewFormValues;
  editMode?: boolean;
  setEdit: Dispatch<SetStateAction<boolean>>;
  displayDate?: string | Date;
  isHeadRaceAI?: boolean;
}
const RoleOverview: VFC<RoleOverviewProps> = (props) => {
  const { data, editMode, setEdit, displayDate, isHeadRaceAI } = props;

  const [screenWidth] = useWindowSize();

  const geRightContent = (): JSX.Element | null => {
    if (editMode) {
      return (
        <button
          className="py-1"
          type="button"
          onClick={(): void => {
            setEdit(true);
          }}
        >
          <PencilIcon className="w-5 text-blue-500" />
        </button>
      );
    }
    if (displayDate) {
      const distance = differenceInHours(new Date(), new Date(displayDate));
      let textDate = '';
      if (distance > 24)
        textDate = `Posted ${formatDistanceToNow(new Date(displayDate), {
          addSuffix: true,
        })}
    `;
      return (
        <span className="text-base font-normal text-gray-400 truncate w-full">
          <EllipsisText
            className="text-right"
            text={textDate || 'Posted 1 day ago'}
          />
        </span>
      );
    }
    return null;
  };

  const factItems = useMemo(() => {
    const facts: FactsPanelProps['items'] = [];

    if (data.numberOfEmployees) {
      facts.push({
        icon: <UserGroupIcon className="w-6 text-headraceYellow-700" />,
        title: data.numberOfEmployees.toString(),
        content: 'Employees',
      });
    }
    if (data.companyStageName && !isHeadRaceAI) {
      facts.push({
        icon: <ChartSquareBarIcon className="w-6 text-headraceYellow-700" />,
        title: data.companyStageName,
        content: 'Company stage',
      });
    }

    if (data.revenue) {
      facts.push({
        icon: <CashIcon className="w-6 text-headraceYellow-700" />,
        title: currencyFormatter({
          compactDisplay: 'short',
          notation: 'compact',
        }).format(data.revenue),
        content: 'Revenue',
      });
    }

    if (data.totalFunding) {
      facts.push({
        icon: <CurrencyDollarIcon className="w-6 text-headraceYellow-700" />,
        title: currencyFormatter({
          compactDisplay: 'short',
          notation: 'compact',
        }).format(data.totalFunding),
        content: 'Total funding',
      });
    }

    if (data.yoyGrowth) {
      facts.push({
        icon: <TrendingUpIcon className="w-6 text-headraceYellow-700" />,
        title: `${data.yoyGrowth}%`,
        content: 'YoY growth',
      });
    }

    if (data.tam) {
      facts.push({
        icon: <ChartPieIcon className="w-6 text-headraceYellow-700" />,
        title: currencyFormatter({
          compactDisplay: 'short',
          notation: 'compact',
        }).format(data.tam),
        content: 'TAM',
      });
    }

    if (data.latestFundingRoundAmount && data.latestFundingRoundType) {
      const latestFundingRoundType = capitalizeString(
        LatestFundingRoundTypeLabel[
          data.latestFundingRoundType as LatestFundingRoundTypeEnum
        ]
          .replace(/_/g, ' ')
          .toLocaleLowerCase()
      );
      facts.push({
        icon: <RefreshIcon className="w-6 text-headraceYellow-700" />,
        title: `${currencyFormatter({
          compactDisplay: 'short',
          notation: 'compact',
        }).format(data.latestFundingRoundAmount)} ${latestFundingRoundType}`,
        content: 'Latest round',
      });
    }

    if (data.investors) {
      facts.push({
        icon: <LibraryIcon className="w-6 text-headraceYellow-700" />,
        title: data.investors,
        content: 'Lead investors',
      });
    }

    return facts;
  }, [
    data.companyStageName,
    data.investors,
    data.latestFundingRoundAmount,
    data.latestFundingRoundType,
    data.numberOfEmployees,
    data.revenue,
    data.tam,
    data.totalFunding,
    data.yoyGrowth,
    isHeadRaceAI,
  ]);

  const emptyState = useMemo(() => {
    if (
      editMode &&
      !data.companyIntro &&
      !data.numberOfEmployees &&
      !data.companyStageName &&
      !data.revenue &&
      !data.totalFunding &&
      !data.yoyGrowth &&
      !data.tam &&
      !data.latestFundingRoundAmount &&
      !data.totalFunding &&
      !data.investors
    )
      return (
        <EmptyStateIllustration
          button={{
            label: 'Add company details',
            onClick: (): void => setEdit(true),
          }}
          title="Add your global company information"
          description="These details inform recruiters and candidates on your company and go across all roles."
          image={null}
          showSeparator={false}
          descriptionClassName="w-full mb-2 !text-gray-500"
          className="!p-0"
          contentClassName="!gap-2"
          titleClassName="!text-sm"
          imageClassName="w-2/3 lg:w-2/3 xl:w-full"
        />
      );
    return null;
  }, [
    data.companyIntro,
    data.companyStageName,
    data.investors,
    data.latestFundingRoundAmount,
    data.numberOfEmployees,
    data.revenue,
    data.tam,
    data.totalFunding,
    data.yoyGrowth,
    editMode,
    setEdit,
  ]);

  const subtitleBadges = useMemo(() => {
    const badges = [];
    if (data.roleFunctionName) {
      badges.push(data.roleFunctionName);
    }
    if (data.remoteWorkPolicy) {
      const remoteWorkPolicyLabels = Object.entries(RemoteWorkPolicyEnumLabels);
      const remoteLabel = remoteWorkPolicyLabel(
        remoteWorkPolicyLabels,
        data.remoteWorkPolicy
      );
      if (remoteLabel) {
        badges.push(remoteLabel);
      }
    }

    return badges;
  }, [data.remoteWorkPolicy, data.roleFunctionName]);

  return (
    <SummaryCard
      className="h-fit"
      innerContainerClassName="p-6 !gap-[14px]"
      title={{
        titleContent: (
          <span className="text-2xl leading-6 text-headraceBlack-800 text-left w-full">
            {data.name}
          </span>
        ),
        titleContainerClassName: 'text-headraceBlack-800',
      }}
      subtitle={{
        subtitleContent: (
          <div className="font-normal text-lg text-gray-600 mb-1  md:line-clamp-2 !text-left inline-flex items-center gap-4">
            <span>
              {data.companyName}
              {' | '}
              {data.locations
                ? data.locations
                    .map((city) => cityLabel(city.cityName, city.stateAbbrev))
                    .join(' | ')
                : ''}
            </span>
            <span className="ml-4 align-middle">
              {data.linkedIn && (
                <a
                  href={
                    isValidHttpUrl(data.linkedIn)
                      ? data.linkedIn
                      : `https://${data.linkedIn}`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedinIcon className="w-5 h-5" />
                </a>
              )}
              {data.crunchbase && (
                <a
                  href={
                    isValidHttpUrl(data.crunchbase)
                      ? data.crunchbase
                      : `https://${data.crunchbase}`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <CrunchbaseIcon className="w-5 h-5 ml-2" />
                </a>
              )}
              {data.companyUrl && (
                <a
                  href={
                    isValidHttpUrl(data.companyUrl)
                      ? data.companyUrl
                      : `https://${data.companyUrl}`
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="inline-block"
                >
                  <LinkIcon className="w-5 ml-2 text-blue-500 inline-block -mt-2 stroke-2" />
                </a>
              )}
            </span>
          </div>
        ),
        subtitleBadges,
        subtitleDescription: data.companyTagline ?? '',
      }}
      rightContent={geRightContent()}
      headerClassName="justify-between"
      rightContentClassName={displayDate ? '' : 'w-[3%] !text-right'}
      headerContentClassName="w-[97%]"
      content={
        <OnlyReadRichTextEditor
          name="recruiterIntro"
          json={data.companyIntroJSON || null}
          value={data.companyIntro || null}
          limitHeight={false}
        />
      }
      contentClassName="!font-base"
      screenWidth={screenWidth}
      facts={{
        factItems,
        factsClassName: classNames('grid  !text-sm sm:gap-y-8 gap-y-4', {
          'sm:grid-cols-4 grid-cols-1':
            factItems.length === 4 || factItems.length > 6,
          'sm:grid-cols-3 grid-cols-1':
            factItems.length > 4 && factItems.length <= 6,
          '!flex justify-around sm:flex-row flex-col': factItems.length < 4,
        }),
        factsContentClassName: 'text-gray-400',
      }}
      image={{
        imageUrl: data.logoUrl,
      }}
      extraComponent={emptyState}
    />
  );
};

export default RoleOverview;
