import type { FC } from 'react';

interface Props {
  children: React.ReactNode;
}

const SimpleContent: FC<Props> = ({ children }) => (
  <main>
    <div className="sm:px-[60px] pt-10 px-6  pb-16  relative 2xl:max-w-7xl 2xl:mx-auto">
      {children}
    </div>
  </main>
);

export default SimpleContent;
