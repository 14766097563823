import { PencilAltIcon } from '@heroicons/react/outline';
import type { FormikProps } from 'formik';
import type { RefObject } from 'react';
import React, { useEffect, useState } from 'react';

import Button from '../../Button';
import CardWithHeader from '../../CardWithHeader';
import type { Document } from './Form';
import Form from './Form';

interface CardRightContentProps {
  formikRef?: RefObject<FormikProps<{ files: Document[] }>>;
  inEditMode: boolean;
  setInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  viewOnly: boolean;
}

const CardRightContent = (props: CardRightContentProps): JSX.Element => {
  const { formikRef, inEditMode, setInEditMode, viewOnly } = props;
  if (viewOnly) return <div />;
  if (inEditMode)
    return (
      <>
        <Button
          className="mr-4"
          buttonType="secondary"
          onClick={(): void => setInEditMode(false)}
        >
          Cancel
        </Button>
        <Button
          buttonType="primary"
          onClick={async (): Promise<void> => {
            setInEditMode(false);
            await formikRef?.current?.submitForm();
          }}
        >
          Save
        </Button>
      </>
    );
  return (
    <PencilAltIcon
      className="w-5 h-5 stroke-blue-500 cursor-pointer"
      onClick={(): void => setInEditMode(true)}
    />
  );
};

interface CandidateUploadDocumentsProps {
  documents: Document[];
  formikRef?: RefObject<FormikProps<{ files: Document[] }>>;
  onFileChange?: (doc: Document) => void;
  handleDelete?: (documentId: string) => void;
  hideUploadWidget?: boolean;
  handleFileLinkClick?: () => void;
  handleDragDropFileButtonClick?: () => void;
  submitDocument?: (documents: Document[]) => Promise<void>;
  viewOnly?: boolean;
  editMode?: boolean;
}

const CandidateUploadDocuments: React.VFC<CandidateUploadDocumentsProps> = ({
  documents,
  formikRef,
  onFileChange,
  handleDelete,
  handleFileLinkClick,
  handleDragDropFileButtonClick,
  hideUploadWidget = false,
  submitDocument,
  viewOnly = false,
  editMode = false,
}) => {
  const [inEditMode, setInEditMode] = useState(editMode);

  useEffect(() => {
    setInEditMode(editMode);
  }, [editMode]);

  return (
    <CardWithHeader
      title="Uploaded Documents"
      className="my-6"
      rightContent={
        <CardRightContent
          formikRef={formikRef}
          inEditMode={inEditMode}
          setInEditMode={setInEditMode}
          viewOnly={viewOnly}
        />
      }
    >
      <Form
        documents={documents}
        formikRef={formikRef}
        inEditMode={inEditMode}
        onFileChange={onFileChange}
        handleDelete={handleDelete}
        handleFileLinkClick={handleFileLinkClick}
        handleDragDropFileButtonClick={handleDragDropFileButtonClick}
        hideUploadWidget={hideUploadWidget}
        submitDocument={submitDocument}
      />
    </CardWithHeader>
  );
};

export default CandidateUploadDocuments;
