import {
  differenceInDays,
  formatDistanceToNow,
  formatDistanceToNowStrict,
} from 'date-fns';

interface Options {
  addSuffix?: boolean;
  includeHours?: boolean;
}

const formatTimeSince = (date: Date, options?: Options): string => {
  const currentDate = new Date();
  const timeSinceDays = differenceInDays(currentDate, date);

  if (timeSinceDays <= 0 && !options?.includeHours) {
    return 'Today';
  }

  if (options?.addSuffix) {
    return formatDistanceToNow(date, {
      addSuffix: true,
    });
  }

  return formatDistanceToNowStrict(date, {
    roundingMethod: 'ceil',
  });
};

export default formatTimeSince;
