import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import type { VFC } from 'react';

interface Props {
  label?: string;
  description?: string;
  checked: boolean;
  onChange: (check: React.SetStateAction<boolean>) => void;
  disabled?: boolean;
}

const SimpleToggle: VFC<Props> = ({
  onChange,
  checked,
  label,
  description,
  disabled,
}) => {
  const switchClass = classNames(
    {
      'bg-headraceYellow-600': checked,
      'bg-gray-200': !checked,
    },
    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-headraceYellow-500'
  );

  const spanClass = classNames(
    {
      'translate-x-5': checked,
      'translate-x-0': !checked,
    },
    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
  );

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={checked}
        onChange={onChange}
        className={switchClass}
        disabled={disabled}
      >
        <span aria-hidden="true" className={spanClass} />
      </Switch>
      {label && (
        <Switch.Label as="span" className="ml-3">
          <span className="text-sm font-medium text-gray-800">{label}</span>
          {description && (
            <span className="text-sm text-gray-500">{description}</span>
          )}
        </Switch.Label>
      )}
    </Switch.Group>
  );
};

export default SimpleToggle;
