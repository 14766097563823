import classNames from 'classnames';

import LoadingSpinner from '../LoadingSpinner';

interface ButtonProps {
  disabled?: boolean;
  loading?: boolean;
  className?: string;
}

const SubmitButton: React.FC<ButtonProps> = (props) => {
  const { loading, disabled, children, className } = props;

  const buttoncClassName = classNames(
    'inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium shadow-sm  active:outline-none sm:w-auto text-headraceBlack-700 bg-headraceYellow-700 hover:bg-headraceYellow-800  sm:h-auto outline-none',
    { 'opacity-25 cursor-not-allowed': disabled },
    className
  );

  return (
    <button
      disabled={disabled || loading}
      type="submit"
      className={buttoncClassName}
    >
      {loading && <LoadingSpinner className="w-6 h-6 text-white" />}
      {children}
    </button>
  );
};

export default SubmitButton;
