import type {
  NotificationStructure,
  UserNotificationEnum,
  UserNotificationSetting,
} from '@headrace/types';
import {
  NotificationStructureType,
  UserNotificationChannel,
} from '@headrace/types';
import { MailIcon } from '@heroicons/react/outline';
import type { VFC } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { SlackIcon } from '../../../CustomIcons';
import NotificationOption from './NotificationOption';

interface NotificationSettingsState {
  [key: string]: {
    [key: string]: boolean;
  };
}

interface SettingsNotificationFormProps {
  channels: UserNotificationChannel[];
  channelLegend: {
    [key: string]: {
      label: string;
      description: React.ReactNode;
      action?: React.ReactNode;
    };
  };
  notifications: string[];
  notificationsStructure: NotificationStructure[];
  notificationsData?: UserNotificationSetting[];
  onChangeNotification: (data: UserNotificationSetting[]) => Promise<void>;
  isLoading: boolean;
  isSlackConnected: boolean;
}

const SettingsNotificationForm: VFC<SettingsNotificationFormProps> = ({
  channels,
  channelLegend,
  notifications,
  notificationsStructure,
  notificationsData,
  onChangeNotification,
  isLoading,
  isSlackConnected,
}) => {
  const channelIcon = {
    [String(UserNotificationChannel.EMAIL)]: <MailIcon />,
    [String(UserNotificationChannel.SLACK)]: <SlackIcon />,
  };
  const initialNotificationsSettingsState = (): NotificationSettingsState => {
    const flags: NotificationSettingsState = {};
    channels.forEach((channel) => {
      flags[channel] = {};
      notifications.forEach((notif) => {
        if (channel === UserNotificationChannel.SLACK && !isSlackConnected) {
          flags[channel][notif] = false;
          return;
        }
        flags[channel][notif] = true;
      });
    });
    return flags;
  };

  const [settings, setSettings] = useState<NotificationSettingsState>(
    initialNotificationsSettingsState()
  );

  useEffect(() => {
    if (!notificationsData) return;
    const remoteSettings = notificationsData;
    setSettings((localSettings) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const newSettings: NotificationSettingsState = JSON.parse(
        JSON.stringify(localSettings)
      );
      remoteSettings.forEach((setting) => {
        newSettings[setting.channel][setting.type] = setting.enabled;
      });
      return newSettings;
    });
  }, [notificationsData]);

  const notificationIsActive = (channel: string, type: string): boolean =>
    settings[channel]?.[type] ?? false;

  // Event Handler
  const handleChangeUserNotificationSetting = useCallback(
    async (
      channel: UserNotificationChannel,
      type: UserNotificationEnum,
      checked: boolean
    ) => {
      if (isLoading) return;
      await onChangeNotification([
        {
          channel,
          type,
          enabled: checked,
        },
      ]);
    },
    [isLoading, onChangeNotification]
  );

  return (
    <div className="pt-4 shadow sm:rounded-md sm:overflow-hidden">
      <div className="px-8 py-5 bg-white space-y-6">
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-6">
            <div className="col-span-2" />
            <div className="col-span-4 grid grid-cols-2">
              {channels.map((channel) => (
                <div
                  key={channel}
                  className="col-span-1 flex flex-col justify-center"
                >
                  <div className="text-lg font-medium leading-6 text-gray-900 flex flex-row justify-center">
                    <div className="w-7 mr-4">{channelIcon[channel]}</div>
                    {channelLegend[channel].label}
                    {channelLegend[channel].action}
                  </div>
                  <div className="text-xs text-gray-400 text-center w-full">
                    {channelLegend[channel].description}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-20">
            {notificationsStructure.map((notificationStructure) => {
              if (
                notificationStructure.type === NotificationStructureType.GROUP
              ) {
                return (
                  <div className="flex flex-col">
                    <div className="grid grid-cols-6 border-b pb-2">
                      <div className="col-span-2 ">
                        <div className="text-lg font-medium leading-6 text-gray-900">
                          {notificationStructure.label}
                        </div>
                        <div className="mt-1 text-sm text-gray-600">
                          {notificationStructure.description}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col w-full">
                      <div className="flex flex-col gap-2">
                        {notificationStructure.children.map(
                          (childNotification) => {
                            if (
                              childNotification.type ===
                              NotificationStructureType.OPTION
                            ) {
                              return (
                                <div className="grid grid-cols-6 w-full">
                                  <div className="col-span-2 w-full flex items-center">
                                    <div className="">
                                      <div className="text-base font-medium text-gray-900">
                                        {childNotification.label}
                                      </div>
                                      <div className="mt-1 text-sm text-gray-600">
                                        {childNotification.description}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-span-4 grid grid-cols-2 w-full">
                                    {channels.map((channel) => (
                                      <div
                                        key={channel}
                                        className="col-span-1 flex justify-center"
                                      >
                                        <div className="">
                                          <NotificationOption
                                            key={childNotification.value}
                                            notification={childNotification}
                                            verifyChecked={(type): boolean =>
                                              notificationIsActive(
                                                channel,
                                                type
                                              )
                                            }
                                            onChange={(
                                              type: UserNotificationEnum,
                                              status: boolean
                                            ): Promise<void> =>
                                              handleChangeUserNotificationSetting(
                                                channel,
                                                type,
                                                status
                                              )
                                            }
                                            disabled={
                                              channel ===
                                                UserNotificationChannel.SLACK &&
                                              !isSlackConnected
                                            }
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              );
                            }
                            return null;
                          }
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsNotificationForm;
