const completeUrl = (url: string): string => {
  if (url === '') return '';
  let URL = url;
  if (!/^https?:\/\//i.test(url)) {
    URL = `https://${url}`;
  }
  return URL;
};

export default completeUrl;
