import classNames from 'classnames';
import Image from 'next/image';
import type { ReactElement } from 'react';
import { type VFC, useEffect, useMemo, useState } from 'react';

import Button from './Button';
import ErrorMessageBox from './ErrorMessageBox';
import type { OptionsProps } from './forms';
import { BasicSelect } from './forms';
import LoadingSpinner from './LoadingSpinner';

interface Collaborator {
  id: string;
  firstName: string;
  lastName: string;
  avatar?: string;
}

interface Props {
  collaborators: Collaborator[];
  allCollaborators: Collaborator[];
  error?: string | null;
  loading: boolean;
  onCollaboratorsSave: (collaboratorIds: string[]) => void;
  saving?: boolean;
  onRemoveCollaborator: (collaboratorId: string) => void;
  removing?: boolean;
  isHeadRaceAI?: boolean;
}
const CollaboratorsBar: VFC<Props> = ({
  collaborators,
  allCollaborators,
  error,
  loading,
  onCollaboratorsSave,
  saving,
  onRemoveCollaborator,
  removing,
  isHeadRaceAI = false,
}) => {
  const [changeToSelect, setChangeToSelect] = useState(false);
  const [selectedCollaborators, setSelectedCollaborators] = useState<
    OptionsProps[]
  >([]);

  const stringToHexColor = (name: string): string => {
    let hash = 0;
    for (let i = 0; i < name.length; i += 1) {
      // eslint-disable-next-line no-bitwise
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    // eslint-disable-next-line no-bitwise
    const c = (hash & 0x009966ff).toString(16).toUpperCase();
    return `#${'00000'.substring(0, 6 - c.length)}${c}`;
  };

  const avatarCollaborator = (
    firstName: string,
    lastName: string,
    toSelect?: boolean
  ): JSX.Element => (
    <div
      className={classNames(
        'w-8 h-8 rounded-full flex items-center justify-center text-white font-medium text-sm',
        {
          '!w-6 !h-6 !text-xs': toSelect,
        }
      )}
      style={{
        backgroundColor: stringToHexColor(`${firstName} ${lastName}`),
      }}
    >
      {firstName[0]}
      {lastName[0]}
    </div>
  );

  const listOptions = (options: OptionsProps[]): ReactElement => (
    <div className="flex flex-wrap gap-2 mt-1">
      {options.map((option) =>
        option.withAvatar ? (
          <div
            className="flex items-center bg-gray-100 rounded-l-full rounded-r-full"
            key={option.value}
          >
            <div>
              {option.withAvatar.avatar ? (
                <Image
                  className="inline-block h-6 w-6 rounded-full"
                  src={option.withAvatar.avatar}
                  alt=""
                  width={24}
                  height={24}
                />
              ) : (
                avatarCollaborator(
                  option.withAvatar.firstName,
                  option.withAvatar.lastName,
                  true
                )
              )}
            </div>
            <div className="pl-2">
              <p className="text-sm font-normal text-headraceBlack-700">
                {option.withAvatar.firstName} {option.withAvatar.lastName}
              </p>
            </div>
            <div className="pr-2 pl-2 flex items-center">
              <button
                type="button"
                className="flex items-center justify-center text-gray-500 font-medium text-sm hover:text-xs hover:bg-gray-500 hover:text-white rounded-full h-4 w-4"
                onClick={(e): void => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedCollaborators((prev) =>
                    prev.filter(
                      (collaborator) => collaborator.value !== option.value
                    )
                  );
                }}
              >
                x
              </button>
            </div>
          </div>
        ) : null
      )}
    </div>
  );

  const collaboratorsOptions = useMemo(
    () =>
      allCollaborators.map((collaborator) => ({
        label: `${collaborator.firstName} ${collaborator.lastName}`,
        value: collaborator.id,
        withAvatar: collaborator,
      })),
    [allCollaborators]
  );

  const saveCollaborators = (): void => {
    setChangeToSelect(false);
    onCollaboratorsSave(
      selectedCollaborators.map((collaborator) => collaborator.value)
    );
  };

  const firstCollaborators = useMemo(
    () =>
      collaborators.map((collaborator) => ({
        label: `${collaborator.firstName} ${collaborator.lastName}`,
        value: collaborator.id,
        withAvatar: collaborator,
      })),
    [collaborators]
  );

  useEffect(() => {
    setSelectedCollaborators(firstCollaborators);
  }, [firstCollaborators]);

  return (
    <div
      className={classNames(
        'fixed  left-0 right-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white px-6 py-4 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8',
        { 'md:left-[92px]': !isHeadRaceAI, 'md:left-[13rem]': isHeadRaceAI }
      )}
    >
      <div className="flex items-center gap-4">
        <span className="text-sm text-gray-500">Collaborators</span>
        {changeToSelect ? (
          <>
            <BasicSelect
              isMulti
              options={collaboratorsOptions}
              customMultiValueOptions={(options): ReactElement =>
                listOptions(options)
              }
              className="w-[500px]"
              isClearable={false}
              value={selectedCollaborators}
              onChange={(newVaue): void => {
                setSelectedCollaborators(newVaue as OptionsProps[]);
              }}
            />
            <div className="flex gap-2">
              <Button
                size="sm"
                onClick={saveCollaborators}
                loading={saving}
                disabled={selectedCollaborators.length === 0}
              >
                Save
              </Button>
              <Button
                size="sm"
                buttonType="secondary"
                onClick={(): void => {
                  setChangeToSelect(false);
                  setSelectedCollaborators(firstCollaborators);
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <ul className="flex flex-none items-center gap-x-2">
            {collaborators.map((collaborator) => (
              <li
                key={`${collaborator.firstName} ${collaborator.lastName}`}
                className="relative group cursor-pointer"
              >
                {collaborator.avatar ? (
                  <Image
                    src={collaborator.avatar}
                    alt={collaborator.firstName}
                    className="w-6 h-6 rounded-full relative"
                    width={24}
                    height={24}
                  />
                ) : (
                  avatarCollaborator(
                    collaborator.firstName,
                    collaborator.lastName
                  )
                )}
                <button
                  type="button"
                  className="hidden absolute right-0 top-0 h-4 w-4 -translate-y-1/3 translate-x-1/3 transform rounded-full bg-gray-600 ring-2 ring-white group-hover:flex items-center justify-center text-white text-xs"
                  onClick={(): void => {
                    onRemoveCollaborator(collaborator.id);
                  }}
                  disabled={removing}
                >
                  {removing ? <LoadingSpinner svgClassName="!w-3" /> : 'x'}
                </button>
              </li>
            ))}
            <li>
              <button
                type="button"
                className="w-8 h-8 rounded-full flex items-center justify-center text-gray-600 font-medium text-xl hover:bg-slate-100"
                onClick={(): void => setChangeToSelect(true)}
                disabled={loading || Boolean(error)}
              >
                {loading ? <LoadingSpinner svgClassName="!w-6" /> : '+'}
              </button>
              {error && <ErrorMessageBox error={error} />}
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default CollaboratorsBar;
