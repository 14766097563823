import UserCard from './UserCard';
import type { UserStatsItemProps } from './UserStatsItem';
import UserStatsItem from './UserStatsItem';

interface UserStatsProps {
  className?: string;
  userImageSrc?: string | null;
  userName: string;
  userSubtitle?: string | null;
  stats: UserStatsItemProps[];
  label?: string;
}

const UserStats: React.VFC<UserStatsProps> = (props) => {
  const {
    className = '',
    userImageSrc,
    userName,
    userSubtitle,
    stats,
    label,
  } = props;
  return (
    <div className={`bg-white shadow rounded-lg ${className}`}>
      <UserCard
        userImageSrc={userImageSrc}
        userName={userName}
        userSubtitle={userSubtitle}
        label={label}
      />
      {stats.length ? (
        <ul className="flex flex-wrap overflow-hidden text-headraceBlack rounded-b-lg items-center text-center">
          {stats.map((statistic: UserStatsItemProps, index, array) => (
            <UserStatsItem
              key={`${array.length - index}`}
              statTitle={statistic.statTitle}
              statLabel={statistic.statLabel}
              statValue={statistic.statValue}
              error={statistic.error}
              loading={statistic.loading}
              onClick={statistic.onClick}
              redirectTo={statistic.redirectTo}
            />
          ))}
        </ul>
      ) : (
        ''
      )}
    </div>
  );
};

export default UserStats;
