import classNames from 'classnames';
import type { FC } from 'react';

interface CardProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  titleSeparator?: boolean;
  subTitle?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  rightContent?: React.ReactNode;
  removeChildrenXPadding?: boolean;
  removeChildrenYPadding?: boolean;
  removeBorder?: boolean;
  headerClassName?: string;
  childrenClassName?: string;
}

const CardWithHeader: FC<CardProps> = ({
  title,
  description,
  titleSeparator,
  subTitle,
  children,
  className,
  rightContent,
  removeChildrenXPadding,
  removeChildrenYPadding,
  removeBorder,
  headerClassName,
  childrenClassName,
}) => {
  const cardClassName = classNames(
    'bg-white shadow rounded-lg divide-y divide-gray-200 text-headraceBlack-700',
    className
  );

  const childrenClass = classNames(
    'text-headraceBlack-800 text-sm',
    {
      'px-6': !removeChildrenXPadding,
      'py-6': !removeChildrenYPadding,
      'border-none': removeBorder,
    },
    childrenClassName
  );

  return (
    <div className={cardClassName}>
      <div
        className={classNames(
          'px-6 py-5 font-medium text-lg flex justify-between items-center flex-wrap sm:flex-nowrap text-headraceBlack-700 w-full',
          headerClassName
        )}
      >
        <div>
          <span>{title}</span>
          {description && (
            <div className="text-gray-500 text-sm">{description}</div>
          )}
          {subTitle && <span className="mx-2">{subTitle}</span>}
        </div>
        {titleSeparator && (
          <div className="flex-grow border-t border-gray-300 ml-3" />
        )}
        {rightContent && <div>{rightContent}</div>}
      </div>
      <div className={childrenClass}>{children}</div>
    </div>
  );
};

export default CardWithHeader;
