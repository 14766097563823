import { ChevronDownIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';

import LoadingSpinner from './LoadingSpinner';
import OverlayDialog from './OverlayDialog';

export interface OptionSelectMenu {
  label: string;
  action: (() => void) | string;
}

interface SelectMenuProps {
  options: OptionSelectMenu[];
  label: string;
  containerClassName?: string;
  panelClassName?: string;
  cardClassName?: string;
  loading?: boolean;
}

interface CardOptionsProps {
  closeMenu?: () => void;
  options?: OptionSelectMenu[];
  cardClassName?: string;
}

const CardOptions: VFC<CardOptionsProps> = ({
  options,
  closeMenu,
  cardClassName,
}) => {
  if (!options) return <span />;
  return (
    <div className={classNames('flex flex-col items-start ', cardClassName)}>
      <div className="w-full">
        <div className="max-h-40 overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-zinc-300 hover:scrollbar-thumb-zinc-300">
          {options.map((option) => {
            if (typeof option.action === 'string') {
              return (
                <a
                  href={option.action}
                  key={option.label}
                  className="w-full px-3 py-2 flex flex-row items-stretch hover:bg-headraceYellow-700"
                  onClick={(evt): void => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    if (closeMenu) closeMenu();
                  }}
                >
                  <span className="text-headraceBlack-800 text-left text-sm font-normal grow">
                    {option.label}
                  </span>
                </a>
              );
            }
            return (
              <button
                type="button"
                key={option.label}
                className="w-full px-3 py-2 flex flex-row items-stretch hover:bg-headraceYellow-700"
                onClick={(evt): void => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  if (typeof option.action === 'function') {
                    option.action();
                  }
                  if (closeMenu) closeMenu();
                }}
              >
                <span className="text-headraceBlack-800 text-left text-sm font-normal grow">
                  {option.label}
                </span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const SelectMenu: VFC<SelectMenuProps> = ({
  options,
  label,
  cardClassName,
  containerClassName,
  panelClassName,
  loading,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState<number>(0);

  useLayoutEffect(() => {
    setWidth(ref.current?.offsetWidth || 0);
  }, []);
  const selectContainer: VFC = () => (
    <div
      ref={ref}
      className={classNames(
        'flex cursor-pointer rounded-md border bg-headraceYellow-700 w-40 focus:outline-none text-sm font-medium text-headraceBlack-700 ',
        containerClassName
      )}
    >
      <span className="flex truncate  py-2 text-center items-center justify-center w-11/12">
        {loading && <LoadingSpinner svgClassName="text-white !w-4 !h-4" />}
        <span className="truncate">{label}</span>
      </span>
      <div className="pointer-events-none flex items-center justify-center text-headraceBlack-700  border-l border-gray-500 w-1/12">
        <ChevronDownIcon className="h-3 w-3" />
      </div>
    </div>
  );
  return (
    <OverlayDialog
      Icon={selectContainer}
      iconContainerClassName="!p-0 !w-full"
      iconClassName="!w-full"
      panelClassName={classNames('mt-1', panelClassName)}
      stylePanel={{ width }}
    >
      <CardOptions options={options} cardClassName={cardClassName} />
    </OverlayDialog>
  );
};

export default SelectMenu;
