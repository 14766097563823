import type {
  RoleRequirementFormValues,
  RoleRequirementValue,
} from '@headrace/types';
import { useFormikContext } from 'formik';
import type { VFC } from 'react';
import { v4 as uuidv4 } from 'uuid';

import RoleRequirementsCardForm from './RoleRequirementsCardForm';

interface RoleRequirementFormProps {
  originalRequirements: RoleRequirementValue[];
}

const RoleRequirementsForm: VFC<RoleRequirementFormProps> = ({
  originalRequirements,
}) => {
  const initialValues = (mustHave: boolean): RoleRequirementValue => ({
    id: uuidv4(),
    prompt: '',
    mustHave,
  });
  const { values } = useFormikContext<RoleRequirementFormValues>();
  return (
    <div className="flex flex-col gap-8">
      <RoleRequirementsCardForm
        name="mustToHaveRequirements"
        originalRequirements={originalRequirements}
        values={values.mustToHaveRequirements}
        deletedRequirements={values.deletedRequirements || []}
        initialValues={initialValues(true)}
        title="Must haves requirements"
      />
      <RoleRequirementsCardForm
        name="niceToHaveRequirements"
        originalRequirements={originalRequirements}
        values={values.niceToHaveRequirements}
        deletedRequirements={values.deletedRequirements || []}
        initialValues={initialValues(false)}
        title="Nice to have requirements"
      />
    </div>
  );
};

export default RoleRequirementsForm;
