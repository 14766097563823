import { Dialog, Transition } from '@headlessui/react';
import {
  EmployerSearchAgreementActivityCategory,
  RecruiterSearchAgreementActivityCategory,
} from '@headrace/types';
import { ExclamationIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { Fragment, useMemo, useState } from 'react';

import Button from '../Button';
import { Select, TextArea } from '../forms';

type FeedbackKeys =
  | keyof typeof EmployerSearchAgreementActivityCategory
  | keyof typeof RecruiterSearchAgreementActivityCategory;
type FeedbackValues =
  | EmployerSearchAgreementActivityCategory
  | RecruiterSearchAgreementActivityCategory;

interface FeedbackOptionsProps {
  label: FeedbackValues;
  value: FeedbackKeys;
}

export interface ModalPayload {
  selectedOptionValue: FeedbackKeys;
  textFeedback: string;
}
export interface ModalProps {
  open: boolean;
  onConfirm: (payload: ModalPayload) => void;
  onClose: () => void;
  title: string;
  subtitle?: string;
  feedbackOptions: FeedbackOptionsProps[];
  withDetails?: boolean;
}

const ConfirmModalWithFeedback: React.VFC<ModalProps> = ({
  open,
  onConfirm,
  onClose,
  title,
  subtitle = '',
  feedbackOptions = [],
  withDetails = true,
}) => {
  const [selectedOption, setSelectedOption] = useState<
    FeedbackOptionsProps | undefined
  >(feedbackOptions?.[0]);
  const [textFeedback, setTextFeedback] = useState<string>('');
  const isButtonDisabled = useMemo<boolean>(() => {
    if (
      (selectedOption?.label ===
        EmployerSearchAgreementActivityCategory.REASON_NOT_LISTED ||
        selectedOption?.label ===
          RecruiterSearchAgreementActivityCategory.REASON_NOT_LISTED) &&
      !textFeedback
    ) {
      return true;
    }
    return !selectedOption;
  }, [selectedOption, textFeedback]);

  const validateFunction = (payload: ModalPayload): void => {
    setTextFeedback(payload.textFeedback);
  };
  const handleOptionChange = (newValue: string): void => {
    const newOption = feedbackOptions.find(
      (option) => option.value === newValue
    );
    if (!newOption) return;
    setSelectedOption(newOption);
  };
  const onSubmit = (): void => {
    if (!selectedOption) return;
    const payload: ModalPayload = {
      selectedOptionValue: selectedOption.value,
      textFeedback,
    };
    // Validate
    onConfirm(payload);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={classNames('fixed z-30 inset-0 overflow-y-auto')}
        onClose={onClose}
      >
        <div className="flex items-center justify-center h-full min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="h-full flex items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full sm:max-w-lg">
                <div className="flex flex-row gap-4 p-6">
                  <div className="w-10 h-10 flex shrink-0 items-center justify-center rounded-full bg-red-100">
                    <ExclamationIcon
                      className="w-6 h-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="flex flex-col gap-4 grow text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <h3 className="text-sm text-gray-500">{subtitle}</h3>
                    <Formik
                      initialValues={{
                        selectedOptionValue: 'ROLE_FILLED_BY_OTHER_MEANS',
                        textFeedback: '',
                      }}
                      onSubmit={onSubmit}
                      validate={validateFunction}
                    >
                      <Form className="space-y-6">
                        <Select
                          name="selectedOptionValue"
                          label="Reason"
                          options={feedbackOptions}
                          placeholder="Select a Seniority"
                          onChange={handleOptionChange}
                          maxMenuHeight={180}
                        />
                        {withDetails && (
                          <TextArea label="Details" name="textFeedback" />
                        )}
                        <div className="flex flex-row gap-3 justify-end">
                          <Button buttonType="secondary" onClick={onClose}>
                            Cancel
                          </Button>
                          <Button
                            buttonType="primary"
                            type="submit"
                            disabled={isButtonDisabled}
                          >
                            Confirm
                          </Button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmModalWithFeedback;
