import { LINKED_IN_URL } from '@headrace/constants';
import { useFormikContext } from 'formik';
import Image from 'next/image';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';

import Button from '../../../Button';
import { AvatarPlaceholderIcon } from '../../../CustomIcons';
import type { OptionsProps } from '../../../forms';
import {
  CheckboxField,
  Input,
  MultipleInputRange,
  MultiSelectField,
  Select,
  SubmitButton,
} from '../../../forms';
import type { CreateMediaUploadLinkFields } from '../../../ImageUpload';
import ImageUpload from '../../../ImageUpload';

export const BasicFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  linkedIn: Yup.string()
    .matches(LINKED_IN_URL, 'Invalid LinkedIn URL')
    .required('LinkedIn URL is required'),
  roleFunctions: Yup.array()
    .required('Role functions is required')
    .max(3, 'You can only select 3 role functions'),
});

export interface BasicFormValues {
  photo: string;
  priceRange?: {
    max: number;
    min: number;
  };
  firstName: string;
  lastName: string;
  location: string | null;
  linkedIn: string;
  title: string;
  roleFunctions: string[];
  contingentSearch?: boolean;
  retainedSearch?: boolean;
}

export interface BasicDataProps {
  profileImg?: string;
  cityOptions: OptionsProps[];
  functionsOptions: OptionsProps[];
  createMediaUploadLink?: CreateMediaUploadLinkFields;
  setEdit: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
}

const BasicData: React.VFC<BasicDataProps> = (props) => {
  const {
    profileImg,
    cityOptions,
    functionsOptions,
    createMediaUploadLink,
    setEdit,
    loading,
  } = props;

  const { setFieldValue } = useFormikContext();
  const [imagenUp, setImagenUp] = useState('');

  return (
    <div className="bg-white shadow sm:rounded-lg border-b-[10px] border-headraceYellow-700 h-full">
      <div className="flex justify-between px-10 py-4 items-center border-b">
        <div className="text-xl font-medium text-headraceBlack-800 leading-6">
          Personal information
        </div>
        <div className="h-5 flex items-center gap-4">
          <Button
            buttonType="secondary"
            className="!py-2"
            onClick={(): void => setEdit(false)}
          >
            Cancel
          </Button>
          <SubmitButton className="!py-2" loading={loading}>
            Save
          </SubmitButton>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-7 gap-6 p-10  items-center justify-center">
        <div className="sm:col-span-2 flex flex-col justify-center items-center gap-4">
          {imagenUp || profileImg ? (
            <Image
              className="rounded-full w-46 h-46 bg-white"
              src={imagenUp || profileImg || ''}
              alt="user-profile-image"
              width={176}
              height={176}
            />
          ) : (
            <div className="relative w-46 h-46 sm:p-0 px-10">
              <AvatarPlaceholderIcon className="fill-gray-300 rounded-full" />
            </div>
          )}
          {createMediaUploadLink && (
            <ImageUpload
              getImageData={(image: string, key: string): void => {
                setImagenUp(image);
                setFieldValue('photo', key);
              }}
              cropShape="round"
              minZoom={1}
              createMediaUploadLink={createMediaUploadLink}
            />
          )}
        </div>
        <div className="sm:col-span-5 flex flex-col gap-y-2 text-headraceBlack-800 lg:justify-center lg:items-center sm:items-start">
          <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-4 gap-y-2">
            <div className="col-span-full">
              <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-4 gap-y-2">
                <div className="col-span-1">
                  <Input name="firstName" label="First Name" />
                </div>
                <div className="col-span-1">
                  <Input name="lastName" label="Last Name" />
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <Select
                name="location"
                label="Location"
                options={cityOptions}
                isSearchable
              />
            </div>
            <div className="col-span-1">
              <Input name="title" label="Title" />
            </div>
            <div className="col-span-1">
              <Input name="linkedIn" label="Linkedin" />
            </div>
            <div className="sm:col-span-full col-span-1">
              <MultiSelectField
                name="roleFunctions"
                label="Top Job Functions"
                labelHelper="Limit 3"
                options={functionsOptions}
                isSearchable
              />
            </div>
            <div className="sm:col-span-full col-span-1 flex flex-col gap-2">
              <div className="block text-sm font-medium text-headraceBlack-800">
                Search preference
              </div>
              <div className="flex gap-10">
                <CheckboxField
                  name="retainedSearch"
                  label="Retained"
                  checkboxClassName="white-checkbox"
                />
                <CheckboxField
                  name="contingentSearch"
                  label="Contingent"
                  checkboxClassName="white-checkbox"
                />
              </div>
            </div>
          </div>

          <MultipleInputRange
            min={0}
            max={50}
            name="priceRange"
            label="Placement fee range"
            className="w-full"
            step={1}
            formatLabel={(value): string => `${value}%`}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicData;
