import classNames from 'classnames';

import Button from '../Button';

export interface TabsProps {
  tabs: {
    id: string;
    name: string;
    onClick: (tabId: string) => void;
    current: boolean;
  }[];
  className?: string;
}

const Tabs: React.VFC<TabsProps> = ({ tabs, className }) => (
  <div className={classNames('border-b border-gray-200', className)}>
    <nav className="-mb-px flex" aria-label="Tabs">
      {tabs.map((tab, index) => (
        <Button
          key={tab.id}
          type="button"
          buttonType="link"
          className={classNames(
            'mx-4 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
            {
              'ml-0': index === 0,
              'border-headraceYellow-600 !text-headraceBlack-800 font-medium':
                tab.current,
              '!text-gray-500 border-transparent hover:border-gray-300':
                !tab.current,
            }
          )}
          onClick={(): void => tab.onClick(tab.id)}
        >
          {tab.name}
        </Button>
      ))}
    </nav>
  </div>
);

export default Tabs;
