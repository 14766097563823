import { type NavigationItem, SettingsLayout } from '@headrace/ui';
import type { FC } from 'react';

export const settingsNavigation: NavigationItem[] = [
  {
    name: 'General',
    href: '/settings',
    title: 'Settings',
    yellowStripe: true,
  },
  {
    name: 'Notifications',
    href: '/settings/notifications',
    yellowStripe: true,
  },
  {
    name: 'Payments',
    href: '/settings/payments/list',
    children: [
      '/settings/payments',
      '/settings/payments/list',
      '/settings/payments/add',
    ],
    yellowStripe: true,
  },
  {
    name: 'Team',
    href: '/settings/team',
    yellowStripe: true,
  },
];

const MainLayout: FC<{ className?: string }> = ({ children, className }) => (
  <SettingsLayout navigation={settingsNavigation} className={className}>
    {children}
  </SettingsLayout>
);

export default MainLayout;
