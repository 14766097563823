import classNames from 'classnames';
import type { FC } from 'react';

interface PillButtonProps {
  className?: string;
  onClick: () => void;
}

const PillButton: FC<PillButtonProps> = ({ className, children, onClick }) => (
  <button
    onClick={onClick}
    type="button"
    className={classNames(
      'relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3 shadow',
      className
    )}
  >
    {children}
  </button>
);

export default PillButton;
