/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-props-no-spreading */
import { CandidateActivityHistoryActivityType } from '@headrace/types';
import classNames from 'classnames';
import type { FC, ReactNode } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import DraggableCandidate from './draggableCandidate';
import type { CandidateData } from './types';

interface Props {
  droppableId: CandidateActivityHistoryActivityType;
  type: string;
  allowedTypes: CandidateActivityHistoryActivityType[];
  candidates: CandidateData[];
  getOptionsMenu: (
    candidateId: string,
    candidateStatus: string,
    _searchAgreementId?: string | null,
    marketplaceVisibility?: string,
    agency?: {
      id: string;
      name: string;
      recruiterId: string;
    }
  ) => ReactNode;
  currentDragging?: string;
  showSplitIcon?: boolean;
  employerSide?: boolean;
  handleCandidateDetails?: (candidateId: string) => void;
}

const DroppableCandidate: FC<Props> = ({
  droppableId,
  type,
  candidates,
  getOptionsMenu,
  currentDragging,
  allowedTypes,
  showSplitIcon = false,
  employerSide,
  handleCandidateDetails,
}) => (
  <Droppable
    droppableId={droppableId}
    type={type}
    isDropDisabled={
      !allowedTypes.includes(
        CandidateActivityHistoryActivityType[
          currentDragging as keyof typeof CandidateActivityHistoryActivityType
        ]
      )
    }
  >
    {(provided, snap) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
        className={classNames(
          snap.isDraggingOver ? 'bg-gray-400' : 'bg-gray-50',
          'h-[410px] overflow-y-auto rounded-lg gap-2'
        )}
      >
        {candidates.map((candidate, index) => (
          <DraggableCandidate
            key={candidate.id}
            index={index}
            candidate={candidate}
            getOptionsMenu={getOptionsMenu}
            showSplitSearchIcon={!!candidate?.collaboration && showSplitIcon}
            employerSide={employerSide}
            handleCandidateDetails={handleCandidateDetails}
          />
        ))}
      </div>
    )}
  </Droppable>
);

export default DroppableCandidate;
