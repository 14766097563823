import classNames from 'classnames';
import type { VFC } from 'react';

interface Props {
  className?: string;
}

const MiniDivider: VFC<Props> = ({ className }) => (
  <div className="relative flex items-center justify-items-center p-0 m-0">
    <div
      className={classNames(
        'flex bg-headraceYellow-700 w-[1.875rem] h-0.5',
        className
      )}
    />
  </div>
);

export default MiniDivider;
