import type {
  RoleRequirementCardData,
  RoleRequirementFormValues,
  RoleRequirementValue,
} from '@headrace/types';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import type { VFC } from 'react';
import { useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';

import CheckListItem from '../../CheckListItem';
import EditCard from '../../EditCard';
import EmptyStateIllustration from '../../EmptyStateIllustration';
import RoleRequirementsForm from './RoleRequirementsForm';

export interface RoleRequirementCardProps extends RoleRequirementCardData {
  editMode?: boolean;
  refetchLoading?: boolean;
}

const RoleRequirementsFormSchema = Yup.object().shape({
  mustToHaveRequirements: Yup.array().of(
    Yup.object().shape({
      prompt: Yup.string().required('This field is required'),
    })
  ),
  niceToHaveRequirements: Yup.array().of(
    Yup.object().shape({
      prompt: Yup.string().required('This field is required'),
    })
  ),
});

const RequirementsList = ({
  requirements,
  title,
}: {
  requirements: RoleRequirementValue[];
  title: string;
}): JSX.Element | null =>
  requirements.length ? (
    <div className="flex flex-col gap-4">
      <div className="text-headraceBlack-700 text-base font-medium">
        {title}
      </div>
      {requirements.map((requirement) => (
        <CheckListItem
          key={requirement.id}
          label={requirement.prompt}
          checkIconClassName="!text-headraceYellow-700"
          labelClassName="text-gray-500 text-sm !font-normal"
        />
      ))}
    </div>
  ) : null;

const RoleRequirementsCard: VFC<RoleRequirementCardProps> = ({
  saveAction,
  cancelAction,
  loading,
  mustToHaveRequirements,
  niceToHaveRequirements,
  editMode,
  refetchLoading,
}) => {
  const formRef = useRef<FormikProps<RoleRequirementFormValues>>(null);
  const [activeEditMode, setActiveEditMode] = useState(false);
  const viewComponent = useMemo(() => {
    const allRequirements = [
      ...mustToHaveRequirements,
      ...niceToHaveRequirements,
    ];
    return allRequirements.length ? (
      <div className="flex flex-col gap-6">
        <RequirementsList
          requirements={mustToHaveRequirements}
          title="Must haves"
        />
        <RequirementsList
          requirements={niceToHaveRequirements}
          title="Nice to haves"
        />
      </div>
    ) : (
      <EmptyStateIllustration
        button={{
          label: 'Add role requirements',
          onClick: (): void => setActiveEditMode(true),
        }}
        title="Add role requirements"
        description="Add a list of must-haves and nice-to haves to identify the ideal candidate."
        image="/illustrations/emptyStateDossier/role-requirements-empty.svg"
        showSeparator={false}
        descriptionClassName="w-full mb-2 !text-gray-500"
        className="!p-0"
        contentClassName="!gap-2"
        titleClassName="!text-sm"
        imageClassName="w-2/3 lg:w-2/3 xl:w-full"
      />
    );
  }, [mustToHaveRequirements, niceToHaveRequirements]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        mustToHaveRequirements,
        niceToHaveRequirements,
        deletedRequirements: [],
      }}
      onSubmit={(values): void => {
        if (saveAction) saveAction(values);
        setActiveEditMode(false);
      }}
      validationSchema={RoleRequirementsFormSchema}
      enableReinitialize
    >
      <Form>
        <EditCard
          saveAction={async (): Promise<void> => {
            await formRef.current?.submitForm();
          }}
          cancelAction={(): void => {
            setActiveEditMode(false);
            if (cancelAction) cancelAction();
          }}
          viewStateComponent={viewComponent}
          editStateComponent={
            <RoleRequirementsForm
              originalRequirements={[
                ...mustToHaveRequirements,
                ...niceToHaveRequirements,
              ]}
            />
          }
          title="Ideal candidate checklist"
          loading={loading}
          editMode={editMode}
          activeEditMode={activeEditMode}
          closeOnSave={false}
          onEdit={(): void => setActiveEditMode(true)}
          refetchLoading={refetchLoading}
        />
      </Form>
    </Formik>
  );
};

export default RoleRequirementsCard;
