import classNames from 'classnames';

import type { Column, MinTableData } from './TableTd';
import TableTd from './TableTd';

type TableBodyProps<D extends MinTableData> = {
  data: D[];
  columns: Column<D>[];
  onClickRow?: (id?: string) => void;
  noRowPointer?: (id?: string) => boolean;
  classNameRow?: (id?: string) => string;
};

const TableBody = <D extends MinTableData>(
  props: TableBodyProps<D>
): JSX.Element => {
  const {
    data,
    columns,
    onClickRow,
    noRowPointer = (): boolean => false,
    classNameRow = (): string => '',
  } = props;

  return (
    <tbody className="divide-y divide-gray-200">
      {data.map((row) => (
        <tr
          key={row.id}
          className={classNames(
            'border-t',
            {
              'cursor-pointer': !!onClickRow && !noRowPointer(row.id),
            },
            classNameRow(row.id)
          )}
        >
          {columns.map((column) => (
            <TableTd
              key={`${row.id}-${column.header}`}
              data={row}
              column={column}
              onClickRow={column.header === '' ? undefined : onClickRow}
              className="sm:px-5 sm:py-4 p-2 text-sm font-normal text-headraceBlack-800"
            />
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
