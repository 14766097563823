/* eslint-disable react/jsx-props-no-spreading */
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import type { ReactElement, VFC } from 'react';
import React, { useMemo } from 'react';
import type {
  DropdownIndicatorProps,
  GroupBase,
  MenuListProps,
  MultiValueProps,
  OptionProps,
  Props,
  StylesConfig,
} from 'react-select';
import Select, { components } from 'react-select';
import type { SelectComponents } from 'react-select/dist/declarations/src/components';

import colors from '../../utils/headraceColors';

export interface SelectActionsProps {
  label: string;
  action?: () => void;
}
export interface CustomAvatarSelectProps {
  avatar?: string;
  firstName: string;
  lastName: string;
}
export interface OptionsProps {
  label: string;
  value: string;
  withAvatar?: CustomAvatarSelectProps;
}

const MultiValue: React.ComponentType<
  MultiValueProps<OptionsProps, boolean, GroupBase<OptionsProps>>
> = (props) => {
  const { index, getValue } = props;
  const hiddenLength = getValue().length;

  if (index < 0) {
    return <components.MultiValue {...props} />;
  }
  if (index === 0) {
    return (
      <div className="option-label">{`${hiddenLength} Item${
        hiddenLength !== 1 ? 's' : ''
      } selected`}</div>
    );
  }
  return null;
};

const Option: React.ComponentType<
  OptionProps<OptionsProps, boolean, GroupBase<OptionsProps>>
> = (props) => {
  const {
    data: { label },
    isSelected,
  } = props;
  if (isSelected) {
    return (
      <div>
        <components.Option {...props}>
          <div className="relative pr-4">
            <span>{label}</span>
            <CheckIcon className="h-4 w-4 absolute top-1 right-0" />
          </div>
        </components.Option>
      </div>
    );
  }
  return (
    <div>
      <components.Option {...props}>{label}</components.Option>
    </div>
  );
};

export interface SelectAction {
  action: () => void;
  label: string;
}

interface BasicSelectProps {
  displayCounter?: boolean;
  hasError?: boolean;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  actions?: SelectAction[];
  optionsMessage?: boolean;
  customNoOptionsMessage?: string;
  showIconDropdown?: boolean;
  noBorders?: boolean;
  showIndicatorSeparator?: boolean;
  customMultiValueOptions?: (
    options: OptionsProps[]
  ) => ReactElement<BasicSelectProps>;
}

const DropdownIndicator: React.ComponentType<
  DropdownIndicatorProps<OptionsProps, boolean, GroupBase<OptionsProps>>
> | null = (propsDropIndicator) => (
  <components.DropdownIndicator {...propsDropIndicator}>
    <div className="text-gray-400">
      <ChevronUpIcon className="h-3 w-3 -mb-1" />
      <ChevronDownIcon className="h-3 w-3" />
    </div>
  </components.DropdownIndicator>
);

const BasicSelect: VFC<Props<OptionsProps> & BasicSelectProps> = (props) => {
  const {
    maxMenuHeight,
    inputRef,
    hasError = false,
    displayCounter = false,
    actions,
    optionsMessage = true,
    customNoOptionsMessage = 'No options',
    styles = {},
    showIconDropdown,
    noBorders,
    showIndicatorSeparator = false,
    customMultiValueOptions,
  } = props;
  const customStyles: StylesConfig<OptionsProps> = {
    container: (provided) => ({
      ...provided,
      borderRadius: '6px',
      fontSize: '12px',
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: noBorders ? '6px 0px 0px 6px' : '6px',
      border: hasError ? '1px solid red' : '1px solid lightgray',
      boxShadow: 'none',
      ':active': {
        border: '1px solid lightgray',
        boxShadow: 'none',
      },
      ':hover': {
        border: '1px solid lightgray',
        boxShadow: 'none',
      },
      minHeight: noBorders ? '42px' : undefined,
    }),
    option: (baseStyles, { isSelected }) => ({
      ...baseStyles,
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: isSelected ? colors.headraceYellow[700] : 'white',
      color: colors.headraceBlack[700],
      width: '100%',
      left: 0,
      ':active': {
        backgroundColor: colors.headraceYellow[700],
      },
      ':hover': {
        backgroundColor: colors.headraceYellow[700],
      },
      fontSize: '12px',
    }),
    singleValue: (base) => ({
      ...base,
      color: colors.headraceBlack[700],
      textAlign: 'left',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      backgroundColor: 'red',
      fontSize: '14px',
    }),
    placeholder: (base) => ({
      ...base,
      textAlign: 'left',
    }),
    input: (provided) => ({
      ...provided,
      input: {
        outline: '0px solid grey',
        boxShadow: 'none',
        border: '0px solid grey',
        ':focus': {
          border: '1px solid white',
          boxShadow: 'none',
          outline: '1px solid white',
        },
      },
    }),
    menu: (base) => ({
      ...base,
      overflow: 'hidden',
      borderRadius: '6px',
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      '::-webkit-scrollbar': {
        width: '0px',
        height: '0px',
      },
      '::-webkit-scrollbar-track': {
        display: 'none',
      },
      '::-webkit-scrollbar-thumb': {
        display: 'none',
      },
      '::-webkit-scrollbar-thumb:hover': {
        display: 'none',
      },
    }),
    multiValue: (baseStyles) => ({
      ...baseStyles,
      borderRadius: 40,
      paddingLeft: 4,
      paddingRight: 4,
    }),
    multiValueLabel: (baseStyles) => ({
      ...baseStyles,
      color: 'black',
    }),
    multiValueRemove: (baseStyles) => ({
      ...baseStyles,
      borderRadius: 40,
      color: 'grey',
      ':hover': {
        backgroundColor: 'grey',
        color: 'white',
      },
    }),
    ...styles,
  };

  const componentsOptions = useMemo(() => {
    const customMultiValue: React.ComponentType<
      MultiValueProps<OptionsProps, boolean, GroupBase<OptionsProps>>
    > = (multiValueProps) => {
      const { index, getValue } = multiValueProps;

      if (index < 0) {
        return <components.MultiValue {...multiValueProps} />;
      }
      if (index === 0 && customMultiValueOptions) {
        return customMultiValueOptions(getValue() as OptionsProps[]);
      }
      return null;
    };
    const componentsObj: Partial<
      SelectComponents<OptionsProps, boolean, GroupBase<OptionsProps>>
    > = {
      Option,
    };
    const menuList: React.ComponentType<
      MenuListProps<OptionsProps, boolean, GroupBase<OptionsProps>>
    > = (propsMenu) => {
      const {
        children,
        options,
        selectProps: { onMenuClose },
      } = propsMenu;
      return (
        <components.MenuList {...propsMenu}>
          {actions && actions.length > 0 && (
            <div className="flex justify-between">
              {actions.map((action) => (
                <button
                  key={action.label}
                  type="button"
                  onClick={(): void => {
                    action.action();
                    onMenuClose();
                  }}
                  className={classNames(
                    {
                      'border-b border-gray-200':
                        optionsMessage || options.length,
                      '-mb-[16px]': !optionsMessage && !options.length,
                    },
                    'p-3  w-full text-left text-gray-700 hover:bg-headraceYellow-700 font-normal'
                  )}
                >
                  {action.label}
                </button>
              ))}
            </div>
          )}

          {children}
        </components.MenuList>
      );
    };
    if (customMultiValueOptions) componentsObj.MultiValue = customMultiValue;
    if (displayCounter) componentsObj.MultiValue = MultiValue;

    if (actions && actions.length > 0) componentsObj.MenuList = menuList;
    if (!showIconDropdown) componentsObj.DropdownIndicator = DropdownIndicator;

    return componentsObj;
  }, [
    actions,
    customMultiValueOptions,
    displayCounter,
    optionsMessage,
    showIconDropdown,
  ]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getComponents = () => {
    if (showIndicatorSeparator) return { ...componentsOptions };
    return { ...componentsOptions, IndicatorSeparator: () => null };
  };

  return (
    <Select
      {...props}
      styles={customStyles}
      components={getComponents()}
      menuPortalTarget={document.body}
      maxMenuHeight={maxMenuHeight || 210}
      menuPlacement="auto"
      menuPosition="fixed"
      isSearchable
      ref={(selectRef): void => {
        if (inputRef && selectRef?.inputRef) {
          inputRef.current = selectRef.inputRef;
        }
      }}
      noOptionsMessage={(): string =>
        customNoOptionsMessage && optionsMessage ? customNoOptionsMessage : ''
      }
    />
  );
};
export default BasicSelect;
