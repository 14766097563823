const formatAsUrl = (url: string): string => {
  if (!url.startsWith('https://')) {
    if (url.startsWith('www.')) {
      return `https://${url}`;
    }
    if (url.startsWith('http://www.')) {
      return `https${url.substring(4)}`;
    }
    return `https://www.${url}`;
  }
  return url;
};

export default formatAsUrl;
