import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
}

const ArrowUndoIcon: VFC<Props> = ({
  className = 'bi bi-arrow-counterclockwise',
  width = '14',
  height = '14',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4.66669H7.66667C10.6122 4.66669 13 7.0545 13 10V11.3334M1 4.66669L5 8.66669M1 4.66669L5 0.666687"
      stroke="#4B5563"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowUndoIcon;
