import type { CandidateDocumentTypeEnum } from '@headrace/types';
import { DownloadIcon } from '@heroicons/react/outline';
import dynamic from 'next/dynamic';
import type { VFC } from 'react';
import { useState } from 'react';

import Button from '../Button';
import type { OptionsProps } from '../forms/fields/BasicSelect';
import BasicSelect from '../forms/fields/BasicSelect';
import Modal from '../Modal';
import { downloadAs } from './DocumentReader';

const DocumentReader = dynamic(() => import('./DocumentReader'), {
  ssr: false,
});
export interface HeadRaceFile {
  id: string;
  file: File;
  name: string;
  url: string;
  documentType: CandidateDocumentTypeEnum;
}

export interface InitialValuesOfferAccept {
  expectedFirstYearSalary: string;
  expectedFirstYearShareCount: string;
  expectedStartDate: null | Date;
  file: string;
}

export interface Documents {
  id: string;
  name: string;
  documentPath: string | null | undefined;
}

interface Props {
  open: boolean;
  onClose: () => void;
  documents: Documents[];
}

const CandidateViewDocumentsModal: VFC<Props> = ({
  open,
  onClose,
  documents,
}) => {
  const formatDocumentsData: OptionsProps[] =
    documents && documents.length > 0
      ? documents.map((document) => ({
          value: document.documentPath || '',
          label: document.name,
        }))
      : [{ label: '', value: '' }];
  const [documentSelected, setDocumentSelected] = useState<OptionsProps | null>(
    formatDocumentsData[0]
  );

  if (!documents || documents.length < 0) return null;

  if (open) {
    return (
      <Modal
        onClose={(): void => {
          onClose();
        }}
        open={open}
        title="Candidate documents"
        className="sm:min-w-[800px] my-8 min-h-[650px] sm:max-w-none sm:w-4/5"
      >
        <>
          <div className="flex items-center mb-8 w-11/12">
            <div className="font-medium">Documents:</div>
            {formatDocumentsData.length > 1 && (
              <BasicSelect
                id="filterRole"
                options={formatDocumentsData}
                onChange={(newValue): void => {
                  setDocumentSelected(newValue as OptionsProps);
                }}
                defaultValue={formatDocumentsData[0]}
                value={documentSelected}
                className="ml-4 w-[270px]"
                placeholder="Select file"
                hideSelectedOptions={false}
              />
            )}
            {formatDocumentsData.length === 1 && (
              <div className="ml-4">{formatDocumentsData[0].label}</div>
            )}
            {documentSelected && (
              <Button
                onClick={(): Promise<void> =>
                  downloadAs(documentSelected?.value, documentSelected?.label)
                }
                className="font-medium text-blue-500 !m-0"
                buttonType="link"
              >
                <DownloadIcon className="w-7 h-7 ml-4 stroke-gray-500 hover:stroke-gray-400" />{' '}
              </Button>
            )}
          </div>

          {documentSelected && (
            <DocumentReader
              urlFile={documentSelected.value}
              nameFile={documentSelected.label}
            />
          )}
        </>
      </Modal>
    );
  }
  return null;
};

export default CandidateViewDocumentsModal;
