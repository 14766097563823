import { SCHEDULE_LINK } from '@headrace/constants';
import { InterviewerType } from '@headrace/types';
import * as Yup from 'yup';

const CandidateInterviewSchema = (
  interviewerType: InterviewerType,
  confirmation: boolean,
  editMode?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any =>
  Yup.object().shape({
    firstName:
      interviewerType === InterviewerType.INVITED
        ? Yup.string().required('First name is required')
        : Yup.string().nullable(),
    lastName:
      interviewerType === InterviewerType.INVITED
        ? Yup.string().required('Last name is required')
        : Yup.string().nullable(),
    email:
      interviewerType === InterviewerType.INVITED
        ? Yup.string().email('Invalid email').required('Email is required')
        : Yup.string().nullable(),
    interviewerId:
      interviewerType === InterviewerType.EXISTING
        ? Yup.string().required('Interviewer is required')
        : Yup.string().nullable(),
    scheduleLink:
      (!confirmation ||
        (confirmation && interviewerType === InterviewerType.EXISTING)) &&
      !editMode
        ? Yup.string()
            .required('Schedule link is required')
            .matches(SCHEDULE_LINK, 'Invalid schedule link')
        : Yup.string()
            .matches(SCHEDULE_LINK, 'Invalid schedule link')
            .nullable(),
  });

export default CandidateInterviewSchema;
