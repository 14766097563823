import type { ApolloError } from '@apollo/client';
import type { CardRecruiterDataType } from '@headrace/types';
import type { OptionsProps } from '@headrace/ui/src/forms/fields/BasicSelect';
import { useCallback, useMemo, useState } from 'react';

import type {
  ActiveRoleRecruitersQuery,
  RecommendedRecruitersByRoleQuery,
  RecruiterRelevancesByRoleQuery,
} from '@/graphql/generated';
import {
  useActiveRoleRecruitersQuery,
  useRecommendedRecruitersByRoleLazyQuery,
  useRecruiterRelevancesByRoleLazyQuery,
} from '@/graphql/generated';

export interface EnrichmentOptions {
  roleSeniorityOptions: OptionsProps[];
  roleFunctionOptions: OptionsProps[];
  cityOptions: OptionsProps[];
}

interface Query {
  error?: ApolloError;
  loading: boolean;
}
interface RecommendedRecruitersQuery extends Query {
  data?: RecommendedRecruitersByRoleQuery;
}

interface RecruiterRelevanceQuery extends Query {
  data?: RecruiterRelevancesByRoleQuery;
}

const getRecruitersCardData = (
  recommendedQuery: RecommendedRecruitersQuery,
  relevantQuery: RecruiterRelevanceQuery,
  excludedRecruitersId: string[]
): CardRecruiterDataType[] => {
  if (recommendedQuery.data && relevantQuery.data) {
    let recommendedAndRelevantRecruiters = [];
    const formatRelevantRecruiters =
      relevantQuery.data.recruiterRelevancesByRole.map(
        (relevantRecruiters) => ({
          id: relevantRecruiters.id,
          recruiter: {
            id: relevantRecruiters.id,
            linkedIn: relevantRecruiters.linkedIn,
            title: relevantRecruiters.title,
            bio: relevantRecruiters.bio,
            searchPreference: relevantRecruiters.searchPreference,
            agency: {
              name: relevantRecruiters.agency.name,
              website: relevantRecruiters.agency.website,
            },
            location: {
              id: relevantRecruiters.location?.id,
              cityName: relevantRecruiters.location?.cityName,
              stateAbbrev: relevantRecruiters.location?.stateAbbrev,
            },
            user: {
              name: relevantRecruiters.user.name,
              email: relevantRecruiters.user.email,
              phoneNumber: relevantRecruiters.user.phoneNumber,
              photoUrl: relevantRecruiters.user.photoUrl,
            },
            timeToPlacement: relevantRecruiters.timeToPlacement,
            minPricePercent: relevantRecruiters.minPricePercent,
            maxPricePercent: relevantRecruiters.maxPricePercent,
            roleFunctions: relevantRecruiters.roleFunctions,
          },
          isRequested: relevantRecruiters.isRequested,
          schedulingLink15Min: relevantRecruiters.schedulingLink15Min,
          schedulingLink30Min: relevantRecruiters.schedulingLink30Min,
          schedulingLink60Min: relevantRecruiters.schedulingLink60Min,
          isRecommended: false,
          placementsCompanyStages: relevantRecruiters.placementsCompanyStages,
          placementsByFunctionCount:
            relevantRecruiters.placementsByFunctionCount,
          topRoleSeniority: relevantRecruiters.topRoleSeniority,
          isRequestedToDirect: relevantRecruiters.isRequestedToDirect,
          isInvitedToMarketplaceRole:
            relevantRecruiters.isInvitedToMarketplaceRole,
        })
      );
    if (excludedRecruitersId.length > 0) {
      const recommendedRecruiterClean = [
        ...recommendedQuery.data.recommendedRecruitersByRole,
        ...formatRelevantRecruiters,
      ].filter((recruiter) => {
        if (excludedRecruitersId.length) {
          return !excludedRecruitersId.includes(recruiter.recruiter.id);
        }
        return true;
      });
      recommendedAndRelevantRecruiters = recommendedRecruiterClean;
    } else {
      const recommendedRecruiter =
        recommendedQuery.data.recommendedRecruitersByRole.map((recruiter) => ({
          ...recruiter,
          isRecommended: true,
        }));
      recommendedAndRelevantRecruiters = [
        ...recommendedRecruiter,
        ...formatRelevantRecruiters,
      ];
    }
    const recommendedAndRelevantRecrtuitersHashTable: {
      [key: string]: CardRecruiterDataType;
    } = {};
    recommendedAndRelevantRecruiters.forEach((item) => {
      const recruiterHashEntry =
        recommendedAndRelevantRecrtuitersHashTable[item.recruiter.id];
      if (recruiterHashEntry) {
        recommendedAndRelevantRecrtuitersHashTable[item.recruiter.id] = {
          ...item,
          isRecommended: true,
        };
      } else {
        recommendedAndRelevantRecrtuitersHashTable[item.recruiter.id] = item;
      }
    });
    return Object.values(recommendedAndRelevantRecrtuitersHashTable);
  }
  return [];
};

export interface RecommendedAndRelevantRecruitersOutput {
  fetchRelevantRecruiters: () => void;
  fetchRecommendedRecruiters: () => void;
  callRecommendedAndRelevantRecruiters: () => void;
  loadingRecommendedAndRelevantRecruiters: boolean;
  errorRecommendedAndRelevantRecruiters?: ApolloError;
  recommendedAndRelevantRecruitersData: CardRecruiterDataType[];
  activeRoleRecruitersData?: ActiveRoleRecruitersQuery['activeRoleRecruiters'];
  loadingActiveRecruiters: boolean;
  errorActiveRecruiters?: ApolloError;
  refetchActiveRecruiters: () => Promise<void>;
}

const useRecommendedAndRelevantRecruiters = (
  roleId: string
): RecommendedAndRelevantRecruitersOutput => {
  const [recommendedRecruitersData, setRecommendedRecruitersData] =
    useState<RecommendedRecruitersQuery>({
      loading: false,
    });
  const [relevantRecruitersData, setRelevantRecruitersData] =
    useState<RecruiterRelevanceQuery>({
      loading: false,
    });

  const [getRecommendedRecruiters] = useRecommendedRecruitersByRoleLazyQuery();

  const [getRelevantRecruiters] = useRecruiterRelevancesByRoleLazyQuery();

  const {
    data: activeRoleRecruitersData,
    loading: loadingActiveRecruiters,
    error: errorActiveRecruiters,
    refetch: refetchactiveRecruiters,
  } = useActiveRoleRecruitersQuery({ variables: { roleId } });

  const excludedRecruitersId = useMemo(
    () =>
      activeRoleRecruitersData?.activeRoleRecruiters.map(
        (recruiter) => recruiter.id
      ) ?? [],
    [activeRoleRecruitersData?.activeRoleRecruiters]
  );

  const fetchRelevantRecruiters = useCallback((): void => {
    getRelevantRecruiters({
      variables: {
        roleId,
      },
    })
      .then((data) => {
        setRelevantRecruitersData({
          data: data.data,
          loading: data.loading,
          error: data.error,
        });
      })
      .catch((errorMessage: ApolloError) => {
        setRelevantRecruitersData({
          loading: false,
          error: errorMessage,
          data: undefined,
        });
      });
  }, [getRelevantRecruiters, roleId]);

  const fetchRecommendedRecruiters = useCallback((): void => {
    getRecommendedRecruiters({
      variables: {
        roleId,
      },
    })
      .then((data) => {
        setRecommendedRecruitersData({
          data: data.data,
          loading: data.loading,
          error: data.error,
        });
      })
      .catch((errorMessage: ApolloError) => {
        setRecommendedRecruitersData({
          loading: false,
          error: errorMessage,
          data: undefined,
        });
      });
  }, [getRecommendedRecruiters, roleId]);

  const callRecommendedAndRelevantRecruiters = useCallback((): void => {
    fetchRecommendedRecruiters();
    fetchRelevantRecruiters();
  }, [fetchRecommendedRecruiters, fetchRelevantRecruiters]);

  const refetchActiveRecruiters = useCallback(async (): Promise<void> => {
    await refetchactiveRecruiters();
  }, [refetchactiveRecruiters]);

  const recommendedAndRelevantRecruitersData = useMemo(
    (): CardRecruiterDataType[] =>
      getRecruitersCardData(
        recommendedRecruitersData,
        relevantRecruitersData,
        excludedRecruitersId
      ),
    [recommendedRecruitersData, relevantRecruitersData, excludedRecruitersId]
  );

  return {
    fetchRelevantRecruiters,
    fetchRecommendedRecruiters,
    callRecommendedAndRelevantRecruiters,
    loadingRecommendedAndRelevantRecruiters:
      recommendedRecruitersData.loading || relevantRecruitersData.loading,
    errorRecommendedAndRelevantRecruiters:
      recommendedRecruitersData.error || relevantRecruitersData.error,
    recommendedAndRelevantRecruitersData,
    activeRoleRecruitersData: activeRoleRecruitersData?.activeRoleRecruiters,
    loadingActiveRecruiters,
    errorActiveRecruiters,
    refetchActiveRecruiters,
  };
};

export default useRecommendedAndRelevantRecruiters;
