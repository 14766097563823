/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';

import type { PrimitiveType } from '../utils/typing';
import { isPrimitive } from '../utils/typing';

type Join<K extends (string | number)[], P> = P extends (string | number)[]
  ? '' extends P
    ? never
    : [...K, ...P]
  : never;

// Prev needs 2 as max value to avoid an infinite depth for this type
type Prev = [never, 0, 1, 2];
export type Paths<T, I extends number = 3> = [I] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T]-?: K extends string | number
        ? [K] | Join<[K], Paths<T[K], Prev[I]>>
        : never;
    }[keyof T]
  : '';

export interface MinTableData {
  id: string;
}

interface BaseColumn<D extends MinTableData> {
  header: string;
  sortFunction?: (a: D, b: D) => number;
  filterOptions?: string[];
  filterData?: (data: D) => string | string[];
  filterExactMatch?: boolean;
  className?: string;
  headerClassName?: string;
  justifyHeader?: 'center' | 'right';
  helper?: string;
  widthFilterMenu?: string;
}

interface PathColumn<D extends MinTableData> extends BaseColumn<D> {
  path: Paths<D>;
}

interface FormatterColumn<D extends MinTableData> extends BaseColumn<D> {
  formatter: (value: D) => React.ReactNode;
}

export type Column<D extends MinTableData> = PathColumn<D> | FormatterColumn<D>;

interface Props<D extends MinTableData> {
  data: D;
  column: Column<D>;
  className?: string;
  onClickRow?: (id?: string) => void;
}

const TableTd = <D extends MinTableData>(props: Props<D>): JSX.Element => {
  const { data, column, className, onClickRow } = props;

  let value: React.ReactNode = null;
  if ('path' in column) {
    value = column.path.reduce<D | PrimitiveType>((acc, cur) => {
      // just return if a primitive value is found
      if (isPrimitive(acc)) {
        return acc;
      }
      return acc[cur];
    }, data);
    // we don't want to render objects, so it's better to check this
    if (!isPrimitive(value)) {
      value = '-';
    }
  } else if ('formatter' in column) {
    value = column.formatter(data);
  }

  const tdClassName = classNames(className, column.className);

  return (
    <td
      className={tdClassName}
      onClick={onClickRow && ((): void => onClickRow(data?.id))}
    >
      {value}
    </td>
  );
};

export default TableTd;
