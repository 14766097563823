import { FeeScheduleTypeEnum } from '@headrace/types';
import type { VFC } from 'react';

interface EquityPlacementFeeProps {
  amount: string;
  feeScheduleType: FeeScheduleTypeEnum;
  trigger: string;
}

const EquityPlacementFee: VFC<EquityPlacementFeeProps> = ({
  amount,
  feeScheduleType,
  trigger,
}) => {
  const parsedAmount =
    feeScheduleType === FeeScheduleTypeEnum.EQUITY_FIXED
      ? `${amount} units`
      : `${amount}%`;
  return (
    <>
      <div>
        <span>{parsedAmount}</span>
      </div>
      <div>
        <span>{trigger}</span>
      </div>
    </>
  );
};

export default EquityPlacementFee;
