import { FeeTriggerTypeEnum } from '@headrace/types';
import type { VFC } from 'react';

import formatDate from '../../utils/formatDate';

interface CustomFeeProps {
  amount: string;
  feeTriggerType?: FeeTriggerTypeEnum | null;
  oneTimeFeeTriggerAt?: Date | null;
  oneTimeFeeRelativeSigningDays?: number | null;
}

const CustomFee: VFC<CustomFeeProps> = ({
  amount,
  feeTriggerType,
  oneTimeFeeTriggerAt,
  oneTimeFeeRelativeSigningDays,
}) => {
  const trigger = ((): string => {
    if (
      feeTriggerType === FeeTriggerTypeEnum.ONE_TIME_FEE_ABSOLUTE &&
      oneTimeFeeTriggerAt
    )
      return `On ${formatDate(oneTimeFeeTriggerAt, 'MM/d/yyyy')}`;
    if (
      feeTriggerType === FeeTriggerTypeEnum.ONE_TIME_FEE_RELATIVE &&
      oneTimeFeeRelativeSigningDays
    )
      return `${oneTimeFeeRelativeSigningDays} days from signing`;
    return '';
  })();

  return (
    <>
      <div>
        <span>${amount}</span>
      </div>
      <div>
        <span>{trigger}</span>
      </div>
    </>
  );
};

export default CustomFee;
