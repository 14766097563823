import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
}

const TypeBoldIcon: VFC<Props> = ({
  className = 'bi bi-type-bold',
  width = '8',
  height = '10',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.40502 9.97003C2.87302 9.97003 2.34102 9.95136 1.80902 9.91403C1.28635 9.88603 0.740354 9.81136 0.171021 9.69003V0.28203C0.619021 0.19803 1.10902 0.137363 1.64102 0.100029C2.17302 0.0533628 2.66769 0.0300293 3.12502 0.0300293C3.74102 0.0300293 4.30569 0.0766959 4.81902 0.170029C5.34169 0.254029 5.78969 0.403362 6.16302 0.618029C6.53635 0.832696 6.82569 1.11736 7.03102 1.47203C7.24569 1.81736 7.35302 2.2467 7.35302 2.76003C7.35302 3.5347 6.97969 4.14603 6.23302 4.59403C6.84902 4.82736 7.26902 5.1447 7.49302 5.54603C7.71702 5.94736 7.82902 6.40003 7.82902 6.90403C7.82902 7.92136 7.45569 8.6867 6.70902 9.20003C5.97169 9.71336 4.87035 9.97003 3.40502 9.97003ZM2.29902 5.61603V8.12203C2.45769 8.1407 2.63035 8.1547 2.81702 8.16403C3.00369 8.17336 3.20902 8.17803 3.43302 8.17803C4.08635 8.17803 4.61369 8.0847 5.01502 7.89803C5.41635 7.71136 5.61702 7.36603 5.61702 6.86203C5.61702 6.41403 5.44902 6.0967 5.11302 5.91003C4.77702 5.71403 4.29635 5.61603 3.67102 5.61603H2.29902ZM2.29902 3.95003H3.36302C4.03502 3.95003 4.51569 3.86603 4.80502 3.69803C5.09435 3.5207 5.23902 3.2407 5.23902 2.85803C5.23902 2.46603 5.08969 2.1907 4.79102 2.03203C4.49235 1.87336 4.05369 1.79403 3.47502 1.79403C3.28835 1.79403 3.08769 1.7987 2.87302 1.80803C2.65835 1.80803 2.46702 1.81736 2.29902 1.83603V3.95003Z"
      fill="#4B5563"
    />
  </svg>
);

export default TypeBoldIcon;
