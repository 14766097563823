import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
  color?: string;
}

const MealIcon: VFC<Props> = ({
  className,
  width = '28',
  height = '38',
  color = '#FFA300',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 28 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9999 0C10.3999 0 9.95996 0.479933 9.95996 1.03997V10.2H7.08003L7.0799 1.03997C7.0799 0.439967 6.59997 0 6.03993 0C5.43993 0 4.99997 0.479933 4.99997 1.03997V10.2H2.12003V1.03997C2.12003 0.439967 1.6401 0 1.08007 0C0.520034 0 0 0.479933 0 1.03997V13.24C0 15.36 1.72007 17.12 3.88 17.12H5.00007V20.84H3.44003C2.84003 20.84 2.40007 21.3199 2.40007 21.8799V34.2799C2.40007 36.3199 4.04003 37.9599 6.08007 37.9599C8.12003 37.9599 9.76007 36.32 9.76007 34.2799V21.8799C9.76007 21.2799 9.28014 20.84 8.7201 20.84H7.16007V17.12H8.28013C10.4002 17.12 12.1601 15.3999 12.1601 13.24L12.1603 1.03997C12.0402 0.479933 11.5603 0 11.0002 0H10.9999ZM7.59993 34.32C7.59993 35.1999 6.87986 35.88 6.0399 35.88C5.15996 35.88 4.47986 35.16 4.47986 34.32V22.96H7.59993V34.32ZM8.15996 15.04H3.83996C2.87993 15.04 2.07993 14.24 2.07993 13.28V12.3199H9.91993V13.28C9.92006 14.24 9.16003 15.04 8.16003 15.04H8.15996Z"
      fill={color}
    />
    <path
      d="M27.9999 9.24C27.9999 4.68 25.56 0 21.4399 0C17.3199 0 14.9199 4.72 14.9199 9.24C14.9199 13.24 17.28 16.44 20.3999 17V20.84H18.8399C18.2399 20.84 17.7999 21.3199 17.7999 21.88V34.28C17.7999 36.3199 19.4399 37.96 21.4799 37.96C23.5199 37.96 25.1599 36.32 25.1599 34.28V21.88C25.1599 21.28 24.68 20.84 24.12 20.84H22.5599V17C25.68 16.44 27.9999 13.24 27.9999 9.24ZM23.0399 34.32C23.0399 35.1999 22.3199 35.88 21.4799 35.88C20.6 35.88 19.9199 35.16 19.9199 34.32L19.92 22.96H23.08L23.0799 34.32H23.0399ZM16.9999 9.24C16.9999 4.84 19.32 2.08 21.4399 2.08C23.56 2.08 25.8799 4.80007 25.8799 9.24C25.8799 12.48 23.9199 15 21.4399 15C18.96 15.04 16.9999 12.4799 16.9999 9.24Z"
      fill={color}
    />
  </svg>
);

export default MealIcon;
