import type { VFC } from 'react';

interface ErrorProps {
  id: string;
  error: { [key: string]: string } | null;
  touched: boolean;
}

const ErrorObjectText: VFC<ErrorProps> = ({ id, error, touched }) => {
  if (touched && error)
    return (
      <div id={id}>
        {touched}
        {Object.keys(error).map((key) => (
          <div id={key} className="mt-2 text-sm text-red-600">
            {error[key]}
          </div>
        ))}
      </div>
    );

  return null;
};
export default ErrorObjectText;
