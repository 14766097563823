import type { ApolloError } from '@apollo/client';

interface ApolloNetworkError {
  errors: { message: string }[];
}

const formatApolloError = (_error: ApolloError): string => {
  // Generic error
  if (!_error.networkError) {
    return _error.message;
  }
  // API feedback error
  const errorResult =
    'result' in _error.networkError
      ? (_error.networkError.result as ApolloNetworkError)
      : null;
  const errorList =
    errorResult && 'errors' in errorResult
      ? errorResult.errors.map((err) => err.message).join('\n')
      : '';
  return errorList;
};

export default formatApolloError;
