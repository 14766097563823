import classNames from 'classnames';
import Link from 'next/link';
import { type FC, useMemo } from 'react';

import type { NavigationItem } from './TailwindLayout';

type Props = {
  tab: NavigationItem;
  isSelected: boolean;
};

const TabbedNavigationItem: FC<Props> = ({ tab, isSelected }) => {
  const pill = useMemo(() => {
    if (!tab.pill) return null;

    switch (tab.pillType) {
      case 'PRIMARY': {
        return (
          <span className="rounded-[10px] inline-flex ml-2 bg-headraceYellow-700 !h-[20px] px-[10px] text-xs leading-4 text-center text-gray-800 items-center">
            {tab.pill}
          </span>
        );
      }
      case 'SECONDARY': {
        return (
          <span className="rounded-[10px] inline-flex ml-2 bg-headraceGray-100 !h-[20px] px-[10px] text-xs leading-4 text-center text-headraceBlack-700  items-center">
            {tab.pill}
          </span>
        );
      }
      default:
        return (
          <span className="rounded-[10px] inline-flex ml-2 bg-headraceYellow-700 !h-[20px] px-[10px] text-xs leading-4 text-center text-gray-800 items-center">
            {tab.pill}
          </span>
        );
    }
  }, [tab]);

  return (
    <Link key={tab.name} href={tab.href} passHref shallow>
      <a
        className={classNames(
          isSelected
            ? 'border-headraceYellow-700 text-headraceBlack-800'
            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
          'whitespace-nowrap border-b-2 font-medium text-sm py-6'
        )}
      >
        {tab.name}
        {pill}
      </a>
    </Link>
  );
};

export default TabbedNavigationItem;
