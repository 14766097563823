// This component must be dynamically imported
import 'react-pdf/dist/esm/Page/TextLayer.css';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DownloadIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import type { VFC } from 'react';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import Button from '../Button';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface HeadRaceFile {
  urlFile: string;
  nameFile: string;
}

export const downloadAs = async (path: string, name: string): Promise<void> => {
  await fetch(path, {
    method: 'GET',
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    });
};

const DocumentReader: VFC<HeadRaceFile> = ({ urlFile, nameFile }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [errorShowingDocument, setErrorShowingDocument] = useState(false);

  const onDocumentLoadSuccess = (file: PDFDocumentProxy): void => {
    setNumPages(file.numPages);
  };

  const goToPrevPage = (): void =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = (): void => {
    if (numPages) {
      setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
    }
  };

  return (
    <div className="flex justify-center items-center h-[700px]">
      <div
        className={classNames('flex justify-center', {
          'w-full': !errorShowingDocument,
        })}
      >
        <Document
          file={urlFile}
          onLoadSuccess={(file: PDFDocumentProxy): void => {
            onDocumentLoadSuccess(file);
            setErrorShowingDocument(false);
          }}
          onLoadError={(): void => setErrorShowingDocument(true)}
          error={false}
          className="w-full"
        >
          {!errorShowingDocument && (
            <nav className="flex justify-between my-4">
              <button type="button" onClick={goToPrevPage}>
                <ArrowLeftIcon className="w-5 h-5 mt-4 stroke-headraceYellow-700 hover:stroke-headraceYellow-300" />{' '}
              </button>
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <button type="button" onClick={goToNextPage}>
                <ArrowRightIcon className="w-5 h-5 mt-4 stroke-headraceYellow-700 hover:stroke-headraceYellow-300" />{' '}
              </button>
            </nav>
          )}
          <div className="h-[650px] overflow-auto my-4 flex justify-center">
            <Page pageNumber={pageNumber} renderAnnotationLayer={false} />
          </div>
        </Document>
      </div>
      {errorShowingDocument && (
        <div className="flex items-center justify-center flex-col h-[650px] my-4 overflow-auto">
          The document cannot be previewed, to download click below
          <Button
            onClick={(): Promise<void> => downloadAs(urlFile, nameFile)}
            className="font-medium text-blue-500 !m-0"
            buttonType="link"
          >
            <DownloadIcon className="w-10 h-10 mt-4 stroke-gray-500 hover:stroke-gray-400" />{' '}
          </Button>
        </div>
      )}
    </div>
  );
};

export default DocumentReader;
