import { useToaster } from 'react-hot-toast';

import SimpleNotification from './SimpleNotification';

const Notifications: React.VFC = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause } = handlers;

  return (
    <div
      aria-live="assertive"
      className="z-50 fixed inset-0 flex px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      <div className="w-full flex flex-col space-y-4 items-end">
        {toasts.map((toast) => (
          <SimpleNotification
            key={toast.id}
            id={toast.id}
            message={toast.message}
            type={toast.type}
            visible={toast.visible}
          />
        ))}
      </div>
    </div>
  );
};

export default Notifications;
