import type { VFC } from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
}

const TextCenterIcon: VFC<Props> = ({
  className = 'bi bi-text-center',
  width = '16',
  height = '16',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66675 7.99999H13.3334M5.68605 12H10.3527M5.73949 4.01086H10.4062"
      stroke="#4B5563"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TextCenterIcon;
