import { HEADRACE_FEE } from '@headrace/constants';
import {
  FeeScheduleTypeEnum,
  MarketplaceVisibilityEnum,
} from '@headrace/types';
import { currencyFormatter, headraceFee } from '@headrace/utils';
import classNames from 'classnames';
import Image from 'next/image';
import type { VFC } from 'react';
import { useMemo, useState } from 'react';

import Modal from '../../Modal';
import CalculatorField from './CalculatorField';

interface CalculatorModalProps {
  className?: string;
  open: boolean;
  onClose: () => void;
  marketplaceVisibility?: string | null;
  fee: number;
  feeType: FeeScheduleTypeEnum;
  singularRole?: boolean;
  salary: number;
}

const CalculatorModal: VFC<CalculatorModalProps> = (props) => {
  const {
    className,
    onClose,
    open,
    marketplaceVisibility,
    fee,
    salary,
    feeType,
    singularRole,
  } = props;

  const [salaryValue, setSalaryValue] = useState(salary);

  const headRaceFee = useMemo(() => {
    // TODO: this could be dynamic and returned from backend depending on how much work a recruiter has done for an employer
    if (marketplaceVisibility === MarketplaceVisibilityEnum.DIRECT) {
      if (singularRole) return 0;
    }
    return HEADRACE_FEE;
  }, [marketplaceVisibility, singularRole]);

  const description = useMemo<string>(() => {
    switch (marketplaceVisibility) {
      case MarketplaceVisibilityEnum.DIRECT:
      case MarketplaceVisibilityEnum.INVITE:
        return 'Below of an estimate of expected earnings based on the role’s base salary, the placement fee, and HeadRace’s platform fees.';
      case MarketplaceVisibilityEnum.PUBLIC:
        return 'Below of an estimate of expected earnings based on the reward and HeadRace’s platform fees.';
      default:
        return '';
    }
  }, [marketplaceVisibility]);

  const fields = useMemo(() => {
    switch (marketplaceVisibility) {
      case MarketplaceVisibilityEnum.DIRECT:
        return (
          <>
            <CalculatorField
              label="Salary"
              type="currency"
              value={salaryValue}
              defaultValue={salary}
              disabled={false}
              onChange={(values): void => {
                setSalaryValue(Number(values.value));
              }}
            />
            {feeType === FeeScheduleTypeEnum.CASH_PERCENT && (
              <CalculatorField
                label="Placement fee percentage"
                type="percentage"
                defaultValue={fee}
                disabled
              />
            )}
            {feeType === FeeScheduleTypeEnum.CASH_FIXED && (
              <CalculatorField
                label="Placement fee"
                type="currency"
                defaultValue={fee}
                disabled
              />
            )}
            <CalculatorField
              label="HeadRace fee"
              type="percentage"
              defaultValue={headRaceFee}
              disabled
            />
          </>
        );
      case MarketplaceVisibilityEnum.INVITE:
      case MarketplaceVisibilityEnum.PUBLIC:
        return (
          <>
            {feeType === FeeScheduleTypeEnum.CASH_PERCENT && (
              <>
                <CalculatorField
                  label="Salary"
                  type="currency"
                  value={salaryValue}
                  defaultValue={salary}
                  disabled={false}
                  onChange={(values): void => {
                    setSalaryValue(Number(values.value));
                  }}
                />
                <CalculatorField
                  label="Placement fee percentage"
                  type="percentage"
                  defaultValue={fee}
                  disabled
                />
              </>
            )}
            {feeType === FeeScheduleTypeEnum.CASH_FIXED && (
              <CalculatorField
                label="Reward"
                type="currency"
                defaultValue={fee}
                disabled
              />
            )}
            <CalculatorField
              label="HeadRace fee"
              type="percentage"
              defaultValue={headRaceFee}
              disabled
            />
          </>
        );
      default:
        return null;
    }
  }, [fee, feeType, headRaceFee, salary, salaryValue, marketplaceVisibility]);

  return (
    <Modal
      open={open}
      onClose={(): void => {
        setSalaryValue(salary);
        onClose();
      }}
      title="Estimated earnings"
      subtitle={description}
      alignHeader="left"
      fullWidth={false}
      showXicon
      className={classNames('sm:min-w-[600px] place-self-center', className)}
      closeIconClassName="text-gray-400 w-6"
    >
      <div className="flex flex-col items-center sm:flex-row sm:items-stretch justify-center divide-y sm:divide-y-0 sm:divide-x divide-headraceYellow-700">
        <div className="flex flex-col gap-[10px] mb-6 sm:mb-0 sm:mr-6 py-[7px] sm:py-0 sm:px-[7px] min-w-[200px] items-center">
          <p className="font-medium text-xs leading-[13.79px] text-center max-w-[128px] text-gray-400">
            Estimated earnings from this role
          </p>
          <p className="font-medium text-[32px] leading-[36.77px] text-center text-gray-600 break-all">
            {currencyFormatter().format(headraceFee(feeType, salaryValue, fee))}
          </p>
          <div className="w-[128px]">
            <Image
              src="/illustrations/searchTermsMan.svg"
              alt="Calculator illustration"
              layout="responsive"
              width={128}
              height={103}
              objectFit="contain"
            />
          </div>
        </div>
        <div className="flex items-center pt-6 sm:pt-0 sm:pl-6 flex-auto min-w-[284px] justify-end">
          <div className="flex flex-col gap-4 sm:items-end justify-center h-fit">
            {fields}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CalculatorModal;
