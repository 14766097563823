import type { ReactNode, VFC } from 'react';
import { useCallback, useMemo, useState } from 'react';

import Modal from './Modal';
import RatingInput from './RatingInput';
import ReviewInput from './ReviewInput';

interface ReviewValue {
  review: string;
  communication: number;
  timeliness: number;
  responsiveness: number;
  overallRating: number;
}

interface Props {
  onSubmit: (values: ReviewValue) => void;
  open: boolean;
  onClose: () => void;
  companyName: string;
}

const ReviewModal: VFC<Props> = ({ onSubmit, open, onClose, companyName }) => {
  const [height, setHeight] = useState(0);
  const initialValue = {
    review: '',
    overallRating: 0,
    communication: 0,
    timeliness: 0,
    responsiveness: 0,
  };
  const [review, setReview] = useState<ReviewValue>(initialValue);
  const [disableNextButton, setDisableNextButton] = useState<boolean>(false);
  const stepsData: {
    name: keyof ReviewValue;
    type: 'text' | 'rating';
    label: string;
  }[] = useMemo(
    () => [
      {
        name: 'communication',
        type: 'rating',
        label: 'Communication',
      },
      {
        name: 'timeliness',
        type: 'rating',
        label: 'Timeliness',
      },
      {
        name: 'responsiveness',
        type: 'rating',
        label: 'Responsiveness',
      },
      {
        name: 'overallRating',
        type: 'rating',
        label: 'Overall rating',
      },
      {
        name: 'review',
        type: 'text',
        label: 'Public review',
      },
    ],
    []
  );

  const onChangeReview = (
    value: ReviewValue[keyof ReviewValue],
    key: keyof ReviewValue
  ): void => {
    setReview((prev) => {
      const newReview = { ...prev, [key]: value };
      return newReview;
    });
  };

  const validateCurrentValue = useCallback(
    (currentIndex: number): boolean => {
      if (currentIndex === 0) {
        return !Object.values(review).some((value) => value === 0);
      }
      return review.review.length > 20;
    },
    [review]
  );

  const steps = useMemo(() => {
    const textSteps: JSX.Element[] = [];
    stepsData.forEach((step) => {
      if (step.type === 'text') {
        textSteps.push(
          <ReviewInput
            handleChange={(value): void => {
              onChangeReview(value, step.name);
            }}
            value={review[step.name]}
            name={step.name}
            label={step.label}
            description="Minimum of 20 characters"
            style={{ height }}
          />
        );
      }
    });
    const ratingData = stepsData
      .filter((step) => step.type === 'rating')
      .map((step) => ({
        key: step.name,
        review: review[step.name] as number,
        onChange: (value: number): void => {
          onChangeReview(value, step.name);
        },
        label: step.label,
        bold: step.name === 'overallRating',
      }));
    return [
      <RatingInput
        ratings={ratingData}
        setHeight={(h): void => {
          setHeight(h);
        }}
      />,
      ...textSteps,
    ];
  }, [height, review, stepsData]);

  const header = useMemo(
    () => (
      <div className="-mt-4">
        <div className="flex justify-between">
          <div className="text-lg font-medium text-gray-900">
            Review your search with {companyName}
          </div>
        </div>
        <div className="w-8 border-t-2 border-headraceYellow-700 my-2" />

        <div className="">
          <p className="text-sm text-gray-500 text-left">
            Please leave a review for {companyName}, taking into consideration
            the overall experience you had working with this employer.
          </p>
        </div>
      </div>
    ),
    [companyName]
  );
  return (
    <Modal
      open={open}
      onClose={onClose}
      steps={steps}
      onSubmit={(): void => {
        onSubmit(review);
        onClose();
        setReview(initialValue);
      }}
      header={(): ReactNode => header}
      validate={(_currentReview, _currentStep, currentIndex): void => {
        const isValid = validateCurrentValue(currentIndex);
        if (!isValid) {
          setDisableNextButton(true);
        } else {
          setDisableNextButton(false);
        }
      }}
      disableNextButton={disableNextButton}
    />
  );
};

export default ReviewModal;
