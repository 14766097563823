import type { ApolloError } from '@apollo/client';
import { CandidateActivityHistoryActivityType } from '@headrace/types';
import type { RecruiterToReview } from '@headrace/ui';
import { formatApolloError } from '@headrace/utils';
import React, { useCallback, useMemo, useRef } from 'react';

import type {
  CandidatesByRoleQuery,
  RoleByIdQuery,
  RoleQuestionsQuery,
} from '@/graphql/generated';
import {
  useCandidatesByRoleQuery,
  useRoleByIdQuery,
  useRoleQuestionsQuery,
} from '@/graphql/generated';
import { useEmployee } from '@/lib/EmployeeProvider';
import type { RecommendedAndRelevantRecruitersOutput } from '@/lib/hooks/useRecommendedAndRelevantRecruiters';
import useRecommendedAndRelevantRecruiters from '@/lib/hooks/useRecommendedAndRelevantRecruiters';

export interface RoleDetailsContextData
  extends RecommendedAndRelevantRecruitersOutput {
  roleData: {
    data?: RoleByIdQuery['employerRoleById'];
    loading: boolean;
    error?: ApolloError;
  };
  refetchRole: () => Promise<void>;
  submittedCandidates: {
    data?: CandidatesByRoleQuery['candidatesByRole'];
    loading: boolean;
    error?: ApolloError;
    refetchCandidates: () => Promise<void>;
  };
  recruitersToReview: (
    candidates: CandidatesByRoleQuery['candidatesByRole']['candidates']
  ) => RecruiterToReview[] | null;
  candidatesFetchedOnce: boolean;
  roleQuestions: {
    data?: RoleQuestionsQuery['roleQuestions'];
    loading: boolean;
    error?: string | null;
    refetch: () => Promise<void>;
  };
}

interface RoleDetailsProviderProps {
  roleId: string;
}

const RoleDetailsContext = React.createContext<RoleDetailsContextData>({
  fetchRelevantRecruiters: () => Promise.resolve(),
  callRecommendedAndRelevantRecruiters: () => {},
  fetchRecommendedRecruiters: () => Promise.resolve(),
  loadingRecommendedAndRelevantRecruiters: false,
  recommendedAndRelevantRecruitersData: [],
  loadingActiveRecruiters: false,
  refetchActiveRecruiters: () => Promise.resolve(),
  refetchRole: () => Promise.resolve(),
  roleData: {
    loading: false,
  },
  submittedCandidates: {
    loading: false,
    refetchCandidates: () => Promise.resolve(),
  },
  recruitersToReview: () => null,
  candidatesFetchedOnce: true,
  roleQuestions: {
    loading: false,
    refetch: () => Promise.resolve(),
  },
});

const RoleDetailsProvider: React.FC<RoleDetailsProviderProps> = ({
  children,
  roleId,
}) => {
  const candidatesFetchedOnce = useRef<boolean>(true);
  const { data, loading, error, refetch } = useRoleByIdQuery({
    variables: { id: roleId },
  });

  const { employee } = useEmployee();

  const refetchRole = useCallback(async (): Promise<void> => {
    await refetch();
  }, [refetch]);

  const {
    fetchRecommendedRecruiters,
    fetchRelevantRecruiters,
    callRecommendedAndRelevantRecruiters,
    recommendedAndRelevantRecruitersData,
    loadingRecommendedAndRelevantRecruiters,
    errorRecommendedAndRelevantRecruiters,
    activeRoleRecruitersData,
    refetchActiveRecruiters,
    loadingActiveRecruiters,
    errorActiveRecruiters,
  } = useRecommendedAndRelevantRecruiters(roleId);

  const {
    data: candidatesData,
    loading: loadingCandidateData,
    error: errorCandidateData,
    refetch: refetchCandidateData,
  } = useCandidatesByRoleQuery({
    variables: { roleId },
  });

  const refetchCandidates = useCallback(async (): Promise<void> => {
    candidatesFetchedOnce.current = false;
    await refetchCandidateData();
  }, [refetchCandidateData]);

  const recruitersToReview = useCallback(
    (
      candidates: CandidatesByRoleQuery['candidatesByRole']['candidates']
    ): RecruiterToReview[] | null => {
      if (!candidates) return null;
      const recruiters: RecruiterToReview[] = [];
      candidates.forEach((candidate) => {
        if (candidate.recruiter) {
          let recruiter = {
            id: candidate.recruiter?.id,
            name: candidate.recruiter?.user.name,
            photoUrl: candidate.recruiter?.user.photoUrl,
          };
          if (candidate.collaboration) {
            recruiter = {
              id: candidate.collaboration.primaryRecruiter.id,
              name: candidate.collaboration.primaryRecruiter.user.name,
              photoUrl: candidate.collaboration.primaryRecruiter.user.photoUrl,
            };
          }
          if (
            candidate.status ===
              CandidateActivityHistoryActivityType.OFFER_ACCEPTED &&
            !recruiters.find((r) => r.id === recruiter.id) &&
            !candidate.searchAgreementRole.searchReviews.some(
              (r) =>
                r.reviewedByEmployeeId === employee?.id &&
                r.recruiterId === recruiter.id
            )
          ) {
            recruiters.push({
              id: recruiter.id,
              name: recruiter.name,
              photoUrl: recruiter.photoUrl,
              candidateName: candidate.talent.name,
              candidateLinkedin: candidate.talent.linkedIn,
            });
          }
        }
      });
      return recruiters;
    },
    [employee?.id]
  );

  const {
    data: roleQuestionData,
    loading: roleQuestionLoading,
    error: roleQuestionError,
    refetch: roleQuestionRefetch,
  } = useRoleQuestionsQuery({
    variables: { filter: { roleId } },
  });

  const refetchQuestions = useCallback(async (): Promise<void> => {
    await roleQuestionRefetch();
  }, [roleQuestionRefetch]);

  const roleDetailsContextState: RoleDetailsContextData = useMemo(
    () => ({
      fetchRecommendedRecruiters,
      fetchRelevantRecruiters,
      callRecommendedAndRelevantRecruiters,
      recommendedAndRelevantRecruitersData,
      loadingRecommendedAndRelevantRecruiters,
      errorRecommendedAndRelevantRecruiters,
      activeRoleRecruitersData,
      refetchActiveRecruiters,
      loadingActiveRecruiters,
      errorActiveRecruiters,
      refetchRole,
      roleData: {
        data: data?.employerRoleById,
        loading,
        error,
      },
      submittedCandidates: {
        data: candidatesData?.candidatesByRole || undefined,
        loading: loadingCandidateData,
        error: errorCandidateData,
        refetchCandidates,
      },
      recruitersToReview,
      candidatesFetchedOnce: candidatesFetchedOnce.current,
      roleQuestions: {
        data: roleQuestionData?.roleQuestions,
        loading: roleQuestionLoading,
        error: roleQuestionError ? formatApolloError(roleQuestionError) : null,
        refetch: refetchQuestions,
      },
    }),
    [
      fetchRecommendedRecruiters,
      fetchRelevantRecruiters,
      callRecommendedAndRelevantRecruiters,
      recommendedAndRelevantRecruitersData,
      loadingRecommendedAndRelevantRecruiters,
      errorRecommendedAndRelevantRecruiters,
      activeRoleRecruitersData,
      refetchActiveRecruiters,
      loadingActiveRecruiters,
      errorActiveRecruiters,
      refetchRole,
      data?.employerRoleById,
      loading,
      error,
      candidatesData?.candidatesByRole,
      loadingCandidateData,
      errorCandidateData,
      refetchCandidates,
      recruitersToReview,
      roleQuestionData?.roleQuestions,
      roleQuestionLoading,
      roleQuestionError,
      refetchQuestions,
    ]
  );

  return (
    <RoleDetailsContext.Provider value={roleDetailsContextState}>
      {children}
    </RoleDetailsContext.Provider>
  );
};

export const useRoleDetails = (): RoleDetailsContextData =>
  React.useContext(RoleDetailsContext);

export default RoleDetailsProvider;
